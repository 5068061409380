import React from "react"
import { makeAutoObservable } from "mobx"
import { t } from "@lingui/macro"

import { FormFields } from "src/lib/form-fields"
import { Channel } from "src/channel"
import { InfoCenterAdminService } from "src/api"
import { reportError } from "src/lib/report"
import { ILibraryCategory, ILibraryInformationItem } from "src/types/library"

interface IFormFields {
    internalName?: string
    nameInApp?: string
}

export class LibraryCategoriesStore {
    static Context = React.createContext<LibraryCategoriesStore | null>(null)
    formFields = new FormFields<IFormFields>({})
    isLoading = false

    items: ILibraryInformationItem[] = []

    constructor() {
        makeAutoObservable(this)
    }

    init = (category?: ILibraryCategory) => {
        this.formFields.init({
            internalName: category?.adminName ?? "",
            nameInApp: category?.displayName ?? "",
        })

        this.items = category?.items ?? []
    }

    setItems(items: ILibraryInformationItem[]) {
        this.items = items
        this.formFields.setAllFieldsAsDirty()
    }

    createCategory = async () => {
        try {
            await this.formFields.catchErrors(async () => {
                await InfoCenterAdminService.infocenterCreateCategroy({
                    request: {
                        admin_name: this.formFields.get("internalName") ?? "",
                        display_name: this.formFields.get("nameInApp") ?? "",
                    },
                })
            })

            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "library-categories",
                    action: "update",
                },
            })
        } catch (error) {
            reportError(t`edit-category-modal.save-category-fail`, error)
        } finally {
            this.setIsLoading(false)
        }
    }

    updateCategory = async (category: ILibraryCategory) => {
        try {
            await this.formFields.catchErrors(async () => {
                await InfoCenterAdminService.infocenterUpdateCategory({
                    categoryId: category.categoryId as number,
                    request: {
                        admin_name: this.formFields.get("internalName") ?? "",
                        display_name: this.formFields.get("nameInApp") ?? "",
                        parent_category_id: category?.parentCategoryId,
                    },
                })

                await InfoCenterAdminService.patchV1AdminInfocenterCategoryRank1(
                    {
                        categoryId: category.categoryId as number,
                        request: Object.fromEntries(
                            this.items.map((item) => [item.id, item.rank]),
                        ),
                    },
                )
            })

            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "library-categories",
                    action: "update",
                },
            })
        } catch (error) {
            reportError(t`edit-category-modal.save-category-fail`, error)
        } finally {
            this.setIsLoading(false)
        }
    }

    validateRequiredFields = (requiredFields: string[]) => {
        this.formFields.clearErrors()
        requiredFields.forEach((field) => {
            const requiredField = field as keyof IFormFields
            const formField = this.formFields.get(requiredField)

            if (formField == null || formField === "") {
                this.formFields.setError(requiredField, t`errors.required`)
            } else {
                this.formFields.clearError(requiredField)
            }
        })
    }

    setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading)
}
