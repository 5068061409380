import { IIconProps } from "src/components/icons"

export const AvyTmplLogo = (props: IIconProps) => {
    const colorStyle = props?.style?.color?.toLowerCase()
    const isWhiteText = Boolean(
        colorStyle === "#fff" || colorStyle === "#ffffff",
    )
    const fill = isWhiteText ? "#fff" : "#202020"

    return (
        <svg
            width="300"
            height="111"
            viewBox="0 0 300 111"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={props.style}
        >
            <path
                d="M77.7213 30.2085L44.8316 1.75671C42.1211 -0.585569 38.0856 -0.585569 35.3664 1.75671L2.47669 30.2085C0.900614 31.5691 0 33.5411 0 35.6164V76.4598C0 80.421 3.23009 83.6244 7.20492 83.6244H28.9063C30.1706 83.6244 31.2011 82.6083 31.2011 81.3424V68.0983C31.2011 66.841 32.223 65.8163 33.4959 65.8163H46.7454C48.0097 65.8163 49.0402 66.8324 49.0402 68.0983V81.3424C49.0402 82.5997 50.062 83.6244 51.335 83.6244H73.0017C76.9852 83.6244 80.2067 80.4124 80.2067 76.4598V35.6164C80.2067 33.5411 79.306 31.5691 77.73 30.2085H77.7213ZM49.0315 45.5969C49.0315 46.9575 47.9231 48.0597 46.5548 48.0597H33.6518C32.2836 48.0597 31.1751 46.9575 31.1751 45.5969V32.766C31.1751 31.4055 32.2836 30.3032 33.6518 30.3032H46.5548C47.9231 30.3032 49.0315 31.4055 49.0315 32.766V45.5969Z"
                fill={fill}
            />
            <path
                d="M166.189 25.3949H154.966C154.265 25.3949 153.711 25.5843 153.304 25.9718C152.897 26.3593 152.698 26.9191 152.698 27.651V29.4077C152.698 30.398 152.386 30.8889 151.754 30.8889C151.312 30.8889 150.74 30.5961 150.039 30.0105C147.9 28.1074 145.423 26.6263 142.6 25.5843C139.777 24.5423 136.66 24.0171 133.23 24.0171C128.147 24.0171 123.436 25.3346 119.106 27.9783C114.776 30.6133 111.321 34.2473 108.741 38.863C106.16 43.4786 104.87 48.6454 104.87 54.3633C104.87 60.0812 106.16 65.2652 108.741 69.9154C111.321 74.5741 114.776 78.2167 119.106 80.8517C123.436 83.4868 128.147 84.8129 133.23 84.8129C136.807 84.8129 140.054 84.2532 142.99 83.1337C145.917 82.0142 148.454 80.447 150.593 78.4319C151 78.1047 151.329 77.9411 151.589 77.9411C152.291 77.9411 152.637 78.6214 152.637 79.9734V81.3512C152.637 82.8582 153.39 83.6073 154.906 83.6073H166.181C167.688 83.6073 168.45 82.8582 168.45 81.3512V27.651C168.45 26.9191 168.259 26.3593 167.869 25.9718C167.48 25.5843 166.917 25.3949 166.181 25.3949H166.189ZM152.152 63.2416C150.68 65.825 148.662 67.84 146.099 69.2867C143.536 70.7334 140.686 71.4568 137.56 71.4568C134.434 71.4568 131.568 70.7334 128.996 69.2867C126.415 67.84 124.38 65.825 122.891 63.2416C121.401 60.6582 120.648 57.6959 120.648 54.3633C120.648 51.0307 121.393 48.1287 122.891 45.5453C124.38 42.9619 126.415 40.9555 128.996 39.526C131.576 38.0966 134.434 37.3818 137.56 37.3818C140.686 37.3818 143.536 38.0966 146.099 39.526C148.662 40.9555 150.68 42.9619 152.152 45.5453C153.624 48.1287 154.36 51.0652 154.36 54.3633C154.36 57.6615 153.624 60.6582 152.152 63.2416Z"
                fill={fill}
            />
            <path
                d="M235.493 25.7825C235.138 25.5241 234.653 25.395 234.03 25.395H222.036C220.711 25.395 219.862 25.9805 219.49 27.1517L206.717 61.2869C206.31 62.3117 205.816 62.8283 205.227 62.8283C204.639 62.8283 204.136 62.3117 203.738 61.2869L191.138 27.2033C190.956 26.6178 190.627 26.17 190.168 25.86C189.709 25.55 189.164 25.395 188.54 25.395H176.546C175.923 25.395 175.438 25.5241 175.109 25.7825C174.78 26.0408 174.615 26.4025 174.615 26.8847C174.615 27.1775 174.71 27.5478 174.892 27.987L197.997 81.8593C198.473 83.0305 199.356 83.616 200.646 83.616H209.826C211.116 83.616 211.982 83.0477 212.424 81.911L235.753 27.987C235.935 27.5478 236.03 27.1775 236.03 26.8847C236.03 26.4111 235.857 26.0408 235.502 25.7825H235.493Z"
                fill={fill}
            />
            <path
                d="M299.524 25.8083C299.195 25.5327 298.692 25.395 298.034 25.395H286.257C284.967 25.395 284.118 26.015 283.711 27.2636L272.375 60.3569C271.968 61.3816 271.449 61.8983 270.825 61.8983C270.202 61.8983 269.717 61.4075 269.388 60.4172L257.888 27.2722C257.481 26.0236 256.649 25.4036 255.402 25.4036H242.802C242.179 25.4036 241.685 25.5327 241.339 25.7911C240.984 26.0494 240.811 26.4111 240.811 26.8933C240.811 27.1861 240.906 27.5564 241.088 27.9956L261.871 78.6731C262.131 79.1898 262.261 79.6979 262.261 80.2146C262.261 80.6537 262.131 81.1274 261.871 81.644L251.254 108.417C251.107 108.744 251.029 109.08 251.029 109.407C251.029 109.924 251.202 110.311 251.557 110.587C251.904 110.862 252.397 111 253.021 111H264.46C265.716 111 266.582 110.397 267.058 109.183L299.835 27.9956C299.948 27.6683 300 27.3325 300 27.0053C300 26.4886 299.835 26.1011 299.506 25.8255L299.524 25.8083Z"
                fill={fill}
            />
        </svg>
    )
}
