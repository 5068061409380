import { MenuItem, styled } from "@mui/material"

export const Flag = styled("img")(({ theme }) => ({
    width: "1.5rem",
    borderRadius: theme.shape.borderRadius,
    marginRight: "0.5rem",
}))

export const UserInfoIconWrapper = styled("div")(() => ({
    width: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}))

export const CustomMenuItem = styled(MenuItem)(() => ({
    display: "flex",
    justifyContent: "space-between",
}))
