import { styled } from "@mui/material"

export const FileInputButton = styled("button")(({ theme }) => ({
    display: "block",
    width: "100%",
    padding: "0 0.75rem",
    borderWidth: "1px",
    borderStyle: "dashed",
    borderColor: theme.palette.grey[500],
    borderRadius: theme.shape.borderRadius,
    background: "transparent",
    color: theme.palette.grey[700],
    fontSize: "1rem",
    cursor: "pointer",
    "&:hover:not(:disabled)": {
        color: theme.palette.grey[900],
        borderColor: theme.palette.grey[700],
    },
    "&:active:not(:disabled)": {
        color: theme.palette.grey[900],
        borderColor: theme.palette.grey[900],
    },
    "&:disabled": {
        cursor: "not-allowed",
    },
}))
