import { t } from "@lingui/macro"
import { FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useState } from "react"

import { Picker, IPickerProps } from "src/components/WeekdayRangePicker/Picker"
import {
    createDefaultWeekdayRanges,
    Distribution,
} from "src/components/WeekdayRangePicker/utils"

interface IBaseProps {
    disabled?: boolean
}

type IProps = IBaseProps & Omit<IPickerProps, "distribution">

export const WeekdayRangePicker = observer(
    ({ onChange, step, ...props }: IProps) => {
        const getInitialDistribution = useCallback(() => {
            const ranges = Object.values(props.value)

            const nonNullRanges = ranges.filter(
                (range): range is number[] => range != null,
            )

            if (ranges.length !== nonNullRanges.length) {
                return Distribution.IndividualDays
            }

            const firstRange = nonNullRanges[0]
            for (const range of nonNullRanges.slice(1)) {
                if (firstRange[0] !== range[0] || firstRange[1] !== range[1]) {
                    return Distribution.IndividualDays
                }
            }

            return Distribution.EqualDays
        }, [props.value])

        const [distribution, setDistribution] = useState<Distribution>(
            getInitialDistribution,
        )

        const handleDistributionChange = useCallback(
            (_: unknown, value: string) => {
                const distribution = value as Distribution
                if (distribution === Distribution.EqualDays) {
                    onChange(createDefaultWeekdayRanges(step))
                }
                setDistribution(distribution)
            },
            [onChange, step],
        )

        return (
            <Stack spacing={1}>
                <Stack
                    spacing={1}
                    direction={{ xs: "column", sm: "row" }}
                    alignItems={{ xs: "flex-start", sm: "center" }}
                >
                    <span>{t`weekday-range-picker.distribution-label`}</span>
                    <RadioGroup
                        row
                        value={distribution}
                        onChange={handleDistributionChange}
                    >
                        <FormControlLabel
                            value={Distribution.EqualDays}
                            label={t`weekday-range-picker.all-days-distribution-label`}
                            control={<Radio />}
                            disabled={props.disabled}
                        />
                        <FormControlLabel
                            value={Distribution.IndividualDays}
                            label={t`weekday-range-picker.day-by-day-distribution-label`}
                            control={<Radio />}
                            disabled={props.disabled}
                        />
                    </RadioGroup>
                </Stack>
                <Picker
                    distribution={distribution}
                    step={step}
                    value={props.value}
                    onChange={onChange}
                    disabled={props.disabled}
                />
            </Stack>
        )
    },
)
