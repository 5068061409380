import { observer } from "mobx-react"

import { FormPanel } from "src/components/FormPanel"
import { IEmbeddedIntegrationSvgIconUploadProps } from "src/modals/embedded-integration-v2/types/embeddedIntegrationSvgIconUpload"
import ImageSelectionSection from "src/components/AssetAttachmentsField"

export const EmbeddedIntegrationSvgIconUpload = observer(
    ({
        header,
        subHeader,
        value,
        onIconDelete,
        onIconChange,
        errorMessage,
        acceptedFormats,
        setErrorMessage,
    }: IEmbeddedIntegrationSvgIconUploadProps) => {
        return (
            <FormPanel
                sections={[
                    {
                        header: header,
                        subHeader: subHeader,
                        content: (
                            <ImageSelectionSection
                                imageUrl={value}
                                onImageDelete={() => onIconDelete()}
                                onImageChange={(images) => onIconChange(images)}
                                errorMessage={errorMessage ?? ""}
                                setErrorMessage={(error) =>
                                    setErrorMessage(error)
                                }
                                acceptedFormats={acceptedFormats}
                            />
                        ),
                    },
                ]}
            />
        )
    },
)
