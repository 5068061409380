import { styled } from "@mui/material"

const Mark = styled("mark")(({ theme }) => ({
    background: "rgb(223, 227, 243)",
    color: theme.palette.common.black,
}))

interface IProps {
    text: string
    query: string | null
}

/**
 * HighlightedQuery highlights matching parts of a string by wrapping them in a
 * `mark` element.
 *
 * This is implemented without Regex as there's no way to 100% be sure to not
 * execute malicious user provided regex.
 */
export const HighlightedQuery = (props: IProps) => {
    if (
        props.query == null ||
        props.text.length === 0 ||
        props.query.length === 0
    ) {
        return <>{props.text}</>
    }

    const lowerCaseText = props.text.toLowerCase()
    const lowerCaseQuery = props.query.toLowerCase()

    const lowerCaseSegments = lowerCaseText
        .split(lowerCaseQuery)
        .flatMap((text, i) => (i === 0 ? [text] : [lowerCaseQuery, text]))

    let cursor = 0
    const properCaseSegments = lowerCaseSegments.map((segment) => {
        const nextCursor = cursor + segment.length
        const proper = props.text.substring(cursor, nextCursor)
        cursor = nextCursor
        return proper
    })

    const markedSegments = properCaseSegments.map((segment, i) =>
        i % 2 === 0 ? (
            segment
        ) : (
            <Mark key={`mark-${segment}-${i}`}>{segment}</Mark>
        ),
    )

    return <>{markedSegments}</>
}
