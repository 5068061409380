import { t } from "@lingui/macro"
import { Stack, ToggleButton, Typography } from "@mui/material"
import { observer } from "mobx-react"

import { useCallback, useMemo } from "react"

import { Separator } from "src/modals/embedded-integration-v2/styled"
import { useStore } from "src/store/lib/useStore"
import { ModalTextField } from "src/components/ModalTextField"
import { EmbeddedIntegrationActivePurchaseSection } from "src/modals/embedded-integration-v2/components/ActivePurchaseSectionView"
import { ContentSectionStore } from "src/modals/embedded-integration-v2/sections/ContentSection/store"
import {
    EmbedIntegrationToggleChipGroup,
    LinkHeader,
} from "src/modals/embedded-integration-v2/sections/ContentSection/styled"
import { IPurchaseTabFieldsProps } from "src/modals/embedded-integration-v2/types/defaultPurchaseTabFields"
import { EmbeddedIntegrationRickLinkCardSection } from "src/modals/embedded-integration-v2/components/RichLinkCardSectionView"
import { EmbedIntegrationAddCardButtonSection } from "src/modals/embedded-integration-v2/components/AddCardButtonSection"
import { EmbeddedIntegrationPurchaseTabCardType } from "src/modals/embedded-integration-v2/types/contentDynamicFields"
import { initialPurchaseRichLinkDynamicFieldState } from "src/modals/embedded-integration-v2/constants/initialPurchaseRichLinkDynamicFieldState"
import { initialPurchaseSimpleLinkDynamicFieldState } from "src/modals/embedded-integration-v2/constants/initialPurchaseSimpleLinkDynamicFieldState"
import { initialPurchaseCollapsibleLinkDynamicFieldState } from "src/modals/embedded-integration-v2/constants/initialPurchaseCollapsibleLinkDynamicFieldState"
import { EmbeddedIntegrationSimpleLinkCardSection } from "src/modals/embedded-integration-v2/components/SimpleLinkCardSectionView"
import { EmbeddedIntegrationCollapsibleCardSection } from "src/modals/embedded-integration-v2/components/CollapsibleCardSectionView"
import { IPurchaseTabTextFieldsProps } from "src/modals/embedded-integration-v2/types/purchaseTabTextFields"

import { SpanBox } from "src/modals/embedded-integration-v2/sections/ContentSection/Tabs/PurchaseTabScreen/styled"

export const EmbeddedIntegrationPurchaseTabSection = observer(() => {
    const contentStore = useStore(ContentSectionStore)
    const { data } = contentStore.fields

    const handleTextInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        key: keyof IPurchaseTabTextFieldsProps,
        purchaseType: IPurchaseTabFieldsProps,
    ) => {
        purchaseType[key].errorMessage = ""
        if (event.target.value.trim() === "") {
            purchaseType[key].errorMessage = t`errors.required`
        }
        purchaseType[key].value = event.target.value
    }
    const renderTextInput = (
        label: string,
        rowLength: number,
        helperText: string,
        purchaseType: IPurchaseTabFieldsProps,
        key: keyof IPurchaseTabTextFieldsProps,
        maxLength?: number,
    ) => {
        return (
            <ModalTextField
                variant="default"
                label={label ?? ""}
                value={purchaseType[key].value}
                onChange={(event) =>
                    handleTextInputChange(event, key, purchaseType)
                }
                helperText={
                    Boolean(purchaseType[key].errorMessage)
                        ? purchaseType[key].errorMessage
                        : helperText
                }
                error={Boolean(purchaseType[key].errorMessage)}
                rows={rowLength}
                maxLength={maxLength ?? 255}
            />
        )
    }

    const handleSwitchChange = useCallback(
        (
            value: boolean,
            purchaseType: IPurchaseTabFieldsProps,
            label: string,
        ) => {
            switch (label) {
                case t`embed-integrations-modal.content-section.purchase-tab.status-badge.header`:
                    if (value === false) {
                        purchaseType.statusBadgeText.errorMessage = ""
                        purchaseType.statusBadgeBackgroundColor.errorMessage =
                            ""
                        purchaseType.statusBadgeText.value = ""
                        purchaseType.statusBadgeBackgroundColor.value = ""
                    }
                    return (purchaseType.statusBadge.value = value)
                case t`embed-integrations-modal.content-section.purchase-tab.info-box.header`:
                    if (value === false) {
                        purchaseType.infoBoxTitle.errorMessage = ""
                        purchaseType.infoBoxTextDescription.errorMessage = ""
                        purchaseType.infoBoxBackgroundColor.errorMessage = ""
                        purchaseType.infoBoxTitle.value = ""
                        purchaseType.infoBoxTextDescription.value = ""
                        purchaseType.infoBoxBackgroundColor.value = ""
                    }
                    return (purchaseType.infoBox.value = value)
                case t`embed-integrations-modal.content-section.purchase-tab.price-box.header`:
                    if (value === false) {
                        purchaseType.priceBoxName.errorMessage = ""
                        purchaseType.priceBoxAmount.errorMessage = ""
                        purchaseType.priceBoxCurrency.errorMessage = ""
                        purchaseType.priceBoxPeriod.errorMessage = ""
                        purchaseType.priceBoxName.value = ""
                        purchaseType.priceBoxAmount.value = 0
                        purchaseType.priceBoxCurrency.value = ""
                        purchaseType.priceBoxPeriod.value = ""
                    }
                    return (purchaseType.priceBox.value = value)
            }
        },
        [],
    )

    const renderStatusBadgeSection = (
        label: string,
        purchaseType: IPurchaseTabFieldsProps,
    ) => (
        <EmbeddedIntegrationActivePurchaseSection
            isRequired={purchaseType.statusBadge.value}
            headerLabel={label}
            handleSwitchChange={(event, value: boolean) => {
                handleSwitchChange(value, purchaseType, label)
            }}
        >
            {renderTextInput(
                t`embed-integrations-modal.content-section.purchase-tab.status-badge.label`,
                1,
                "",
                purchaseType,
                "statusBadgeText",
                255,
            )}
            {renderTextInput(
                t`embed-integrations-modal.content-section.purchase-tab.status-badge.color`,
                1,
                t`embed-integrations-modal.content-section.purchase-tab.status-badge.helper-text`,
                purchaseType,
                "statusBadgeBackgroundColor",
                255,
            )}
        </EmbeddedIntegrationActivePurchaseSection>
    )
    const renderInfoBoxSection = (
        label: string,
        purchaseType: IPurchaseTabFieldsProps,
    ) => (
        <EmbeddedIntegrationActivePurchaseSection
            isRequired={purchaseType.infoBox.value}
            headerLabel={label}
            handleSwitchChange={(event, value: boolean) => {
                handleSwitchChange(value, purchaseType, label)
            }}
        >
            {renderTextInput(
                t`embed-integrations-modal.content-section.purchase-tab.info-box.label`,
                1,
                "",
                purchaseType,
                "infoBoxTitle",
                255,
            )}
            {renderTextInput(
                t`embed-integrations-modal.content-section.purchase-tab.info-box.description`,
                4,
                "",
                purchaseType,
                "infoBoxTextDescription",
                10000,
            )}
            {renderTextInput(
                t`embed-integrations-modal.content-section.purchase-tab.info-box.color`,
                1,
                t`embed-integrations-modal.content-section.purchase-tab.info-box.color.helper-text`,
                purchaseType,
                "infoBoxBackgroundColor",
                255,
            )}
        </EmbeddedIntegrationActivePurchaseSection>
    )

    const handleChange = useCallback(
        (_: unknown, value: string) => {
            if (value !== null) {
                contentStore.fields.set("salesPurchaseType", value)
            }
        },
        [contentStore],
    )

    const renderToggleChips = useMemo(
        () => (
            <EmbedIntegrationToggleChipGroup
                value={contentStore.fields.data.salesPurchaseType}
                onChange={handleChange}
                exclusive
            >
                {contentStore._toggleChipOptions.map((type) => (
                    <ToggleButton key={type} value={type}>
                        <SpanBox>{type}</SpanBox>
                    </ToggleButton>
                ))}
            </EmbedIntegrationToggleChipGroup>
        ),
        [
            contentStore._toggleChipOptions,
            contentStore.fields.data.salesPurchaseType,
            handleChange,
        ],
    )

    const renderPriceBoxSection = (
        label: string,
        purchaseType: IPurchaseTabFieldsProps,
    ) => (
        <EmbeddedIntegrationActivePurchaseSection
            isRequired={purchaseType.priceBox.value}
            headerLabel={label}
            handleSwitchChange={(event, value: boolean) => {
                handleSwitchChange(value, purchaseType, label)
            }}
        >
            {renderTextInput(
                t`embed-integrations-modal.content-section.purchase-tab.price-box.name`,
                1,
                "",
                purchaseType,
                "priceBoxName",
                255,
            )}
            {renderTextInput(
                t`embed-integrations-modal.content-section.purchase-tab.price-box.amount`,
                1,
                "",
                purchaseType,
                "priceBoxAmount",
                255,
            )}
            {renderTextInput(
                t`embed-integrations-modal.content-section.purchase-tab.price-box.currency`,
                1,
                t`embed-integrations-modal.content-section.purchase-tab.price-box.currency.helper-text`,
                purchaseType,
                "priceBoxCurrency",
                255,
            )}
            {renderTextInput(
                t`embed-integrations-modal.content-section.purchase-tab.price-box.period`,
                1,
                t`embed-integrations-modal.content-section.purchase-tab.price-box.period.helper-text`,
                purchaseType,
                "priceBoxPeriod",
                255,
            )}
        </EmbeddedIntegrationActivePurchaseSection>
    )
    const handleAddRichLinkCardClick = (
        cardType: string,
        purchaseType: IPurchaseTabFieldsProps,
    ) => {
        const purchaseObject = { ...purchaseType }

        switch (cardType) {
            case EmbeddedIntegrationPurchaseTabCardType.Rich:
                const richLinkDynamicList =
                    purchaseType?.purchaseRichLinkDynamicFieldsList !==
                        undefined &&
                    purchaseType?.purchaseRichLinkDynamicFieldsList?.length !==
                        0
                        ? purchaseType.purchaseRichLinkDynamicFieldsList
                        : []
                const richList = [
                    ...richLinkDynamicList,
                    { ...initialPurchaseRichLinkDynamicFieldState },
                ]
                purchaseObject.purchaseRichLinkDynamicFieldsList = richList
                return contentStore.fields.set(
                    data.salesPurchaseType,
                    purchaseObject,
                )

            case EmbeddedIntegrationPurchaseTabCardType.Simple:
                const simpleLinkDynamicList =
                    purchaseType?.purchaseSimpleLinkDynamicFieldsList !==
                        undefined &&
                    purchaseType?.purchaseSimpleLinkDynamicFieldsList
                        ?.length !== 0
                        ? purchaseType.purchaseSimpleLinkDynamicFieldsList
                        : []
                const simpleList = [
                    ...simpleLinkDynamicList,
                    { ...initialPurchaseSimpleLinkDynamicFieldState },
                ]
                purchaseObject.purchaseSimpleLinkDynamicFieldsList = simpleList
                return contentStore.fields.set(
                    data.salesPurchaseType,
                    purchaseObject,
                )
            default:
                const collapsibleLinkDynamicList =
                    purchaseType?.purchaseCollapsibleLinkDynamicFieldsList !==
                        undefined &&
                    purchaseType?.purchaseCollapsibleLinkDynamicFieldsList
                        ?.length !== 0
                        ? purchaseType.purchaseCollapsibleLinkDynamicFieldsList
                        : []
                const collapsibleList = [
                    ...collapsibleLinkDynamicList,
                    { ...initialPurchaseCollapsibleLinkDynamicFieldState },
                ]
                purchaseObject.purchaseCollapsibleLinkDynamicFieldsList =
                    collapsibleList
                return contentStore.fields.set(
                    data.salesPurchaseType,
                    purchaseObject,
                )
        }
    }
    const handleRemoveCardClick = (
        index: number,
        cardType: string,
        purchaseType: IPurchaseTabFieldsProps,
    ) => {
        switch (cardType) {
            case EmbeddedIntegrationPurchaseTabCardType.Rich:
                if (purchaseType.purchaseRichLinkDynamicFieldsList.length > 0) {
                    return purchaseType.purchaseRichLinkDynamicFieldsList.splice(
                        index,
                        1,
                    )
                }
                return
            case EmbeddedIntegrationPurchaseTabCardType.Simple:
                if (
                    purchaseType.purchaseSimpleLinkDynamicFieldsList.length > 0
                ) {
                    return purchaseType.purchaseSimpleLinkDynamicFieldsList.splice(
                        index,
                        1,
                    )
                }
                return
            default:
                if (
                    purchaseType.purchaseCollapsibleLinkDynamicFieldsList
                        .length > 0
                ) {
                    return purchaseType.purchaseCollapsibleLinkDynamicFieldsList.splice(
                        index,
                        1,
                    )
                }
                return
        }
    }

    const renderPurchaseRichLinkCard = (
        purchaseType: IPurchaseTabFieldsProps,
    ) => (
        <Stack spacing={1}>
            {purchaseType.purchaseRichLinkDynamicFieldsList?.map(
                (item, index) => (
                    <EmbeddedIntegrationRickLinkCardSection
                        index={index}
                        item={item}
                        purchaseType={purchaseType}
                        onRemoveClick={() =>
                            handleRemoveCardClick(
                                index,
                                EmbeddedIntegrationPurchaseTabCardType.Rich,
                                purchaseType,
                            )
                        }
                    />
                ),
            )}
            <EmbedIntegrationAddCardButtonSection
                onClick={() =>
                    handleAddRichLinkCardClick(
                        EmbeddedIntegrationPurchaseTabCardType.Rich,
                        purchaseType,
                    )
                }
                buttonText={t`embed-integrations-modal.content-section.purchase-tab.price-box.button-text`}
            />
        </Stack>
    )

    const renderPurchaseSimpleLinkCard = (
        purchaseType: IPurchaseTabFieldsProps,
    ) => (
        <Stack spacing={1}>
            {purchaseType.purchaseSimpleLinkDynamicFieldsList?.map(
                (item, index) => (
                    <EmbeddedIntegrationSimpleLinkCardSection
                        index={index}
                        item={item}
                        purchaseType={purchaseType}
                        onRemoveClick={() =>
                            handleRemoveCardClick(
                                index,
                                EmbeddedIntegrationPurchaseTabCardType.Simple,
                                purchaseType,
                            )
                        }
                    />
                ),
            )}
            <EmbedIntegrationAddCardButtonSection
                onClick={() =>
                    handleAddRichLinkCardClick(
                        EmbeddedIntegrationPurchaseTabCardType.Simple,
                        purchaseType,
                    )
                }
                buttonText={t`embed-integrations-modal.content-section.purchase-tab.price-box.button-text`}
            />
        </Stack>
    )

    const renderPurchaseCollapsibleSectionCard = (
        purchaseType: IPurchaseTabFieldsProps,
    ) => (
        <Stack spacing={1}>
            {purchaseType.purchaseCollapsibleLinkDynamicFieldsList?.map(
                (item, index) => (
                    <EmbeddedIntegrationCollapsibleCardSection
                        index={index}
                        item={item}
                        purchaseType={purchaseType}
                        onRemoveClick={() =>
                            handleRemoveCardClick(
                                index,
                                EmbeddedIntegrationPurchaseTabCardType.Collapsible,
                                purchaseType,
                            )
                        }
                    />
                ),
            )}
            <EmbedIntegrationAddCardButtonSection
                onClick={() =>
                    handleAddRichLinkCardClick(
                        EmbeddedIntegrationPurchaseTabCardType.Collapsible,
                        purchaseType,
                    )
                }
                buttonText={t`embed-integrations-modal.content-section.purchase-tab.price-box.button-text`}
            />
        </Stack>
    )
    const renderPurchaseSection = (
        purchaseTabFields: IPurchaseTabFieldsProps,
    ) => {
        return (
            <>
                {renderStatusBadgeSection(
                    t`embed-integrations-modal.content-section.purchase-tab.status-badge.header`,
                    purchaseTabFields,
                )}
                {renderInfoBoxSection(
                    t`embed-integrations-modal.content-section.purchase-tab.info-box.header`,
                    purchaseTabFields,
                )}
                {renderPriceBoxSection(
                    t`embed-integrations-modal.content-section.purchase-tab.price-box.header`,
                    purchaseTabFields,
                )}
                <Separator />
                <LinkHeader>{t`embed-integrations-modal.content-section.purchase-tab.rich-title`}</LinkHeader>
                {renderPurchaseRichLinkCard(purchaseTabFields)}
                <Separator />
                <LinkHeader>{t`embed-integrations-modal.content-section.purchase-tab.simple-title`}</LinkHeader>
                {renderPurchaseSimpleLinkCard(purchaseTabFields)}
                <Separator />
                <LinkHeader>{t`embed-integrations-modal.content-section.purchase-tab.collapsible-title`}</LinkHeader>
                {renderPurchaseCollapsibleSectionCard(purchaseTabFields)}
            </>
        )
    }

    return (
        <Stack spacing={2}>
            <Typography variant="body1">{t`embed-integrations-modal.content-section.purchase-tab.header`}</Typography>
            <Separator />
            {renderToggleChips}
            {renderPurchaseSection(data[data.salesPurchaseType])}
        </Stack>
    )
})
