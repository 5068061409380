import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { Box } from "@mui/material"

import { useStore } from "src/store/lib/useStore"
import { FormFields } from "src/modals/chatbot-configuration/components/formField"
import { AutoCompleteTextField } from "src/components/AutoCompleteTextField"
import { GlobalStore } from "src/store"
import { ChatbotConfigurationStore } from "src/modals/chatbot-configuration/store"
import { ConfigType } from "src/types/dynamic-configurable-form-fields/config-type"

export const MetaDataSection = observer(() => {
    const globalStore = useStore(GlobalStore)
    const store = useStore(ChatbotConfigurationStore)

    return (
        <Box>
            <FormFields.ChatbotConfigurationTextInput
                variant="default"
                label={t`chatbot-configuration-detail.internal-name`}
                id="name"
                value={store.form.get("name")}
                rowLength={1}
                disabled={store.isReadOnly}
                maxLength={255}
                helperText={store.form.error("name") ?? undefined}
                error={Boolean(store.form.error("name"))}
                handleTextInputChange={(event) => {
                    store.form.set("name", event.target.value)
                }}
            />
            <AutoCompleteTextField
                disableCloseOnSelect
                multiSelect={store.configType === ConfigType.Multiple}
                options={store.locationOptions}
                defaultValues={store.form.get("location")}
                placeholder={t`chatbot-configuration-modal-location-filter-placeholder`}
                onChange={(newValue) => {
                    store.form.set("location", newValue)
                }}
                loading={globalStore.loading.is(
                    ChatbotConfigurationStore.LoadingKeys.init,
                )}
                sx={{
                    backgroundColor: "common.white",
                }}
                error={Boolean(store.form.error("location"))}
                helperText={store.form.error("location") ?? undefined}
            />
        </Box>
    )
})
