import { t } from "@lingui/macro"
import { makeAutoObservable } from "mobx"
import React from "react"

import {
    community_Author,
    community_CommentWithAdminDetails,
    community_PostWithAdminDetailsV2,
    CommunityAdminService,
    PreviewService,
} from "src/api"
import { Channel } from "src/channel"
import { loads } from "src/channel/utils"
import { environment } from "src/config"
import { getAuthorName, ILikeAuthor } from "src/lib/community"
import { parseDate } from "src/lib/date"
import { persistFiles } from "src/lib/file"
import { FormFields } from "src/lib/form-fields"
import { createLoadingKeys } from "src/lib/loading"
import { reportSuccess, reportUnhandledApiError } from "src/lib/report"

const flutterBaseUrl = environment.FLUTTER_BASE_URL

interface ICommentFormFields {
    authorId: string | null
    images: IFile[]
    text: string
}

interface IEditCommentFormFields {
    id: number
    text: string
}

interface IPost {
    id: number
    authorId: number
    authorObjectType: "admin_user" | "tenant" | "none"
    authorObjectId: number
    authorIsBlocked: boolean
    authorName: string
    categoryName: string
    commentCount: number
    likeCount: number
    likes: ILikeAuthor[]
    images: string[]
    createdDate: Date | null
    accessType: string
    text: string
    apartmentNumber: string
    internalObjectId: string
    communityId: number
}

export interface IComment {
    id: number
    postId: number
    authorObjectType: "admin_user" | "tenant" | "none"
    authorObjectId: number
    authorName: string
    createdDate: Date | null
    likes: ILikeAuthor[]
    text: string
    accessType: string
    images: string[]
    apartmentNumber: string
    internalObjectId: string
}

export interface IAuthor {
    id: string
    name: string
}

const MAX_IMAGES = 3

export class ViewCommunityPostStore implements IDisposable {
    static Context = React.createContext<ViewCommunityPostStore | null>(null)
    static LoadingKeys = createLoadingKeys(
        "init",
        "submit-comment",
        "delete-post",
        "delete-comment",
        "update-comment",
        "like-comment",
        "like-post",
        "block-unblock",
    )
    private repositoryChangeListenerDisposer?: () => void

    initialized = false

    comments: IComment[] = []
    authors: IAuthor[] = []

    private _post: IPost | null = null

    private editCommentForm: FormFields<IEditCommentFormFields> | null = null

    commentForm = new FormFields<ICommentFormFields>({
        authorId: null,
        images: [],
        text: "",
    })

    canEditPost(adminId?: number) {
        return (
            this.post.authorObjectType === "admin_user" &&
            this.post.authorObjectId === adminId
        )
    }

    canEditComment(commentId: number, adminId?: number) {
        const comment = this.comments.find(
            (comment) => comment.id === commentId,
        )
        if (comment == null) {
            return false
        }

        return (
            comment.authorObjectType === "admin_user" &&
            comment.authorObjectId === adminId
        )
    }

    get post(): IPost {
        if (this._post == null) {
            throw new Error("post not initialized")
        }

        return this._post
    }

    constructor() {
        makeAutoObservable(this)
        this.listenToRepositoryChanges()
    }

    dispose() {
        this.repositoryChangeListenerDisposer?.()
    }

    @loads(() => ViewCommunityPostStore.LoadingKeys.init)
    async init(id: number, authors: IAuthor[]) {
        await this.loadPost(id)
        await this.loadComments()
        this.setAuthors(authors)
        this.setInitialized()
        this.setInitialAuthor()
    }

    @loads(() => ViewCommunityPostStore.LoadingKeys["submit-comment"])
    async submitCommentForm() {
        try {
            this.commentForm.clearErrors()
            this.validate()
            if (this.commentForm.hasErrors()) {
                return
            }

            await this.commentForm.catchErrors(async () => {
                const images = await persistFiles(
                    this.commentForm.get("images"),
                    "image",
                )
                await CommunityAdminService.postV1AdminCommunityPostComment({
                    postId: this.post.id,
                    request: {
                        author_name: this.getAuthorNameFromForm(),
                        image_urls: images.map((image) => image.url),
                        text: this.commentForm.get("text"),
                    },
                })

                await Promise.all([this.reloadPost(), this.loadComments()])

                this.resetCommentForm()

                Channel.send({
                    name: "repository/updated",
                    payload: {
                        repository: "community-comments",
                        action: "create",
                    },
                })
            })
        } catch (e) {
            reportUnhandledApiError(e)
        }
    }

    getDetailsPreviewUrl = async () => {
        const detailsPreviewUrl = flutterBaseUrl + "community-preview?base64="

        try {
            const url = await PreviewService.postV1PreviewCommunityPost({
                request: {
                    author_name: this.post.authorName,
                    category_id: 0,
                    community_ids: [this.post.communityId],
                    image_urls: this.post.images,
                    pinned: false,
                    text: this.post.text,
                },
            })

            return `${detailsPreviewUrl}${encodeURIComponent(url)}`
        } catch (error) {
            return ""
        }
    }

    appendImage(image: ILocalFile) {
        const images = [...this.commentForm.get("images")]
        this.commentForm.set("images", [...images, image])
    }

    deleteImageAtIndex(index: number) {
        this.commentForm.data.images.splice(index, 1)
    }

    canAppendImage() {
        return this.commentForm.get("images").length < MAX_IMAGES
    }

    canSubmitComment() {
        return this.commentForm.get("text").trim().length > 0
    }

    @loads(() => ViewCommunityPostStore.LoadingKeys["delete-post"])
    async deletePost(): Promise<boolean> {
        try {
            await CommunityAdminService.deleteV1AdminCommunityPost({
                postId: this.post.id,
            })

            Channel.send({
                name: "repository/updated",
                payload: { repository: "community-posts", action: "delete" },
            })

            return true
        } catch (e) {
            reportUnhandledApiError(e)
            return false
        }
    }

    @loads(() => ViewCommunityPostStore.LoadingKeys["delete-comment"])
    async deleteComment(id: number): Promise<boolean> {
        try {
            await CommunityAdminService.deleteV1AdminCommunityComment({
                commentId: id,
            })

            Channel.send({
                name: "repository/updated",
                payload: { repository: "community-comments", action: "delete" },
            })

            this.removeCommentFromLoaded(id)

            return true
        } catch (e) {
            reportUnhandledApiError(e)
            return false
        }
    }

    startEditingComment(id: number) {
        const comment = this.comments.find((comment) => comment.id === id)

        if (comment == null) {
            return
        }

        this.editCommentForm = new FormFields({
            id: comment.id,
            text: comment.text,
        })
    }

    cancelEditingComment() {
        this.editCommentForm = null
    }

    getEditingCommentForm(id: number) {
        if (this.editCommentForm == null) {
            return null
        } else if (this.editCommentForm.get("id") !== id) {
            return null
        }

        return this.editCommentForm
    }

    isEditingComment(id: number) {
        if (this.editCommentForm == null) {
            return false
        }
        return this.editCommentForm.get("id") === id
    }

    isEditingOtherComment(id: number) {
        if (this.editCommentForm == null) {
            return false
        }
        return this.editCommentForm.get("id") !== id
    }

    isPostLikedByUser(adminId?: number) {
        if (adminId == null) {
            return false
        }

        return this.post.likes.some(
            (author) =>
                author.objectType === "admin_user" &&
                author.objectId === adminId,
        )
    }

    isCommentLikedByUser(commentId: number, adminId?: number) {
        if (adminId == null) {
            return false
        }

        const comment = this.comments.find(
            (comment) => comment.id === commentId,
        )
        if (comment == null) {
            return false
        }

        return comment.likes.some(
            (author) =>
                author.objectType === "admin_user" &&
                author.objectId === adminId,
        )
    }

    @loads(() => ViewCommunityPostStore.LoadingKeys["like-comment"])
    async toggleCommentLike(commentId: number, adminId?: number) {
        if (adminId == null) {
            return
        }

        if (this.isCommentLikedByUser(commentId, adminId)) {
            try {
                this.removeLikeFromLoadedComment(commentId, adminId)
                await CommunityAdminService.deleteV1AdminCommunityCommentLike({
                    commentId,
                })
            } catch (e) {
                this.addLikeFromLoadedComment(commentId, adminId)
                reportUnhandledApiError(e)
            }
        } else {
            try {
                this.addLikeFromLoadedComment(commentId, adminId)
                await CommunityAdminService.postV1AdminCommunityCommentLike({
                    commentId,
                    request: {
                        author_name: this.getAuthorNameFromForm(),
                    },
                })
            } catch (e) {
                this.removeLikeFromLoadedComment(commentId, adminId)
                reportUnhandledApiError(e)
            }
        }
    }

    @loads(() => ViewCommunityPostStore.LoadingKeys["like-post"])
    async togglePostLike(adminId?: number) {
        if (adminId == null) {
            return
        }

        if (this.isPostLikedByUser(adminId)) {
            try {
                this.removeLikeFromLoadedPost(adminId)
                await CommunityAdminService.deleteV1AdminCommunityPostLike({
                    postId: this.post.id,
                })
            } catch (e) {
                this.addLikeFromLoadedPost(adminId)
                reportUnhandledApiError(e)
            }
        } else {
            try {
                this.addLikeFromLoadedPost(adminId)
                await CommunityAdminService.postV1AdminCommunityPostLike({
                    postId: this.post.id,
                    request: {
                        authorName: this.getAuthorNameFromForm(),
                    },
                })
            } catch (e) {
                this.removeLikeFromLoadedPost(adminId)
                reportUnhandledApiError(e)
            }
        }
    }

    @loads(() => ViewCommunityPostStore.LoadingKeys["block-unblock"])
    async blockUnblockTenant(action: "block" | "unblock") {
        try {
            switch (action) {
                case "block":
                    await CommunityAdminService.postV1AdminCommunityBlock({
                        communityId: this.post.communityId,
                        request: {
                            author_id: this.post.authorId,
                        },
                    })
                    reportSuccess(
                        t`view-community-post-modal-block-tenant-success`,
                    )
                    break
                case "unblock":
                    await CommunityAdminService.postV1AdminCommunityUnblock({
                        communityId: this.post.communityId,
                        request: {
                            author_id: this.post.authorId,
                        },
                    })
                    reportSuccess(
                        t`view-community-post-modal-unblock-tenant-success`,
                    )
                    break
            }

            return true
        } catch (error) {
            reportUnhandledApiError(error)
            return false
        }
    }

    @loads(() => ViewCommunityPostStore.LoadingKeys["update-comment"])
    async submitEditedComment() {
        if (this.editCommentForm == null) {
            return
        }

        const { id, text } = this.editCommentForm.data
        try {
            await CommunityAdminService.patchV1AdminCommunityComment({
                commentId: id,
                request: { text },
            })
            this.updateLoadedComment(id, { text })
            this.cancelEditingComment()

            Channel.send({
                name: "repository/updated",
                payload: { repository: "community-comments", action: "update" },
            })
        } catch (e) {
            reportUnhandledApiError(e)
        }
    }

    private async loadPost(id: number) {
        try {
            const post = await CommunityAdminService.getV2AdminCommunityPost({
                postId: id,
            })
            this.setPost(post)
        } catch (e) {
            reportUnhandledApiError(e)
        }
    }

    private async loadComments() {
        try {
            const comments =
                await CommunityAdminService.getV1AdminCommunityPostComment({
                    postId: this.post.id,
                })
            this.setComments(comments.comments ?? [])
        } catch (e) {
            reportUnhandledApiError(e)
        }
    }

    private reloadPost() {
        return this.loadPost(this.post.id)
    }

    private updateLoadedComment(id: number, data: { text: string }) {
        const comment = this.comments.find((comment) => comment.id === id)
        if (comment == null) {
            return
        }

        Object.assign(comment, data)
    }

    private removeLikeFromLoadedComment(id: number, adminId: number) {
        const comment = this.comments.find((comment) => comment.id === id)
        if (comment == null) {
            return
        }

        comment.likes = comment.likes.filter(
            (like) =>
                like.objectType !== "admin_user" || like.objectId !== adminId,
        )
    }

    private addLikeFromLoadedComment(id: number, adminId: number) {
        const comment = this.comments.find((comment) => comment.id === id)
        if (comment == null) {
            return
        }

        comment.likes.push({
            objectType: "admin_user",
            objectId: adminId,
        })
    }

    private removeLikeFromLoadedPost(adminId: number) {
        this.post.likes = this.post.likes.filter(
            (like) =>
                like.objectType !== "admin_user" || like.objectId !== adminId,
        )
    }

    private addLikeFromLoadedPost(adminId: number) {
        this.post.likes.push({ objectType: "admin_user", objectId: adminId })
    }

    private setPost(post: community_PostWithAdminDetailsV2) {
        this._post = {
            id: post.post_id ?? -1,
            authorId: post.author_id ?? -1,
            authorObjectType: (post.author?.object_type ??
                "none") as IPost["authorObjectType"],
            authorObjectId: post.author?.object_id ?? -1,
            authorName: getAuthorName(post),
            authorIsBlocked: post.author_is_blocked ?? false,
            categoryName: post.category_name ?? "",
            commentCount: post.comments_count ?? 0,
            likes: this.toLikeAuthors(post.likes ?? []),
            likeCount: post.likes?.length ?? 0,
            images: post.image_urls ?? [],
            createdDate: parseDate(post.created_date),
            text: post.text ?? "",
            accessType: post.access_type ?? "",
            apartmentNumber: post.apartment_no ?? "-",
            internalObjectId: post.internal_apartment_id ?? "-",
            communityId: post.community_id ?? -1,
        }
    }

    private setComments(comments: community_CommentWithAdminDetails[]) {
        const nextComments = comments.map(
            (comment): IComment => ({
                id: comment.comment_id ?? -1,
                postId: comment.post_id ?? -1,
                authorObjectType: (comment.author?.object_type ??
                    "none") as IPost["authorObjectType"],
                authorObjectId: comment.author?.object_id ?? -1,
                authorName: getAuthorName(comment),
                createdDate: parseDate(comment.created_date),
                likes: this.toLikeAuthors(comment.likes ?? []),
                text: comment.text ?? "",
                accessType: comment.access_type ?? "",
                images: comment.image_urls ?? [],
                apartmentNumber: comment.apartment_no ?? "-",
                internalObjectId: comment.internal_apartment_id ?? "-",
            }),
        )

        nextComments.sort((a, b) => {
            const aTime = a.createdDate?.getTime() ?? 0
            const bTime = b.createdDate?.getTime() ?? 0
            return aTime - bTime
        })

        this.comments = nextComments
    }

    private toLikeAuthors(authors: community_Author[]): ILikeAuthor[] {
        return authors.map((author) => ({
            authorName: author.author_name ?? "",
            objectId: author.object_id ?? -1,
            objectType: (author.object_type ??
                "none") as ILikeAuthor["objectType"],
        }))
    }

    private removeCommentFromLoaded(id: number) {
        this.comments = this.comments.filter((comment) => comment.id !== id)
    }

    private resetCommentForm() {
        this.commentForm.set("images", [])
        this.commentForm.set("text", "")
    }

    public getAuthorNameFromForm() {
        const id = this.commentForm.get("authorId")
        const author = this.authors.find((author) => author.id === id)
        return author?.name
    }

    private setInitialAuthor() {
        //get local stored author
        const localAuthorId = this.getAuthorFromLocalStore()
        //validate if the stored author is pressent in the author list
        const authorExist = this.authors.find(
            (author) => author.id === localAuthorId,
        )

        if (authorExist !== undefined && localAuthorId != null) {
            this.commentForm.set("authorId", localAuthorId)
            return
        }

        if (authorExist === undefined || this.authors.length > 0) {
            this.setAuthorIdToLocalStore(this.authors[0].id)
            this.commentForm.set("authorId", this.authors[0].id)
            return
        }
    }

    private setAuthors(authors: IAuthor[]) {
        this.authors = authors
    }

    private setInitialized() {
        this.initialized = true
    }

    private validate() {
        if (this.commentForm.get("text").trim().length === 0) {
            this.commentForm.setError("text", t`errors.required`)
        }
    }

    private listenToRepositoryChanges() {
        this.repositoryChangeListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "community-posts" &&
                    event.payload.action === "update"
                ) {
                    await this.reloadPost()
                }
            },
        )
    }

    private getAuthorFromLocalStore() {
        return window.localStorage.getItem("authorId")
    }

    public setAuthorIdToLocalStore(authorId: string) {
        window.localStorage.setItem("authorId", authorId)
    }
}
