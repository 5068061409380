import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material"
import { observer } from "mobx-react"
import { t } from "@lingui/macro"

import { useEffect } from "react"

import { EditAutomaticAssigneeStore } from "src/modals/automatic-assignee-detail"

import { useStore } from "src/store/lib/useStore"
import { reportError } from "src/lib/report"
import { ContactFormAdminService } from "src/api"

interface IProps {
    propertyId: number | undefined
}
export const Assignee = observer((props: IProps) => {
    const store = useStore(EditAutomaticAssigneeStore)

    useEffect(() => {
        ;(async () => {
            try {
                store.setAssignee(
                    await ContactFormAdminService.getV1AdminContactFormAdmins({
                        propertyId: props.propertyId ?? -1,
                    }),
                )
            } catch (error) {
                reportError(
                    t`edit-information-item-modal.load-types-fail`,
                    error,
                )
            }
        })()
    }, [store, props.propertyId])

    const onSelectValueChange = async (assigneeId: string) => {
        await store.formFields.set("automaticAssignee", Number(assigneeId))
    }
    return (
        <FormControl style={{ breakInside: "avoid" }}>
            <InputLabel id="type-label">{t`contact-form-automatic-assignee-view.header`}</InputLabel>
            {store.automaticAssignees.length > 0 && (
                <Select
                    labelId="assignee-label"
                    id="assignee"
                    value={String(store.formFields.get("automaticAssignee"))}
                    label={t`contact-form-automatic-assignee-view.header`}
                    onChange={(event) =>
                        onSelectValueChange(event.target.value)
                    }
                    error={Boolean(store.formFields.error("automaticAssignee"))}
                >
                    {store.automaticAssignees.map((item) => (
                        <MenuItem key={item.admin_id} value={item.admin_id}>
                            {item.name}
                        </MenuItem>
                    ))}
                </Select>
            )}
            {Boolean(store.formFields.error("automaticAssignee")) && (
                <FormHelperText error={true}>
                    {store.formFields.error("automaticAssignee")}
                </FormHelperText>
            )}
        </FormControl>
    )
})
