import { t } from "@lingui/macro"
import { TranslateRounded } from "@material-ui/icons"
import { Box, Stack, TextField, useTheme } from "@mui/material"
import { observer } from "mobx-react"
import React, { useCallback, useMemo, useState, ChangeEvent } from "react"
import { debounce } from "lodash"

import { FeaturesAdminService } from "src/api"
import { ConfigurableSecondaryLanguage } from "src/components/Inputs/TextFields/MultiLanguageTextField/components/ConfigurableSecondaryLanguage"
import { TooltipWithIcon } from "src/components/Tooltips/TooltipWithIcon"
import { DEFAULT_LANGUAGE } from "src/config"
import { reportUnhandledApiError } from "src/lib/report"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { IMultiLanguageTextFieldProps } from "src/types/modal-text-field"

export const MultiLanguageTextField = observer(
    ({
        data,
        disabled,
        rows,
        maxRows,
        tooltipText,
        label,
        placeholder,
        helperText,
        error,
        maxLength,
        size,
        fieldName,
        onChange,
        onSecondaryLanguageChange,
    }: IMultiLanguageTextFieldProps) => {
        const globalStore = useStore(GlobalStore)
        const theme = useTheme()
        const currentTimestamp = Date.now()
        const [sourceTextEditTime, setSourceTextEditTime] =
            useState<number>(currentTimestamp)
        const [languagesTranslationTime, setLanguagesTranslationTime] =
            useState<number>(currentTimestamp)

        const textFieldValue = data?.[DEFAULT_LANGUAGE] ?? ""
        const backgroundColor =
            disabled === true
                ? theme.palette.grey[300]
                : theme.palette.common.white

        const configurableLanguages = useMemo(
            () =>
                globalStore.session.configurableLanguages
                    ?.split(", ")
                    ?.filter((language) => language !== DEFAULT_LANGUAGE) ?? [],
            [globalStore.session.configurableLanguages],
        )
        const multiline = useMemo(
            () =>
                (rows !== undefined && rows > 1) ||
                (maxRows !== undefined && maxRows > 1),
            [rows, maxRows],
        )
        const areTranslationsStale = useMemo(() => {
            return sourceTextEditTime > languagesTranslationTime
        }, [sourceTextEditTime, languagesTranslationTime])

        const handleChange = useCallback(
            (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                if (!Boolean(onChange)) return

                const trimmedValue = event.target.value.trimStart()
                const updatedValue = {
                    ...data,
                    [DEFAULT_LANGUAGE]: trimmedValue,
                }

                setSourceTextEditTime(Date.now())
                onChange({
                    ...event,
                    target: {
                        ...event.target,
                        value: updatedValue,
                    },
                    currentTarget: {
                        ...event.currentTarget,
                        value: updatedValue,
                    },
                })
            },
            [data, onChange],
        )

        const handleSecondaryLanguageChange = useCallback(
            (value: string, language: string) => {
                if (!Boolean(onSecondaryLanguageChange)) return

                const trimmedValue = value.trimStart()
                const updatedValue = {
                    ...data,
                    [language]: trimmedValue,
                }

                onSecondaryLanguageChange({
                    target: { value: updatedValue },
                    currentTarget: { value: updatedValue },
                })
            },
            [data, onSecondaryLanguageChange],
        )

        const translationApiPayload = useMemo(() => {
            return {
                localised_items: {
                    [fieldName]: {
                        [DEFAULT_LANGUAGE]: textFieldValue,
                        ...configurableLanguages.reduce(
                            (obj, cur) => ({ ...obj, [cur]: "" }),
                            {},
                        ),
                    },
                },
            }
        }, [configurableLanguages, fieldName, textFieldValue])

        const handleTranslateClick = async () => {
            if (onSecondaryLanguageChange === undefined || data === undefined)
                return

            try {
                const response =
                    await FeaturesAdminService.postV2AdminFeatureConfigTranslate(
                        { request: translationApiPayload },
                    )

                // update the data with the translated values
                onSecondaryLanguageChange({
                    target: {
                        value: response?.localised_items?.[fieldName] ?? data,
                    },
                    currentTarget: {
                        value: response?.localised_items?.[fieldName] ?? data,
                    },
                })
                setLanguagesTranslationTime(Date.now())
            } catch (error) {
                reportUnhandledApiError(error)
            }
        }

        const debouncedHandleTranslateClick = debounce(
            handleTranslateClick,
            500,
        )

        return (
            <>
                <Box
                    sx={{ display: "flex", gap: "8px" }}
                    style={{
                        breakInside: "avoid",
                        alignItems: "center",
                    }}
                >
                    <TextField
                        label={label}
                        placeholder={placeholder}
                        onChange={handleChange}
                        value={textFieldValue}
                        helperText={helperText}
                        error={error}
                        disabled={disabled}
                        rows={rows}
                        multiline={multiline}
                        maxRows={maxRows}
                        style={{ backgroundColor }}
                        inputProps={{
                            maxLength,
                        }}
                        size={size}
                        data-testid="multi-language-text-field"
                    />
                    <Stack spacing={2} direction="row">
                        <TooltipWithIcon tooltipText={tooltipText} />
                        <TooltipWithIcon
                            tooltipText={
                                areTranslationsStale
                                    ? t`multi-language-text-field.refresh-translation`
                                    : t`multi-language-text-field.generate-translation`
                            }
                            icon={<TranslateRounded width="16" height="16" />}
                            onClick={debouncedHandleTranslateClick}
                        />
                    </Stack>
                </Box>
                <Stack spacing={1} direction="column">
                    {configurableLanguages.map((language: string) => (
                        <ConfigurableSecondaryLanguage
                            key={language}
                            language={language}
                            data={data}
                            areTranslationsStale={areTranslationsStale}
                            onSave={(value) =>
                                handleSecondaryLanguageChange(value, language)
                            }
                        />
                    ))}
                </Stack>
            </>
        )
    },
)
