import { Stack } from "@mui/material"
import { observer } from "mobx-react"
import { t } from "@lingui/macro"

import { LabelQuestionTextField } from "./LabelQuestionTextField"
import { BottomContainerSection } from "./BottomContainerSection"

import {
    CardContainer,
    CardInnerContainer,
    DraggableIconContainer,
    Separator,
} from "src/modals/contact-forms-form-builder/styled"
import { FormDetailStore } from "src/modals/contact-forms-form-builder/store"
import { IMetaDataFieldsSectionProps } from "src/modals/contact-forms-form-builder/types/dynamicFieldSection"
import { DragIndicator } from "src/components/icons/DragIndicator"
import { ExternalFieldTypeDropdown } from "src/modals/contact-forms-form-builder/DynamicViewComponents/ExternalFieldTypeDropdown"
import { isBinButtonDisabled } from "src/modals/contact-forms-form-builder/helper/isBinButtonDisabled"
import { IMetaDataFieldListItemProps } from "src/modals/contact-forms-form-builder/types/fieldsListItem"
import { useStore } from "src/store/lib/useStore"
import { useWindowWide } from "src/views/feature-toggles/features/useWindowWide"

export const MetaDataFieldsSection = observer(
    (props: IMetaDataFieldsSectionProps) => {
        const {
            metaDataFields,
            setMetaDataFields,
            index,
            handleContentCopyClick,
            handleRemoveClick,
        } = props
        const store = useStore(FormDetailStore)
        const currentWidth = useWindowWide(0)

        const handleLabelQuestionFormChange = (
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => {
            const list = [...metaDataFields]
            const obj: IMetaDataFieldListItemProps = JSON.parse(
                JSON.stringify(list[index]),
            )
            obj.label.errorMessage = ""
            store.setHasError(false)
            const value = event.target.value
            obj.label.value = value.trim() !== "" ? value : ""
            if (value.trim() === "") {
                store.setHasError(true)
                obj.label.errorMessage = t`errors.required`
            }
            list[index] = obj
            setMetaDataFields(list)
        }

        const onSelectValueChange = (value: string) => {
            let data = [...metaDataFields]
            const obj: IMetaDataFieldListItemProps = JSON.parse(
                JSON.stringify(data[index]),
            )
            obj.source.errorMessage = ""
            store.setHasError(false)
            obj.source.value = value.trim() !== "" ? value : ""
            if (value.trim() === "") {
                store.setHasError(true)
                obj.source.errorMessage = t`errors.required`
            }
            data[index] = obj
            setMetaDataFields(data)
        }

        return (
            <CardContainer windowWidth={currentWidth}>
                <CardInnerContainer>
                    <DraggableIconContainer>
                        <DragIndicator />
                    </DraggableIconContainer>
                    <Stack spacing={2}>
                        <ExternalFieldTypeDropdown
                            sources={
                                store.formFields.get("metaDataFieldsList") ?? []
                            }
                            selectedValue={metaDataFields[index].source.value}
                            onSelectionChange={onSelectValueChange}
                            errorMessage={
                                metaDataFields[index].source.errorMessage
                            }
                        />
                        <LabelQuestionTextField
                            index={index}
                            item={metaDataFields[index].label}
                            handleFormChange={handleLabelQuestionFormChange}
                        />
                        <Separator />
                        <BottomContainerSection
                            variant="non-required"
                            index={index}
                            handleContentCopyClick={() =>
                                handleContentCopyClick(index)
                            }
                            handleDeleteClick={() => handleRemoveClick(index)}
                            disableButton={isBinButtonDisabled(metaDataFields)}
                        />
                    </Stack>
                </CardInnerContainer>
            </CardContainer>
        )
    },
)
