import { IIconProps } from "src/components/icons"

export const ChevronUp24 = (props: IIconProps) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M12 9C12.275 8.99967 12.5369 9.11312 12.7187 9.31136L16.7968 13.7622C16.9856 13.9589 17.0486 14.2384 16.9615 14.4925C16.8744 14.7465 16.6508 14.9354 16.3773 14.9859C16.1038 15.0364 15.8232 14.9407 15.6441 14.7358L12.0781 10.8438C12.0584 10.8222 12.0299 10.8098 12 10.8098C11.9701 10.8098 11.9416 10.8222 11.9219 10.8438L8.35586 14.7366C8.17682 14.9415 7.89625 15.0372 7.62272 14.9867C7.34919 14.9362 7.12562 14.7473 7.03852 14.4932C6.95142 14.2392 7.01445 13.9597 7.20323 13.763L11.2801 9.31296C11.4621 9.11424 11.7244 9.00025 12 9Z"
            fill="currentColor"
        />
    </svg>
)
