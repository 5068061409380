import { useEffect, useState } from "react"
import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { Button, styled } from "@mui/material"

import { OrdersStore, IOrder } from "./store"

import { ProductCatalogueAdminService } from "src/api"
import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { ListPage } from "src/components/ListPage"
import { Table } from "src/components/Table"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { autoDownloadFile } from "src/lib/file"
import { formatDateTime } from "src/lib/date"
import { formatPrice } from "src/lib/number"
import { reportUnhandledApiError } from "src/lib/report"
import { IPageFilterProps } from "src/components/PageFilter"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"

const ActionButton = styled(Button)(({ theme }) => ({
    background: theme.palette.common.white,
}))

const View = observer(() => {
    const vstore = useStore(OrdersStore)
    const gstore = useStore(GlobalStore)
    const [isDownloading, setIsDownloading] = useState(false)

    const header = {
        header: t`orders-view.header`,
        breadcrumbs: [t`orders-view.header`],
    }

    const tableIsLoading =
        !vstore.orders.meta.initialized ||
        gstore.loading.is(OrdersStore.LoadingKeys.pageLoad)

    const downloadOrders = async () => {
        setIsDownloading(true)

        trackModuleEvent("Orders | Export Orders", {
            [MixpanelProperties.AccessGroupName]:
                gstore.session.accessGroup?.name,
            [MixpanelProperties.AccessGroupID]: gstore.session.accessGroup?.id,
        })

        try {
            const response =
                await ProductCatalogueAdminService.getV1AdminCatalogueOrderExport()

            const blob = new Blob([response], { type: "text/csv" })
            const url = URL.createObjectURL(blob)

            autoDownloadFile(
                url,
                `AVY-orders-${new Date().toDateString()}.csv`,
                "export-orders-link",
            )

            URL.revokeObjectURL(url)
        } catch (e) {
            reportUnhandledApiError(e)
        } finally {
            setIsDownloading(false)
        }
    }

    useEffect(() => {
        ;(async () => {
            await vstore.init()
        })()
    }, [vstore])

    const filter: IPageFilterProps = {
        actions: (
            <ActionButton
                onClick={downloadOrders}
                disabled={tableIsLoading || isDownloading}
                color="info"
                variant="outlined"
                sx={{
                    width: {
                        sm: "fit-content",
                    },
                }}
                data-testid="export-orders-button"
            >
                {t`orders-view.export-orders`}
            </ActionButton>
        ),
    }

    return (
        <ListPage header={header} filter={filter} loading={tableIsLoading}>
            <Table
                paginator={vstore.orders}
                headers={[
                    {
                        key: "orderId",
                        name: t`orders-view.table-header-order-id`,
                    },
                    {
                        key: "date",
                        name: t`orders-view.table-header-date`,
                    },
                    {
                        key: "status",
                        name: t`orders-view.table-header-status`,
                    },
                    {
                        key: "provider",
                        name: t`orders-view.table-header-provider`,
                    },
                    {
                        key: "tenantId",
                        name: t`orders-view.table-header-tenant-id`,
                    },
                    {
                        key: "price",
                        name: t`orders-view.table-header-price`,
                    },
                    {
                        key: "product",
                        name: t`orders-view.table-header-product`,
                    },
                ]}
                rowRenderer={rowRenderer}
            />
        </ListPage>
    )
})

const rowRenderer = (item: IOrder) => ({
    orderId: item.orderId,
    date: item.orderDate != null && formatDateTime(item.orderDate),
    status: item.status,
    provider: item.provider,
    tenantId: item.tenantId,
    price:
        item.priceCents != null &&
        formatPrice(item.priceCents, true, item.currency),
    product: item.adminName,
})

export const OrdersView = () => (
    <StoreProvider Store={OrdersStore}>
        <View />
    </StoreProvider>
)
