import { Stack, styled } from "@mui/material"

const Body = styled(Stack)(({ theme }) => ({
    background: theme.palette.common.white,
    padding: "10px",
    borderRadius: "0 8px 8px 8px",
    flex: 1,
    lineHeight: 1.4,
}))

const Author = styled("span")({
    fontSize: "14px",
    fontWeight: 500,
})

const TimeSince = styled("time")(({ theme }) => ({
    fontSize: "12px",
    color: theme.palette.text.disabled,
}))

const Image = styled("img")({
    height: "82px",
    width: "82px",
    borderRadius: "4px",
    overflow: "hidden",
    objectFit: "cover",
    display: "block",
})

const LikeButton = styled("button")(({ theme }) => ({
    background: "transparent",
    color: theme.palette.grey[700],
    fontSize: "12px",
    border: 0,
    cursor: "pointer",
    "&:hover, &:active": {
        color: theme.palette.grey[900],
        border: 0,
    },
    "&:disabled": {
        color: theme.palette.grey[700],
    },
}))

const LikeFooter = styled(Stack)(({ theme }) => ({
    fontSize: "14px",
    color: theme.palette.grey[700],
    "& > span": {
        fontSize: "12px",
        lineHeight: 1,
    },
}))

const OpenLikesModalButton = styled("button")(({ theme }) => ({
    padding: 0,
    margin: 0,
    border: 0,
    color: theme.palette.grey[700],
    background: "transparent",
    display: "flex",
    alignItems: "center",
    gap: "4px",
    cursor: "pointer",
    "&:hover, &:active": {
        color: theme.palette.grey[900],
        border: 0,
    },
    "&:disabled": {
        color: theme.palette.grey[700],
        cursor: "default",
    },
}))

const ActionDropdownPosition = styled("div")({ margin: "-8px" })

const ApartmentIdContainer = styled("span")(({ theme }) => ({
    fontSize: "12px",
    color: theme.palette.text.disabled,
    marginLeft: 8,
}))

const InternalObjectIDContainer = styled("span")(({ theme }) => ({
    fontSize: "12px",
    color: theme.palette.text.disabled,
    marginLeft: 8,
}))

export {
    Body,
    Author,
    TimeSince,
    Image,
    LikeButton,
    LikeFooter,
    OpenLikesModalButton,
    ActionDropdownPosition,
    ApartmentIdContainer,
    InternalObjectIDContainer,
}
