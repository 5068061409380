import { IIconProps } from "src/components/icons"

export const FilterOutline24 = (props: IIconProps) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g>
            <g>
                <g>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20.8053 3.92419C21.1119 4.49095 21.0529 5.17115 20.6529 5.68408L14.925 13.2992V18.1815C14.9236 18.7122 14.6574 19.2116 14.2056 19.5311C14.2036 19.5324 14.2039 19.5324 14.2042 19.5325C14.2067 19.5329 14.2093 19.5334 12.2305 20.6879C11.851 20.9002 11.4148 21.0063 10.9731 20.9938C10.4692 21.0343 9.9703 20.8748 9.59773 20.554C9.22517 20.2332 9.01312 19.7805 9.01318 19.3061V13.297L3.33799 5.67133C2.94425 5.15739 2.89032 4.47997 3.19832 3.91688C3.50632 3.35378 4.12442 2.99977 4.79915 3H19.2014C19.8789 3.00023 20.4988 3.35743 20.8053 3.92419ZM12.9267 13.0023C12.9267 12.8087 12.9906 12.6199 13.1097 12.4617L18.3717 5.4659C18.4576 5.35144 18.4685 5.20156 18.3997 5.07728C18.3309 4.953 18.194 4.87503 18.0447 4.8751H5.94698C5.7981 4.87514 5.66159 4.95279 5.59273 5.0766C5.52388 5.20041 5.5341 5.34986 5.61926 5.4644L10.8284 12.4662C10.9458 12.6233 11.0086 12.8105 11.0083 13.0023V18.4799C11.0084 18.6139 11.0848 18.7377 11.2087 18.8045C11.3325 18.8714 11.485 18.8711 11.6086 18.8038C11.9412 18.6226 12.3411 18.4006 12.6129 18.2497C12.6559 18.2258 12.6958 18.2037 12.7316 18.1838C12.8527 18.1161 12.9268 17.9936 12.9267 17.8614V13.0023Z"
                        fill="currentColor"
                    />
                </g>
            </g>
        </g>
    </svg>
)
