import React from "react"
import { makeAutoObservable } from "mobx"

import { t } from "@lingui/macro"

import { Channel } from "src/channel"
import { reportError, reportUnhandledApiError } from "src/lib/report"

import { createLoadingKeys } from "src/lib/loading"
import { FormFields } from "src/lib/form-fields"
import { loads } from "src/channel/utils"
import { AdminService } from "src/api"
import { ModalsStore } from "src/store/modals"

export type TPropertyType =
    | "RENT"
    | "BRF"
    | "STUDENT_BRF"
    | "STUDENT_RENT"
    | "DEMO"

export interface IFormFields {
    id: number | undefined | string
    legalName: string
    organisationId: string
    propertyOwnerId: number | undefined
    selfOnboardingEnabled: boolean
    type: TPropertyType
    externalId: string
    integrationId: number | undefined
    integrationActive: boolean
    leaflet: string
}

export class CreateLegalEntityModalStore {
    modals = new ModalsStore()

    static Context = React.createContext<CreateLegalEntityModalStore | null>(
        null,
    )
    static LoadingKeys = createLoadingKeys("submit", "init", "delete")
    private id?: number

    //due the LoadingKeys isn't working properly we are testing this `isLoading` property
    //to replace the previous submit validation
    isLoading = false

    fields = new FormFields<IFormFields>({
        id: "",
        legalName: "",
        organisationId: "",
        propertyOwnerId: undefined,
        selfOnboardingEnabled: false,
        type: "RENT",
        externalId: "",
        integrationId: undefined,
        integrationActive: false,
        leaflet: "",
    })

    constructor() {
        makeAutoObservable(this)
    }
    @loads(() => CreateLegalEntityModalStore.LoadingKeys.init)
    async init(propertyOwnerId: number | undefined, id?: number) {
        this.setLegalId(id)
        this.setIsLoading(true)
        try {
            if (id != null) {
                const response = await AdminService.getV1AdminLegalEntity1({
                    legalEntityId: id,
                })
                this.fields.init({
                    legalName: response.legal_name ?? "",
                    organisationId: response.org_id ?? "",
                    selfOnboardingEnabled:
                        response.self_onboarding_enabled ?? false,
                    type: "RENT",
                    externalId: response.external_id ?? "",
                    integrationId: response.integration_id,
                    integrationActive: response.integration_active ?? false,
                    leaflet: response.leaflet_url ?? "",
                    id: id,
                    propertyOwnerId: response.property_owner_id,
                })
            } else {
                this.fields.init({
                    ...this.fields.data,
                    id,
                    propertyOwnerId:
                        propertyOwnerId != null ? propertyOwnerId : undefined,
                })
            }
        } catch (e) {
            reportUnhandledApiError(e)
        }
        this.setIsLoading(false)
    }

    @loads(() => CreateLegalEntityModalStore.LoadingKeys.submit)
    private setLegalId(legalId?: number) {
        this.id = legalId
    }
    async submit() {
        const { data } = this.fields
        this.setIsLoading(true)
        await this.fields.catchErrors(async () => {
            if (this.id == null) {
                const response = await AdminService.postV1AdminLegalEntityBatch(
                    {
                        request: [
                            {
                                legal_name: data.legalName,
                                org_id: data.organisationId,
                                property_owner_id: data.propertyOwnerId ?? 0,
                                self_onboarding_enabled:
                                    data.selfOnboardingEnabled,
                                type: data.type,
                                integration_active:
                                    data.integrationActive ?? false,
                                integration_id: data.integrationId,
                                external_id: data.externalId ?? "",
                            },
                        ],
                    },
                )

                Channel.send({
                    name: "repository/updated",
                    payload: {
                        repository: "legal-entities",
                        action: "create",
                        item: {
                            // response doesnt return an id so Im not  sure how to update -  https://dev-api.avy.se/swagger/index.html#/Admin/post_v1_admin_legal_entity_batch
                            id: response.id,
                            name: data.legalName,
                        },
                    },
                })
            } else {
                await AdminService.patchV1AdminLegalEntity({
                    request: {
                        external_id: data.externalId,
                        integration_active: data.integrationActive ?? false,
                        integration_id: data.integrationId,
                        name: data.legalName,
                        property_owner_id: data.propertyOwnerId,
                    },
                    legalEntityId: this.id,
                })

                Channel.send({
                    name: "repository/updated",
                    payload: {
                        repository: "legal-entities",
                        action: "update",
                        item: {
                            // response doesnt return an id so Im not sure how to update -  https://dev-api.avy.se/swagger/index.html#/Admin/post_v1_admin_legal_entity_batch
                            id: this.id,
                            name: data.legalName,
                        },
                    },
                })
            }
        })
        this.setIsLoading(false)
    }

    @loads(() => CreateLegalEntityModalStore.LoadingKeys.delete)
    async delete(id: number | string) {
        const { data } = this.fields
        try {
            const legalEntityId = parseInt(id.toString())
            await AdminService.deleteV1AdminLegalEntity({
                legalEntityId,
            })
            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "legal-entities",
                    action: "update",
                    item: {
                        // response doesnt return an id so Im not sure how to update -  https://dev-api.avy.se/swagger/index.html#/Admin/post_v1_admin_legal_entity_batch
                        id: legalEntityId,
                        name: data.legalName,
                    },
                },
            })
        } catch (e) {
            reportError(
                t`create-new-legal-entity-modal.failed-deleting-entity`,
                e,
            )
        }
    }

    setIsLoading = (value: boolean) => {
        this.isLoading = value
    }
}
