import { styled } from "@mui/material"
export const FabButtonContainer = styled("div")(() => ({
    alignSelf: "center",
}))

export const IconContainer = styled("div")(({ theme }) => ({
    width: "1.5rem",
    borderRadius: theme.shape.borderRadius,
    marginRight: "0.5rem",
    paddingTop: "0.5rem",
}))

export const ItemContainer = styled("span")({
    display: "flex",
    alignItems: "center",
})

export const DropdownItemParentContainer = styled("div")(() => ({
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
}))
export const TextFieldContainer = styled("div")(() => ({
    flex: 0.96,
}))

export const Separator = styled("div")(() => ({
    border: "1px solid #EFEFEF",
    marginTop: "20px",
}))

export const CardContainer = styled("div")(
    ({ windowWidth }: { windowWidth: boolean }) => ({
        marginLeft: windowWidth ? 8 : 0,
        marginRight: windowWidth ? 8 : 0,
        borderWidth: 1,
        borderRadius: "8px",
        paddingLeft: "8px",
        backgroundColor: "#1876d3",
    }),
)

export const CardInnerContainer = styled("div")(() => ({
    borderWidth: 1,
    backgroundColor: "white",
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingBottom: "16px",
}))

export const CardBottomContainer = styled("div")(() => ({
    flexDirection: "row",
    display: "flex",
    justifyContent: "flex-end",
}))

export const VerticalSeparator = styled("div")(() => ({
    border: "1px solid #EFEFEF",
}))

export const DraggableContainer = styled("div")(() => ({
    marginBottom: "20px",
}))

export const DraggableIconContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "6px",
})
