import { LexoRank } from "lexorank"

/**
 * IRankable items are ordered based on a lexicographical `rank` in ascending order.
 *
 * The benefit of using this system to sort items is that you only need to
 * update the moved item's rank to change the order.
 */
export interface IRankable {
    rank: string

    /**
     * Is set to `true` if this items has changed position.
     *
     * Defaults to `false`.
     */
    modifiedRank: boolean
}

/**
 * Prepares rankable items to be sortable. Our ranking logic requires all items
 * to have a rank and for all items to be in the correct rank order. We cannot
 * trust the API to return ranks for all items so we need to fill in the blanks.
 *
 * This function needs to be called once before using a list of items in a
 * sorting compoment, e.g. RankableList.
 *
 * @param items A list of potentially unsorted and unranked items.
 * @returns A list of sorted and ranked items.
 */
export const prepareRankableItems = <TItem extends IRankable>(
    items: TItem[],
): TItem[] => {
    let rankedItems = items.filter((item) => item.rank !== "")
    const unRankedItems = items.filter((item) => item.rank === "")

    rankedItems.sort((a, b) => a.rank.localeCompare(b.rank))

    rankedItems = rankedItems.map((item) => ({ ...item, modifiedRank: false }))

    unRankedItems.forEach((unRankedItem) => {
        const rank =
            rankedItems.length === 0
                ? LexoRank.middle()
                : LexoRank.parse(rankedItems[0].rank).genPrev()

        rankedItems.unshift({
            ...unRankedItem,
            rank: rank.toString(),
            modifiedRank: true,
        })
    })

    return rankedItems
}

/**
 * Returns a modified and sorted version of `list`. The modification in
 * `dropResult` will be applied before sorting the new list. The modified item
 * will be marked with `modifiedRank: true` to make it easy to find modified
 * items.
 *
 * This function does not modify the `list` argument to make it play nicely with mobx.
 *
 * @param list The original list of items
 * @param dropResult Result of a drop from react-smooth-dnd
 * @returns A new sorted list
 */
export const sortListFromResult = <TItem extends IRankable>(
    list: TItem[],
    dropResult: IDropResult,
): TItem[] => {
    // Deep-copy as to not mutate the original list and list items.
    const nextList = JSON.parse(JSON.stringify(list)) as TItem[]

    // The item was dropped in the first position of the list
    if (dropResult.addedIndex === 0 && dropResult.removedIndex != null) {
        nextList[dropResult.removedIndex].rank = LexoRank.parse(
            nextList[0].rank,
        )
            .genPrev()
            .toString()
        nextList[dropResult.removedIndex].modifiedRank = true
    }
    // The item was dropped in the last position of the list
    else if (
        dropResult.addedIndex === nextList.length - 1 &&
        dropResult.removedIndex != null
    ) {
        const last = nextList.length - 1
        nextList[dropResult.removedIndex].rank = LexoRank.parse(
            nextList[last].rank,
        )
            .genNext()
            .toString()
        nextList[dropResult.removedIndex].modifiedRank = true
    } else if (
        dropResult.addedIndex != null &&
        dropResult.removedIndex != null
    ) {
        const over = LexoRank.parse(nextList[dropResult.addedIndex].rank)
        const second = LexoRank.parse(
            nextList[
                dropResult.addedIndex +
                    (dropResult.addedIndex < dropResult.removedIndex ? -1 : 1)
            ].rank,
        )
        nextList[dropResult.removedIndex].rank = over.between(second).toString()
        nextList[dropResult.removedIndex].modifiedRank = true
    }

    return nextList.sort((a, b) => a.rank.localeCompare(b.rank))
}
