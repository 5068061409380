/* eslint-disable import/no-relative-parent-imports */
import { t } from "@lingui/macro"
import { Stack, TextField } from "@mui/material"
import { observer } from "mobx-react"

import { FormDetailStore } from "../store"

import { handleEmailFieldChange } from "../helper/handleEmailFieldChange"

import { useStore } from "src/store/lib/useStore"

export const EmailSection = observer(() => {
    const store = useStore(FormDetailStore)

    const handleEmailChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        handleEmailFieldChange(event, store.formFields)
    }

    return (
        <Stack spacing={2}>
            <TextField
                label={t`contact-form-forms-detail-modal.form-detail-section.output-email-label`}
                value={store.formFields.get("email")}
                onChange={handleEmailChange}
                helperText={store.formFields.error("email")}
                error={Boolean(store.formFields.error("email"))}
            />
        </Stack>
    )
})
