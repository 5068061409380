export interface IMessage<TKey extends string = string, TPayload = unknown> {
    name: TKey
    payload: TPayload
}

type MessageHandler<TMessage extends IMessage> = (event: TMessage) => void

/**
 * Message channel can be used through out the application to send and consume
 * messages in a de-coupled way.
 */
export class Channel<TEvents extends IMessage> {
    private listeners: MessageHandler<TEvents>[] = []

    addListener(handler: MessageHandler<TEvents>) {
        this.listeners.push(handler)
        return () => {
            this.listeners = this.listeners.filter(
                (listener) => listener !== handler,
            )
        }
    }

    send(event: TEvents) {
        this.listeners.forEach((fn) => fn(event))
    }

    clearListeners() {
        this.listeners = []
    }
}
