import { List, ListItem } from "@mui/material"
import { observer } from "mobx-react"

import { ConfigurableDynamicFormFieldsUnhandledElementUi } from "src/components/ConfigurableDynamicFormFields/components/UnhandledUIElement"
import { ConfigurableDynamicFormFieldsTextField } from "src/components/ConfigurableDynamicFormFields/formFields/text-field"
import { IConfigurableDynamicFormFieldsTextListProps } from "src/components/ConfigurableDynamicFormFields/types/form-field"

export const ConfigurableDynamicFormFieldsTextList = observer(
    ({ field, section }: IConfigurableDynamicFormFieldsTextListProps) => {
        if (field.length === undefined) {
            return <ConfigurableDynamicFormFieldsUnhandledElementUi />
        }

        const items = Array(field.length).fill({})
        return (
            <List component="ol" sx={{ marginLeft: 4, listStyle: "decimal" }}>
                {items.map((_, index) => (
                    <ListItem sx={{ display: "list-item" }} key={index}>
                        <ConfigurableDynamicFormFieldsTextField
                            field={field}
                            section={section}
                            index={index}
                        />
                    </ListItem>
                ))}
            </List>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },
)
