import { t } from "@lingui/macro"
import { Alert, Button } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { CenteredSpinner } from "src/components/CenteredSpinner"
import { Form } from "src/components/Form"
import { FormPanel } from "src/components/FormPanel"
import { ModalHeader } from "src/components/ModalHeader"
import { useInitializer } from "src/lib/initializer"
import { SettingsSection } from "src/modals/community-detail/SettingsSection"
import { CommunityDetailStore } from "src/modals/community-detail/store"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"

type IProps = { accessGroupId: number } | { id: number }

const Modal = observer((props: IProps) => {
    const vstore = useStore(CommunityDetailStore)
    const gstore = useStore(GlobalStore)

    const initialized = useInitializer(
        () => vstore.init(props),
        [vstore, props],
    )

    const handleSubmit = useCallback(async () => {
        await vstore.submit()
        if (!vstore.fields.hasErrors()) {
            gstore.modals.pop()
        }
    }, [vstore, gstore.modals])

    if (
        !initialized ||
        gstore.loading.is(CommunityDetailStore.LoadingKeys.init)
    ) {
        return <CenteredSpinner height="100vh" />
    }
    const isButtonDisabled =
        gstore.loading.is(CommunityDetailStore.LoadingKeys.submit) ||
        vstore.fields.get("accessType") === "READ" ||
        vstore.fields.getIsDirty() === false

    return (
        <Form onSubmit={handleSubmit}>
            <ModalHeader>
                <Button
                    data-testid="community-create/edit-modal-submit-button"
                    type="submit"
                    variant="contained"
                    disabled={isButtonDisabled}
                >{t`community-detail-modal.save-button`}</Button>
            </ModalHeader>
            {Boolean(vstore.fields.error("generic")) && (
                <Alert severity="error">{vstore.fields.error("generic")}</Alert>
            )}
            <FormPanel
                sections={[
                    {
                        header: t`community-detail-modal.settings-section`,
                        content: <SettingsSection />,
                    },
                ]}
            />
        </Form>
    )
})

export const CommunityDetailModal = observer((props: IProps) => (
    <StoreProvider Store={CommunityDetailStore}>
        <Modal {...props} />
    </StoreProvider>
))
