import { Environment, getEnvironment } from "src/lib/environment"

export interface IAuth {
    CLIENT_ID: string
    TOKEN_URL: string
    AUTHORIZATION_URL: string
    REDIRECT_URL: string
    SCOPES: string[]
}

interface IVariables {
    MIXPANEL_TOKEN: string
    API_BASE_URL: string
    BASE_URL: string
    LEGACY_API_BASE_URL: string
    SENTRY_DSN: string | null
    AUTH: {
        [key in "MICROSOFT" | "FASTIGHETSAGARNA"]: IAuth
    }
    MUI_X_PRO_KEY: string
    CHATBOT_INSIGHTS_DASHBOARD_ID: string
    TENANTS_INSIGHTS_DASHBOARD_ID: string
    FLUTTER_BASE_URL: string
}

const environments: { [key in Environment]: IVariables } = {
    test: {
        MIXPANEL_TOKEN: "7759539fcfbd1622d15240c77ed03ccd",
        MUI_X_PRO_KEY:
            "26c607008a9110b74d4267cad2703a5eTz05MTY3NSxFPTE3NDg3NjYwNzcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
        API_BASE_URL: "http://avytmpl-admin-api/api",
        LEGACY_API_BASE_URL: "http://avytmpl-admin-api-legacy/api",
        BASE_URL: "http://avytmpl-admin-legacy/api",
        FLUTTER_BASE_URL: "https://stage-devteam.avy.se/",
        SENTRY_DSN: null,
        AUTH: {
            MICROSOFT: {
                CLIENT_ID: "865e0e16-ff19-4ba6-a2b2-cb35417b0617",
                TOKEN_URL:
                    "https://login.microsoftonline.com/ae80b4a6-40a6-4793-8585-9177f81dd38d/oauth2/v2.0/token",
                AUTHORIZATION_URL:
                    "https://login.microsoftonline.com/ae80b4a6-40a6-4793-8585-9177f81dd38d/oauth2/v2.0/authorize",
                REDIRECT_URL:
                    "https://dev-admin.avy.se/login/?callback=microsoft",
                SCOPES: ["openid", "email", "profile"],
            },
            FASTIGHETSAGARNA: {
                CLIENT_ID: "avy",
                TOKEN_URL:
                    "https://inloggning.fastighetsagarna.se/connect/token",
                AUTHORIZATION_URL:
                    "https://inloggning.fastighetsagarna.se/connect/authorize",
                REDIRECT_URL:
                    "https://dev-admin.avy.se/login/?callback=fastighetsagarna",
                SCOPES: ["openid", "profile", "roles"],
            },
        },
        CHATBOT_INSIGHTS_DASHBOARD_ID: "150",
        TENANTS_INSIGHTS_DASHBOARD_ID: "141",
    },
    local: {
        MIXPANEL_TOKEN: "7759539fcfbd1622d15240c77ed03ccd",
        MUI_X_PRO_KEY:
            "26c607008a9110b74d4267cad2703a5eTz05MTY3NSxFPTE3NDg3NjYwNzcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
        API_BASE_URL: "https://dev-api.avy.se/api",
        LEGACY_API_BASE_URL: "https://www.avydev.xyz:92/api",
        BASE_URL: "http://localhost:3001",
        FLUTTER_BASE_URL: "https://dev-devteam.avy.se/",
        SENTRY_DSN: null,
        AUTH: {
            MICROSOFT: {
                CLIENT_ID: "865e0e16-ff19-4ba6-a2b2-cb35417b0617",
                TOKEN_URL:
                    "https://login.microsoftonline.com/ae80b4a6-40a6-4793-8585-9177f81dd38d/oauth2/v2.0/token",
                AUTHORIZATION_URL:
                    "https://login.microsoftonline.com/ae80b4a6-40a6-4793-8585-9177f81dd38d/oauth2/v2.0/authorize",
                REDIRECT_URL: "http://localhost:3001/login/?callback=microsoft",
                SCOPES: ["openid", "email", "profile"],
            },
            FASTIGHETSAGARNA: {
                CLIENT_ID: "avy",
                TOKEN_URL:
                    "https://inloggning.fastighetsagarna.se/connect/token",
                AUTHORIZATION_URL:
                    "https://inloggning.fastighetsagarna.se/connect/authorize",
                REDIRECT_URL:
                    "http://localhost:3001/login/?callback=fastighetsagarna",
                SCOPES: ["openid", "profile", "roles"],
            },
        },
        CHATBOT_INSIGHTS_DASHBOARD_ID: "150",
        TENANTS_INSIGHTS_DASHBOARD_ID: "141",
    },
    development: {
        MIXPANEL_TOKEN: "7759539fcfbd1622d15240c77ed03ccd",
        MUI_X_PRO_KEY:
            "26c607008a9110b74d4267cad2703a5eTz05MTY3NSxFPTE3NDg3NjYwNzcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
        API_BASE_URL: "https://dev-api.avy.se/api",
        LEGACY_API_BASE_URL: "https://www.avydev.xyz:92/api",
        BASE_URL: "https://dev-admin.avy.se",
        FLUTTER_BASE_URL: "https://dev-devteam.avy.se/",
        SENTRY_DSN:
            "https://cbbb0c39ac3f42cd8a89b9ff338dab2f@sentry.io/1884379",
        AUTH: {
            MICROSOFT: {
                CLIENT_ID: "865e0e16-ff19-4ba6-a2b2-cb35417b0617",
                TOKEN_URL:
                    "https://login.microsoftonline.com/ae80b4a6-40a6-4793-8585-9177f81dd38d/oauth2/v2.0/token",
                AUTHORIZATION_URL:
                    "https://login.microsoftonline.com/ae80b4a6-40a6-4793-8585-9177f81dd38d/oauth2/v2.0/authorize",
                REDIRECT_URL:
                    "https://dev-admin.avy.se/login/?callback=microsoft",
                SCOPES: ["openid", "email", "profile"],
            },
            FASTIGHETSAGARNA: {
                CLIENT_ID: "avy",
                TOKEN_URL:
                    "https://inloggning.fastighetsagarna.se/connect/token",
                AUTHORIZATION_URL:
                    "https://inloggning.fastighetsagarna.se/connect/authorize",
                REDIRECT_URL:
                    "https://dev-admin.avy.se/login/?callback=fastighetsagarna",
                SCOPES: ["openid", "profile", "roles"],
            },
        },
        CHATBOT_INSIGHTS_DASHBOARD_ID: "150",
        TENANTS_INSIGHTS_DASHBOARD_ID: "141",
    },
    stage: {
        API_BASE_URL: "https://stage-api.avy.se/api",
        LEGACY_API_BASE_URL: "https://avy.se:92/api",
        BASE_URL: "https://stage-admin.avy.se",
        FLUTTER_BASE_URL: "https://stage-devteam.avy.se/",
        SENTRY_DSN:
            "https://cbbb0c39ac3f42cd8a89b9ff338dab2f@sentry.io/1884379",
        MIXPANEL_TOKEN: "f28ad96032c9107752012b5f81561dbb",
        MUI_X_PRO_KEY:
            "26c607008a9110b74d4267cad2703a5eTz05MTY3NSxFPTE3NDg3NjYwNzcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",

        AUTH: {
            MICROSOFT: {
                CLIENT_ID: "865e0e16-ff19-4ba6-a2b2-cb35417b0617",
                TOKEN_URL:
                    "https://login.microsoftonline.com/ae80b4a6-40a6-4793-8585-9177f81dd38d/oauth2/v2.0/token",
                AUTHORIZATION_URL:
                    "https://login.microsoftonline.com/ae80b4a6-40a6-4793-8585-9177f81dd38d/oauth2/v2.0/authorize",
                REDIRECT_URL:
                    "https://stage-admin.avy.se/login/?callback=microsoft",
                SCOPES: ["openid", "email", "profile"],
            },
            FASTIGHETSAGARNA: {
                CLIENT_ID: "avy",
                TOKEN_URL:
                    "https://fast-identity-test.azurewebsites.net/connect/token",
                AUTHORIZATION_URL:
                    "https://fast-identity-test.azurewebsites.net/connect/authorize",
                REDIRECT_URL:
                    "https://stage-admin.avy.se/login/?callback=fastighetsagarna",
                SCOPES: ["openid", "profile", "roles"],
            },
        },
        CHATBOT_INSIGHTS_DASHBOARD_ID: "150",
        TENANTS_INSIGHTS_DASHBOARD_ID: "141",
    },
    production: {
        API_BASE_URL: "https://api.avy.se/api",
        LEGACY_API_BASE_URL: "https://avy.se/backend/api",
        BASE_URL: "https://admin.avy.se",
        FLUTTER_BASE_URL: "https://stage-devteam.avy.se/",
        SENTRY_DSN:
            "https://5905464ac86240768219011befefcbff@sentry.io/1884377",
        MIXPANEL_TOKEN: "a84d457617959a38c88e69eac204a2da",
        MUI_X_PRO_KEY:
            "26c607008a9110b74d4267cad2703a5eTz05MTY3NSxFPTE3NDg3NjYwNzcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",

        AUTH: {
            MICROSOFT: {
                CLIENT_ID: "865e0e16-ff19-4ba6-a2b2-cb35417b0617",
                TOKEN_URL:
                    "https://login.microsoftonline.com/ae80b4a6-40a6-4793-8585-9177f81dd38d/oauth2/v2.0/token",
                AUTHORIZATION_URL:
                    "https://login.microsoftonline.com/ae80b4a6-40a6-4793-8585-9177f81dd38d/oauth2/v2.0/authorize",
                REDIRECT_URL: "https://admin.avy.se/login/?callback=microsoft",
                SCOPES: ["openid", "email", "profile"],
            },
            FASTIGHETSAGARNA: {
                CLIENT_ID: "avy",
                TOKEN_URL:
                    "https://inloggning.fastighetsagarna.se/connect/token",
                AUTHORIZATION_URL:
                    "https://inloggning.fastighetsagarna.se/connect/authorize",
                REDIRECT_URL:
                    "https://admin.avy.se/login/?callback=fastighetsagarna",
                SCOPES: ["openid", "profile", "roles"],
            },
        },
        CHATBOT_INSIGHTS_DASHBOARD_ID: "150",
        TENANTS_INSIGHTS_DASHBOARD_ID: "141",
    },
}

export const variables: IVariables =
    environments[getEnvironment(window.location.href)]

export const environment = getEnvironment(window.location.href)

export const isNodeEnvironment = (
    ...environments: typeof process.env.NODE_ENV[]
) => {
    return environments.includes(process.env.NODE_ENV)
}
