import { Box } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { HighlightedQuery } from "src/components/HighlightedQuery"
import { ListItemBreadcrumb } from "src/components/SegmentPicker/components/ListItemBreadcrumb"
import { SelectionStatusChip } from "src/components/SegmentPicker/components/SelectionStatusChip"
import { SegmentTypeIcon } from "src/components/SegmentPicker/components/SegmentTypeIcon"

import { SegmentPickerStore } from "src/components/SegmentPicker/store"
import { useStore } from "src/store/lib/useStore"
import {
    ListItemInnerSearch,
    ListItemTextSearch,
    SegmentNameButton,
    SegmentNameStatic,
} from "src/components/SegmentPicker/components/SegmentPickerSearchListItem/styled"
import { ISegmentPickerSearchListItemProps } from "src/components/SegmentPicker/components/SegmentPickerSearchListItem/types"
import { SegmentItemListPrefix } from "src/components/SegmentPicker/components/SegmentItemListPrefix"

export const SegmentPickerSearchListItem = observer(
    (props: ISegmentPickerSearchListItemProps) => {
        const store = useStore(SegmentPickerStore)

        const { segment } = props

        const handleParentClick = useCallback(async () => {
            store.setParent(segment)
            await store.availableSegments.loadInitialPage()
        }, [store, segment])

        return (
            <ListItemInnerSearch
                secondaryAction={
                    <SelectionStatusChip
                        selected={store.hasSelectedSegment(segment)}
                        hasSelectedAncestors={store.hasSelectedAncestorSegment(
                            segment,
                        )}
                        tenantCount={segment.tenantCount}
                    />
                }
            >
                <SegmentItemListPrefix segment={segment} />
                <Box
                    sx={{
                        margin: "0 8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <SegmentTypeIcon type={segment.type} size={20} />
                </Box>
                <ListItemTextSearch
                    primary={
                        store.hasChildSegmentType(segment) ? (
                            <SegmentNameButton
                                title={segment.name}
                                onClick={handleParentClick}
                            >
                                <HighlightedQuery
                                    text={segment.name}
                                    query={store.query}
                                />
                            </SegmentNameButton>
                        ) : (
                            <SegmentNameStatic>
                                <HighlightedQuery
                                    text={segment.name}
                                    query={store.query}
                                />
                            </SegmentNameStatic>
                        )
                    }
                    secondary={<ListItemBreadcrumb segment={segment} />}
                />
            </ListItemInnerSearch>
        )
    },
)
