import { Toolbar, Stack, IconButton, useTheme } from "@mui/material"
import { observer } from "mobx-react"

import {
    LargeScreenDrawer,
    SmallScreenDrawer,
} from "src/layouts/DefaultLayout/styled"

import { NavigationList } from "src/layouts/DefaultLayout/Navigation/List"

import { Logo } from "src/layouts/DefaultLayout/Navigation/Logo"
import { Icon } from "src/components/icons"
import { HamburgerClose32 } from "src/components/icons/HamburgerClose32"
import { ErrorBoundary } from "src/components/ErrorBoundary"

interface IProps {
    drawerOpen: boolean
    onDrawerClose: () => void
}

export const Navigation = observer((props: IProps) => {
    const theme = useTheme()
    return (
        <>
            <LargeScreenDrawer variant="permanent" open={true}>
                <ErrorBoundary>
                    <NavigationList />
                </ErrorBoundary>
            </LargeScreenDrawer>
            <SmallScreenDrawer
                variant="temporary"
                open={props.drawerOpen}
                onClose={props.onDrawerClose}
            >
                <Toolbar
                    component={Stack}
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Logo />
                    <IconButton onClick={props.onDrawerClose}>
                        <Icon
                            icon={<HamburgerClose32 />}
                            size={32}
                            color={theme.palette.grey[900]}
                        />
                    </IconButton>
                </Toolbar>
                <ErrorBoundary>
                    <NavigationList />
                </ErrorBoundary>
            </SmallScreenDrawer>
        </>
    )
})
