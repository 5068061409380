import { styled, IconButton } from "@mui/material"

const StyledIconButton = styled(IconButton)(() => ({
    fontSize: "0.875rem",
    fontWeight: 500,
    paddingLeft: 0,
    paddingRight: 1,
    backgroundColor: "transparent",
    ":hover": {
        backgroundColor: "transparent",
    },
}))

const MessageBubble = styled("div")(({ theme }) => ({
    width: "auto",
    minWidth: 0,
    fontSize: "14px",
    color: theme.palette.grey[900],
    lineHeight: 1.43,
    letterSpacing: "0.17px",
}))

const IconContainer = styled("span")(() => ({
    paddingTop: "5px",
}))

export { StyledIconButton, IconContainer, MessageBubble }
