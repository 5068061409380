import { IIconProps } from "src/components/icons"

export const Bin24 = (props: IIconProps) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 9.48975H6.00014C5.77923 9.48975 5.60014 9.67645 5.60014 9.90675V20.3319C5.60014 21.2531 6.31648 21.9999 7.20013 21.9999H16.8C17.6837 21.9999 18.4 21.2531 18.4 20.3319V9.90675C18.4 9.67645 18.2209 9.48975 18 9.48975ZM10.6001 19.0809C10.6001 19.4263 10.3315 19.7064 10.0001 19.7064C9.66873 19.7064 9.40011 19.4263 9.40011 19.0809V11.8875C9.40011 11.5421 9.66873 11.262 10.0001 11.262C10.3315 11.262 10.6001 11.5421 10.6001 11.8875V19.0809ZM14.0001 19.7064C14.3314 19.7064 14.6001 19.4263 14.6001 19.0809V11.8875C14.6001 11.5421 14.3314 11.262 14.0001 11.262C13.6687 11.262 13.4001 11.5421 13.4001 11.8875V19.0809C13.4001 19.4263 13.6687 19.7064 14.0001 19.7064Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.84 5.14465C16.982 5.23142 18.0994 5.53409 19.1375 6.03787C19.6529 6.28975 19.9869 6.82413 19.9999 7.41733C19.9934 7.87305 19.6371 8.23888 19.1999 8.23883H4.80007C4.58594 8.23885 4.38075 8.14938 4.23039 7.99044C4.08004 7.8315 3.99708 7.61635 4.00008 7.39314C4.03586 6.77934 4.40181 6.23954 4.94487 5.99951C5.96 5.52543 7.04558 5.23629 8.15444 5.14465C8.5874 3.29861 10.1743 2 11.9972 2C13.8201 2 15.407 3.29861 15.84 5.14465ZM14.08 4.97701C13.678 4.15852 12.8635 3.65061 11.9816 3.66845C11.1103 3.65536 10.3089 4.16404 9.92002 4.97701C11.3055 4.89281 12.6945 4.89281 14.08 4.97701Z"
            fill="currentColor"
        />
    </svg>
)
