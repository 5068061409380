import { useEffect, useState } from "react"
import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { Button, styled } from "@mui/material"

import { OrdersIndividualAdditionsStore, IOrder } from "./store"

import { ProductCatalogueAdminService } from "src/api"
import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { ListPage } from "src/components/ListPage"
import { Table } from "src/components/Table"
import { Pdf24 } from "src/components/icons/Pdf24"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { autoDownloadFile } from "src/lib/file"
import { formatDateTime } from "src/lib/date"
import { reportUnhandledApiError } from "src/lib/report"
import { IPageFilterProps } from "src/components/PageFilter"

const ActionButton = styled(Button)(({ theme }) => ({
    background: theme.palette.common.white,
}))

const View = observer(() => {
    const vstore = useStore(OrdersIndividualAdditionsStore)
    const gstore = useStore(GlobalStore)
    const [isDownloading, setIsDownloading] = useState(false)

    const header = {
        header: t`individual-additions-view.header`,
        breadcrumbs: [t`individual-additions-view.header`],
    }

    const tableIsLoading =
        !vstore.individualAdditions.meta.initialized ||
        gstore.loading.is(OrdersIndividualAdditionsStore.LoadingKeys.pageLoad)

    const downloadOrders = async () => {
        setIsDownloading(true)

        try {
            const response =
                await ProductCatalogueAdminService.getV1AdminCatalogueImprovementOrderExport()

            const blob = new Blob([response], { type: "text/csv" })
            const url = URL.createObjectURL(blob)

            autoDownloadFile(
                url,
                `AVY-individual-additions-orders-${new Date().toDateString()}.csv`,
                "export-orders-link",
            )

            URL.revokeObjectURL(url)
        } catch (e) {
            reportUnhandledApiError(e)
        } finally {
            setIsDownloading(false)
        }
    }

    useEffect(() => {
        ;(async () => {
            await vstore.init()
        })()
    }, [vstore])

    const filter: IPageFilterProps = {
        actions: (
            <ActionButton
                onClick={downloadOrders}
                disabled={tableIsLoading || isDownloading}
                color="info"
                variant="outlined"
                sx={{
                    width: {
                        sm: "fit-content",
                    },
                }}
                data-testid="export-orders-button"
            >
                {t`orders-view.export-orders`}
            </ActionButton>
        ),
    }

    return (
        <ListPage header={header} filter={filter} loading={tableIsLoading}>
            <Table
                paginator={vstore.individualAdditions}
                headers={[
                    {
                        key: "orderId",
                        name: t`individual-additions-view.table-header-order-id`,
                    },
                    {
                        key: "totalSignaturesCount",
                        name: t`individual-additions-view.table-header-status`,
                    },
                    {
                        key: "lastUpdated",
                        name: t`individual-additions-view.table-header-last-updated`,
                    },
                    {
                        key: "provider",
                        name: t`individual-additions-view.table-header-provider`,
                    },
                    {
                        key: "objectId",
                        name: t`individual-additions-view.table-header-object-id`,
                    },
                    {
                        key: "adminName",
                        name: t`individual-additions-view.table-header-product`,
                    },
                    {
                        key: "pdfUrl",
                        name: "",
                    },
                ]}
                rowRenderer={rowRenderer}
            />
        </ListPage>
    )
})

const rowRenderer = (order: IOrder) => ({
    orderId: order.orderId,
    totalSignaturesCount: `${order.signedCount}/${
        order.totalSignaturesCount
    } ${t`individual-additions-view.table-status-cell-signed`}`,
    lastUpdated: order.lastUpdated != null && formatDateTime(order.lastUpdated),
    provider: order.provider,
    objectId: order.objectId,
    adminName: order.adminName,
    pdfUrl: order.pdfUrl != null && (
        <PdfLink target={"_blank"} href={order.pdfUrl}>
            <Pdf24 />
        </PdfLink>
    ),
})

const PdfLink = styled("a")(() => ({
    display: "block",
    fontSize: "0",
}))

export const OrdersIndividualAdditionsView = () => (
    <StoreProvider Store={OrdersIndividualAdditionsStore}>
        <View />
    </StoreProvider>
)
