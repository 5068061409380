/* eslint-disable import/no-relative-parent-imports */
import { Button } from "@mui/material"
import { t } from "@lingui/macro"
import { observer } from "mobx-react"

import { FooterContainer } from "../styled"

import { IWindowModalBottomViewProps } from "./types"

import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"

export const WindowModalBottomView = observer(
    (props: IWindowModalBottomViewProps) => {
        const globalStore = useStore(GlobalStore)
        return (
            <FooterContainer>
                <>
                    <Button
                        sx={{ marginTop: "14px" }}
                        onClick={() => globalStore.modals.pop()}
                        disabled={false}
                        color="info"
                    >
                        {t`global.cancel`}
                    </Button>
                    <Button
                        sx={{ marginTop: "14px" }}
                        onClick={props.onClick}
                        disabled={false}
                        color="info"
                    >
                        {t`global.save`}
                    </Button>
                </>
            </FooterContainer>
        )
    },
)
