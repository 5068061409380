import { t } from "@lingui/macro"
import { Grid, IconButton } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { ImagePreview } from "src/components/AttachmentsField/ImageAttachments/ImagePreview"
import { ImagePreviewHoverOptions } from "src/components/AttachmentsField/ImageAttachments/ImagePreviewHoverOptions"
import { UploadImageField } from "src/components/AttachmentsField/ImageAttachments/UploadImageField"
import { Bin24 } from "src/components/icons/Bin24"
import { View24 } from "src/components/icons/View24"
import { range } from "src/lib/array"

interface IProps {
    value: IFile[]
    onChange: (images: IFile[]) => void
    max: number
    readOnly?: boolean
}

export const ImageAttachments = observer((props: IProps) => {
    const handleChange = useCallback(
        (files: IFile[]) => {
            props.onChange([...props.value, ...files])
        },
        [props],
    )

    const removeAtIndex = useCallback(
        (index: number) => {
            const value = props.value.slice(0)
            value.splice(index, 1)
            props.onChange(value)
        },
        [props],
    )
    return (
        <Grid container spacing={2}>
            {props.value.slice(0, props.max).map((image, i) => (
                <Grid key={image.url} item xs={4}>
                    <ImagePreview url={image.url}>
                        <ImagePreviewHoverOptions>
                            <IconButton
                                href={image.url}
                                target="_blank"
                                color="inherit"
                                title={t`attachments-field-component.image-attachments.preview-title`}
                                disabled={props.readOnly}
                            >
                                <View24 />
                            </IconButton>
                            <IconButton
                                onClick={() => removeAtIndex(i)}
                                color="inherit"
                                title={t`attachments-field-component.image-attachments.remove-button`}
                                disabled={props.readOnly}
                            >
                                <Bin24 />
                            </IconButton>
                        </ImagePreviewHoverOptions>
                    </ImagePreview>
                </Grid>
            ))}
            {range(Math.max(props.max - props.value.length, 0)).map((i) => (
                <Grid key={i} item xs={4}>
                    <UploadImageField
                        onChange={handleChange}
                        disabled={props.readOnly}
                    />
                </Grid>
            ))}
        </Grid>
    )
})
