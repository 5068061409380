import { t } from "@lingui/macro"
import { Link, IconButton } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useMemo } from "react"
import { GridRowParams } from "@mui/x-data-grid-pro"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"

import { ListPage } from "src/components/ListPage"
import { DataGridProTable } from "src/components/Table/DataGridPro"
import { OmniDomainModal } from "src/modals/omni-domains"
import { OmniDomainStore } from "src/views/omni-domains/store"
import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { Repository } from "src/types/channel"
import { FilterModel, IColumn, SortModel } from "src/types/data-grid-pro"
import {
    getFilterModelForRepository,
    getSortModelForRepository,
} from "src/lib/data-grid-pro"
import { avy_api_pkg_omnidomain_OmniDomain } from "src/api/models/avy_api_pkg_omnidomain_OmniDomain"
import { ConfirmModal } from "src/modals/confirm"
import { useInitializer } from "src/lib/initializer"

const repository: Repository = "omni-domains" as Repository // Type assertion to fix the error

const View = observer(() => {
    const store = useStore(OmniDomainStore)
    const globalStore = useStore(GlobalStore)

    const initialized = useInitializer(async () => {
        await store.init()
    }, [store])

    const header = useMemo(
        () => ({
            header: t`omni-domains-view.heading`,
            breadcrumbs: [t`omni-domains-view.heading`],
            createOptions: {
                onClick: () =>
                    globalStore.modals.open(() => <OmniDomainModal />),
                item: "",
            },
            disabled: false,
        }),
        [globalStore.modals],
    )

    const handleEditItem = useCallback(
        (params: GridRowParams<avy_api_pkg_omnidomain_OmniDomain>) => {
            const item = params.row
            if (item != null) {
                globalStore.modals.open(() => (
                    <OmniDomainModal omniDomain={item} />
                ))
            }
        },
        [globalStore.modals],
    )

    const handleDeleteItem = useCallback(
        (id: number) => {
            globalStore.modals.open(
                () => (
                    <ConfirmModal
                        onConfirm={async (confirmed) => {
                            if (confirmed) {
                                await store.deleteItem(id)
                            }
                        }}
                        title={t`omni-domains-view.delete.are-you-sure`}
                        content={t`omni-domains-view.delete.do-you-want-delete`}
                    />
                ),
                {
                    variant: "slide-up-w600",
                },
            )
        },
        [globalStore.modals, store],
    )

    const handleFilterChange = useCallback(
        async (model: FilterModel) => {
            const sort = getSortModelForRepository(
                repository,
                globalStore.session.dataGridSortModel,
            )
            await store.query({ sort, filter: model })
        },
        [store, globalStore.session.dataGridSortModel],
    )

    const handleSortChange = useCallback(
        async (model: SortModel) => {
            const filter = getFilterModelForRepository(
                repository,
                globalStore.session.dataGridFilterModel,
            )
            await store.query({ filter, sort: model })
        },
        [store, globalStore.session.dataGridFilterModel],
    )

    const columns: IColumn<avy_api_pkg_omnidomain_OmniDomain>[] = useMemo(
        () => [
            {
                field: "omni_domain_id",
                headerName: "ID",
                width: 70,
            },
            {
                field: "name",
                headerName: t`omni-domains-view.name`,
                renderCell: (params) => (
                    <>
                        <Link component="button" sx={{ textAlign: "left" }}>
                            {params.value}
                        </Link>
                        <IconButton
                            size="small"
                            href={`https://${params.value}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <OpenInNewIcon fontSize="small" />
                        </IconButton>
                    </>
                ),
                type: "string",
                minWidth: 200,
                flex: 2,
            },
            {
                field: "property_owner_name",
                headerName: t`omni-domains.property-owner`,
                type: "string",
                flex: 2,
            },
            {
                field: "certificate_status",
                headerName: t`omni-domains-view.dns-record`,
                type: "string",
                flex: 1,
            },
            {
                field: "is_managed",
                headerName: t`omni-domains-view.is-managed`,
                flex: 1,
            },
            {
                field: "created_at",
                headerName: t`omni-domains-view.created-at`,
                flex: 1,
            },
        ],
        [],
    )

    return (
        <ListPage header={header} loading={!initialized}>
            <DataGridProTable
                paginator={store.omniDomains}
                data={store.omniDomains.items}
                columns={columns}
                advancedOperations={{
                    pagination: "server",
                    filtering: "server",
                    sorting: "server",
                }}
                onRowClickEvent={handleEditItem}
                rowActionsRenderer={(item) => [
                    {
                        text: t`omni-domains-view.action.delete-item`,
                        destructive: true,
                        onClick: () =>
                            handleDeleteItem(item.omni_domain_id ?? 0),
                    },
                ]}
                repository={repository}
                loading={
                    !initialized ||
                    globalStore.loading.is(
                        OmniDomainStore.LoadingKeys.deleteItem,
                    )
                }
                onFilterChange={handleFilterChange}
                onSortChange={handleSortChange}
            />
        </ListPage>
    )
})

export const OmniDomainsView = () => (
    <StoreProvider Store={OmniDomainStore}>
        <View />
    </StoreProvider>
)
