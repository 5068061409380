import { IIconProps } from "src/components/icons"

export const Pdf24 = (props: IIconProps) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.2 3H8.4C7.41 3 6.6 3.81 6.6 4.8V15.6C6.6 16.59 7.41 17.4 8.4 17.4H19.2C20.19 17.4 21 16.59 21 15.6V4.8C21 3.81 20.19 3 19.2 3ZM4.8 6.6H3V19.2C3 20.19 3.81 21 4.8 21H17.4V19.2H4.8V6.6ZM15.6 12V9.3C15.6 8.805 15.195 8.4 14.7 8.4H12.9V12.9H14.7C15.195 12.9 15.6 12.495 15.6 12ZM13.8 9.3H14.7V12H13.8V9.3ZM17.4 11.1H18.3V10.2H17.4V9.3H18.7348V8.4H16.5V12.9H17.4V11.1ZM10.2 11.1H11.1C11.595 11.1 12 10.695 12 10.2V9.3C12 8.805 11.595 8.4 11.1 8.4H9.3V12.9H10.2V11.1ZM10.2 9.3H11.1V10.2H10.2V9.3Z"
            fill="currentColor"
        />
    </svg>
)
