import React from "react"

import { Pagination } from "src/lib/pagination"
import { createLoadingKeys } from "src/lib/loading"
import { loads } from "src/channel/utils"
import { ISessionPropertyOwner } from "src/store/session"

export class FeatureConfigurationsStore {
    static Context = React.createContext<FeatureConfigurationsStore | null>(
        null,
    )
    static LoadingKeys = createLoadingKeys("init")

    private _propertyOwners: ISessionPropertyOwner[] = []

    propertyOwners = new Pagination(
        (params) => {
            const pageStart = (params.page - 1) * params.pageSize
            const pagePropertyOwners = this._propertyOwners.slice(
                pageStart,
                pageStart + params.pageSize,
            )
            return {
                items: pagePropertyOwners,
                count: this._propertyOwners.length,
                sourceItems: this._propertyOwners,
            }
        },
        { static: true },
    )

    @loads(() => FeatureConfigurationsStore.LoadingKeys.init)
    async init(propertyOwners: ISessionPropertyOwner[]) {
        this._propertyOwners = propertyOwners
        await this.propertyOwners.loadInitialPage()
    }
}
