import React from "react"
import { observer } from "mobx-react"
import { styled, TextField, useMediaQuery, useTheme } from "@mui/material"
import { DateTimePicker as MUIDateTimePicker } from "@mui/lab"
import { format } from "date-fns"

interface IProps {
    value: Date | null
    onChangePicker: (value: Date | null) => void
    label: string
    minDate?: Date | null
    maxDate?: Date | null
    error?: boolean
    helperText?: string | null
    inputId: string
    disabled?: boolean
}

const Label = styled("label")(() => ({
    marginBottom: "8px",
}))

const InputAndLabelWrapper = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
}))

export const DateTimePicker = observer(
    ({
        value,
        onChangePicker,
        label,
        minDate,
        maxDate,
        error,
        helperText,
        inputId,
        disabled,
    }: IProps) => {
        const theme = useTheme()
        const smallScreen = useMediaQuery(theme.breakpoints.down("md"))

        const isValidDate = (date: Date | never) => {
            // @ts-ignore
            return !isNaN(date)
        }

        return (
            <>
                {!smallScreen ? (
                    <InputAndLabelWrapper>
                        <Label htmlFor={inputId}>{label}</Label>
                        <TextField
                            type="datetime-local"
                            value={
                                value != null
                                    ? format(value, "yyyy-MM-dd'T'HH:mm")
                                    : ""
                            }
                            onChange={(event) => {
                                const newDate = new Date(event.target.value)
                                onChangePicker(
                                    isValidDate(newDate) ? newDate : null,
                                )
                            }}
                            error={error}
                            helperText={helperText}
                            id={inputId}
                            disabled={disabled}
                        />
                    </InputAndLabelWrapper>
                ) : (
                    <MUIDateTimePicker
                        label={label}
                        disabled={disabled}
                        onChange={(date) => onChangePicker(date)}
                        value={value}
                        maxDate={maxDate}
                        minDate={minDate}
                        renderInput={(props) => (
                            <TextField
                                {...props}
                                error={error ?? false}
                                helperText={helperText}
                            />
                        )}
                    />
                )}
            </>
        )
    },
)
