import { styled } from "@mui/material"

export const BreadcrumbDefaultButton = styled("button")(({ theme }) => ({
    fontSize: "12px",
    lineHeight: 1.4,
    color: theme.palette.grey[700],
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "inline",
    border: "none",
    background: "transparent",
    padding: 0,
    flexShrink: 0,
    "&:hover": {
        textDecoration: "underline",
        cursor: "pointer",
    },
    "&:active": {
        opacity: 0.8,
    },
    "&:last-of-type": {
        flexShrink: 1,
    },
}))

export const BreadcrumbTooltipButton = styled("button")(({ theme }) => ({
    fontSize: "10px",
    lineHeight: 1.4,
    color: theme.palette.grey[900],
    fontWeight: 500,
    wordWrap: "break-word",
    wordBreak: "break-word",
    display: "inline",
    border: "none",
    background: "transparent",
    padding: 0,
    "&:hover": {
        textDecoration: "underline",
        cursor: "pointer",
    },
    "&:active": {
        opacity: 0.8,
    },
}))

export const DefaultBreadcrumbWrapper = styled("span")({
    fontSize: "12px",
    lineHeight: 1.4,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    display: "inline-flex",
    cursor: "default",
})
