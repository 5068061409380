import { makeAutoObservable } from "mobx"
import React from "react"

import { ApiError } from "src/api"
import { loads } from "src/channel/utils"
import { IAvyChatInputFormFields } from "src/components/Chat/types/chatInputFormFields"
import { persistFiles } from "src/lib/file"
import { FormFields } from "src/lib/form-fields"

import { createLoadingKeys } from "src/lib/loading"

const MAX_IMAGES = 3

export class AvyChatInputStore {
    static Context = React.createContext<AvyChatInputStore | null>(null)
    static LoadingKeys = createLoadingKeys("submit-message")

    chatInputForm = new FormFields<IAvyChatInputFormFields>({
        images: [],
        text: "",
    })

    constructor() {
        makeAutoObservable(this)
    }

    //#region store operations

    @loads(() => AvyChatInputStore.LoadingKeys["submit-message"])
    async submitMessage(
        handleSubmit: (images: IPersistedFile[], text: string) => Promise<void>,
    ) {
        this.chatInputForm.clearErrors()

        this.chatInputForm.validateRequiredFields([{ field: "text" }])
        if (this.chatInputForm.hasErrors()) return

        await this.chatInputForm.catchErrors(async () => {
            try {
                const images = await persistFiles(
                    this.chatInputForm.get("images"),
                    "image",
                )

                await handleSubmit(images, this.chatInputForm.get("text"))
                this.resetChatInputForm()
            } catch (e) {
                // Handle a special error format only present in this ContactFormAdminService.contactFormCreateMessage API
                // endpoint, otherwise let `catchErrors` handle it.
                if (
                    e instanceof ApiError &&
                    e.status === 400 &&
                    e.body?.status != null
                ) {
                    this.chatInputForm.setError("text", e.body.status)
                    return
                }
                throw e
            }
        })
    }
    //#endregion

    //#region helpers
    private resetChatInputForm() {
        this.chatInputForm.set("images", [])
        this.chatInputForm.set("text", "")
    }

    appendImage(image: ILocalFile) {
        const images = [...this.chatInputForm.get("images")]
        this.chatInputForm.set("images", [...images, image])
    }

    deleteImageAtIndex(index: number) {
        this.chatInputForm.data.images.splice(index, 1)
    }

    canAppendImage() {
        return this.chatInputForm.get("images").length < MAX_IMAGES
    }

    canSubmitComment() {
        return this.chatInputForm.get("text").trim().length > 0
    }
    //#endregion
}
