import { memo } from "react"
import { Typography, Box } from "@mui/material"

import { IAnalysisItemProps } from "src/components/ChatMessageJourney/types"
import {
    StyledIconButton,
    IconContainer,
} from "src/components/ChatMessageJourney/sections/AnalysisSection/styled"

export const AnalysisItem = memo(({ data }: IAnalysisItemProps) => (
    <Box sx={{ marginBottom: "8px" }}>
        <IconContainer>
            <StyledIconButton>{data.source_name ?? ""}</StyledIconButton>
        </IconContainer>
        <Box
            sx={{
                backgroundColor: "background.paper",
                padding: 1,
                borderRadius: 0.5,
            }}
        >
            <Typography variant="body2">{data.reason ?? ""}</Typography>
        </Box>
    </Box>
))
