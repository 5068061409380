import { Stack, Typography, useTheme } from "@mui/material"
import { observer } from "mobx-react"

import { useCallback } from "react"

import { t } from "@lingui/macro"

import { useWindowWide } from "./useWindowWide"

import {
    CardContainer,
    EditSegmentButton,
    ItemContainer,
    SegmentContainer,
    Segments,
    Separator,
    TitleContainer,
} from "./styled"

import { FeaturesStore } from "./store"

import { GlobalStore } from "src/store"

import { SegmentPickerModal } from "src/modals/segment-picker"
import { useStore } from "src/store/lib/useStore"

import { Icon } from "src/components/icons"
import { LegalEntity16 } from "src/components/icons/LegalEntity16"
import { Property16 } from "src/components/icons/Property16"
import { PropertyOwner16 } from "src/components/icons/PropertyOwner16"

import { avy_api_pkg_featurev2_SegmentGroup } from "src/api"

interface IProps {
    id?: number
    apiName: string | undefined
    header: string | undefined
    tag: string
    description: string
    loading?: boolean
    segmentGroups: Array<avy_api_pkg_featurev2_SegmentGroup>
    linkToGuide?: string
}

export const FeatureListItem = observer((props: IProps) => {
    const theme = useTheme()
    const store = useStore(FeaturesStore)
    const currentWidth = useWindowWide(900)
    const gstore = useStore(GlobalStore)
    const segments = [
        {
            key: "propertyowner",
            name: t`feature-toggle.property-owner-segment`,
            count: props.segmentGroups.find(
                (segment) => segment.type === "propertyowner",
            )?.access_group_segment_count,
        },
        {
            key: "legalentity",
            name: t`feature-toggle.legel-entity-segment`,
            count: props.segmentGroups.find(
                (segment) => segment.type === "legalentity",
            )?.access_group_segment_count,
        },
        {
            key: "property",
            name: t`feature-toggle.property-segment`,
            count: props.segmentGroups.find(
                (segment) => segment.type === "property",
            )?.access_group_segment_count,
        },
    ]

    const renderSegmentIcon = (type: string) => {
        switch (type) {
            case "propertyowner":
                return (
                    <Icon
                        icon={<PropertyOwner16 />}
                        color={theme.palette.utility.blue.main}
                    />
                )
            case "legalentity":
                return (
                    <Icon
                        icon={<LegalEntity16 />}
                        color={theme.palette.utility.mothGreen.main}
                    />
                )
            case "property":
                return (
                    <Icon
                        icon={<Property16 />}
                        color={theme.palette.utility.rustRed.main}
                    />
                )
        }
    }

    const renderSegmentTypeItem = (
        icon: string,
        segmentTitle: string,
        count?: number,
    ) => (
        <ItemContainer>
            <TitleContainer>
                {renderSegmentIcon(icon)}
                <Typography
                    fontFamily={"Roboto"}
                    fontSize="13px"
                    marginLeft={"16px"}
                    fontWeight={"400"}
                    color="#333333"
                >
                    {segmentTitle}
                </Typography>
            </TitleContainer>
            <Typography
                fontFamily={"Roboto"}
                fontSize="12px"
                fontWeight={"700"}
                color="#333333"
            >
                {count != null ? count : 0}
            </Typography>
        </ItemContainer>
    )

    const handleSegmentChange = useCallback(
        async (segmentIds: number[]) => {
            await store.submit(segmentIds, props.id)
        },
        [store, props.id],
    )

    const handleClick = useCallback(() => {
        let allSegments: number[] = []
        props.segmentGroups.map((d: avy_api_pkg_featurev2_SegmentGroup) =>
            d.ids?.map((value: number) => allSegments.push(value)),
        )
        gstore.modals.open(
            () => (
                <SegmentPickerModal
                    initialSegmentIds={allSegments}
                    accessGroupId={gstore.session.accessGroupId}
                    allowedSegmentTypes={[
                        "propertyowner",
                        "legalentity",
                        "property",
                    ]}
                    onChange={async (segmentIds) => {
                        gstore.modals.pop()
                        await handleSegmentChange(segmentIds)
                    }}
                    onClose={() => {
                        gstore.modals.pop()
                    }}
                />
            ),
            { variant: "slide-up-w995" },
        )
    }, [
        gstore.modals,
        gstore.session,
        handleSegmentChange,
        props.segmentGroups,
    ])

    return (
        <Stack spacing={2.5}>
            <CardContainer windowWidth={currentWidth}>
                <Typography
                    fontFamily={"Roboto"}
                    fontSize="10px"
                    lineHeight={"14px"}
                    fontWeight={"500"}
                    color="rgba(0, 0, 0, 0.38)"
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    {props.apiName}
                </Typography>
                <Typography
                    fontFamily={"Roboto"}
                    component={"h6"}
                    fontSize="20px"
                    lineHeight={"32px"}
                    fontWeight={"500"}
                    color="#333333"
                    sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "1",
                        WebkitBoxOrient: "vertical",
                    }}
                >
                    {props.header}
                </Typography>
                <Typography
                    fontFamily={"Roboto"}
                    component={"caption"}
                    fontSize="12px"
                    fontWeight={"400"}
                    color="#333333"
                    height={"20px"}
                    noWrap
                >
                    {props.tag.toUpperCase()}
                </Typography>
                <Typography
                    fontFamily={"Roboto"}
                    marginTop={"20px"}
                    width={"275"}
                    height={"60px"}
                    minHeight={"60px"}
                    marginBottom={"16px"}
                    component={"h2"}
                    fontSize="14px"
                    fontWeight={"400"}
                    color="rgba(0, 0, 0, 0.6)"
                    sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "3",
                        WebkitBoxOrient: "vertical",
                    }}
                >
                    {props.description}
                </Typography>
                {props.linkToGuide != null ? (
                    <Typography
                        component={"line"}
                        fontSize="14px"
                        fontWeight={"400"}
                        color="#333333"
                        height={"60px"}
                        sx={{ textDecoration: "underline", marginTop: "16px" }}
                    >
                        {t`feature-toggle.link-to-guide-text`}
                    </Typography>
                ) : (
                    <></>
                )}
                <Separator />
                <SegmentContainer>
                    <Typography
                        fontFamily={"Roboto"}
                        fontSize="14px"
                        fontWeight={"500"}
                        color="#333333"
                    >
                        {t`feature-toggle.using-segment`}
                    </Typography>
                    <Segments>
                        {segments.map((item) => {
                            return renderSegmentTypeItem(
                                item.key,
                                item.name,
                                item.count,
                            )
                        })}
                    </Segments>
                    <EditSegmentButton
                        variant="outlined"
                        size="small"
                        onClick={handleClick}
                        data-testid="edit-segments-button"
                    >
                        {t`feature-toggle.edit-segment-button`}
                    </EditSegmentButton>
                </SegmentContainer>
            </CardContainer>
        </Stack>
    )
})
