import { observer } from "mobx-react"

import { AttachmentsField } from "src/components/AttachmentsField"
import { FormFieldSet } from "src/components/FormFieldSet"
import { ResourceDetailStore } from "src/modals/resource-detail/store"
import { useStore } from "src/store/lib/useStore"

export const AttachmentsSection = observer(() => {
    const vstore = useStore(ResourceDetailStore)

    return (
        <FormFieldSet header="">
            <AttachmentsField
                onChange={(images, documents) => {
                    vstore.fields.set("imageAttachments", images)
                    vstore.fields.set("documentAttachments", documents)
                }}
                maxImages={3}
                maxDocuments={10}
                images={vstore.fields.get("imageAttachments")}
                documents={vstore.fields.get("documentAttachments")}
                disabled={vstore.fields.get("accessType") === "READ"}
                onError={(documentsErrorMessage: string) =>
                    vstore.fields.setError(
                        "documentAttachments",
                        documentsErrorMessage,
                    )
                }
            />
        </FormFieldSet>
    )
})
