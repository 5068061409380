import { observer } from "mobx-react"
import { useCallback, useState } from "react"
import { Box, Slider as MUISlider, styled } from "@mui/material"

import { formatMinutesFromMidnight } from "src/lib/date"
import {
    dayMinuteBounds,
    transformSliderValue,
} from "src/components/WeekdayRangePicker/utils"

const StyledSlider = styled(MUISlider)(({ theme }) => ({
    // TODO: change to theme colors. The design in figma doesn't currently have
    // the correct colors. This color will be used as a placeholder color until
    // the design is updated.
    color: "#1976D2",
    height: "6px",
    "& .MuiSlider-valueLabel": {
        backgroundColor: "unset",
        color: theme.palette.grey[900],
        fontWeight: 400,
        top: "unset",
        bottom: "-8px",
        "&.MuiSlider-valueLabelOpen": {
            transform: "translateY(100%) scale(1)",
        },
        padding: 0,
    },
    "& .MuiSlider-thumb": {
        "&::before": {
            display: "none",
        },
        "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
            boxShadow: "unset",
        },
    },
}))

const Wrap = styled(Box)({
    display: "inline-block",
    height: "54px",
    width: "100%",
})

interface IProps {
    step: number
    value: number[]
    onChange: (value: number[]) => void
    disabled?: boolean
}

export const Slider = observer(({ onChange, step, ...props }: IProps) => {
    // Store the value temporarily while dragging the slider. Call the `onChange`
    // handle only on mouseup and when the value has been "committed". If we
    // were to call `onChange` on every change it would cause unnecessary
    // re-renders making the UI sluggish.
    const [temporaryValue, setTemporaryValue] = useState<number[] | null>(null)
    const currentValue = temporaryValue ?? props.value

    const handleChange = useCallback(
        (_: unknown, _value: number | number[]) => {
            if (typeof temporaryValue === "number" || temporaryValue == null) {
                throw new Error("invalid value")
            }
            setTemporaryValue(null)
            onChange(temporaryValue)
        },
        [onChange, temporaryValue],
    )

    const handleTemporaryChange = useCallback(
        (_: unknown, value: number | number[], activeThumb: number) => {
            if (typeof value === "number") {
                throw new Error("invalid value")
            }

            const nextValue = transformSliderValue(
                currentValue,
                value,
                step,
                activeThumb === 0 ? "left" : "right",
            )

            setTemporaryValue(nextValue)
        },
        [currentValue, step],
    )

    return (
        <Wrap>
            <StyledSlider
                step={5}
                value={currentValue}
                onChange={handleTemporaryChange}
                onChangeCommitted={handleChange}
                valueLabelDisplay="on"
                valueLabelFormat={formatMinutesFromMidnight}
                min={dayMinuteBounds.min}
                max={dayMinuteBounds.max}
                disabled={props.disabled}
                disableSwap
            />
        </Wrap>
    )
})
