import { makeAutoObservable } from "mobx"
import React from "react"

import { FeaturesAdminService } from "src/api"
import { loads } from "src/channel/utils"
import { createLoadingKeys } from "src/lib/loading"
import { reportUnhandledApiError } from "src/lib/report"
import { ISessionLegalEntity } from "src/store/session"

export class CopyFeatureConfigurationStore {
    static Context = React.createContext<CopyFeatureConfigurationStore | null>(
        null,
    )
    static LoadingKeys = createLoadingKeys("submit")

    legalEntities: ISessionLegalEntity[] = []

    private from: ISessionLegalEntity | null = null
    private selectedForCopy = new Set<number>()

    constructor() {
        makeAutoObservable(this)
    }

    init(from: ISessionLegalEntity, legalEntities: ISessionLegalEntity[]) {
        this.from = from
        this.legalEntities = legalEntities
    }

    toggleCopy(legalEntity: ISessionLegalEntity) {
        if (this.selectedForCopy.has(legalEntity.id)) {
            this.selectedForCopy.delete(legalEntity.id)
        } else {
            this.selectedForCopy.add(legalEntity.id)
        }
    }

    willCopy(legalEntity: ISessionLegalEntity) {
        return this.selectedForCopy.has(legalEntity.id)
    }

    hasSelected() {
        return this.selectedForCopy.size > 0
    }

    selected() {
        return Array.from(this.selectedForCopy)
    }

    @loads(() => CopyFeatureConfigurationStore.LoadingKeys.submit)
    async submit() {
        if (this.selectedForCopy.size === 0 || this.from == null) {
            return
        }

        try {
            const currentSettings =
                await FeaturesAdminService.getV1AdminFeaturesConfig({
                    legalEntityId: this.from.id,
                })
            const settingsAsRecord = Object.fromEntries(
                currentSettings.feature_config?.map((x) => [x.name, x.value]) ??
                    [],
            )

            await FeaturesAdminService.putV1AdminFeaturesConfig({
                request: {
                    feature_config: this.selected().map((id) => ({
                        legal_entity_id: id,
                        feature_config: settingsAsRecord,
                    })),
                },
            })
        } catch (e) {
            reportUnhandledApiError(e)
            throw e
        }
    }
}
