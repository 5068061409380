import { t } from "@lingui/macro"
import { useMemo } from "react"

import { IStatusChipProps, StatusChip } from "src/components/StatusChip"
import { StatusCasesV2 } from "src/types/status-casesV2"

export const CaseStatusChip = ({ status }: { status: StatusCasesV2 }) => {
    const chipProps = useMemo((): IStatusChipProps => {
        const statusMap: Record<StatusCasesV2, IStatusChipProps> = {
            [StatusCasesV2.Created]: {
                state: "negative",
                label: t`cases-view.forms-tab.status-chip-created-status`,
            },
            [StatusCasesV2.Done]: {
                state: "other",
                label: t`cases-view.forms-tab.status-chip-done-status`,
            },
            [StatusCasesV2.InProgress]: {
                state: "neutral",
                label: t`cases-view.forms-tab.status-chip-inprogress-status`,
            },
            [StatusCasesV2.ExternallyHandled]: {
                state: "other",
                label: t`cases-view.forms-tab.status-chip-externally-handled-status`,
            },
            [StatusCasesV2.Unknown]: {
                state: "info",
                label: t`cases-view.forms-tab.status-chip-unknown-status`,
            },
        }

        return (
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            statusMap[status] || {
                state: "other",
                label: t`cases-view.forms-tab.status-chip-unknown-status`,
            }
        )
    }, [status])

    return <StatusChip {...chipProps} />
}
