import { t } from "@lingui/macro"
import { IconButton } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useEffect, useRef } from "react"

import { Bin24 } from "src/components/icons/Bin24"
import { View24 } from "src/components/icons/View24"
import { ImagePreview } from "src/components/ImageUploadField/ImagePreview"
import { ImagePreviewHoverOptions } from "src/components/ImageUploadField/ImagePreviewHoverOptions"
import { UploadImageField } from "src/components/ImageUploadField/UploadImageField"

interface IProps {
    value: IFile | null
    onChange: (image: IFile | null) => void
    label?: string
    disabled?: boolean
    tooltip?: string
}

export const ImageUploadField = observer((props: IProps) => {
    const createdObjectUrls = useRef<string[]>([])

    useEffect(() => {
        const urls = createdObjectUrls.current
        return () => {
            urls.forEach((url) => URL.revokeObjectURL(url))
        }
    }, [])

    const handleChange = useCallback(
        (file: File) => {
            const url = URL.createObjectURL(file)
            createdObjectUrls.current.push(url)
            props.onChange({ file, url })
        },
        [props],
    )

    const clearValue = useCallback(() => {
        props.onChange(null)
    }, [props])

    if (props.value != null) {
        return (
            <ImagePreview
                url={props.value.url}
                label={props.label}
                tooltip={props.tooltip}
            >
                <ImagePreviewHoverOptions>
                    <IconButton
                        href={props.value.url}
                        target="_blank"
                        color="inherit"
                        title={t`image-upload-field-component.preview-title`}
                        disabled={props.disabled}
                    >
                        <View24 />
                    </IconButton>
                    <IconButton
                        onClick={clearValue}
                        color="inherit"
                        title={t`image-upload-field-component.remove-button`}
                        disabled={props.disabled}
                    >
                        <Bin24 />
                    </IconButton>
                </ImagePreviewHoverOptions>
            </ImagePreview>
        )
    } else {
        return (
            <UploadImageField
                onChange={handleChange}
                label={props.label}
                disabled={props.disabled}
                tooltip={props.tooltip}
            />
        )
    }
})
