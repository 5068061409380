import { Box, styled } from "@mui/material"

export const ConfigurableSecondaryLanguageStyledBox = styled(Box)(
    ({ theme }) => ({
        display: "flex",
        border: `1px solid ${theme.palette.grey[200]}`,
        borderRadius: "16px",
        padding: "4px 8px",
        backgroundColor: theme.palette.grey[200],
        width: "16px",
        height: "16px",
        justifyContent: "center",
        alignItems: "center",
    }),
)
