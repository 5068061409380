import { t } from "@lingui/macro"
import { Alert, Button } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { CreateSection } from "./CreateSection"
import ImageHandler from "./ImageHandler"

import { ActionList } from "src/components/ActionList"
import { CenteredSpinner } from "src/components/CenteredSpinner"
import { Form } from "src/components/Form"
import { FormPanel } from "src/components/FormPanel"
import { ModalHeader } from "src/components/ModalHeader"
import { ProductCategoriesCreateOrEditStore } from "src/modals/product-categories-create-edit/store"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { useCloseConfirmationForForm } from "src/store/modals/use-close-confirmation-for-form"
import { ICategoryData } from "src/views/product-categories"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"

interface IProps {
    categoryData?: ICategoryData
}

const ProductCategoriesCreateOrEdit = observer((props: IProps) => {
    const gstore = useStore(GlobalStore)
    const store = useStore(ProductCategoriesCreateOrEditStore)
    const navigate = useNavigate()

    useCloseConfirmationForForm(store.fields)

    useEffect(() => {
        if (props.categoryData?.categoryId != null) {
            trackModuleEvent("Products | Categories Detail View", {
                [MixpanelProperties.AccessGroupName]:
                    gstore.session.accessGroup?.name,
                [MixpanelProperties.AccessGroupID]:
                    gstore.session.accessGroup?.id,
                [MixpanelProperties.ItemID]: props.categoryData?.categoryId,
                [MixpanelProperties.ItemName]: props.categoryData?.name,
            })
        }

        ;(async () => {
            await store.init(props.categoryData)
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store, props, gstore.session.accessGroupId])

    const imageUrl = store.fields.get("imageUrl")
    const createCategory: boolean = props.categoryData === undefined

    const handleSubmit = useCallback(async () => {
        await store.submit()
        trackModuleEvent("Products | Categories Create New", {
            [MixpanelProperties.AccessGroupName]:
                gstore.session.accessGroup?.name,
            [MixpanelProperties.AccessGroupID]: gstore.session.accessGroup?.id,
        })
        if (!store.fields.hasErrors()) {
            gstore.modals.pop()
            // reload page, timeout just to not break tests
            // TODO: replace navigate with adding a second StoreProvider to the modal, so modal gets access to view store, and re fetch the updated data
            // setTimeout(() => {
            //     navigate(0)
            // }, 300)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store, gstore])

    const handleImageDelete = useCallback(() => {
        store.fields.set("imageUrl", undefined)
    }, [store.fields])

    const handleImageChange = useCallback(
        (images: IFile[]) => {
            store.image = images[0]

            store.fields.set("imageUrl", images[0].url)
        },
        [store],
    )

    const textContent = {
        nameSectionHeader: createCategory
            ? t`product-categories-create-edit.create-name-header`
            : t`product-categories-create-edit.edit-name-header`,

        nameSectionSubHeader: createCategory
            ? t`product-categories-create-edit.create-name-subheader`
            : t`product-categories-create-edit.edit-name-subheader`,
        thumbnailSectionHeader: t`product-categories-create-edit.thumbnail-image-header`,
        thumbnailSectionSubHeader: t`product-categories-create-edit.create-thumbnail-image-subheader`,
        svgIconSectionHeader: t`product-categories-create-edit.svg-icon-header`,
        svgIconSectionSubHeader: t`product-categories-create-edit.create-svg-icon-subheader`,
        listingSectionHeader: t`product-categories-create-edit.product-listing-header`,
        listingSectionSubHeader:
            store.products != null && store.products?.length > 0
                ? t`product-categories-create-edit.products-exist-product-listing-subheader`
                : t`product-categories-create-edit.no-products-product-listing-subheader`,
    }

    if (
        gstore.loading.is(ProductCategoriesCreateOrEditStore.LoadingKeys.init)
    ) {
        return <CenteredSpinner height="100vh" />
    }

    return (
        <Form onSubmit={handleSubmit}>
            <ModalHeader>
                <Button
                    data-testid="create/edit-modal-submit-button"
                    type="submit"
                    variant="contained"
                    disabled={
                        !(
                            store.fields.getIsDirty() ||
                            store.hasUpdatedProducts
                        ) ||
                        store.fields.get("name") === "" ||
                        gstore.loading.is(
                            ProductCategoriesCreateOrEditStore.LoadingKeys
                                .submit,
                        )
                    }
                >
                    {t`product-categories-create-edit.save-category`}
                </Button>
            </ModalHeader>
            {Boolean(store.fields.error("generic")) && (
                <Alert severity="error">{store.fields.error("generic")}</Alert>
            )}
            <FormPanel
                sections={[
                    {
                        header: textContent.nameSectionHeader,
                        subHeader: textContent.nameSectionSubHeader,
                        content: <CreateSection />,
                    },
                    {
                        header: textContent.thumbnailSectionHeader,
                        subHeader: textContent.thumbnailSectionSubHeader,
                        content: (
                            <ImageHandler
                                imageUrl={imageUrl}
                                handleImageDelete={handleImageDelete}
                                handleImageChange={handleImageChange}
                            />
                        ),
                    },
                ]}
            />
            {!createCategory && (
                <FormPanel
                    sections={[
                        {
                            header: textContent.listingSectionHeader,
                            subHeader: textContent.listingSectionSubHeader,
                            content: (
                                <ActionList
                                    items={store.products}
                                    rowRenderer={(item) => (
                                        <>
                                            <img
                                                src={item.imageUrl}
                                                style={{
                                                    width: "38px",
                                                    height: "38px",
                                                    objectFit: "cover",
                                                    objectPosition: "center",
                                                    marginRight: "12px",
                                                    borderRadius: "4px",
                                                }}
                                                alt={item.name}
                                            />
                                            <p>{item.name}</p>
                                        </>
                                    )}
                                    rowActionsRenderer={(product) => [
                                        {
                                            text: t`product-categories-create-edit.edit-product`,
                                            onClick: () => {
                                                navigate(
                                                    `/product-catalogue/products/${product.id}`,
                                                )
                                                gstore.modals.pop()
                                            },
                                        },
                                    ]}
                                />
                            ),
                        },
                    ]}
                />
            )}
        </Form>
    )
})

export const ProductCategoriesCreateOrEditModal = (props: IProps) => (
    <StoreProvider Store={ProductCategoriesCreateOrEditStore}>
        <ProductCategoriesCreateOrEdit {...props} />
    </StoreProvider>
)
