import { t } from "@lingui/macro"
import { observer } from "mobx-react"

import { useCallback, useEffect, useMemo } from "react"

import { EmbedStore } from "./store"

import { ListPage } from "src/components/ListPage"
import { Table } from "src/components/Table"
import { EmbeddedIntegrationsModal } from "src/modals/embedded-integration"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { avy_api_pkg_embed_Segment } from "src/api"

const View = observer(() => {
    const vstore = useStore(EmbedStore)
    const gstore = useStore(GlobalStore)

    useEffect(() => {
        ;(async () => {
            await vstore.init()
        })()

        return () => {
            vstore.dispose()
        }
    }, [vstore, gstore.session.accessGroupId, gstore.session.segmentIds])

    const handleOpenIntegrationClick = useCallback(
        (id: number, segments: avy_api_pkg_embed_Segment[]) => {
            gstore.modals.open(() => (
                <EmbeddedIntegrationsModal id={id} segments={segments} />
            ))
        },
        [gstore],
    )

    const header = useMemo(
        () => ({
            header: t`embed-integrations-view.header`,
            breadcrumbs: [t`embed-integrations-view.header`],
        }),
        [],
    )

    const tableIsLoading =
        !vstore.embedIntegrations.meta.initialized ||
        gstore.loading.is(EmbedStore.LoadingKeys.pageLoad)

    return (
        <ListPage header={header} loading={tableIsLoading}>
            <Table
                paginator={vstore.embedIntegrations}
                onRowClick={(item) =>
                    handleOpenIntegrationClick(item.id, item.segments)
                }
                headers={[
                    {
                        key: "id",
                        name: "ID",
                    },
                    {
                        key: "name",
                        name: t`embed-integrations.header`,
                    },
                    {
                        key: "type",
                        name: t`embed-integrations.type`,
                    },
                    {
                        key: "selectedSegments",
                        name: t`embed-integrations.segments`,
                    },
                ]}
                rowRenderer={(item) => ({
                    name: item.name,
                    id: item.id,
                    type: item.type ?? "",
                    selectedSegments:
                        `${String(
                            item.segments?.length,
                        )} ${t`contact-form-forms-view.published.value`}` ?? "",
                })}
            />
        </ListPage>
    )
})

export const EmbeddedIntegrationsView = () => (
    <StoreProvider Store={EmbedStore}>
        <View />
    </StoreProvider>
)
