import { observer } from "mobx-react"
import { Alert, Button, Stack, useTheme } from "@mui/material"
import { useCallback, useEffect, useMemo } from "react"
import { t } from "@lingui/macro"

import { MainContainer } from "./styled"

import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { Form } from "src/components/Form"
import { ModalHeader } from "src/components/ModalHeader"
import { StoreProvider } from "src/store/lib/StoreProvider"
import {
    CommunityPostDetailStore,
    IAuthor,
} from "src/modals/community-post-detail/store"
import { FormPanel } from "src/components/FormPanel"
import { SettingsSection } from "src/modals/community-post-detail/SettingsSection"
import { MessageSection } from "src/modals/community-post-detail/MessageSection"
import { ImageSection } from "src/modals/community-post-detail/ImageSection"
import { CenteredSpinner } from "src/components/CenteredSpinner"
import { useCloseConfirmationForForm } from "src/store/modals/use-close-confirmation-for-form"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"
import { Preview } from "src/components/icons/Preview"
import { Icon } from "src/components/icons"
import { AvyPreview } from "src/components/Preview"

interface IProps {
    id?: number
}

const View = observer((props: IProps) => {
    const gstore = useStore(GlobalStore)
    const store = useStore(CommunityPostDetailStore)
    const theme = useTheme()

    const authors: IAuthor[] = useMemo(() => {
        const authors: IAuthor[] = [
            { id: "self", name: gstore.session.user?.name ?? "" },
        ]

        if (gstore.session.hasAccessToModule("community_author_name")) {
            const propertyOwners = gstore.session.propertyOwners
            for (const propertyOwner of propertyOwners) {
                authors.push({
                    id: `po-${propertyOwner.id}`,
                    name: propertyOwner.legalName ?? "",
                })
            }

            const legalEntities = propertyOwners.flatMap(
                (propertyOwner) => propertyOwner.legalEntities ?? [],
            )

            for (const legalEntity of legalEntities) {
                authors.push({
                    id: `le-${legalEntity.id}`,
                    name: legalEntity.legalName ?? "",
                })
            }
        }

        return authors
    }, [gstore.session])

    useEffect(() => {
        ;(async () => {
            if (props.id !== null || props.id > 0) {
                trackModuleEvent("Community | Detailed View", {
                    [MixpanelProperties.CommunityName]: authors[0].name,
                    [MixpanelProperties.ItemID]: props.id,
                    [MixpanelProperties.AccessGroupName]:
                        gstore.session.accessGroup?.name,
                    [MixpanelProperties.AccessGroupID]:
                        gstore.session.accessGroup?.id,
                })
            }
            await store.init(authors, props.id)
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store, authors, props])

    useCloseConfirmationForForm(store.form)

    const onPreviewClick = () => {
        gstore.modals.togglePreview(() => (
            <AvyPreview details={store.getDetailsPreviewUrl} />
        ))
    }

    const handleSubmit = useCallback(async () => {
        trackModuleEvent("Community | Publish Post", {
            [MixpanelProperties.CommunityName]: store.getAuthorName(),
            [MixpanelProperties.ItemID]: props.id,
            [MixpanelProperties.AccessGroupName]:
                gstore.session.accessGroup?.name,
            [MixpanelProperties.AccessGroupID]: gstore.session.accessGroup?.id,
        })
        await store.submit()
        if (!store.form.hasErrors()) {
            gstore.modals.popPreview()
            gstore.modals.pop()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store, gstore.modals])

    if (
        gstore.loading.is(CommunityPostDetailStore.LoadingKeys.init) ||
        !store.initialized
    ) {
        return <CenteredSpinner />
    }
    const isSubmitDisabled =
        gstore.loading.is(CommunityPostDetailStore.LoadingKeys.submit) ||
        store.form.get("accessType") === "READ" ||
        store.form.getIsDirty() === false ||
        store.form.data.communityIds.length === 0

    const isPreviewEnabled =
        store.form.data.communityIds.length !== 0 &&
        (store.form.get("text").trim() !== "" ||
            store.form.get("communityIds").length > 0 ||
            store.form.get("images").some((image) => image !== null))

    return (
        <MainContainer>
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    <Stack direction="row" spacing={1}>
                        <Button
                            startIcon={
                                <Icon
                                    icon={<Preview />}
                                    size={20}
                                    color={theme.palette.info.main}
                                />
                            }
                            disabled={!isPreviewEnabled}
                            variant="contained"
                            color="info"
                            onClick={onPreviewClick}
                            data-testid="preview-button"
                        >
                            {t`global.preview-button`}
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={isSubmitDisabled}
                        >
                            {store.isEditing
                                ? t`community-post-detail-modal.save-button`
                                : t`community-post-detail-modal.publish-button`}
                        </Button>
                    </Stack>
                </ModalHeader>
                {Boolean(store.form.error("generic")) && (
                    <Alert severity="error">
                        {store.form.error("generic")}
                    </Alert>
                )}
                <FormPanel
                    sections={[
                        {
                            header: t`community-post-detail-modal.settings-section.header`,
                            content: <SettingsSection />,
                        },
                    ]}
                />
                <FormPanel
                    sections={[
                        {
                            header: t`community-post-detail-modal.message-section.header`,
                            subHeader: t`community-post-detail-modal.message-section.sub-header`,
                            content: <MessageSection />,
                        },
                        {
                            header: t`community-post-detail-modal.images-section.header`,
                            subHeader: t`community-post-detail-modal.images-section.sub-header`,
                            content: <ImageSection />,
                        },
                    ]}
                />
            </Form>
        </MainContainer>
    )
})

export const CommunityPostDetailModal = observer((props: IProps) => {
    return (
        <StoreProvider Store={CommunityPostDetailStore}>
            <View {...props} />
        </StoreProvider>
    )
})
