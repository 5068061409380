import { debounce } from "@mui/material"
import { makeAutoObservable } from "mobx"

import { loadsWrap } from "src/channel/utils"
import { updateMatchingItem } from "src/lib/array"
import { convertAdvanceQueryToApiPayload } from "src/lib/data-grid-pro"
import { uniqueId } from "src/lib/unique-id"
import {
    IAdvanceQueryModel,
    IAdvanceQueryModelApi,
    TPaginationModel,
} from "src/types/data-grid-pro"

interface IPaginationQuery {
    pageSize: number
    page: number
    search: string | null
    advanceQuery: IAdvanceQueryModelApi | undefined
}

type IStaticItemFunction<TResult> = (query: IPaginationQuery) => {
    items: TResult[]
    sourceItems: TResult[]
    count: number
}

type INonStaticItemFunction<TResult, TRawResult> = (
    query: IPaginationQuery,
) => Promise<{ items: TResult[]; count: number; rawItems?: TRawResult[] }>

/**
 * Pagination unifies pagination of several kinds into one interface. It's
 * meant to be generic and to be used anywhere you need to keep track of
 * paginated items.
 */
export class Pagination<TResult, TRawResult> {
    private DEBOUNCE_MS = 500
    private DEFAULT_QUERY: IPaginationQuery = {
        page: 0,
        pageSize: 100,
        search: null,
        advanceQuery: undefined,
    }
    private _items: TResult[] = []
    private _rawItems: TRawResult[] = []
    private _sourceItems: TResult[] = []
    private count: number | null = null
    private initialized = false
    private query: IPaginationQuery = { ...this.DEFAULT_QUERY }
    private debouncedLoad: () => Promise<void>
    private loadingKey: symbol
    private selectedPageSize =
        window.localStorage.getItem("selectedPageSize") !== null
            ? Number(window.localStorage.getItem("selectedPageSize"))
            : 0

    get items() {
        return this._items
    }

    get rawItems() {
        return this._rawItems
    }

    get sourceItems() {
        if (this.options?.static !== true) {
            // eslint-disable-next-line no-console
            console.error(
                "Cannot fetch source items if non-static paginators. Add `static: true` to options to enable source items.",
            )
            return []
        }

        return this._sourceItems
    }

    get meta() {
        return {
            ...this.query,
            count: this.count,
            initialized: this.initialized,
        }
    }

    constructor(
        fn: IStaticItemFunction<TResult>,
        options?: {
            /**
             * Static paginators already have all items to be paginated stored
             * locally. `Pagination` just provides an interface to move between
             * pages and filter the items.
             */
            static: true
        },
    )
    constructor(
        fn: INonStaticItemFunction<TResult, TRawResult>,
        options?: {
            loadingKey?: symbol
        },
    )
    constructor(
        private fn:
            | IStaticItemFunction<TResult>
            | INonStaticItemFunction<TResult, TRawResult>,
        private options?: {
            static: boolean
            loadingKey?: symbol
        },
    ) {
        makeAutoObservable(this)

        if (options?.static === true) {
            this.debouncedLoad = () => this.rawLoad()
        } else {
            this.debouncedLoad = debounce(
                async () => await this.rawLoad(),
                this.DEBOUNCE_MS,
            )
        }

        if (options?.loadingKey != null) {
            this.loadingKey = options.loadingKey
        } else {
            // Add a unique loading key if none is provided to make sure one is
            // always set.
            this.loadingKey = Symbol(`Pagination/${uniqueId()}`)
        }
    }

    setPageSize(pageSize: number) {
        window.localStorage.setItem("selectedPageSize", String(pageSize))
        this.query.pageSize = pageSize
        this.query.page = this.DEFAULT_QUERY.page
    }

    /*
     ** data grid pro works on pagination model, i.e., a combination of page size and page change, so we don't have to reset page on every page size change
     */
    setPageSizeOnly(pageSize: number) {
        window.localStorage.setItem("selectedPageSize", String(pageSize))
        this.query.pageSize = pageSize
    }

    setPage(page: number) {
        this.query.page = page
    }

    setSearch(search: string | null) {
        this.query.search = search
    }

    async reload() {
        await this.load()
    }

    async loadInitialPage(advanceQuery?: IAdvanceQueryModel) {
        this.setPage(this.DEFAULT_QUERY.page)
        this.setPageSize(
            this.selectedPageSize > 0
                ? this.selectedPageSize
                : this.DEFAULT_QUERY.pageSize,
        )
        this.setAdvanceQuery(advanceQuery)
        await this.load()
    }
    /**
     * To get list all data for list so as to implement local pagination and filter.
     */
    async loadAllPage() {
        this.setPage(this.DEFAULT_QUERY.page)
        this.setPageSize(this.DEFAULT_QUERY.pageSize)
        await this.load(9999)
    }

    async loadPage(page: number) {
        this.setPage(page)
        await this.load()
    }

    async loadPageSize(pageSize: number) {
        this.setPageSize(pageSize)
        await this.load()
    }

    async loadPaginationModel(model: TPaginationModel) {
        this.setPageSizeOnly(model.pageSize)
        this.setPage(model.page)
        await this.load()
    }

    async loadSearch(search: string | null) {
        this.setSearch(search)
        this.setPage(this.DEFAULT_QUERY.page)
        this.setPageSize(this.DEFAULT_QUERY.pageSize)
        await this.debouncedLoad()
    }

    async loadAdvanceQuery(query: IAdvanceQueryModel) {
        this.setAdvanceQuery(query)
        await this.load()
    }

    updateItem(match: Partial<TResult>, update: (item: TResult) => TResult) {
        this._items = updateMatchingItem(this._items, match, update)
    }

    private load = async (page?: number) => await this.rawLoad(page)

    private rawLoad(page?: number) {
        return loadsWrap(this.loadingKey, async () => {
            try {
                const result = await this.fn({
                    // this.query.page starts at zero, but most apis starts at one.
                    page: this.query.page + 1,
                    pageSize:
                        page !== null && page !== undefined
                            ? page
                            : this.query.pageSize,
                    search: this.query.search,
                    advanceQuery: this.query.advanceQuery,
                })
                this.setItems(result.items)
                this.setCount(result.count)

                if ("sourceItems" in result) {
                    this.setSourceItems(result.sourceItems)
                }

                if ("rawItems" in result && Array.isArray(result.rawItems)) {
                    this.setRawItems(result.rawItems)
                }
            } finally {
                this.setInitialized()
            }
        })
    }

    private setItems(items: TResult[]) {
        this._items = items
    }

    private setRawItems(items: TRawResult[]) {
        this._rawItems = items
    }

    private setSourceItems(sourceItems: TResult[]) {
        this._sourceItems = sourceItems
    }

    private setCount(count: number) {
        this.count = count
    }

    private setInitialized() {
        this.initialized = true
    }

    private setAdvanceQuery(query?: IAdvanceQueryModel) {
        this.query.advanceQuery = convertAdvanceQueryToApiPayload(query)
    }
}
