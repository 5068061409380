import { t } from "@lingui/macro"
import { useCallback } from "react"
import { Stack, useTheme } from "@mui/material"
import { observer } from "mobx-react"

import { AccessGroup } from "src/components/ModalAccessGroup/AccessGroup"
import { FormFields } from "src/modals/content-items/components/formFields"
import { useStore } from "src/store/lib/useStore"
import { content_ContentItemEntity } from "src/api"
import { IContentTypes } from "src/modals/content-items/types/settingsSectionProps"
import { AutoCompleteTextField } from "src/components/AutoCompleteTextField"
import { GlobalStore } from "src/store"
import { ContentItemsStore } from "src/views/content-items/store"
import { ContentItemsModalStore } from "src/modals/content-items/store"
import { ConfigType } from "src/types/dynamic-configurable-form-fields/config-type"

const contentTypes: IContentTypes[] = [
    { id: "module", label: "Module" },
    { id: "embed", label: "Embed" },
    { id: "integration_connector", label: "Integration" },
    { id: "subscription_plan", label: "Subscription Plan" },
    { id: "catalogue_product", label: "Catalogue Product" },
    { id: "information_item", label: "Information Item" },
]

export const SettingsSection = observer(() => {
    const { palette } = useTheme()
    const globalStore = useStore(GlobalStore)
    const contentItemsStore = useStore(ContentItemsModalStore)

    const handleSelectOnChange = useCallback(
        (newValue) => {
            contentItemsStore.form.set("location", newValue)
        },
        [contentItemsStore.form],
    )

    return (
        <Stack spacing={2}>
            <AccessGroup
                accessGroupId={contentItemsStore.accessGroupId as number}
                errorMessage={
                    contentItemsStore.form.error("accessGroupID") ?? ""
                }
                disabled={contentItemsStore.isEditMode}
            />
            <FormFields.ContentItemsTextInput
                label={t`navigation-items-view.setting-section.internal-name`}
                name="internalName"
                rowLength={1}
                disabled={contentItemsStore.isReadOnly}
                maxLength={255}
                value={contentItemsStore.form.get("internalName")}
                helperText={contentItemsStore.form.error("internalName") ?? ""}
                handleTextInputChange={(event) => {
                    contentItemsStore.form.set(
                        "internalName",
                        event.target.value,
                    )
                }}
            />
            <FormFields.ContentItemsDropdown
                optionArray={contentTypes}
                selectedValue={contentItemsStore.form.get("entity")}
                onSelectionChange={(value) => {
                    contentItemsStore.form.set(
                        "entity",
                        `${value as content_ContentItemEntity}`,
                    )
                    contentItemsStore.form.set("entityID", 0)
                    void contentItemsStore.featureTargetList(
                        contentItemsStore.accessGroupId,
                    )
                }}
                errorMessage={contentItemsStore.form.error("entity") ?? ""}
                disabled={contentItemsStore.isReadOnly}
                label={t`navigation-items-view.setting-section.content-type`}
            />
            {contentItemsStore.form.get("entity") !== undefined && (
                <FormFields.ContentItemsDropdown
                    optionArray={contentItemsStore.featureTargetLists}
                    selectedValue={contentItemsStore.form.get("entityID")}
                    onSelectionChange={(value) =>
                        contentItemsStore.form.set("entityID", Number(value))
                    }
                    errorMessage={
                        Boolean(contentItemsStore.form.get("entityID"))
                            ? contentItemsStore.form.error("entityID") ?? ""
                            : t`errors.required`
                    }
                    disabled={contentItemsStore.isReadOnly}
                    label={t`navigation-items-view.setting-section.target-feature`}
                />
            )}
            <AutoCompleteTextField
                disableCloseOnSelect
                multiSelect={
                    contentItemsStore.configType === ConfigType.Multiple
                }
                options={contentItemsStore.locationOptions}
                defaultValues={contentItemsStore.form.get("location")}
                placeholder={t`content-items-modal-location-filter-placeholder`}
                onChange={handleSelectOnChange}
                loading={
                    globalStore.loading.is(
                        ContentItemsStore.LoadingKeys.init,
                    ) ||
                    globalStore.loading.is(
                        ContentItemsStore.LoadingKeys.loading,
                    )
                }
                sx={{
                    backgroundColor: palette.common.white,
                }}
                error={Boolean(contentItemsStore.form.error("location"))}
                helperText={contentItemsStore.form.error("location") ?? ""}
            />
        </Stack>
    )
})
