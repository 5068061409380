import { t } from "@lingui/macro"
import { Fab, Stack } from "@mui/material"
import { observer } from "mobx-react"
import { Container, Draggable, DropResult } from "react-smooth-dnd"
import { v4 as uuidv4 } from "uuid"

import { useStore } from "src/store/lib/useStore"
import { Add24 } from "src/components/icons/Add24"
import { OutputType } from "src/types/output"
import { IFormFieldsSectionProps } from "src/modals/contact-forms-form-builder/types/formFieldsSection"
import { FormDetailStore } from "src/modals/contact-forms-form-builder/store"
import {
    initialExternalFieldsState,
    initialInternalFieldsState,
} from "src/modals/contact-forms-form-builder/constants/initialFieldState"
import {
    IDynamicFieldsListItemProps,
    IExternalDynamicFieldsListItemProps,
} from "src/modals/contact-forms-form-builder/types/fieldsListItem"
import {
    DraggableContainer,
    FabButtonContainer,
} from "src/modals/contact-forms-form-builder/styled"
import { ExternalDynamicFieldsSection } from "src/modals/contact-forms-form-builder/DynamicViewComponents/ExternalDynamicFieldsSection"
import { DynamicNativeFieldsSection } from "src/modals/contact-forms-form-builder/DynamicViewComponents/DynamicNativeFields"

export const FormFieldsSection = observer((props: IFormFieldsSectionProps) => {
    const store = useStore(FormDetailStore)
    const outputSelectedValue = store.formFields.get("outputTypeSelectedValue")
    const IntegrationConnectorId =
        store.formFields.get("selectedConnectorId") ?? 0

    const FieldsListArray =
        outputSelectedValue === OutputType.Integration
            ? props.externalDynamicFieldsList
            : props.dynamicFieldsList

    const handleRemoveClick = (index: number) => {
        store.setHasError(false)
        let list = []
        if (outputSelectedValue === OutputType.Integration) {
            list = [...props.externalDynamicFieldsList]
            list.splice(index, 1)
            props.setExternalDynamicFieldsList(list)
        } else {
            list = [...props.dynamicFieldsList]
            list.splice(index, 1)
            props.setDynamicFieldsList(list)
        }
    }

    const handleAddClick = () => {
        if (outputSelectedValue === OutputType.Integration) {
            props.setExternalDynamicFieldsList([
                ...props.externalDynamicFieldsList,
                {
                    ...initialExternalFieldsState,
                    uuid: uuidv4(),
                },
            ])
        } else {
            props.setDynamicFieldsList([
                ...props.dynamicFieldsList,
                {
                    ...initialInternalFieldsState,
                    uuid: uuidv4(),
                },
            ])
        }
    }
    const handleSwitchChange = (index: number) => {
        let list = []
        if (outputSelectedValue === OutputType.Integration) {
            list = [...props.externalDynamicFieldsList]
            list[index].isRequired = !list[index].isRequired
            props.setExternalDynamicFieldsList(list)
        } else {
            list = [...props.dynamicFieldsList]
            list[index].isRequired = !list[index].isRequired
            props.setDynamicFieldsList(list)
        }
    }

    const handleContentCopyClick = (index: number) => {
        let list = []
        if (outputSelectedValue === OutputType.Integration) {
            list = [...props.externalDynamicFieldsList]
            const obj = JSON.parse(JSON.stringify(list[index]))
            obj.uuid = uuidv4()
            list.splice(list.length, 0, obj)
            props.setExternalDynamicFieldsList(list)
        } else {
            list = [...props.dynamicFieldsList]
            list.splice(list.length, 0, list[index])
            props.setDynamicFieldsList(list)
        }
    }

    const sortListFromResult = (dropResult: IDropResult) => {
        let items = []
        if (outputSelectedValue === OutputType.Integration) {
            items = JSON.parse(JSON.stringify(props.externalDynamicFieldsList))

            const item = items[dropResult.removedIndex]

            items.splice(dropResult.removedIndex, 1)
            items.splice(dropResult.addedIndex, 0, item)

            const list = items.map(
                (item: IExternalDynamicFieldsListItemProps) => ({
                    ...item,
                }),
            )
            props.setExternalDynamicFieldsList(list)
        } else {
            items = JSON.parse(JSON.stringify(props.dynamicFieldsList))

            const item = items[dropResult.removedIndex]

            items.splice(dropResult.removedIndex, 1)
            items.splice(dropResult.addedIndex, 0, item)

            const list = items.map((item: IDynamicFieldsListItemProps) => ({
                ...item,
            }))
            props.setDynamicFieldsList(list)
        }
    }
    const onDrop = (dropResult: DropResult) => {
        if (
            dropResult.removedIndex == null ||
            dropResult.addedIndex == null ||
            dropResult.addedIndex === dropResult.removedIndex
        ) {
            return
        }

        sortListFromResult({
            removedIndex: dropResult.removedIndex,
            addedIndex: dropResult.addedIndex,
        })
    }
    return (
        <Stack spacing={2}>
            <Container onDrop={onDrop}>
                {FieldsListArray.map(
                    (item, index) =>
                        item.uuid.trim().length > 0 && (
                            <Draggable key={index}>
                                <DraggableContainer>
                                    {outputSelectedValue ===
                                        OutputType.Integration &&
                                    IntegrationConnectorId > 0 ? (
                                        <ExternalDynamicFieldsSection
                                            isRequired={item.isRequired}
                                            inputList={item.inputList ?? []}
                                            externalDynamicFieldsList={
                                                props.externalDynamicFieldsList
                                            }
                                            setExternalDynamicFieldsList={
                                                props.setExternalDynamicFieldsList
                                            }
                                            index={index}
                                            handleRemoveClick={
                                                handleRemoveClick
                                            }
                                            handleSwitchChange={
                                                handleSwitchChange
                                            }
                                            handleContentCopyClick={
                                                handleContentCopyClick
                                            }
                                        />
                                    ) : (
                                        <DynamicNativeFieldsSection
                                            isRequired={item.isRequired}
                                            inputList={item.inputList ?? []}
                                            dynamicFieldsList={
                                                props.dynamicFieldsList
                                            }
                                            setDynamicFieldsList={
                                                props.setDynamicFieldsList
                                            }
                                            index={index}
                                            handleRemoveClick={
                                                handleRemoveClick
                                            }
                                            handleSwitchChange={
                                                handleSwitchChange
                                            }
                                            handleContentCopyClick={
                                                handleContentCopyClick
                                            }
                                        />
                                    )}
                                </DraggableContainer>
                            </Draggable>
                        ),
                )}
            </Container>
            <FabButtonContainer>
                <Fab
                    variant="extended"
                    color="primary"
                    size="medium"
                    disabled={store.isEditMode}
                    onClick={handleAddClick}
                >
                    <Add24 />
                    {t`contact-form-forms-detail-modal.form-field-section.add-field-label`}
                </Fab>
            </FabButtonContainer>
        </Stack>
    )
})
