import { initialState } from "src/modals/embedded-integration-v2/constants/initialState"

export const initialGeneralDynamicFieldsState = {
    categoryDropdownValue: { ...initialState },
    categoryFields: [
        {
            description: "",
            label: "",
            required: true,
            type: "",
            errorMessage: "",
        },
    ],
}
