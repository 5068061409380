import React from "react"

import { Channel } from "src/channel"
import { createLoadingKeys } from "src/lib/loading"
import { loads } from "src/channel/utils"
import { AccessGroupsAdminService } from "src/api"
import { FormFields } from "src/lib/form-fields"

interface IFormFields {
    name: string
    segmentIds: number[]
    id?: number
}
export class AccessGroupsCreateOrEditStore {
    static Context = React.createContext<AccessGroupsCreateOrEditStore | null>(
        null,
    )
    static LoadingKeys = createLoadingKeys(
        "init",
        "submit",
        "deleteAccessGroup",
    )

    private _accessGroupId: number | undefined = undefined
    private setAccessGroupId(id: number | undefined) {
        this._accessGroupId = id
    }

    fields = new FormFields<IFormFields>({
        name: "",
        segmentIds: [],
        id: undefined,
    })

    @loads(() => AccessGroupsCreateOrEditStore.LoadingKeys.init)
    async init(accessGroupId?: number) {
        this.setAccessGroupId(accessGroupId)

        if (this._accessGroupId != null) {
            const response =
                await AccessGroupsAdminService.getV1AdminAccessGroup1({
                    accessGroupId: this._accessGroupId,
                })
            this.fields.init({
                name: response.name ?? "",
                segmentIds: response.segment_ids ?? [],
                id: response.id,
            })
        }
    }

    @loads(() => AccessGroupsCreateOrEditStore.LoadingKeys.submit)
    async submit() {
        await this.fields.catchErrors(async () => {
            const { data } = this.fields

            if (this._accessGroupId != null) {
                await AccessGroupsAdminService.patchV1AdminAccessGroup({
                    request: {
                        name: data.name,
                        segment_ids: data.segmentIds,
                    },
                    accessGroupId: this._accessGroupId,
                })

                Channel.send({
                    name: "repository/updated",
                    payload: {
                        repository: "access-groups",
                        action: "update",
                    },
                })
            } else {
                await AccessGroupsAdminService.postV1AdminAccessGroup({
                    request: {
                        name: data.name,
                        segment_ids: data.segmentIds,
                    },
                })

                Channel.send({
                    name: "repository/updated",
                    payload: {
                        repository: "access-groups",
                        action: "create",
                    },
                })
            }
        })
    }

    @loads(() => AccessGroupsCreateOrEditStore.LoadingKeys.deleteAccessGroup)
    async deleteAccessGroup(id: number) {
        await AccessGroupsAdminService.deleteV1AdminAccessGroup({
            accessGroupId: id,
        })

        Channel.send({
            name: "repository/updated",
            payload: {
                repository: "access-groups",
                action: "delete",
            },
        })
    }
}
