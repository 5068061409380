import { styled } from "@mui/material"

const HeaderContainer = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    padding: theme.spacing(2.97),
    background: "var(--Light-Background-Paper)",
}))

export { HeaderContainer }
