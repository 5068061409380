/**
 * Creates loading key symbols from strings.
 *
 * @param keys Strings keys to be converted to symbols.
 * @returns A map of symbols
 */
export function createLoadingKeys<TKey extends string>(...keys: TKey[]) {
    return keys.reduce((acc, v) => ({ ...acc, [v]: Symbol(v) }), {}) as {
        [key in TKey]: symbol
    }
}
