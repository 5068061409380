import { observer } from "mobx-react"
import { t } from "@lingui/macro"
import { useMemo } from "react"
import {
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
} from "@mui/material"

import { TenantsDetailsStore } from "./store"

import { useStore } from "src/store/lib/useStore"

export const StatusFilter = observer(() => {
    const store = useStore(TenantsDetailsStore)

    const statuses: Record<string, string> = useMemo(
        () => ({
            Cancelled: t`invoices-view.invoice-status.cancelled`,
            Handled: t`invoices-view.invoice-status.handled`,
            Paid: t`invoices-view.invoice-status.paid`,
            Pending: t`invoices-view.invoice-status.pending`,
            Unpaid: t`invoices-view.invoice-status.unpaid`,
        }),
        [],
    )

    return (
        <FormControl size="small">
            <InputLabel id="status-filter-label">{t`invoices-view.status-filter-label`}</InputLabel>
            <Select
                labelId="status-filter-label"
                id="status-filter"
                multiple
                value={store.statusFilter}
                onChange={(
                    event: SelectChangeEvent<typeof store.statusFilter>,
                ) =>
                    store.setStatusFilter(
                        typeof event.target.value === "string"
                            ? event.target.value.split(",")
                            : event.target.value,
                    )
                }
                input={
                    <OutlinedInput
                        label={t`invoices-view.status-filter-label`}
                    />
                }
                renderValue={(selected) =>
                    selected.map((status) => statuses[status]).join(", ")
                }
            >
                {Object.keys(statuses).map((status) => (
                    <MenuItem key={status} value={status}>
                        <Checkbox
                            checked={store.statusFilter.indexOf(status) > -1}
                            sx={{ padding: ".25em .5rem .25rem .25rem" }}
                        />
                        <ListItemText primary={statuses[status]} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
})
