/**
 * Maps feature names from the api to consistent camelCase feature names used by the form.
 */
const toForm = {
    app_icon_name: "appIconName",
    primaryColor: "primaryColor",
    propertyOwnerEmailAddress: "propertyOwnerEmailAddress",
    email_from_name: "senderName",
    email_from_address: "senderAddress",
    issue_reporting_external_link: "issueReportingExternalLink",
    issue_reporting_email_to_name: "issueReportingEmailToName",
    issue_reporting_email_to_address: "issueReportingEmailToAddress",
    sib_template_tenant_move_in: "emailTemplateTenantMoveIn",
    sib_template_tenant_electricity_email:
        "emailTemplateTenantElectricityEmail",
    sib_template_tenant_final_reminder: "emailTemplateTenantFinalReminder",
    sib_template_tenant_insurance_email: "emailTemplateTenantInsuranceEmail",
    sib_template_tenant_post_move_in_reminder_email:
        "emailTemplateTenantPostMoveInRemindEmail",
    sib_template_tenant_reminder_register:
        "emailTemplateTenantReminderRegister",
    sib_template_tenant_rollout_registration_reminder_final:
        "emailTemplateTenantRolloutRegistrationReminderFinal",
    sib_template_tenant_rollout_registration_reminder:
        "emailTemplateTenantRolloutRegistrationReminder",
    sib_template_tenant_rollout_registration_reminder_2:
        "emailTemplateTenantRolloutRegistrationReminder2",
    branding_background_image_url: "brandingBackgroundImage",
    branding_logo_url: "brandingLogo",
    overview_header_image_url: "overviewHeaderImage",
    overview_logo_url: "overviewLogo",
    propertyOwnerEmailLogo: "propertyOwnerEmailLogo",
    block_invoice_payment_after_due_date_days: "paymentDueDateBlocker",
    sms_template_tenant_move_in: "SMSTemplateTenantMoveIn",
    sms_from_phone: "SMSFromPhone",
    sms_template_tenant_final_reminder: "SMSTemplateTenantFinalReminder",
    sms_template_tenant_rollout_registration_reminder:
        "SMSTemplateTenantRolloutRegistrationReminder",
    sms_template_tenant_rollout_registration_reminder_2:
        "SMSTemplateTenantRolloutRegistrationReminder2",
    dash_position_ext_access_scheduler: "DashPositionExternalAccess",
    dash_position_amido: "DashPositionAmido",
    termination_connector_id: "terminationConnectorId",
    currency: "currency",
    active_languages: "activeLanguages",
    contact_details_editable: "contactDetailsEditable",
    edit_contact_details: "editContactDetails",

    //#region flutter
    use_for_public_branding: "useForPublicBranding", // key being used to set a legal entity's  as public branding, i.e., to be used for all other legal entities
    web_page_title: "webPageTitle",
    web_page_description: "webPageDescription",
    //#region colors
    primary_color: "primaryColorFlutter",
    on_primary_color: "onPrimaryColor",
    primary_Container_color: "primaryContainerColor",
    on_primary_container_color: "onPrimaryContainerColor",
    secondary_color: "secondaryColorFlutter",
    on_secondary_color: "onSecondaryColor",
    secondary_container_color: "secondaryContainerColor",
    on_secondary_container_color: "onSecondaryContainerColor",
    //#endregion

    //#region images and icons
    company_logo: "companyLogo",
    overview_and_app_icon: "overviewAndAppIcon",
    overview_header_image_compact: "overviewHeaderImageCompact",
    side_pane_image_expanded: "sidePaneImageExpanded",
    login_background_image_compact: "loginBackgroundCompact",
    login_background_image_expanded: "loginBackgroundExpanded",
    //#endregion

    //#endregion
} as const

// Create an inverse object of `toForm` where the key and values are swapped.
const fromForm = Object.fromEntries(
    Object.entries(toForm).map((entry) => [entry[1], entry[0]]),
) as InverseRecord<typeof toForm>

export const keyMap = { toForm, fromForm } as const
