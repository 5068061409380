import { IAntIconProps } from "src/layouts/DefaultLayout/Navigation/icons/props"

export const AntIconAccessGroups = (props: IAntIconProps) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M10.5 10L11 10L11 13L10.5 13C9.67 13 9 13.67 9 14.5L9 15.5L7 15.5L7 14.5C7 13.67 6.33 13 5.5 13L2.5 13C1.67 13 1 13.67 1 14.5L1 18.5C1 19.33 1.67 20 2.5 20L5.5 20C6.33 20 7 19.33 7 18.5L7 17.5L9 17.5L9 18.5C9 19.33 9.67 20 10.5 20L13.5 20C14.33 20 15 19.33 15 18.5L15 17.5L17 17.5L17 18.5C17 19.33 17.67 20 18.5 20L21.5 20C22.33 20 23 19.33 23 18.5L23 14.5C23 13.67 22.33 13 21.5 13L18.5 13C17.67 13 17 13.67 17 14.5L17 15.5L15 15.5L15 14.5C15 13.67 14.33 13 13.5 13L13 13L13 10L13.5 10C14.33 10 15 9.33 15 8.5L15 4.5C15 3.67 14.33 3 13.5 3L10.5 3C9.67 3 9 3.67 9 4.5L9 8.5C9 9.33 9.67 10 10.5 10Z"
            fill="currentColor"
        />
    </svg>
)
