import { Chip, useTheme } from "@mui/material"
import { observer } from "mobx-react"
import { useState } from "react"

export const CategoryChip = observer(
    (props: React.ComponentProps<typeof Chip>) => {
        const theme = useTheme()

        // Pick a random color for each chip. It's completely random so the same
        // category will get different colors for each mount. This is by design
        // and the behavior is the same as in the apps.
        const [color, _] = useState(() => {
            return theme.palette.resources.communityPostCategories[
                Math.floor(
                    Math.random() *
                        theme.palette.resources.communityPostCategories.length,
                )
            ]
        })

        return <Chip label={props.label} sx={{ background: color }} />
    },
)
