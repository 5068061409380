import { observer } from "mobx-react"
import { useCallback, useEffect, useState } from "react"
import { useLocation } from "react-router"

import { CenteredSpinner } from "src/components/CenteredSpinner"
import { AppBar } from "src/layouts/DefaultLayout/AppBar"

import { Content } from "src/layouts/DefaultLayout/Content"
import { Navigation } from "src/layouts/DefaultLayout/Navigation"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { SessionStore } from "src/store/session"
import { Head } from "src/layouts/DefaultLayout/Head"
import { ErrorBoundary } from "src/components/ErrorBoundary"

export const DefaultLayout = observer(() => {
    const gstore = useStore(GlobalStore)
    const [drawerOpen, setDrawerOpen] = useState(false)
    const location = useLocation()

    const closeDrawer = useCallback(() => setDrawerOpen(false), [])
    const openDrawer = useCallback(() => setDrawerOpen(true), [])

    // Close the navigation drawer on location change.
    useEffect(() => {
        closeDrawer()
    }, [location, closeDrawer])

    useEffect(() => {
        ;(async () => {
            const userTokenExist = gstore.session.userTokenIsPresent
            if (userTokenExist) {
                const success = await gstore.session.init()
                if (!success) {
                    window.location.href = "/login"
                }
            } else {
                window.location.href = "/login"
            }
        })()
    }, [gstore])

    const loading =
        !gstore.session.initialized ||
        gstore.loading.is(SessionStore.LoadingKeys.init)

    if (loading) {
        return <CenteredSpinner />
    }

    return (
        <>
            <AppBar onMenuClick={openDrawer} />
            <ErrorBoundary>
                <Head />
            </ErrorBoundary>
            <Navigation drawerOpen={drawerOpen} onDrawerClose={closeDrawer} />
            <Content />
        </>
    )
})
