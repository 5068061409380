import React from "react"
import { makeAutoObservable } from "mobx"
import camelcaseKeys from "camelcase-keys"

import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"
import { ProductCatalogueAdminService } from "src/api"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { parseDate } from "src/lib/date"

export class OrdersIndividualAdditionsStore {
    static Context = React.createContext<OrdersIndividualAdditionsStore | null>(
        null,
    )
    static LoadingKeys = createLoadingKeys("pageLoad")
    private _accessGroupId: number = DEFAULT_ACCESS_GROUP.id

    individualAdditions = new Pagination(
        async (query) => {
            const response =
                await ProductCatalogueAdminService.getV1AdminCatalogueImprovementOrder(
                    {
                        pageNumber: query.page,
                        pageSize: query.pageSize,
                        accessGroupId: this.getAccountGroupId(),
                    },
                )

            const items =
                response.orders?.map((order) => ({
                    ...camelcaseKeys(order),
                    id: order.order_id as number,
                    lastUpdated:
                        order.last_updated != null
                            ? parseDate(order.last_updated)
                            : undefined,
                })) ?? []

            return {
                items: items,
                count: response.total_count ?? items?.length ?? 0,
            }
        },
        {
            loadingKey: OrdersIndividualAdditionsStore.LoadingKeys.pageLoad,
        },
    )

    constructor() {
        makeAutoObservable(this)
    }

    async init() {
        await this.individualAdditions.loadInitialPage()
    }

    private getAccountGroupId() {
        return this._accessGroupId !== DEFAULT_ACCESS_GROUP.id
            ? this._accessGroupId
            : undefined
    }
}

export interface IOrder {
    adminName?: string
    lastUpdated?: Date
    objectId?: string
    orderId?: number
    pdfUrl?: string
    provider?: string
    signedCount?: number
    totalSignaturesCount?: number
}
