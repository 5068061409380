import { IIconProps } from "src/components/icons"

export const LegalEntity16 = (props: IIconProps) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5 12.9999H3.5C2.67157 12.9999 2 12.3177 2 11.4761V5.88888C2 5.04731 2.67157 4.36509 3.5 4.36509H5.45C5.51256 4.36531 5.56479 4.31667 5.57 4.25334L5.635 3.66922C5.72887 2.90177 6.37375 2.32746 7.135 2.33336H8.855C9.61625 2.32746 10.2611 2.90177 10.355 3.66922L10.43 4.25334C10.4352 4.31667 10.4874 4.36531 10.55 4.36509H12.5C13.3284 4.36509 14 5.04731 14 5.88888V11.4761C14 12.3177 13.3284 12.9999 12.5 12.9999ZM7.135 3.3492C6.88069 3.34733 6.66551 3.53969 6.635 3.79618L6.585 4.22284C6.57993 4.25953 6.59089 4.29663 6.615 4.32443C6.6398 4.35069 6.67417 4.3654 6.71 4.36506H9.29C9.32583 4.3654 9.3602 4.35069 9.385 4.32443C9.40911 4.29663 9.42007 4.25953 9.415 4.22284L9.365 3.79618C9.33449 3.53969 9.11931 3.34733 8.865 3.3492H7.135ZM4.625 7.15869C4.62769 6.94944 4.79402 6.78048 5 6.77774H11C11.206 6.78048 11.3723 6.94944 11.375 7.15869V7.41266C11.3723 7.62191 11.206 7.79087 11 7.79361H5C4.79402 7.79087 4.62769 7.62191 4.625 7.41266V7.15869ZM5 9.57137C4.79402 9.57411 4.62769 9.74307 4.625 9.95232V10.2063C4.62769 10.4155 4.79402 10.5845 5 10.5872H11C11.206 10.5845 11.3723 10.4155 11.375 10.2063V9.95232C11.3723 9.74307 11.206 9.57411 11 9.57137H5Z"
            fill="currentColor"
        />
    </svg>
)
