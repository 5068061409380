import { Login } from "./Login"
import { LoginStore } from "./store"

import { StoreProvider } from "src/store/lib/StoreProvider"

export const LoginView = () => (
    <StoreProvider Store={LoginStore}>
        <Login />
    </StoreProvider>
)
