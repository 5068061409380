import { makeAutoObservable } from "mobx"

import React from "react"
import camelcaseKeys from "camelcase-keys"
import { format } from "date-fns"

import {
    avy_api_pkg_tenants_domain_PropertyData,
    avy_api_pkg_tenants_domain_TenantWithContracts,
    domain_GetInvoicesRequest,
    InvoiceAdminService,
    TenantAdminService,
} from "src/api"

import { loads } from "src/channel/utils"
import { parseDate } from "src/lib/date"
import { FormFields } from "src/lib/form-fields"
import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"
import { reportUnhandledApiError } from "src/lib/report"
import { CustomInvoiceAdminService } from "src/api/_custom/services/InvoiceAdminService"
import { autoDownloadFile } from "src/lib/file"

interface IFormFields {
    fullname: string | undefined
    personal_id: string | undefined
    email: string | undefined
    phone_no: string | undefined
    is_post_notification: boolean | undefined
}

interface IDateRangeFilter {
    from: Date | null
    to: Date | null
}

export class TenantsDetailsStore {
    static Context = React.createContext<TenantsDetailsStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "submit", "export")

    private _tenantDetails: avy_api_pkg_tenants_domain_TenantWithContracts = {}
    private _selectedContractID?: number
    private _isEdit: boolean = false

    private _selectedContractObject: avy_api_pkg_tenants_domain_PropertyData =
        {}

    segments: number[] = []
    statusFilter: string[] = []
    private _showContract: boolean = false
    private _tenantId: number = 0
    dateRangeFilter: IDateRangeFilter = {
        from: null,
        to: null,
    }

    fields = new FormFields<IFormFields>({
        fullname: "",
        personal_id: "",
        email: "",
        phone_no: "",
        is_post_notification: false,
    })

    get isEdit() {
        return this._isEdit
    }

    get tenantDetails() {
        return this._tenantDetails
    }

    get tenantId() {
        return this._tenantId
    }

    get showContract() {
        return this._showContract
    }

    public setTenantId(tenantId: number) {
        this._tenantId = tenantId
    }

    public setIsEdit(isEdit: boolean) {
        this._isEdit = isEdit
    }

    public setShowContract(flag: boolean) {
        this._showContract = flag
    }

    @loads(() => TenantsDetailsStore.LoadingKeys.export)
    async export() {
        const blob = await CustomInvoiceAdminService.getV1AdminInvoice({
            tenantId: this.tenantId.toString(),
            _export: true,
        })

        const url = URL.createObjectURL(blob)

        autoDownloadFile(
            url,
            `AVY-TMPL-Invoices-${new Date().toDateString()}.csv`,
            "export-invoices-link",
        )

        // Keep the url alive for a minute just to be sure.
        setTimeout(() => {
            URL.revokeObjectURL(url)
        }, 60_000)
    }

    get selectedContractID() {
        return this._selectedContractID
    }

    get selectedContractObject() {
        return this._selectedContractObject
    }

    public setSelectedContractObject(
        object: avy_api_pkg_tenants_domain_PropertyData,
    ) {
        this._selectedContractObject = object
    }

    private initFields(fields: IFormFields) {
        this.fields.init(fields)
    }

    public setTenantDetails(
        tenantDetails: avy_api_pkg_tenants_domain_TenantWithContracts = {},
    ) {
        this._tenantDetails = tenantDetails
        if (
            tenantDetails.contracts !== undefined &&
            tenantDetails.contracts.length === 1
        ) {
            this.setSelectedContractObject(tenantDetails.contracts[0])
            this.setShowContract(true)
        }
        this.initFields({
            fullname: tenantDetails.name,
            personal_id: tenantDetails.personal_id,
            email: tenantDetails.email,
            phone_no: tenantDetails.phone_no,
            is_post_notification: tenantDetails.is_post_notification,
        })
    }

    public setSelectedContract(contractId: number) {
        this._selectedContractID = contractId
        const selectedObject = this.tenantDetails.contracts?.find(
            (obj) => obj.lease_contract_id === contractId,
        )
        if (selectedObject !== undefined) {
            this.setSelectedContractObject(selectedObject)
        }
        this.setShowContract(true)
    }

    constructor() {
        makeAutoObservable(this)
    }

    private formatDateToDateRangeQuery(date: Date) {
        return format(date, "yyyyMM")
    }
    async setStatusFilter(statusFilter: string[]) {
        this.statusFilter = statusFilter
        await this.invoices.loadInitialPage()
    }

    async setDateRangeFilter(value: IDateRangeFilter) {
        this.dateRangeFilter = value
        await this.invoices.loadInitialPage()
    }

    private dateRangeQuery(): domain_GetInvoicesRequest["date_range"] {
        const query: domain_GetInvoicesRequest["date_range"] = {}
        if (this.dateRangeFilter.from != null) {
            query.from = this.formatDateToDateRangeQuery(
                this.dateRangeFilter.from,
            )
        }
        if (this.dateRangeFilter.to != null) {
            query.to = this.formatDateToDateRangeQuery(this.dateRangeFilter.to)
        }

        return Object.keys(query).length > 0 ? query : undefined
    }

    private setSegments(segments: number[]) {
        this.segments = segments
    }

    async loadSegments(segments: number[]) {
        this.setSegments(segments)
        await this.invoices.loadInitialPage()
    }

    @loads(() => TenantsDetailsStore.LoadingKeys.submit)
    async submit() {
        await this.fields.catchErrors(async () => {
            try {
                const { data } = this.fields
                if (this.tenantDetails.tenant_id !== null) {
                    await TenantAdminService.patchV1AdminTenants({
                        request: {
                            name: data.fullname,
                            email: data.email === "" ? " " : data.email,
                            phone_number:
                                data.phone_no === "" ? " " : data.phone_no,
                            is_post_notification: data.is_post_notification,
                        },
                        tenantId: this.tenantId,
                    })
                }
            } catch (e) {
                reportUnhandledApiError(e)
            } finally {
                this.setIsEdit(false)
                this.setTenantDetails(
                    await TenantAdminService.tenantsGetOne({
                        tenantId: this.tenantId,
                    }),
                )
            }
        })
    }

    @loads(() => TenantsDetailsStore.LoadingKeys.init)
    async init(tenantId: number, segmentIds: number[], loadInvoices: boolean) {
        try {
            this.setTenantDetails(
                await TenantAdminService.tenantsGetOne({
                    tenantId: tenantId,
                }),
            )
            this.setTenantId(tenantId)
            this.setSegments(segmentIds)
            if (loadInvoices) {
                await this.invoices.loadInitialPage()
            }
        } catch (e) {
            reportUnhandledApiError(e)
        } finally {
        }
    }

    invoices = new Pagination(async () => {
        const response = await InvoiceAdminService.getV1AdminInvoice({
            tenantId: this.tenantId.toString(),
        })

        const items =
            response
                .map((invoice) => ({
                    ...camelcaseKeys(invoice),
                    id: invoice.invoice_id as number,
                    dueDate:
                        invoice.due_date != null
                            ? parseDate(invoice.due_date)
                            : undefined,
                    invoiceDate:
                        invoice.invoice_date != null
                            ? parseDate(invoice.invoice_date)
                            : undefined,
                    selected: false,
                    transactionDate:
                        // TODO: sync api client when these are available
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (invoice as any).transaction_date != null
                            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              parseDate((invoice as any).transaction_date)
                            : undefined,
                }))
                .filter((invoice) => {
                    if (this.statusFilter.length === 0) {
                        return true
                    }
                    return this.statusFilter.includes(invoice.status ?? "")
                }) ?? []

        return {
            items: items,
            count: items.length ?? 0,
        }
    })

    get canDownloadInvoiceCsv() {
        return (this.invoices.meta.count ?? 0) > 0
    }

    async simulateUser() {
        const response = await TenantAdminService.getV1AdminTenantsMagicLink({
            tenantId: this.tenantId,
            propertyObjectId:
                this.selectedContractObject.property_object_id ?? 0,
        })
        const { link } = response
        if (link !== undefined) {
            window.open(link, "_blank")
        }
    }
}
