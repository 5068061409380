import React, { useState } from "react"
import DialogTitle from "@mui/material/DialogTitle"

import { t } from "@lingui/macro"

import { observer } from "mobx-react"

import {
    Button,
    DialogActions,
    Stack,
    Typography,
    useTheme,
} from "@mui/material"

import { i18n } from "@lingui/core"

import { CustomMenuItem, Flag, UserInfoIconWrapper } from "./styled"

import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { Locale, Locales } from "src/locales/locale"
import { CheckRounded24 } from "src/components/icons/CheckRounded24"

export const LanguageSelectorModal = observer(() => {
    const gstore = useStore(GlobalStore)
    const theme = useTheme()

    const [selectedLang, setSelectedLang] = useState(
        Locales[gstore.session.language].key,
    )

    const handleClose = () => {
        gstore.modals.pop()
    }

    const languageList = [Locales[Locale.English], Locales[Locale.Swedish]]

    const onLanguageClick = (lang: Locale) => {
        setSelectedLang(lang)
    }

    const handleLanguageChange = () => {
        i18n.activate(selectedLang)
        handleClose()
    }

    return (
        <Stack padding="8px">
            <DialogTitle id="alert-dialog-title">
                {t`language-selector-modal.title`}
            </DialogTitle>
            {languageList.map((lang) => (
                <CustomMenuItem
                    onClick={() => onLanguageClick(lang.key)}
                    data-testID={`lang-${lang.key}`}
                >
                    <Stack flexDirection="row">
                        <UserInfoIconWrapper sx={{ marginRight: "16px" }}>
                            <Flag sizes="24px" src={lang.icon} />
                        </UserInfoIconWrapper>
                        <Typography
                            color={theme.palette.grey[900]}
                            fontSize={16}
                            fontWeight={24}
                        >
                            {lang.name}
                        </Typography>
                    </Stack>
                    <Stack>
                        {lang.key === selectedLang ? <CheckRounded24 /> : null}
                    </Stack>
                </CustomMenuItem>
            ))}

            <DialogActions
                style={{
                    justifyContent: "flex-end",
                    paddingLeft: "24px",
                }}
            >
                <Button color="info" onClick={handleClose}>
                    {t`global.cancel`}
                </Button>
                <Button
                    data-testid="confirm-button"
                    onClick={handleLanguageChange}
                    color="info"
                    autoFocus
                >
                    {t`global.ok`}
                </Button>
            </DialogActions>
        </Stack>
    )
})
