import { SxProps, Theme } from "@mui/material"
import { observer } from "mobx-react"
import { Outlet } from "react-router"

import { HeadAccessGroupSelect } from "./Head/HeadAccessGroupSelect"

import {
    AccessGroupWrapper,
    ContentContainer,
    OutletWrap,
    ToolbarSpacer,
} from "src/layouts/DefaultLayout/styled"

import { ErrorBoundary } from "src/components/ErrorBoundary"

import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"

export const Content = observer(() => {
    const gstore = useStore(GlobalStore)

    let sx: SxProps<Theme> = {}

    if (gstore.modals.active.length > 0) {
        sx = { overflow: "hidden", height: "100vh" }
    }

    return (
        <ContentContainer sx={sx}>
            <ToolbarSpacer />
            <AccessGroupWrapper>
                <HeadAccessGroupSelect />
            </AccessGroupWrapper>
            <ErrorBoundary>
                <OutletWrap>
                    <Outlet />
                </OutletWrap>
            </ErrorBoundary>
        </ContentContainer>
    )
})
