import { t } from "@lingui/macro"
import { Alert } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useEffect } from "react"

import { CenteredSpinner } from "src/components/CenteredSpinner"
import { Form } from "src/components/Form"
import { FormPanel } from "src/components/FormPanel"
import { AssigneeSection } from "src/modals/cases-v2-detail/sections/AssigneeSection"
import { ErrandSection } from "src/modals/cases-v2-detail/sections/ErrandSection"
import { AnswersSection } from "src/modals/cases-v2-detail/sections/AnswersSection"

import { CaseDetailStore } from "src/modals/cases-v2-detail/store"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { ReporterSection } from "src/modals/cases-v2-detail/sections/ReporterSection"

interface IProps {
    id: number
    formId: string
}

export const CasesDetails = observer((props: IProps) => {
    const store = useStore(CaseDetailStore)
    const globalStore = useStore(GlobalStore)

    useEffect(() => {
        ;(async () => {
            await store.init(props.id)
        })()
    }, [store, props.id])

    const handleSubmit = useCallback(async () => {
        await store.submit()
        if (!store.form.hasErrors()) {
            globalStore.modals.pop()
        }
    }, [store, globalStore])

    if (globalStore.loading.is(CaseDetailStore.LoadingKeys.init)) {
        return <CenteredSpinner height="100vh" />
    }

    return (
        <Form formId={props.formId} onSubmit={handleSubmit}>
            {Boolean(store.form.error("generic")) && (
                <Alert severity="error">{store.form.error("generic")}</Alert>
            )}
            <FormPanel
                sections={[
                    {
                        header: t`cases-detail-modal.errand-section.header`,
                        subHeader: t`cases-detail-modal.errand-section.sub-header`,
                        content: <ErrandSection id={props.id} />,
                    },
                    {
                        header: t`cases-detail-modal.reporter-section.header`,
                        content: <ReporterSection />,
                    },
                    {
                        header: t`cases-detail-modal.answers-section.header`,
                        subHeader: t`cases-detail-modal.answers-section.sub-header`,
                        content: <AnswersSection />,
                    },
                    {
                        header: t`cases-detail-modal.assignee-section.header`,
                        subHeader: t`cases-detail-modal.assignee-section.sub-header`,
                        content: <AssigneeSection />,
                    },
                ]}
            />
        </Form>
    )
})
