import { t } from "@lingui/macro"
import {
    Checkbox,
    List,
    ListItem as MUIListItem,
    TablePagination,
    useTheme,
} from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useMemo, useRef } from "react"

import { SegmentPickerSearchListItem } from "src/components/SegmentPicker/components/SegmentPickerSearchListItem"
import { ISegmentListProps } from "src/components/SegmentPicker/components/SegmentsList/types"

import { SegmentPickerStore } from "src/components/SegmentPicker/store"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"

export const SegmentsList = observer((props: ISegmentListProps) => {
    const theme = useTheme()
    const store = useStore(SegmentPickerStore)
    const globalStore = useStore(GlobalStore)

    const listRef = useRef<HTMLUListElement | null>(null)

    const scrollToTop = useCallback(() => {
        listRef.current?.scrollTo({ top: 0 })
    }, [listRef])

    const handlePageChange = useCallback(
        async (_: unknown, page: number) => {
            await store.availableSegments.loadPage(page)
            scrollToTop()
        },
        [scrollToTop, store.availableSegments],
    )

    const handlePageCheckboxClick = useCallback(() => {
        if (store.availableSegmentsPageStatus === "all") {
            store.deselectSegmentsFromSource()
        } else {
            store.selectSegmentsFromSource()
        }
    }, [store])

    const handleRowsPerPageChange = useCallback(
        async (
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => {
            await Promise.all([
                store.selectedSegments.loadPageSize(Number(event.target.value)),
                store.availableSegments.loadPageSize(
                    Number(event.target.value),
                ),
            ])
            scrollToTop()
        },
        [scrollToTop, store.availableSegments, store.selectedSegments],
    )

    const renderSelectAllCheckbox = useMemo(
        () => (
            <MUIListItem
                sx={{
                    background: theme.palette.grey[100],
                    marginBottom: "2px",
                    borderRadius: "4px",
                    padding: 0,
                    height: "32px",
                    overflow: "hidden",
                    alignItems: "stretch",
                }}
            >
                <Checkbox
                    disableRipple
                    onClick={handlePageCheckboxClick}
                    title={
                        store.availableSegmentsPageStatus === "all"
                            ? t`segment-picker.available.deselect-all-segments`
                            : t`segment-picker.available.select-all-segments`
                    }
                    indeterminate={
                        store.availableSegmentsPageStatus === "partial"
                    }
                    checked={store.availableSegmentsPageStatus === "all"}
                    sx={{
                        background: theme.palette.common.white,
                        borderRadius: 0,
                        padding: "4px",
                    }}
                />
            </MUIListItem>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [store.availableSegmentsPageStatus],
    )

    const canRenderSelectAllCheckbox = useMemo(() => {
        if (
            globalStore.session.selectedParakeyType !== "private" &&
            (props.hasSelectAllCheckbox === undefined ||
                props.hasSelectAllCheckbox)
        ) {
            return renderSelectAllCheckbox
        }
        return null
    }, [
        globalStore.session.selectedParakeyType,
        props.hasSelectAllCheckbox,
        renderSelectAllCheckbox,
    ])

    if (
        !store.availableSegments.meta.initialized ||
        store.availableSegments.items.length === 0
    ) {
        return null
    }

    return (
        <>
            <List ref={listRef} sx={{ overflow: "auto", flex: 1 }}>
                {canRenderSelectAllCheckbox}
                {store.availableSegments.items.map((segment) => (
                    <SegmentPickerSearchListItem
                        key={segment.id}
                        segment={segment}
                    />
                ))}
            </List>
            <TablePagination
                component="div"
                count={store.availableSegments.meta.count ?? -1}
                page={store.availableSegments.meta.page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                rowsPerPage={store.availableSegments.meta.pageSize}
                labelRowsPerPage={t`table-pagination-component.rows-per-page`}
                labelDisplayedRows={(info) =>
                    t({
                        id: "table-pagination-component.displayed-rows",
                        values: {
                            from: info.from,
                            count: info.count,
                            to: info.to,
                            page: info.page,
                        },
                    })
                }
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                }}
            />
        </>
    )
})
