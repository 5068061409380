import { t } from "@lingui/macro"
import { useMemo } from "react"
import { Typography, Box } from "@mui/material"

import { ConsideredItem } from "src/components/ChatMessageJourney/components"
import { IConsideredSectionProps } from "src/components/ChatMessageJourney/types"

const ConsideredSection = ({
    message,
    handleSourceClick,
}: IConsideredSectionProps) => {
    const consideredData = useMemo(() => {
        return (
            message?.nodeInfo?.find((node) => node.node_name === "GetDataNode")
                ?.state?.sourcesConsidered ?? []
        )
    }, [message?.nodeInfo])

    if (consideredData.length === 0) {
        return null
    }

    return (
        <Box sx={{ marginBottom: "16px" }}>
            <Typography
                variant="subtitle2"
                sx={{ display: "flex", marginBottom: "8px" }}
            >
                {t`chat-message-journey.considered.text`}
            </Typography>
            {consideredData.map((data, index) => (
                <ConsideredItem
                    key={index}
                    data={data}
                    handleSourceClick={handleSourceClick}
                />
            ))}
        </Box>
    )
}

export default ConsideredSection
