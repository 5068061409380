/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { avy_api_pkg_featurev2_Flag } from "../models/avy_api_pkg_featurev2_Flag"
import type { featurev2_UpdateSegmentsRequest } from "../models/featurev2_UpdateSegmentsRequest"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class FeatureFlagAdminService {
    /**
     * Get flags
     * Gets flags with segment data
     * @returns avy_api_pkg_featurev2_Flag OK
     * @throws ApiError
     */
    public static getV2AdminFeatureFlag({
        accessGroupId,
    }: {
        /** Currently selected access group ID **/
        accessGroupId?: number
    }): CancelablePromise<Array<avy_api_pkg_featurev2_Flag>> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v2/admin/feature/flag",
            query: {
                access_group_id: accessGroupId,
            },
        })
    }

    /**
     * Update feature flag segments
     * Updates feature flag segments
     * @returns any OK
     * @throws ApiError
     */
    public static putV2AdminFeatureFlagSegments({
        flagId,
        request,
    }: {
        /** Flag ID **/
        flagId: number
        /** Update feature flag segments request **/
        request: featurev2_UpdateSegmentsRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v2/admin/feature/flag/{flag_id}/segments",
            path: {
                flag_id: flagId,
            },
            body: request,
        })
    }
}
