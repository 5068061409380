import { Stack } from "@mui/material"
import { useEffect, useState } from "react"
import YouTube, { YouTubeProps } from "react-youtube"

import { getVideoIdFromYoutubeUrl } from "src/components/YouTubeEmbed/helpers/getVideoIdFromUrl"

interface IProps {
    videoUrl: string
}

export const YouTubeEmbed = (props: IProps) => {
    const [videoID, setVideoID] = useState<string>("")

    useEffect(() => {
        if (props.videoUrl !== "") {
            const id = getVideoIdFromYoutubeUrl(props.videoUrl)
            setVideoID(id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const opts: YouTubeProps["opts"] = {
        width: "100%",
        playerVars: {
            autoplay: 0,
        },
    }

    if (videoID.length === 0) {
        return null
    }

    return (
        <Stack width="100%" marginBottom={3} marginTop={2}>
            <YouTube
                videoId={videoID}
                className="embed embed-youtube"
                opts={opts}
            />
        </Stack>
    )
}
