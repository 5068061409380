/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import { makeAutoObservable } from "mobx"
import { t } from "@lingui/macro"

import { FormFields } from "src/lib/form-fields"
import { IIntegrationTypeConnectorProps } from "src/views/integration-connector/type"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { ConnectorAdminService } from "src/api"
import { Channel } from "src/channel"

export class IntegrationConnectorItemStore {
    static Context = React.createContext<IntegrationConnectorItemStore | null>(
        null,
    )
    formFields = new FormFields<IFormFields>({})
    isLoading = false
    accessType: string = ""
    connectors: IIntegrationTypeConnectorProps[] = []
    segmentIds: number[] = []
    connectorTypeId: number = -1
    type: string = ""
    accessGroupId: number = DEFAULT_ACCESS_GROUP.id
    isEditMode = false

    constructor() {
        makeAutoObservable(this)
    }

    init = async (integrationId?: number) => {
        if (integrationId !== undefined) {
            const response =
                await ConnectorAdminService.getV1AdminConnectorIntegration1({
                    integrationId: integrationId as number,
                })
            this.formFields.init({
                connectionId: String(
                    response.integration_id !== undefined
                        ? response.integration_id
                        : "-",
                ),
                segmentIds: response.segment_ids ?? [],
                name: response.name ?? "",
                metaData: response.metadata,
                connectorTypeId: response.integration_type_id ?? -1,
                type: response.type ?? "",
                accessGroupId: response.access_group_id,
            })
            this.isEditMode = response.integration_id !== undefined
        } else {
            this.formFields.set("segmentIds", [])
            this.formFields.set("connectionId", "-")
        }
    }

    createItem = async () => {
        try {
            await this.formFields.catchErrors(async () => {
                const response =
                    await ConnectorAdminService.postV1AdminConnectorIntegration(
                        {
                            request: this.getRequestData(),
                        },
                    )
                await this.publishIntegrationConnector(
                    response.integration_id as number,
                )
                Channel.send({
                    name: "repository/updated",
                    payload: {
                        repository: "integration-connector",
                        action: "create",
                        item: {
                            id: response.integration_id as number,
                            name: response.name,
                        },
                    },
                })
            })
        } catch (error) {
            reportError(error)
        } finally {
            this.setIsLoading(false)
        }
    }

    updateItem = async () => {
        try {
            await this.formFields.catchErrors(async () => {
                await ConnectorAdminService.patchV1AdminConnectorIntegration({
                    integrationId: Number(
                        this.formFields.data.connectionId,
                    ) as number,
                    request: this.getUpdateRequestData(),
                })
            })
            await this.publishIntegrationConnector(
                Number(this.formFields.data.connectionId) as number,
            )
            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "integration-connector",
                    action: "update",
                },
            })
        } catch (error) {
            reportError(error)
        } finally {
            this.setIsLoading(false)
        }
    }

    publishIntegrationConnector = async (id: number) => {
        if (id != null) {
            await ConnectorAdminService.putV1AdminConnectorIntegrationPublish({
                integrationId: id,
                request: { published_in: this.formFields.data.segmentIds },
            })
        } else {
            this.formFields.setErrors({
                segmentIds: t`resource-detail-modal.could-not-publish-error`,
            })
            throw new Error(
                "Couldn't save the selected segments because no somehow no resource id was selected. This shouldn't be possible.",
            )
        }
    }
    getUpdateRequestData = () => ({
        metadata: this.formFields.data.metaData,
        name: this.formFields.data.name ?? "",
    })
    getRequestData = () => ({
        access_group_id:
            this.formFields.data.accessGroupId ?? DEFAULT_ACCESS_GROUP.id,
        integration_type_id: this.formFields.data.connectorTypeId ?? 0,
        metadata: this.formFields.data.metaData,
        name: this.formFields.data.name ?? "",
    })

    setConnector = (connectors: IIntegrationTypeConnectorProps[]) => {
        this.connectors = connectors
    }

    validateRequiredFields = (requiredFields: string[]) => {
        this.formFields.clearErrors()

        requiredFields.forEach((field) => {
            const requiredField = field as keyof IFormFields
            const formField = this.formFields.get(requiredField)

            if (
                formField == null ||
                formField.toString().trim() === "" ||
                formField === -1 ||
                formField === "<p></p>\n"
            ) {
                this.formFields.setError(requiredField, t`errors.required`)
            } else {
                this.formFields.clearError(requiredField)
            }
        })
    }

    setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading)
    setAccessTypes = (accessType: string) => {
        this.accessType = accessType
    }
}

export interface IFormFields {
    connectionId?: string
    name?: string
    connectorTypeId?: number
    type?: string
    accessGroupId?: number
    accessType?: string
    metaData?: Record<string, string>
    isDynamicFieldEmpty?: string
    segmentIds?: number[]
}
