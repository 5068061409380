import { List } from "@mui/material"
import { observer } from "mobx-react"

import { GroupItemList } from "./ListItem/GroupItemList"

import { ListItemSingle } from "./ListItem/ListItemSingle"

import { NavSection, SuperUserSection } from "src/layouts/DefaultLayout/styled"

import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"

import { INavigationItemSingle } from "src/store/navigation/types"

export const NavigationList = observer(() => {
    const gstore = useStore(GlobalStore)

    const secondListItems = gstore.navigation.secondListItems
    const defaultItems = gstore.navigation.defaultItems

    return (
        <NavSection>
            <List>
                {defaultItems.map((group, index) => {
                    if (group.group === "none") {
                        return (
                            <ListItemSingle
                                key={index}
                                item={
                                    group.children[0] as INavigationItemSingle
                                }
                            />
                        )
                    }
                    return <GroupItemList key={group.group} item={group} />
                })}
                <SuperUserSection>
                    {secondListItems.map((group) => (
                        <GroupItemList key={group.group} item={group} />
                    ))}
                </SuperUserSection>
            </List>
        </NavSection>
    )
})
