import { observer } from "mobx-react"
import { useEffect } from "react"

import { ViewContactFormChatStore } from "./store"

import { StoreProvider } from "src/store/lib/StoreProvider"

import { CenteredSpinner } from "src/components/CenteredSpinner"
import { AvyChat } from "src/components/Chat"
import { useStore } from "src/store/lib/useStore"
import { EAvyChatVariant } from "src/components/Chat/types/chat"
import { boxWrapperStyles } from "src/modals/contact-form-detail/ContactFormChat/styled"

interface IProps {
    id: number
}

const View = observer((props: IProps) => {
    const store = useStore(ViewContactFormChatStore)

    useEffect(() => {
        ;(async () => {
            await store.init(props.id)
        })()
    }, [store, props.id])

    return (
        <>
            {!store.initialized ? (
                <CenteredSpinner height="100%" />
            ) : (
                <AvyChat
                    variant={EAvyChatVariant.Chat}
                    messages={store.messages}
                    boxWrapperStyles={boxWrapperStyles}
                    handleSubmit={(images: IPersistedFile[], text: string) =>
                        store.submitCommentForm(images, text)
                    }
                    handleSourceClick={() => undefined} // sources are currently not supported by contact form API.
                />
            )}
        </>
    )
})

export const ContactFormChatModal = (props: IProps) => (
    <StoreProvider Store={ViewContactFormChatStore}>
        <View {...props} />
    </StoreProvider>
)
