import { t } from "@lingui/macro"
import { useCallback } from "react"
import { Button, useTheme } from "@mui/material"

import { Icon } from "src/components/icons"
import { Cross16 } from "src/components/icons/Cross16"

import { HeaderContainer } from "src/components/ChatMessageJourney/sections/HeaderSection/styled"
import { IHeaderSectionProps } from "src/components/ChatMessageJourney/types"

const HeaderSection = ({ setIsOpenID }: IHeaderSectionProps) => {
    const theme = useTheme()

    const handleClose = useCallback(() => {
        setIsOpenID("")
    }, [setIsOpenID])

    return (
        <HeaderContainer>
            <Button
                startIcon={
                    <Icon
                        icon={<Cross16 />}
                        size={20}
                        color={theme.palette.info.main}
                    />
                }
                color="info"
                onClick={handleClose}
            >
                {t`chat-message-journey.header.Close`}
            </Button>
        </HeaderContainer>
    )
}

export default HeaderSection
