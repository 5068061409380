import { styled } from "@mui/material"
import { observer } from "mobx-react"
import React from "react"

const ImagePreviewWrap = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    position: "relative",
    overflow: "hidden",
}))

interface IProps {
    url: string
    children?: React.ReactNode
    aspectRatio?: string
}

export const ImagePreview = observer((props: IProps) => {
    return (
        <ImagePreviewWrap
            sx={{
                backgroundImage: `url(${props.url})`,
                aspectRatio: props.aspectRatio,
                height:
                    props.aspectRatio == null
                        ? { xs: "64px", sm: "128px" }
                        : "auto",
            }}
        >
            {props.children}
        </ImagePreviewWrap>
    )
})
