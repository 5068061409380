import { t } from "@lingui/macro"
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material"
import { observer } from "mobx-react"
import React, { useCallback } from "react"

import { ModalTextField } from "src/components/ModalTextField"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"

interface IProps {
    translation: string
    language: string
    onSave: (value: string) => void
}

export const MultiLanguageEditModal = observer(
    ({ translation, language, onSave }: IProps) => {
        const gstore = useStore(GlobalStore)
        const [value, setValue] = React.useState(translation)

        const handleCancel = () => {
            gstore.modals.pop()
        }

        const handleSave = () => {
            onSave(value)
            gstore.modals.pop()
        }

        const handleChange = useCallback(
            (
                event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                >,
            ) => {
                setValue(event.target.value)
            },
            [],
        )

        return (
            <>
                <DialogTitle>{`${t`multi-language-text-field-modal.title`} (${language})`}</DialogTitle>
                <DialogContent style={{ paddingTop: 8 }}>
                    <ModalTextField
                        variant="default"
                        label={`${t`multi-language-text-field-modal.description`} (${language})`}
                        value={value}
                        onChange={handleChange}
                        error={false}
                        rows={3}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        color="info"
                        onClick={handleCancel}
                        data-testid="Confirm/Cancel"
                    >
                        {t`global.cancel`}
                    </Button>
                    <Button
                        color="info"
                        onClick={handleSave}
                        autoFocus
                        data-testid="Confirm/Ok"
                    >
                        {t`global.save`}
                    </Button>
                </DialogActions>
            </>
        )
    },
)
