import { makeAutoObservable } from "mobx"
import React from "react"

import { CommunityAdminService } from "src/api"
import { Channel } from "src/channel"
import { loads } from "src/channel/utils"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"

export interface ICommunity {
    id: number
    name: string
    internalName: string
    adminEmails: string
    accessGroupId: number
    accessType: string
}

export class CommunitiesViewStore implements IDisposable {
    static Context = React.createContext<CommunitiesViewStore | null>(null)
    static LoadingKeys = createLoadingKeys("init")

    private allCommunities: ICommunity[] = []
    private accessGroupId: number = DEFAULT_ACCESS_GROUP.id

    communities = new Pagination(
        (params) => {
            const pageStart = (params.page - 1) * params.pageSize
            const communities = this.allCommunities.filter(
                (community) =>
                    this.accessGroupId === DEFAULT_ACCESS_GROUP.id ||
                    community.accessGroupId === this.accessGroupId,
            )
            const pageCommunities = communities.slice(
                pageStart,
                pageStart + params.pageSize,
            )

            return {
                items: pageCommunities,
                sourceItems: this.allCommunities,
                count: this.allCommunities.length,
            }
        },
        { static: true },
    )

    private repositoryUpdatesListenerDisposer?: () => void

    constructor() {
        makeAutoObservable(this)
    }

    dispose(): void {
        this.repositoryUpdatesListenerDisposer?.()
    }

    async init(accessGroupId: number) {
        this.setAccessGroupId(accessGroupId)
        this.listenToCommunityRepositoryUpdated()
        await this.loadCommunities()
        await this.communities.loadInitialPage()
    }

    @loads(() => CommunitiesViewStore.LoadingKeys.init)
    async loadCommunities() {
        const rawCommunities = await CommunityAdminService.getV1AdminCommunity()
        this.setCommunties(
            (rawCommunities ?? []).map((community) => ({
                id: community.community_id ?? 0,
                name: community.name ?? "",
                accessType: community.access_type ?? "",
                internalName: community.internal_name ?? "",
                adminEmails: this.getEmailsText(community.admin_emails),
                accessGroupId:
                    community.access_group_id ?? DEFAULT_ACCESS_GROUP.id,
            })),
        )
    }

    private listenToCommunityRepositoryUpdated() {
        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "community"
                ) {
                    await this.loadCommunities()
                    await this.communities.loadInitialPage()
                }
            },
        )
    }

    private setCommunties(communities: ICommunity[]) {
        this.allCommunities = communities
    }

    private setAccessGroupId(accessGroupId: number) {
        this.accessGroupId = accessGroupId
    }

    private getEmailsText(emailList: string[] | undefined) {
        return emailList != null && emailList.length > 1
            ? `${emailList[0]} +${emailList.length - 1}`
            : `${emailList != null ? emailList[0] : ""}`
    }
}
