const filterPanelSlotProp = {
    sx: {
        "*.MuiDataGrid-filterFormDeleteIcon": {
            width: "auto",
        },
        "*.MuiDataGrid-filterFormLogicOperatorInput": {
            width: "auto",
        },
    },
}

export { filterPanelSlotProp }
