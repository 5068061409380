import { t } from "@lingui/macro"
import { Stack, ToggleButton, Typography, useTheme } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useEffect, useMemo } from "react"

import { ToggleChipGroup } from "src/components/SegmentPicker/components/SegmentType/styled"

import { SegmentPickerStore } from "src/components/SegmentPicker/store"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"

export const SegmentType = observer(() => {
    const store = useStore(SegmentPickerStore)
    const globalStore = useStore(GlobalStore)
    const theme = useTheme()

    const handleChange = useCallback(
        async (_: unknown, value: ISegmentForSearch["type"] | null) => {
            if (value !== null) {
                store.setSegmentType(value)
                await store.availableSegments.loadInitialPage()
            }
        },
        [store],
    )

    useEffect(() => {
        if (globalStore.session.selectedParakeyType === "Private") {
            store.setSegmentType("propertyobject")
        }
    }, [globalStore.session.selectedParakeyType, store])

    const translations = useMemo(
        (): { [key in ISegmentForSearch["type"]]: string } => ({
            legalentity: t`segment-picker.legalentity-segment-type`,
            property: t`segment-picker.property-segment-type`,
            propertyowner: t`segment-picker.property-owner-segment-type`,
            building: t`segment-picker.building-segment-type`,
            address: t`segment-picker.address-segment-type`,
            propertyobject: t`segment-picker.object-segment-type`,
            district: t`segment-picker.district-segment-type`,
            area1: t`segment-picker.area1-segment-type`,
            area2: t`segment-picker.area2-segment-type`,
            area3: t`segment-picker.area3-segment-type`,
            other: t`segment-picker.other-segment-type`,
        }),
        [],
    )
    return (
        <Stack spacing={0.5}>
            <Typography
                fontSize={14}
                fontWeight={400}
                color={theme.palette.grey[900]}
            >
                {t`segment-picker.available.segment-type`}
            </Typography>
            <div>
                <ToggleChipGroup
                    value={store.segmentType}
                    onChange={handleChange}
                    exclusive
                >
                    {globalStore.session.selectedParakeyType === "Private"
                        ? store.segmentTypes
                              .filter((type) => type === "propertyobject")
                              .map((item) => (
                                  <ToggleButton
                                      key={item}
                                      value={item}
                                      disabled={store.typeIsLowerThanOrEqualToParent(
                                          item,
                                      )}
                                  >
                                      {translations[item]}
                                  </ToggleButton>
                              ))
                        : store.segmentTypes.map((type) => (
                              <ToggleButton
                                  key={type}
                                  value={type}
                                  disabled={store.typeIsLowerThanOrEqualToParent(
                                      type,
                                  )}
                              >
                                  {translations[type]}
                              </ToggleButton>
                          ))}
                </ToggleChipGroup>
            </div>
        </Stack>
    )
})
