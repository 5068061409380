import { Typography, useTheme } from "@mui/material"
import { memo, useMemo } from "react"

import { StyledTooltip } from "src/components/Tooltips/TooltipWithIcon/styled"
import { Icon } from "src/components/icons"
import { Alert16 } from "src/components/icons/Alert16"
import { ITooltipWithIconProps } from "src/types/tooltip/tooltipWithIconProps"

export const TooltipWithIcon = memo(
    ({ icon, onClick, tooltipText }: ITooltipWithIconProps) => {
        const theme = useTheme()

        const _icon = useMemo(
            () => icon ?? <Alert16 width="20px" height="20px" />,
            [icon],
        )

        return (
            <StyledTooltip title={tooltipText} arrow onClick={onClick}>
                <Typography sx={{ marginTop: "16px" }}>
                    <Icon icon={_icon} color={theme.palette.grey[700]} />
                </Typography>
            </StyledTooltip>
        )
    },
)
