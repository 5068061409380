import { styled, Avatar as MUIAvatar } from "@mui/material"
import { observer } from "mobx-react"

import { convertStringToColor } from "src/lib/stringToColor"

type Size = 24 | 32 | 40 | 56

const StyledMUIAvatar = styled(MUIAvatar)<{ size: Size; name: string }>(
    ({ theme, size, name }) => {
        let fontSize = "12px"
        if (size === 56) {
            fontSize = "20px"
        } else if (size === 40) {
            fontSize = "16px"
        }

        return {
            display: "inline-flex",
            background: convertStringToColor(name),
            color: theme.palette.common.white,
            justifyContent: "center",
            alignItems: "center",
            height: `${size}px`,
            width: `${size}px`,
            fontSize,
            textTransform: "uppercase",
        }
    },
)

interface IProps {
    name: string
    size?: Size
}

export const Avatar = observer((props: IProps) => {
    const parts = props.name.split(" ")
    const acronym = parts.map((part) => part[0] ?? "").join("")
    const initials =
        acronym.length > 2
            ? `${acronym[0]}${acronym[acronym.length - 1]}`
            : acronym.slice(0, 2)
    return (
        <StyledMUIAvatar name={props.name} size={props.size ?? 24}>
            {initials}
        </StyledMUIAvatar>
    )
})
