import { t } from "@lingui/macro"
import { Chip, Tooltip, Typography } from "@mui/material"

import { chatbot_Tags } from "src/api"

export const TagsCell = (props: { value?: chatbot_Tags }) => {
    const tagNames = Object.values(props.value ?? {})
    const isSingleTag =
        tagNames != null && tagNames?.length > 0 && tagNames?.length === 1

    return isSingleTag ? (
        <Chip label={tagNames[0]} />
    ) : (
        <Tooltip title={tagNames.join()} arrow>
            <Typography variant="body2">
                {`(${
                    tagNames?.length
                }) ${t`tags-cell-component.tags`.toLowerCase()}`}
            </Typography>
        </Tooltip>
    )
}
