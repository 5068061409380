import React from "react"
import { t } from "@lingui/macro"
import { makeAutoObservable } from "mobx"

import { Channel } from "src/channel"

import { FormFields } from "src/lib/form-fields"
import { IEditAutomaticAssigneeItem } from "src/types/automatic_assignee"
import {
    contact_Admin,
    contact_DefaultAssigneeUpdate,
    ContactFormAdminService,
} from "src/api"

export class EditAutomaticAssigneeStore {
    static Context = React.createContext<EditAutomaticAssigneeStore | null>(
        null,
    )
    formFields = new FormFields<IFormFields>({})
    automaticAssignees: contact_Admin[] = []
    isLoading = false

    constructor() {
        makeAutoObservable(this)
    }

    init = (item?: IEditAutomaticAssigneeItem) => {
        if (item?.row.adminId != null) {
            this.formFields.init({
                automaticAssignee: Number(item?.row.adminId),
            })
        }
    }
    updateItem = async (item: contact_DefaultAssigneeUpdate) => {
        // Update
        await ContactFormAdminService.postV1AdminContactFormDefaultAssignee({
            request: {
                admin_id: item.admin_id as number,
                property_id: item.property_id as number,
            },
        })
        let object = this.automaticAssignees.find(
            (data) => data.admin_id === item.admin_id,
        )
        Channel.send({
            name: "repository/updated",
            payload: {
                repository: "cases",
                action: "update",
                item: {
                    id: item.property_id ?? 0,
                    name: object?.name ?? "",
                    extraId: item.admin_id,
                },
            },
        })
    }

    setAssignee = (automaticAssignees: contact_Admin[]) => {
        automaticAssignees.splice(0, 0, {
            name: t`contact-form-automatic-assignee-view.no-assignee-selected`,
        })
        this.automaticAssignees = automaticAssignees
    }

    setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading)
}

export interface IFormFields {
    automaticAssignee?: number
}
