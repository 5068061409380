/* eslint-disable import/no-relative-parent-imports */
import {
    AccordionDetails,
    Stack,
    useTheme,
    Button,
    Box,
    Divider,
    styled,
    CircularProgress,
} from "@mui/material"

import { memo, useCallback, useMemo, useState } from "react"

import { t } from "@lingui/macro"

import Markdown from "markdown-to-jsx"

import TranslateRoundedIcon from "@mui/icons-material/TranslateRounded"

import {
    AttachedImage,
    AttachedVideo,
    AttachmentsGrid,
    Author,
    CreatedAt,
    IconContainer,
    MessageBubble,
    Separator,
    StyledAccordion,
    StyledAccordionSummary,
    StyledIconButton,
    ItemContainer,
    Typography400,
    Typography500,
} from "./styled"

import { Icon } from "src/components/icons"

import { Time } from "src/components/Time"
import { ChatBot } from "src/components/icons/ChatBot"
import { parseDate } from "src/lib/date"

import { chatbot_MessageInfoSource } from "src/api/models/chatbot_MessageInfoSource"
import { chatbot_Source } from "src/api/models/chatbot_Source"
import { Avatar } from "src/components/Avatar"
import { ChevronDown24 } from "src/components/icons/ChevronDown24"
import { Export } from "src/components/icons/Export"
import { isVideoByUrl } from "src/lib/video"
import {
    EMessageSender,
    IChatMessage,
} from "src/types/chat-message/chatMessage"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { AttachmentModal } from "src/modals/attachment"

const MessageWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.6)",
}))

const AvyChatMessage = (props: {
    message: IChatMessage
    handleSourceClick?: (item?: chatbot_MessageInfoSource) => void
    setMessageJourneyID: (id?: string) => void
    isTranslationEnabled?: boolean
}) => {
    const theme = useTheme()
    const {
        message,
        handleSourceClick,
        setMessageJourneyID,
        isTranslationEnabled,
    } = props
    const globalStore = useStore(GlobalStore)

    const [expanded, setExpanded] = useState<boolean>(false)
    const isAdminMessage = message.messageSender === EMessageSender.Admin

    // Depending on who sent the message we want the message, avatar and
    // attachments to either left or right aligned.
    const stackDirection = isAdminMessage ? "row-reverse" : "row"
    const alignItems = isAdminMessage ? "flex-end" : "flex-start"
    const direction = isAdminMessage ? "right" : "left"

    const handleChange = useCallback(
        (_: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded)
        },
        [],
    )

    const getMessageBackgroundColor = useMemo(() => {
        switch (message.messageSender) {
            case EMessageSender.Chatbot:
                return theme.palette.utility.lightIndigo.main
            case EMessageSender.Admin:
                return theme.palette.common.white
            case EMessageSender.User:
                return theme.palette.utility.lightGreen.main

            default:
                return theme.palette.common.white
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message.messageSender])

    const renderSourceInfo = useMemo(() => {
        return (
            <AccordionDetails sx={{ paddingBottom: 0 }}>
                {message.source?.map((item: chatbot_Source, index: number) => (
                    <ItemContainer
                        key={index}
                        sx={{
                            maxHeight: 20,
                            marginBottom: "4px",
                        }}
                    >
                        <Typography500 color={theme.palette.common.black}>
                            {item.source?.name}
                        </Typography500>
                        <IconContainer>
                            <StyledIconButton
                                onClick={() =>
                                    handleSourceClick !== undefined &&
                                    handleSourceClick(item.source)
                                }
                            >
                                <Icon icon={<Export />} size={16} />
                            </StyledIconButton>
                        </IconContainer>
                    </ItemContainer>
                ))}
                <Box display="flex" justifyContent="flex-end" mt={2}>
                    <Button
                        variant="text"
                        sx={{
                            color: theme.palette.info.main,
                            "&:hover": {
                                backgroundColor: "transparent",
                                textDecoration: "underline",
                            },
                        }}
                        onClick={() => setMessageJourneyID(message?.messageId)}
                    >
                        {t`chat-component.message.details`}
                    </Button>
                </Box>
            </AccordionDetails>
        )
    }, [
        handleSourceClick,
        message,
        setMessageJourneyID,
        theme.palette.common.black,
        theme.palette.info.main,
    ])

    const renderSource = useMemo(() => {
        return (
            <>
                <Separator />
                <StyledAccordion
                    expanded={expanded}
                    disableGutters={true}
                    elevation={0}
                    onChange={handleChange}
                    backgroundColor={getMessageBackgroundColor}
                >
                    <StyledAccordionSummary expandIcon={<ChevronDown24 />}>
                        <Typography400 color={theme.palette.common.black}>
                            {t`chat-component.message.source`}
                        </Typography400>
                    </StyledAccordionSummary>
                    {renderSourceInfo}
                </StyledAccordion>
            </>
        )
    }, [
        expanded,
        getMessageBackgroundColor,
        handleChange,
        renderSourceInfo,
        theme.palette.common.black,
    ])

    const handleAttachmentClick = useCallback(
        (url: string) => {
            globalStore.modals.open(() => <AttachmentModal url={url} />, {
                variant: "slide-up-w995",
            })
        },
        [globalStore.modals],
    )

    const renderAttachmentPreview = useCallback(
        (url: string) => (
            <>
                {isVideoByUrl(url) ? (
                    <AttachedVideo
                        src={url}
                        onClick={() => handleAttachmentClick(url)}
                    />
                ) : (
                    <AttachedImage
                        src={url}
                        onClick={() => handleAttachmentClick(url)}
                    />
                )}
            </>
        ),
        [handleAttachmentClick],
    )

    const renderAttachments = useMemo(() => {
        return (
            <AttachmentsGrid direction={direction}>
                {(message.attachmentUrls ?? []).map((url) =>
                    renderAttachmentPreview(url),
                )}
            </AttachmentsGrid>
        )
    }, [direction, message.attachmentUrls, renderAttachmentPreview])

    const renderAvatar = useMemo(() => {
        switch (message.messageSender) {
            case EMessageSender.Chatbot:
                return (
                    <IconContainer>
                        <Icon icon={<ChatBot />} size={32} />
                    </IconContainer>
                )
            case EMessageSender.Admin:
            case EMessageSender.User:
                return <Avatar size={32} name={message.authorName} />

            default:
                return null
        }
    }, [message.messageSender, message.authorName])

    const renderAuthorDetails = useMemo(() => {
        return (
            <Stack direction={stackDirection} alignItems="center" spacing={1}>
                {renderAvatar}
                <Author>{message.authorName}</Author>
                <CreatedAt>
                    <Time date={parseDate(message.createdAt)} />
                </CreatedAt>
            </Stack>
        )
    }, [message.authorName, message.createdAt, renderAvatar, stackDirection])

    return (
        <Stack flexDirection="column" spacing={1}>
            {renderAuthorDetails}
            <Stack alignItems={alignItems}>
                <MessageBubble
                    direction={direction}
                    backgroundColor={getMessageBackgroundColor}
                >
                    <Markdown
                        options={{
                            overrides: {
                                img: {
                                    component: (data) =>
                                        renderAttachmentPreview(data.src),
                                },
                            },
                        }}
                    >
                        {message.text}
                    </Markdown>
                    {isTranslationEnabled !== undefined &&
                    isTranslationEnabled &&
                    (message?.translationText === undefined ||
                        message?.translationText === "") ? (
                        <>
                            <Divider sx={{ my: 1 }} />
                            <MessageWrapper>
                                <CircularProgress size={14} />
                            </MessageWrapper>
                        </>
                    ) : (
                        message?.translationText !== undefined &&
                        message?.translationText !== "" && (
                            <>
                                <Divider sx={{ my: 1 }} />
                                <MessageWrapper>
                                    <TranslateRoundedIcon fontSize="small" />
                                    <i>
                                        <Markdown
                                            options={{
                                                overrides: {
                                                    img: {
                                                        component: (data) =>
                                                            renderAttachmentPreview(
                                                                data.src,
                                                            ),
                                                    },
                                                },
                                            }}
                                        >
                                            {message?.translationText ?? ""}
                                        </Markdown>
                                    </i>
                                </MessageWrapper>
                            </>
                        )
                    )}
                    {message.source != null && message.source.length > 0
                        ? renderSource
                        : null}
                    {message.attachmentUrls !== undefined &&
                    message.attachmentUrls.length > 0
                        ? renderAttachments
                        : null}
                </MessageBubble>
            </Stack>
        </Stack>
    )
}

export default memo(
    AvyChatMessage,
    (prev, next) =>
        prev.message.messageId !== next.message.messageId &&
        prev.message.translationText !== next.message.translationText,
)
