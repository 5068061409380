import { Fab } from "@mui/material"

import { FabButtonContainer } from "src/modals/embedded-integration-v2/styled"
import { Add24 } from "src/components/icons/Add24"
import { IAddCardSectionProps } from "src/modals/embedded-integration-v2/types/addCardSection"

export const EmbedIntegrationAddCardButtonSection = ({
    onClick,
    buttonText,
}: IAddCardSectionProps) => (
    <FabButtonContainer>
        <Fab
            variant="extended"
            color="primary"
            size="medium"
            disabled={false}
            onClick={onClick}
        >
            <Add24 />
            {buttonText}
        </Fab>
    </FabButtonContainer>
)
