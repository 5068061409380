import { alpha, Box, Stack, styled, Tab, Tabs } from "@mui/material"

export const BottomBar = styled(Stack)({
    flex: 0,
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
})

export const TabBar = styled(Tabs)(({ theme }) => ({
    flex: 0,
    minHeight: "56px",
    backgroundColor: alpha(theme.palette.utility.torquoiseFaded.main, 0.5),
    "& .MuiTabs-indicator": { backgroundColor: theme.palette.grey[900] },
}))

export const TabButton = styled(Tab)(({ theme }) => ({
    color: theme.palette.grey[900],
    textTransform: "none",
    fontSize: "16px",
    minHeight: "56px",
    "&.Mui-selected": {
        color: theme.palette.grey[900],
    },
}))

export const TabContent = styled(Box)({
    overflow: "auto",
    flex: 1,
    padding: "1rem",
})
