import { TabPanel } from "@mui/lab"
import { Button, FormControlLabel, Grid, styled } from "@mui/material"

export const TabPanelWrapper = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
}))

export const ContactDetailsWrapper = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.grey[50],
    padding: "16px",
}))

export const EditButtonWrapper = styled(Grid)(() => ({
    display: "flex",
    justifyContent: "space-between",
}))

export const ContactGridWrapper = styled(Grid)(() => ({
    marginTop: "24px",
}))

export const GridLeftWrapper = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up("sm")]: {
        paddingRight: "8px",
    },
}))
export const GridRightWrapper = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up("sm")]: {
        paddingLeft: "8px",
    },
    [theme.breakpoints.down("sm")]: {
        marginTop: "16px",
    },
}))

export const GridContactsWrapper = styled(Grid)(() => ({
    marginTop: "8px",
}))

export const AppSettingsWrapper = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.grey[50],
    padding: "16px",
    marginTop: "16px",
    height: "250px",
}))

export const AddressWrapper = styled("div")(() => ({
    marginTop: "24px",
}))

export const DetailsHeaderView = styled("div")(() => ({
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
}))

export const UserAvatarWrapper = styled("div")(() => ({
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
}))

export const InvoiceFilterContainer = styled("div")(() => ({
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
}))

export const ActionButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== "loading",
})<{ loading?: boolean }>(({ theme, loading }) => ({
    background: theme.palette.common.white,
    position: "relative",
    height: "40px",
    [theme.breakpoints.down("sm")]: {
        marginTop: "10px",
    },
    [theme.breakpoints.up("sm")]: {
        marginLeft: "10px",
    },
    "& .MuiCircularProgress-root": {
        display: loading === true ? "block" : "none",
        position: "absolute",
    },
    "& span:not(.MuiCircularProgress-root)": {
        visibility: loading === true ? "hidden" : "visible",
    },
}))

export const FormControlContainer = styled(FormControlLabel)(() => ({
    marginRight: 0,
}))

export const HeaderWrapper = styled("div")(() => ({
    marginBottom: "32px",
}))

export const TabPanelContainer = styled(TabPanel)(() => ({
    padding: "16px",
}))

export const InvoiceSwitchWrapper = styled("div")(() => ({
    paddingTop: "10px",
}))

export const BreadcrumbContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
}))

export const BackCursor = styled("div")(() => ({
    cursor: "pointer",
}))
