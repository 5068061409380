import React from "react"
import { observer } from "mobx-react-lite"
import { Grid, Typography, Box } from "@mui/material"

import {
    IAsset,
    IImageLibraryProps,
} from "src/components/AssetLibraryPicker/types"

import {
    IconItem,
    IconBox,
} from "src/components/AssetLibraryPicker/tabs/styled"

const IconLibrary: React.FC<IImageLibraryProps> = observer(
    ({ icons, handleIconSelect }: IImageLibraryProps) => {
        return (
            <Grid container spacing={2}>
                {icons.map((icon: IAsset, index: number) => (
                    <Grid
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                        }}
                        item
                        xs={3}
                        sm={2.4}
                        md={2.4}
                        key={icon.name + index}
                    >
                        <IconItem onClick={() => handleIconSelect(icon)}>
                            <IconBox className="icon-box">
                                <Box
                                    component="img"
                                    src={icon.path}
                                    alt={icon.name}
                                    sx={{ width: 40, height: 40 }}
                                />
                            </IconBox>
                        </IconItem>
                        <Typography
                            sx={{ paddingLeft: 0.5 }}
                            variant="caption"
                            noWrap
                        >
                            {`${icon.name}`.replace(".svg", "")}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
        )
    },
)
export default IconLibrary
