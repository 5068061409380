import { ThemeProvider } from "@mui/material"
import { I18nProvider } from "@lingui/react"
import React from "react"
import "react-app-polyfill/stable"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import { LicenseInfo } from "@mui/x-license-pro"
import { LocalizationProvider } from "@mui/lab"

import AdapterDateFns from "@mui/lab/AdapterDateFns"

import { sv } from "date-fns/locale"

import "./api/configure"
import "./store/configure"
import "./lib/sentry"

import { DefaultTheme } from "./themes/default"

import "./styles/index.css"

// eslint-disable-next-line import/no-relative-parent-imports
import "../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import { GlobalStore } from "src/store"
import { App } from "src/App"
import { i18n, loadI18n } from "src/locales"
import { ApiError } from "src/api"
import { reportUnhandledApiError } from "src/lib/report"
import { variables } from "src/config/variables"

loadI18n()
LicenseInfo.setLicenseKey(variables.MUI_X_PRO_KEY)

const gstore = new GlobalStore()

window.onunhandledrejection = (event) => {
    // eslint-disable-next-line no-console
    console.error(event.reason)
    if (event.reason instanceof ApiError) {
        reportUnhandledApiError(event.reason)
    }
}

ReactDOM.render(
    <React.StrictMode>
        {/* Hard-code sv has locale for dates for the time being */}
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={sv}>
            <I18nProvider i18n={i18n}>
                <ThemeProvider theme={DefaultTheme}>
                    <GlobalStore.Context.Provider value={gstore}>
                        <BrowserRouter>
                            <App />
                        </BrowserRouter>
                    </GlobalStore.Context.Provider>
                </ThemeProvider>
            </I18nProvider>
        </LocalizationProvider>
    </React.StrictMode>,
    document.getElementById("root"),
)
