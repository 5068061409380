import {
    styled,
    ListItem as MUIListItem,
    ListItemText as MUIListItemText,
    IconButton,
} from "@mui/material"

export const ListItemInnerSelect = styled(MUIListItem)(({ theme }) => ({
    background: theme.palette.grey[100],
    padding: "0 90px 0 12px",
    height: "50px",
    borderRadius: "1000px",
    marginBottom: "2px",
}))

export const ListItemTextSelect = styled(MUIListItemText)({
    marginLeft: "8px",
})

export const DeselectButton = styled(IconButton)(({ theme }) => ({
    background: theme.palette.grey[300],
    padding: "4px",
    "&:hover": {
        background: theme.palette.grey[500],
    },
}))
