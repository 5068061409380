import { memo } from "react"
import { Button, useTheme } from "@mui/material"

interface IFilterButtonProps {
    label: string
    value: boolean | null
    isActive: boolean
    onClick: () => void
}

export const FilterButton = memo(
    ({ label, isActive, onClick }: IFilterButtonProps) => {
        const { palette } = useTheme()
        return (
            <Button
                onClick={onClick}
                sx={{
                    backgroundColor: isActive ? "rgba(0, 0, 0, 0.06)" : "",
                    "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.05)",
                    },
                    color: palette.common.black,
                }}
            >
                {label}
            </Button>
        )
    },
)
