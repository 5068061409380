import { observer } from "mobx-react"
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    FormHelperText,
} from "@mui/material"
import { useEffect } from "react"
import { t } from "@lingui/macro"

import { EditProductStore } from "src/views/product-catalogue/edit/store"

import { useStore } from "src/store/lib/useStore"

export const Category = observer(() => {
    const store = useStore(EditProductStore)

    useEffect(() => {
        return () => {
            // Reset to initial value if component unmounts
            if (
                store.formFields.get("categoryId") !== store.product.categoryId
            ) {
                store.formFields.set("categoryId", store.product.categoryId)
            }
        }
    }, [store])

    return (
        <FormControl style={{ breakInside: "avoid" }}>
            <InputLabel id="category-label">{t`edit-product-view.category.label`}</InputLabel>
            <Select
                labelId="category-label"
                id="category"
                value={store.formFields.get("categoryId") ?? ""}
                label={t`edit-product-view.category.label`}
                onChange={(event) =>
                    store.formFields.set(
                        "categoryId",
                        Number(event.target.value),
                    )
                }
                error={Boolean(store.formFields.error("categoryId"))}
            >
                {store.categories.map((category) => (
                    <MenuItem
                        value={category.categoryId}
                        key={category.categoryId}
                    >
                        {category.name}
                    </MenuItem>
                ))}
            </Select>
            {Boolean(store.formFields.error("categoryId")) && (
                <FormHelperText error={true}>
                    {store.formFields.error("categoryId")}
                </FormHelperText>
            )}
        </FormControl>
    )
})
