import { observer } from "mobx-react"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    useMediaQuery,
    MenuItem,
    Select,
    Tab,
    useTheme,
    Fab,
    styled,
} from "@mui/material"
import { useParams, useNavigate } from "react-router-dom"
import { SyntheticEvent, useEffect, useState, useCallback } from "react"
import { t } from "@lingui/macro"

import { PropertyOwnersTable } from "./propertyOwner"
import { ClientManagementStore } from "./store"
import { LegalEntityTableView } from "./legal-entity"
import { PropertyTableView } from "./property"

import { H1, Subheader } from "src/components/PageHeader"
import { Add24 } from "src/components/icons/Add24"
import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { CreateNewPropertyOwnerModal } from "src/modals/create-new-propertyOwner-modal"
import { CreateNewLegalEntityModal } from "src/modals/create-new-legalEntity-modal"
import { CreateNewPropertyModal } from "src/modals/create-new-property-modal"

const TabPanelWrapper = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
}))

const View = observer(() => {
    const store = useStore(ClientManagementStore)
    const globalStore = useStore(GlobalStore)
    const params = useParams()
    const navigate = useNavigate()
    const theme = useTheme()
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))

    const tabName = params.tab ?? "property-owners"

    const propertyOwnerId =
        params.propertyOwnerId != null
            ? Number(params.propertyOwnerId)
            : undefined

    const legalEntityId =
        params.legalEntityId != null ? Number(params.legalEntityId) : undefined

    const [tab, setTab] = useState<string>(tabName)

    useEffect(() => {
        ;(async () => {
            await store.init(
                globalStore.session.accessGroupId,
                globalStore.session.segmentIds,
            )
            if (tab === "property" && propertyOwnerId != null) {
                await store.getLegalEntities(propertyOwnerId)
            }
        })()
    }, [
        store,
        propertyOwnerId,
        tab,
        globalStore.session.accessGroupId,
        globalStore.session.segmentIds,
    ])

    const handleTabChange = (event: SyntheticEvent, newValue: string) => {
        navigate(
            `/client-management/${newValue}/${
                propertyOwnerId != null ? propertyOwnerId : ""
            }${legalEntityId != null ? "/" + legalEntityId : ""}`,
        )
        setTab(newValue)
    }

    const handlePropertyOwnerDropdownChange = async (
        propertyOwnerIdFromDropdown: number,
    ) => {
        navigate(`/client-management/${tabName}/${propertyOwnerIdFromDropdown}`)
        await store.getLegalEntities(propertyOwnerIdFromDropdown)
    }

    const handleLegalEntityDropdownChange = async (
        legalEntityIdFromDropdown: number,
    ) => {
        if (propertyOwnerId != null) {
            await store.propertyOwner.loadInitialPage()
            navigate(
                `/client-management/${tabName}/${propertyOwnerId}/${legalEntityIdFromDropdown}`,
            )
        }
    }

    const getTabStyle = (tabName: string) => {
        const tabActive = tab === tabName
        return {
            backgroundColor: tabActive
                ? theme.palette.common.white
                : theme.palette.grey[300],
            marginRight: "8px",
            boxShadow: tabActive ? "0px 0px 10px rgba(0, 0, 0, 0.1)" : "",
            borderRadius: "4px 4px 0px 0px",
            width: "196px",
        }
    }

    const capitalize = (string: string) => {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }

    const onCreateClick = useCallback(() => {
        if (tab === "property-owners") {
            globalStore.modals.open(() => <CreateNewPropertyOwnerModal />)
        }
        if (tab === "legal-entity") {
            globalStore.modals.open(() => (
                <CreateNewLegalEntityModal
                    propertyOwnersList={store.propertyOwners}
                    chosenPropertyOwnerId={propertyOwnerId}
                />
            ))
        }
        if (tab === "property") {
            globalStore.modals.open(() => (
                <CreateNewPropertyModal
                    chosenPropertyOwnerId={propertyOwnerId}
                    propertyOwnersList={store.propertyOwners}
                    chosenLegalEntityId={legalEntityId}
                />
            ))
        }
    }, [globalStore, tab, propertyOwnerId, legalEntityId, store.propertyOwners])

    return (
        <>
            <TabContext value={tab}>
                <div style={{ marginBottom: "32px" }}>
                    <H1>{t`client-management.h1-header`}</H1>
                    <Subheader>{capitalize(tab.replace(/-/g, " "))}</Subheader>
                </div>
                <Box
                    sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                    }}
                    data-testid="client-management-tabs"
                >
                    <TabList
                        variant={smallScreen ? "fullWidth" : "standard"}
                        onChange={handleTabChange}
                    >
                        <Tab
                            style={getTabStyle("property-owners")}
                            label={t`client-management-view.property-owner`.toUpperCase()}
                            value="property-owners"
                        />
                        <Tab
                            style={getTabStyle("legal-entity")}
                            label={t`client-management-view.legal-entity`.toUpperCase()}
                            value="legal-entity"
                        />
                        <Tab
                            style={getTabStyle("property")}
                            label={t`client-management-view.property`.toUpperCase()}
                            value="property"
                        />
                    </TabList>
                </Box>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        paddingTop: "16px",
                        paddingRight: smallScreen ? 0 : "24px",
                        backgroundColor: theme.palette.common.white,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: smallScreen ? "column" : "row",
                            marginRight: smallScreen ? 0 : "16px",
                            justifyContent: smallScreen ? "center" : "flex-end",
                            flexGrow: "1",
                            marginLeft: "16px",
                        }}
                    >
                        {(tab === "legal-entity" || tab === "property") && (
                            <FormControl
                                sx={{ m: 1, minWidth: 120 }}
                                size="small"
                                style={{
                                    width: smallScreen ? "100%" : "224px",
                                    height: "35px",
                                }}
                                data-testid="property-owner-dropdown"
                            >
                                <InputLabel id="select-property-owner">
                                    {t`client-management-view.property-owner`}
                                </InputLabel>
                                <Select
                                    labelId="select-property-owner"
                                    label={t`client-management-view.property-owner`}
                                    value={
                                        propertyOwnerId != null &&
                                        store.propertyOwners.length > 0
                                            ? propertyOwnerId
                                            : ""
                                    }
                                    onChange={async (event) => {
                                        await handlePropertyOwnerDropdownChange(
                                            event.target.value as number,
                                        )
                                    }}
                                >
                                    {store.propertyOwners.map(
                                        (propertyOwner, index) => {
                                            return (
                                                <MenuItem
                                                    key={index}
                                                    value={
                                                        propertyOwner.propertyOwnerId
                                                    }
                                                >
                                                    {propertyOwner.legalName}
                                                </MenuItem>
                                            )
                                        },
                                    )}
                                </Select>
                            </FormControl>
                        )}
                        {tab === "property" && (
                            <FormControl
                                sx={{ m: 1, minWidth: 120 }}
                                size="small"
                                style={{
                                    width: smallScreen ? "100%" : "224px",
                                    height: "35px",
                                }}
                                data-testid="legal-entity-dropdown"
                            >
                                <InputLabel id="select-legal-entity">
                                    {t`client-management-view.legal-entity`}
                                </InputLabel>
                                <Select
                                    labelId="select-legal-entity"
                                    label={t`client-management-view.legal-entity`}
                                    value={
                                        legalEntityId !== undefined &&
                                        store.legalEntities.length > 0
                                            ? legalEntityId
                                            : ""
                                    }
                                    onChange={async (event) => {
                                        await handleLegalEntityDropdownChange(
                                            event.target.value as number,
                                        )
                                    }}
                                >
                                    {store.legalEntities.map(
                                        (legalEntity, index) => {
                                            return (
                                                <MenuItem
                                                    key={index}
                                                    value={
                                                        legalEntity.legalEntityId
                                                    }
                                                >
                                                    {legalEntity.legalName}
                                                </MenuItem>
                                            )
                                        },
                                    )}
                                </Select>
                            </FormControl>
                        )}
                    </div>
                    {smallScreen ? (
                        <Fab
                            style={{
                                width: "40px",
                                height: "40px",
                                margin: "8px 16px",
                            }}
                            data-testid="create-button"
                            variant="circular"
                            color="primary"
                            size="small"
                            onClick={onCreateClick}
                        >
                            <Add24 />
                        </Fab>
                    ) : (
                        <Button
                            variant="contained"
                            style={{
                                height: "40px",
                                marginTop: "8px",
                                width: "144px",
                            }}
                            onClick={onCreateClick}
                            data-testid="create-button"
                        >
                            + {t`client-management-view.create-button`}
                        </Button>
                    )}
                </div>
                {tab === "property-owners" && (
                    <TabPanelWrapper>
                        <TabPanel value="property-owners">
                            <PropertyOwnersTable
                                propertyOwners={store.propertyOwner}
                            />
                        </TabPanel>
                    </TabPanelWrapper>
                )}
                {tab === "legal-entity" && (
                    <TabPanelWrapper>
                        <TabPanel value="legal-entity">
                            <LegalEntityTableView
                                propertyOwner={store.propertyOwners.find(
                                    (propertyOwner) =>
                                        propertyOwner.id === propertyOwnerId,
                                )}
                                propertyOwnersList={store.propertyOwners}
                            />
                        </TabPanel>
                    </TabPanelWrapper>
                )}
                {tab === "property" && (
                    <TabPanelWrapper>
                        <TabPanel value="property">
                            <PropertyTableView
                                propertyOwner={store.propertyOwners.find(
                                    (propertyOwner) =>
                                        propertyOwner.id === propertyOwnerId,
                                )}
                                legalEntityId={legalEntityId}
                                onEditClick={onCreateClick}
                                propertyOwnersList={store.propertyOwners}
                            />
                        </TabPanel>
                    </TabPanelWrapper>
                )}
            </TabContext>
        </>
    )
})

export const ClientManagementView = () => (
    <StoreProvider Store={ClientManagementStore}>
        <View />
    </StoreProvider>
)
