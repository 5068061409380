import { t } from "@lingui/macro"
import { TextField } from "@mui/material"
import { observer } from "mobx-react"

import { ILabelQuestionTextFieldProps } from "src/modals/contact-forms-form-builder/types/formFieldsSection"

export const LabelQuestionTextField = observer(
    (props: ILabelQuestionTextFieldProps) => {
        return (
            <TextField
                label={t`contact-form-forms-detail-modal.form-field-section.native-fields-question-label`}
                value={props.item.value}
                onChange={(event) => props.handleFormChange(event)}
                error={Boolean(props.item.errorMessage)}
                helperText={props.item.errorMessage}
            />
        )
    },
)
