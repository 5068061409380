/* eslint-disable import/no-relative-parent-imports */
import { Stack } from "@mui/material"
import { t } from "@lingui/macro"
import { observer } from "mobx-react"

import { Name, Connector } from "../form-fields/index"

import { IAccessGroupDropdownViewProps } from "./types"

import { FormPanel } from "src/components/FormPanel"
import { AccessGroup } from "src/components/ModalAccessGroup/AccessGroup"

export const AccessGroupDropdownAndConnectorView = observer(
    (props: IAccessGroupDropdownViewProps) => (
        <FormPanel
            sections={[
                {
                    header: t`integration-connector-modal.settings`,
                    content: (
                        <Stack spacing={2}>
                            <Name
                                setSaveButtonEnabled={
                                    props.setSaveButtonEnabled
                                }
                            />
                            <AccessGroup
                                disabled={props.disabled}
                                accessGroupId={props.accessGroupId}
                                errorMessage={props.errorMessage}
                            />
                            <Connector
                                resourceType={props.resourceType}
                                isPopUpView={props.isPopUpView}
                            />
                        </Stack>
                    ),
                },
            ]}
        />
    ),
)
