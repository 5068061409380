/**
 * stripTags removes tags from a string of html and returns the text content.
 *
 * @param html html with tags
 * @returns the text content of the provided html
 */
export function stripTags(html: string) {
    const div = document.createElement("div")
    div.innerHTML = html
    return (div.textContent ?? div.innerText).trim()
}
