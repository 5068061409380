import { observer } from "mobx-react"
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    useTheme,
    Box,
    SelectChangeEvent,
    Typography,
    FormHelperText,
} from "@mui/material"
import { t } from "@lingui/macro"

import { EditProductStore } from "src/views/product-catalogue/edit/store"

import { useStore } from "src/store/lib/useStore"
import { Alert16 } from "src/components/icons/Alert16"
import { Icon } from "src/components/icons"
import { Tooltip } from "src/components/Tooltips/Tooltip"

export const ProductType = observer(() => {
    const store = useStore(EditProductStore)
    const theme = useTheme()

    const handleChange = async (event: SelectChangeEvent<string>) => {
        store.formFields.set("productType", event.target.value)
        store.setPricePlans(await store.fetchPricePlans(event.target.value))
    }

    return (
        <Box
            sx={{
                display: "flex",
                gap: "8px",
            }}
        >
            <FormControl style={{ breakInside: "avoid" }}>
                <InputLabel id="product-type-label">{t`edit-product-view.product-type.label`}</InputLabel>
                <Select
                    labelId="product-type-label"
                    id="product-type"
                    value={store.formFields.get("productType") ?? ""}
                    label={t`edit-product-view.product-type.label`}
                    onChange={handleChange}
                    error={Boolean(store.formFields.error("productType"))}
                    disabled={Boolean(store.product.productId)}
                >
                    {store.productTypes.map(
                        (type) =>
                            getMappedType(type) != null && (
                                <MenuItem value={type} key={type}>
                                    {getMappedType(type)}
                                </MenuItem>
                            ),
                    )}
                </Select>
                {Boolean(store.formFields.error("productType")) && (
                    <FormHelperText error={true}>
                        {store.formFields.error("productType")}
                    </FormHelperText>
                )}
            </FormControl>
            <Tooltip title={t`edit-product-view.product-type.tooltip`} arrow>
                <Typography sx={{ marginTop: "16px" }}>
                    <Icon
                        icon={<Alert16 width="20px" height="20px" />}
                        color={theme.palette.grey[700]}
                    />
                </Typography>
            </Tooltip>
        </Box>
    )
})

const getMappedType = (type: string) => {
    const productTypeMapping = {
        purchase: t`edit-product-view.product-type.purchase`,
        quote_request: t`edit-product-view.product-type.quote-request`,
        affiliate: t`edit-product-view.product-type.affiliate`,
        standard_improvement_single: t`edit-product-view.product-type.individual-addition`,
    }

    return productTypeMapping[type as keyof typeof productTypeMapping] ?? null
}
