import { t } from "@lingui/macro"
import { makeAutoObservable } from "mobx"
import React from "react"

import { ChatbotAdminService } from "src/api"
import { Channel } from "src/channel"
import { CustomDataHelper } from "src/api/_custom/services/DataHelper"
import { loads } from "src/channel/utils"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"
import { reportError } from "src/lib/report"
import { IChatbotDispatch } from "src/types/chatbot-dispatches/chatbotDispatch"
import { IAdvanceQueryModel } from "src/types/data-grid-pro"
import { IChatbotDispatchListApiResponse } from "src/views/chatbot-dispatches/types/chatbotDispatchListApiResponse"

export class ChatbotDispatchesStore {
    static Context = React.createContext<ChatbotDispatchesStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "loading")

    _accessGroupId: number = DEFAULT_ACCESS_GROUP.id
    _segments: number[] = []

    private repositoryUpdatesListenerDisposer?: () => void

    dispatches = new Pagination(
        async (params) => {
            const response =
                (await ChatbotAdminService.postV1AdminChatbotDispatchList({
                    request: {
                        filter: params.advanceQuery,
                        page_number: params.page,
                        page_size: params.pageSize,
                        ...CustomDataHelper.PrepareVisibilityData(this),
                    },
                })) as IChatbotDispatchListApiResponse

            const items: IChatbotDispatch[] = (response.dispatches ?? []).map(
                (dispatch) => ({
                    id: dispatch.dispatch_id ?? -1,
                    dispatch_id: dispatch.dispatch_id ?? -1,
                    access_group_id: dispatch.access_group_id ?? -1,
                    name: dispatch.name ?? "",
                    ai_name: dispatch.ai_name ?? "",
                    segment_names: dispatch.segment_names ?? [],
                    access_type: dispatch.access_type ?? "NONE",
                    description: dispatch.description ?? "",
                    message: dispatch.message ?? "",
                    button_text: dispatch.button_text ?? "",
                    dispatch_url: dispatch.data?.dispatch_url ?? "",
                    segment_ids: dispatch.segment_ids ?? [],
                    data: dispatch.data ?? {},
                    created_at: dispatch.created_at ?? "",
                    updated_at: dispatch.updated_at ?? "",
                    dispatch_category: dispatch.data?.dispatch_category,
                }),
            )

            return {
                items,
                count: response.total_count ?? 0,
            }
        },
        {
            loadingKey: ChatbotDispatchesStore.LoadingKeys.loading,
        },
    )

    constructor() {
        makeAutoObservable(this)
    }

    //#region getters
    get segments() {
        return this._segments
    }

    get accessGroupId() {
        return this._accessGroupId
    }
    //#endregion

    //#region setters
    private setSegments(segments: number[]) {
        this._segments = segments
    }

    private setAccessGroup(accessGroupId: number) {
        this._accessGroupId = accessGroupId
    }
    //#endregion

    //#region store operations
    @loads(() => ChatbotDispatchesStore.LoadingKeys.init)
    async init(accessGroupId: number, advanceQuery?: IAdvanceQueryModel) {
        this.listenToMessageRepositoryUpdated()
        this.setAccessGroup(accessGroupId)
        await this.dispatches.loadInitialPage(advanceQuery)
    }

    dispose() {
        this.repositoryUpdatesListenerDisposer?.()
    }

    async loadSegments(segments: number[]) {
        this.setSegments(segments)
        await this.dispatches.loadInitialPage()
    }

    deleteItem = async (dispatchId: number) => {
        try {
            await ChatbotAdminService.deleteV1AdminChatbotDispatch({
                dispatchId: dispatchId.toString(),
            })
        } catch (error) {
            reportError(
                t`library-information-items-view.delete-item-fail`,
                error,
            )
        } finally {
            await this.dispatches.reload()
        }
    }

    async query(advanceQuery: IAdvanceQueryModel) {
        await this.dispatches.loadAdvanceQuery(advanceQuery)
    }

    // events are fired from dispatches modal are listened here
    private listenToMessageRepositoryUpdated() {
        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "chatbot-dispatches"
                ) {
                    await this.dispatches.reload()
                }
            },
        )
    }
    //#endregion
}
