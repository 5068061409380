/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { admin_CloneAdminRequest } from "../models/admin_CloneAdminRequest"
import type { admin_CreateAdminRequest } from "../models/admin_CreateAdminRequest"
import type { admin_CreatePropertyOwnerRequest } from "../models/admin_CreatePropertyOwnerRequest"
import type { admin_CreatePropertyOwnerResponse } from "../models/admin_CreatePropertyOwnerResponse"
import type { admin_CreatePropertyRequest } from "../models/admin_CreatePropertyRequest"
import type { admin_CreatePropertyResponse } from "../models/admin_CreatePropertyResponse"
import type { admin_GetAdminResponse } from "../models/admin_GetAdminResponse"
import type { admin_GetAdminsResponse } from "../models/admin_GetAdminsResponse"
import type { admin_GetLegalEntityResponse } from "../models/admin_GetLegalEntityResponse"
import type { admin_GetPropertyOwnerResponse } from "../models/admin_GetPropertyOwnerResponse"
import type { admin_GetPropertyRequest } from "../models/admin_GetPropertyRequest"
import type { admin_GetPropertyResponse } from "../models/admin_GetPropertyResponse"
import type { admin_PropertyResponse } from "../models/admin_PropertyResponse"
import type { admin_UpdateAccessRequest } from "../models/admin_UpdateAccessRequest"
import type { admin_UpdateAdminRequest } from "../models/admin_UpdateAdminRequest"
import type { admin_UpdatePropertyOwnerRequest } from "../models/admin_UpdatePropertyOwnerRequest"
import type { admin_UpdatePropertyOwnerResponse } from "../models/admin_UpdatePropertyOwnerResponse"
import type { avy_api_pkg_admin_domain_UserDetails } from "../models/avy_api_pkg_admin_domain_UserDetails"
import type { avy_api_pkg_common_LeaseContract } from "../models/avy_api_pkg_common_LeaseContract"
import type { avy_api_pkg_common_LegalEntityCreate } from "../models/avy_api_pkg_common_LegalEntityCreate"
import type { avy_api_pkg_common_UpdateLegalEntity } from "../models/avy_api_pkg_common_UpdateLegalEntity"
import type { avy_api_pkg_common_UpdateProperty } from "../models/avy_api_pkg_common_UpdateProperty"
import type { fastapi_FastAPIRequest } from "../models/fastapi_FastAPIRequest"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class AdminService {
    /**
     * Get admins
     * Get admins by property owner id or legal entity id.
     * @returns admin_GetAdminsResponse OK
     * @throws ApiError
     */
    public static getV1Admin({
        propertyOwnerId,
        legalEntityId,
    }: {
        /** Property owner id **/
        propertyOwnerId?: string
        /** Legal entity id **/
        legalEntityId?: string
    }): CancelablePromise<admin_GetAdminsResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin",
            query: {
                property_owner_id: propertyOwnerId,
                legal_entity_id: legalEntityId,
            },
        })
    }

    /**
     * Create admin
     * Create admin
     * @returns any Created
     * @throws ApiError
     */
    public static postV1Admin({
        request,
    }: {
        /** Request body create admin **/
        request: admin_CreateAdminRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin",
            body: request,
        })
    }

    /**
     * Schedule fastAPI data load request
     * Schedule fastAPI data load request
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminIntegrationFastapiRequest({
        request,
    }: {
        /** Request body **/
        request: fastapi_FastAPIRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/integration/fastapi-request",
            body: request,
        })
    }

    /**
     * Get lease contract
     * Get lease contract by ID
     * @returns avy_api_pkg_common_LeaseContract OK
     * @throws ApiError
     */
    public static getV1AdminLeaseContract({
        leaseContractId,
    }: {
        /** lease contract id **/
        leaseContractId: number
    }): CancelablePromise<avy_api_pkg_common_LeaseContract> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/lease-contract/{lease_contract_id}",
            query: {
                lease_contract_id: leaseContractId,
            },
        })
    }

    /**
     * Get legal entities
     * Get legal entities by property owner id
     * @returns admin_GetLegalEntityResponse OK
     * @throws ApiError
     */
    public static getV1AdminLegalEntity({
        propertyOwnerId,
    }: {
        /** Property owner id **/
        propertyOwnerId: number
    }): CancelablePromise<Array<admin_GetLegalEntityResponse>> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/legal-entity",
            query: {
                property_owner_id: propertyOwnerId,
            },
        })
    }

    /**
     * Create legal entities
     * Create legal entities
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminLegalEntityBatch({
        request,
    }: {
        /** Request body create legal entities **/
        request: Array<avy_api_pkg_common_LegalEntityCreate>
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/legal-entity/batch",
            body: request,
        })
    }

    /**
     * Get legal entity
     * Get legal entity by id
     * @returns admin_GetLegalEntityResponse OK
     * @throws ApiError
     */
    public static getV1AdminLegalEntity1({
        legalEntityId,
    }: {
        /** Legal entity id **/
        legalEntityId: number
    }): CancelablePromise<admin_GetLegalEntityResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/legal-entity/{legal_entity_id}",
            path: {
                legal_entity_id: legalEntityId,
            },
        })
    }

    /**
     * Delete Legal Entity
     * Delete Legal Entity
     * @returns any OK
     * @throws ApiError
     */
    public static deleteV1AdminLegalEntity({
        legalEntityId,
    }: {
        /** Legal Entity ID **/
        legalEntityId: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/legal-entity/{legal_entity_id}",
            path: {
                legal_entity_id: legalEntityId,
            },
        })
    }

    /**
     * Update LegalEntity
     * Update LegalEntity
     * @returns void
     * @throws ApiError
     */
    public static patchV1AdminLegalEntity({
        request,
        legalEntityId,
    }: {
        /** Request body update LegalEntity **/
        request: avy_api_pkg_common_UpdateLegalEntity
        /** LegalEntity ID **/
        legalEntityId: number
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: "PATCH",
            url: "/v1/admin/legal-entity/{legal_entity_id}",
            path: {
                legal_entity_id: legalEntityId,
            },
            body: request,
        })
    }

    /**
     * Expire all contracts in a legal entity
     * Expire all contracts in a legal entity
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminLegalEntityExpireAllContracts({
        legalEntityId,
    }: {
        /** Legal Entity ID **/
        legalEntityId: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/legal-entity/{legal_entity_id}/expire-all-contracts",
            path: {
                legal_entity_id: legalEntityId,
            },
        })
    }

    /**
     * Create properties
     * Create properties
     * @returns admin_CreatePropertyResponse OK
     * @throws ApiError
     */
    public static postV1AdminProperty({
        request,
    }: {
        /** Request body create properties **/
        request: Array<admin_CreatePropertyRequest>
    }): CancelablePromise<admin_CreatePropertyResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/property",
            body: request,
        })
    }

    /**
     * Get property owners
     * Get property owners
     * @returns admin_GetPropertyOwnerResponse OK
     * @throws ApiError
     */
    public static getV1AdminPropertyOwner(): CancelablePromise<
        Array<admin_GetPropertyOwnerResponse>
    > {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/property-owner",
        })
    }

    /**
     * Create property owner
     * Create property owner
     * @returns admin_CreatePropertyOwnerResponse OK
     * @throws ApiError
     */
    public static postV1AdminPropertyOwner({
        request,
    }: {
        /** Request body create property owner **/
        request: admin_CreatePropertyOwnerRequest
    }): CancelablePromise<admin_CreatePropertyOwnerResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/property-owner",
            body: request,
        })
    }

    /**
     * Trigger contract deletion for contract
     * When this endpoint is hit, we will delete contracts that don't look like they are coming back
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminPropertyOwnerTriggerScheduledOffboarding(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/property-owner/trigger-scheduled-offboarding",
        })
    }

    /**
     * Get property owner
     * Get property owner by id
     * @returns admin_GetPropertyOwnerResponse OK
     * @throws ApiError
     */
    public static getV1AdminPropertyOwner1({
        propertyOwnerId,
    }: {
        /** Property owner id **/
        propertyOwnerId: number
    }): CancelablePromise<admin_GetPropertyOwnerResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/property-owner/{property_owner_id}",
            path: {
                property_owner_id: propertyOwnerId,
            },
        })
    }

    /**
     * Update property owner
     * Update property owner
     * @returns admin_UpdatePropertyOwnerResponse OK
     * @throws ApiError
     */
    public static patchV1AdminPropertyOwner({
        propertyOwnerId,
        request,
    }: {
        /** Property owner id **/
        propertyOwnerId: number
        /** Request body create property owner **/
        request: admin_UpdatePropertyOwnerRequest
    }): CancelablePromise<admin_UpdatePropertyOwnerResponse> {
        return __request(OpenAPI, {
            method: "PATCH",
            url: "/v1/admin/property-owner/{property_owner_id}",
            path: {
                property_owner_id: propertyOwnerId,
            },
            body: request,
        })
    }

    /**
     * Get properties
     * Get properties by property owner ids and/or legal entity ids
     * @returns admin_GetPropertyResponse OK
     * @throws ApiError
     */
    public static postV1AdminPropertySearch({
        request,
    }: {
        /** Request body get properties **/
        request: admin_GetPropertyRequest
    }): CancelablePromise<Array<admin_GetPropertyResponse>> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/property/search",
            body: request,
        })
    }

    /**
     * Get Property
     * Get Property
     * @returns admin_PropertyResponse OK
     * @throws ApiError
     */
    public static getV1AdminProperty({
        propertyId,
    }: {
        /** Property ID **/
        propertyId: number
    }): CancelablePromise<admin_PropertyResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/property/{property_id}",
            path: {
                property_id: propertyId,
            },
        })
    }

    /**
     * Delete Property
     * Delete Property
     * @returns any OK
     * @throws ApiError
     */
    public static deleteV1AdminProperty({
        propertyId,
    }: {
        /** Property ID **/
        propertyId: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/property/{property_id}",
            path: {
                property_id: propertyId,
            },
        })
    }

    /**
     * Update Property
     * Update Property
     * @returns void
     * @throws ApiError
     */
    public static patchV1AdminProperty({
        request,
        propertyId,
    }: {
        /** Request body update Property **/
        request: avy_api_pkg_common_UpdateProperty
        /** Property ID **/
        propertyId: number
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: "PATCH",
            url: "/v1/admin/property/{property_id}",
            path: {
                property_id: propertyId,
            },
            body: request,
        })
    }

    /**
     * Expire all contracts in a property
     * Expire all contracts in a property
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminPropertyExpireAllContracts({
        propertyId,
    }: {
        /** Property ID **/
        propertyId: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/property/{property_id}/expire-all-contracts",
            path: {
                property_id: propertyId,
            },
        })
    }

    /**
     * User details
     * Returns details and access right of a logged in user.
     * @returns avy_api_pkg_admin_domain_UserDetails OK
     * @throws ApiError
     */
    public static getV1AdminUserDetails(): CancelablePromise<avy_api_pkg_admin_domain_UserDetails> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/user-details",
        })
    }

    /**
     * Get admin
     * Get admin by id
     * @returns admin_GetAdminResponse OK
     * @throws ApiError
     */
    public static getV1Admin1({
        adminId,
    }: {
        /** Admin ID **/
        adminId: number
    }): CancelablePromise<admin_GetAdminResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/{admin_id}",
            path: {
                admin_id: adminId,
            },
        })
    }

    /**
     * Delete admin
     * Delete admin
     * @returns any OK
     * @throws ApiError
     */
    public static deleteV1Admin({
        adminId,
    }: {
        /** Admin ID **/
        adminId: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/{admin_id}",
            path: {
                admin_id: adminId,
            },
        })
    }

    /**
     * Update admin
     * Update admin
     * @returns void
     * @throws ApiError
     */
    public static patchV1Admin({
        request,
        adminId,
    }: {
        /** Request body update admin **/
        request: admin_UpdateAdminRequest
        /** Admin ID **/
        adminId: number
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: "PATCH",
            url: "/v1/admin/{admin_id}",
            path: {
                admin_id: adminId,
            },
            body: request,
        })
    }

    /**
     * Update admin access
     * Update admin access
     * @returns admin_GetAdminsResponse OK
     * @throws ApiError
     */
    public static putV1AdminAccess({
        request,
        adminId,
    }: {
        /** Request body update admin access **/
        request: admin_UpdateAccessRequest
        /** Admin ID **/
        adminId: number
    }): CancelablePromise<admin_GetAdminsResponse> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/{admin_id}/access",
            path: {
                admin_id: adminId,
            },
            body: request,
        })
    }

    /**
     * Clone admin
     * Clone existing admin with permissions
     * @returns any Created
     * @throws ApiError
     */
    public static postV1AdminClone({
        request,
        adminId,
    }: {
        /** Request body clone existing admin **/
        request: admin_CloneAdminRequest
        /** Admin ID **/
        adminId: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/{admin_id}/clone",
            path: {
                admin_id: adminId,
            },
            body: request,
        })
    }
}
