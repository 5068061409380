import { FileAdminService } from "src/api"

export function isLocalFile(file: IFile): file is ILocalFile {
    return "file" in file && file.file instanceof File
}

export function isPersistedFile(file: IFile): file is IPersistedFile {
    return !isLocalFile(file)
}

/**
 * Persist all local files to object storage and return them as persisted files.
 *
 * @param files A list of both local and persisted files
 * @param type Which type of file to persist @see {@link IPersistedFile}
 * @returns
 */
export async function persistFiles(
    files: IFile[],
    type: IPersistedFile["type"],
): Promise<IPersistedFile[]> {
    const alreadyPersistedFiles = files.filter(isPersistedFile)
    const nonPersistedFiles = files.filter(isLocalFile)
    const newlyPersistedFiles = await Promise.all(
        nonPersistedFiles.map((file) => persistFile(file, type)),
    )
    return [...alreadyPersistedFiles, ...newlyPersistedFiles]
}

export async function persistFile(
    file: ILocalFile,
    type: IPersistedFile["type"],
): Promise<IPersistedFile> {
    const url = await FileAdminService.postV1AdminUpload({
        file: file.file,
    })
    return { url, name: file.file.name, type }
}

export const autoDownloadFile = (
    url: string,
    filename: string,
    testId?: string,
) => {
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", filename)
    testId !== undefined && link.setAttribute("data-testid", testId)
    document.body.appendChild(link)
    link.click()
}
