import { styled } from "@mui/material"

export const DraggableContainer = styled("div")(() => ({
    marginBottom: "20px",
}))

export const DraggableIconContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "6px",
})
export const FabButtonContainer = styled("div")(() => ({
    alignSelf: "center",
}))

export const Separator = styled("div")(() => ({
    border: "1px solid #EFEFEF",
    marginTop: "20px",
}))
