/* eslint-disable import/no-relative-parent-imports */
import { t } from "@lingui/macro"
import { Button, Fab, Stack, useMediaQuery, useTheme } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { ConnectorDropdown } from "src/components/ConnectorDropdown"

import { Add24 } from "src/components/icons/Add24"
import { IntegrationConnectorItemModal } from "src/modals/integration-connector-detail"
import { ResourceDetailStore } from "src/modals/resource-detail/store"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"

export const IntegrationConnector = observer(() => {
    const store = useStore(ResourceDetailStore)
    const gstore = useStore(GlobalStore)

    const theme = useTheme()
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))

    const handleCreateNewConnector = useCallback(() => {
        gstore.modals.open(
            () => (
                <IntegrationConnectorItemModal
                    showPopUpView={true}
                    resourceType={store.fields.get("resourceTypeSelectedValue")}
                />
            ),
            {
                variant: "slide-up-w995",
            },
        )
    }, [gstore.modals, store.fields])

    return (
        <Stack direction="row" alignItems="center" mt={2}>
            <ConnectorDropdown
                connectors={store.fields.get("connectorList") ?? []}
                selectedValue={String(store.fields.get("selectedConnectorId"))}
                onSelectionChange={(value) => {
                    store.fields.set("selectedConnectorId", value)
                    store.fields.set("publicResourceID", "")
                }}
                errorMessage={
                    store.fields.error("selectedConnectorId") as string
                }
                disabled={store.fields.get("accessType") === "READ"}
                width="83%"
            />
            <div>
                {smallScreen ? (
                    <Fab
                        sx={{ marginLeft: "18px" }}
                        data-testid="create-button"
                        variant="circular"
                        color="primary"
                        size="small"
                        disabled={store.fields.get("accessType") === "READ"}
                        onClick={handleCreateNewConnector}
                    >
                        <Add24 />
                    </Fab>
                ) : (
                    <Button
                        sx={{ marginLeft: "8px" }}
                        onClick={handleCreateNewConnector}
                        disabled={store.fields.get("accessType") === "READ"}
                        color="info"
                    >
                        {t`resource-detail-modal.create-new-button`}
                    </Button>
                )}
            </div>
        </Stack>
    )
})
