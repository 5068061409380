import { useEffect } from "react"
import { observer } from "mobx-react"
import { t } from "@lingui/macro"

import { ContactFormSettingsViewStore } from "./store"

import { StoreProvider } from "src/store/lib/StoreProvider"
import { Table } from "src/components/Table"
import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { ListPage } from "src/components/ListPage"
import { ContactFormTypeDetailModal } from "src/modals/contact-form-type-detail"

const View = observer(() => {
    const store = useStore(ContactFormSettingsViewStore)
    const gstore = useStore(GlobalStore)

    useEffect(() => {
        ;(async () => await store.init())()
    }, [store])

    return (
        <ListPage
            header={{
                header: t`contact-form-settings-view.header`,
                breadcrumbs: [
                    t`contact-form-settings-view.contact-forms-breadcrumb`,
                    t`contact-form-settings-view.settings-breadcrumb`,
                ],
            }}
            loading={gstore.loading.is(
                ContactFormSettingsViewStore.LoadingKeys.pageLoad,
            )}
        >
            <Table
                paginator={store.contactFormTypes}
                headers={[
                    {
                        key: "name",
                        name: t`contact-form-settings-view.name-header`,
                    },
                ]}
                rowRenderer={(item) => ({
                    name: item.name,
                })}
                onRowClick={(item) =>
                    gstore.modals.open(() => (
                        <ContactFormTypeDetailModal
                            id={item.id}
                            publishedIn={item.publishedIn}
                            fields={item.fields}
                            messagingEnabled={item.messagingEnabled as boolean}
                        />
                    ))
                }
            />
        </ListPage>
    )
})

export const ContactFormSettingsView = observer(() => (
    <StoreProvider Store={ContactFormSettingsViewStore}>
        <View />
    </StoreProvider>
))
