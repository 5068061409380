import { makeAutoObservable } from "mobx"
import React from "react"

import { TagsAdminService } from "src/api"
import { Channel } from "src/channel"

import { loads } from "src/channel/utils"
import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"

export class TagsComponentStore {
    static Context = React.createContext<TagsComponentStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "loading")

    private repositoryUpdatesListenerDisposer?: () => void

    tags = new Pagination(
        async (params) => {
            const response = await TagsAdminService.postV1AdminTagList({
                request: {
                    page_size: params.pageSize,
                    page_number: params.page,
                },
            })

            const items = (response.tags ?? []).map((tag) => ({
                id: tag.tag_id ?? -1,
                name: tag.name,
                description: tag.description,
            }))

            return {
                items,
                count: response.total_count ?? 0,
            }
        },
        {
            loadingKey: TagsComponentStore.LoadingKeys.loading,
        },
    )

    constructor() {
        makeAutoObservable(this)
    }

    //#region helpers
    getTagsFromIds = (ids?: number[]) =>
        ids !== undefined && ids.length > 0
            ? this.tags.items.filter((tag) => ids.includes(tag.id))
            : []

    //#endregion

    //#region store operations
    @loads(() => TagsComponentStore.LoadingKeys.init)
    async init() {
        await this.tags.loadAllPage()
        this.listenToMessageRepositoryUpdated()
    }

    dispose() {
        this.repositoryUpdatesListenerDisposer?.()
    }

    // events fired from create tags modal are listened here
    private listenToMessageRepositoryUpdated() {
        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "tags"
                ) {
                    await this.tags.reload()
                }
            },
        )
    }
    //#endregion
}
