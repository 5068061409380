/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { campaign_AdminCampaignCreateData } from "../models/campaign_AdminCampaignCreateData"
import type { campaign_AdminCampaignUpdateData } from "../models/campaign_AdminCampaignUpdateData"
import type { campaign_AdminListCampaignsFilter } from "../models/campaign_AdminListCampaignsFilter"
import type { campaign_ListCampaignsResponse } from "../models/campaign_ListCampaignsResponse"
import type { campaign_ListOffersRequest } from "../models/campaign_ListOffersRequest"
import type { campaign_ListOffersResponse } from "../models/campaign_ListOffersResponse"
import type { internal_api_http_admin_campaign_CampaignResponse } from "../models/internal_api_http_admin_campaign_CampaignResponse"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class CampaignAdminService {
    /**
     * Create campaign
     * Creates a campaign
     * @returns internal_api_http_admin_campaign_CampaignResponse OK
     * @throws ApiError
     */
    public static postV1AdminCampaign({
        request,
    }: {
        /** Request body create campaign **/
        request: campaign_AdminCampaignCreateData
    }): CancelablePromise<internal_api_http_admin_campaign_CampaignResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/campaign",
            body: request,
        })
    }

    /**
     * List campaigns
     * Gets list of campaigns for admin
     * @returns campaign_ListCampaignsResponse OK
     * @throws ApiError
     */
    public static postV1AdminCampaignList({
        request,
    }: {
        /** Filter campaigns **/
        request: campaign_AdminListCampaignsFilter
    }): CancelablePromise<campaign_ListCampaignsResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/campaign/list",
            body: request,
        })
    }

    /**
     * List offers
     * Gets the list of all available offers
     * @returns campaign_ListOffersResponse OK
     * @throws ApiError
     */
    public static postV1AdminCampaignOfferList({
        request,
    }: {
        /** Selected segments **/
        request: campaign_ListOffersRequest
    }): CancelablePromise<campaign_ListOffersResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/campaign/offer/list",
            body: request,
        })
    }

    /**
     * Get single campaign
     * Gets campaign for admin
     * @returns internal_api_http_admin_campaign_CampaignResponse OK
     * @throws ApiError
     */
    public static getV1AdminCampaign({
        campaignId,
    }: {
        /** Campaign ID **/
        campaignId: number
    }): CancelablePromise<internal_api_http_admin_campaign_CampaignResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/campaign/{campaign_id}",
            path: {
                campaign_id: campaignId,
            },
        })
    }

    /**
     * Delete campaign
     * Deletes campaign for admin
     * @returns void
     * @throws ApiError
     */
    public static deleteV1AdminCampaign({
        campaignId,
    }: {
        /** Campaign ID **/
        campaignId: number
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/campaign/{campaign_id}",
            path: {
                campaign_id: campaignId,
            },
        })
    }

    /**
     * Update campaign
     * Updates a campaign
     * @returns internal_api_http_admin_campaign_CampaignResponse OK
     * @throws ApiError
     */
    public static patchV1AdminCampaign({
        campaignId,
        request,
    }: {
        /** Campaign ID **/
        campaignId: number
        /** Request body update campaign **/
        request: campaign_AdminCampaignUpdateData
    }): CancelablePromise<internal_api_http_admin_campaign_CampaignResponse> {
        return __request(OpenAPI, {
            method: "PATCH",
            url: "/v1/admin/campaign/{campaign_id}",
            path: {
                campaign_id: campaignId,
            },
            body: request,
        })
    }
}
