import { observer } from "mobx-react"
import { t } from "@lingui/macro"

import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material"

import { IEmbeddedIntegrationDropdownProps } from "src/modals/embedded-integration-v2/types/embeddedIntegrationDropdown"

export const EmbeddedIntegrationDropdown = observer(
    ({
        optionArray,
        selectedValue,
        onSelectionChange,
        errorMessage,
        disabled,
        inputLabel,
        label,
        isNoneOption = false,
    }: IEmbeddedIntegrationDropdownProps) => {
        const labelId = `${inputLabel}-label`
        const dataTestId = `${inputLabel}-dropdown`
        const dropdownId = `${inputLabel}-id`
        return (
            <FormControl style={{ breakInside: "avoid", width: "100%" }}>
                <InputLabel id={labelId}>{inputLabel}</InputLabel>
                <Select
                    data-testid={dataTestId}
                    labelId={labelId}
                    id={dropdownId}
                    value={selectedValue}
                    label={label}
                    onChange={(event) => onSelectionChange(event.target.value)}
                    error={Boolean(errorMessage)}
                    disabled={disabled}
                >
                    {Boolean(isNoneOption) && (
                        <MenuItem value="">
                            {t`embed-integrations-modal.option.none`}
                        </MenuItem>
                    )}
                    {optionArray.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>
                {Boolean(errorMessage) && (
                    <FormHelperText error={true}>{errorMessage}</FormHelperText>
                )}
            </FormControl>
        )
    },
)
