import { observer } from "mobx-react"
import { useCallback, useEffect } from "react"
import { Alert, Button } from "@mui/material"
import { t } from "@lingui/macro"

import { AccessGroupsCreateOrEditStore } from "./store"
import { DeleteSection } from "./DeleteSection"
import { NameSection } from "./NameSection"
import { SegmentsSection } from "./SegmentsSection"

import { StoreProvider } from "src/store/lib/StoreProvider"
import { Form } from "src/components/Form"
import { ModalHeader } from "src/components/ModalHeader"
import { FormPanel } from "src/components/FormPanel"
import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { reportError } from "src/lib/report"
import { useInitializer } from "src/lib/initializer"
import { CenteredSpinner } from "src/components/CenteredSpinner"
import { useCloseConfirmationForForm } from "src/store/modals/use-close-confirmation-for-form"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"

interface IProps {
    id?: number
}

const AccessGroupsCreateOrEdit = observer(({ id }: IProps) => {
    const store = useStore(AccessGroupsCreateOrEditStore)
    const globalStore = useStore(GlobalStore)

    useCloseConfirmationForForm(store.fields)

    const initialized = useInitializer(() => store.init(id), [store, id])

    useEffect(() => {
        if (id != null) {
            trackModuleEvent("AccessGroup | AccessGroup Detail View", {
                [MixpanelProperties.AccessGroupName]:
                    globalStore.session.accessGroup?.name,
                [MixpanelProperties.AccessGroupID]:
                    globalStore.session.accessGroup?.id,
                [MixpanelProperties.ItemID]: id,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const validateRequiredFields = useCallback(
        (requiredFields: string[]) => {
            for (const field of requiredFields) {
                const requiredField = field as keyof { name: string }
                const formField = store.fields.get(requiredField)

                if (formField == null || formField === "") {
                    store.fields.setError(requiredField, t`errors.required`)
                } else {
                    store.fields.clearError(requiredField)
                }
            }
        },
        [store.fields],
    )

    const handleSubmit = useCallback(async () => {
        validateRequiredFields(["name"])
        if (!store.fields.hasErrors()) {
            try {
                if (id != null) {
                    trackModuleEvent("AccessGroup | AccessGroup Update", {
                        [MixpanelProperties.AccessGroupName]:
                            globalStore.session.accessGroup?.name,
                        [MixpanelProperties.AccessGroupID]:
                            globalStore.session.accessGroup?.id,
                        [MixpanelProperties.ItemID]: id,
                    })
                } else {
                    trackModuleEvent("AccessGroup | AccessGroup Create New", {
                        [MixpanelProperties.AccessGroupName]:
                            globalStore.session.accessGroup?.name,
                        [MixpanelProperties.AccessGroupID]:
                            globalStore.session.accessGroup?.id,
                    })
                }
                await store.submit()
            } catch {
                reportError(t`create-edit-access-group.submit-error-message`)
            } finally {
                globalStore.modals.pop()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validateRequiredFields, store, globalStore.modals])

    if (
        !initialized ||
        globalStore.loading.is(AccessGroupsCreateOrEditStore.LoadingKeys.init)
    ) {
        return <CenteredSpinner height="100vh" />
    }

    return (
        <Form onSubmit={handleSubmit}>
            <ModalHeader>
                <Button
                    data-testid={"create/edit-access-group-submit-button"}
                    type="submit"
                    variant="contained"
                    disabled={store.fields.getIsDirty() === false}
                >
                    {t`create-edit-access-group-save-button`}
                </Button>
            </ModalHeader>
            {Boolean(store.fields.error("generic")) && (
                <Alert severity="error">{store.fields.error("generic")}</Alert>
            )}
            <FormPanel
                sections={[
                    {
                        header:
                            id != null
                                ? t`create-edit-access-group-modal.edit-access-group`
                                : t`create-edit-access-group-modal.create-access-group`,
                        content: <NameSection />,
                    },
                ]}
            />
            <FormPanel
                sections={[
                    {
                        header: t`create-edit-access-group-modal.segments-header`,
                        content: <SegmentsSection />,
                    },
                ]}
            />
            {id != null && (
                <FormPanel
                    sections={[
                        {
                            header: t`create-edit-access-group-modal.delete-group-header`,
                            content: <DeleteSection id={id} />,
                        },
                    ]}
                />
            )}
        </Form>
    )
})

export const AccessGroupCreateOrEditModal = (props: IProps) => (
    <StoreProvider Store={AccessGroupsCreateOrEditStore}>
        <AccessGroupsCreateOrEdit {...props} />
    </StoreProvider>
)
