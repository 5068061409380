import { t } from "@lingui/macro"
import { Button } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useEffect } from "react"

import { LibraryCategoriesOrderStore } from "./store"

import { CenteredSpinner } from "src/components/CenteredSpinner"
import { Form } from "src/components/Form"
import { ModalHeader } from "src/components/ModalHeader"
import { LibraryCategoriesOrderOrderingSection } from "src/modals/library-categories-order/sections/Ordering"
import { ILibraryCategoriesOrderModalProps } from "src/modals/library-categories-order/types/librariesCategoriesOrderProps"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"

const Modal = observer((props: ILibraryCategoriesOrderModalProps) => {
    const globalStore = useStore(GlobalStore)
    const store = useStore(LibraryCategoriesOrderStore)

    useEffect(() => {
        store.init(props.categories)
    }, [store, props])

    const handleSubmit = useCallback(async () => {
        if (await store.submit()) {
            globalStore.modals.pop()
        }
    }, [store, globalStore])

    if (globalStore.loading.is(LibraryCategoriesOrderStore.LoadingKeys.init)) {
        return <CenteredSpinner height="100vh" />
    }

    return (
        <Form onSubmit={handleSubmit}>
            <ModalHeader>
                <Button
                    data-testid="library-categories-order-modal-submit-button"
                    type="submit"
                    variant="contained"
                    disabled={globalStore.loading.is(
                        LibraryCategoriesOrderStore.LoadingKeys.submit,
                    )}
                >
                    {t`library-categories-order-modal.submit-button`}
                </Button>
            </ModalHeader>
            <LibraryCategoriesOrderOrderingSection />
        </Form>
    )
})

export const LibraryCategoriesOrderModal = (
    props: ILibraryCategoriesOrderModalProps,
) => (
    <StoreProvider Store={LibraryCategoriesOrderStore}>
        <Modal {...props} />
    </StoreProvider>
)
