import {
    Box,
    styled,
    Tooltip,
    tooltipClasses,
    TooltipProps,
} from "@mui/material"

import { ChipVariant } from "src/components/SegmentPicker/components/SegmentPickerChip/types"

export const AncestorTooltip = styled<(props: TooltipProps) => JSX.Element>(
    ({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ),
)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        background: theme.palette.common.white,
        color: theme.palette.grey[900],
        fontSize: "10px",
        fontWeight: 500,
        filter: "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1))",
        width: "180px",
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white,
    },
}))

export const ChipWrapper = styled(Box)<{
    variant: ChipVariant
}>(({ variant, theme }) => ({
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.05)",
    borderRadius: "1000px",
    padding: "2px 8px",
    cursor: "default",
    ...(variant === "selected"
        ? {
              background: theme.palette.success.main,
              color: theme.palette.common.white,
          }
        : {}),
    ...(variant === "ancestor-selected"
        ? {
              background: theme.palette.warning.main,
              color: theme.palette.common.white,
          }
        : {}),
    ...(variant === "regular"
        ? {
              background: theme.palette.common.white,
              color: theme.palette.grey[900],
          }
        : {}),
}))
