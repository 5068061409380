import { t } from "@lingui/macro"

import { useMemo } from "react"
import { Typography, Box } from "@mui/material"

import {
    IReformulateNodeSectionProps,
    IInfoSectionProps,
} from "src/components/ChatMessageJourney/types"

const InfoSection = ({ title, content }: IInfoSectionProps) => (
    <Box component="section" sx={{ marginBottom: 2 }}>
        <Typography variant="subtitle2" sx={{ marginBottom: 0.5 }}>
            {title}
        </Typography>
        <Typography variant="body2">{content ?? ""}</Typography>
    </Box>
)

const ReformulateNodeSection = ({ message }: IReformulateNodeSectionProps) => {
    const reformulateNodeData = useMemo(() => {
        return message?.nodeInfo?.find(
            (node) => node?.node_name === "ReformulateInputNode",
        )
    }, [message])

    if (reformulateNodeData === undefined) {
        return null
    }

    return (
        <>
            <InfoSection
                title={t`chat-message-journey.language-detected`}
                content={reformulateNodeData.state.language}
            />
            <InfoSection
                title={t`chat-message-journey.reformulated-input`}
                content={reformulateNodeData.state.standaloneMessage}
            />
        </>
    )
}

export default ReformulateNodeSection
