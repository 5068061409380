import React from "react"
import { makeAutoObservable } from "mobx"

import { Channel } from "src/channel"

import { FormFields } from "src/lib/form-fields"
import { loads } from "src/channel/utils"
import { createLoadingKeys } from "src/lib/loading"
import {
    AdminService,
    ConnectorAdminService,
    admin_CreatePropertyOwnerResponse,
    admin_UpdatePropertyOwnerResponse,
} from "src/api"
import { IIntegrationConnectorItem } from "src/views/integration-connector/type"

export interface IFormFields {
    legalName: string
    integrationType: string
    connectorList?: IIntegrationConnectorItem[]
    selectedConnectorId?: number
    mfaRequired?: boolean
    syncTenantContact?: boolean
}
export class CreatePropertyOwnerModalStore {
    static Context = React.createContext<CreatePropertyOwnerModalStore | null>(
        null,
    )
    static LoadingKeys = createLoadingKeys("submit")

    fields = new FormFields<IFormFields>({
        legalName: "",
        integrationType: "other",
        selectedConnectorId: 0,
        mfaRequired: false,
        syncTenantContact: false,
    })

    constructor() {
        makeAutoObservable(this)
    }
    async getAllIntegrations(
        type: string,
    ): Promise<{ items: IIntegrationConnectorItem[] }> {
        const response =
            await ConnectorAdminService.getV1AdminConnectorIntegration({
                type,
            })

        const items: IIntegrationConnectorItem[] =
            response.map((item) => ({
                id: item.integration_id as number,
                accessGroupId: item.access_group_id as number,
                accessType: item.access_type as string,
                createdAt: item.created_at as string,
                deletedAt: item.deleted_at as string,
                integrationId: item.integration_id as number,
                integrationTypeId: item.integration_type_id as number,
                metaData: item.metadata as Record<string, string>,
                name: item.name as string,
                type: item.type as string,
                updatedAt: item.updated_at as string,
            })) ?? []

        return {
            items,
        }
    }

    async getFastApiIntegrations() {
        const response = await this.getAllIntegrations("fastapi")
        this.fields.set("connectorList", response.items)
    }
    @loads(() => CreatePropertyOwnerModalStore.LoadingKeys.submit)
    async submit(isEditMode: boolean, propertyOwnerId: number) {
        await this.fields.catchErrors(async () => {
            const { data } = this.fields
            let response:
                | admin_UpdatePropertyOwnerResponse
                | admin_CreatePropertyOwnerResponse = {}
            if (isEditMode) {
                response = await AdminService.patchV1AdminPropertyOwner({
                    propertyOwnerId,
                    request: {
                        fastapi_connector_integration_id:
                            data.selectedConnectorId,
                        integration_type: data.integrationType,
                        mfa_required: data.mfaRequired,
                        sync_tenant_contact: data.syncTenantContact,
                    },
                })
            } else {
                response = await AdminService.postV1AdminPropertyOwner({
                    request: {
                        legal_name: data.legalName,
                        integration_type: data.integrationType,
                        fastapi_connector_integration_id:
                            data.selectedConnectorId,
                        mfa_required: data.mfaRequired,
                        sync_tenant_contact: data.syncTenantContact,
                    },
                })
            }

            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "property-owners",
                    action: isEditMode ? "create" : "update",
                    item: {
                        id: response.property_owner_id ?? 0 ?? 0,
                        name: data.legalName,
                    },
                },
            })
        })
    }
}
