import { styled } from "@mui/material"

import { StatusCasesV2 } from "src/types/status-casesV2"

export const ListItem = styled("li")(() => ({
    display: "flex",
    flexDirection: "row",
    marginRight: "0px",
}))

export const StatusDot = styled("span", { shouldForwardProp: () => true })<{
    status: StatusCasesV2
}>(({ status, theme }) => ({
    display: "inline-block",
    height: "12px",
    width: "12px",
    marginRight: "8px",
    borderRadius: "10000px",
    background: {
        [StatusCasesV2.Created]: theme.palette.utility.rustRedFaded.main,
        [StatusCasesV2.Done]: theme.palette.grey[300],
        [StatusCasesV2.InProgress]: theme.palette.utility.orangeFaded.main,
        [StatusCasesV2.ExternallyHandled]: theme.palette.grey[300],
        [StatusCasesV2.Unknown]: theme.palette.grey[700],
    }[status],
}))

export const CheckboxParagraph = styled("p")(({ theme }) => ({
    color: theme.palette.text.disabled,
}))
