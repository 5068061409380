import { styled } from "@mui/material"

export const DropdownItemParentContainer = styled("div")(() => ({
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
}))
export const TextFieldContainer = styled("div")(() => ({
    flex: 0.96,
}))
