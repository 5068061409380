import { t } from "@lingui/macro"
import { Stack } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useEffect, useRef } from "react"

import { FileInputButton } from "src/components/AttachmentsField/FileInputButton"
import { useDragAndDrop } from "src/components/AttachmentsField/hooks/useDragAndDrop"
import { IUploadDocumentFieldProps } from "src/components/AttachmentsField/types/uploadDocumentFieldProps"
import { Attach24 } from "src/components/icons/Attach24"

export const UploadDocumentField = observer(
    (props: IUploadDocumentFieldProps) => {
        const inputRef = useRef<HTMLInputElement | null>(null)
        const createdObjectUrls = useRef<string[]>([])

        const addToCreatedUrlObjects = useCallback((file: File) => {
            const url = URL.createObjectURL(file)
            createdObjectUrls.current.push(url)
            return {
                file,
                url,
            }
        }, [])

        const onDrop = useCallback(
            (acceptedFiles: File[]) => {
                const files = acceptedFiles.map((file): IFile => {
                    return addToCreatedUrlObjects(file)
                })
                props.onChange(files)
            },
            [props, addToCreatedUrlObjects],
        )
        const { getRootProps, getInputProps } = useDragAndDrop({
            onDrop,
            onError: props.onError,
            disabled: props.disabled,
        })

        useEffect(() => {
            const urls = createdObjectUrls.current
            return () => {
                urls.forEach((url) => URL.revokeObjectURL(url))
            }
        }, [])

        const handleChange = useCallback(
            (
                event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                >,
            ) => {
                if ("files" in event.target && event.target.files != null) {
                    const files = Array.from(event.target.files).map(
                        (file): IFile => {
                            return addToCreatedUrlObjects(file)
                        },
                    )
                    props.onChange(files)
                }
            },
            [props, addToCreatedUrlObjects],
        )

        return (
            <div {...getRootProps()}>
                <FileInputButton
                    type="button"
                    onClick={() => inputRef.current?.click()}
                    disabled={props.disabled}
                    sx={{ height: "56px" }}
                >
                    <Stack
                        component="span"
                        spacing={1}
                        direction="row"
                        alignItems="center"
                    >
                        <span>
                            <Attach24 />
                        </span>
                        <span>
                            {props.placeholder ??
                                (props.max > 1
                                    ? t`attachments-field-component.upload-document-field.button-multiple`
                                    : t`attachments-field-component.upload-document-field.button-single`)}
                        </span>
                    </Stack>
                </FileInputButton>
                <input
                    {...getInputProps()}
                    data-testid="UploadDocumentField/Input"
                    ref={inputRef}
                    multiple={props.max > 1}
                    style={{ display: "none" }}
                    onChange={handleChange}
                />
            </div>
        )
    },
)
