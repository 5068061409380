export enum ContactFormStatus {
    Sent = "sent",
    InProgress = "in_progress",
    Done = "done",
    SentFallback = "sent_fallback",
    // ContactForm.status is only typed as a string in the api which means that
    // we cannot typecheck the status during compile time. If we get an
    // unsupported status we set the it to Unknown.
    Unknown = "unknown",
}

export enum ContactFormReadUnreadStatus {
    UnRead = "false",
}

export const parseStatus = (status: string): ContactFormStatus => {
    switch (status) {
        case ContactFormStatus.Sent:
        case ContactFormStatus.InProgress:
        case ContactFormStatus.Done:
        case ContactFormStatus.SentFallback:
            return status
        default:
            return ContactFormStatus.Unknown
    }
}
