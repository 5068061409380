import { IIconProps } from "src/components/icons"

export const Building24 = (props: IIconProps) => (
    <svg
        width={14}
        height={20}
        viewBox="0 0 14 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M0.388943 2.91667H13.611C13.7604 2.91676 13.8967 2.82506 13.9616 2.68077C14.0264 2.53648 14.0083 2.36514 13.9151 2.24L12.5921 0.469168C12.3715 0.172149 12.0364 -0.000637449 11.6821 1.76734e-06H2.3178C1.96353 -0.000637449 1.6284 0.172149 1.40782 0.469168L0.0856145 2.24C-0.00756513 2.36503 -0.0257 2.53617 0.0389504 2.6804C0.103601 2.82462 0.239617 2.91644 0.388943 2.91667Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.6109 4.16699H0.388883C0.174109 4.16699 0 4.35354 0 4.58366V18.7503C0 19.4407 0.522326 20.0003 1.16665 20.0003H5.05547C5.27025 20.0003 5.44436 19.8138 5.44436 19.5837V17.917C5.44436 16.9965 6.14079 16.2503 6.99989 16.2503C7.85898 16.2503 8.55542 16.9965 8.55542 17.917V19.5837C8.55542 19.8138 8.72952 20.0003 8.9443 20.0003H12.8331C13.4774 20.0003 13.9998 19.4407 13.9998 18.7503V4.58366C13.9998 4.35354 13.8257 4.16699 13.6109 4.16699ZM3.88883 13.7503C3.88883 13.9804 3.71472 14.167 3.49994 14.167H1.94441C1.72964 14.167 1.55553 13.9804 1.55553 13.7503V12.0837C1.55553 11.3933 2.07786 10.8337 2.72218 10.8337C3.3665 10.8337 3.88883 11.3933 3.88883 12.0837V13.7503ZM3.49994 9.16699C3.71472 9.16699 3.88883 8.98044 3.88883 8.75033V7.08366C3.88883 6.3933 3.3665 5.83366 2.72218 5.83366C2.07786 5.83366 1.55553 6.3933 1.55553 7.08366V8.75033C1.55553 8.98044 1.72964 9.16699 1.94441 9.16699H3.49994ZM8.16653 13.7503C8.16653 13.9804 7.99243 14.167 7.77765 14.167H6.22212C6.00735 14.167 5.83324 13.9804 5.83324 13.7503V12.0837C5.83324 11.3933 6.35556 10.8337 6.99989 10.8337C7.64421 10.8337 8.16653 11.3933 8.16653 12.0837V13.7503ZM7.77765 9.16699C7.99243 9.16699 8.16653 8.98044 8.16653 8.75033V7.08366C8.16653 6.3933 7.64421 5.83366 6.99989 5.83366C6.35556 5.83366 5.83324 6.3933 5.83324 7.08366V8.75033C5.83324 8.98044 6.00735 9.16699 6.22212 9.16699H7.77765ZM12.4442 13.7503C12.4442 13.9804 12.2701 14.167 12.0554 14.167H10.4998C10.2851 14.167 10.1109 13.9804 10.1109 13.7503V12.0837C10.1109 11.3933 10.6333 10.8337 11.2776 10.8337C11.9219 10.8337 12.4442 11.3933 12.4442 12.0837V13.7503ZM12.0554 9.16699C12.2701 9.16699 12.4442 8.98044 12.4442 8.75033V7.08366C12.4442 6.3933 11.9219 5.83366 11.2776 5.83366C10.6333 5.83366 10.1109 6.3933 10.1109 7.08366V8.75033C10.1109 8.98044 10.2851 9.16699 10.4998 9.16699H12.0554Z"
            fill="currentColor"
        />
    </svg>
)
