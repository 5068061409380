import { styled, Typography } from "@mui/material"

export const Text = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[900],
    fontSize: "14px",
    lineHeight: 0.1,
    width: "5px",
    height: "22px",
}))

export const Header = styled("h3")(() => ({
    lineHeight: "32px",
    fontWeight: 500,
    fontSize: "20px",
    paddingLeft: "24px",
}))
export const FooterContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingRight: "16px",
}))

export const BoxContainer = styled("div")(() => ({
    paddingRight: "16px",
    paddingLeft: "16px",
}))

export const DynamicBoxContainer = styled("div")(() => ({
    paddingRight: "16px",
    paddingLeft: "16px",
    paddingTop: "16px",
}))
