import { t } from "@lingui/macro"
import { FormHelperText, IconButton } from "@mui/material"

import { ImagePreview } from "src/components/AssetAttachmentsField/ImageAttachments/ImagePreview"
import { ImagePreviewHoverOptions } from "src/components/AssetAttachmentsField/ImageAttachments/ImagePreviewHoverOptions"
import { View24 } from "src/components/icons/View24"
import { Bin24 } from "src/components/icons/Bin24"
import { UploadImageField } from "src/components/AssetAttachmentsField/ImageAttachments/UploadImageField"

import { IImageHandlerProps } from "src/components/AssetAttachmentsField/types/imageSelectionProps"

import { Wrapper } from "src/components/AssetAttachmentsField/styled"

export default function ImageSelectionSection({
    imageUrl,
    onImageDelete,
    onImageChange,
    errorMessage,
    setErrorMessage,
    acceptedFormats,
    aspectRatio,
    disabled,
    dimensions,
    wrapperWidth,
}: IImageHandlerProps) {
    return (
        <Wrapper width={wrapperWidth}>
            {imageUrl != null && imageUrl !== "" ? (
                <ImagePreview url={imageUrl ?? ""} aspectRatio={aspectRatio}>
                    <ImagePreviewHoverOptions>
                        <IconButton
                            href={imageUrl ?? ""}
                            target="_blank"
                            color="inherit"
                            title={t`common.open-in-new-tab`}
                        >
                            <View24 />
                        </IconButton>
                        <IconButton
                            onClick={onImageDelete}
                            color="inherit"
                            title={t`common.delete`}
                        >
                            <Bin24 />
                        </IconButton>
                    </ImagePreviewHoverOptions>
                </ImagePreview>
            ) : (
                <UploadImageField
                    onChange={onImageChange}
                    aspectRatio={aspectRatio}
                    dimensions={dimensions}
                    acceptedFormats={
                        acceptedFormats ?? [
                            "image/png",
                            "image/jpeg",
                            "image/jpg",
                            "image/svg+xml",
                        ]
                    }
                    setErrorMessage={setErrorMessage}
                    disabled={disabled ?? false}
                />
            )}
            {Boolean(errorMessage) && (
                <FormHelperText error={true}>{errorMessage}</FormHelperText>
            )}
        </Wrapper>
    )
}
