import { makeAutoObservable } from "mobx"
import React from "react"

import { SessionStore } from "./session"

import { LoadingStore } from "src/store/loading"
import { ModalsStore } from "src/store/modals"
import { FeatureStore } from "src/store/features"
import { NavigationStore } from "src/store/navigation"

export class GlobalStore implements IDisposable {
    static Context = React.createContext<GlobalStore | null>(null)

    session = new SessionStore()
    loading = new LoadingStore()
    modals = new ModalsStore()
    features = new FeatureStore()
    navigation = new NavigationStore(this.session, this.features)

    constructor() {
        makeAutoObservable(this)
    }

    dispose() {
        this.session.dispose()
        this.loading.dispose()
    }
}
