import * as Sentry from "@sentry/browser"

import { variables, environment } from "src/config/variables"

if (variables.SENTRY_DSN != null) {
    Sentry.init({
        dsn: variables.SENTRY_DSN,
        environment,
    })
}
