import { t } from "@lingui/macro"
import { Stack } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { DocumentAttachments } from "src/components/AttachmentsField/DocumentAttachments"
import { ImageAttachments } from "src/components/AttachmentsField/ImageAttachments"
import { IAttachmentFieldProps } from "src/components/AttachmentsField/types/attachmentsFieldProps"
import { FormFieldSet } from "src/components/FormFieldSet"

const DEFAULT_MAX_IMAGES = 5
const DEFAULT_MAX_DOCUMENTS = 5

/**
 * AttachmentsField
 */
export const AttachmentsField = observer((props: IAttachmentFieldProps) => {
    const maxImages = props.maxImages ?? DEFAULT_MAX_IMAGES
    const maxDocuments = props.maxDocuments ?? DEFAULT_MAX_DOCUMENTS

    const handleImageChange = useCallback(
        (images: IFile[]) => {
            props.onChange(images.slice(0, maxImages), props.documents, "image")
        },
        [props, maxImages],
    )

    const handleDocumentChange = useCallback(
        (documents: IFile[]) => {
            props.onChange(
                props.images,
                documents.slice(0, maxDocuments),
                "document",
            )
        },
        [props, maxDocuments],
    )

    return (
        <Stack spacing={4}>
            <FormFieldSet
                header={t({
                    id: "attachments-field-component.image-attachments.header",
                    values: { count: maxImages },
                })}
            >
                <ImageAttachments
                    value={props.images}
                    onChange={handleImageChange}
                    max={maxImages}
                    readOnly={props.disabled}
                />
            </FormFieldSet>
            <FormFieldSet
                header={t`attachments-field-component.document-attachments.header`}
                tooltip={t`attachments-field-component.document-attachments.tooltip`}
            >
                <DocumentAttachments
                    value={props.documents}
                    onChange={handleDocumentChange}
                    max={maxDocuments}
                    readOnly={props.disabled}
                    onError={props.onError}
                    documentError={props.documentError}
                />
            </FormFieldSet>
        </Stack>
    )
})
