import OutlinedInput from "@mui/material/OutlinedInput"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import ListItemText from "@mui/material/ListItemText"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import Checkbox from "@mui/material/Checkbox"
import { observer } from "mobx-react"
import { useCallback, useState } from "react"

import { IProps } from "src/components/MultipleSelectCheckmarks/types"
import { MENU_PROPS } from "src/components/MultipleSelectCheckmarks/constants"

export const MultipleSelectCheckmarks = observer(
    ({ options, label, onChange }: IProps) => {
        const [selectedOptions, setSelectedOptions] = useState<string[]>([])

        const handleChange = useCallback(
            (event: SelectChangeEvent<typeof selectedOptions>) => {
                const {
                    target: { value },
                } = event
                setSelectedOptions(
                    typeof value === "string" ? value.split(",") : value,
                )

                const selectedOptions = options.filter((option) =>
                    value.includes(option.value),
                )
                onChange(selectedOptions)
            },
            [onChange, options],
        )

        const renderSelectedValue = useCallback(
            (selected: string[]) => {
                const selectedNames = selected
                    .map(
                        (value) =>
                            options.find((option) => option.value === value)
                                ?.name,
                    )
                    .filter((name) => name !== undefined) as string[]
                return selectedNames.join(", ")
            },
            [options],
        )

        return (
            <FormControl sx={{ m: 1, width: 280 }}>
                <InputLabel
                    id="multi-select-dropdown-label"
                    data-testid="multi-select-dropdown-label"
                >
                    {label}
                </InputLabel>
                <Select
                    labelId="multi-select-dropdown-label"
                    data-testid="multi-select-dropdown"
                    multiple
                    value={selectedOptions}
                    onChange={handleChange}
                    input={<OutlinedInput label={label} />}
                    renderValue={renderSelectedValue}
                    MenuProps={MENU_PROPS}
                >
                    {options.map((option, index) => (
                        <MenuItem
                            key={index}
                            value={option.value}
                            data-testid="menu-options"
                        >
                            <Checkbox
                                checked={
                                    selectedOptions.indexOf(option.value) > -1
                                }
                            />
                            <ListItemText
                                primary={option.name}
                                data-testid={`option-${index}`}
                            />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    },
)
