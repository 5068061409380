import {
    FormControlLabel,
    Paper as MUIPaper,
    Stack,
    styled,
    Switch,
} from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { Slider } from "src/components/WeekdayRangePicker/Slider"
import { createDefaultRange } from "src/components/WeekdayRangePicker/utils"

const Paper = styled(MUIPaper)({ padding: "8px 24px 8px 16px" })

const StaticLabel = styled("span")({ lineHeight: 2.4 })

interface IProps {
    step: number
    label: string
    value: number[] | null
    onChange: (value: number[] | null) => void
    togglable?: false
    disabled?: boolean
}

export const SliderPanel = observer(({ onChange, step, ...props }: IProps) => {
    const handleToggleChange = useCallback(
        (_: unknown, checked: boolean) => {
            if (checked) {
                onChange(createDefaultRange(step))
            } else {
                onChange(null)
            }
        },
        [onChange, step],
    )

    return (
        <Paper elevation={0}>
            <Stack
                direction={{
                    xs: "column",
                    sm: "row",
                }}
                spacing={2}
                justifyContent="space-between"
                alignItems="flex-start"
            >
                <Stack direction="row" alignItems="center" minWidth="130px">
                    {props.togglable !== false ? (
                        <FormControlLabel
                            label={props.label}
                            control={
                                <Switch
                                    color="success"
                                    checked={props.value != null}
                                    onChange={handleToggleChange}
                                    disabled={props.disabled}
                                />
                            }
                        />
                    ) : (
                        <StaticLabel>{props.label}</StaticLabel>
                    )}
                </Stack>
                {props.value != null && (
                    <Slider
                        step={step}
                        value={props.value}
                        onChange={onChange}
                        disabled={props.disabled}
                    />
                )}
            </Stack>
        </Paper>
    )
})
