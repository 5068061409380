import { FormTypeDropdownKeys } from "src/modals/contact-forms-form-builder/types/formTypeDropdownValues"

export const shouldHaveOptionsArray = (value: string) => {
    switch (value) {
        case FormTypeDropdownKeys.RadioButton:
            return true
        case FormTypeDropdownKeys.Checkbox:
            return true
        case FormTypeDropdownKeys.Dropdown:
            return true
        default:
            return false
    }
}
