import React from "react"
import { makeAutoObservable } from "mobx"
import { t } from "@lingui/macro"

import { FormFields } from "src/lib/form-fields"
import { reportError } from "src/lib/report"
import { AuthenticationAdminService } from "src/api"

interface IFormFields {
    email: string
}

export class ForgotPasswordStore {
    static Context = React.createContext<ForgotPasswordStore | null>(null)
    isLoading = false
    fields = new FormFields<IFormFields>({
        email: "",
    })
    isSuccess = false

    constructor() {
        makeAutoObservable(this)
    }

    private setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading
    }

    private setIsSuccess(isSuccess: boolean) {
        this.isSuccess = isSuccess
    }

    async resetPassword() {
        this.setIsLoading(true)
        this.fields.clearErrors()

        try {
            await AuthenticationAdminService.postV1AdminAuthResetPassword({
                request: {
                    email: this.fields.get("email"),
                },
            })

            this.setIsSuccess(true)
        } catch (error) {
            reportError(t`forgot-password-view.reset-password-fail`, error)
        } finally {
            this.setIsLoading(false)
        }
    }
}
