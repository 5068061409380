import { styled } from "@mui/material"
import QRCode from "react-qr-code"

export const QRCodeStyled = styled(QRCode)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        width: "50%",
        height: "auto",
        maxWidth: "50%",
    },
    [theme.breakpoints.down("xs")]: {
        width: "100%",
        height: "auto",
        maxWidth: "100%",
    },
}))
