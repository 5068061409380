import React from "react"
import { t } from "@lingui/macro"

import { reportError } from "src/lib/report"
import { loads } from "src/channel/utils"
import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"
import { AdminService } from "src/api"
import { Channel } from "src/channel"

export interface IProperty {
    city: string
    createdDate: string
    externalId: string
    integrationId: number | undefined
    integrationActive: boolean
    integrationType: string
    leaflet: string
    legalEntityId: number
    modifiedDate: string
    name: string
    propertyId: number
    propertyOwnerId: number
    selfOnboardingCode: string
    selfOnboardingEnabled: boolean
    id: string | number
}

export class PropertyStore {
    static Context = React.createContext<PropertyStore | null>(null)
    static LoadingKeys = createLoadingKeys("pageLoad", "init", "fastApiRequest")

    private repositoryUpdatesListenerDisposer?: () => void
    private _properties: IProperty[] = []
    property = new Pagination(
        (params) => {
            const pageStart = (params.page - 1) * params.pageSize
            const pageProperties = this._properties.slice(
                pageStart,
                pageStart + params.pageSize,
            )
            return {
                items: pageProperties,
                count: this._properties.length,
                sourceItems: this._properties,
            }
        },
        { static: true },
    )

    private _propertyOwnerId: number | undefined = undefined

    get propertyOwnerId() {
        return this._propertyOwnerId
    }

    private _legalEntityId: number | undefined = undefined

    get legalEntityId() {
        return this._legalEntityId
    }

    setPropertyOwnerId = (propertyOwnerId: number) => {
        this._propertyOwnerId = propertyOwnerId
    }

    setLegalEntityId = (legalEntityId: number) => {
        this._legalEntityId = legalEntityId
    }

    setProperties(properties: IProperty[]) {
        this._properties = properties
    }

    @loads(() => PropertyStore.LoadingKeys.init)
    async init(
        propertyOwnerId: number | undefined,
        legalEntityId: number | undefined,
    ) {
        if (propertyOwnerId != null) {
            this.setPropertyOwnerId(propertyOwnerId)
        }

        if (legalEntityId != null) {
            this.setLegalEntityId(legalEntityId)
        }
        this.listenToResourcesRepositoryUpdated()
        await this.loadProperties()
    }

    dispose() {
        this.repositoryUpdatesListenerDisposer?.()
    }

    @loads(() => PropertyStore.LoadingKeys.fastApiRequest)
    async propertyFastApiRequest(
        propertyOwnerId: number,
        propertyId: number,
        fullLoad: boolean,
    ) {
        await AdminService.postV1AdminIntegrationFastapiRequest({
            request: {
                property_owner_id: propertyOwnerId,
                property_id: propertyId,
                full_load: fullLoad,
            },
        })
    }

    async offboardPropertyRequest(propertyId: number) {
        await AdminService.postV1AdminPropertyExpireAllContracts({
            propertyId: propertyId,
        })
    }

    private listenToResourcesRepositoryUpdated() {
        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "properties"
                ) {
                    await this.loadProperties()
                }
            },
        )
    }

    private async loadProperties() {
        try {
            const propertyResponse =
                this.legalEntityId != null && this.propertyOwnerId != null
                    ? await AdminService.postV1AdminPropertySearch({
                          request: {
                              legal_entity_ids: [this.legalEntityId],
                              property_owner_ids: [this.propertyOwnerId],
                          },
                      })
                    : []
            this.setProperties(
                propertyResponse.map(
                    (legalEntity): IProperty => ({
                        city: legalEntity.city ?? "",
                        createdDate: legalEntity.created_date ?? "",
                        externalId: legalEntity.external_id ?? "",
                        integrationId: legalEntity.integration_id ?? undefined,
                        integrationActive:
                            legalEntity.integration_active ?? false,
                        integrationType: legalEntity.integration_type ?? "",
                        leaflet: legalEntity.leaflet ?? "",
                        legalEntityId: legalEntity.legal_entity_id ?? 0,
                        modifiedDate: legalEntity.modified_date ?? "",
                        name: legalEntity.name ?? "",
                        propertyId: legalEntity.property_id ?? 0,
                        propertyOwnerId: legalEntity.property_owner_id ?? 0,
                        selfOnboardingCode:
                            legalEntity.self_onboarding_code ?? "",
                        selfOnboardingEnabled:
                            legalEntity.self_onboarding_enabled ?? false,
                        id: legalEntity.property_id ?? 0,
                    }),
                ) ?? [],
            )
        } catch (e) {
            reportError(
                t`client-management-properties-table.failed-to-load-properties`,
                e,
            )
        }
        await this.property.reload()
    }
}
