import { t } from "@lingui/macro"
import { FormControlLabel, Stack, Switch, TextField } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useEffect, useState } from "react"

import { ResourceDetailStore } from "./store"

import { FormFieldSet } from "src/components/FormFieldSet"
import { IntegrationConnector } from "src/modals/resource-detail/components/IntegrationConnector"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { IIntegrationConnectorItem } from "src/views/integration-connector/type"

export const DigitalAccessIntegrationSection = observer(() => {
    const gstore = useStore(GlobalStore)
    const store = useStore(ResourceDetailStore)

    const [selectedConnector, setSelectedConnector] = useState<
        IIntegrationConnectorItem | undefined
    >(undefined)

    const selectedConnectorID = store.fields.get("selectedConnectorId")
    const connectorList = store.fields.get("connectorList")

    useEffect(() => {
        ;(async () => {
            if (gstore.session.hasAccessToModule("accessy")) {
                await store.getAccessyIntegrations()
            }

            if (gstore.session.hasAccessToModule("parakey")) {
                store.addParakeyConnectorToList()
            }
        })()
    }, [store, gstore.session])

    useEffect(() => {
        const connector = (connectorList ?? []).find(
            (d) => d.id === selectedConnectorID,
        )

        setSelectedConnector(connector)
        store.fields.set("parakeyEnabled", connector?.type === "parakey")
    }, [selectedConnectorID, connectorList, store])

    const handleDigitalAccessIntegrationChange = useCallback(
        (_: unknown, checked: boolean) => {
            store.fields.set("digitalAccessEnabled", checked)
            store.resetDigitalAccessSection()
        },
        [store],
    )

    return (
        <FormFieldSet>
            <Stack spacing={2}>
                <FormControlLabel
                    checked={store.fields.get("digitalAccessEnabled")}
                    onChange={handleDigitalAccessIntegrationChange}
                    control={<Switch color="success" />}
                    label={t`resource-detail-modal.digital-access-integration-section.access-key-enabled-label`}
                    disabled={store.fields.get("accessType") === "READ"}
                />
            </Stack>
            {store.fields.get("digitalAccessEnabled") && (
                <IntegrationConnector />
            )}
            {selectedConnector !== undefined &&
                selectedConnector.type === "accessy" && (
                    <Stack mt={2}>
                        <TextField
                            label={t`resource-detail-modal.digital-access-integration-section.integration-uuid-label`}
                            value={store.fields.get("publicResourceID")}
                            onChange={(event) =>
                                store.fields.set(
                                    "publicResourceID",
                                    event.target.value,
                                )
                            }
                            helperText={store.fields.error("publicResourceID")}
                            error={Boolean(
                                store.fields.error("publicResourceID"),
                            )}
                            disabled={store.fields.get("accessType") === "READ"}
                        />
                    </Stack>
                )}
        </FormFieldSet>
    )
})
