import { t } from "@lingui/macro"
import { Stack, TextField } from "@mui/material"
import { observer } from "mobx-react"

import { FormPanel } from "src/components/FormPanel"
import { formFields } from "src/modals/library-information-items/formFields"
import { LibraryInformationItemStore } from "src/modals/library-information-items/store"
import { useStore } from "src/store/lib/useStore"

export const LibraryInformationItemsContentSection = observer(() => {
    const store = useStore(LibraryInformationItemStore)
    const selectedType = store.formFields.get("type")

    return (
        <FormPanel
            sections={[
                {
                    header: t`edit-information-item-modal.content`,
                    content: (
                        <Stack spacing={2}>
                            <TextField
                                label={t`edit-information-item-modal.item-name-in-app`}
                                onChange={store.formFields.setter("nameInApp")}
                                value={store.formFields.get("nameInApp") ?? ""}
                                helperText={
                                    store.formFields.error("nameInApp") ??
                                    t`edit-information-item-modal.item-name-in-app-helper`
                                }
                                error={Boolean(
                                    store.formFields.error("nameInApp"),
                                )}
                                disabled={
                                    store.formFields.get("accessType") ===
                                    "READ"
                                }
                                inputProps={{ maxLength: 40 }}
                            />
                            {selectedType === "url" && (
                                <TextField
                                    label={t`edit-information-item-modal.link-url`}
                                    onChange={store.formFields.setter("url")}
                                    value={store.formFields.get("url") ?? ""}
                                    helperText={store.formFields.error("url")}
                                    error={Boolean(
                                        store.formFields.error("url"),
                                    )}
                                    disabled={
                                        store.formFields.get("accessType") ===
                                        "READ"
                                    }
                                />
                            )}
                            {selectedType === "document" && (
                                <formFields.LibraryInformationItemsDocument />
                            )}
                            {selectedType === "page" && (
                                <formFields.LibraryInformationItemsPage />
                            )}
                        </Stack>
                    ),
                },
            ]}
        />
    )
})
