import { observer } from "mobx-react"
import React, { useCallback, useEffect, useState } from "react"

import { t } from "@lingui/macro"

import { IntegrationConnectorStore } from "./store"

import { IIntegrationConnectorItem, IIntegrationConnectorRowItem } from "./type"

import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { DataGridTable } from "src/components/Table/DataGrid"
import { ListPage } from "src/components/ListPage"
import { IntegrationConnectorItemModal } from "src/modals/integration-connector-detail"

const View = observer(() => {
    const vstore = useStore(IntegrationConnectorStore)
    const gstore = useStore(GlobalStore)
    const [pageSize, setPageSize] = useState(10)
    const [data, setData] = useState<IIntegrationConnectorItem[]>([])

    const header = {
        header: t`integration-connector-view.header`,
        breadcrumbs: [],
        createOptions: {
            onClick: () =>
                gstore.modals.open(() => (
                    <IntegrationConnectorItemModal showPopUpView={false} />
                )),
            item: "",
        },
        disabled: false,
    }

    useEffect(() => {
        ;(async () => {
            await vstore.init()
        })()
    }, [vstore])

    useEffect(() => {
        setData(vstore.result.items)
    }, [vstore.result.items])

    const handleEditItem = useCallback(
        (item?: IIntegrationConnectorRowItem) => {
            if (item != null) {
                gstore.modals.open(() => (
                    <IntegrationConnectorItemModal
                        id={item.row.integrationId}
                        showPopUpView={false}
                    />
                ))
            }
        },
        [gstore.modals],
    )

    const columns = [
        {
            field: "name",
            headerName: t`integration-connector-view.column-header-name`,
            flex: 1,
        },
        {
            field: "type",
            headerName: t`integration-connector-view.column-header-type`,
            flex: 1,
        },
    ]
    return (
        <ListPage
            header={header}
            loading={gstore.loading.is(
                IntegrationConnectorStore.LoadingKeys.pageLoad,
            )}
        >
            <DataGridTable
                paginator={vstore.result}
                data={data}
                columns={columns}
                minHeight
                setPageSize={setPageSize}
                pageSize={pageSize}
                isClientSidePagination={true}
                onRowClickEvent={handleEditItem}
            />
        </ListPage>
    )
})

export const IntegrationConnectorView = () => (
    <StoreProvider Store={IntegrationConnectorStore}>
        <View />
    </StoreProvider>
)
