import { observer } from "mobx-react"
import { TextField, Box, useTheme, Typography } from "@mui/material"
import { t } from "@lingui/macro"

import { EditProductStore } from "src/views/product-catalogue/edit/store"
import { useStore } from "src/store/lib/useStore"
import { Alert16 } from "src/components/icons/Alert16"
import { Icon } from "src/components/icons"
import { Tooltip } from "src/components/Tooltips/Tooltip"

export const AdminName = observer(() => {
    const store = useStore(EditProductStore)
    const theme = useTheme()

    return (
        <Box
            sx={{ display: "flex", gap: "8px" }}
            style={{ breakInside: "avoid" }}
        >
            <TextField
                label={t`edit-product-view.internal-product-name.label`}
                value={store.formFields.get("adminName") ?? ""}
                onChange={store.formFields.setter("adminName")}
                helperText={store.formFields.error("adminName")}
                error={Boolean(store.formFields.error("adminName"))}
            />
            <div>
                <Tooltip
                    title={t`edit-product-view.internal-product-name.tooltip`}
                    arrow
                >
                    <Typography sx={{ marginTop: "16px" }}>
                        <Icon
                            icon={<Alert16 width="20px" height="20px" />}
                            color={theme.palette.grey[700]}
                        />
                    </Typography>
                </Tooltip>
            </div>
        </Box>
    )
})
