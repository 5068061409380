import { IAntIconProps } from "./props"

export const EmbeddedIntegrationsIcon = (props: IAntIconProps) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M12 4.25C12.4142 4.25 12.75 3.91421 12.75 3.5C12.75 3.08579 12.4142 2.75 12 2.75C11.5858 2.75 11.25 3.08579 11.25 3.5C11.25 3.91421 11.5858 4.25 12 4.25Z"
            fill="currentColor"
        />
        <path
            d="M19 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H5C4.86 3 4.73 3.01 4.6 3.04C4.21 3.12 3.86 3.32 3.59 3.59C3.41 3.77 3.26 3.99 3.16 4.23C3.06 4.46 3 4.72 3 5V19C3 19.27 3.06 19.54 3.16 19.78C3.26 20.02 3.41 20.23 3.59 20.42C3.86 20.69 4.21 20.89 4.6 20.97C4.73 20.99 4.86 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM10.3 14.88C9.91 15.27 9.27 15.27 8.88 14.88L6.71 12.71C6.32 12.32 6.32 11.69 6.71 11.3L8.88 9.13C9.27 8.74 9.91 8.74 10.3 9.13C10.69 9.52 10.69 10.15 10.3 10.54L8.83 12L10.29 13.46C10.68 13.85 10.69 14.49 10.3 14.88ZM12 4.25C11.59 4.25 11.25 3.91 11.25 3.5C11.25 3.09 11.59 2.75 12 2.75C12.41 2.75 12.75 3.09 12.75 3.5C12.75 3.91 12.41 4.25 12 4.25ZM13.7 14.88C13.31 14.49 13.31 13.86 13.7 13.47L15.17 12L13.7 10.53C13.31 10.14 13.31 9.51 13.7 9.12C14.09 8.73 14.73 8.73 15.12 9.12L17.29 11.29C17.68 11.68 17.68 12.31 17.29 12.7L15.12 14.87C14.73 15.27 14.09 15.27 13.7 14.88Z"
            fill="currentColor"
        />
    </svg>
)
