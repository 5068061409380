import { useCallback } from "react"
import { FileRejection, useDropzone } from "react-dropzone"

import {
    ACCEPTED_FILE_TYPE,
    ACCEPTED_FILE_TYPE_EXTENSION,
    MAX_SIZE,
} from "src/components/AttachmentsField/constants/documentAttachments"
import { IUseDragAndDropProps } from "src/components/AttachmentsField/types/useDragAndDropProps"

export const useDragAndDrop = (props: IUseDragAndDropProps) => {
    const formatMessage = (fileName: string, message: string) => {
        return `${fileName}: ${message}`
    }

    const handleError = useCallback((reason: FileRejection[]) => {
        if (reason.length === 1) {
            return formatMessage(
                reason[0].file.name,
                reason[0].errors[0].message,
            )
        }

        let errorMessage = ""
        reason.forEach((reason) => {
            errorMessage +=
                formatMessage(reason.file.name, reason.errors[0].message) + "\n"
        })

        return errorMessage
    }, [])

    const onDropRejected = useCallback(
        (reason: FileRejection[]) => {
            const message = handleError(reason)
            props.onError(message)
        },
        [props, handleError],
    )

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: props.onDrop,
        maxSize: MAX_SIZE,
        accept: {
            [ACCEPTED_FILE_TYPE]: [ACCEPTED_FILE_TYPE_EXTENSION],
        },
        onDropRejected,
        disabled: props.disabled,
    })

    return { getRootProps, getInputProps }
}
