import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useCallback, useEffect, useMemo } from "react"

import { IListPageProps, ListPage } from "src/components/ListPage"
import { Table } from "src/components/Table"
import { reportError } from "src/lib/report"
import { ResourceDetailModal } from "src/modals/resource-detail"
import { GlobalStore } from "src/store"

import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { ResourcesStore } from "src/views/resources/store"

import { AccessTypeHeader } from "src/components/AccessTypeHeader"
import { ResourceType } from "src/types/resource"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"

const View = observer(() => {
    const vstore = useStore(ResourcesStore)
    const gstore = useStore(GlobalStore)

    useEffect(() => {
        ;(async () => {
            await vstore.init(gstore.session.accessGroupId)
        })()

        return () => {
            vstore.dispose()
        }
    }, [vstore, gstore.session.accessGroupId])

    const typeTranslations = {
        [ResourceType.BookingEngine]: t`resources-view.type-booking`,
        [ResourceType.Integration]: t`resources-view.type-integration`,
    }

    const header = useMemo(
        (): IListPageProps["header"] => ({
            header: t`resources-view.header`,
            breadcrumbs: [
                t`resources-view.bookings-breadcrumb`,
                t`resources-view.resources-breadcrumb`,
            ],
            createOptions: {
                onClick: () => {
                    gstore.modals.open(() => <ResourceDetailModal />)
                },
                item: "a new resource",
            },
        }),
        [gstore.modals],
    )

    const getPublishedIn = useCallback((publishedIn?: string[]) => {
        if (publishedIn == null) {
            return ""
        } else if (publishedIn.length === 1) {
            return publishedIn[0]
        } else {
            return t({
                id: "resource-view.published-in-value",
                values: { count: publishedIn.length },
            })
        }
    }, [])

    const duplicateResourceClickHandler = useCallback(
        (id: number) => async () => {
            try {
                await vstore.duplicateResource(
                    id,
                    gstore.session.accessGroup?.name,
                )
            } catch (e) {
                reportError(t`resource-view.failed-to-duplicate-resource`, e)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [vstore],
    )

    const deleteResourceClickHandler = useCallback(
        (id: number, itemName: string, resourceType: string) => async () => {
            try {
                await vstore.deleteResource(id)
                trackModuleEvent("Booking | Delete resource", {
                    [MixpanelProperties.ItemID]: id,
                    [MixpanelProperties.AccessGroupName]:
                        gstore.session.accessGroup?.name,
                    [MixpanelProperties.ItemName]: itemName,
                    [MixpanelProperties.ResourceType]: resourceType,
                })
            } catch (e) {
                reportError(t`resource-view.failed-to-delete-resource`, e)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [vstore],
    )

    const loading = gstore.loading.is(ResourcesStore.LoadingKeys.pageLoad)

    return (
        <ListPage header={header} loading={loading}>
            <Table
                paginator={vstore.resources}
                headers={[
                    { key: "name", name: t`resource-view.name-column` },
                    {
                        key: "publicResourceId",
                        name: t`resource-view.public-resource-id-column`,
                        hidden:
                            !gstore.session.hasAccessToModule("parakey") &&
                            !gstore.session.hasAccessToModule("accessy"),
                    },
                    {
                        key: "resourceType",
                        name: t`resource-view.public-resource-type-column`,
                    },
                    {
                        key: "publishedIn",
                        name: t`resource-view.published-in-column`,
                    },
                    {
                        key: "accessType",
                        name: t`bookings-view.access-type`,
                    },
                ]}
                rowRenderer={(row) => ({
                    name: row.name,
                    publishedIn: getPublishedIn(row.publishedIn),
                    resourceType:
                        typeTranslations[row.resourceType as ResourceType],
                    publicResourceId: row.publicRecordingId ?? "",
                    accessType: (
                        <AccessTypeHeader accessType={row?.accessType} />
                    ),
                })}
                onRowClick={(item) => {
                    gstore.modals.open(() => (
                        <ResourceDetailModal id={item.id} />
                    ))
                }}
                rowActionsRenderer={(item) => [
                    {
                        text: t`resource-view.duplicate-resource`,
                        onClick: duplicateResourceClickHandler(item.id),
                    },
                    {
                        text: t`resource-view.delete-resource`,
                        onClick: deleteResourceClickHandler(
                            item.id,
                            item.name ?? "",
                            item.resourceType ?? "",
                        ),
                        destructive: true,
                    },
                ]}
            />
        </ListPage>
    )
})

export const ResourcesView = () => (
    <StoreProvider Store={ResourcesStore}>
        <View />
    </StoreProvider>
)
