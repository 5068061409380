import { t } from "@lingui/macro"
import { TextField } from "@mui/material"
import { observer } from "mobx-react"

import { CampaignDetailStore } from "src/modals/campaign-detail/store"
import { useStore } from "src/store/lib/useStore"

export const AddContentsSection = observer(() => {
    const store = useStore(CampaignDetailStore)

    return (
        <>
            <TextField
                placeholder={t`campaign-detail-modal.add-content-section.content-header`}
                onChange={store.fields.setter("header")}
                value={store.fields.get("header")}
                helperText={
                    Boolean(store.fields.error("header"))
                        ? store.fields.error("header")
                        : t`errors.required-x-characters`
                }
                error={Boolean(store.fields.error("header"))}
            />
            <TextField
                placeholder={t`campaign-detail-modal.add-content-section.content-tagline`}
                onChange={store.fields.setter("tagLine")}
                value={store.fields.get("tagLine")}
                error={Boolean(store.fields.error("tagLine"))}
            />
            <TextField
                placeholder={t`campaign-detail-modal.add-content-section.content-description`}
                onChange={store.fields.setter("description")}
                value={store.fields.get("description")}
                helperText={
                    Boolean(store.fields.error("description"))
                        ? store.fields.error("description")
                        : t`errors.required-x-characters`
                }
                error={Boolean(store.fields.error("description"))}
            />
        </>
    )
})
