import { IIconProps } from "src/components/icons"

export const Open = (props: IIconProps) => (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clip-path="url(#clip0_2311_3832)">
            <path
                d="M7.87522 6.74997H10.1252C10.3352 6.74997 10.5002 6.58497 10.5002 6.37497V4.49997H11.8427C12.1802 4.49997 12.3452 4.09497 12.1052 3.86247L9.26272 1.01997C9.11272 0.869971 8.88022 0.869971 8.73022 1.01997L5.88772 3.86247C5.65522 4.09497 5.82022 4.49997 6.15772 4.49997H7.50022V6.37497C7.50022 6.58497 7.66522 6.74997 7.87522 6.74997ZM6.37522 7.49997H4.50022V6.15747C4.50022 5.81997 4.09522 5.65497 3.86272 5.89497L1.02021 8.73747C0.870215 8.88747 0.870215 9.11997 1.02021 9.26997L3.86272 12.1125C4.09522 12.345 4.50022 12.18 4.50022 11.8425V10.5H6.37522C6.58522 10.5 6.75022 10.335 6.75022 10.125V7.87497C6.75022 7.66497 6.58522 7.49997 6.37522 7.49997ZM16.9877 8.73747L14.1452 5.89497C13.9052 5.65497 13.5002 5.81997 13.5002 6.15747V7.49997H11.6252C11.4152 7.49997 11.2502 7.66497 11.2502 7.87497V10.125C11.2502 10.335 11.4152 10.5 11.6252 10.5H13.5002V11.8425C13.5002 12.18 13.9052 12.345 14.1377 12.105L16.9802 9.26247C17.1302 9.11997 17.1302 8.87997 16.9877 8.73747ZM10.1252 11.25H7.87522C7.66522 11.25 7.50022 11.415 7.50022 11.625V13.5H6.15772C5.82022 13.5 5.65522 13.905 5.89522 14.1375L8.73772 16.98C8.88772 17.13 9.12022 17.13 9.27022 16.98L12.1127 14.1375C12.3452 13.905 12.1802 13.5 11.8502 13.5H10.5002V11.625C10.5002 11.415 10.3352 11.25 10.1252 11.25Z"
                fill={props.style?.color ?? "#000"}
            />
        </g>
        <defs>
            <clipPath id="clip0_2311_3832">
                <rect width="18" height="18" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
