/* eslint-disable import/no-relative-parent-imports */
import { t } from "@lingui/macro"
import {
    FormControlLabel,
    Radio,
    RadioGroup,
    Stack,
    Typography,
} from "@mui/material"
import { observer } from "mobx-react"

import { FormDetailStore } from "../store"

import { IntegrationSection } from "./IntegrationSection"

import { EmailSection } from "./EmailSection"

import { CaseEngineSection } from "./CaseEngineSection"

import { FormFieldSet } from "src/components/FormFieldSet"
import { useStore } from "src/store/lib/useStore"
import { OutputType } from "src/types/output"

export const OutputSettingsSection = observer(() => {
    const store = useStore(FormDetailStore)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        store.formFields.set(
            "outputTypeSelectedValue",
            (event.target as HTMLInputElement).value as OutputType,
        )
        if (event.target.value === OutputType.Email) {
            store.formFields.set("sendEmail", true)
        } else {
            store.formFields.set("sendEmail", false)
        }

        if (event.target.value !== OutputType.CaseEngine) {
            store.formFields.set("messagingEnabled", false)
        }
    }

    return (
        <>
            <FormFieldSet
                header={""}
                description={t`contact-form-forms-detail-modal.form-detail-section.output-label.header`}
            >
                <Stack spacing={2}>
                    <RadioGroup
                        value={
                            store.formFields.get("outputTypeSelectedValue") ??
                            ""
                        }
                        onChange={handleChange}
                    >
                        <FormControlLabel
                            label={
                                <Typography variant="body1">
                                    {t`contact-form-forms-detail-modal.form-detail-section.output-label.integration`}
                                </Typography>
                            }
                            disabled={store.isEditMode}
                            value={OutputType.Integration}
                            control={<Radio color="info" />}
                        />
                        <FormControlLabel
                            label={
                                <Typography variant="body1">
                                    {t`contact-form-forms-detail-modal.form-detail-section.output-label.email`}
                                </Typography>
                            }
                            disabled={store.isEditMode}
                            value={OutputType.Email}
                            control={<Radio color="info" />}
                        />
                        <FormControlLabel
                            label={
                                <Typography variant="body1">
                                    {t`contact-form-forms-detail-modal.form-detail-section.output-label.case-engine`}
                                </Typography>
                            }
                            disabled={store.isEditMode}
                            value={OutputType.CaseEngine}
                            control={<Radio color="info" />}
                        />
                    </RadioGroup>
                    {store.formFields.get("outputTypeSelectedValue") ===
                        OutputType.Integration && <IntegrationSection />}
                    {store.formFields.get("outputTypeSelectedValue") ===
                        OutputType.Email && <EmailSection />}
                    {store.formFields.get("outputTypeSelectedValue") ===
                        OutputType.CaseEngine && <CaseEngineSection />}
                </Stack>
            </FormFieldSet>
        </>
    )
})
