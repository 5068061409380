import { IExternalDynamicFieldsListItemProps } from "src/modals/contact-forms-form-builder/types/fieldsListItem"

export const getExternalFieldsListOptions = (
    item: IExternalDynamicFieldsListItemProps,
) => {
    if (item.inputList.length > 0 && item.inputList[0].value.trim() !== "") {
        return item.inputList.map((i) => ({
            option: i.value.trim() ?? "",
            parent_option_uuid: "",
        }))
    }
}
