import { t } from "@lingui/macro"

import { avy_api_pkg_featurev2_Flag } from "src/api"

import { IFeatureDisplayDetails } from "src/types/feature_details"

export const getFeatureDisplayDetails = (
    feature: avy_api_pkg_featurev2_Flag,
): IFeatureDisplayDetails | undefined => {
    switch (feature.name) {
        case "app_overview":
            return {
                displayName: t`feature-name.app-overview`,
                tag: t`feature-tag.core-view`,
                description: t`feature-description.app-overview`,
                category: "general",
            }
        case "omAVYMenu":
            return {
                displayName: t`feature-name.om-avy-menu`,
                tag: t`feature-tag.app-space`,
                description: t`feature-description.om-avy-menu`,
                category: "general",
            }
        case "move_in_guide":
            return {
                displayName: t`feature-name.move-in-guide`,
                tag: t`feature-tag.app-module`,
                description: t`feature-description.move-in-guide`,
                category: "general",
            }
        case "email_login":
            return {
                displayName: t`feature-name.email-login`,
                tag: t`feature-tag.login-method`,
                description: t`feature-description.email-login`,
                category: "general",
            }
        case "issue_reporting_email":
            return {
                displayName: t`feature-name.issue-reporting-email`,
                tag: t`feature-tag.app-module`,
                description: t`feature-description.issue-reporting-email`,
                category: "contactChannels",
            }
        case "issue_reporting_link":
            return {
                displayName: t`feature-name.issue-reporting-link`,
                tag: t`feature-tag.app-feature`,
                description: t`feature-description.issue-reporting-link`,
                category: "contactChannels",
            }
        case "contact_form":
            return {
                displayName: t`feature-name.contact-form`,
                tag: t`feature-tag.add-on-feature`,
                description: t`feature-description.contact-form`,
                category: "contactChannels",
            }
        case "invoiceTab":
            return {
                displayName: t`feature-name.invoice-tab`,
                tag: t`feature-tag.app-module`,
                description: t`feature-description.invoice-tab`,
                category: "payment",
            }
        case "payment_provider_tink":
            return {
                displayName: t`feature-name.payment-provider-tink`,
                tag: t`feature-tag.payment-method`,
                description: t`feature-description.payment-provider-tink`,
                category: "payment",
            }
        case "invoice_distribution_channel":
            return {
                displayName: t`feature-name.invoice-distribution-channel`,
                tag: t`feature-tag.user-setting`,
                description: t`feature-description.invoice-distribution-channel`,
                category: "payment",
            }
        case "force_registered_tenant_extin_api":
            return {
                displayName: t`feature-name.force-registered-tenant-in-external-invoice-ap`,
                tag: t`feature-tag.system-setting`,
                description: t`feature-description.force-registered-tenant-in-external-invoice-ap`,
                category: "payment",
            }
        case "laundry_booking":
            return {
                displayName: t`feature-name.laundry-booking`,
                tag: t`feature-tag.booking-access-api`,
                description: t`feature-description.laundry-booking`,
                category: "bookingAndAccess",
            }
        case "booking_engine":
            return {
                displayName: t`feature-name.booking-engine`,
                tag: t`feature-tag.booking-system`,
                description: t`feature-description.booking-engine`,
                category: "bookingAndAccess",
            }
        case "aptus":
            return {
                displayName: t`feature-name.aptus`,
                tag: t`feature-tag.booking-access-embedded`,
                description: t`feature-description.aptus`,
                category: "bookingAndAccess",
            }
        case "customer_support":
            return {
                displayName: t`feature-name.customer-support`,
                tag: t`feature-tag.support-feature`,
                description: t`feature-description.customer-support`,
                category: "general",
            }
        case "measurements":
            return {
                displayName: t`feature-name.measurements`,
                tag: t`feature-tag.app-module`,
                description: t`feature-description.measurements`,
                category: "other",
            }
        case "mobility46":
            return {
                displayName: t`feature-name.mobilty-46`,
                tag: t`feature-tag.link-to-external-portal`,
                description: t`feature-description.mobilty-46`,
                category: "other",
            }
        case "messageTab":
            return {
                displayName: t`feature-name.message-tab`,
                tag: t`feature-tag.app-module`,
                description: t`feature-description.message-tab`,
                category: "communication",
            }
        case "community":
            return {
                displayName: t`feature-name.community`,
                tag: t`feature-tag.app-module`,
                description: t`feature-description.community`,
                category: "communication",
            }
        case "infocenter":
            return {
                displayName: t`feature-name.infocenter`,
                tag: t`feature-tag.app-module`,
                description: t`feature-description.infocenter`,
                category: "communication",
            }
        case "service_tab":
            return {
                displayName: t`feature-name.service-tab`,
                tag: t`feature-tag.app-module`,
                description: t`feature-description.service-tab`,
                category: "commerce",
            }
        case "energyDeal":
            return {
                displayName: t`feature-name.energy-deal`,
                tag: t`feature-tag.home-subscription`,
                description: t`feature-description.energy-deal`,
                category: "commerce",
            }
        case "safety_insurance":
            return {
                displayName: t`feature-name.safety-insurance`,
                tag: t`feature-tag.home-subscription`,
                description: t`feature-description.safety-insurance`,
                category: "commerce",
            }
        case "storage_service":
            return {
                displayName: t`feature-name.storage-service`,
                tag: t`feature-tag.home-subscription`,
                description: t`feature-description.storage-service`,
                category: "other",
            }
        case "productTab":
            return {
                displayName: t`feature-name.product-tab`,
                tag: t`feature-tag.app-module`,
                description: t`feature-description.product-tab`,
                category: "commerce",
            }
        case "dh_issue_report":
            return {
                displayName: t`feature-name.dh_issue_report`,
                tag: t`feature-tag.embedded-portal`,
                description: t`feature-description.dh_issue_report`,
                category: "integrations",
            }
        case "pigello_sso":
            return {
                displayName: t`feature-name.pigello_sso`,
                tag: t`feature-tag.embedded-portal`,
                description: t`feature-description.pigello_sso`,
                category: "integrations",
            }
        case "freshdesk":
            return {
                displayName: t`feature-name.fastighetsägarna-fireshdesk`,
                tag: t`feature-tag.integration`,
                description: t`feature-description.fastighetsägarna-fireshdesk`,
                category: "integrations",
            }
        case "fastapi":
            return {
                displayName: t`feature-name.fastapi`,
                tag: t`feature-tag.integration`,
                description: t`feature-description.fastapi`,
                category: "integrations",
            }
        case "guest_accounts":
            return {
                displayName: t`feature-name.guest_accounts`,
                tag: t`feature-tag.app-feature`,
                description: t`feature-description.guest_accounts`,
                category: "general",
            }
        case "lime":
            return {
                displayName: t`feature-name.lime`,
                tag: t`feature-tag.embedded-portal`,
                description: t`feature-description.lime`,
                category: "integrations",
            }
        case "qtsystems":
            return {
                displayName: t`feature-name.qtsystems`,
                tag: t`feature-tag.booking-access-embedded`,
                description: t`feature-description.qtsystems`,
                category: "bookingAndAccess",
            }
        case "rco":
            return {
                displayName: t`feature-name.rco`,
                tag: t`feature-tag.booking-access-embedded`,
                description: t`feature-description.rco`,
                category: "bookingAndAccess",
            }
        case "contact_form_v2":
            return {
                displayName: t`feature-name.contact-form-v2`,
                tag: t`feature-tag.add-on-feature`,
                description: t`feature-description.contact-form`,
                category: "contactChannels",
            }

        default:
            return {
                displayName: feature.name ?? "Unknown",
                tag: "",
                description: "",
                category: "other",
                linkToGuide: "",
            }
    }
}
