import { makeAutoObservable } from "mobx"
import React from "react"

import { FeatureFlagAdminService, avy_api_pkg_featurev2_Flag } from "src/api"
import { getFeatureDisplayDetails } from "src/lib/feature-tag-description"
import { IFeatureDetails } from "src/types/feature_details"
import { FeatureFlagAdminService as CustomFeatureFlagAdminService } from "src/api/_custom/services/FeatureFlagAdminService"
import { reportUnhandledApiError } from "src/lib/report"
import { createLoadingKeys } from "src/lib/loading"
import { loads } from "src/channel/utils"
import { DEFAULT_ACCESS_GROUP } from "src/config"

export class FeaturesStore {
    static Context = React.createContext<FeaturesStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "submit")
    private _accessGroupId?: number = DEFAULT_ACCESS_GROUP.id

    /* Features flag list */
    private _featuresFlagList: IFeatureDetails[] = []
    private _allFeaturesFlagList: IFeatureDetails[] = []
    private _selectedCategory: ICategorySearchNode = "allFeatures"

    get featuresFlagList() {
        return this._featuresFlagList
    }

    public setFeaturesFlagList(
        featuresFlagList: avy_api_pkg_featurev2_Flag[] = [],
    ) {
        const featuresList: IFeatureDetails[] = []
        featuresFlagList.forEach((feature) => {
            const featureData: IFeatureDetails | undefined =
                getFeatureDisplayDetails(feature)
            !(featureData == null) &&
                featuresList.push({ ...featureData, ...feature })
            !(featureData == null) &&
                !this.categoriesArray.includes(featureData.category) &&
                this.categoriesArray.push(featureData.category)
        })
        this._featuresFlagList = featuresList
        this._allFeaturesFlagList = featuresList
        this.categoriesArray.sort(
            (a, b) =>
                this.categoriesSequenceArray.indexOf(a) -
                this.categoriesSequenceArray.indexOf(b),
        )
        this.filterFeaturesFlagList(this.selectedCategory)
    }

    public filterFeaturesFlagList(category: ICategorySearchNode) {
        category === "allFeatures"
            ? (this._featuresFlagList = this._allFeaturesFlagList)
            : (this._featuresFlagList = this._allFeaturesFlagList.filter(
                  (flag) => flag.category === category,
              ))
    }
    @loads(() => FeaturesStore.LoadingKeys.init)
    async init(accessGroupId?: number) {
        try {
            this.setAccountGroupId(accessGroupId)
            this.setFeaturesFlagList(
                await FeatureFlagAdminService.getV2AdminFeatureFlag({
                    accessGroupId,
                }),
            )
        } catch (e) {
            reportUnhandledApiError(e)
        }
    }

    @loads(() => FeaturesStore.LoadingKeys.submit)
    async submit(segments: number[], flagId?: number) {
        try {
            await CustomFeatureFlagAdminService.putV2AdminFeatureFlagSegments({
                request: { segment_ids: segments },
                flagId,
            })
        } catch (e) {
            reportUnhandledApiError(e)
        } finally {
            await this.init(this.getAccountGroupId())
        }
    }

    private categoriesArray: ICategorySearchNode[] = ["allFeatures"]

    private categoriesSequenceArray: ICategorySearchNode[] = [
        "allFeatures",
        "general",
        "contactChannels",
        "payment",
        "bookingAndAccess",
        "communication",
        "commerce",
        "integrations",
        "other",
        "notInUse",
    ]

    constructor() {
        makeAutoObservable(this)
    }

    get categories() {
        return this.categoriesArray
    }

    get selectedCategory() {
        return this._selectedCategory
    }

    public setSelectedCategory(category: ICategorySearchNode) {
        this._selectedCategory = category
    }

    private getAccountGroupId() {
        return this._accessGroupId !== DEFAULT_ACCESS_GROUP.id
            ? this._accessGroupId
            : undefined
    }

    private setAccountGroupId(accessGroupId?: number) {
        this._accessGroupId = accessGroupId
    }

    setCategory(type: ICategorySearchNode) {
        this.setSelectedCategory(type)
        this.filterFeaturesFlagList(type)
    }
}
