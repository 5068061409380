import { Stack, useTheme } from "@mui/material"
import { observer } from "mobx-react"

import { BottomContainerSection } from "./BottomContainerSection"

import {
    CardContainer,
    CardInnerContainer,
    DraggableIconContainer,
    Separator,
} from "./styled"

import { useWindowWide } from "src/views/feature-toggles/features/useWindowWide"
import { IDynamicCardLayoutProps } from "src/components/DynamicCardLayout/types/dynamicCardLayoutProps"
import { DragIndicator } from "src/components/icons/DragIndicator"

export const DynamicCardLayout = observer(
    ({
        children,
        index,
        showDraggableIndicator,
        hideCopyContentIcon,
        hideBinIcon,
        onContentCopyClick,
        onRemoveClick,
    }: IDynamicCardLayoutProps) => {
        const cardTheme = useTheme()
        const currentWidth = useWindowWide(0)

        return (
            <Stack spacing={2}>
                <CardContainer windowWidth={currentWidth} theme={cardTheme}>
                    <CardInnerContainer>
                        {showDraggableIndicator === true && (
                            <DraggableIconContainer>
                                <DragIndicator />
                            </DraggableIconContainer>
                        )}
                        <Stack spacing={2}>
                            {children}
                            <Separator />
                            <BottomContainerSection
                                index={index}
                                hideCopyContentIcon={
                                    hideCopyContentIcon ?? false
                                }
                                hideBinIcon={hideBinIcon ?? false}
                                onContentCopyClick={
                                    onContentCopyClick != null
                                        ? () => onContentCopyClick(index)
                                        : () => null
                                }
                                onDeleteClick={
                                    onRemoveClick != null
                                        ? () => onRemoveClick(index)
                                        : () => null
                                }
                            />
                        </Stack>
                    </CardInnerContainer>
                </CardContainer>
            </Stack>
        )
    },
)
