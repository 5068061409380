import { useEffect } from "react"
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material"
import { observer } from "mobx-react"
import { t } from "@lingui/macro"
import camelcaseKeys from "camelcase-keys"

import { InfoCenterAdminService } from "src/api"

import { useStore } from "src/store/lib/useStore"
import { LibraryInformationItemStore } from "src/modals/library-information-items/store"
import { reportError } from "src/lib/report"

export const LibraryInformationItemsCategory = observer(() => {
    const store = useStore(LibraryInformationItemStore)

    useEffect(() => {
        ;(async () => {
            try {
                store.setCategories(
                    camelcaseKeys(
                        await InfoCenterAdminService.infocenterGetCategory(),
                    ).map((category) => ({
                        ...category,
                        rank: category.rank ?? 0,
                    })),
                )
            } catch (error) {
                reportError(
                    t`edit-information-item-modal.load-categories-fail`,
                    error,
                )
            }
        })()
    }, [store])

    return (
        <FormControl style={{ breakInside: "avoid" }}>
            <InputLabel id="category-label">{t`edit-information-item-modal.category`}</InputLabel>

            <Select
                labelId="category-label"
                id="category"
                value={
                    store.categories.length > 0 &&
                    store.formFields.get("category") != null
                        ? store.formFields.get("category")
                        : ""
                }
                label="Category"
                onChange={(event) =>
                    store.formFields.set(
                        "category",
                        event.target.value as number,
                    )
                }
                error={Boolean(store.formFields.error("category"))}
                disabled={store.formFields.get("accessType") === "READ"}
            >
                {store.categories.map((category) => (
                    <MenuItem
                        value={category.categoryId}
                        key={category.categoryId}
                    >
                        {category.displayName}
                    </MenuItem>
                ))}
            </Select>
            {Boolean(store.formFields.error("category")) && (
                <FormHelperText error={true}>
                    {store.formFields.error("category")}
                </FormHelperText>
            )}
        </FormControl>
    )
})
