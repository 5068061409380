import { makeAutoObservable } from "mobx"

/**
 * LocalStorage is user to set, get and remove items from window.localStorage.
 * It keeps an observable copy of a part of the local storage for mobx
 * components to consume. It also listens to changes to the local storage from
 * other contexts and syncs them the the observable state.
 *
 * Note:
 * This is not built to be type-safe. This would require some data validation
 * on read and write and is not implemented currently.
 */
export class LocalStorage<TData> {
    constructor(public data: TData) {
        makeAutoObservable(this)
        this.loadFromLocalStore()
        this.addStorageEventListener()
    }

    dispose() {
        this.removeStorageEventListener()
    }

    set<TKey extends keyof TData, TValue extends TData[TKey]>(
        key: TKey,
        value: TValue,
    ) {
        this.data[key] = value
        window.localStorage.setItem(
            this.toKey(key),
            this.serialize<TKey, TValue>(value),
        )
    }

    remove<TKey extends keyof TData>(key: TKey) {
        delete this.data[key]
        window.localStorage.removeItem(this.toKey(key))
    }

    private addStorageEventListener() {
        window.addEventListener("storage", this.handleStorageChange)
    }

    private removeStorageEventListener() {
        window.removeEventListener("storage", this.handleStorageChange)
    }

    private handleStorageChange = (event: StorageEvent) => {
        const { key, newValue } = event

        if (key != null) {
            if (newValue != null) {
                this.data[key as keyof TData] = this.deserialize(newValue)
            } else {
                delete this.data[key as keyof TData]
            }
        }
    }

    private loadFromLocalStore() {
        for (const key in window.localStorage) {
            if (window.localStorage.hasOwnProperty(key)) {
                this.data[key as keyof TData] = this.deserialize(
                    window.localStorage[key],
                )
            }
        }
    }

    private serialize<TKey extends keyof TData, TTValue extends TData[TKey]>(
        value: TTValue,
    ) {
        // No-op if value already is a string. This is a special case to support
        // some local storage values from legacy admin. This could be moved to a
        // property config later.
        if (typeof value === "string") {
            return value
        }

        return JSON.stringify(value)
    }

    private deserialize<TType>(value: string) {
        try {
            return JSON.parse(value) as TType
        } catch {
            // No-op if value isn't valid json, i.e. it's a regular string.
            return value as unknown as TType
        }
    }

    private toKey(key: number | string | symbol) {
        return key.toString()
    }
}
