import { t } from "@lingui/macro"

import { observer } from "mobx-react"

import { useTheme, Grid, Stack, Typography } from "@mui/material"

import { SupervisorAccountRounded } from "@mui/icons-material"

import { Icon } from "src/components/icons"

import { useStore } from "src/store/lib/useStore"
import { CommunitiesOverviewViewStore } from "src/views/community-overview/store"

export const LastUpdateRow = observer(() => {
    const store = useStore(CommunitiesOverviewViewStore)
    const { palette } = useTheme()

    return (
        <Grid container>
            <Grid
                item
                xs={6}
                padding={2}
                sx={{ borderRight: `1px solid ${palette.grey[200]}` }}
            >
                <Stack
                    display={"flex"}
                    justifyContent={"space-between"}
                    direction="row"
                    spacing={1}
                    flex={1}
                >
                    <Stack
                        direction="row"
                        display={"flex"}
                        alignItems={"center"}
                    >
                        <Icon
                            icon={
                                <SupervisorAccountRounded
                                    height={24}
                                    width={24}
                                />
                            }
                            size={24}
                            color={palette.utility.darkBlue.main}
                        />
                        <Typography
                            variant="subtitle1"
                            marginLeft={2}
                            color={palette.text.secondary}
                        >
                            {t`community-overview.last-update-grid.last-activity-from-residents`}
                        </Typography>
                    </Stack>
                    <Typography
                        variant="subtitle1"
                        color={palette.text.secondary}
                    >
                        {store.recentActivityFromResidents}
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={6} padding={2}>
                <Stack
                    display={"flex"}
                    justifyContent={"space-between"}
                    direction="row"
                    spacing={1}
                    flex={1}
                >
                    <Stack
                        direction="row"
                        display={"flex"}
                        alignItems={"center"}
                    >
                        <Icon
                            icon={
                                <SupervisorAccountRounded
                                    height={24}
                                    width={24}
                                />
                            }
                            size={24}
                            color={palette.utility.darkBlue.main}
                        />
                        <Typography
                            variant="subtitle1"
                            marginLeft={2}
                            color={palette.text.secondary}
                        >
                            {t`community-overview.last-update-grid.last-activity-from-administrator`}
                        </Typography>
                    </Stack>
                    <Typography
                        variant="subtitle1"
                        color={palette.text.secondary}
                    >
                        {store.lastActivityFromAdministrator}
                    </Typography>
                </Stack>
            </Grid>
        </Grid>
    )
})
