import { withinBounds } from "src/lib/number"

export type IWeekdayRangesValue = {
    [key in Weekdays]: number[] | null
}

export type IWeekdayRangesInterval = {
    startTime: number
    endTime: number
    dayOfWeek: IDayOfWeek
}

export enum Weekdays {
    Monday = "monday",
    Tuesday = "tuesday",
    Wednesday = "wednesday",
    Thursday = "thursday",
    Friday = "friday",
    Saturday = "saturday",
    Sunday = "sunday",
}

export type IDayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6

export enum Distribution {
    EqualDays = "equal-days",
    IndividualDays = "individual-days",
}

export const createDefaultRange = (step: number) => {
    if (step <= dayMinuteBounds.min || step > dayMinuteBounds.max) {
        throw new Error(
            `Step must be between ${dayMinuteBounds.min + 1} and ${
                dayMinuteBounds.max
            }. Got: ${step}.`,
        )
    }

    let start = 8 * 60
    let end = 16 * 60

    // If the proposed range (`start` - `end`) can't be divided evenly with
    // `step` we adjust `end` so that it can.
    const range = end - start
    if (range % step > 0) {
        end += step - (range % step)

        // Move the range to start earlier if the proposed end time is later
        // than 24:00.
        if (end > dayMinuteBounds.max) {
            start -= end - dayMinuteBounds.max
            end -= end - dayMinuteBounds.max
        }
    }

    return [start, end]
}

export const createDefaultWeekdayRanges = (
    step: number,
): IWeekdayRangesValue => {
    const [start, end] = createDefaultRange(step)
    return {
        [Weekdays.Monday]: [start, end],
        [Weekdays.Tuesday]: [start, end],
        [Weekdays.Wednesday]: [start, end],
        [Weekdays.Thursday]: [start, end],
        [Weekdays.Friday]: [start, end],
        [Weekdays.Saturday]: [start, end],
        [Weekdays.Sunday]: [start, end],
    }
}

export const dayMinuteBounds = Object.freeze({ min: 0, max: 24 * 60 })

export const dayOfWeekToWeekday = Object.freeze({
    0: Weekdays.Sunday,
    1: Weekdays.Monday,
    2: Weekdays.Tuesday,
    3: Weekdays.Wednesday,
    4: Weekdays.Thursday,
    5: Weekdays.Friday,
    6: Weekdays.Saturday,
})

export const weekdayToDayOfWeek = Object.freeze({
    [Weekdays.Sunday]: 0 as const,
    [Weekdays.Monday]: 1 as const,
    [Weekdays.Tuesday]: 2 as const,
    [Weekdays.Wednesday]: 3 as const,
    [Weekdays.Thursday]: 4 as const,
    [Weekdays.Friday]: 5 as const,
    [Weekdays.Saturday]: 6 as const,
})

/**
 * Transforms the values from a slider change event to fit within our
 * specification to make sure the slider behaves as we want it to.
 *
 * The built-in slider value coercion logic doesn't work like we want it to
 * regarding snapping to `step`. It divides up the slider in steps with the
 * `min` value as fixed anchor. E.g. if the `step` is set to 6h the only
 * possible starting points for a range is 00:00, 06:00, 12:00 and 18:00.
 *
 * We want the user to be able to pick any starting point in the slider but the
 * selected range must be divisible by `step`.
 *
 * @param currentValue The current value of the slider.
 * @param proposedValue The proposed new value of the slider.
 * @param step The step for which the slider range must be divisible by.
 * @param thumb Which thumb triggered this change?
 * @returns A new range
 */
export const transformSliderValue = (
    currentValue: number[],
    proposedValue: number[],
    step: number,
    thumb: "left" | "right",
): number[] => {
    if (thumb === "left") {
        // Moving the left thumb keeps the range length and just moves the starting point.

        const currentRange = currentValue[1] - currentValue[0]

        // Modify the start but keep the range the same as before
        let nextValue = [proposedValue[0], proposedValue[0] + currentRange]

        // If the start is below the minimum allowed, adjust the range up
        if (nextValue[0] < dayMinuteBounds.min) {
            const adjustment = dayMinuteBounds.min - nextValue[0]
            nextValue[0] += adjustment
            nextValue[1] += adjustment
        }

        // If the end is above the maximum allowed, adjust the range down
        if (nextValue[1] > dayMinuteBounds.max) {
            const adjustment = nextValue[1] - dayMinuteBounds.max
            nextValue[0] -= adjustment
            nextValue[1] -= adjustment
        }

        // Make sure that the values are between 00:00 and 24:00
        return nextValue.map((value) => withinBounds(value, dayMinuteBounds))
    } else {
        // Moving the right thumb moves change the length of the range while
        // keeping the starting point the same.

        // Transform the values to be between 00:00 and 24:00 and make sure to
        // have atleast one `step` length between start and end values.
        let nextValue = [
            withinBounds(proposedValue[0], {
                min: dayMinuteBounds.min,
                max: dayMinuteBounds.max - step,
            }),
            withinBounds(proposedValue[1], {
                min: dayMinuteBounds.min + step,
                max: dayMinuteBounds.max,
            }),
        ]

        // Snap the right thumb to the closest value that is divisible by `step`.
        const range = nextValue[1] - nextValue[0]
        const leftToStep = range % step
        const stepModifier =
            leftToStep > step / 2 ? step - leftToStep : -leftToStep

        return [nextValue[0], nextValue[1] + stepModifier]
    }
}

/**
 * convertIntervalsToWeekdayRanges converts an array of
 * IWeekdayRangesInterval intervals to an IWeekdayRangesValue
 *
 * @param intervals Intervals as IWeekdayRangesInterval[]
 * @returns Intervals as IWeekdayRangesValue
 */
export const convertIntervalsToWeekdayRanges = (
    intervals: IWeekdayRangesInterval[],
): IWeekdayRangesValue => {
    const baseWeekdayRanges: IWeekdayRangesValue = {
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
    }

    const weekdayRanges = intervals.reduce((ranges, interval) => {
        const { dayOfWeek, startTime, endTime } = interval

        const weekday =
            dayOfWeek != null && dayOfWeek in dayOfWeekToWeekday
                ? dayOfWeekToWeekday[
                      dayOfWeek as keyof typeof dayOfWeekToWeekday
                  ]
                : null

        // These values should never be nullish they're just wrongly typed.
        // Ignore this interval if they are.
        if (weekday == null || startTime == null || endTime == null) {
            return ranges
        }

        const range = ranges[weekday]
        if (range == null) {
            ranges[weekday] = [startTime, endTime]
        } else {
            const [currentStartTime, currentEndTime] = range

            const nextStartTime = Math.min(currentStartTime, startTime)
            const nextEndTime = Math.max(currentEndTime, endTime)

            ranges[weekday] = [nextStartTime, nextEndTime]
        }

        return ranges
    }, baseWeekdayRanges)

    return weekdayRanges
}

/**
 * convertWeekdayRangesToIntervals converts an IWeekdayRangesValue to an
 * array of IWeekdayRangesInterval intervals
 *
 * @param weekdayRanges Intervals as IWeekdayRangesValue
 * @param intervalLength Length of the intervals to be created
 * @returns Intervals as IWeekdayRangesInterval[]
 */
export const convertWeekdayRangesToIntervals = (
    weekdayRanges: IWeekdayRangesValue,
    intervalLength: number,
): IWeekdayRangesInterval[] => {
    return Object.entries(weekdayRanges).reduce<IWeekdayRangesInterval[]>(
        (intervals, [weekday, range]) => {
            if (range == null) {
                return intervals
            }

            const [start, end] = range
            let currentStart = start

            while (currentStart < end) {
                const currentEnd = currentStart + intervalLength
                intervals.push({
                    dayOfWeek: weekdayToDayOfWeek[weekday as Weekdays],
                    startTime: currentStart,
                    endTime: currentEnd,
                })
                currentStart = currentEnd
            }
            return intervals
        },
        [],
    )
}
