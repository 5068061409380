import { t } from "@lingui/macro"
import { Alert, Button, Checkbox, TextField } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useEffect } from "react"

import { OutPutParagraph, SpanContainer } from "./styled"

import { CenteredSpinner } from "src/components/CenteredSpinner"
import { Form } from "src/components/Form"
import { FormPanel } from "src/components/FormPanel"

import { DocumentAttachments } from "src/components/AttachmentsField/DocumentAttachments"
import { FormFieldSet } from "src/components/FormFieldSet"
import { ModalHeader } from "src/components/ModalHeader"
import { ProviderDetailStore } from "src/modals/provider-detail/store"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"

interface IProps {
    id?: number
}

const ProviderDetail = observer((props: IProps) => {
    const gstore = useStore(GlobalStore)
    const store = useStore(ProviderDetailStore)

    useEffect(() => {
        if (props.id !== null) {
            trackModuleEvent("Provider | Provider Detail View", {
                [MixpanelProperties.AccessGroupName]:
                    gstore.session.accessGroup?.name,
                [MixpanelProperties.AccessGroupID]:
                    gstore.session.accessGroup?.id,
                [MixpanelProperties.ItemID]: props.id,
            })
        }
        ;(async () => {
            await store.init(props.id)
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store, props.id])

    const handleSubmit = useCallback(async () => {
        await store.submit()
        if (!store.fields.hasErrors()) {
            if (props.id !== null) {
                trackModuleEvent("Provider | Create New Provider", {
                    [MixpanelProperties.AccessGroupName]:
                        gstore.session.accessGroup?.name,
                    [MixpanelProperties.AccessGroupID]:
                        gstore.session.accessGroup?.id,
                })
            } else {
                trackModuleEvent("Provider | Update Provider", {
                    [MixpanelProperties.AccessGroupName]:
                        gstore.session.accessGroup?.name,
                    [MixpanelProperties.AccessGroupID]:
                        gstore.session.accessGroup?.id,
                    [MixpanelProperties.ItemID]: props.id,
                })
            }
            gstore.modals.pop()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store, gstore])

    if (gstore.loading.is(ProviderDetailStore.LoadingKeys.init)) {
        return <CenteredSpinner height="100vh" />
    }
    const isButtonDisabled =
        gstore.loading.is(ProviderDetailStore.LoadingKeys.submit) ||
        store.fields.getIsDirty() === false

    return (
        <Form onSubmit={handleSubmit}>
            <ModalHeader>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={isButtonDisabled}
                >{t`provider-detail-modal.save-button`}</Button>
            </ModalHeader>
            {Boolean(store.fields.error("generic")) && (
                <Alert severity="error">{store.fields.error("generic")}</Alert>
            )}
            <FormPanel
                sections={[
                    {
                        header: t`provider-detail-modal.settings-section.header`,
                        content: (
                            <>
                                <FormFieldSet
                                    header={t`provider-detail-modal.settings-section.provider-name`}
                                    tooltip={t`provider-detail-modal.settings-section.provider-name-tooltip`}
                                >
                                    <TextField
                                        label={t`provider-detail-modal.settings-section.provider-name-label`}
                                        placeholder={t`provider-detail-modal.settings-section.provider-name-placeholder`}
                                        onChange={store.fields.setter(
                                            "providerName",
                                        )}
                                        value={store.fields.get("providerName")}
                                        helperText={store.fields.error(
                                            "providerName",
                                        )}
                                        error={Boolean(
                                            store.fields.error("providerName"),
                                        )}
                                    />
                                </FormFieldSet>
                                <FormFieldSet
                                    header={t`provider-detail-modal.settings-section.contact-details`}
                                    tooltip={t`provider-detail-modal.settings-section.contact-details-tooltip`}
                                >
                                    <TextField
                                        label={t`provider-detail-modal.settings-section.contact-details-label`}
                                        placeholder={t`provider-detail-modal.settings-section.contact-details-placeholder`}
                                        onChange={store.fields.setter(
                                            "contact_details",
                                        )}
                                        value={store.fields.get(
                                            "contact_details",
                                        )}
                                        helperText={store.fields.error(
                                            "contact_details",
                                        )}
                                        error={Boolean(
                                            store.fields.error(
                                                "contact_details",
                                            ),
                                        )}
                                    />
                                </FormFieldSet>
                                <FormFieldSet
                                    header={t`provider-detail-modal.terms-and-condition-attachments.header`}
                                    tooltip={t`provider-detail-modal.terms-and-condition-attachments.tooltip`}
                                >
                                    <DocumentAttachments
                                        value={store.fields.get(
                                            "termsAndConditionAttachments",
                                        )}
                                        onChange={(documents) => {
                                            store.fields.set(
                                                "termsAndConditionAttachments",
                                                documents,
                                            )
                                        }}
                                        max={1}
                                        onError={(errorMessage: string) =>
                                            store.fields.setError(
                                                "termsAndConditionAttachments",
                                                errorMessage,
                                            )
                                        }
                                    />
                                    <SpanContainer>
                                        {store.fields.error(
                                            "termsAndConditionAttachments",
                                        )}
                                    </SpanContainer>
                                </FormFieldSet>
                                <FormFieldSet
                                    header={t`provider-detail-modal.privacy-policy-attachments.header`}
                                    tooltip={t`provider-detail-modal.privacy-policy-attachments.tooltip`}
                                >
                                    <DocumentAttachments
                                        value={store.fields.get(
                                            "privacyPolicyAttachments",
                                        )}
                                        onChange={(documents) => {
                                            store.fields.set(
                                                "privacyPolicyAttachments",
                                                documents,
                                            )
                                        }}
                                        max={1}
                                        onError={(errorMessage: string) =>
                                            store.fields.setError(
                                                "privacyPolicyAttachments",
                                                errorMessage,
                                            )
                                        }
                                    />
                                    <SpanContainer>
                                        {store.fields.error(
                                            "privacyPolicyAttachments",
                                        )}
                                    </SpanContainer>
                                </FormFieldSet>
                                <FormFieldSet
                                    header={t`provider-detail-modal.has-tracking.header`}
                                    tooltip={t`provider-detail-modal.has-tracking.tooltip`}
                                >
                                    <Checkbox
                                        checked={store.fields.get(
                                            "has_tracking",
                                        )}
                                        onChange={(_, checked) =>
                                            store.fields.set(
                                                "has_tracking",
                                                checked,
                                            )
                                        }
                                        color="primary"
                                        onClick={(event) =>
                                            event.stopPropagation()
                                        }
                                    />
                                </FormFieldSet>
                            </>
                        ),
                    },
                ]}
            />
            <FormPanel
                sections={[
                    {
                        header: t`provider-detail-modal.order-output-section.header`,
                        content: (
                            <>
                                <FormFieldSet
                                    header={t`provider-detail-modal.order-output-section.order-output-name`}
                                    tooltip={t`provider-detail-modal.order-output-section.order-output-name-tooltip`}
                                >
                                    <TextField
                                        label={t`provider-detail-modal.order-output-section.order-output-name-label`}
                                        placeholder={t`provider-detail-modal.order-output-section.order-output-name-placeholder`}
                                        onChange={store.fields.setter(
                                            "orderOutputName",
                                        )}
                                        value={store.fields.get(
                                            "orderOutputName",
                                        )}
                                        helperText={store.fields.error(
                                            "orderOutputName",
                                        )}
                                        error={Boolean(
                                            store.fields.error(
                                                "orderOutputName",
                                            ),
                                        )}
                                    />
                                </FormFieldSet>
                                <FormFieldSet
                                    header={t`provider-detail-modal.order-output-section.order-output-email`}
                                    tooltip={t`provider-detail-modal.order-output-section.order-output-email-tooltip`}
                                >
                                    <TextField
                                        label={t`provider-detail-modal.order-output-section.order-output-email-label`}
                                        placeholder={t`provider-detail-modal.order-output-section.order-output-email-placeholder`}
                                        onChange={store.fields.setter(
                                            "orderOutputEmail",
                                        )}
                                        value={store.fields.get(
                                            "orderOutputEmail",
                                        )}
                                        helperText={store.fields.error(
                                            "orderOutputEmail",
                                        )}
                                        error={Boolean(
                                            store.fields.error(
                                                "orderOutputEmail",
                                            ),
                                        )}
                                    />
                                </FormFieldSet>
                                {props.id != null ? (
                                    <FormFieldSet
                                        header={t`provider-detail-modal.order-output-section.order-output-type`}
                                        tooltip={t`provider-detail-modal.order-output-section.order-output-type`}
                                    >
                                        <OutPutParagraph>
                                            {store.fields.get(
                                                "orderOutputType",
                                            )}
                                        </OutPutParagraph>
                                    </FormFieldSet>
                                ) : null}
                            </>
                        ),
                    },
                ]}
            />
        </Form>
    )
})

export const ProviderDetailModal = (props: IProps) => (
    <StoreProvider Store={ProviderDetailStore}>
        <ProviderDetail {...props} />
    </StoreProvider>
)
