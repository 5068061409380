import { IIconProps } from "src/components/icons"

export const User16 = (props: IIconProps) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M13.4735 11.5061C13.1065 10.7391 11.853 10.2586 10.1185 9.59287C9.7885 9.46713 9.5805 9.41496 9.4875 9.13478C9.38062 8.86203 9.40962 8.5518 9.565 8.30574C10.2525 7.53044 10.59 6.36904 10.59 4.86957C10.59 2.88696 9.29 2 8 2C6.71 2 5.41 2.88696 5.41 4.86957C5.41 6.36904 5.7475 7.53044 6.435 8.30574C6.59038 8.5518 6.61938 8.86203 6.5125 9.13478C6.4195 9.41496 6.2125 9.46713 5.8815 9.59287C4.147 10.2586 2.8935 10.7407 2.5265 11.5061C2.20257 12.2036 2.02317 12.9644 2 13.7391C2 13.8832 2.11193 14 2.25 14H13.75C13.8881 14 14 13.8832 14 13.7391C13.9768 12.9644 13.7974 12.2036 13.4735 11.5061Z"
            fill="currentColor"
        />
    </svg>
)
