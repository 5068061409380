import { Box, styled } from "@mui/material"

const RequestDetailsModalStyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.grey[100],
    borderRadius: "8px",
    padding: "16px",
}))

const RequestDetailsModalStyledTable = styled("table")(() => ({
    textAlign: "left",
    fontSize: "14px",

    th: {
        fontWeight: 500,
        padding: "6px 32px 6px 0",
    },

    td: {
        padding: "6px 0",
    },
}))

export { RequestDetailsModalStyledBox, RequestDetailsModalStyledTable }
