import { t } from "@lingui/macro"
import { makeAutoObservable } from "mobx"
import React from "react"

import { CommunityAdminService, community_Author } from "src/api"
import { loads } from "src/channel/utils"
import { createLoadingKeys } from "src/lib/loading"
import { reportUnhandledApiError } from "src/lib/report"

interface ILike {
    authorName: string
}

export class CommunityLikesStore {
    static Context = React.createContext<CommunityLikesStore | null>(null)
    static LoadingKeys = createLoadingKeys("init")

    likes: ILike[] = []

    constructor() {
        makeAutoObservable(this)
    }

    @loads(() => CommunityLikesStore.LoadingKeys.init)
    async initForComment(postId: number, commentId: number) {
        try {
            const post =
                await CommunityAdminService.getV1AdminCommunityPostComment({
                    postId,
                })
            const comment = (post.comments ?? []).find(
                (comment) => comment.comment_id === commentId,
            )

            this.setLikes((comment?.likes ?? []).map(this.parseLike))
        } catch (e) {
            reportUnhandledApiError(e)
        }
    }

    @loads(() => CommunityLikesStore.LoadingKeys.init)
    async initForPost(postId: number) {
        try {
            const post = await CommunityAdminService.getV1AdminCommunityPost1({
                postId,
            })

            this.setLikes((post?.likes ?? []).map(this.parseLike))
        } catch (e) {
            reportUnhandledApiError(e)
        }
    }

    private setLikes(likes: ILike[]) {
        this.likes = likes
    }

    private parseLike(like: community_Author): ILike {
        let authorName =
            like.author_name != null && like.author_name !== ""
                ? like.author_name
                : t`community-likes-modal.author-unknown-name`
        return { authorName }
    }
}
