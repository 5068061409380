import { AlertColor } from "@mui/material"

import { IMessage, Channel as ChannelClass } from "src/lib/channel"
import { Repository } from "src/types/channel"

type Messages =
    | IMessage<"loading/started", { key: symbol }>
    | IMessage<"loading/stopped", { key: symbol }>
    | IMessage<"request/completed", { status: number }>
    | IMessage<
          "repository/updated",
          {
              repository: Repository
              action: "create" | "update" | "delete" | "duplicate"
              item?: {
                  id: number
                  name: string
                  extraId?: number
              }
          }
      >
    | IMessage<"notification/toast", { severity: AlertColor; message: string }>

export const Channel = new ChannelClass<Messages>()
