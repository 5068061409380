import { Stack } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { Wrap, InputWrapper } from "./styled"

import { Form } from "src/components/Form"
import { Input } from "src/modals/view-community-post/CommentForm/Input"
import { Controls } from "src/modals/view-community-post/CommentForm/Controls"
import { ViewCommunityPostStore } from "src/modals/view-community-post/store"
import { useStore } from "src/store/lib/useStore"
import { Avatar } from "src/components/Avatar"

export const CommentForm = observer(() => {
    const store = useStore(ViewCommunityPostStore)

    const handleSubmit = useCallback(async () => {
        await store.submitCommentForm()
    }, [store])

    return (
        <Wrap>
            <Form onSubmit={handleSubmit}>
                <Stack spacing={1}>
                    <Controls />
                    <InputWrapper>
                        <Stack marginRight="10px">
                            <Avatar
                                size={40}
                                name={store.getAuthorNameFromForm() ?? ""}
                            />
                        </Stack>
                        <Stack flex={1}>
                            <Input />
                        </Stack>
                    </InputWrapper>
                </Stack>
            </Form>
        </Wrap>
    )
})
