import { styled } from "@mui/material"

const ChatContainer = styled("div")(({ theme }) => ({
    background: theme.palette.grey[100],
    borderRadius: "0 0 4px 4px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    height: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    position: "relative",
    overflow: "auto",
}))

const ConversationContainer = styled("div")(() => ({
    height: "calc(100%)",
    overflow: "scroll",
    position: "relative",
    width: "100%",
}))

export { ChatContainer, ConversationContainer }
