import { memo } from "react"

import { IContentItemsProps } from "src/modals/content-items/types/contentItemsProps"

import { ModalTextField } from "src/components/ModalTextField"

type ContentItemKey = "generic" | keyof IContentItemsProps

export interface IRenderTextInputProps {
    label: string
    name: ContentItemKey
    rowLength: number
    disabled?: boolean
    maxLength?: number
    handleTextInputChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        name: string,
    ) => void
    value?: string
    helperText?: string
}

export const ContentItemsTextInput = memo(
    ({
        label,
        name,
        rowLength,
        disabled,
        maxLength,
        handleTextInputChange,
        value,
        helperText,
    }: IRenderTextInputProps) => {
        return (
            <ModalTextField
                variant="default"
                label={label ?? ""}
                value={value ?? ""}
                onChange={(event) => handleTextInputChange(event, name)}
                helperText={helperText}
                error={Boolean(helperText)}
                maxLength={maxLength ?? 255}
                rows={rowLength}
                disabled={disabled}
            />
        )
    },
)
