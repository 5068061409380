import { memo } from "react"
import { Box } from "@mui/material"
import { OpenInNew } from "@mui/icons-material"
import Markdown from "markdown-to-jsx" // Assuming you're using react-markdown

import {
    StyledIconButton,
    IconContainer,
    MessageBubble,
} from "src/components/ChatMessageJourney/sections/ConsideredSection/styled"

import { IConsideredItemProps } from "src/components/ChatMessageJourney/types"

export const ConsideredItem = memo(
    ({ data, handleSourceClick }: IConsideredItemProps) => (
        <Box sx={{ marginBottom: "16px" }}>
            <IconContainer>
                <StyledIconButton
                    onClick={() =>
                        handleSourceClick !== undefined &&
                        handleSourceClick(data.Data?.source)
                    }
                >
                    {data.Data?.source?.name ?? ""}
                    <OpenInNew fontSize="inherit" sx={{ marginLeft: 0.5 }} />
                </StyledIconButton>
            </IconContainer>
            <Box
                sx={{
                    backgroundColor: "background.paper",
                    padding: 1,
                    borderRadius: 0.5,
                }}
            >
                <MessageBubble>
                    <Markdown>{data.Text ?? ""}</Markdown>
                </MessageBubble>
            </Box>
        </Box>
    ),
)
