import { observer } from "mobx-react"
import { Alert, Button, TextField, Typography } from "@mui/material"
import { useCallback } from "react"
import { t } from "@lingui/macro"

import { ForgotPasswordStore } from "./store"

import { track } from "src/analytics/AnalyticsManager"

import { useStore } from "src/store/lib/useStore"

export const ForgotPassword = observer(() => {
    const store = useStore(ForgotPasswordStore)

    const handleFormSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            await store.resetPassword()
            track("Password | Forgot Password")
        },
        [store],
    )

    return (
        <>
            <Typography
                variant="h1"
                sx={{
                    fontSize: "34px",
                    fontWeight: 400,
                    textAlign: "center",
                    maxWidth: "286px",
                }}
            >{t`forgot-password-view.heading`}</Typography>
            {store.isSuccess ? (
                <Alert sx={{ mt: 3 }} severity="success">
                    {t`forgot-password-view.success-message`}
                </Alert>
            ) : (
                <>
                    <Typography sx={{ maxWidth: "312px", textAlign: "center" }}>
                        {t`forgot-password-view.preamble`}
                    </Typography>
                    <form style={{ width: "100%" }} onSubmit={handleFormSubmit}>
                        <TextField
                            type="email"
                            value={store.fields.get("email")}
                            onChange={store.fields.setter("email")}
                            label={t`forgot-password-view.email`}
                            helperText={store.fields.error("email")}
                            error={Boolean(store.fields.error("email"))}
                            sx={{
                                mt: 1,
                            }}
                        />
                        {Boolean(store.fields.error("generic")) && (
                            <Alert sx={{ mt: 3 }} severity="error">
                                {store.fields.error("generic")}
                            </Alert>
                        )}
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth={true}
                            sx={{ mt: 3 }}
                            type="submit"
                            disabled={
                                store.isLoading ||
                                store.fields.get("email") === ""
                            }
                        >
                            {t`forgot-password-view.reset-password`}
                        </Button>
                    </form>
                </>
            )}
        </>
    )
})
