import { observer } from "mobx-react"

import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material"
import { t } from "@lingui/macro"

interface IProps {
    sources: { type?: string; source?: string }[]
    selectedValue: string
    onSelectionChange: (value: string) => void
    errorMessage?: string
    disabled?: boolean
}

export const ExternalFieldTypeDropdown = observer((props: IProps) => {
    return (
        <FormControl style={{ breakInside: "avoid", width: "100%" }}>
            <InputLabel id="type-label">{t`contact-form-forms-detail-modal.form-fields-settings-external-field-type-dropdown.header`}</InputLabel>
            <Select
                data-testid="external-field-type-dropdown"
                labelId="external-field-type-label"
                id="external-field-type"
                value={props.selectedValue}
                label={t`contact-form-forms-detail-modal.form-fields-settings-external-field-type-dropdown.header`}
                onChange={(event) =>
                    props.onSelectionChange(event.target.value)
                }
                error={Boolean(props.errorMessage)}
                disabled={props.disabled}
            >
                {props.sources.map((item) => (
                    <MenuItem value={item.source} key={item.type}>
                        {item.source}
                    </MenuItem>
                ))}
            </Select>
            {Boolean(props.errorMessage) && (
                <FormHelperText error={true}>
                    {props.errorMessage}
                </FormHelperText>
            )}
        </FormControl>
    )
})
