import React from "react"
import { makeAutoObservable } from "mobx"
import { t } from "@lingui/macro"

import { FormFields } from "src/lib/form-fields"
import { Channel } from "src/channel"
import { reportError } from "src/lib/report"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { ITagsFormFields } from "src/modals/tags/types/formFields"
import { ITag } from "src/types/tags/tag"
import { TagsAdminService, tags_CreateUpdateTag } from "src/api"

export class TagsStore {
    static Context = React.createContext<TagsStore | null>(null)
    formFields = new FormFields<ITagsFormFields>({})
    isLoading = false
    _accessGroupId: number = DEFAULT_ACCESS_GROUP.id

    constructor() {
        makeAutoObservable(this)
    }

    //#region getters
    get accessGroupId() {
        return this._accessGroupId
    }
    //#endregion

    //#region setters
    setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading)

    setSelectedAccessGroupId = (accessGroupId: number) => {
        this._accessGroupId = accessGroupId
    }
    //#endregion

    //#region store operations
    init = (tag?: ITag) => {
        this.formFields.init({
            id: tag?.id ?? undefined,
            name: tag?.name ?? "",
            description: tag?.description ?? "",
        })
    }

    create = async () => {
        try {
            // create
            await this.formFields.catchErrors(async () => {
                await TagsAdminService.postV1AdminTag({
                    request: this.getRequestData(),
                })
            })

            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "tags",
                    action: "update",
                },
            })
        } catch (error) {
            reportError(t`tags-modal.save-tag-fail`, error)
        } finally {
            this.setIsLoading(false)
        }
    }

    update = async (item: ITag) => {
        try {
            await this.formFields.catchErrors(async () => {
                await TagsAdminService.putV1AdminTag({
                    request: this.getRequestData(),
                    tagId: item.id.toString(),
                })
            })

            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "tags",
                    action: "update",
                },
            })
        } catch (error) {
            reportError(t`tags-modal.save-tag-fail`, error)
        } finally {
            this.setIsLoading(false)
        }
    }
    //#endregion

    //#region helpers
    isEditMode = () => this.formFields.get("id") !== undefined

    getRequestData = (): tags_CreateUpdateTag => {
        return {
            name: this.formFields.get("name") ?? "",
            description: this.formFields.get("description"),
        }
    }

    validateRequiredFields = (requiredFields: (keyof ITagsFormFields)[]) => {
        this.formFields.clearErrors()
        requiredFields.forEach((field) => {
            const requiredField = field
            const formField = this.formFields.get(requiredField)
            const value =
                typeof formField === "string" ? formField.trim() : formField

            if (value == null || value === "") {
                this.formFields.setError(requiredField, t`errors.required`)
            } else {
                this.formFields.clearError(requiredField)
            }
        })
    }

    validateAndSubmit = async (tag?: ITag) => {
        this.setIsLoading(true)
        this.validateRequiredFields(["name", "description"])
        if (this.formFields.hasErrors() === true) {
            this.setIsLoading(false)
            return
        }
        if (tag?.id == null) {
            await this.create()
        } else {
            await this.update(tag)
        }
    }
    //#endregion
}
