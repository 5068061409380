import { t } from "@lingui/macro"
import { Alert, Divider, Paper, Snackbar, Stack, styled } from "@mui/material"
import { observer } from "mobx-react"
import { useParams } from "react-router-dom"

import { useEffect } from "react"

import { EditProductStore } from "src/views/product-catalogue/edit/store"

import { CenteredSpinner } from "src/components/CenteredSpinner"
import { PageHeader } from "src/components/PageHeader"
import { PageNotFoundView } from "src/components/PageNotFound"
import { useInitializer } from "src/lib/initializer"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { Buttons } from "src/views/product-catalogue/edit/sections/Buttons"
import { ContentSection } from "src/views/product-catalogue/edit/sections/Content"
import { FirstSection } from "src/views/product-catalogue/edit/sections/First"
import { PublishSettingsSection } from "src/views/product-catalogue/edit/sections/PublishSettings"
import { SettingsSection } from "src/views/product-catalogue/edit/sections/Settings"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"

const View = observer((props: IProps) => {
    const store = useStore(EditProductStore)
    const globalStore = useStore(GlobalStore)
    const productId = useParams()?.productId ?? props.productId
    const productType = store.formFields.get("productType")
    const isLoading = productId != null && store.product.productId == null

    useEffect(() => {
        if (productId != null) {
            trackModuleEvent("Products | Products Detailed View", {
                [MixpanelProperties.AccessGroupName]:
                    globalStore.session.accessGroup?.name,
                [MixpanelProperties.AccessGroupID]:
                    globalStore.session.accessGroup?.id,
                [MixpanelProperties.ItemID]: productId,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId])

    const initialized = useInitializer(
        () => store.init(Number(productId)),
        [store, productId],
    )
    if (globalStore.session.accessGroupId === -1) {
        return <PageNotFoundView />
    } else if (!initialized || isLoading === true) {
        return <CenteredSpinner height="500px" />
    }
    return (
        <Stack spacing={2.5}>
            <PageHeader
                header={
                    store.product.adminName ??
                    t`edit-product-view.create-product`
                }
                breadcrumbs={[
                    t`navigation.product-catalogue`,
                    t`navigation.products`,
                    store.product.adminName ??
                        t`edit-product-view.create-product`,
                ]}
            />
            {Boolean(store.formFields.error("generic")) && (
                <Alert severity="error">
                    {store.formFields.error("generic")}
                </Alert>
            )}
            <form>
                <Stack spacing={2.5}>
                    <FirstSection />

                    <CustomPaper>
                        <SettingsSection />
                        {productType != null && (
                            <>
                                <Divider style={{ margin: "24px 0" }} />
                                <ContentSection />
                            </>
                        )}
                    </CustomPaper>

                    <CustomPaper>
                        <PublishSettingsSection />
                    </CustomPaper>

                    <CustomPaper>
                        {store.formFields.hasErrors() &&
                            store.formFields.error("generic") != null && (
                                <p>{store.formFields.error("generic")}</p>
                            )}
                        <Snackbar
                            open={store.snackbar.message !== ""}
                            autoHideDuration={6000}
                            onClose={() => store.setSnackbar({ message: "" })}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                        >
                            <Alert
                                onClose={() =>
                                    store.setSnackbar({ message: "" })
                                }
                                severity="success"
                                sx={{ width: "100%" }}
                            >
                                {store.snackbar.message}
                            </Alert>
                        </Snackbar>
                        <Buttons />
                    </CustomPaper>
                </Stack>
            </form>
        </Stack>
    )
})

export const CustomPaper = styled(Paper)(() => ({
    borderWidth: "0",
    padding: "20px",
}))

interface IProps {
    productId?: number // productId prop is only used by unit test
}

export const EditProductView = (props: IProps) => (
    <StoreProvider Store={EditProductStore}>
        <View {...props} />
    </StoreProvider>
)
