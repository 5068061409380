import { styled } from "@mui/material"

const MessageBubble = styled("div")(({ theme }) => ({
    width: "auto",
    minWidth: 0,
    fontSize: "14px",
    color: theme.palette.grey[900],
    lineHeight: 1.43,
    letterSpacing: "0.17px",
}))

export { MessageBubble }
