export type Environment =
    | "local"
    | "development"
    | "stage"
    | "production"
    | "test"

export const getEnvironment = (url: string): Environment => {
    const subdomain = new URL(url).hostname.split(".")[0]

    if (subdomain === "avytmpl-admin" && process.env.NODE_ENV === "test") {
        return "test"
    } else if (subdomain.startsWith("local")) {
        return "local"
    } else if (subdomain.startsWith("dev-")) {
        return "development"
    } else if (subdomain.startsWith("stage-")) {
        return "stage"
    } else {
        return "production"
    }
}
