import { observer } from "mobx-react"
import { useCallback, useEffect } from "react"
import { Button, Link, Stack } from "@mui/material"
import { t } from "@lingui/macro"

import { useStore } from "src/store/lib/useStore"
import { Table } from "src/components/Table"
import { formatDateTime } from "src/lib/date"
import { GlobalStore } from "src/store"
import { ConfirmModal } from "src/modals/confirm"
import { LobbyAdminService } from "src/api"
import { StatusChip } from "src/components/StatusChip"
import { reportError } from "src/lib/report"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"
import { SelfRegistrationStore } from "src/views/self-registration/store"
import { SelfRegistrationRequestsDetailsModal } from "src/views/self-registration/components/RequestDetailsModal"
import { ILobby } from "src/views/self-registration/types/lobby"

export const SelfRegistrationRequestTab = observer(() => {
    const store = useStore(SelfRegistrationStore)
    const globalStore = useStore(GlobalStore)
    const approvableItems = store.requestsPagination.items.filter(
        (item) => item.status !== "approved" && item.status !== "rejected",
    )

    useEffect(() => {
        trackModuleEvent("Self registration | List view", {
            [MixpanelProperties.SelfRegistrationTabName]: "Request",
        })
    }, [])

    useEffect(() => {
        store.setAccessGroupId(globalStore.session.accessGroupId)

        if (!store.requestsPagination.meta.initialized) {
            ;(async () => {
                await store.requestsPagination.loadInitialPage()
            })()
        }
    }, [store, globalStore.session.accessGroupId])

    const handleApproveAll = useCallback(() => {
        globalStore.modals.open(
            () => (
                <ConfirmModal
                    onConfirm={async (confirmed) => {
                        if (confirmed) {
                            trackModuleEvent(
                                "Self registration | Approve requests",
                            )

                            try {
                                await LobbyAdminService.postV1AdminLobbyApproveAll(
                                    {
                                        accessGroupId:
                                            globalStore.session.accessGroupId,
                                    },
                                )
                            } catch (error) {
                                reportError(
                                    t`self-registration-view.failed-to-approve-all`,
                                    error,
                                )
                            } finally {
                                await store.requestsPagination.reload()
                            }
                        }
                    }}
                    title={t`self-registration-view.are-you-sure`}
                    content={t`self-registration-view.confirm-approve-all-requests`}
                />
            ),
            {
                variant: "slide-up-w600",
            },
        )
    }, [
        globalStore.modals,
        store.requestsPagination,
        globalStore.session.accessGroupId,
    ])

    const handleApproveReject = useCallback(
        (item: ILobby, status: "approved" | "rejected") => {
            globalStore.modals.open(
                () => (
                    <ConfirmModal
                        onConfirm={async (confirmed) => {
                            if (confirmed) {
                                try {
                                    await LobbyAdminService.putV1AdminLobby({
                                        lobbyId: item.lobbyId as number,
                                        request: {
                                            status: status,
                                        },
                                    })
                                } catch (error) {
                                    reportError(
                                        t`self-registration-view.failed-to-change-status`,
                                        error,
                                    )
                                } finally {
                                    globalStore.modals.pop()
                                    await store.requestsPagination.reload()
                                }
                            }
                        }}
                        title={t`self-registration-view.are-you-sure`}
                        content={
                            (status === "approved"
                                ? t`self-registration-view.confirm-approve-request`
                                : t`self-registration-view.confirm-reject-request`) +
                            ` (${item.firstName ?? ""} ${item.lastName ?? ""})`
                        }
                    />
                ),
                {
                    variant: "slide-up-w600",
                },
            )
        },
        [globalStore.modals, store.requestsPagination],
    )

    const handleTenantNameClick = useCallback(
        (item: ILobby) => {
            globalStore.modals.open(
                () => (
                    <SelfRegistrationRequestsDetailsModal
                        item={item}
                        onApprove={() => {
                            handleApproveReject(item, "approved")
                        }}
                        onReject={() => {
                            handleApproveReject(item, "rejected")
                        }}
                    />
                ),
                {
                    variant: "right-slide-in",
                },
            )
        },
        [globalStore.modals, handleApproveReject],
    )

    return (
        <Stack spacing={3}>
            <div>
                <Button
                    color="info"
                    variant="contained"
                    onClick={handleApproveAll}
                    sx={{ float: "right", textTransform: "uppercase" }}
                    disabled={approvableItems.length === 0}
                >
                    {t`self-registration-view.approve-all-new-requests`}
                </Button>
            </div>
            <Table
                paginator={store.requestsPagination}
                headers={[
                    {
                        key: "tenantName",
                        name: t`self-registration-view.tenant-name`,
                    },
                    {
                        key: "phone",
                        name: t`self-registration-view.phone-number`,
                    },
                    {
                        key: "email",
                        name: t`self-registration-view.email-address`,
                    },
                    {
                        key: "createdAt",
                        name: t`self-registration-view.request-date`,
                    },
                    {
                        key: "propertyName",
                        name: t`self-registration-view.property`,
                    },
                    {
                        key: "status",
                        name: t`self-registration-view.status`,
                    },
                    {
                        key: "respond",
                        name: t`self-registration-view.respond`,
                    },
                ]}
                rowRenderer={(item: ILobby) => ({
                    tenantName: (
                        <Link
                            component="button"
                            onClick={() => handleTenantNameClick(item)}
                            sx={{ fontWeight: 500, whiteSpace: "nowrap" }}
                        >
                            {item.firstName} {item.lastName}
                        </Link>
                    ),
                    phone: item.phone,
                    email: item.email,
                    createdAt:
                        item.createdAt != null &&
                        formatDateTime(item.createdAt),
                    propertyName: item.propertyName,
                    status: (
                        <StatusChip
                            label={
                                item.status === "approved"
                                    ? t`self-registration-view.approved`
                                    : item.status === "rejected"
                                    ? t`self-registration-view.rejected`
                                    : t`self-registration-view.new`
                            }
                            state={
                                item.status === "approved"
                                    ? "positive"
                                    : item.status === "rejected"
                                    ? "negative"
                                    : "other"
                            }
                        />
                    ),
                    respond: item.status !== "approved" &&
                        item.status !== "rejected" && (
                            <Stack direction="row" spacing={2}>
                                <Button
                                    size="small"
                                    color="info"
                                    variant="contained"
                                    onClick={() =>
                                        handleApproveReject(item, "approved")
                                    }
                                >
                                    {t`self-registration-view.approve`}
                                </Button>
                                <Button
                                    size="small"
                                    color="error"
                                    variant="contained"
                                    onClick={() =>
                                        handleApproveReject(item, "rejected")
                                    }
                                >
                                    {t`self-registration-view.reject`}
                                </Button>
                            </Stack>
                        ),
                })}
            />
        </Stack>
    )
})
