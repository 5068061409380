import { t } from "@lingui/macro"
import { useCallback, useState } from "react"
import {
    Button,
    Alert,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Chip,
    TextField,
    Typography,
    Box,
    Switch,
    FormControlLabel,
} from "@mui/material"
import { observer } from "mobx-react"

import { useStore } from "src/store/lib/useStore"

import { useInitializer } from "src/lib/initializer"
import { CenteredSpinner } from "src/components/CenteredSpinner"
import { Form } from "src/components/Form"
import { ModalHeader } from "src/components/ModalHeader"
import { FormPanel } from "src/components/FormPanel"
import { StatusChip } from "src/components/StatusChip"
import { OmniDomainStore } from "src/modals/omni-domains/store"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { IOmniDomainModalProps } from "src/modals/omni-domains/types/omniDomainProps"
import { CertificateStatus } from "src/types/certificateStatus"

const Modal = observer((props: IOmniDomainModalProps) => {
    const store = useStore(OmniDomainStore)
    const globalStore = useStore(GlobalStore)

    const [thisModal] = useState(
        globalStore.modals.active[globalStore.modals.active.length - 1],
    )

    thisModal.confirmOnOverlayClick = () => {
        return store.formFields.hasErrors()
    }

    const initialized = useInitializer(async () => {
        await store.init(props.omniDomain)
        store.setPropertyOwners(globalStore.session.propertyOwners)
    }, [store, props.omniDomain, globalStore.session.propertyOwners])

    const handleSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            store.setIsLoading(true)

            if (store.formFields.hasErrors()) {
                store.setIsLoading(false)
                return
            }

            let success = false
            if (props.omniDomain === undefined) {
                success = await store.createItem()
            }

            if (success || store.formFields.error("generic") === undefined) {
                globalStore.modals.pop()
            }
            store.setIsLoading(false)
        },
        [globalStore.modals, props.omniDomain, store],
    )

    const handleManagedToggle = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const isManaged = event.target.checked
            store.formFields.set("is_managed", isManaged)
            if (isManaged) {
                store.formFields.set("name", "")
            }
        },
        [store.formFields],
    )

    if (!initialized) {
        return <CenteredSpinner height="100vh" />
    }

    if (store.fetchError !== null) {
        return <Alert severity="error">{store.fetchError}</Alert>
    }

    return (
        <Form onSubmit={handleSubmit}>
            <ModalHeader>
                {props.omniDomain === undefined && (
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={store.isLoading}
                    >
                        {store.isLoading ? (
                            <CircularProgress size={24} color="inherit" />
                        ) : (
                            t`global.save`
                        )}
                    </Button>
                )}
            </ModalHeader>
            {props.omniDomain === undefined && (
                <FormControlLabel
                    control={
                        <Switch
                            checked={
                                store.formFields.get("is_managed") ?? false
                            }
                            onChange={handleManagedToggle}
                        />
                    }
                    label={t`omni-domains-modal.managed-domain`}
                />
            )}
            {props.omniDomain === undefined &&
                !Boolean(store.formFields.get("is_managed")) && (
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label={t`omni-domains-modal.name`}
                            value={store.formFields.get("name") ?? ""}
                            onChange={(event) =>
                                store.formFields.set("name", event.target.value)
                            }
                            error={Boolean(store.formFields.error("name"))}
                            helperText={store.formFields.error("name")}
                        />
                    </FormControl>
                )}
            {props.omniDomain !== undefined && (
                <Box
                    sx={{ display: "flex", alignItems: "center", mb: 2, mt: 2 }}
                >
                    <Typography sx={{ mr: 2, fontWeight: "bold" }}>
                        {t`omni-domains-modal.name`}:
                    </Typography>
                    <Typography sx={{ flexGrow: 1 }}>
                        {store.formFields.get("name") ?? ""}
                    </Typography>
                    <StatusChip
                        state={getCertificateStatusState(
                            store.formFields.get("certificate_status") ?? "",
                        )}
                        label={store.formFields.get("certificate_status") ?? ""}
                    />
                </Box>
            )}
            {props.omniDomain !== undefined ? (
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Typography sx={{ mr: 2, fontWeight: "bold" }}>
                        {t`omni-domains.property-owner`}:
                    </Typography>
                    <Typography>
                        {store.propertyOwners.find(
                            (po) =>
                                po.id ===
                                store.formFields.get("property_owner_id"),
                        )?.legalName ?? ""}
                    </Typography>
                </Box>
            ) : (
                <FormControl fullWidth margin="normal">
                    <InputLabel id="property-owner-label">{t`omni-domains.property-owner`}</InputLabel>
                    <Select
                        labelId="property-owner-label"
                        value={store.formFields.get("property_owner_id") ?? ""}
                        onChange={(event) =>
                            store.formFields.set(
                                "property_owner_id",
                                event.target.value as number,
                            )
                        }
                        error={Boolean(
                            store.formFields.error("property_owner_id"),
                        )}
                    >
                        {store.propertyOwners.map((po) => (
                            <MenuItem key={po.id} value={po.id}>
                                {po.legalName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
            {props.omniDomain !== undefined && (
                <>
                    <FormPanel
                        sections={[
                            {
                                header: t`omni-domains-modal.certificate-dns-record`,
                                headerStatusChip: store.isDnsChecking ? (
                                    <CircularProgress size={20} />
                                ) : store.formFields.get(
                                      "certificate_dns_valid",
                                  ) === true ? (
                                    <Chip
                                        label={t`omni-domains-modal.valid`}
                                        color="success"
                                        size="small"
                                    />
                                ) : store.formFields.get(
                                      "certificate_dns_valid",
                                  ) === false ? (
                                    <Chip
                                        label={t`omni-domains-modal.invalid`}
                                        color="error"
                                        size="small"
                                    />
                                ) : null,
                                content: (
                                    <Box
                                        sx={{
                                            display: "grid",
                                            gridTemplateColumns: "1fr 2fr",
                                            gap: 2,
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography>{t`omni-domains-modal.type`}</Typography>
                                        <Typography variant="body2">
                                            CNAME
                                        </Typography>

                                        <Typography>{t`omni-domains-modal.host`}</Typography>
                                        <Typography variant="body2">
                                            {store.formFields.get(
                                                "certificate_dns_record",
                                            ) ?? ""}
                                        </Typography>

                                        <Typography>{t`omni-domains-modal.value`}</Typography>
                                        <Typography variant="body2">
                                            {store.formFields.get(
                                                "certificate_dns_record_value",
                                            ) ?? ""}
                                        </Typography>
                                    </Box>
                                ),
                            },
                            {
                                header: t`omni-domains-modal.application-dns`,
                                headerStatusChip: store.isDnsChecking ? (
                                    <CircularProgress size={20} />
                                ) : store.formFields.get(
                                      "domain_cname_valid",
                                  ) === true ? (
                                    <Chip
                                        label={t`omni-domains-modal.valid`}
                                        color="success"
                                        size="small"
                                    />
                                ) : store.formFields.get(
                                      "domain_cname_valid",
                                  ) === false ? (
                                    <Chip
                                        label={t`omni-domains-modal.invalid`}
                                        color="error"
                                        size="small"
                                    />
                                ) : null,
                                content: (
                                    <Box
                                        sx={{
                                            display: "grid",
                                            gridTemplateColumns: "1fr 2fr",
                                            gap: 2,
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography>{t`omni-domains-modal.type`}</Typography>
                                        <Typography variant="body2">
                                            CNAME
                                        </Typography>

                                        <Typography>{t`omni-domains-modal.host`}</Typography>
                                        <Typography variant="body2">
                                            {store.formFields.get("name") ?? ""}
                                        </Typography>

                                        <Typography>{t`omni-domains-modal.value`}</Typography>
                                        <Typography variant="body2">
                                            {store.formFields.get(
                                                "omni_domain_name",
                                            ) ?? ""}
                                            .
                                        </Typography>
                                    </Box>
                                ),
                            },
                        ]}
                    />
                    {store.dnsCheckError !== null && (
                        <Alert severity="error" sx={{ mt: 2 }}>
                            {store.dnsCheckError}
                        </Alert>
                    )}
                </>
            )}
        </Form>
    )
})

export const OmniDomainModal = (props: IOmniDomainModalProps) => (
    <StoreProvider Store={OmniDomainStore}>
        <Modal {...props} />
    </StoreProvider>
)

function getCertificateStatusState(status: string): CertificateStatus {
    return parseCertificateStatus(status)
}

function parseCertificateStatus(status: string): CertificateStatus {
    switch (status.toLowerCase()) {
        case "active":
        case "valid":
            return CertificateStatus.Positive
        case "pending":
            return CertificateStatus.Info
        case "invalid":
            return CertificateStatus.Negative
        case "neutral":
            return CertificateStatus.Neutral
        default:
            return CertificateStatus.Other
    }
}
