import { NewPassword } from "./NewPassword"
import { NewPasswordStore } from "./store"

import { StoreProvider } from "src/store/lib/StoreProvider"

export const NewPasswordView = () => (
    <StoreProvider Store={NewPasswordStore}>
        <NewPassword />
    </StoreProvider>
)
