import { makeAutoObservable } from "mobx"
import React from "react"

import { contact_MessageCommon, ContactFormAdminService } from "src/api"
import { Channel } from "src/channel"
import { loads } from "src/channel/utils"
import { createLoadingKeys } from "src/lib/loading"

import {
    EMessageSender,
    EMessageType,
    IChatMessage,
    TMessageSender,
} from "src/types/chat-message/chatMessage"
import {
    ESystemMessageType,
    ISystemMessage,
} from "src/types/chat-message/systemMessage"

interface IPost {}

export interface IAuthor {
    id: string
    name: string
}

export class ViewContactFormChatStore implements IDisposable {
    static Context = React.createContext<ViewContactFormChatStore | null>(null)
    static LoadingKeys = createLoadingKeys("init")
    private repositoryChangeListenerDisposer?: () => void

    initialized = false

    contactFormId?: number = undefined

    messages: IChatMessage[] = []

    private _post: IPost | null = null

    get post(): IPost {
        if (this._post == null) {
            throw new Error("post not initialized")
        }

        return this._post
    }

    constructor() {
        makeAutoObservable(this)
        this.listenToRepositoryChanges()
    }

    dispose() {
        this.repositoryChangeListenerDisposer?.()
    }

    getSystemMessageType(
        message: contact_MessageCommon,
    ): ISystemMessage | undefined {
        if (message.message_type === EMessageType.SystemMessage) {
            switch (message.system_message?.system_message_type) {
                case ESystemMessageType.Status:
                    return {
                        systemMessageId:
                            message.system_message.system_message_id?.toString() ??
                            "",
                        systemMessageType: ESystemMessageType.Status,
                        systemMessage:
                            message.system_message?.parameters?.new_status ??
                            "",
                    }

                case ESystemMessageType.Assignee:
                    const handlerName =
                        message.system_message?.parameters?.new_handler_name
                    return {
                        systemMessageId:
                            message.system_message.system_message_id?.toString() ??
                            "",
                        systemMessageType: ESystemMessageType.Assignee,
                        systemMessage:
                            handlerName !== undefined
                                ? handlerName.charAt(0).toUpperCase() +
                                  handlerName.slice(1)
                                : "",
                    }

                case ESystemMessageType.Date:
                    return {
                        systemMessageId:
                            message.system_message.system_message_id?.toString() ??
                            "",
                        systemMessageType: ESystemMessageType.Date,
                        systemMessage:
                            message.system_message?.parameters
                                ?.implementation_date ?? "",
                    }

                case ESystemMessageType.ReporterEdit:
                    return {
                        systemMessageId:
                            message.system_message.system_message_id?.toString() ??
                            "",
                        systemMessageType: ESystemMessageType.ReporterEdit,
                        systemMessage:
                            message.system_message?.parameters?.reporter_name ??
                            "",
                    }

                default:
                    break
            }
        }
    }

    @loads(() => ViewContactFormChatStore.LoadingKeys.init)
    async init(id: number) {
        const contactForm = await ContactFormAdminService.contactFormGetOne({
            contactFormId: id,
        })

        this.setContactFormId(id)

        const newMessagesFormat = contactForm.messages?.map(
            (message): IChatMessage => {
                return {
                    attachmentUrls:
                        message?.message?.attachment_urls?.filter(
                            (attachment) => attachment.length,
                        ) ?? [],
                    authorName: message?.message?.author_name ?? "",
                    authorType: message?.message?.author_type ?? "",
                    contentFormId: message?.message?.contact_form_id as number,
                    createdAt: message?.message?.created_at ?? "",
                    messageId: message?.message?.message_id?.toString() ?? "",
                    text: message?.message?.text ?? "",
                    messageSender: this.getMessageSender(
                        message.message?.author_type,
                    ),
                    messageType: message.message_type ?? undefined,
                    systemMessage: this.getSystemMessageType(message),
                }
            },
        )

        this.setMessages(newMessagesFormat ?? [])

        this.setInitialized()
    }

    setMessages(messages: IChatMessage[]) {
        this.messages = messages
    }

    setContactFormId(id: number) {
        this.contactFormId = id
    }

    async submitCommentForm(images: IPersistedFile[], text: string) {
        try {
            await ContactFormAdminService.contactFormCreateMessage({
                contactFormId: this.contactFormId as number,
                request: {
                    attachment_urls: images.map((image) => image.url),
                    text,
                },
            })

            await this.init(this.contactFormId as number)
        } catch (e) {
            throw e
        }
    }

    private reloadMessages() {
        return //this.loadPost(this.post.id)
    }

    private setInitialized() {
        this.initialized = true
    }

    private listenToRepositoryChanges() {
        this.repositoryChangeListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.action === "update"
                ) {
                    await this.reloadMessages()
                }
            },
        )
    }

    private getMessageSender(authorType?: string): TMessageSender | undefined {
        switch (authorType) {
            case "tenant":
                return EMessageSender.User
            case "admin":
                return EMessageSender.Admin

            default:
                return undefined
        }
    }
}
