import { makeAutoObservable } from "mobx"
import React from "react"

import { ProductCatalogueAdminService } from "src/api"

import { loads } from "src/channel/utils"
import { createLoadingKeys } from "src/lib/loading"
import { IRankable, prepareRankableItems } from "src/lib/ranking"

export interface ICategoryData extends IRankable {
    name: string
    imageUrl: string
    iconUrl: string
    categoryId: number
    id: number
    numberOfProducts?: number
}

export class ProductCategoriesOrderStore {
    static Context = React.createContext<ProductCategoriesOrderStore | null>(
        null,
    )
    static LoadingKeys = createLoadingKeys("init", "submit", "deleteCategory")

    categories: ICategoryData[] = []

    setCategories(categories: ICategoryData[]) {
        this.categories = categories
    }

    get hasUpdatedCategories() {
        return this.updatedCategories.length > 0
    }

    private get updatedCategories() {
        return this.categories.filter((category) => category.modifiedRank)
    }

    constructor() {
        makeAutoObservable(this)
    }

    @loads(() => ProductCategoriesOrderStore.LoadingKeys.init)
    async init() {
        const response =
            await ProductCatalogueAdminService.getV1AdminCatalogueCategory()

        const categories = (response.categories ?? []).map(
            (category): ICategoryData => {
                return {
                    name: category.name ?? "",
                    imageUrl: category.image_url ?? "",
                    iconUrl: category.icon_url ?? "",
                    categoryId: category.category_id ?? 0,
                    id: category.category_id ?? 0,
                    rank: category.rank ?? "",
                    modifiedRank: false,
                }
            },
        )

        this.setCategories(prepareRankableItems(categories))
    }

    @loads(() => ProductCategoriesOrderStore.LoadingKeys.submit)
    async submit() {
        await Promise.all(
            this.updatedCategories.map((category) => {
                return ProductCatalogueAdminService.putV1AdminCatalogueCategory(
                    {
                        categoryId: category.categoryId,
                        request: {
                            icon_url: category.iconUrl,
                            image_url: category.imageUrl,
                            name: category.name,
                            rank: category.rank,
                        },
                    },
                )
            }),
        )
    }

    @loads(() => ProductCategoriesOrderStore.LoadingKeys.submit)
    async deleteCategory(categoryId: number) {
        await ProductCatalogueAdminService.deleteV1AdminCatalogueCategory({
            categoryId: categoryId,
        })
    }
}
