import { useEffect, useState } from "react"

import { reportError } from "./report"

/**
 * Runs an initializer function and returns true or false depending on whether
 * atleast one initializer call has succeeded.
 *
 * This is useful if you need to fetch data asynchronously on mount in a
 * component and show a loader while loading. If you only use useEffect the
 * component will render once before the loader is shown. And that will trigger
 * any other useEffects in the component tree.
 *
 * @param cb Initializer function
 * @param deps Dependency list for useEffect
 * @returns
 */
export function useInitializer(
    cb: () => Promise<void>,
    deps?: React.DependencyList,
) {
    const [initialized, setInitialized] = useState(false)
    useEffect(
        () => {
            cb()
                .then(() => setInitialized(true))
                .catch((reason) => reportError(reason))
        },
        // The dependency list cannot be statically checked here as we're
        // passing it through from the arguments of useInitializer. Also we
        // don't want to add `cb` to the list as it will change for each render.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        deps,
    )
    return initialized
}
