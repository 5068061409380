import { t } from "@lingui/macro"
import { makeAutoObservable } from "mobx"
import React from "react"

import { FormFields } from "src/lib/form-fields"

import { createLoadingKeys } from "src/lib/loading"

import { loads } from "src/channel/utils"
import { EmbedAdminService, avy_api_pkg_embed_Segment } from "src/api"
import { Channel } from "src/channel"

interface IFormFields {
    segmentIds: number[]
}

export class EmbeddedIntegrationStore {
    static Context = React.createContext<EmbeddedIntegrationStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "submit")

    private id?: number
    private setId(id?: number) {
        this.id = id
    }

    private _accessGroupId?: number
    setAccessGroupId(id?: number) {
        this._accessGroupId = id
    }
    get accessGroupId() {
        return this._accessGroupId
    }

    fields = new FormFields<IFormFields>({
        segmentIds: [],
    })

    constructor() {
        makeAutoObservable(this)
    }

    init(
        accessGroupId: number,
        id?: number,
        segments?: avy_api_pkg_embed_Segment[],
    ) {
        this.setId(id)
        this.setAccessGroupId(accessGroupId)

        if (id !== null) {
            this.initFields({
                segmentIds:
                    segments != null
                        ? segments.map((item) => item.segment_id ?? 0)
                        : [],
            })
        }

        /**
         * useInitializer/@loads is designed to handle async functions
         * and that is the case everywhere else, so handling this by returning a promise so as to
         * handle mounting of segmentPicker only after initialization
         **/
        return Promise.resolve()
    }

    @loads(() => EmbeddedIntegrationStore.LoadingKeys.submit)
    async submit() {
        const { id } = this

        if (this._accessGroupId == null) {
            this.fields.setErrors({ accessGroupId: t`errors.required` })
            return
        }

        await this.fields.catchErrors(async () => {
            const { data } = this.fields
            await EmbedAdminService.putV1AdminEmbedIntegrationPublish({
                integrationId: id ?? 0,
                request: {
                    segment_ids: data.segmentIds,
                },
            })
            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "embed-integrations",
                    action: "update",
                    item: {
                        id: id ?? 0,
                        name: "Segments update",
                    },
                },
            })
        })
    }

    private initFields(fields: IFormFields) {
        this.fields.init(fields)
    }
}
