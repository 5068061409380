import { t } from "@lingui/macro"
import { Stack, TextField } from "@mui/material"
import { observer } from "mobx-react"

import { FormPanel } from "src/components/FormPanel"
import { LibraryCategoriesStore } from "src/modals/library-categories/store"
import { useStore } from "src/store/lib/useStore"

export const LibraryCategoriesSettingsSection = observer(() => {
    const store = useStore(LibraryCategoriesStore)
    return (
        <FormPanel
            sections={[
                {
                    header: t`edit-category-modal.settings`,
                    content: (
                        <Stack spacing={2}>
                            <TextField
                                label={t`edit-category-modal.internal-name`}
                                onChange={store.formFields.setter(
                                    "internalName",
                                )}
                                value={
                                    store.formFields.get("internalName") ?? ""
                                }
                                helperText={store.formFields.error(
                                    "internalName",
                                )}
                                error={Boolean(
                                    store.formFields.error("internalName"),
                                )}
                                inputProps={{ maxLength: 40 }}
                            />
                            <TextField
                                label={t`edit-category-modal.category-name-in-app`}
                                onChange={store.formFields.setter("nameInApp")}
                                value={store.formFields.get("nameInApp") ?? ""}
                                helperText={
                                    store.formFields.error("nameInApp") ??
                                    t`edit-category-modal.category-name-in-app-helper`
                                }
                                error={Boolean(
                                    store.formFields.error("nameInApp"),
                                )}
                                inputProps={{ maxLength: 40 }}
                            />
                        </Stack>
                    ),
                },
            ]}
        />
    )
})
