import { observer } from "mobx-react"
import { Alert, Button, Stack } from "@mui/material"
import { t } from "@lingui/macro"
import { useCallback, useEffect } from "react"

import { EditAutomaticAssigneeStore, formFields } from "."

import { useStore } from "src/store/lib/useStore"
import { Form } from "src/components/Form"
import { FormPanel } from "src/components/FormPanel"
import { ModalHeader } from "src/components/ModalHeader"
import { GlobalStore } from "src/store"
import { IEditAutomaticAssigneeItem } from "src/types/automatic_assignee"
import { ListPage } from "src/components/ListPage"

export type IEditAutomaticAssigneeProps = {
    item?: IEditAutomaticAssigneeItem
}

export const EditAutomaticAssignee = observer(
    (props: IEditAutomaticAssigneeProps) => {
        const store = useStore(EditAutomaticAssigneeStore)
        const globalStore = useStore(GlobalStore)
        const thisModal =
            globalStore.modals.active[globalStore.modals.active.length - 1]
        thisModal.confirmOnOverlayClick = () => store.formFields.getIsDirty()

        useEffect(() => {
            store.init(props.item)
        }, [store, props.item])

        const handleSubmit = useCallback(
            async (event: React.FormEvent<HTMLFormElement>) => {
                event.preventDefault()
                store.setIsLoading(true)
                const request = {
                    admin_id: store.formFields.get("automaticAssignee"),
                    property_id: props.item?.row.propertyId,
                }
                await store.updateItem(request)

                if (!Boolean(store.formFields.error("generic"))) {
                    globalStore.modals.pop()
                }
            },
            [globalStore.modals, props.item, store],
        )

        return (
            <Form onSubmit={handleSubmit}>
                <>
                    <ModalHeader>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={
                                store.formFields.getIsDirty() === false ||
                                store.isLoading
                            }
                            data-testid="submit-automatic-assignee-form"
                        >
                            {t`global.save`}
                        </Button>
                    </ModalHeader>
                    {Boolean(store.formFields.error("generic")) && (
                        <Alert severity="error">
                            {store.formFields.error("generic")}
                        </Alert>
                    )}

                    <ListPage
                        header={{
                            header: props?.item?.row.propertyName,
                            breadcrumbs: [],
                        }}
                    >
                        <FormPanel
                            sections={[
                                {
                                    header: t`contact-form-automatic-assignee-view.header`,
                                    content: (
                                        <Stack spacing={2}>
                                            <formFields.Assignee
                                                propertyId={
                                                    props.item?.row.propertyId
                                                }
                                            />
                                        </Stack>
                                    ),
                                },
                            ]}
                        />
                    </ListPage>
                </>
            </Form>
        )
    },
)
