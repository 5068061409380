import { t } from "@lingui/macro"

import { ItemTypes } from "src/types/library-information-items/item-types"

export const getItemTypeText = (itemType: ItemTypes) => {
    let itemTypeText = ""

    switch (itemType) {
        case "page":
            itemTypeText = t`library-information-items-view.types.page`
            break
        case "url":
            itemTypeText = t`library-information-items-view.types.link-url`
            break
        case "document":
            itemTypeText = t`library-information-items-view.types.document`
            break
        default:
            break
    }

    return itemTypeText
}
