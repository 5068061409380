import { t } from "@lingui/macro"
import {
    Button,
    Fab,
    Stack,
    styled,
    useMediaQuery,
    useTheme,
} from "@mui/material"
import { observer } from "mobx-react"
import React from "react"

import { Tooltip } from "src/components/Tooltips/Tooltip"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"

import { Add24 } from "src/components/icons/Add24"

export const H1 = styled("h1")(({ theme }) => ({
    fontSize: "20px",
    lineHeight: 1.6,
    fontWeight: 500,
    color: theme.palette.grey[900],
    margin: 0,
}))

export const Subheader = styled("p")(({ theme }) => ({
    color: theme.palette.grey[700],
    margin: 0,
    marginTop: "8px",
}))

interface IProps {
    header?: string
    breadcrumbs: string[]
    createOptions?: {
        onClick: () => void
        item: string
    }
    disabled?: boolean
    tailingComponent?: React.ReactNode
}

export type IPageHeaderProps = IProps

export const PageHeader = observer((props: IProps) => {
    const gstore = useStore(GlobalStore)
    const theme = useTheme()
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))

    const isEnabled =
        props.disabled === true ||
        (props.disabled === undefined &&
            gstore.session.hasSelectedAllAccessGroups)

    let createButton: React.ReactNode = null
    let tailingComponent: React.ReactNode = null

    if (props.tailingComponent != null) {
        tailingComponent = props.tailingComponent
    }
    if (props.createOptions != null) {
        const tooltipText = isEnabled
            ? t({
                  id: "page-header.create-button-tooltip",
                  values: { item: props.createOptions.item },
              })
            : "" // An empty string disables and hides the tooltip

        createButton = (
            <Tooltip title={tooltipText} placement="left" arrow>
                <div>
                    {smallScreen ? (
                        <Fab
                            data-testid="create-button"
                            variant="circular"
                            color="primary"
                            size="small"
                            disabled={isEnabled}
                            onClick={props.createOptions.onClick}
                        >
                            <Add24 />
                        </Fab>
                    ) : (
                        <Button
                            data-testid="create-button"
                            variant="contained"
                            onClick={props.createOptions.onClick}
                            disabled={isEnabled}
                            title={t({
                                id: "page-header.create-button-title",
                                values: { item: props.createOptions.item },
                            })}
                        >
                            + {t`page-header.create-button-text`}
                        </Button>
                    )}
                </div>
            </Tooltip>
        )
    }

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            <Stack>
                <H1 data-testid="Page-header">{props.header}</H1>
                <Subheader>{props.breadcrumbs.join(" / ")}</Subheader>
            </Stack>
            {tailingComponent !== null ? tailingComponent : createButton}
        </Stack>
    )
})
