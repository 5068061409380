import { ContentState, convertToRaw, RawDraftContentState } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import { useCallback, useMemo, useState } from "react"
import { Editor } from "react-draft-wysiwyg"

import { FileAdminService } from "src/api"

// Temporary style overrides. The design of this component will change later
// when the Design team delivers a design. It has been temporarily styled to
// look somewhat ok.
import "./style.css"
import { youtubeIdRegex } from "src/lib/urlLinksHandler"

interface IProps {
    initialValue: string
    onChange: (value: string) => void
    invalid?: boolean
    readOnly?: boolean
    placeholder?: string
}

export const WysiwygEditor = (props: IProps) => {
    const [contentState] = useState<RawDraftContentState>(() => {
        const blocksFromHTML = htmlToDraft(props.initialValue)
        return convertToRaw(
            ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            ),
        )
    })

    const handleChange = useCallback(
        (contentState: RawDraftContentState) => {
            props.onChange(draftToHtml(contentState))
        },
        [props],
    )

    const handleImageUpload = useCallback(async (file: Blob) => {
        const url = await FileAdminService.postV1AdminUpload({ file: file })
        return { data: { link: url } }
    }, [])

    const embedCallback = (embeddedLink: string) => {
        let match = embeddedLink.match(youtubeIdRegex)
        if (match != null && match[2].length === 11) {
            return `https://www.youtube.com/embed/${match[2]}`
        }
    }

    const toolbar = useMemo(
        () => ({
            options: [
                "inline",
                "blockType",
                "list",
                "image",
                "embedded",
                "link",
                "emoji",
                "history",
            ],
            inline: {
                options: ["bold", "underline"],
            },
            blockType: {
                options: ["Normal", "H3", "H2"],
            },
            list: {
                options: ["unordered", "ordered"],
            },
            embedded: {
                embedCallback,
            },
            image: {
                urlEnabled: true,
                uploadEnabled: true,
                alignmentEnabled: false,
                uploadCallback: handleImageUpload,
                previewImage: true,
            },
        }),
        [handleImageUpload],
    )

    return (
        <Editor
            data-testid="wysiwyg-editor"
            toolbar={toolbar}
            onChange={handleChange}
            initialContentState={contentState}
            wrapperClassName={`wysiwyg-editor__wrapper ${
                props.invalid === true && "wysiwyg-editor__wrapper--invalid"
            }`}
            editorClassName="wysiwyg-editor__editor"
            toolbarClassName="wysiwyg-editor__toolbar"
            readOnly={props.readOnly}
            handlePastedText={() => false}
            placeholder={props?.placeholder}
        />
    )
}
