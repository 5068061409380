import React, { useCallback } from "react"
import { observer } from "mobx-react"

import { List as MUIList, styled } from "@mui/material"
import { Container, Draggable, DropResult } from "react-smooth-dnd"

import { Item } from "src/components/DragAndDropList/Item"
import { IActionDropdownItem } from "src/components/ActionDropdown"

const List = styled(MUIList)<{ component: string }>({
    "& .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper": {
        overflow: "unset",
    },
})

interface IProps<TItem> {
    items: TItem[]
    onDrop: (result: IDropResult) => void
    rowRenderer: (item: TItem) => React.ReactNode
    rowActionsRenderer?: (item: TItem) => IActionDropdownItem[]
}

export type PassthroughDragAndDropListProps<TItem> = Omit<
    IProps<TItem>,
    "onDrop"
>

export const DragAndDropList = observer(function <TItem>(props: IProps<TItem>) {
    const onDrop = useCallback(
        (dropResult: DropResult) => {
            if (
                dropResult.removedIndex == null ||
                dropResult.addedIndex == null ||
                dropResult.addedIndex === dropResult.removedIndex
            ) {
                return
            }

            props.onDrop({
                removedIndex: dropResult.removedIndex,
                addedIndex: dropResult.addedIndex,
            })
        },
        [props],
    )

    return (
        <List component="ol">
            <Container
                dragHandleSelector=".drag-handle"
                lockAxis="y"
                onDrop={onDrop}
            >
                {props.items.map((item, index) => (
                    <Draggable key={index}>
                        <Item
                            key={index}
                            rowActions={props.rowActionsRenderer?.(item)}
                        >
                            {props.rowRenderer(item)}
                        </Item>
                    </Draggable>
                ))}
            </Container>
        </List>
    )
})
