import { observer } from "mobx-react"
import { styled } from "@mui/material"

import { Body } from "src/modals/view-community-post/Post/Body"
import { Footer } from "src/modals/view-community-post/Post/Footer"
import { CommentList } from "src/modals/view-community-post/Post/CommentList"

const Wrap = styled("div")({ flex: 1, overflow: "auto", padding: "16px" })

const Hr = styled("hr")(({ theme }) => ({
    margin: "16px 0",
    border: "none",
    height: "1px",
    background: theme.palette.grey[500],
}))

export const Post = observer(() => {
    return (
        <Wrap>
            <Body />
            <Footer />
            <Hr />
            <CommentList />
        </Wrap>
    )
})
