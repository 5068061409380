import { alpha, styled } from "@mui/material"

export const ImagePreviewHoverOptions = styled("div")(({ theme }) => ({
    background: alpha(theme.palette.grey[900], 0.7),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: 0,
    position: "absolute",
    inset: 0,
    color: theme.palette.common.white,
    "&:hover": {
        opacity: 1,
    },
}))
