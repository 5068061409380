import { observer } from "mobx-react"
import React, { useCallback, useEffect, useMemo } from "react"
import { MenuItem, Link, TextField } from "@mui/material"
import { t } from "@lingui/macro"

import { ManageUsersStore } from "./store"

import { ConfirmDeleteModal } from "src/modals/confirm-delete"
import { ManageUsersCreateOrEditModal } from "src/modals/manage-users-create-edit-copy"
import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { ListPage } from "src/components/ListPage"
import { Table } from "src/components/Table"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { IPageFilterProps } from "src/components/PageFilter"

const View = observer(() => {
    const store = useStore(ManageUsersStore)
    const globalStore = useStore(GlobalStore)
    const loggedInUsersPropertyOwners = globalStore.session.propertyOwners

    useEffect(() => {
        ;(async () => {
            await store.init(loggedInUsersPropertyOwners)
        })()
    }, [loggedInUsersPropertyOwners, store])

    const handleNewUserClick = useCallback(() => {
        globalStore.modals.open(() => (
            <ManageUsersCreateOrEditModal
                duplicate={false}
                propertyOwners={
                    loggedInUsersPropertyOwners.length > 1
                        ? loggedInUsersPropertyOwners
                        : undefined
                }
            />
        ))
    }, [globalStore.modals, loggedInUsersPropertyOwners])

    const header = useMemo(
        () => ({
            header: t`manage-users-view.header`,
            breadcrumbs: [t`manage-users-view.header`],
            createOptions: {
                item: t`manage-users-view.create-user`,
                onClick: handleNewUserClick,
            },
        }),
        [handleNewUserClick],
    )

    const handleEditUserClick = useCallback(
        (id: number) => (event: React.MouseEvent<Element>) => {
            event.preventDefault()
            globalStore.modals.open(() => (
                <ManageUsersCreateOrEditModal adminId={id} duplicate={false} />
            ))
        },
        [globalStore.modals],
    )

    const handleDuplicateUserClick = useCallback(
        (id: number) => (event: React.MouseEvent<Element>) => {
            event.preventDefault()
            globalStore.modals.open(() => (
                <ManageUsersCreateOrEditModal adminId={id} duplicate={true} />
            ))
        },
        [globalStore.modals],
    )

    const deleteUser = useCallback(
        (id: number) => async () => {
            try {
                await store.deleteUser(id)
            } finally {
                globalStore.modals.pop()
            }
        },
        [store, globalStore.modals],
    )

    const handleDeleteUserClick = useCallback(
        (id: number) => async () => {
            await globalStore.modals.open(
                () => (
                    <ConfirmDeleteModal
                        variant="single"
                        deleteItem={deleteUser(id)}
                        itemId={id}
                    />
                ),
                { variant: "slide-up-w600" },
            )
        },
        [deleteUser, globalStore.modals],
    )

    const handlePropertyOwnerDropdownChange = useCallback(
        async (event) => {
            await store.setPropertyOwnerId(event.target.value)
        },
        [store],
    )

    const handleLegalEntityDropdownChange = useCallback(
        async (event) => {
            await store.setLegalEntityId(event.target.value)
        },
        [store],
    )

    const tableIsLoading =
        !store.admins.meta.initialized ||
        globalStore.loading.is(ManageUsersStore.LoadingKeys.pageLoad) ||
        globalStore.loading.is(ManageUsersStore.LoadingKeys.delete) ||
        globalStore.loading.is(ManageUsersStore.LoadingKeys.duplicate)

    const filter: IPageFilterProps = {
        actions: (
            <>
                <div>
                    {/* TextField? Why? */}
                    <TextField
                        value={store.propertyOwnerId ?? ""}
                        onChange={handlePropertyOwnerDropdownChange}
                        select
                        label={t`manage-users-view.property-owner-manager`}
                        size="small"
                        disabled={store.propertyOwners.length === 1}
                        sx={{ minWidth: "250px " }}
                    >
                        {store.propertyOwners.map((propertyOwner) => {
                            return (
                                <MenuItem
                                    key={propertyOwner.id}
                                    value={propertyOwner.id}
                                >
                                    {propertyOwner.name}
                                </MenuItem>
                            )
                        })}
                    </TextField>
                </div>
                <div>
                    <TextField
                        value={store.legalEntityId ?? ""}
                        onChange={handleLegalEntityDropdownChange}
                        select
                        label={t`manage-users-view.legal-entity-brf`}
                        size="small"
                        sx={{ minWidth: "250px " }}
                    >
                        {store.legalEntities.map((legalEntity) => {
                            return (
                                <MenuItem
                                    key={legalEntity.id}
                                    value={legalEntity.id}
                                >
                                    {legalEntity.name}
                                </MenuItem>
                            )
                        })}
                    </TextField>
                </div>
            </>
        ),
    }

    return (
        <ListPage header={header} filter={filter} loading={tableIsLoading}>
            <Table
                paginator={store.admins}
                headers={[
                    {
                        key: "adminId",
                        name: t`manage-users-view.admin-id-header`,
                    },
                    {
                        key: "name",
                        name: t`manage-users-view.name-header`,
                    },
                    {
                        key: "email",
                        name: t`manage-users-view.email-address-header`,
                    },
                ]}
                rowRenderer={(item) => ({
                    adminId: item.adminId,
                    name: (
                        <Link
                            component="button"
                            onClick={handleEditUserClick(item.adminId)}
                        >
                            {item.name}
                        </Link>
                    ),
                    email: item.email,
                })}
                rowActionsRenderer={(item) => [
                    {
                        text: t`manage-users-view.edit-user`,
                        onClick: handleEditUserClick(item.adminId),
                    },
                    {
                        text: t`manage-users-view.duplicate-user`,
                        onClick: handleDuplicateUserClick(item.adminId),
                    },
                    {
                        text: t`manage-users-view.delete-user`,
                        onClick: handleDeleteUserClick(item.adminId),
                        destructive: true,
                    },
                ]}
            />
        </ListPage>
    )
})

export const ManageUsersView = () => (
    <StoreProvider Store={ManageUsersStore}>
        <View />
    </StoreProvider>
)
