import {
    styled,
    Toolbar,
    AppBar as MUIAppBar,
    alpha,
    Divider as MUIDivider,
    ListItem as MUIListItem,
    Drawer as MUIDrawer,
    ListItemText as MUListItemText,
} from "@mui/material"

export const ContentContainer = styled("main")(({ theme }) => ({
    width: "100%",
    [theme.breakpoints.up("lg")]: {
        marginLeft: "280px",
        width: "calc(100% - 280px)",
    },
}))

export const OutletWrap = styled("div")(({ theme }) => ({
    [theme.breakpoints.up("lg")]: { marginTop: "4rem" },
    boxSizing: "border-box",
    width: "100%",
    padding: "2rem",
}))

export const ToolbarSpacer = styled(Toolbar)(({ theme }) => ({
    display: "block",
    [theme.breakpoints.up("lg")]: {
        display: "none",
    },
}))

export const AccessGroupWrapper = styled("div")(({ theme }) => ({
    [theme.breakpoints.down("lg")]: {
        height: "64px",
        width: "100%",
        backgroundColor: theme.palette.grey[300],
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        padding: "12px 16px",
    },
    display: "none",
}))

export const StyledAppBar = styled(MUIAppBar)(({ theme }) => ({
    display: "block",
    backgroundImage: "url(/static/images/avy-logo.png)",
    backgroundSize: "103px 38px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.grey[300],
    [theme.breakpoints.up("lg")]: {
        display: "none",
    },
}))

export const Flag = styled("img")(({ theme }) => ({
    width: "1.5rem",
    borderRadius: theme.shape.borderRadius,
    marginRight: "0.5rem",
}))

export const ItemContainer = styled("span")({
    display: "flex",
    alignItems: "center",
})

export const Divider = styled(MUIDivider)(({ theme }) => ({
    backgroundColor: alpha(theme.palette.common.white, 0.25),
}))

export const I18nListItem = styled(MUIListItem)(({ theme }) => ({
    [theme.breakpoints.up("lg")]: {
        display: "none",
    },
}))

export const SuperUserSection = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.grey[200],
    flexDirection: "column",
    width: "100%",
    height: "100%",
    paddingTop: "16px",
}))

export const NavSection = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.grey[300],
    flexDirection: "column",
    padding: 0,
    width: "100%",
}))

export const Drawer = styled(MUIDrawer)(({ theme }) => ({
    "& .MuiPaper-root": {
        backgroundColor: theme.palette.brand.green.main,
        border: "none",
        [theme.breakpoints.down("sm")]: {
            paddingBottom: "70px",
        },
        [theme.breakpoints.down("lg")]: {
            paddingBottom: "70px",
        },
    },
}))

export const LargeScreenDrawer = styled(Drawer)(({ theme }) => ({
    position: "relative",
    zIndex: 1,
    display: "block",
    "& .MuiDrawer-paper": {
        boxSizing: "border-box",
        width: "280px",
        paddingTop: "4rem",
        backgroundColor: theme.palette.grey[300],
    },
    [theme.breakpoints.down("lg")]: {
        display: "none",
    },
}))

export const SmallScreenDrawer = styled(Drawer)(({ theme }) => ({
    display: "block",
    "& .MuiDrawer-paper": {
        width: "calc(100% - 4rem)",
        minHeight: "100vh",
        maxWidth: "300px",
        boxSizing: "border-box",
        backgroundColor: theme.palette.grey[300],
    },
    [theme.breakpoints.up("lg")]: {
        display: "none",
    },
}))

export const CustomListItemButton = styled("a")(({ theme }) => ({
    color: theme.palette.grey[600],
    padding: 0,
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    height: "30px",
}))

export const CustomListItemText = styled("span")(() => ({
    fontSize: "12px",
    fontFamily: "Roboto",
    fontWeight: "500",
    letterSpacing: "0.15px",
    lineHeight: "12px",
}))

export const ListItemText = styled(MUListItemText)(({ theme }) => ({
    color: theme.palette.grey[800],
}))

export const ChevronIconWrapper = styled("div")(({ theme }) => ({
    color: theme.palette.grey[800],
    display: "flex",
    alignItems: "center",
    paddingRight: "16px",
}))

export const EllipseIconWrapper = styled("div")(({ theme }) => ({
    height: "100%",
    color: theme.palette.grey[400],
    margin: "16px",
    display: "flex",
    alignItems: "center",
}))

export const UserInfoIconWrapper = styled("div")(() => ({
    width: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}))
