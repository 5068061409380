import { ForgotPassword } from "./ForgotPassword"
import { ForgotPasswordStore } from "./store"

import { StoreProvider } from "src/store/lib/StoreProvider"

export const ForgotPasswordView = () => (
    <StoreProvider Store={ForgotPasswordStore}>
        <ForgotPassword />
    </StoreProvider>
)
