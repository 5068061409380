import { t } from "@lingui/macro"
import { Event, LocationOn } from "@mui/icons-material"
import {
    InputAdornment,
    Stack,
    TextField,
    useMediaQuery,
    useTheme,
} from "@mui/material"
import { observer } from "mobx-react"

import { FormFieldSet } from "src/components/FormFieldSet"
import { formatDateTime } from "src/lib/date"
import { CaseDetailStore } from "src/modals/cases-v2-detail/store"
import { useStore } from "src/store/lib/useStore"

export const ReporterSection = observer(() => {
    const store = useStore(CaseDetailStore)
    const theme = useTheme()
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))

    const createdAt = store.form.get("createdAt")

    return (
        <FormFieldSet>
            <Stack spacing={2}>
                {(Boolean(store.form.get("tenantEmail")) ||
                    Boolean(store.form.get("tenantPhoneNumber"))) && (
                    <Stack
                        spacing={2}
                        direction={smallScreen ? "column" : "row"}
                    >
                        {store.form.get("tenantId") > 0 && (
                            <TextField
                                label={t`cases-detail-modal.reporter-section.tenant-id-label`}
                                value={store.form.get("tenantId")}
                                onChange={store.form.setter("tenantId")}
                                disabled
                            />
                        )}
                        {Boolean(store.form.get("tenantFullName")) && (
                            <TextField
                                label={t`cases-detail-modal.reporter-section.reporter-label`}
                                value={store.form.get("tenantFullName")}
                                onChange={store.form.setter("tenantFullName")}
                                disabled
                            />
                        )}
                    </Stack>
                )}
                {(Boolean(store.form.get("tenantEmail")) ||
                    Boolean(store.form.get("tenantPhoneNumber"))) && (
                    <Stack
                        spacing={2}
                        direction={smallScreen ? "column" : "row"}
                    >
                        {Boolean(store.form.get("tenantEmail")) && (
                            <TextField
                                label={t`cases-detail-modal.reporter-section.tenant-email-label`}
                                value={store.form.get("tenantEmail")}
                                onChange={store.form.setter("tenantEmail")}
                                disabled
                            />
                        )}
                        {Boolean(store.form.get("tenantPhoneNumber")) && (
                            <TextField
                                label={t`cases-detail-modal.reporter-section.tenant-phonenumber-label`}
                                value={store.form.get("tenantPhoneNumber")}
                                onChange={store.form.setter(
                                    "tenantPhoneNumber",
                                )}
                                disabled
                            />
                        )}
                    </Stack>
                )}
                {Boolean(store.form.get("tenantPhoneNumber")) && (
                    <TextField
                        label={t`cases-detail-modal.reporter-section.tenant-address-label`}
                        value={store.form.get("objectAddress")}
                        onChange={store.form.setter("objectAddress")}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LocationOn />
                                </InputAdornment>
                            ),
                        }}
                        disabled
                    />
                )}
                <Stack spacing={2} direction="row">
                    <TextField
                        label={t`cases-detail-modal.reporter-section.created-label`}
                        defaultValue={
                            createdAt != null ? formatDateTime(createdAt) : ""
                        }
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Event />
                                </InputAdornment>
                            ),
                        }}
                        disabled
                    />
                </Stack>
            </Stack>
        </FormFieldSet>
    )
})
