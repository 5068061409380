import { observer } from "mobx-react"
import { Button, Checkbox, styled, FormControlLabel } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { t } from "@lingui/macro"

import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"

import { IMultiSelectItem } from "src/modals/manage-users-create-edit-copy/store"

interface IProps {
    title: string
    items: IMultiSelectItem[]
    selectedItems: number[]
    handleCheckboxChange: (id: number, AllSelect: boolean) => void
}

const List = styled("ul")(() => ({
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    maxHeight: "400px",
    listStyle: "none",
    paddingLeft: "24px",
}))

const ListItem = styled("li")(() => ({
    display: "flex",
    flexDirection: "row",
    marginRight: "24px",
}))

const ButtonContainer = styled("div")(() => ({
    display: "flex",
    justifyContent: "flex-end",
}))

const Header = styled("h3")(() => ({
    lineHeight: "32px",
    fontWeight: 500,
    fontSize: "20px",
    margin: "16px 24px",
}))

const HeaderContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
}))

export const ScrollableEditModal = observer(
    ({ title, items, selectedItems, handleCheckboxChange }: IProps) => {
        const globalStore = useStore(GlobalStore)
        const [allChecked, setAllChecked] = useState(
            selectedItems.length === items.length,
        )

        const getCheckedValue = useCallback(
            (id: number) => {
                const checked = selectedItems.find(
                    (selectedItem) => selectedItem === id,
                )
                return checked !== undefined
            },
            [selectedItems],
        )

        const allSelectedMethod = (id?: number) => {
            if (id != null) {
                if (selectedItems.length === items.length) {
                    handleCheckboxChange(id, !allChecked)
                } else {
                    handleCheckboxChange(id, allChecked)
                }
            } else {
                items.map((item) => handleCheckboxChange(item.id, !allChecked))
            }
        }
        useEffect(() => {
            selectedItems.length === items.length
                ? setAllChecked(true)
                : setAllChecked(false)
        }, [selectedItems.length, items.length])

        return (
            <>
                <HeaderContainer>
                    <Header>{title}</Header>
                    <p>{`${
                        selectedItems.length
                    } ${t`scrollable-edit-modal.selected`}`}</p>
                </HeaderContainer>

                <FormControlLabel
                    control={
                        <Checkbox
                            id="select-all-check-box"
                            checked={
                                selectedItems.length > 0 &&
                                selectedItems.length === items.length
                            }
                            onChange={() => allSelectedMethod()}
                            indeterminate={
                                selectedItems.length > 0 &&
                                selectedItems.length !== items.length
                            }
                            sx={{ paddingLeft: "44px" }}
                        />
                    }
                    label={t`global.select-all`}
                />
                <List>
                    {items.map((item) => {
                        return (
                            <ListItem key={item.id} data-testid="list-item">
                                <Checkbox
                                    checked={getCheckedValue(item.id)}
                                    onChange={() => {
                                        allSelectedMethod(item.id)
                                    }}
                                    data-testid="checkbox"
                                />
                                <p>{item.name}</p>
                            </ListItem>
                        )
                    })}
                </List>
                <ButtonContainer>
                    <Button
                        onClick={() => {
                            globalStore.modals.pop()
                        }}
                    >
                        {t`scrollable-edit-modal.done-button`}
                    </Button>
                </ButtonContainer>
            </>
        )
    },
)
