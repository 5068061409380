import { styled } from "@mui/material/styles"
import { Box } from "@mui/material"

export const IconItem = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
        "& .icon-box": {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))

export const IconBox = styled(Box)(({ theme }) => ({
    width: 91,
    height: 91,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
}))

export const ImageItem = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
        "& .icon-box": {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))

export const ImagesBox = styled(Box)(({ theme }) => ({
    width: 158,
    height: 158,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
}))
