import { t } from "@lingui/macro"
import { Stack } from "@mui/material"
import { observer } from "mobx-react"

import { FormPanel } from "src/components/FormPanel"
import { ModalTextField } from "src/components/ModalTextField"
import { TagsStore } from "src/modals/tags/store"
import { useStore } from "src/store/lib/useStore"

export const TagsContentSection = observer(() => {
    const store = useStore(TagsStore)

    return (
        <FormPanel
            sections={[
                {
                    header: t`tags-modal.section.content`,
                    content: (
                        <Stack spacing={2}>
                            <ModalTextField
                                variant="withToolTip"
                                label={t`tags-modal.form-fields.name`}
                                tooltipText={t`tags-modal.form-fields.name-tooltip`}
                                value={store.formFields.get("name")}
                                onChange={store.formFields.setter("name")}
                                helperText={
                                    store.formFields.error("name") ??
                                    t`tags-modal.form-fields.name-helper`
                                }
                                error={Boolean(store.formFields.error("name"))}
                                maxLength={36}
                                rows={1}
                            />
                            <ModalTextField
                                variant="withToolTip"
                                label={t`tags-modal.form-fields.description`}
                                tooltipText={t`tags-modal.form-fields.description-tooltip`}
                                value={store.formFields.get("description")}
                                onChange={store.formFields.setter(
                                    "description",
                                )}
                                helperText={
                                    store.formFields.error("description") ??
                                    t`tags-modal.form-fields.description-helper`
                                }
                                error={Boolean(
                                    store.formFields.error("description"),
                                )}
                                rows={4}
                            />
                        </Stack>
                    ),
                },
            ]}
        />
    )
})
