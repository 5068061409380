import { IIconProps } from "src/components/icons"

export const Alert16 = (props: IIconProps) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 1.33337C4.3181 1.33337 1.33333 4.31814 1.33333 8.00004C1.33333 11.6819 4.3181 14.6667 8 14.6667C11.6819 14.6667 14.6667 11.6819 14.6667 8.00004C14.6627 4.31979 11.6802 1.33735 8 1.33337ZM8.13888 4.11115C8.59912 4.11115 8.97222 4.48425 8.97222 4.94448C8.97222 5.40472 8.59912 5.77782 8.13888 5.77782C7.67865 5.77782 7.30555 5.40472 7.30555 4.94448C7.30555 4.48425 7.67865 4.11115 8.13888 4.11115ZM7.16666 11.6112H9.38888C9.69571 11.6112 9.94444 11.3624 9.94444 11.0556C9.94444 10.7488 9.69571 10.5 9.38888 10.5H8.97222C8.89551 10.5 8.83333 10.4379 8.83333 10.3612V7.72226C8.83333 7.10861 8.33587 6.61115 7.72222 6.61115H7.16666C6.85984 6.61115 6.61111 6.85988 6.61111 7.16671C6.61111 7.47353 6.85984 7.72226 7.16666 7.72226H7.58333C7.66003 7.72226 7.72222 7.78444 7.72222 7.86115V10.3612C7.72222 10.4379 7.66003 10.5 7.58333 10.5H7.16666C6.85984 10.5 6.61111 10.7488 6.61111 11.0556C6.61111 11.3624 6.85984 11.6112 7.16666 11.6112Z"
            fill="currentcolor"
        />
    </svg>
)
