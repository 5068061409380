import {
    HeadContainer,
    HeadRightAlignedContainer,
    HeadWrapper,
    HeaderSubWrapper,
} from "./styled"

import { HeadAccessGroupSelect } from "src/layouts/DefaultLayout/Head/HeadAccessGroupSelect"
import { I18nSelect } from "src/layouts/DefaultLayout/I18nSelect"
import { HeadUserInfo } from "src/layouts/DefaultLayout/Head/HeadUserInfo"
import { Logo } from "src/layouts/DefaultLayout/Navigation/Logo"

export const Head = () => (
    <HeadWrapper>
        <HeadContainer>
            <Logo />
            <HeaderSubWrapper>
                <HeadAccessGroupSelect />
                <HeadRightAlignedContainer>
                    <I18nSelect />
                    <HeadUserInfo />
                </HeadRightAlignedContainer>
            </HeaderSubWrapper>
        </HeadContainer>
    </HeadWrapper>
)
