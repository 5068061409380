import { i18n } from "@lingui/core"
import { t } from "@lingui/macro"
import { formatDistance, isSameDay, parseISO } from "date-fns"

import { getLocale } from "src/locales"

export function timeSinceDate(date: Date) {
    return formatDistance(date, new Date(), {
        addSuffix: true,
        locale: getLocale().dateFnsLocale,
    })
}

/**
 * Asserts that a given date is valid, throws otherwise
 *
 * @param date Source
 */
export function assertValidDate(date: Date) {
    if (date.toString() === "Invalid Date") {
        throw new Error(`Given date is invalid!`)
    }

    return date
}

/**
 * Parses an ISO date and asserts that it's valid
 *
 * @param date source date
 * @returns Date object from the date
 */
export function parseDate(date: string | Date): Date
export function parseDate(date: null | undefined): null
export function parseDate(date: string | Date | null | undefined): Date | null
export function parseDate(date: string | Date | null | undefined): Date | null {
    if (date == null) {
        return null
    }

    const parsedDate = typeof date === "string" ? parseISO(date) : date

    assertValidDate(parsedDate)

    return parsedDate
}

export function formatMinutesFromMidnight(minutes: number) {
    const bounds = { min: 0, max: 24 * 60 }
    if (minutes < bounds.min || minutes > bounds.max) {
        throw new Error(
            `Input of ${formatMinutesFromMidnight.name} is out-of-bounds. Must be between ${bounds.min} and ${bounds.max}, got ${minutes}`,
        )
    }
    const zeroedHours = `0${Math.floor(minutes / 60)}`.slice(-2)
    const zeroedMinutes = `0${minutes % 60}`.slice(-2)
    // TODO: support AM/PM
    return `${zeroedHours}:${zeroedMinutes}`
}

export function timeToMinutesFromMidnight(time: string) {
    const [hoursRaw, minutesRaw] = time.split(":")
    return parseInt(hoursRaw, 10) * 60 + parseInt(minutesRaw, 10)
}

/**
 * Formats a date to a date string. The format is based on the selected
 * locale/language and is determined by the built-in `Intl` object.
 *
 * `dateStyle` will default to "short" if not provided.
 *
 * [See formatting options](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat)
 *
 * [See lingui I18n.date docs](https://lingui.js.org/ref/core.html#I18n.date)
 *
 * @param date Date to be formatted
 * @param options Formatting options
 * @returns A formatted string of the provided date
 */
export function formatDate(
    date: Date,
    options?: {
        dateStyle: Intl.DateTimeFormatOptions["dateStyle"]
    },
) {
    return i18n.date(date, {
        dateStyle: options?.dateStyle ?? "short",
        timeStyle: undefined,
    })
}

/**
 * Formats a date to a datetime string. The format is based on the selected
 * locale/language and is determined by the built-in `Intl` object.
 *
 * Both `dateStyle` and `timeStyle` will default to "short" if not provided.
 *
 * [See formatting options](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat)
 *
 * [See lingui I18n.date docs](https://lingui.js.org/ref/core.html#I18n.date)
 *
 * @param date Date to be formatted
 * @param options Formatting options
 * @returns A formatted string of the provided date
 */
export function formatDateTime(
    date: Date,
    options?: {
        dateStyle: Intl.DateTimeFormatOptions["dateStyle"]
        timeStyle: Intl.DateTimeFormatOptions["timeStyle"]
    },
) {
    return i18n.date(date, {
        dateStyle: options?.dateStyle ?? "short",
        timeStyle: options?.timeStyle ?? "short",
    })
}

/**
 * Formats a date range to a datetime range string. The format is based on the selected
 * locale/language and is determined by the built-in `Intl` object.
 *
 * Both `dateStyle` and `timeStyle` will default to "short" if not provided.
 *
 * [See formatting options](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat)
 *
 * [See lingui I18n.date docs](https://lingui.js.org/ref/core.html#I18n.date)
 *
 * @param dates Date range to be formatted: [start, end]
 * @param options Formatting options
 * @returns A formatted string of the provided date range
 */
export function formatDateTimeRange(
    dates: [Date, Date],
    options?: {
        dateStyle: Intl.DateTimeFormatOptions["dateStyle"]
        timeStyle: Intl.DateTimeFormatOptions["timeStyle"]
    },
) {
    options = {
        dateStyle: options?.dateStyle ?? "short",
        timeStyle: options?.timeStyle ?? "short",
    }

    const [start, end] = dates

    if (start > end) {
        return t`global.invalid-datetime-range`
    }

    if (isSameDay(start, end)) {
        const firstAsString = i18n.date(start, options)
        const secondAsString = i18n.date(end, {
            timeStyle: options.timeStyle,
        })
        return `${firstAsString} - ${secondAsString}`
    } else {
        const firstAsString = i18n.date(start, options)
        const secondAsString = i18n.date(end, {
            timeStyle: options.timeStyle,
        })
        return `${firstAsString} - ${secondAsString}`
    }
}
