import { IIconProps } from "src/components/icons"

export const Search24 = (props: IIconProps) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.0795 14.9641L20.5611 18.4476C21.1463 19.0333 21.1463 19.9827 20.5611 20.5684C19.9715 21.1438 19.0309 21.1438 18.4414 20.5684L14.9598 17.0857C11.6728 19.2844 7.255 18.6298 4.74603 15.5722C2.23707 12.5147 2.45533 8.05162 5.25063 5.25379C8.04594 2.45596 12.5065 2.236 15.5633 4.74526C18.62 7.25452 19.2758 11.6745 17.0795 14.9641ZM16.1283 10.6961C16.1283 7.69326 13.6954 5.25897 10.6941 5.25897C7.69431 5.26227 5.26329 7.69463 5.25999 10.6961C5.25999 13.699 7.69295 16.1332 10.6941 16.1332C13.6954 16.1332 16.1283 13.699 16.1283 10.6961Z"
            fill="currentColor"
        />
    </svg>
)
