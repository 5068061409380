export const iconList = [
    "404.svg",
    "Add2.svg",
    "AddCircle2.svg",
    "AI.svg",
    "AlertCircle.svg",
    "AlertTriangle.svg",
    "ArrowCircleDown.svg",
    "ArrowCircleLeft.svg",
    "ArrowCircleRight.svg",
    "ArrowCircleUp.svg",
    "ArrowDown.svg",
    "ArrowLeft.svg",
    "ArrowLeftRight.svg",
    "ArrowReset.svg",
    "ArrowRight.svg",
    "ArrowUp.svg",
    "Attach2.svg",
    "AvyIconFilled.svg",
    "Bank2.svg",
    "BankID.svg",
    "BellOutlined2.svg",
    "BellOutlinedEmpty.svg",
    "BellOutlinedOff2.svg",
    "BicyclePurchase.svg",
    "BicycleRent.svg",
    "BicycleStorage.svg",
    "Blizz2.svg",
    "Blueprint.svg",
    "Boplats.svg",
    "BoxStorage2.svg",
    "BoxStorageFilled.svg",
    "Building2.svg",
    "Buildings2.svg",
    "BulbOutlined2.svg",
    "Bullet.svg",
    "BusinessCard.svg",
    "CalendarAdd.svg",
    "CalendarAdd2.svg",
    "CalendarDate.svg",
    "CalendarDone.svg",
    "CalendarEdit.svg",
    "CalendarEmpty.svg",
    "CalendarFavorite.svg",
    "CalendarLocked.svg",
    "CalendarTime.svg",
    "CameraOutlined2.svg",
    "CameraVideoOutlined.svg",
    "Car2.svg",
    "ChatTranslate.svg",
    "CheckCircle2.svg",
    "CheckCircleFilled2.svg",
    "Checklist2.svg",
    "CheckSimple.svg",
    "CheckSquareFilled.svg",
    "ChevronDown.svg",
    "ChevronLeft.svg",
    "ChevronRight.svg",
    "ChevronUp.svg",
    "Cleaning.svg",
    "Close.svg",
    "CloudDownload.svg",
    "CloudUpload.svg",
    "Coins.svg",
    "Community.svg",
    "Copy2.svg",
    "Dashboard.svg",
    "DeclarationOfInterest.svg",
    "DigitalLetter.svg",
    "Document2.svg",
    "DocumentContract.svg",
    "DocumentTermination.svg",
    "Download.svg",
    "Edit2.svg",
    "EmptyNest.svg",
    "Envelope2.svg",
    "Exchange.svg",
    "Express2.svg",
    "Filter2.svg",
    "Fire2.svg",
    "Flag.svg",
    "GlobeOutlined2.svg",
    "GoogleTranslate.svg",
    "Graph2.svg",
    "HeartLarge.svg",
    "Hide.svg",
    "House2.svg",
    "HouseLoan.svg",
    "HouseUser2.svg",
    "HouseUsers2.svg",
    "Hyperlink2.svg",
    "ImageError.svg",
    "Info.svg",
    "Invoice.svg",
    "It services.svg",
    "KeyOutlined2.svg",
    "LaundryMachine2.svg",
    "LibraryBook2.svg",
    "LikeFilled.svg",
    "LikeOutlined.svg",
    "Loading.svg",
    "Lock2.svg",
    "Login 2.svg",
    "LogOut.svg",
    "MapPin2.svg",
    "MapTack2.svg",
    "Mastercard.svg",
    "MenuHamburgerClosed.svg",
    "MenuMeatballs.svg",
    "MessageBubbleSquareOutlined2.svg",
    "NoticeBoard.svg",
    "Offer.svg",
    "OpenInWeb.svg",
    "Parking2.svg",
    "Pay.svg",
    "PDF2.svg",
    "Pencil2.svg",
    "Phone2.svg",
    "PictureMultiple2.svg",
    "PictureSingle2.svg",
    "Plant2.svg",
    "PlantMonstera2.svg",
    "QuestionCircle.svg",
    "QuestionCircleOutlined2.svg",
    "Relocation.svg",
    "RemoveCircle2.svg",
    "RemoveCircleOutlined2.svg",
    "ResizeExpandSize.svg",
    "Router.svg",
    "SavingPiggyBank2.svg",
    "Search.svg",
    "SecondhandContract.svg",
    "Send2.svg",
    "Services.svg",
    "Settings2.svg",
    "Share.svg",
    "ShieldHeart.svg",
    "ShieldHome2.svg",
    "ShieldHomeFilled.svg",
    "ShoppingBasket.svg",
    "ShoppingCart.svg",
    "Show.svg",
    "Sickness2.svg",
    "Smartphone.svg",
    "Springboard.svg",
    "Stairway2.svg",
    "StarFilled2.svg",
    "StarOutlined2.svg",
    "StarRibbon.svg",
    "StatusIndicator.svg",
    "Stop.svg",
    "Stopwatch2.svg",
    "Storage.svg",
    "SubtractCircle2.svg",
    "Success.svg",
    "Support2.svg",
    "Survey2.svg",
    "Tasklist2.svg",
    "Temperature.svg",
    "TimeClock2.svg",
    "Tool.svg",
    "TrashBin2.svg",
    "Tree2.svg",
    "Unemployment2.svg",
    "UnlikeFilled.svg",
    "UnLikeOutlined.svg",
    "User2.svg",
    "UsersFriends.svg",
    "View2.svg",
    "ViewHidden2.svg",
    "WalletOutlined2.svg",
    "Wifi2.svg",
    "Wrench2.svg",
    "ZoomIn.svg",
    "ZoomOut.svg",
]
export const imageList = [
    "illu-blueprint-black.png",
    "illu-blueprint-blue.png",
    "illu-blueprint-green saturated.png",
    "illu-blueprint-green.png",
    "illu-blueprint-orange.png",
    "illu-blueprint-purple.png",
    "illu-blueprint-red.png",
    "illu-blueprint-turquoise.png",
    "illu-blueprint-yellow.png",
    "illu-cases-black.png",
    "illu-cases-blue.png",
    "illu-cases-green saturated.png",
    "illu-cases-green.png",
    "illu-cases-orange.png",
    "illu-cases-purple.png",
    "illu-cases-red.png",
    "illu-cases-turquoise.png",
    "illu-cases-yellow.png",
    "illu-community-black.png",
    "illu-community-blue.png",
    "illu-community-green saturated.png",
    "illu-community-green.png",
    "illu-community-orange.png",
    "illu-community-purple.png",
    "illu-community-red.png",
    "illu-community-turquoise.png",
    "illu-community-yellow.png",
    "illu-consumption-black.png",
    "illu-consumption-blue.png",
    "illu-consumption-green saturated.png",
    "illu-consumption-green.png",
    "illu-consumption-orange.png",
    "illu-consumption-purple.png",
    "illu-consumption-red.png",
    "illu-consumption-turquoise.png",
    "illu-consumption-yellow.png",
    "illu-invoices-black.png",
    "illu-invoices-blue.png",
    "illu-invoices-green saturated.png",
    "illu-invoices-green.png",
    "illu-invoices-orange.png",
    "illu-invoices-purple.png",
    "illu-invoices-red.png",
    "illu-invoices-turquoise.png",
    "illu-invoices-yellow.png",
    "illu-laundry booking-black.png",
    "illu-laundry booking-blue.png",
    "illu-laundry booking-green saturated.png",
    "illu-laundry booking-green.png",
    "illu-laundry booking-orange.png",
    "illu-laundry booking-purple.png",
    "illu-laundry booking-red.png",
    "illu-laundry booking-turquoise.png",
    "illu-laundry booking-yellow.png",
    "illu-library-black.png",
    "illu-library-blue.png",
    "illu-library-green saturated.png",
    "illu-library-green.png",
    "illu-library-orange.png",
    "illu-library-purple.png",
    "illu-library-red.png",
    "illu-library-turquoise.png",
    "illu-library-yellow.png",
    "illu-moving boxes-black.png",
    "illu-moving boxes-blue.png",
    "illu-moving boxes-green saturated.png",
    "illu-moving boxes-green.png",
    "illu-moving boxes-orange.png",
    "illu-moving boxes-purple.png",
    "illu-moving boxes-red.png",
    "illu-moving boxes-turquoise.png",
    "illu-moving boxes-yellow.png",
    "illu-myHome-black.png",
    "illu-myHome-blue.png",
    "illu-myHome-green saturated.png",
    "illu-myHome-green.png",
    "illu-myHome-orange.png",
    "illu-myHome-purple.png",
    "illu-myHome-red.png",
    "illu-myHome-turquoise.png",
    "illu-myHome-yellow.png",
    "illu-notice board-black.png",
    "illu-notice board-blue.png",
    "illu-notice board-green saturated.png",
    "illu-notice board-green.png",
    "illu-notice board-orange.png",
    "illu-notice board-purple.png",
    "illu-notice board-red.png",
    "illu-notice board-turquoise.png",
    "illu-notice board-yellow.png",
    "illu-parking-black.png",
    "illu-parking-blue.png",
    "illu-parking-green saturated.png",
    "illu-parking-green.png",
    "illu-parking-orange.png",
    "illu-parking-purple.png",
    "illu-parking-red.png",
    "illu-parking-turquoise.png",
    "illu-parking-yellow.png",
    "illu-search apartment-black.png",
    "illu-search apartment-blue.png",
    "illu-search apartment-green saturated.png",
    "illu-search apartment-green.png",
    "illu-search apartment-orange.png",
    "illu-search apartment-purple.png",
    "illu-search apartment-red.png",
    "illu-search apartment-turquoise.png",
    "illu-search apartment-yellow.png",
]
