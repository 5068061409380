import { t } from "@lingui/macro"
import { TextField } from "@mui/material"
import { observer } from "mobx-react"

import { FeatureConfigurationDetailStore } from "src/modals/feature-configuration-detail/store"
import { useStore } from "src/store/lib/useStore"

export const SMSTemplateSection = observer(() => {
    const store = useStore(FeatureConfigurationDetailStore)

    return (
        <>
            <TextField
                label={t`feature-configuration-detail-modal.sms-template-section.tenant-movein-welcome-label`}
                value={store.form.get("SMSTemplateTenantMoveIn")}
                onChange={store.form.setter("SMSTemplateTenantMoveIn")}
            />
            <TextField
                label={t`feature-configuration-detail-modal.sms-template-section.tenant-rollout-reminder-label`}
                value={store.form.get(
                    "SMSTemplateTenantRolloutRegistrationReminder",
                )}
                onChange={store.form.setter(
                    "SMSTemplateTenantRolloutRegistrationReminder",
                )}
            />
            <TextField
                label={t`feature-configuration-detail-modal.sms-template-section.tenant-rollout-reminder-2-label`}
                value={store.form.get(
                    "SMSTemplateTenantRolloutRegistrationReminder2",
                )}
                onChange={store.form.setter(
                    "SMSTemplateTenantRolloutRegistrationReminder2",
                )}
            />{" "}
            <TextField
                label={t`feature-configuration-detail-modal.sms-template-section.tenant-movein-welcome-final-notification-label`}
                value={store.form.get("SMSTemplateTenantFinalReminder")}
                onChange={store.form.setter("SMSTemplateTenantFinalReminder")}
            />
        </>
    )
})
