import { t } from "@lingui/macro"
import { Stack } from "@mui/material"
import { observer } from "mobx-react"

import { FormPanel } from "src/components/FormPanel"
import { ModalTextField } from "src/components/ModalTextField"
import { ChatbotDispatchesStore } from "src/modals/chatbot-dispatches/store"
import { useStore } from "src/store/lib/useStore"

export const ChatbotDispatchesContentSection = observer(() => {
    const store = useStore(ChatbotDispatchesStore)

    return (
        <FormPanel
            sections={[
                {
                    header: t`chatbot-dispatches-modal.section.content`,
                    content: (
                        <Stack spacing={2}>
                            <ModalTextField
                                variant="withToolTip"
                                label={t`chatbot-dispatches-modal.form-fields.ai-name`}
                                tooltipText={t`chatbot-dispatches-modal.form-fields.ai-name-tooltip`}
                                value={store.formFields.get("ai_name")}
                                onChange={store.formFields.setter("ai_name")}
                                helperText={store.formFields.error("ai_name")}
                                error={Boolean(
                                    store.formFields.error("ai_name"),
                                )}
                                disabled={
                                    store.formFields.get("access_type") ===
                                    "READ"
                                }
                                rows={1}
                            />
                            <ModalTextField
                                variant="withToolTip"
                                label={t`chatbot-dispatches-modal.form-fields.ai-instructions`}
                                tooltipText={t`chatbot-dispatches-modal.form-fields.ai-instructions-tooltip`}
                                value={store.formFields.get("description")}
                                onChange={store.formFields.setter(
                                    "description",
                                )}
                                helperText={store.formFields.error(
                                    "description",
                                )}
                                error={Boolean(
                                    store.formFields.error("description"),
                                )}
                                disabled={
                                    store.formFields.get("access_type") ===
                                    "READ"
                                }
                                rows={4}
                            />
                            <ModalTextField
                                variant="withToolTip"
                                label={t`chatbot-dispatches-modal.form-fields.dispatch-message`}
                                tooltipText={t`chatbot-dispatches-modal.form-fields.dispatch-message-tooltip`}
                                value={store.formFields.get("message")}
                                onChange={store.formFields.setter("message")}
                                helperText={store.formFields.error("message")}
                                error={Boolean(
                                    store.formFields.error("message"),
                                )}
                                disabled={
                                    store.formFields.get("access_type") ===
                                    "READ"
                                }
                                rows={4}
                            />
                            <ModalTextField
                                variant="withToolTip"
                                label={t`chatbot-dispatches-modal.form-fields.button-label`}
                                tooltipText={t`chatbot-dispatches-modal.form-fields.button-label-tooltip`}
                                value={store.formFields.get("button_text")}
                                onChange={store.formFields.setter(
                                    "button_text",
                                )}
                                helperText={store.formFields.error(
                                    "button_text",
                                )}
                                error={Boolean(
                                    store.formFields.error("button_text"),
                                )}
                                disabled={
                                    store.formFields.get("access_type") ===
                                    "READ"
                                }
                                rows={4}
                            />
                            <ModalTextField
                                variant="withToolTip"
                                label={t`chatbot-dispatches-modal.form-fields.link`}
                                tooltipText={t`chatbot-dispatches-modal.form-fields.link-tooltip`}
                                value={store.formFields.get("dispatch_url")}
                                onChange={store.formFields.setter(
                                    "dispatch_url",
                                )}
                                helperText={store.formFields.error(
                                    "dispatch_url",
                                )}
                                error={Boolean(
                                    store.formFields.error("dispatch_url"),
                                )}
                                disabled={
                                    store.formFields.get("access_type") ===
                                    "READ"
                                }
                                rows={4}
                            />
                        </Stack>
                    ),
                },
            ]}
        />
    )
})
