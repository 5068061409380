/* eslint-disable import/no-relative-parent-imports */
import { useMemo } from "react"
import {
    Autocomplete,
    FormControl,
    TextField,
    FormHelperText,
    useTheme,
} from "@mui/material"
import { observer } from "mobx-react"
import { t } from "@lingui/macro"

import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { ISelectedAccessGroup } from "src/types/local-storage"

interface IProps {
    disabled?: boolean
    accessGroupId?: number
    errorMessage?: string
}

export const AccessGroup = observer((props: IProps) => {
    const gstore = useStore(GlobalStore)
    const isError = Boolean(props.errorMessage)
    const theme = useTheme()

    const accessGroupLabels = useMemo(() => {
        return gstore.session.accessGroups.reduce(
            (acc: { [k: number]: string }, ag: ISelectedAccessGroup) => {
                return (acc = { ...acc, [ag.id]: ag.name })
            },
            {},
        )
    }, [gstore.session.accessGroups])

    const agPickerChange = async (accessGroupId: number | undefined) => {
        const accessGroup = gstore.session.accessGroups.find(
            (ag) => ag.id === accessGroupId,
        )

        if (accessGroup != null) {
            await gstore.session.setSelectedAccessGroup(accessGroup)
        } else {
            throw new Error("accessGroup could not be selected.")
        }
    }
    const value = () =>
        props.accessGroupId != null && props.accessGroupId !== -1
            ? props.accessGroupId
            : gstore.session.accessGroup.id != null
            ? gstore.session.accessGroup.id
            : DEFAULT_ACCESS_GROUP.id
    return (
        <FormControl style={{ breakInside: "avoid" }}>
            <Autocomplete
                data-testid="accessGroup-dropdown"
                id="accessGroupId"
                options={gstore.session.accessGroups.map((x) => x.id)}
                onChange={(_, value) => agPickerChange(value as number)}
                value={value()}
                renderInput={(props) => (
                    <TextField
                        {...props}
                        label={t`head.access-group-label`}
                        error={isError}
                    />
                )}
                getOptionLabel={(option) =>
                    accessGroupLabels[Number(option)] ?? ""
                }
                renderOption={(props, option) => (
                    <li {...props} key={Number(option)}>
                        {accessGroupLabels[Number(option)]}
                    </li>
                )}
                title={t`head.access-group-label`}
                placeholder={t`head.access-group-label`}
                autoHighlight
                blurOnSelect
                disableClearable
                disabled={props.disabled}
                style={{
                    backgroundColor:
                        props.disabled === true
                            ? "transparent"
                            : theme.palette.common.white,
                }}
            />
            {Boolean(props.accessGroupId) && (
                <FormHelperText error={true}>
                    {props.errorMessage}
                </FormHelperText>
            )}
        </FormControl>
    )
})
