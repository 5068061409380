import { memo } from "react"

import { Box } from "@mui/material"

import { ModalTextField } from "src/components/ModalTextField"
import { IChatbotConfigurationItemProps } from "src/modals/chatbot-configuration/types/configAgentsItemProps"

type ConfigAgentInputKey = "generic" | keyof IChatbotConfigurationItemProps

export interface IRenderTextInputProps {
    label: string
    id: ConfigAgentInputKey
    rowLength: number
    disabled?: boolean
    maxLength?: number
    handleTextInputChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        key: string,
    ) => void
    value?: string
    helperText?: string
    variant?: "withToolTip" | "default"
    error?: boolean
    tooltipText?: string
}

export const ChatbotConfigurationTextInput = memo(
    ({
        label,
        id,
        rowLength,
        disabled,
        maxLength,
        handleTextInputChange,
        value,
        helperText,
        variant = "withToolTip",
        error,
        tooltipText,
    }: IRenderTextInputProps) => {
        return (
            <Box style={{ paddingBottom: "16px" }}>
                <ModalTextField
                    variant={variant}
                    tooltipText={tooltipText ?? ""}
                    label={label ?? ""}
                    value={value ?? ""}
                    onChange={(event) => handleTextInputChange(event, id)}
                    helperText={helperText}
                    error={error ?? false}
                    maxLength={maxLength ?? 255}
                    rows={rowLength}
                    disabled={disabled}
                />
            </Box>
        )
    },
)
