import { t } from "@lingui/macro"

import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { Box, DialogContent, IconButton, FormHelperText } from "@mui/material"
import { Add as AddIcon } from "@mui/icons-material"
import CloseIcon from "@mui/icons-material/Close"

import { iconList, imageList } from "./assets"
import { IconLibrary, ImageLibrary } from "./tabs"

import { StoreProvider } from "src/store/lib/StoreProvider"
import ImageSelectionSection from "src/components/AssetAttachmentsField"

import {
    AssetPickerTab,
    AssetPickerTabs,
    AssetPickerTitle,
    AssetPickerDialog,
    OrText,
    AddButton,
} from "src/components/AssetLibraryPicker/styled"
import { useStore } from "src/store/lib/useStore"
import { ImagePickerStore } from "src/components/AssetLibraryPicker/store"
import { CenteredSpinner } from "src/components/CenteredSpinner"
import { IAssetLibraryViewProps } from "src/components/AssetLibraryPicker/types"

const AssetLibraryView = observer(
    ({
        onIconDelete,
        onIconChange,
        errorMessage,
        value,
        setErrorMessage,
        acceptedFormats,
        aspectRatio,
        disabled,
        activeAssetType,
    }: IAssetLibraryViewProps) => {
        const imagePickerStore = useStore(ImagePickerStore)

        const handleTabChange = (_event: React.SyntheticEvent, tabId: number) =>
            imagePickerStore.setActiveTab(tabId)

        useEffect(() => {
            const loadAssets = async () => {
                imagePickerStore.setLoading(true)
                try {
                    const loadedIcons = await Promise.all(
                        iconList.map(async (iconName) => {
                            const module = await import(
                                `./assets/icons/${iconName}`
                            )
                            return { name: iconName, path: module.default }
                        }),
                    )
                    imagePickerStore.setIcons(loadedIcons)

                    const loadedImages = await Promise.all(
                        imageList.map(async (imageName) => {
                            const module = await import(
                                `./assets/images/${imageName}`
                            )
                            return { name: imageName, path: module.default }
                        }),
                    )
                    imagePickerStore.setImages(loadedImages)
                } catch (error) {
                } finally {
                    imagePickerStore.setLoading(false)
                }
            }

            if (imagePickerStore.openModal) {
                ;(async () => await loadAssets())()
            }
        }, [imagePickerStore, imagePickerStore.openModal])

        useEffect(() => {
            if (activeAssetType !== undefined && activeAssetType?.length > 0) {
                imagePickerStore.setActiveTab(
                    activeAssetType === "icons" ? 0 : 1,
                )
            }
        }, [imagePickerStore, activeAssetType])

        return (
            <>
                <Box display="flex" alignItems="center">
                    <ImageSelectionSection
                        imageUrl={value}
                        onImageDelete={() => onIconDelete()}
                        onImageChange={(images) => onIconChange(images)}
                        errorMessage={""}
                        setErrorMessage={(error) => setErrorMessage(error)}
                        acceptedFormats={acceptedFormats}
                        aspectRatio={aspectRatio ?? "1/1"}
                        disabled={disabled ?? false}
                    />
                    <OrText variant="body2">{t`asset-library-picker.text.or`}</OrText>
                    <AddButton
                        variant="outlined"
                        color="info"
                        startIcon={<AddIcon />}
                        onClick={() => imagePickerStore.setOpenModal(true)}
                        disabled={disabled ?? false}
                    >
                        {t`asset-library-picker.add-from-library`}
                    </AddButton>
                </Box>
                {Boolean(errorMessage) && (
                    <FormHelperText error={true}>{errorMessage}</FormHelperText>
                )}
                <AssetPickerDialog
                    open={imagePickerStore.openModal}
                    scroll="paper"
                >
                    <AssetPickerTitle>
                        {t`asset-library-picker.select-asset`}
                        <IconButton
                            size="small"
                            onClick={() => imagePickerStore.setOpenModal(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </AssetPickerTitle>
                    <AssetPickerTabs
                        value={imagePickerStore.activeTab}
                        onChange={handleTabChange}
                    >
                        <AssetPickerTab
                            label={t`asset-library-picker.tab.icons`}
                            disabled={
                                (activeAssetType !== undefined &&
                                    activeAssetType !== "icons") ??
                                false
                            }
                        />
                        <AssetPickerTab
                            label={t`asset-library-picker.tab.images`}
                            disabled={
                                (activeAssetType !== undefined &&
                                    activeAssetType !== "images") ??
                                false
                            }
                        />
                    </AssetPickerTabs>
                    <DialogContent dividers>
                        {imagePickerStore.loading ? (
                            <CenteredSpinner height="75vh" />
                        ) : null}
                        {imagePickerStore.activeTab === 0 && (
                            <IconLibrary
                                icons={imagePickerStore.icons}
                                handleIconSelect={(icon) =>
                                    imagePickerStore.handleIconSelect(
                                        icon,
                                        onIconChange,
                                    )
                                }
                            />
                        )}
                        {imagePickerStore.activeTab === 1 && (
                            <ImageLibrary
                                icons={imagePickerStore.images}
                                handleIconSelect={(icon) =>
                                    imagePickerStore.handleIconSelect(
                                        icon,
                                        onIconChange,
                                    )
                                }
                            />
                        )}
                    </DialogContent>
                </AssetPickerDialog>
            </>
        )
    },
)

export const AssetLibrary = (props: IAssetLibraryViewProps) => (
    <StoreProvider Store={ImagePickerStore}>
        <AssetLibraryView {...props} />
    </StoreProvider>
)

export default AssetLibrary
