/**
 * Determines the MIME type of a file based on its name and content.
 *
 * @param {string} fileName - The name of the file.
 * @param {ArrayBuffer} content - The content of the file.
 */
export const getFileType = (fileName: string, content: ArrayBuffer): string => {
    // Extract the file extension from the file name
    const extension = fileName?.split(".").pop()?.toLowerCase()

    // Create a Uint8Array view of the file content
    const uint8Array = new Uint8Array(content)

    // Check if the file is a PNG using the PNG file signature
    if (
        uint8Array.length > 4 &&
        uint8Array[0] === 0x89 &&
        uint8Array[1] === 0x50 &&
        uint8Array[2] === 0x4e &&
        uint8Array[3] === 0x47
    ) {
        return "image/png"
    }

    // Check if the file is a JPEG using the JPEG file signature
    if (
        uint8Array.length > 2 &&
        uint8Array[0] === 0xff &&
        uint8Array[1] === 0xd8
    ) {
        return "image/jpeg"
    }

    // Check if the file is an SVG using the SVG file signature
    if (
        content.byteLength > 3 &&
        new TextDecoder().decode(content.slice(0, 4)) === "<svg"
    ) {
        return "image/svg+xml"
    }

    // Fallback to extension-based detection
    switch (extension) {
        case "png":
            return "image/png"
        case "jpg":
        case "jpeg":
            return "image/jpeg"
        case "svg":
            return "image/svg+xml"
        default:
            return "application/octet-stream"
    }
}
