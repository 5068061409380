import { observer } from "mobx-react"

import { t } from "@lingui/macro"

import {
    Autocomplete,
    Checkbox,
    Stack,
    TextField,
    useTheme,
} from "@mui/material"

import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons"

import { useCallback } from "react"

import { useStore } from "src/store/lib/useStore"
import { CommunityInsightsStore } from "src/views/community-insights/store"
import { ICommunity } from "src/views/community-insights/types"
import { popperStyled } from "src/views/community-insights/components/styles"

const icon = <CheckBoxOutlineBlank fontSize="small" />
const checkedIcon = <CheckBox fontSize="small" />

export const MultiselectAutocomplete = observer(() => {
    const store = useStore(CommunityInsightsStore)
    const theme = useTheme()

    const handleSearchChange = useCallback(
        (option: ICommunity[]) => {
            const selectedCommunities = option.map((option) => option.id)
            store.setSelectedCommunities(selectedCommunities)
        },
        [store],
    )

    const renderOption = useCallback((props, option, { selected }) => {
        const { ...optionProps } = props
        return (
            <li key={option.id} {...optionProps}>
                <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                />
                {option.internal_name} ({option.id})
            </li>
        )
    }, [])

    const renderInput = useCallback(
        (params) => (
            <TextField
                {...params}
                maxRows={1}
                label={t`community-insights.search-field-placeholder`}
                placeholder={t`community-insights.search-field-placeholder`}
                sx={{
                    backgroundColor: theme.palette.common.white,
                }}
            />
        ),
        [theme.palette.common.white],
    )

    return (
        <Stack maxHeight={56} style={{ position: "relative" }}>
            <Autocomplete
                multiple
                id="community-insights-checkboxes-autocomplete"
                limitTags={2}
                options={store.allCommunities}
                disableCloseOnSelect
                getOptionLabel={(option) => option.internal_name}
                onChange={(_event, value) => handleSearchChange(value)}
                renderOption={renderOption}
                style={{ width: 500, zIndex: 1 }}
                renderInput={renderInput}
                PopperComponent={popperStyled}
            />
        </Stack>
    )
})
