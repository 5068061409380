import { makeAutoObservable } from "mobx"
import React from "react"

import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"
import { admin_Category, ProductCatalogueAdminService } from "src/api"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { ICategoryData } from "src/views/product-categories"
import { Channel } from "src/channel"

export class ProductCategoriesStore {
    static Context = React.createContext<ProductCategoriesStore | null>(null)
    static LoadingKeys = createLoadingKeys("pageLoad")

    private repositoryUpdatesListenerDisposer?: () => void

    private _categories: ICategoryData[] = []
    setCategories(categories: ICategoryData[]) {
        this._categories = categories
    }
    categories = new Pagination((params) => {
        const pageStart = (params.page - 1) * params.pageSize
        const pageCategories = this._categories.slice(
            pageStart,
            pageStart + params.pageSize,
        )
        return {
            items: pageCategories,
            count: this._categories.length,
            sourceItems: this._categories,
        }
    })

    private _segments: number[] = []
    private _accessGroupId: number = DEFAULT_ACCESS_GROUP.id

    get segments() {
        return this._segments
    }

    constructor() {
        makeAutoObservable(this)
    }

    private async loadCategories() {
        const decoratedCategory = (
            category: admin_Category,
            numberOfProducts?: number,
        ): ICategoryData => ({
            id: category.category_id ?? "",
            categoryId: category.category_id ?? -1,
            name: category.name ?? "",
            imageUrl: category.image_url ?? "",
            numberOfProducts: numberOfProducts ?? 0,
            rank: category.rank,
        })

        const getCategory = (category: admin_Category) => {
            const categoryId = category.category_id ?? 0
            return ProductCatalogueAdminService.getV1AdminCatalogueCategory1({
                categoryId,
            })
        }

        const decorateCategory = async (category: admin_Category) => {
            const catalogueCategory = await getCategory(category)
            const numberOfProducts = catalogueCategory.products?.length ?? 0
            return decoratedCategory(category, numberOfProducts)
        }

        const response =
            await ProductCatalogueAdminService.getV1AdminCatalogueCategory()

        this.setCategories(
            await Promise.all(response.categories?.map(decorateCategory) ?? []),
        )
        await this.categories.loadInitialPage()
    }

    async loadSegments(segments: number[]) {
        this.setSegments(segments)
        await this.loadCategories()
    }

    async init(accessGroupId: number, segments: number[]) {
        this.listenToCategoriesRepositoryUpdated()
        this.setAccountGroupId(accessGroupId)
        await this.loadSegments(segments)
    }

    private setAccountGroupId(accessGroupId: number) {
        this._accessGroupId = accessGroupId
    }

    private setSegments(segments: number[]) {
        this._segments = segments
    }

    private listenToCategoriesRepositoryUpdated() {
        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "product-categories"
                ) {
                    await this.loadCategories()
                }
            },
        )
    }
}
