import { IIconProps } from "src/components/icons"

export const CheckCircle16 = (props: IIconProps) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.3335 8.00004C1.3335 4.31814 4.31826 1.33337 8.00016 1.33337C11.6803 1.33766 14.6625 4.31992 14.6668 8.00004C14.6668 11.6819 11.6821 14.6667 8.00016 14.6667C4.31826 14.6667 1.3335 11.6819 1.3335 8.00004ZM7.12615 11.0003L11.3642 6.19078C11.5062 6.04347 11.5557 5.83008 11.4931 5.63533C11.4305 5.44058 11.2659 5.29597 11.0648 5.25894C10.8636 5.2219 10.6583 5.29841 10.5305 5.45811L6.67499 9.83373L5.18309 8.55383C4.96108 8.34191 4.60932 8.35009 4.39739 8.57209C4.18547 8.7941 4.19365 9.14586 4.41565 9.35778L6.32562 11.0351C6.4358 11.1379 6.58265 11.1921 6.73318 11.1855C6.88372 11.179 7.02531 11.1122 7.12615 11.0003Z"
            fill="currentColor"
        />
    </svg>
)
