import { t } from "@lingui/macro"
import { Alert, Button, Chip, Stack } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useEffect } from "react"

import { ContactFormTypeDetailStore } from "./store"

import { ToggleChatAccessSection } from "./ToggleChatAccessSection"

import { Form } from "src/components/Form"
import { FormFieldSet } from "src/components/FormFieldSet"
import { FormPanel } from "src/components/FormPanel"
import { ModalHeader } from "src/components/ModalHeader"
import { SegmentPickerField } from "src/components/SegmentPickerField"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { useCloseConfirmationForForm } from "src/store/modals/use-close-confirmation-for-form"

interface IProps {
    id: number
    publishedIn: number[]
    fields: string[]
    messagingEnabled: boolean
}

const View = observer((props: IProps) => {
    const store = useStore(ContactFormTypeDetailStore)
    const gstore = useStore(GlobalStore)

    useEffect(
        () =>
            store.init(
                props.id,
                props.publishedIn,
                props.fields,
                props.messagingEnabled,
            ),
        [store, props],
    )

    useCloseConfirmationForForm(store.form)

    const handleSegmentPickerChange = useCallback(
        (segmentIds: number[]) => {
            store.form.set("segmentIds", segmentIds)
        },
        [store.form],
    )

    const handleSubmit = useCallback(async () => {
        await store.submit()
        if (!store.form.hasErrors()) {
            gstore.modals.pop()
        }
    }, [store, gstore.modals])

    return (
        <Form onSubmit={handleSubmit}>
            <ModalHeader>
                <Button
                    type="submit"
                    variant="contained"
                >{t`contact-form-type-detail-modal.publish-button`}</Button>
            </ModalHeader>
            {Boolean(store.form.error("generic")) && (
                <Alert color="error">{store.form.error("generic")}</Alert>
            )}
            <FormPanel
                sections={[
                    {
                        header: t`contact-form-type-detail-modal.publish-settings-section.header`,
                        content: (
                            <FormFieldSet
                                header={t`contact-form-type-detail-modal.publish-settings-section.segment-picker-field-header`}
                            >
                                <SegmentPickerField
                                    value={store.form.get("segmentIds")}
                                    onChange={handleSegmentPickerChange}
                                    error={Boolean(
                                        store.form.error("segmentIds"),
                                    )}
                                    accessGroupId={gstore.session.accessGroupId}
                                    helperText={store.form.error("segmentIds")}
                                />
                            </FormFieldSet>
                        ),
                    },
                ]}
            />
            <FormPanel
                sections={[
                    {
                        header: t`contact-form-type-detail-modal.fields-section.header`,
                        content: (
                            <Stack spacing={0.5} alignItems="flex-start">
                                {store.fields.map((field) => (
                                    <Chip key={field} label={field} />
                                ))}
                            </Stack>
                        ),
                    },
                ]}
            />
            <FormPanel
                sections={[
                    {
                        header: t`contact-form-type-detail-modal.enable-direct-message.header-title`,
                        content: (
                            <FormFieldSet
                                header={t`contact-form-type-detail-modal.enable-direct-message.description`}
                            >
                                <ToggleChatAccessSection />
                            </FormFieldSet>
                        ),
                    },
                ]}
            />
        </Form>
    )
})

export const ContactFormTypeDetailModal = observer((props: IProps) => (
    <StoreProvider Store={ContactFormTypeDetailStore}>
        <View {...props} />
    </StoreProvider>
))
