import { t } from "@lingui/macro"
import { useMemo, SyntheticEvent } from "react"
import { Button } from "@mui/material"
import { observer } from "mobx-react"

import { CasesDetails } from "./tabs/Details"
import { CasesMessages } from "./tabs/Messages"

import { ICasesModalViewProps } from "./types/caseDetailModalView"

import { ModalHeader } from "src/components/ModalHeader"
import { CaseDetailStore, ITabId } from "src/modals/cases-v2-detail/store"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { Tabs } from "src/components/Tabs"
import { Container } from "src/modals/cases-v2-detail/styled"

import { useUniqueId } from "src/lib/unique-id"

const View = observer(({ id }: ICasesModalViewProps) => {
    const globalStore = useStore(GlobalStore)
    const caseDetailStore = useStore(CaseDetailStore)

    const formId = useUniqueId()

    const isSaveButtonDisabled = globalStore.loading.is(
        CaseDetailStore.LoadingKeys.submit,
    )

    const tabsData = useMemo(
        () => [
            {
                label: t`cases-detail-modal.detail-tab-label`,
                id: ITabId.DETAIL,
                view: <CasesDetails formId={formId} id={id} />,
            },
            {
                label: t`contact-form-detail-modal.chat-tab-label`,
                id: ITabId.CHAT,
                view: (
                    <CasesMessages
                        conversationId={caseDetailStore.conversationId ?? ""}
                        id={id}
                    />
                ),
            },
        ],
        [formId, id, caseDetailStore.conversationId],
    )

    const handleTabChange = (_event: SyntheticEvent, tabId: string) =>
        caseDetailStore.setSelectedTabId(tabId)

    return (
        <Container>
            <ModalHeader>
                <Button
                    type="submit"
                    variant="contained"
                    form={formId}
                    disabled={
                        isSaveButtonDisabled ||
                        !caseDetailStore.form.getIsDirty()
                    }
                >
                    {t`cases-detail-modal.save-button`}
                </Button>
            </ModalHeader>

            {globalStore.features.enabled("ShowCasesDetailTabs") &&
            caseDetailStore.messagingEnabled ? (
                <Tabs
                    data={tabsData}
                    selectedTabId={caseDetailStore.selectedTabId}
                    handleTabChange={handleTabChange}
                    tabListStyles={{ padding: 0 }}
                    tabContentStyles={{ flex: 1 }}
                />
            ) : (
                <CasesDetails formId={formId} id={id} />
            )}
        </Container>
    )
})

export const CaseDetailModalView = (props: ICasesModalViewProps) => (
    <StoreProvider Store={CaseDetailStore}>
        <View {...props} />
    </StoreProvider>
)
