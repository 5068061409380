import { observer } from "mobx-react"
import { Button } from "@mui/material"
import { t } from "@lingui/macro"
import React, { useCallback } from "react"

import { AccessGroupsCreateOrEditStore } from "./store"

import { FormFieldSet } from "src/components/FormFieldSet"
import { useStore } from "src/store/lib/useStore"
import { ConfirmDeleteModal } from "src/modals/confirm-delete"
import { GlobalStore } from "src/store"
import { reportError } from "src/lib/report"

interface IProps {
    id: number
}

export const DeleteSection = observer(({ id }: IProps) => {
    const store = useStore(AccessGroupsCreateOrEditStore)
    const globalStore = useStore(GlobalStore)

    const deleteAccessGroup = useCallback(async () => {
        try {
            await store.deleteAccessGroup(id)
        } catch (e) {
            reportError(
                t`create-edit-access-group-modal.delete-section.error-message`,
                e,
            )
        } finally {
            globalStore.modals.pop()
            globalStore.modals.pop()
        }
    }, [id, store, globalStore.modals])

    const handleDeleteAccessGroupClick = useCallback(() => {
        globalStore.modals.open(
            () => (
                <ConfirmDeleteModal
                    variant="single"
                    deleteItem={deleteAccessGroup}
                    itemId={id}
                    title={t`access-groups-view.confirm-delete-modal.title`}
                    content={t`access-groups-view.confirm-delete-modal.content`}
                />
            ),
            { variant: "slide-up-w600" },
        )
    }, [id, deleteAccessGroup, globalStore.modals])

    return (
        <FormFieldSet header="">
            <Button
                onClick={handleDeleteAccessGroupClick}
                color={"error"}
                data-testid={"delete-access-group-button"}
            >
                {t`create-edit-access-group-modal.delete-section.button-text`}
            </Button>
        </FormFieldSet>
    )
})
