// create switch with use of material ui library with custom styles and memorized content
import React, { memo } from "react"
import { Switch, FormControlLabel } from "@mui/material"

import { ISwitchInputProps } from "src/components/Inputs/types/ISwitchInputProps"

export const SwitchInput = memo(
    ({ label, checked, onSwitchChange, ...rest }: ISwitchInputProps) => {
        return (
            <FormControlLabel
                control={
                    <Switch
                        checked={checked ?? false}
                        onChange={({ target: { checked } }) =>
                            onSwitchChange(checked)
                        }
                        {...rest}
                    />
                }
                label={label ?? ""}
            />
        )
    },
)
