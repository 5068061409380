import { FormHelperText } from "@mui/material"
import { observer } from "mobx-react"

import { LibraryInformationItemStore } from "src/modals/library-information-items/store"
import { useStore } from "src/store/lib/useStore"
import { WysiwygEditor } from "src/components/WysiwygEditor"

export const LibraryInformationItemsPage = observer(() => {
    const store = useStore(LibraryInformationItemStore)

    return (
        <div>
            <WysiwygEditor
                initialValue={store.formFields.get("page") ?? ""}
                onChange={(value) => store.formFields.set("page", value)}
                invalid={Boolean(store.formFields.error("page"))}
                readOnly={store.formFields.get("accessType") === "READ"}
            />
            <FormHelperText
                sx={{
                    margin: "3px 14px 0",
                }}
                error={Boolean(store.formFields.error("page"))}
            >
                {store.formFields.error("page")}
            </FormHelperText>
        </div>
    )
})
