import { observer } from "mobx-react"
import { useCallback, useEffect, useMemo } from "react"
import { t } from "@lingui/macro"
import { Button, Link, styled } from "@mui/material"

import { ProductCategoriesStore } from "./store"

import { ProductCategoriesOrderModal } from "src/modals/product-categories-order"
import { ProductCategoriesCreateOrEditModal } from "src/modals/product-categories-create-edit"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { Table } from "src/components/Table"
import { ListPage } from "src/components/ListPage"
import { IPageFilterProps } from "src/components/PageFilter"
import { SegmentPickerButton } from "src/components/SegmentPickerButton"

export interface ICategoryData {
    name: string
    imageUrl: string
    categoryId: number
    id: string | number
    numberOfProducts?: number
    rank?: string
}

const ActionButton = styled(Button)(({ theme }) => ({
    background: theme.palette.common.white,
}))

const View = observer(() => {
    const store = useStore(ProductCategoriesStore)
    const globalStore = useStore(GlobalStore)

    useEffect(() => {
        ;(async () => {
            await store.init(
                globalStore.session.accessGroupId,
                globalStore.session.segmentIds,
            )
        })()
    }, [
        store,
        globalStore.session.accessGroupId,
        globalStore.session.segmentIds,
    ])

    const handleNewCategoryClick = useCallback(() => {
        globalStore.modals.open(() => <ProductCategoriesCreateOrEditModal />)
    }, [globalStore])

    const handleSegmentChange = useCallback(
        (segments: number[]) => store.loadSegments(segments),
        [store],
    )

    const handleOpenCategoryClick = useCallback(
        (id: number) => async () => {
            const categoryData: ICategoryData | undefined =
                await store.categories.items.find(
                    (category) => id === category.categoryId,
                )
            globalStore.modals.open(() => (
                <ProductCategoriesCreateOrEditModal
                    categoryData={categoryData}
                />
            ))
        },
        [store, globalStore.modals],
    )

    const header = useMemo(
        () => ({
            header: t`product-categories-view.header`,
            breadcrumbs: [
                t`product-categories-view.header`,
                t`product-categories-view.categories-breadcrumbs`,
            ],
            createOptions: {
                item: t`notice-board-view.add-post`,
                onClick: handleNewCategoryClick,
            },
        }),
        [handleNewCategoryClick],
    )

    const filter = useMemo(
        (): IPageFilterProps => ({
            actions: (
                <>
                    <SegmentPickerButton
                        value={store.segments}
                        onChange={handleSegmentChange}
                    />

                    <ActionButton
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                            globalStore.modals.open(() => (
                                <ProductCategoriesOrderModal />
                            ))
                        }
                    >
                        {t`page-filter.sort-button`}
                    </ActionButton>
                </>
            ),
        }),
        [handleSegmentChange, store.segments, globalStore.modals],
    )

    const tableIsLoading =
        !store.categories.meta.initialized ||
        globalStore.loading.is(ProductCategoriesStore.LoadingKeys.pageLoad)

    return (
        <ListPage header={header} filter={filter} loading={tableIsLoading}>
            <Table
                paginator={store.categories}
                headers={[
                    {
                        key: "name",
                        name: t`product-categories-view.name-header`,
                    },
                    {
                        key: "numberOfProducts",
                        name: t`product-categories-view.number-of-products-header`,
                    },
                ]}
                rowRenderer={(item) => ({
                    name: (
                        <Link
                            component="button"
                            onClick={handleOpenCategoryClick(item.categoryId)}
                        >
                            {item.name}
                        </Link>
                    ),
                    numberOfProducts: item.numberOfProducts ?? 0,
                })}
                rowActionsRenderer={(item) => [
                    {
                        text: t`product-categories-view.edit-category`,
                        onClick: handleOpenCategoryClick(item.categoryId),
                    },
                ]}
            />
        </ListPage>
    )
})

export const ProductCategoriesView = () => (
    <StoreProvider Store={ProductCategoriesStore}>
        <View />
    </StoreProvider>
)
