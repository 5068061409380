import { t } from "@lingui/macro"
import { observer } from "mobx-react"

import React, { useCallback, useEffect, useMemo } from "react"

import { CampaignsStore } from "./store"

import { CampaignsStatusChip } from "./CampaignsStatusChip"

import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { Table } from "src/components/Table"
import { ListPage } from "src/components/ListPage"
import { CampaignDetailModal } from "src/modals/campaign-detail"
import { reportError } from "src/lib/report"
import { formatDateTime } from "src/lib/date"
import { SegmentPickerButton } from "src/components/SegmentPickerButton"

const View = observer(() => {
    const vstore = useStore(CampaignsStore)
    const gstore = useStore(GlobalStore)

    useEffect(() => {
        ;(async () => {
            await vstore.init(
                gstore.session.accessGroupId,
                gstore.session.segmentIds,
            )
        })()

        return () => {
            vstore.dispose()
        }
    }, [vstore, gstore.session.accessGroupId, gstore.session.segmentIds])

    const handleNewCampaignClick = useCallback(() => {
        gstore.modals.open(() => <CampaignDetailModal />)
    }, [gstore])

    const handleOpenCampaignClick = useCallback(
        (id: number) => {
            gstore.modals.open(() => <CampaignDetailModal id={id} />)
        },
        [gstore],
    )

    const createOpenCampaignClickHandler = useCallback(
        (id: number) => (event: React.MouseEvent<Element>) => {
            event.preventDefault()
            handleOpenCampaignClick(id)
        },
        [handleOpenCampaignClick],
    )

    const duplicateCampaignClickHandler = useCallback(
        (id: number) => async () => {
            try {
                await vstore.duplicateResource(id)
            } catch (e) {
                reportError(t`resource-view.failed-to-duplicate-resource`, e)
            }
        },
        [vstore],
    )

    const header = useMemo(
        () => ({
            header: t`campaign-view.header`,
            breadcrumbs: [t`campaign-view.header`],
            createOptions: {
                item: t`campaign-view.add-campaign`,
                onClick: handleNewCampaignClick,
            },
        }),
        [handleNewCampaignClick],
    )

    const handleSearchChange = useCallback(
        (query: string) => vstore.campaigns.loadSearch(query),
        [vstore],
    )

    const handleSegmentChange = useCallback(
        (segments: number[]) => vstore.loadSegments(segments),
        [vstore],
    )

    const filter = useMemo(
        () => ({
            query: vstore.campaigns.meta.search ?? "",
            onQueryChange: handleSearchChange,
            actions: (
                <>
                    <SegmentPickerButton
                        value={vstore.segments}
                        onChange={handleSegmentChange}
                    />
                </>
            ),
        }),
        [
            handleSearchChange,
            handleSegmentChange,
            vstore.campaigns.meta.search,
            vstore.segments,
        ],
    )

    const tableIsLoading =
        !vstore.campaigns.meta.initialized ||
        gstore.loading.is(CampaignsStore.LoadingKeys.pageLoad)

    return (
        <ListPage header={header} filter={filter} loading={tableIsLoading}>
            <Table
                paginator={vstore.campaigns}
                onRowClick={(item) => handleOpenCampaignClick(item.id)}
                headers={[
                    {
                        key: "name",
                        name: t`campaign-view.title-header`,
                    },
                    {
                        key: "subscription",
                        name: t`campaign-view.subscription-header`,
                    },
                    {
                        key: "createdAt",
                        name: t`campaign-view.createdAt-header`,
                    },
                    {
                        key: "visibility",
                        name: t`campaign-view.visibility-header`,
                    },
                    {
                        key: "status",
                        name: t`campaign-view.status-header`,
                    },
                    {
                        key: "publishedAt",
                        name: t`campaign-view.publishedAt-header`,
                    },
                    {
                        key: "unpublishedAt",
                        name: t`campaign-view.unpublishedAt-header`,
                    },
                ]}
                rowRenderer={(item) => ({
                    name: item.internalName,
                    subscription: item.offerType,
                    createdAt: item.createdAt?.toLocaleString(),
                    visibility: `${item.segmentsIds.length} ${
                        item.segmentsIds.length > 1 ? "Segments" : "Segment"
                    }`,
                    status:
                        item.status !== undefined ? (
                            <CampaignsStatusChip status={item.status} />
                        ) : null,
                    publishedAt:
                        item.publishedAt != null
                            ? formatDateTime(new Date(item.publishedAt))
                            : "-",
                    unpublishedAt:
                        item.unpublishedAt != null
                            ? formatDateTime(new Date(item.unpublishedAt))
                            : "-",
                })}
                rowActionsRenderer={(item) => {
                    return [
                        {
                            text: t`campaign-view.edit-campaign`,
                            onClick: createOpenCampaignClickHandler(item.id),
                        },
                        {
                            text: t`campaign-view.duplicate-campaign`,
                            onClick: duplicateCampaignClickHandler(item.id),
                        },
                    ]
                }}
            />
        </ListPage>
    )
})

export const CampaignsView = () => (
    <StoreProvider Store={CampaignsStore}>
        <View />
    </StoreProvider>
)
