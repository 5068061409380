import { IIconProps } from "src/components/icons"

export const Address24 = (props: IIconProps) => (
    <svg
        width={18}
        height={18}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 0.375C18 0.167893 17.8285 0 17.617 0H8.80852C8.597 0 8.42554 0.167893 8.42554 0.375V17.625C8.42554 17.8321 8.597 18 8.80852 18H9.95745C10.169 18 10.3404 17.8321 10.3404 17.625V9.375C10.3404 9.16789 10.5119 9 10.7234 9H13.7872C14.4218 9 14.9362 8.49632 14.9362 7.875V6C14.9362 5.79289 15.1076 5.625 15.3192 5.625H15.7021C16.3367 5.625 16.8511 5.12132 16.8511 4.5V2.577C16.8515 2.40631 16.9695 2.25742 17.1383 2.21475C17.6458 2.08646 18.0002 1.63817 18 1.125V0.375ZM13.7872 6.375C13.7872 6.58211 13.6158 6.75 13.4043 6.75H10.7234C10.5119 6.75 10.3404 6.58211 10.3404 6.375V6C10.3404 5.79289 10.5119 5.625 10.7234 5.625H13.4043C13.6158 5.625 13.7872 5.79289 13.7872 6V6.375ZM15.3192 3.375C15.5307 3.375 15.7021 3.20711 15.7021 3V2.625C15.7021 2.41789 15.5307 2.25 15.3192 2.25H10.7234C10.5119 2.25 10.3404 2.41789 10.3404 2.625V3C10.3404 3.20711 10.5119 3.375 10.7234 3.375H15.3192Z"
            fill="currentColor"
        />
        <path
            d="M2.29787 13.5003C2.29787 14.1216 2.81227 14.6253 3.44681 14.6253H7.2766C7.48811 14.6253 7.65957 14.4574 7.65957 14.2503V12.7503C7.65957 12.5432 7.48811 12.3753 7.2766 12.3753H3.82979C3.61827 12.3753 3.44681 12.2074 3.44681 12.0003V7.83481C3.447 7.72786 3.49382 7.62607 3.57549 7.55506C3.80528 7.35406 4.04272 7.16131 4.2886 6.97831C4.34662 6.93569 4.42425 6.92884 4.48913 6.9606C4.554 6.99236 4.59498 7.05729 4.59498 7.12831V10.1283C4.59667 10.7487 5.11104 11.2507 5.74468 11.2503H7.2766C7.48811 11.2503 7.65957 11.0824 7.65957 10.8753V9.37531C7.65957 9.1682 7.48811 9.00031 7.2766 9.00031H6.12766C5.91615 9.00031 5.74468 8.83241 5.74468 8.62531V6.29056C5.74468 6.14984 5.82512 6.02097 5.95302 5.95681C6.42199 5.72195 6.90668 5.51849 7.40374 5.34781C7.55712 5.29495 7.65969 5.15302 7.65957 4.99381V4.45231C7.65957 4.36352 7.61619 4.2801 7.54286 4.22788C7.46953 4.17566 7.37511 4.16095 7.28885 4.18831C4.22216 5.18849 1.64808 7.27505 0.072 10.0383C0.037997 10.1262 0.0138553 10.2175 0 10.3106V16.8753C0 17.4966 0.514396 18.0003 1.14894 18.0003H7.2766C7.48811 18.0003 7.65957 17.8324 7.65957 17.6253V16.1253C7.65957 15.9182 7.48811 15.7503 7.2766 15.7503H1.53191C1.3204 15.7503 1.14894 15.5824 1.14894 15.3753V10.5708C1.14878 10.5046 1.16677 10.4396 1.20102 10.3826C1.43008 9.99602 1.6812 9.62242 1.95319 9.26356C2.00237 9.19845 2.08869 9.17161 2.16722 9.19701C2.24575 9.22242 2.29875 9.29432 2.29864 9.37531L2.29787 13.5003Z"
            fill="currentColor"
        />
    </svg>
)
