import React from "react"

import { environment } from "src/config/variables"

export type IIconProps = {
    width?: string | number
    height?: string | number
    style?: React.CSSProperties
}

export const Icon = (props: {
    icon: React.ReactNode
    size?: number
    color?: string
}) => {
    const iconProps: IIconProps = {}

    if (props.size != null) {
        iconProps.width = props.size
        iconProps.height = props.size
    }

    let icon = React.Children.only(props.icon)

    if (React.isValidElement(icon)) {
        icon = React.cloneElement(icon, iconProps)
    } else {
        // `icon` is not a valid element, it might be a string, a fragment or
        // null. As we cannot add props to these nodes we return the same icon
        // unmodified unless the environment is "local" then we throw an error.
        if (environment === "local") {
            throw new Error(`Icon got an invalid element as icon prop.`)
        }
    }

    return (
        <span
            style={{
                color: props.color,
                width: props.size,
                height: props.size,
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            {icon}
        </span>
    )
}
