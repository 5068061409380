import { Stack } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { ChatInputControls } from "src/components/Chat/Input/Controls"
import { ChatInputTextField } from "src/components/Chat/Input/TextField"
import { AvyChatInputStore } from "src/components/Chat/Input/store"
import { ChatInputWrap } from "src/components/Chat/Input/styled"
import { IAvyChatInputProps } from "src/components/Chat/types/chatInput"
import { Form } from "src/components/Form"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"

const Component = observer((props: IAvyChatInputProps) => {
    const gstore = useStore(GlobalStore)
    const store = useStore(AvyChatInputStore)

    const isSubmitting = gstore.loading.is(
        AvyChatInputStore.LoadingKeys["submit-message"],
    )

    const onSubmit = useCallback(async () => {
        await store.submitMessage(props.handleSubmit)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.handleSubmit])

    return (
        <ChatInputWrap>
            <Form onSubmit={onSubmit}>
                <Stack spacing={1}>
                    <ChatInputControls isSubmitting={isSubmitting} />
                    <ChatInputTextField isSubmitting={isSubmitting} />
                </Stack>
            </Form>
        </ChatInputWrap>
    )
})

export const AvyChatInput = (props: IAvyChatInputProps) => (
    <StoreProvider Store={AvyChatInputStore}>
        <Component {...props} />
    </StoreProvider>
)
