import { observer } from "mobx-react"
import React, { useCallback, useEffect, useMemo } from "react"
import { t } from "@lingui/macro"

import { AccessGroupsStore } from "./store"

import { ConfirmDeleteModal } from "src/modals/confirm-delete"
import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { ListPage } from "src/components/ListPage"
import { Table } from "src/components/Table"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { AccessGroupCreateOrEditModal } from "src/modals/access-groups-create-edit"
import { reportError } from "src/lib/report"

const View = observer(() => {
    const store = useStore(AccessGroupsStore)
    const globalStore = useStore(GlobalStore)

    useEffect(() => {
        ;(async () => {
            await store.init()
        })()
    }, [store])

    const handleNewAccessGroupClick = useCallback(() => {
        globalStore.modals.open(() => <AccessGroupCreateOrEditModal />)
    }, [globalStore.modals])

    const header = useMemo(
        () => ({
            header: t`access-groups-view.header`,
            breadcrumbs: [t`access-groups-view.breadcrumb`],
            createOptions: {
                item: "",
                onClick: handleNewAccessGroupClick,
            },
        }),
        [handleNewAccessGroupClick],
    )

    const deleteAccessGroup = useCallback(
        (id: number) => async () => {
            try {
                await store.deleteAccessGroup(id)
            } catch {
                reportError(t`access-groups-view.failed-to-delete-group`)
            }
            globalStore.modals.pop()
        },
        [store, globalStore],
    )

    const handleDeleteAccessGroupClick = useCallback(
        (id: number) => () => {
            globalStore.modals.open(
                () => (
                    <ConfirmDeleteModal
                        variant="single"
                        deleteItem={deleteAccessGroup(id)}
                        itemId={id}
                        title={t`access-groups-view.confirm-delete-modal.title`}
                        content={t`access-groups-view.confirm-delete-modal.content`}
                    />
                ),
                { variant: "slide-up-w600" },
            )
        },
        [globalStore, deleteAccessGroup],
    )

    const handleEditAccessGroupClick = useCallback(
        (id: number) => (event: React.MouseEvent<Element>) => {
            event.preventDefault()
            globalStore.modals.open(() => (
                <AccessGroupCreateOrEditModal id={id} />
            ))
        },
        [globalStore.modals],
    )

    const tableIsLoading =
        !store.accessGroups.meta.initialized ||
        globalStore.loading.is(AccessGroupsStore.LoadingKeys.pageLoad)

    return (
        <ListPage header={header} loading={tableIsLoading}>
            <Table
                paginator={store.accessGroups}
                headers={[
                    {
                        key: "name",
                        name: t`access-groups-view.name-header`,
                    },
                ]}
                rowRenderer={(item) => ({
                    name: item.name,
                })}
                rowActionsRenderer={(item) => [
                    {
                        text: t`access-groups-view.edit-group`,
                        onClick: handleEditAccessGroupClick(item.id),
                    },
                    {
                        text: t`access-groups-view.delete-group`,
                        destructive: true,
                        onClick: handleDeleteAccessGroupClick(item.id),
                    },
                ]}
            />
        </ListPage>
    )
})

export const AccessGroupsView = () => (
    <StoreProvider Store={AccessGroupsStore}>
        <View />
    </StoreProvider>
)
