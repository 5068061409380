import { observer } from "mobx-react"
import { t } from "@lingui/macro"

import { AccessGroupsCreateOrEditStore } from "./store"

import { FormFieldSet } from "src/components/FormFieldSet"
import { SegmentPickerField } from "src/components/SegmentPickerField"

import { useStore } from "src/store/lib/useStore"

export const SegmentsSection = observer(() => {
    const store = useStore(AccessGroupsCreateOrEditStore)
    return (
        <FormFieldSet
            header={t`create-edit-access-group-modal.segments-section.header`}
        >
            <SegmentPickerField
                value={store.fields.get("segmentIds")}
                onChange={(segmentIds) => {
                    store.fields.set("segmentIds", segmentIds)
                }}
            />
        </FormFieldSet>
    )
})
