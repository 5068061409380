import { observer } from "mobx-react"
import { useCallback, useEffect, useMemo } from "react"
import { t } from "@lingui/macro"
import { Link, Tooltip, Typography, useTheme, IconButton } from "@mui/material"
import { Link as RouterLink, useNavigate } from "react-router-dom"

import { ProductCatalogueStore } from "./store"
import { ProductCatalogueStatusChip } from "./ProductCatalogueStatusChip"

import { ConfirmDeleteModal } from "src/modals/confirm-delete"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { Table } from "src/components/Table"
import { Icon } from "src/components/icons"
import { EditPencil } from "src/components/icons/EditPencil"
import { ViewEye } from "src/components/icons/ViewEye"
import { ListPage } from "src/components/ListPage"
import { reportError } from "src/lib/report"
import { IPageFilterProps } from "src/components/PageFilter"
import { SegmentPickerButton } from "src/components/SegmentPickerButton"
import { TooltipContainer } from "src/styledComponents/TooltipContainer"

const View = observer(() => {
    const theme = useTheme()
    const store = useStore(ProductCatalogueStore)
    const globalStore = useStore(GlobalStore)
    const navigate = useNavigate()

    useEffect(() => {
        ;(async () => {
            await store.init(
                globalStore.session.accessGroupId,
                globalStore.session.segmentIds,
            )
        })()
    }, [
        store,
        globalStore.session.accessGroupId,
        globalStore.session.segmentIds,
    ])

    const productTypeNames: Record<string, string | undefined> = {
        purchase: t`product-catalogue-view.product-type.purchase`,
        quote_request: t`product-catalogue-view.product-type.quote-request`,
        affiliate: t`product-catalogue-view.product-type.affiliate`,
        standard_improvement_single: t`product-catalogue-view.product-type.individual-addition`,
    }

    const handleSegmentChange = useCallback(
        (segments: number[]) => store.loadSegments(segments),
        [store],
    )

    const header = useMemo(
        () => ({
            header: t`product-catalogue-view.header`,
            breadcrumbs: [
                t`product-catalogue-view.header`,
                t`product-catalogue-view.breadcrumbs-products`,
            ],
            createOptions: {
                onClick: () => navigate("new"),
                item: "",
            },
        }),
        [navigate],
    )

    const filter = useMemo(
        (): IPageFilterProps => ({
            actions: (
                <>
                    <SegmentPickerButton
                        value={store.segments}
                        onChange={handleSegmentChange}
                    />
                </>
            ),
        }),
        [handleSegmentChange, store.segments],
    )

    const tableIsLoading =
        !store.products.meta.initialized ||
        globalStore.loading.is(ProductCatalogueStore.LoadingKeys.pageLoad)

    const deleteProduct = useCallback(
        (id: number) => async () => {
            try {
                await store.deleteProduct(id)
            } catch (e) {
                reportError(
                    t`product-catalogue-view.failed-to-delete-product`,
                    e,
                )
            }
            globalStore.modals.pop()
        },
        [store, globalStore.modals],
    )

    const deleteProductClickHandler = useCallback(
        (id: number) => async () => {
            await globalStore.modals.open(
                () => (
                    <ConfirmDeleteModal
                        variant="single"
                        itemId={id}
                        deleteItem={deleteProduct(id)}
                        title={t`confirm-delete-modal.title`}
                        content={t`confirm-delete-modal.content`}
                    />
                ),
                { variant: "slide-up-w600" },
            )
        },
        [globalStore, deleteProduct],
    )

    const duplicateProductClickHandler = useCallback(
        (id: number) => async () => {
            try {
                await store.duplicateProduct(id)
            } catch (e) {
                reportError(
                    t`product-catalogue-view.failed-to-duplicate-product`,
                    e,
                )
            }
        },
        [store],
    )

    return (
        <ListPage header={header} filter={filter} loading={tableIsLoading}>
            <Table
                paginator={store.products}
                headers={[
                    {
                        key: "adminName",
                        name: t`product-catalogue-view.product-header`,
                    },
                    {
                        key: "productId",
                        name: t`product-catalogue-view.product-id-header`,
                    },
                    {
                        key: "provider",
                        name: t`product-catalogue-view.provider-header`,
                    },
                    {
                        key: "productType",
                        name: t`product-catalogue-view.product-type-header`,
                    },
                    {
                        key: "category",
                        name: t`product-catalogue-view.category-header`,
                    },
                    {
                        key: "status",
                        name: t`product-catalogue-view.status-header`,
                    },
                    {
                        key: "accessType",
                        name: t`product-catalogue-view.access-type`,
                    },
                ]}
                rowRenderer={(item) => ({
                    productId: item.productId,
                    adminName: (
                        <Link
                            component={RouterLink}
                            to={item.productId.toString()}
                        >
                            {item.adminName}
                        </Link>
                    ),
                    provider: item.provider,
                    productType: productTypeNames[item.productType] ?? "",
                    category: item.category,
                    status: (
                        <ProductCatalogueStatusChip
                            status={
                                item.productState === "ready" &&
                                item.published !== undefined &&
                                item.published.length > 0
                                    ? "published"
                                    : item.productState ?? "-"
                            }
                        />
                    ),
                    accessType: (
                        <TooltipContainer>
                            <Tooltip
                                title={
                                    !item?.editable
                                        ? t`product-catalogue-view.read`
                                        : t`product-catalogue-view.write`
                                }
                                arrow
                                placement="right-start"
                            >
                                <Typography sx={{ marginTop: "16px" }}>
                                    <IconButton
                                        onClick={() =>
                                            navigate(item.productId.toString())
                                        }
                                    >
                                        <Icon
                                            icon={
                                                !item?.editable ? (
                                                    <ViewEye />
                                                ) : (
                                                    <EditPencil />
                                                )
                                            }
                                            color={theme.palette.grey[700]}
                                        />
                                    </IconButton>
                                </Typography>
                            </Tooltip>
                        </TooltipContainer>
                    ),
                })}
                rowActionsRenderer={(item) => [
                    {
                        text: t`product-catalogue-view.edit-product`,
                        onClick: () => navigate(item.productId.toString()),
                    },
                    {
                        text: t`product-catalogue-view.duplicate-product`,
                        onClick: duplicateProductClickHandler(
                            item.productId ?? 0,
                        ),
                    },
                    {
                        text: t`product-catalogue-view.delete-product`,
                        onClick: deleteProductClickHandler(item.productId ?? 0),
                        destructive: true,
                    },
                ]}
            />
        </ListPage>
    )
})

export const ProductCatalogueView = () => (
    <StoreProvider Store={ProductCatalogueStore}>
        <View />
    </StoreProvider>
)
