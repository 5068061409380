import { observer } from "mobx-react"

import { Grid, Stack, Typography, TextField } from "@mui/material"

import { DatePicker } from "@mui/lab"

import { useCallback } from "react"

import { t } from "@lingui/macro"

import { useStore } from "src/store/lib/useStore"
import { CommunitiesOverviewViewStore } from "src/views/community-overview/store"

export const DateRangePickerRow = observer(() => {
    const store = useStore(CommunitiesOverviewViewStore)

    const handleDateRangeChange = useCallback(
        (value: Date) => {
            return store.setDateRangeFilter(value)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [store],
    )

    return (
        <Grid container paddingX={2} paddingY={1} display={"flex"} height={80}>
            <Grid item xs={6} display={"flex"} alignItems={"center"}>
                <Typography variant="h6">{t`community-overview.date-range-header.engagement`}</Typography>
            </Grid>
            <Grid
                display={"flex"}
                xs={6}
                justifyContent={"flex-end"}
                alignItems={"center"}
            >
                <Stack
                    direction="row"
                    spacing={1.25}
                    sx={{
                        width: {
                            xs: "100%",
                            sm: "260px",
                        },
                    }}
                >
                    <DatePicker
                        views={["year", "month"]}
                        label={t`community-overview.date-range-header.year-month`}
                        minDate={new Date("2012-03-01")}
                        value={store.startDate}
                        onChange={(date) =>
                            date !== null && handleDateRangeChange(date)
                        }
                        renderInput={(params) => (
                            <TextField {...params} helperText={null} />
                        )}
                    />
                </Stack>
            </Grid>
        </Grid>
    )
})
