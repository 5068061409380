import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { debounce } from "lodash"

import { styled } from "@mui/material"
import Button from "@mui/material/Button"
import MUIDialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"

import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"

const DialogActions = styled(MUIDialogActions)({
    justifyContent: "flex-end",
    padding: "16px",
})
interface IProps {
    onConfirm: (confirmed: boolean) => void
    title: string
    content: string
}

export const ConfirmModal = observer((props: IProps) => {
    const gstore = useStore(GlobalStore)

    const handleNo = () => {
        props.onConfirm(false)
        gstore.modals.pop()
    }

    const handleYes = debounce(() => {
        props.onConfirm(true)
        gstore.modals.pop()
    }, 500)

    return (
        <>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{props.content}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    color="info"
                    onClick={handleNo}
                    data-testid="Confirm/Cancel"
                >
                    {t`global.cancel`}
                </Button>
                <Button
                    color="info"
                    onClick={handleYes}
                    autoFocus
                    data-testid="Confirm/Ok"
                >
                    {t`global.ok`}
                </Button>
            </DialogActions>
        </>
    )
})
