import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useCallback, useEffect, useMemo } from "react"

import { ListPage } from "src/components/ListPage"
import { DataGridProTable } from "src/components/Table/DataGridPro"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { formatDateTime } from "src/lib/date"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { Repository } from "src/types/channel"
import { IColumn } from "src/types/data-grid-pro"
import { CommunityBlockedTenantsStore } from "src/views/community-blocked-tenants/store"
import { IBlockedTenant } from "src/views/community-blocked-tenants/types"

const repository: Repository = "community-blocked-tenants"

const View = observer(() => {
    const store = useStore(CommunityBlockedTenantsStore)
    const globalStore = useStore(GlobalStore)

    useEffect(() => {
        ;(async () => {
            await store.init(
                globalStore.session.accessGroup.id ?? DEFAULT_ACCESS_GROUP.id,
            )
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalStore.session.accessGroup])

    const header = useMemo(
        () => ({
            header: t`community-blocked-tenants-view.header`,
            breadcrumbs: [
                t`navigation.community-blocked-tenants`,
                t`community-blocked-tenants-view.header`,
            ],
        }),
        [],
    )

    const columns: IColumn<IBlockedTenant>[] = [
        {
            field: "author_name",
            headerName: t`community-blocked-tenants-view.name`,
            renderCell: (params) => params.value,
            type: "string",
            sortable: false,
            filterable: false,
        },
        {
            field: "author_email",
            headerName: t`community-blocked-tenants-view.email`,
            renderCell: (params) => params.value,
            type: "string",
            sortable: false,
            filterable: false,
        },
        {
            field: "author_phone",
            headerName: t`community-blocked-tenants-view.phone-number`,
            renderCell: (params) => params.value,
            type: "string",
            sortable: false,
            filterable: false,
        },
        {
            field: "author_id",
            headerName: t`community-blocked-tenants-view.tenant-id`,
            renderCell: (params) => params.value,
            type: "string",
            sortable: false,
            filterable: false,
        },
        {
            field: "community_name",
            headerName: t`community-blocked-tenants-view.community-name`,
            renderCell: (params) => params.value,
            type: "string",
            sortable: false,
            filterable: false,
        },
        {
            field: "blocked_at",
            headerName: t`community-blocked-tenants-view.blocked-since`,
            renderCell: (params) => formatDateTime(params.value),
            type: "string",
            sortable: false,
            filterable: false,
        },
    ]

    const unblockTenant = useCallback(
        async (item: IBlockedTenant) => {
            await store.unblockTenant(
                item.community_id ?? -1,
                item.author_id ?? -1,
            )
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    const rowActionsRenderer = useCallback(
        (item: IBlockedTenant) => {
            const actions = [
                {
                    text: t`community-blocked-tenants-view.unblock-tenant`,
                    onClick: () => unblockTenant(item),
                },
            ]

            return actions
        },
        [unblockTenant],
    )

    return (
        <ListPage
            header={header}
            loading={globalStore.loading.is(
                CommunityBlockedTenantsStore.LoadingKeys.init,
            )}
        >
            <DataGridProTable
                paginator={store.blockedTenants}
                data={store.blockedTenants.items}
                columns={columns}
                advancedOperations={{
                    pagination: "client",
                    filtering: "client",
                    sorting: "client",
                }}
                rowActionsRenderer={rowActionsRenderer}
                repository={repository}
                loading={!store.blockedTenants.meta.initialized}
                hideToolbar
            />
        </ListPage>
    )
})

export const CommunityBlockedTenantsView = () => (
    <StoreProvider Store={CommunityBlockedTenantsStore}>
        <View />
    </StoreProvider>
)
