import { useTheme } from "@mui/material"
import { useMemo } from "react"

import { CommunityInsightsSeverityLevel } from "src/views/community-insights/types"

export const warningPicker = (score: number): string => {
    if (score === 0) {
        return CommunityInsightsSeverityLevel.NONE
    } else if (score <= 0.5) {
        return CommunityInsightsSeverityLevel.REDUCED
    } else if (score <= 1) {
        return CommunityInsightsSeverityLevel.LOW
    } else if (score <= 1.5) {
        return CommunityInsightsSeverityLevel.MODERATED
    } else if (score <= 2) {
        return CommunityInsightsSeverityLevel.MEDIUM
    } else if (score <= 2.5) {
        return CommunityInsightsSeverityLevel.HIGH
    } else if (score === 3) {
        return CommunityInsightsSeverityLevel.SEVERE
    } else {
        return CommunityInsightsSeverityLevel.NONE
    }
}

const useWarningColor = (warningLevel: number): string => {
    const { palette } = useTheme()
    const warningColors: Record<string, string> = useMemo(
        () => ({
            none: palette.utility.concrete.main,
            reduced: palette.utility.varden.main,
            low: palette.utility.creamBrulee.main,
            moderated: palette.utility.brightSun.main,
            medium: palette.utility.neonCarrot.light,
            high: palette.error.main,
            severe: palette.utility.roofTerracotta.main,
        }),
        [palette],
    )

    const color = warningColors[warningPicker(warningLevel)]
    return color.length > 0 ? color : palette.grey[500]
}

export default useWarningColor
