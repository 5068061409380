import { styled, ListItemIcon as MUIListItemIcon } from "@mui/material"

export const ListItemIcon = styled(MUIListItemIcon)({
    width: "24px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 24,
    paddingLeft: 8,
    paddingRight: 8,
    marginRight: 8,
})
