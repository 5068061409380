import { observer } from "mobx-react"
import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import { Outlet } from "react-router-dom"

import { t } from "@lingui/macro"

import { AvyTmplLogo } from "src/components/icons/AvyTmplLogo"

export const AuthLayout = observer(() => {
    const theme = useTheme()
    const mediumScreen = useMediaQuery(theme.breakpoints.up("md"))

    return (
        <>
            {mediumScreen && (
                <Box
                    sx={{
                        position: "fixed",
                        height: "100vh",
                        backgroundImage:
                            "url('/static/images/auth-layout-background.png')",
                        width: "50%",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <AvyTmplLogo
                        style={{
                            marginBottom: "16px",
                            color: "#fff",
                            width: "50%",
                            maxWidth: "400px",
                        }}
                    />
                </Box>
            )}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: mediumScreen ? "50%" : "100%",
                    minHeight: "100vh",
                    marginLeft: mediumScreen ? "50%" : 0,
                }}
            >
                <Stack
                    spacing={3}
                    component="main"
                    sx={{
                        py: 7,
                        px: mediumScreen ? 3 : 2,
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        maxWidth: "376px",
                    }}
                >
                    {!mediumScreen && (
                        <AvyTmplLogo
                            style={{
                                width: "150px",
                                height: "35px",
                                marginBottom: "16px",
                            }}
                        />
                    )}
                    <Outlet />
                    <Typography sx={{ textAlign: "center" }}>
                        {t`auth-layout.copyright`}
                        {` ${new Date().getFullYear()} `}
                        {t`auth-layout.avy-tmpl`}
                    </Typography>
                </Stack>
            </Box>
        </>
    )
})
