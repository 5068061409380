import { alpha, Box, styled } from "@mui/material"

export const EmptySlot = styled(Box)(({ theme }) => ({
    borderWidth: "1px",
    borderStyle: "dashed",
    borderColor: theme.palette.grey[500],
    borderRadius: "4px",
    width: "100%",
    height: "124px",
}))

export const Slot = styled(Box)(({ theme }) => ({
    position: "relative",
    overflow: "hidden",
    borderRadius: "4px",
    width: "100%",
    height: "124px",
    background: theme.palette.common.white,
}))

export const Img = styled("img")({
    width: "100%",
    height: "100%",
    objectFit: "contain",
})

export const Video = styled("video")({
    width: "100%",
    height: "100%",
    objectFit: "contain",
})

export const ImagePreviewHoverOptions = styled("div")(({ theme }) => ({
    background: alpha(theme.palette.grey[900], 0.7),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: 0,
    position: "absolute",
    inset: 0,
    zIndex: 1,
    color: theme.palette.common.white,
    "&:hover": {
        opacity: 1,
    },
}))
