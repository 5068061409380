import React, { useEffect, useMemo } from "react"

export function StoreProvider<T>(props: {
    children: React.ReactNode
    Store: {
        new (): T
        Context: React.Context<T>
    }
}) {
    const { Store, children } = props

    const store = useMemo(() => new Store(), [Store])

    useEffect(() => {
        return () => {
            ;(store as Partial<IDisposable>).dispose?.()
        }
    }, [store])

    return (
        <Store.Context.Provider value={store}>
            {children}
        </Store.Context.Provider>
    )
}
