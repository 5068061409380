const mobileDeviceTypes = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
]

/**
 * Checks if the current device is a mobile device.
 * @return {boolean} Returns true if the device is a mobile device, false otherwise.
 */

export const isMobile = mobileDeviceTypes.some((type) =>
    navigator?.userAgent?.match(type),
)
