import { Typography } from "@mui/material"
import { ElementType } from "react"

const typographyBase = {
    component: "span" as ElementType,
    variant: "subtitle2" as const,
}

const typography = {
    title: { ...typographyBase, fontWeight: 700 },
    value: { ...typographyBase, fontWeight: 400 },
}

interface IHeaderTypography {
    label: string
    value: string | number
}

export const HeaderTypographyFields = ({ label, value }: IHeaderTypography) => (
    <Typography variant="subtitle2">
        <Typography {...typography.title}>{label}: </Typography>
        <Typography {...typography.value}>{value}</Typography>
    </Typography>
)
