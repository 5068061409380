import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { DatePicker } from "@mui/lab"
import { useEffect } from "react"

import { Stack, styled, TextField } from "@mui/material"
import Button from "@mui/material/Button"
import MUIDialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"

import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { MarkInvoiceAsPaidStore } from "src/modals/mark-invoice-as-paid/store"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"

const DialogActions = styled(MUIDialogActions)({
    justifyContent: "flex-end",
    padding: "16px",
})
interface IProps {
    id: number
}

const View = observer((props: IProps) => {
    const gstore = useStore(GlobalStore)
    const store = useStore(MarkInvoiceAsPaidStore)

    useEffect(() => {
        store.form.set("id", props.id)
    }, [store.form, props.id])

    const handleClose = () => {
        gstore.modals.pop()
    }

    const handleSave = async () => {
        if (await store.submit()) {
            trackModuleEvent("Invoices | Mark as paid")
            gstore.modals.pop()
        }
    }

    return (
        <>
            <DialogTitle>{t`mark-invoice-as-paid-modal.header`}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Stack spacing={2}>
                        <div>{t`mark-invoice-as-paid-modal.body`}</div>
                        <DatePicker
                            onChange={(date) => {
                                if (date != null) {
                                    store.form.set("date", date)
                                }
                            }}
                            label={t`mark-invoice-as-paid-modal.date-label`}
                            value={store.form.get("date")}
                            renderInput={(props) => (
                                <TextField
                                    {...props}
                                    inputProps={{
                                        ...props.inputProps,
                                        readOnly: true,
                                    }}
                                />
                            )}
                        />
                    </Stack>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    color="info"
                    onClick={handleClose}
                    disabled={gstore.loading.is(
                        MarkInvoiceAsPaidStore.LoadingKeys.submit,
                    )}
                >
                    {t`global.cancel`}
                </Button>
                <Button
                    color="info"
                    onClick={handleSave}
                    disabled={gstore.loading.is(
                        MarkInvoiceAsPaidStore.LoadingKeys.submit,
                    )}
                    autoFocus
                >
                    {t`global.save`}
                </Button>
            </DialogActions>
        </>
    )
})

export const MarkInvoiceAsPaidModal = observer((props: IProps) => (
    <StoreProvider Store={MarkInvoiceAsPaidStore}>
        <View {...props} />
    </StoreProvider>
))
