import { useSearchParams } from "react-router-dom"

import { IRouteParams } from "src/views/community-posts-and-comments/type"

const useRouteParams = (): IRouteParams[] => {
    const [searchParams] = useSearchParams()

    return Array.from(searchParams.entries()).map(([key, value]) => ({
        param: key,
        value: value,
    }))
}

export default useRouteParams
