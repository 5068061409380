import { IIconProps } from "src/components/icons"

export const Link24 = (props: IIconProps) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M12.3418 14.4418C12.1357 14.5268 11.9717 14.6903 11.8862 14.8962C11.8006 15.1022 11.8004 15.3337 11.8857 15.5397C12.0158 15.8537 11.9438 16.2151 11.7034 16.4553L9.3279 18.8317C8.67186 19.4876 7.60841 19.4876 6.95237 18.8317L5.16988 17.0501C4.85466 16.735 4.67757 16.3076 4.67757 15.8619C4.67757 15.4162 4.85466 14.9888 5.16988 14.6737L7.54877 12.2981C7.79058 12.0601 8.15072 11.9885 8.46522 12.1159C8.89411 12.2929 9.38528 12.0886 9.56227 11.6597C9.73925 11.2308 9.53504 10.7397 9.10614 10.5627C8.16376 10.1814 7.08492 10.3967 6.361 11.1104L3.98211 13.4859C2.67263 14.7992 2.67263 16.9245 3.98211 18.2378L5.7646 20.0195C7.07669 21.3312 9.20358 21.3312 10.5157 20.0195L12.892 17.6439C13.6115 16.9224 13.8272 15.8392 13.4389 14.8971C13.2616 14.4685 12.7705 14.2647 12.3418 14.4418Z"
            fill="currentColor"
        />
        <path
            d="M8.43665 15.5641C8.76673 15.887 9.29434 15.887 9.62442 15.5641L16.1588 9.03056C16.4772 8.70096 16.4726 8.17705 16.1486 7.85303C15.8246 7.52901 15.3007 7.52446 14.9711 7.84279L8.43665 14.3764C8.10872 14.7044 8.10872 15.2361 8.43665 15.5641Z"
            fill="currentColor"
        />
        <path
            d="M20.0195 5.76376L18.2379 3.98211C16.9245 2.67263 14.7993 2.67263 13.4859 3.98211L11.1096 6.35764C10.39 7.07885 10.1744 8.16179 10.5627 9.10362C10.6774 9.38107 10.9313 9.57628 11.2289 9.61572C11.5265 9.65517 11.8225 9.53285 12.0055 9.29484C12.1884 9.05684 12.2305 8.73931 12.1159 8.46186C11.9873 8.14882 12.0592 7.78922 12.2982 7.54961L14.6737 5.17408C14.9888 4.85886 15.4162 4.68177 15.8619 4.68177C16.3076 4.68177 16.735 4.85886 17.0501 5.17408L18.8317 6.95573C19.1469 7.27081 19.324 7.69823 19.324 8.14392C19.324 8.5896 19.1469 9.01702 18.8317 9.3321L16.4528 11.7034C16.2108 11.9411 15.8509 12.0127 15.5364 11.8857C15.1075 11.7083 14.616 11.9121 14.4385 12.341C14.2611 12.7699 14.4649 13.2614 14.8938 13.4389C15.8363 13.823 16.917 13.6078 17.6406 12.892L20.0161 10.5165C21.3266 9.20382 21.3281 7.07831 20.0195 5.76376Z"
            fill="currentColor"
        />
    </svg>
)
