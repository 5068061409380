import { styled, Theme } from "@mui/material"

export const Separator = styled("div")(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    marginTop: "1.25rem",
}))

export const VerticalSeparator = styled("div")(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
}))

export const CardContainer = styled("div")(
    ({ windowWidth, theme }: { windowWidth: boolean; theme: Theme }) => ({
        marginLeft: windowWidth ? 8 : 0,
        marginRight: windowWidth ? 8 : 0,
        borderWidth: 1,
        borderRadius: "0.5rem",
        paddingLeft: "0.5rem",
        backgroundColor: theme.palette.primary.main,
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        elevation: 5,
    }),
)

export const CardInnerContainer = styled("div")(({ theme }) => ({
    borderWidth: 1,
    backgroundColor: theme.palette.common.white,
    paddingLeft: "1rem",
    paddingRight: "1rem",
    paddingBottom: "1rem",
}))

export const CardBottomContainer = styled("div")(() => ({
    flexDirection: "row",
    display: "flex",
    justifyContent: "flex-end",
}))

export const DraggableIconContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "6px",
})
