import React from "react"
import { t } from "@lingui/macro"

import { reportError } from "src/lib/report"
import { Channel } from "src/channel"
import { loads } from "src/channel/utils"
import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"
import { AdminService } from "src/api"

export interface ILegalEntity {
    createdDate: string
    integrationActive: boolean
    integrationId: number | string
    externalId: string
    integrationType: string
    leafletUrl: string
    legalEntityId: number
    legalName: string
    modifiedDate: string
    organisationId: string
    propertyOwnerId: number
    selfOnboardingCode: string
    selfOnboardingEnabled: boolean
    type: string
    id: number
}

export class LegalEntityStore {
    static Context = React.createContext<LegalEntityStore | null>(null)
    static LoadingKeys = createLoadingKeys("pageLoad", "init", "fastApiRequest")

    private repositoryUpdatesListenerDisposer?: () => void
    private _legalEntities: ILegalEntity[] = []
    legalEntity = new Pagination(
        (params) => {
            const pageStart = (params.page - 1) * params.pageSize
            const pageLegalEntities = this._legalEntities.slice(
                pageStart,
                pageStart + params.pageSize,
            )
            return {
                items: pageLegalEntities,
                count: this._legalEntities.length,
                sourceItems: this._legalEntities,
            }
        },
        { static: true },
    )

    private _propertyOwnerId: number = 0

    get propertyOwnerId() {
        return this._propertyOwnerId
    }

    setPropertyOwnerId = (propertyOwnerId: number) => {
        this._propertyOwnerId = propertyOwnerId
    }

    setLegalEntities(legalEntities: ILegalEntity[]) {
        this._legalEntities = legalEntities
    }

    @loads(() => LegalEntityStore.LoadingKeys.init)
    async init(propertyOwnerId: number) {
        this.listenToResourcesRepositoryUpdated()
        this.setPropertyOwnerId(propertyOwnerId)
        await this.loadLegalEntities()
    }

    dispose() {
        this.repositoryUpdatesListenerDisposer?.()
    }

    async legalEntityFastApiRequest(
        propertyOwnerId: number,
        legalEntityId: number,
        fullLoad: boolean,
    ) {
        await AdminService.postV1AdminIntegrationFastapiRequest({
            request: {
                property_owner_id: propertyOwnerId,
                full_load: fullLoad,
                legal_entity_id: legalEntityId,
            },
        })
    }

    async offboardLegalEntity(legalEntityId: number) {
        await AdminService.postV1AdminLegalEntityExpireAllContracts({
            legalEntityId: legalEntityId,
        })
    }

    private listenToResourcesRepositoryUpdated() {
        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "legal-entities"
                ) {
                    await this.loadLegalEntities()
                }
            },
        )
    }

    private async loadLegalEntities() {
        try {
            const legalEntityResponse =
                await AdminService.getV1AdminLegalEntity({
                    propertyOwnerId: this.propertyOwnerId,
                })
            this.setLegalEntities(
                legalEntityResponse.map(
                    (legalEntity): ILegalEntity => ({
                        createdDate: legalEntity.created_date ?? "",
                        integrationActive:
                            legalEntity.integration_active ?? false,
                        integrationId: legalEntity.integration_id ?? "",
                        externalId: legalEntity.external_id ?? "",
                        integrationType: legalEntity.integration_type ?? "",
                        leafletUrl: legalEntity.leaflet_url ?? "",
                        legalEntityId: legalEntity.legal_entity_id ?? 0,
                        legalName: legalEntity.legal_name ?? "",
                        modifiedDate: legalEntity.modified_date ?? "",
                        organisationId: legalEntity.org_id ?? "",
                        propertyOwnerId: legalEntity.property_owner_id ?? 0,
                        selfOnboardingCode:
                            legalEntity.self_onboarding_code ?? "",
                        selfOnboardingEnabled:
                            legalEntity.self_onboarding_enabled ?? false,
                        type: legalEntity.type ?? "",
                        id: legalEntity.legal_entity_id ?? 0,
                    }),
                ) ?? [],
            )
        } catch (e) {
            reportError(
                t`client-management-legal-entities-table.failed-to-load-legal-entities`,
                e,
            )
        }
        await this.legalEntity.reload()
    }
}
