import mixpanel, { Dict } from "mixpanel-browser"

import { environment } from "src/config"

const MIXPANEL_TOKEN = environment.MIXPANEL_TOKEN
if (MIXPANEL_TOKEN !== "") {
    mixpanel.init(MIXPANEL_TOKEN)
}

export const track = (event: string, properties?: Dict) =>
    mixpanel.track(event, properties)
export const mixpanelIdentify = (adminId: string | undefined) =>
    mixpanel.identify(adminId)

export const mixpanelSet = (event: Record<string, string>) => {
    mixpanel.people.set(event)
}
