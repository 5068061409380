import React from "react"

import { LockAdminService } from "src/api"
import { Channel } from "src/channel"
import { loads } from "src/channel/utils"
import { DEFAULT_ACCESS_GROUP } from "src/config"

import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"
import { IAccessItem } from "src/views/access/type"

export class AccessViewStore implements IDisposable {
    static Context = React.createContext<AccessViewStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "loading")

    private _accessGroupId: number = DEFAULT_ACCESS_GROUP.id
    private repositoryUpdatesListenerDisposer?: () => void

    accessList = new Pagination(
        async (params) => {
            const response = await LockAdminService.getV1AdminLockResource({
                accessGroupId: this.getAccessGroupId(),
                pageNumber: params.page,
                pageSize: params.pageSize,
            })

            const items: IAccessItem[] = (response.result ?? []).map(
                (item) => ({
                    id: item.resource_id ?? -1,
                    name: item.name ?? "",
                    type: item.type ?? "",
                    parakey_resource_id: item.parakey_resource_id ?? "",
                    access_group_id: item.access_group_id ?? -1,
                }),
            )

            return { items, count: response.total_count ?? 0 }
        },
        {
            loadingKey: AccessViewStore.LoadingKeys.loading,
        },
    )

    dispose() {
        this.repositoryUpdatesListenerDisposer?.()
    }

    @loads(() => AccessViewStore.LoadingKeys.init)
    async init(accessGroupId: number) {
        this.listenToAccessyListRepositoryUpdates()
        this.setAccountGroupId(accessGroupId)
        await this.accessList.loadInitialPage()
    }

    private setAccountGroupId(accessGroupId: number) {
        this._accessGroupId = accessGroupId
    }

    private listenToAccessyListRepositoryUpdates() {
        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "parakey"
                ) {
                    await this.accessList.reload()
                }
            },
        )
    }

    private getAccessGroupId() {
        return this._accessGroupId !== DEFAULT_ACCESS_GROUP.id
            ? this._accessGroupId
            : undefined
    }
}
