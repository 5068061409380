import { observer } from "mobx-react"

import { FormPanel } from "src/components/FormPanel"
import ImageSelectionSection from "src/components/ImageSelectionSection"
import { IEmbeddedIntegrationIconUploadProps } from "src/modals/embedded-integration-v2/types/embeddedIntegrationIconUpload"

export const EmbeddedIntegrationIconUpload = observer(
    ({
        header,
        subHeader,
        value,
        onIconDelete,
        onIconChange,
        errorMessage,
    }: IEmbeddedIntegrationIconUploadProps) => {
        return (
            <FormPanel
                sections={[
                    {
                        header: header,
                        subHeader: subHeader,
                        content: (
                            <ImageSelectionSection
                                imageUrl={value}
                                onImageDelete={onIconDelete}
                                onImageChange={(images) => onIconChange(images)}
                                errorMessage={errorMessage ?? ""}
                            />
                        ),
                    },
                ]}
            />
        )
    },
)
