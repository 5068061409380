import { TextField } from "@mui/material"
import { observer } from "mobx-react"

import { t } from "@lingui/macro"

import { IShortAnswerTextFieldProps } from "src/modals/contact-forms-form-builder/types/formFieldsSection"

export const ShortLongAnswerTextField = observer(
    (props: IShortAnswerTextFieldProps) => {
        return (
            <TextField
                label={
                    props.type === "Paragraph"
                        ? t`contact-form-forms-detail-modal.form-field-section.long-text-label`
                        : t`contact-form-forms-detail-modal.form-field-section.short-text-label`
                }
                sx={{
                    "& fieldset": {
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        borderBottomStyle: "dotted",
                    },
                }}
                value={props.item.value}
                onChange={(event) => props.handleShortAnswerChange(event)}
                inputProps={{ maxLength: props.maxLength }}
                error={Boolean(props.item.errorMessage)}
                helperText={props.item.errorMessage}
            />
        )
    },
)
