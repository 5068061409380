import { styled } from "@mui/material"

const Header = styled("h2")(() => ({
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: 1.6,
    margin: 0,
}))

const SubHeader = styled("p")(() => ({
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    margin: 0,
}))

const Section = styled("div")(({ theme }) => ({
    display: "flex",
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        alignItems: "flex-start",
    },
    [theme.breakpoints.up("md")]: {
        flexDirection: "row",
        alignItems: "center",
    },
    justifyContent: "space-between",
    marginTop: "16px",
    padding: "24px 16px",
    minHeight: "112px",
    background: theme.palette.grey[100],
    borderRadius: "16px",
}))

const TextContainer = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
        marginRight: 0,
    },
    [theme.breakpoints.up("md")]: {
        marginRight: "24px",
    },
}))

export { Header, SubHeader, Section, TextContainer }
