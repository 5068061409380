import { observer } from "mobx-react"
import { TextField } from "@mui/material"
import { useEffect } from "react"
import { t } from "@lingui/macro"

import { EditProductStore } from "src/views/product-catalogue/edit/store"
import { useStore } from "src/store/lib/useStore"

export const LinkToOffer = observer(() => {
    const store = useStore(EditProductStore)

    useEffect(() => {
        return () => {
            // Reset to initial value if component unmounts
            store.formFields.set("affiliateLink", store.product.affiliateLink)
        }
    }, [store])

    return (
        <TextField
            label={t`edit-product-view.link-to-offer.label`}
            placeholder={t`edit-product-view.link-to-offer.placeholder`}
            value={store.formFields.get("affiliateLink") ?? ""}
            onChange={store.formFields.setter("affiliateLink")}
            error={Boolean(store.formFields.error("affiliateLink"))}
            helperText={store.formFields.error("affiliateLink")}
        />
    )
})
