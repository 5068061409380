import { t } from "@lingui/macro"
import { makeAutoObservable } from "mobx"
import React from "react"

import { format } from "date-fns"

import {
    FileAdminService,
    campaign_AdminCampaignCreateData,
    campaign_AdminCampaignUpdateData,
    campaign_ListOffersRequest,
    campaign_ListOffersResponse,
    campaign_Offer,
} from "src/api"
import { CampaignAdminService } from "src/api/services/CampaignAdminService"
import { loads } from "src/channel/utils"
import { parseDate } from "src/lib/date"
import { isLocalFile, isPersistedFile, persistFiles } from "src/lib/file"
import { FormFields } from "src/lib/form-fields"
import { createLoadingKeys } from "src/lib/loading"
import { Channel } from "src/channel"
import { reportUnhandledApiError } from "src/lib/report"

export interface IFormFields {
    internalName: string
    segmentIds: number[]
    publishAt: Date | null
    unpublishAt: Date | null
    sendPushNotification: boolean
    offerId: number | null
    offerType: string | null
    offerName: string | null
    header: string
    status?: string
    tagLine: string
    description: string
    campaignBanner: IFile[]
    offerList?: campaign_Offer[]
}

export class CampaignDetailStore {
    static Context = React.createContext<CampaignDetailStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "submit")

    private id?: number

    private _accessGroupId?: number

    availableSegmentIds: number[] = []

    fields = new FormFields<IFormFields>({
        internalName: "",
        segmentIds: [],
        publishAt: null,
        unpublishAt: null,
        sendPushNotification: false,
        offerId: null,
        offerType: "",
        offerName: "",
        header: "",
        status: "",
        tagLine: "",
        description: "",
        campaignBanner: [],
    })

    constructor() {
        makeAutoObservable(this)
    }

    @loads(() => CampaignDetailStore.LoadingKeys.init)
    async init(accessGroupId: number, id?: number) {
        this.setId(id)

        try {
            if (id != null) {
                const response = await CampaignAdminService.getV1AdminCampaign({
                    campaignId: id,
                })
                const bannerFile: IFile = {
                    type: "image",
                    name: "",
                    url: response.image ?? "",
                }

                const { data } = this.fields
                const request: campaign_ListOffersRequest = {
                    segment_ids: response.segment_ids ?? [],
                }
                const offersResponse =
                    await CampaignAdminService.postV1AdminCampaignOfferList({
                        request,
                    })
                let offerItem: campaign_Offer | undefined
                if (Boolean(this.id)) {
                    offerItem = this.getOfferItemFromOffers(
                        offersResponse,
                        data,
                    )
                }

                this.setAccessGroupId(response.access_group_id ?? undefined)

                this.initFields({
                    internalName: response.internal_name ?? "",
                    segmentIds: response.segment_ids ?? [],
                    publishAt: parseDate(response.publish_at),
                    unpublishAt: parseDate(response.unpublish_at),
                    sendPushNotification: response.send_push ?? false,
                    offerId: offerItem?.id ?? null,
                    offerType: offerItem?.type ?? "",
                    offerName: offerItem?.name ?? "",
                    status: response.status ?? "",
                    header: response?.header ?? "",
                    tagLine: response.tag_line ?? "",
                    description: response.description ?? "",
                    campaignBanner: [this.toPersistedFile(bannerFile, "image")],
                    offerList: offersResponse.offers ?? [],
                })
            } else {
                this.setAccessGroupId(accessGroupId)
            }
        } catch (e) {
            reportUnhandledApiError(e)
        }
    }

    @loads(() => CampaignDetailStore.LoadingKeys.submit)
    async submit(isDraft: boolean) {
        const { data } = this.fields
        if (!isDraft) {
            if (this.accessGroupId === null) {
                this.fields.setErrors({ accessGroupId: t`errors.required` })
                return
            }

            if (data.campaignBanner.length <= 0) {
                this.fields.setErrors({ campaignBanner: t`errors.required` })
                return
            }
        }

        await this.fields.catchErrors(async () => {
            let bannerImage = await persistFiles(data.campaignBanner, "image")
            if (this.id == null) {
                const request: campaign_AdminCampaignCreateData = {
                    is_draft: isDraft,
                    access_group_id: this.accessGroupId ?? 0,
                    internal_name: data.internalName,
                    segment_ids: data.segmentIds,
                    publish_at: data.publishAt?.toISOString() ?? undefined,
                    unpublish_at: data.unpublishAt?.toISOString() ?? undefined,
                    send_push: data.sendPushNotification,
                    offer_id: data.offerId ?? undefined,
                    offer_type: data.offerType ?? "",
                    header: data.header,
                    tag_line: data.tagLine,
                    description: data.description,
                    image_url: Boolean(bannerImage.length > 0)
                        ? bannerImage[0].url
                        : "",
                }
                const response = await CampaignAdminService.postV1AdminCampaign(
                    {
                        request,
                    },
                )
                Channel.send({
                    name: "repository/updated",
                    payload: {
                        repository: "campaigns",
                        action: "create",
                        item: {
                            id: response.campaign_id ?? 0,
                            name: data.header,
                        },
                    },
                })
            } else {
                const request: campaign_AdminCampaignUpdateData = {
                    is_draft: isDraft,
                    access_group_id: this.accessGroupId ?? 0,
                    internal_name: data.internalName,
                    segment_ids: data.segmentIds,
                    publish_at:
                        data.publishAt !== null
                            ? `${format(data.publishAt, "yyyy-MM-dd")}T${format(
                                  data.publishAt,
                                  "hh:mm:ss",
                              )}Z`
                            : "",
                    unpublish_at:
                        data.unpublishAt !== null
                            ? `${format(
                                  data.unpublishAt,
                                  "yyyy-MM-dd",
                              )}T${format(data.unpublishAt, "hh:mm:ss")}Z`
                            : "",
                    send_push: data.sendPushNotification,
                    offer_id: data.offerId ?? undefined,
                    offer_type: data.offerType ?? "",
                    header: data.header,
                    tag_line: data.tagLine,
                    description: data.description,
                    image_url: Boolean(bannerImage.length > 0)
                        ? bannerImage[0].url
                        : "",
                }

                const response =
                    await CampaignAdminService.patchV1AdminCampaign({
                        campaignId: this.id,
                        request,
                    })

                Channel.send({
                    name: "repository/updated",
                    payload: {
                        repository: "campaigns",
                        action: "update",
                        item: {
                            id: response.campaign_id ?? 0,
                            name: data.header,
                        },
                    },
                })
            }
        })
    }

    @loads(() => CampaignDetailStore.LoadingKeys.submit)
    async getCampaignOfferList() {
        const { data } = this.fields

        const request: campaign_ListOffersRequest = {
            segment_ids: data.segmentIds,
        }

        const response =
            await CampaignAdminService.postV1AdminCampaignOfferList({
                request,
            })
        this.fields.set("offerList", response.offers)

        if (Boolean(this.id)) {
            const offerItem = this.getOfferItemFromOffers(response, data)

            this.fields.set("offerId", offerItem?.id ?? null)
            this.fields.set("offerType", offerItem?.type ?? "")
            this.fields.set("offerName", offerItem?.name ?? "")
        }
    }

    private getOfferItemFromOffers = (
        response: campaign_ListOffersResponse,
        data: IFormFields,
    ): campaign_Offer | undefined => {
        return response?.offers?.find((item) => {
            return item.id === (data.offerId === null ? null : data.offerId)
        })
    }

    private setId(id?: number) {
        this.id = id
    }

    getId() {
        return this.id ?? null
    }

    private initFields(fields: IFormFields) {
        this.fields.init(fields)
    }

    setAccessGroupId(id?: number) {
        this._accessGroupId = id
    }

    get accessGroupId() {
        return this._accessGroupId
    }

    /**
     * Persist attachments to object storage before submitting the post
     * containing attachment references.
     *
     * @param attachments Attachments to persist
     * @param type Which type of attachment to persist @see {@link IPersistedFile}
     * @returns
     */
    private async persistAttachments(
        attachments: IFile[],
        type: IPersistedFile["type"],
    ) {
        const alreadyPersistedFiles = attachments.filter(isPersistedFile)
        const nonPersistedFiles = attachments.filter(isLocalFile)
        const urls = await Promise.all(
            nonPersistedFiles.map((file) =>
                FileAdminService.postV1AdminUpload({
                    file: file.file,
                }),
            ),
        )
        const newlyPersistedFiles: IPersistedFile[] = nonPersistedFiles.map(
            (file, i) => ({ url: urls[i], name: file.file.name, type }),
        )
        return [...alreadyPersistedFiles, ...newlyPersistedFiles].map(
            (file) => ({
                url: file.url,
                name: file.name,
                attachment_type: file.type,
            }),
        )
    }

    private toPersistedFile(fileAttached: IFile, type: "image" | "doc"): IFile {
        return {
            name: "",
            url: fileAttached.url ?? "",
            // The document type is called "doc" for this particular api. Rename
            // it to "document", to be consistent with other api:s.
            type: type === "doc" ? "document" : type,
        }
    }
}
