import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { Checkbox, styled } from "@mui/material"
import React, { useCallback, useEffect, useMemo } from "react"
import { useParams } from "react-router"

import { NoticeBoardStore } from "./store"
import { NoticeBoardStatusChip } from "./NoticeBoardStatusChip"

import { track } from "src/analytics/AnalyticsManager"

import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { NoticeBoardPostDetailModal } from "src/modals/notice-board-post-detail"
import { Table } from "src/components/Table"
import { truncateText } from "src/lib/text"
import { ListPage } from "src/components/ListPage"
import { formatDateTime } from "src/lib/date"
import { reportError } from "src/lib/report"
import { SegmentPickerButton } from "src/components/SegmentPickerButton"
import { AccessTypeHeader } from "src/components/AccessTypeHeader"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"

const View = observer(() => {
    const vstore = useStore(NoticeBoardStore)
    const gstore = useStore(GlobalStore)
    let { noticeBoardId } = useParams()
    const hiddenColumns = gstore.session.hasAccessToModule("ai_chatbot")
        ? []
        : ["includeInChatbot"]

    useEffect(() => {
        ;(async () => {
            await vstore.init(gstore.session.accessGroupId)
        })()

        return () => {
            vstore.dispose()
        }
    }, [vstore, gstore.session.accessGroupId, gstore.session.segmentIds])

    useEffect(() => {
        ;(async () => {
            if (noticeBoardId !== undefined) {
                await handleOpenPostClick(Number(noticeBoardId), "Edit")
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSearchChange = useCallback(
        (query: string) => vstore.posts.loadSearch(query),
        [vstore],
    )

    const handleNewPostClick = useCallback(() => {
        track("Notice Board | Create New")
        gstore.modals.open(() => <NoticeBoardPostDetailModal />, {
            hasPreview: true,
        })
    }, [gstore.modals])

    const handleSegmentChange = useCallback(
        (segments: number[]) => vstore.loadSegments(segments),
        [vstore],
    )

    const handleOpenPostClick = useCallback(
        (id: number, mode: string) => {
            gstore.modals.open(
                () => <NoticeBoardPostDetailModal id={id} mode={mode} />,
                {
                    hasPreview: true,
                },
            )
        },
        [gstore.modals],
    )

    const createOpenPostClickHandler = useCallback(
        (id: number, mode: string) => (event: React.MouseEvent<Element>) => {
            trackModuleEvent(`Notice Board | ${mode}`, {
                [MixpanelProperties.AccessGroupID]:
                    gstore.session.accessGroup?.id,
                [MixpanelProperties.AccessGroupName]:
                    gstore.session.accessGroup?.name,
                [MixpanelProperties.UserID]: gstore.session.user?.adminId,
            })
            event.preventDefault()
            handleOpenPostClick(id, mode)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [handleOpenPostClick],
    )

    const createDeletePostClickHandler = useCallback(
        (id: number) => async () => {
            try {
                await vstore.deletePost(id)
            } catch (e) {
                reportError(t`notice-board-view.failed-to-delete-post`, e)
            }
        },
        [vstore],
    )

    const createSavePinnedClickHandler = useCallback(
        (id: number) => async (_: unknown, pinned: boolean) => {
            await vstore.savePinnedStatus(id, pinned)
        },
        [vstore],
    )

    const header = useMemo(
        () => ({
            header: t`notice-board-view.header`,
            breadcrumbs: [t`notice-board-view.header`],
            createOptions: {
                item: t`notice-board-view.add-post`,
                onClick: handleNewPostClick,
            },
        }),
        [handleNewPostClick],
    )

    const filter = useMemo(
        () => ({
            query: vstore.posts.meta.search ?? "",
            onQueryChange: handleSearchChange,
            actions: (
                <>
                    <SegmentPickerButton
                        value={vstore.segments}
                        onChange={handleSegmentChange}
                    />
                </>
            ),
        }),
        [
            handleSearchChange,
            handleSegmentChange,
            vstore.posts.meta.search,
            vstore.segments,
        ],
    )

    const tableIsLoading =
        !vstore.posts.meta.initialized ||
        gstore.loading.is(NoticeBoardStore.LoadingKeys.pageLoad)

    return (
        <ListPage header={header} filter={filter} loading={tableIsLoading}>
            <Table
                paginator={vstore.posts}
                onRowClick={(item) => handleOpenPostClick(item.id, "Edit")}
                headers={[
                    {
                        key: "messageId",
                        name: t`notice-board-view.messageId-header`,
                    },
                    {
                        key: "adminTitle",
                        name: t`notice-board-view.admin-title-header`,
                    },
                    {
                        key: "postPreview",
                        name: t`notice-board-view.post-header`,
                    },
                    {
                        key: "pinned",
                        name: t`notice-board-view.pinned-header`,
                    },
                    {
                        key: "status",
                        name: t`notice-board-view.status-header`,
                    },
                    {
                        key: "includeInChatbot",
                        name: t`notice-board-view.include-in-chatbot-header`,
                        hidden: hiddenColumns.includes("includeInChatbot"),
                    },
                    {
                        key: "createdAt",
                        name: t`notice-board-view.created-header`,
                    },
                    {
                        key: "receivers",
                        name: t`notice-board-view.receiver-header`,
                    },
                    {
                        key: "read",
                        name: t`notice-board-view.read-header`,
                    },
                    {
                        key: "sender",
                        name: t`notice-board-view.sender-header`,
                    },
                    {
                        key: "accessType",
                        name: t`notice-board-view.access-type`,
                    },
                ]}
                rowRenderer={(item) => ({
                    messageId: item.id,
                    adminTitle: (
                        <NoWrap title={item.adminTitle}>
                            {truncateText(item.adminTitle, 30)}
                        </NoWrap>
                    ),
                    postPreview: (
                        <NoWrap title={item.message}>
                            {truncateText(item.message, 30)}
                        </NoWrap>
                    ),
                    pinned: (
                        <Checkbox
                            checked={item.pinned}
                            color="info"
                            onClick={(event) => event.stopPropagation()}
                            onChange={createSavePinnedClickHandler(item.id)}
                            disabled={item.accessType === "READ"}
                        />
                    ),
                    status: <NoticeBoardStatusChip status={item.status} />,
                    includeInChatbot: item.includeInChatbot
                        ? t`notice-board-view.include-in-chatbot-yes`
                        : t`notice-board-view.include-in-chatbot-no`,
                    createdAt:
                        item.createdAt != null
                            ? formatDateTime(item.createdAt)
                            : null,
                    receivers: t({
                        id: "notice-board-view.receiver-count",
                        values: { count: item.receiverCount },
                    }),
                    read: t({
                        id: "notice-board-view.read-count",
                        values: { count: item.readCount },
                    }),
                    sender: item.sender,
                    accessType: (
                        <AccessTypeHeader accessType={item.accessType} />
                    ),
                })}
                rowActionsRenderer={(item) => [
                    {
                        text: t`notice-board-view.copy-post`,
                        onClick: createOpenPostClickHandler(item.id, "Copy"),
                    },
                    {
                        text: t`notice-board-view.edit-post`,
                        onClick: createOpenPostClickHandler(item.id, "Edit"),
                    },
                    {
                        text: t`notice-board-view.delete-post`,
                        onClick: createDeletePostClickHandler(item.id),
                        destructive: true,
                    },
                ]}
            />
        </ListPage>
    )
})

const NoWrap = styled("span")`
    white-space: nowrap;
`

export const NoticeBoardView = () => (
    <StoreProvider Store={NoticeBoardStore}>
        <View />
    </StoreProvider>
)
