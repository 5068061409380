import { IIconProps } from "src/components/icons"

export const PropertyOwner24 = (props: IIconProps) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20H10.1C15.5657 19.9954 19.9954 15.5657 20 10.1V10ZM18.3333 10C18.3318 10.7407 18.2309 11.4778 18.0333 12.1917C18.0038 12.3081 17.9246 12.4056 17.8167 12.4583C17.7205 12.5129 17.607 12.5278 17.5 12.5C16.9839 12.2778 16.5438 11.91 16.2333 11.4417L14.3833 8.66667C13.9963 8.08815 13.346 7.7411 12.65 7.74167H12.2917C11.026 7.74167 10 6.71565 10 5.45C10 4.18435 11.026 3.15833 12.2917 3.15833H14.6C14.6865 3.15652 14.7713 3.18284 14.8417 3.23333C17.0282 4.7942 18.3282 7.31348 18.3333 10ZM2.225 8.125C2.02314 8.12229 1.84961 8.2675 1.81667 8.46667C1.71564 8.97149 1.66539 9.48516 1.66667 10C1.6822 13.3158 3.6621 16.3069 6.70833 17.6167C6.83144 17.679 6.97689 17.679 7.1 17.6167C7.22043 17.5413 7.29302 17.4087 7.29167 17.2667V16.575C7.29074 16.065 7.47763 15.5726 7.81667 15.1917L8.95 13.9167C9.97614 12.7627 9.92499 11.0089 8.83333 9.91667L7.89167 8.95833C7.34538 8.4153 6.60354 8.11496 5.83333 8.125H2.225Z"
            fill="currentColor"
        />
    </svg>
)
