import { t } from "@lingui/macro"
import { Autocomplete, TextField } from "@mui/material"
import { useMemo } from "react"

import { IAutoCompleteTextFieldProps } from "src/types/auto-complete-text-field"

export const AutoCompleteTextField = <
    T extends { id: string | number; name: string },
>(
    props: IAutoCompleteTextFieldProps<T>,
) => {
    const isMultiple = useMemo(
        () => props.multiSelect ?? true,
        [props.multiSelect],
    )
    const defaultValuesBasedOnMultiSelect = useMemo(
        () =>
            Boolean(isMultiple) ? props.defaultValues : props.defaultValues[0],
        [props.defaultValues, isMultiple],
    )

    const handleChange = (
        _: React.SyntheticEvent<Element, Event>,
        value: T[] | T | null,
    ) => {
        const updatedValue =
            value !== null ? (Array.isArray(value) ? value : [value]) : []
        props.onChange(updatedValue)
    }

    return (
        <Autocomplete
            multiple={isMultiple}
            disableCloseOnSelect={props.disableCloseOnSelect}
            limitTags={props.limitTags}
            data-testid="autocomplete-text-field"
            options={props.options}
            getOptionLabel={(option: T) => option.name}
            value={defaultValuesBasedOnMultiSelect}
            onChange={handleChange}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    placeholder={props.placeholder}
                    error={props?.error ?? false}
                    helperText={props?.helperText ?? ""}
                />
            )}
            loading={props.loading}
            loadingText={t`tags-component.loading`}
            sx={props.sx}
        />
    )
}
