import React, { useCallback } from "react"
import { Box } from "@mui/system"

import { InputBase, useTheme } from "@mui/material"

import { InputElement, boxContainerStyle } from "./styled"

import { IOtpProps } from "src/components/Inputs/types/IOtpProps"

import { numberRegex } from "src/lib/urlLinksHandler"

export const OTP = ({
    separator,
    length,
    value,
    onChange,
    isError,
    disabled,
}: IOtpProps) => {
    const theme = useTheme()

    const inputRefs = React.useRef<HTMLInputElement[]>(
        new Array(length).fill(null),
    )

    const focusInput = useCallback((targetIndex: number) => {
        inputRefs?.current[targetIndex]?.focus()
    }, [])

    const selectInput = useCallback((targetIndex: number) => {
        inputRefs?.current[targetIndex]?.select()
    }, [])

    const handleKeyDown = useCallback(
        (
            event: React.KeyboardEvent<HTMLInputElement>,
            currentIndex: number,
        ) => {
            const { key } = event
            switch (key) {
                case "ArrowUp":
                case "ArrowDown":
                case " ":
                    event.preventDefault()
                    break
                case "ArrowLeft":
                    event.preventDefault()
                    if (currentIndex > 0) {
                        focusInput(currentIndex - 1)
                        selectInput(currentIndex - 1)
                    }
                    break
                case "ArrowRight":
                    event.preventDefault()
                    if (currentIndex < length - 1) {
                        focusInput(currentIndex + 1)
                        selectInput(currentIndex + 1)
                    }
                    break
                case "Delete":
                    event.preventDefault()

                    onChange(
                        value.slice(0, currentIndex) +
                            value.slice(currentIndex + 1),
                    )

                    break
                case "Backspace":
                    event.preventDefault()
                    if (currentIndex > 0) {
                        focusInput(currentIndex - 1)
                        selectInput(currentIndex - 1)
                    }

                    onChange(
                        value.slice(0, currentIndex) +
                            value.slice(currentIndex + 1),
                    )
                    break

                default:
                    break
            }
        },
        [focusInput, length, onChange, selectInput, value],
    )

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, currentIndex: number) => {
            const currentValue = event.target.value
            let indexToEnter = 0

            while (indexToEnter <= currentIndex) {
                if (
                    inputRefs.current[indexToEnter].value.length > 0 &&
                    indexToEnter < currentIndex
                ) {
                    indexToEnter += 1
                } else {
                    break
                }
            }

            const otpArray = value.split("")
            const lastValue = currentValue[currentValue.length - 1]
            otpArray[indexToEnter] = lastValue
            onChange(otpArray.join(""))
            if (currentValue !== "") {
                if (currentIndex < length - 1) {
                    focusInput(currentIndex + 1)
                }
            }
        },
        [focusInput, length, onChange, value],
    )

    const handleClick = useCallback(
        (
            event: React.MouseEvent<HTMLInputElement, MouseEvent>,
            currentIndex: number,
        ) => {
            selectInput(currentIndex)
        },
        [selectInput],
    )

    const handlePaste = useCallback(
        (
            event: React.ClipboardEvent<HTMLInputElement>,
            currentIndex: number,
        ) => {
            event.preventDefault()
            const clipboardData = event.clipboardData

            // Check if there is text data in the clipboard
            if (clipboardData.types.includes("text/plain")) {
                let pastedText = clipboardData.getData("text/plain")
                pastedText = pastedText.substring(0, length).trim()
                let indexToEnter = 0

                while (indexToEnter <= currentIndex) {
                    if (
                        (inputRefs?.current[indexToEnter]?.value).length > 0 &&
                        indexToEnter < currentIndex
                    ) {
                        indexToEnter += 1
                    } else {
                        break
                    }
                }

                const otpArray = value?.split("") ?? []

                for (let i = indexToEnter; i < length; i += 1) {
                    const lastValue = pastedText[i - indexToEnter] ?? " "
                    otpArray[i] = lastValue
                }

                onChange(otpArray.join(""))
            }
        },
        [length, onChange, value],
    )

    return (
        <Box sx={boxContainerStyle}>
            {new Array(length).fill(null).map((_, index) => (
                <React.Fragment key={index}>
                    <InputBase
                        data-testid="otp-base-input"
                        disabled={disabled}
                        components={{
                            Input: InputElement,
                        }}
                        inputRef={(ele: HTMLInputElement) => {
                            inputRefs.current[index] = ele
                        }}
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                            if (numberRegex.test(event.target.value)) {
                                handleChange(event, index)
                            }
                        }}
                        componentsProps={{
                            input: {
                                style: {
                                    border: isError
                                        ? `1px solid ${theme.palette.error.main}`
                                        : `1px solid ${theme.palette.grey[500]}`,
                                },
                                onKeyDown: (
                                    event: React.KeyboardEvent<HTMLInputElement>,
                                ) => handleKeyDown(event, index),
                                onClick: (
                                    event: React.MouseEvent<
                                        HTMLInputElement,
                                        MouseEvent
                                    >,
                                ) => handleClick(event, index),
                                onPaste: (
                                    event: React.ClipboardEvent<HTMLInputElement>,
                                ) => handlePaste(event, index),
                                value: value[index] ?? "",
                            },
                        }}
                    />
                    {index === length - 1 ? null : separator}
                </React.Fragment>
            ))}
        </Box>
    )
}
