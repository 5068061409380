import { TextField, styled } from "@mui/material"

const ChatInputPositioner = styled("div")(() => ({
    position: "relative",
}))

const ChatInputImagesWrap = styled("div")({
    position: "absolute",
    bottom: "8px",
    right: "8px",
    left: "8px",
})

const ChatInputStyledTextField = styled(TextField, {
    shouldForwardProp: (name) => name !== "hasImages",
})<{ hasImages: boolean }>(({ hasImages }) => ({
    "& .MuiInputBase-root": {
        ...(hasImages ? { paddingBottom: "100px" } : {}),
    },
}))

export { ChatInputImagesWrap, ChatInputPositioner, ChatInputStyledTextField }
