import React from "react"

import { ContactFormV2AdminService, contact_Admin } from "src/api"

import { Channel } from "src/channel"
import { loads } from "src/channel/utils"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { parseStatusCasesV2 } from "src/helpers/parseStatusCasesV2"
import { parseDate } from "src/lib/date"

import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"

import {
    IAdvanceQueryModel,
    ISingleSelectOptions,
} from "src/types/data-grid-pro"
import { ICaseItem } from "src/views/cases-v2/type"

export class CasesViewStore implements IDisposable {
    static Context = React.createContext<CasesViewStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "loading", "getAssignees")

    //#region init variable
    private _accessGroupId: number = DEFAULT_ACCESS_GROUP.id
    private repositoryUpdatesListenerDisposer?: () => void
    private _assignees: ISingleSelectOptions[] = []

    unreads = {
        chats: true,
        forms: false,
    }

    caseLists = new Pagination(
        async (params) => {
            const response =
                await ContactFormV2AdminService.getV2AdminContactForm({
                    pageNumber: params.page,
                    pageSize: params.pageSize,
                    accessGroupId: this.getAccessGroupId(),
                })

            const items: ICaseItem[] = (response.result ?? []).map((item) => ({
                id: item.form_id ?? -1,
                title: item.title ?? "",
                object_address: item.object_address ?? "",
                object_id: item.object_id ?? "",
                tenant_id: item.tenant_id ?? -1,
                type_name: item.type_name ?? "",
                property_object_id: item.property_object_id ?? "",
                status: parseStatusCasesV2(item.status ?? ""),
                updated_at: parseDate(item.updated_at),
                created_at: parseDate(item.created_at),
                read:
                    item.admin_read_at !== null &&
                    item.admin_read_at !== undefined &&
                    this.readValidation(
                        item.admin_read_at as string,
                        item.last_activity_at,
                    ),
            }))

            return {
                items,
                count: response.total_count ?? 0,
            }
        },
        {
            loadingKey: CasesViewStore.LoadingKeys.loading,
        },
    )
    //#endregion

    //#region getters
    get assignees() {
        return this._assignees
    }

    private getAccessGroupId() {
        return this._accessGroupId !== DEFAULT_ACCESS_GROUP.id
            ? this._accessGroupId
            : undefined
    }
    //#endregion

    //#region setters
    private setAssignees(data: contact_Admin[]) {
        this._assignees = [...data].map((d) => ({
            value: d.admin_id ?? -1,
            label: d.name ?? "",
        }))
    }

    private setAccountGroupId(accessGroupId: number) {
        this._accessGroupId = accessGroupId
    }

    //#endregion

    //#region operations
    dispose() {
        this.repositoryUpdatesListenerDisposer?.()
    }

    @loads(() => CasesViewStore.LoadingKeys.init)
    async init(accessGroupId: number, advanceQuery?: IAdvanceQueryModel) {
        this.setAccountGroupId(accessGroupId)
        this.listenToCaseRepositoryUpdates()
        await this.caseLists.loadInitialPage(advanceQuery)
    }

    @loads(() => CasesViewStore.LoadingKeys.getAssignees)
    async getAssignees() {
        this.setAssignees(
            await ContactFormV2AdminService.getV2AdminContactFormAdmins({
                propertyId: -1,
            }),
        )
    }

    async query(advanceQuery: IAdvanceQueryModel) {
        await this.caseLists.loadAdvanceQuery(advanceQuery)
    }

    private listenToCaseRepositoryUpdates() {
        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "cases-v2"
                ) {
                    await this.caseLists.reload()
                }
            },
        )
    }
    //#endregion

    //#region helpers
    private readValidation = (
        adminRead: string,
        lastActivity: string | undefined,
    ) => {
        return (
            Date.parse(adminRead as string) > Date.parse(lastActivity as string)
        )
    }
}
