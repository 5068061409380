import { t } from "@lingui/macro"
import { makeAutoObservable } from "mobx"
import React from "react"

import { MixpanelProperties } from "src/analytics/constants/properties"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"

import {
    booking_CreateInterval,
    booking_Interval,
    booking_ResourceAttachment,
    booking_ResourceCreateUpdate,
    BookingAdminService,
    ConnectorAdminService,
} from "src/api"
import { Channel } from "src/channel"

import { loads } from "src/channel/utils"
import {
    convertIntervalsToWeekdayRanges,
    convertWeekdayRangesToIntervals,
    createDefaultWeekdayRanges,
    dayMinuteBounds,
    IDayOfWeek,
    IWeekdayRangesValue,
} from "src/components/WeekdayRangePicker/utils"
import { timeToMinutesFromMidnight } from "src/lib/date"
import { persistFiles } from "src/lib/file"

import { FormFields } from "src/lib/form-fields"
import { createLoadingKeys } from "src/lib/loading"
import { ResourceType } from "src/types/resource"
import { IIntegrationConnectorItem } from "src/views/integration-connector/type"

interface IFormFields {
    name: string
    address: string
    accessGroupId: number | null
    segmentIds: number[]
    maxBookingsPerApartment: number
    description: string
    bookingEnabled: boolean
    resourceTypeSelectedValue?: ResourceType
    url: string
    urlTitle: string
    weekdayRanges: IWeekdayRangesValue
    intervalLength: number
    imageAttachments: IFile[]
    documentAttachments: IFile[]
    parakeyEnabled: boolean
    accessType: string
    selectedConnectorId?: number
    digitalAccessEnabled: boolean
    publicResourceID?: string
    integrationType?: string
    connectorList?: IIntegrationConnectorItem[]
}

export class ResourceDetailStore {
    private readonly DEFAULT_INTERVAL_LENGTH = 15
    static Context = React.createContext<ResourceDetailStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "submit")

    private id?: number
    private repositoryUpdatesListenerDisposer?: () => void
    private hasAccessToParakeyModule?: boolean = false
    private hasAccessToAccessyModule?: boolean = false

    fields = new FormFields<IFormFields>({
        name: "",
        address: "",
        accessGroupId: null,
        segmentIds: [],
        maxBookingsPerApartment: Infinity,
        description: "",
        bookingEnabled: false,
        resourceTypeSelectedValue: ResourceType.BookingEngine,
        url: "",
        urlTitle: "",
        weekdayRanges: createDefaultWeekdayRanges(this.DEFAULT_INTERVAL_LENGTH),
        intervalLength: this.DEFAULT_INTERVAL_LENGTH,
        imageAttachments: [],
        documentAttachments: [],
        parakeyEnabled: false,
        accessType: "",
        selectedConnectorId: 0,
        digitalAccessEnabled: false,
        publicResourceID: "",
        integrationType: "",
    })

    private parakeyConnectorData: IIntegrationConnectorItem = {
        id: -1,
        accessGroupId: this.fields.get("accessGroupId") ?? -1,
        accessType: "",
        createdAt: "",
        deletedAt: "",
        integrationId: -1,
        integrationTypeId: -1,
        metaData: {
            base_url: "",
            client_id: "",
            client_secret: "",
            organization_id: "",
        },
        name: "Parakey",
        type: "parakey",
        updatedAt: "",
    }

    get isCreatingResource() {
        return this.id == null
    }

    constructor() {
        makeAutoObservable(this)
    }

    @loads(() => ResourceDetailStore.LoadingKeys.init)
    async init(accessGroupId: number, resourceId?: number) {
        this.listenToIntegrationConnectorRepositoryUpdated()
        if (resourceId != null) {
            this.setId(resourceId)
            const response =
                await BookingAdminService.getV1AdminBookingResource1({
                    resourceId,
                })

            const selectedConnectorId =
                response.parakey_enable != null &&
                response.parakey_enable === true
                    ? -1
                    : response.integration_connector_id ?? 0

            this.fields.init({
                name: response.name ?? "",
                address: response.address ?? "",
                segmentIds: response.segment_ids ?? [],
                accessGroupId: response.access_group_id ?? -1,
                maxBookingsPerApartment:
                    response.max_bookings_per_apartment ?? Infinity,
                description: response.description ?? "",
                bookingEnabled: response.booking_enabled ?? false,
                url: response.url ?? "",
                accessType: response.access_type ?? "",
                urlTitle: response.url_title ?? "",
                weekdayRanges: this.convertIntervalsToWeekdayRanges(
                    response.intervals ?? [],
                ),
                intervalLength: this.getIntervalLengthFromIntervals(
                    response.intervals ?? [],
                ),
                imageAttachments: this.toPersistedFiles(
                    response.attachments ?? [],
                    "image",
                ),
                documentAttachments: this.toPersistedFiles(
                    response.attachments ?? [],
                    "document",
                ),
                parakeyEnabled: response.parakey_enable ?? false,
                selectedConnectorId,
                resourceTypeSelectedValue:
                    response.resource_type as ResourceType,
                digitalAccessEnabled:
                    response.parakey_enable === true ||
                    (response.integration_type !==
                        "external_access_scheduler" &&
                        response.integration_connector_id !== null),
                publicResourceID:
                    response.integration_type === "accessy"
                        ? response.public_resource_id
                        : "",
                integrationType: response.integration_type ?? "",
            })
        } else {
            this.fields.set("accessGroupId", accessGroupId)
        }
    }

    async getAllIntegrations(
        type: string = "external_access_scheduler",
    ): Promise<{ items: IIntegrationConnectorItem[] }> {
        const response =
            await ConnectorAdminService.getV1AdminConnectorIntegration({
                type,
            })
        const items: IIntegrationConnectorItem[] =
            response.map((item) => ({
                id: item.integration_id as number,
                accessGroupId: item.access_group_id as number,
                accessType: item.access_type as string,
                createdAt: item.created_at as string,
                deletedAt: item.deleted_at as string,
                integrationId: item.integration_id as number,
                integrationTypeId: item.integration_type_id as number,
                metaData: item.metadata as Record<string, string>,
                name: item.name as string,
                type: item.type as string,
                updatedAt: item.updated_at as string,
            })) ?? []

        return {
            items,
        }
    }

    async getBookingsPortalIntegrations() {
        const response = await this.getAllIntegrations()
        this.fields.set("connectorList", response.items)
    }

    async getAccessyIntegrations() {
        this.setHasAccessToAccessyModule(true)
        const response = await this.getAllIntegrations("accessy")
        this.fields.set("connectorList", response.items)
    }

    @loads(() => ResourceDetailStore.LoadingKeys.submit)
    async submit(accessGroupName: string) {
        const { data } = this.fields

        if (data.accessGroupId == null) {
            this.fields.setErrors({ accessGroupId: t`errors.required` })
            return
        }
        this.validate()
        if (this.fields.hasErrors()) {
            return
        }

        const request: booking_ResourceCreateUpdate = {
            access_group_id: data.accessGroupId,
            address: data.address,
            booking_enabled: data.bookingEnabled,
            max_bookings_per_apartment:
                data.maxBookingsPerApartment ?? undefined,
            name: data.name,
            description: data.description,
            url: data.url,
            url_title: data.urlTitle,
            intervals:
                data.resourceTypeSelectedValue === ResourceType.Integration
                    ? []
                    : this.buildBookingIntervals(
                          data.weekdayRanges,
                          data.intervalLength,
                      ),
            attachments: this.toResourceAttachments([
                ...(await persistFiles(data.documentAttachments, "document")),
                ...(await persistFiles(data.imageAttachments, "image")),
            ]),
            parakey_enabled: data.parakeyEnabled,
            integration_connector_id: data.selectedConnectorId,
            public_resource_id: data.publicResourceID,
        }

        if ((request.integration_connector_id as number) <= 0) {
            delete request.integration_connector_id
        }

        await this.fields.catchErrors(async () => {
            const action = this.id == null ? "create" : "update"

            if (this.id == null) {
                const response =
                    await BookingAdminService.postV1AdminBookingResource({
                        request,
                    })
                trackModuleEvent(" Bookings | Create new bookable resource", {
                    [MixpanelProperties.ItemID]: response.resource_id,
                    [MixpanelProperties.ItemName]: response.name,
                    [MixpanelProperties.AccessGroupName]: accessGroupName,
                    [MixpanelProperties.ResourceType]:
                        response.integration_connector_id !== null
                            ? ResourceType.Integration
                            : ResourceType.BookingEngine,
                })
                // TODO: removed this type assertion as soon as the swagger
                // is correctly typed.
                this.setId(response.resource_id as number)
            } else {
                await BookingAdminService.patchV1AdminBookingResource({
                    request,
                    resourceId: this.id,
                })
            }

            if (this.id != null) {
                await BookingAdminService.putV1AdminBookingResourcePublish({
                    request: { published_in: data.segmentIds },
                    resourceId: this.id,
                })

                Channel.send({
                    name: "repository/updated",
                    payload: {
                        repository: "resources",
                        action,
                        item: { id: this.id, name: data.name },
                    },
                })
            } else {
                this.fields.setErrors({
                    segmentIds: t`resource-detail-modal.could-not-publish-error`,
                })
                throw new Error(
                    "Couldn't save the selected segments because no somehow no resource id was selected. This shouldn't be possible.",
                )
            }
        })
    }

    addParakeyConnectorToList() {
        this.setHasAccessToParakeyModule(true)
        const existingList = this.fields.get("connectorList")
        existingList !== undefined
            ? this.fields.set("connectorList", [
                  ...existingList,
                  this.parakeyConnectorData,
              ])
            : this.fields.set("connectorList", [this.parakeyConnectorData])
    }

    resetDigitalAccessSection = () => {
        this.fields.set("selectedConnectorId", 0)
        this.fields.set("parakeyEnabled", false)
        this.fields.set("publicResourceID", "")
        this.fields.get("resourceTypeSelectedValue") ===
            ResourceType.Integration &&
            this.fields.set("digitalAccessEnabled", false)
    }

    setHasAccessToAccessyModule = (value: boolean) => {
        this.hasAccessToAccessyModule = value
    }

    setHasAccessToParakeyModule = (value: boolean) => {
        this.hasAccessToParakeyModule = value
    }

    dispose() {
        this.repositoryUpdatesListenerDisposer?.()
    }

    private toPersistedFiles(
        resourceAttachments: booking_ResourceAttachment[],
        type: "image" | "document",
    ) {
        return resourceAttachments
            .filter((attachment) => attachment.attachment_type === type)
            .map((attachment) => this.toPesistedFile(attachment, type))
    }

    private toPesistedFile(
        resourceAttachments: booking_ResourceAttachment,
        type: "image" | "document",
    ): IPersistedFile {
        return {
            name: resourceAttachments.name ?? "",
            url: resourceAttachments.url ?? "",
            type,
        }
    }

    private toResourceAttachments(
        files: IPersistedFile[],
    ): booking_ResourceAttachment[] {
        return files.map((file) => ({
            name: file.name,
            url: file.url,
            attachment_type: file.type,
        }))
    }

    private setId(id: number) {
        this.id = id
    }

    private convertIntervalsToWeekdayRanges(intervals: booking_Interval[]) {
        // These values cannot be nullish and weekday cannot be outside of 0-6 they're just wrongly typed.
        // Ignore this interval if they are.
        const validIntervals = intervals.filter(
            (
                interval,
            ): interval is NonNullableMap<booking_Interval> & {
                weekday: IDayOfWeek
            } =>
                interval.start_time != null &&
                interval.end_time != null &&
                interval.weekday != null &&
                interval.weekday >= 0 &&
                interval.weekday <= 6,
        )

        const intervalsInput = validIntervals.map((interval) => {
            const startTime = timeToMinutesFromMidnight(
                this.transformTimestampsFromISOFormat(interval.start_time),
            )
            const endTime = this.endTimeToMinutesFromMidnight(
                this.transformTimestampsFromISOFormat(interval.end_time),
            )
            return {
                startTime,
                endTime,
                dayOfWeek: interval.weekday,
            }
        })

        return convertIntervalsToWeekdayRanges(intervalsInput)
    }

    private getIntervalLengthFromIntervals(intervals: booking_Interval[]) {
        // Interval length isn't stored, so it can only be inferred from the
        // intervals. If there are no intervals the interval length will be set
        // to the default one.
        if (intervals.length === 0) {
            return this.DEFAULT_INTERVAL_LENGTH
        }

        const { start_time: startTime, end_time: endTime } = intervals[0]

        // These values should never be nullish they're just wrongly typed.
        if (startTime == null || endTime == null) {
            return this.DEFAULT_INTERVAL_LENGTH
        }

        return (
            this.endTimeToMinutesFromMidnight(
                this.transformTimestampsFromISOFormat(endTime),
            ) -
            timeToMinutesFromMidnight(
                this.transformTimestampsFromISOFormat(startTime),
            )
        )
    }

    private buildBookingIntervals(
        weekdayRanges: IWeekdayRangesValue,
        intervalLength: number,
    ): booking_CreateInterval[] {
        const intervals = convertWeekdayRangesToIntervals(
            weekdayRanges,
            intervalLength,
        )

        return intervals.map((interval) => ({
            weekday: interval.dayOfWeek,
            start_time: this.minutesToIntervalTimestamp(
                interval.startTime,
                "start",
            ),
            end_time: this.minutesToIntervalTimestamp(interval.endTime, "end"),
        }))
    }

    private minutesToIntervalTimestamp(
        minutes: number,
        point: "start" | "end",
    ) {
        if (minutes < dayMinuteBounds.min || minutes > dayMinuteBounds.max) {
            throw new Error(
                `Input of ${this.minutesToIntervalTimestamp.name} is out-of-bounds. Must be between ${dayMinuteBounds.min} and ${dayMinuteBounds.max}, got ${minutes}`,
            )
        }

        // 00:00 not 24:00 represents the end-of-day for end time.
        if (point === "end" && minutes === dayMinuteBounds.max) {
            return "00:00"
        }

        const zeroedHours = `0${Math.floor(minutes / 60)}`.slice(-2)
        const zeroedMinutes = `0${minutes % 60}`.slice(-2)
        return `${zeroedHours}:${zeroedMinutes}`
    }

    /**
     * transformTimestampsFromISOFormat transforms "timestamps" (really
     * datetime but only the time portion is set to a value) in ISO format to
     * HH:mm.
     *
     * This is necessary when receiving interval timestamps from the api as
     * they're formatted as ISO for some technical reason.
     * @param time Time formatted as either 00:00 or 0000-00-00T00:00:00.000Z
     * @returns Time formatted as 00:00
     */
    private transformTimestampsFromISOFormat(time: string) {
        if (time.includes("T")) {
            const timeAsDate = new Date(time)
            const hours = `0${timeAsDate.getUTCHours()}`.slice(-2)
            const minutes = `0${timeAsDate.getUTCMinutes()}`.slice(-2)
            return `${hours}:${minutes}`
        }

        return time
    }

    /**
     * endTimeToMinutesFromMidnight mitigates a quirky behaviour of the api
     * where 00:00 in the end time field represents the end of the day instead
     * of the start of the day.
     *
     * @param endTime End time as a string in HH:mm format.
     * @returns End time as minutes from midnight.
     */
    private endTimeToMinutesFromMidnight(endTime: string) {
        const minutes = timeToMinutesFromMidnight(endTime)
        return minutes === 0 ? dayMinuteBounds.max : minutes
    }

    private validate() {
        if (this.fields.get("digitalAccessEnabled")) {
            if (this.fields.get("selectedConnectorId") === 0) {
                this.fields.setError("selectedConnectorId", t`errors.required`)
            } else if (
                // in case of accessy integrationUuid should not be blank
                this.fields.get("selectedConnectorId") !== 0 &&
                this.fields.get("parakeyEnabled") !== true &&
                this.fields.get("publicResourceID") === ""
            ) {
                this.fields.setError("publicResourceID", t`errors.required`)
            }
        }
    }

    private async updateFunction() {
        switch (this.fields.get("resourceTypeSelectedValue")) {
            case ResourceType.BookingEngine:
                this.hasAccessToAccessyModule === true &&
                    (await this.getAccessyIntegrations())
                this.hasAccessToParakeyModule === true &&
                    this.addParakeyConnectorToList()
                break

            case ResourceType.Integration:
                await this.getBookingsPortalIntegrations()
                break

            default:
                break
        }
    }

    private listenToIntegrationConnectorRepositoryUpdated() {
        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "integration-connector"
                ) {
                    await this.updateFunction()
                    this.fields.set(
                        "selectedConnectorId",
                        event.payload.item?.id,
                    )
                }
            },
        )
    }
}
