import { IntegrationConnectorItemStore, IFormFields } from "./store"
import * as formFields from "./form-fields"
import {
    IntegrationConnectorItem,
    IIntegrationConnectorItemProps,
} from "./IntegrationConnectorItem"

import { StoreProvider } from "src/store/lib/StoreProvider"

const IntegrationConnectorItemModal = (
    props: IIntegrationConnectorItemProps,
) => (
    <StoreProvider Store={IntegrationConnectorItemStore}>
        <IntegrationConnectorItem {...props} />
    </StoreProvider>
)

export {
    IntegrationConnectorItemModal,
    IntegrationConnectorItemStore,
    formFields,
}

export type { IFormFields }
