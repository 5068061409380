export enum GeneralCategoriesFieldsTypes {
    Text = "text",
    File = "file",
    Dropdown = "select",
}
export enum EmbeddedIntegrationSettingType {
    Simple = "simple",
    Subscription = "subscription",
}

export enum EmbeddedIntegrationPurchaseTabCardType {
    Rich = "rich",
    Simple = "simple",
    Collapsible = "collapsible",
}
