import {
    styled,
    Tooltip as MUITooltip,
    tooltipClasses,
    TooltipProps,
} from "@mui/material"

export const Tooltip = styled<(props: TooltipProps) => JSX.Element>(
    ({ className, ...props }) => (
        <MUITooltip {...props} classes={{ popper: className }} />
    ),
)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        background: theme.palette.common.white,
        color: theme.palette.grey[900],
        fontSize: "10px",
        fontWeight: 500,
        filter: "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1))",
        maxWidth: "180px",
        cursor: "default",
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white,
    },
}))
