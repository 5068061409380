import toSnakecase from "lodash.snakecase"
import toCamelcase from "lodash.camelcase"

import { t } from "@lingui/macro"

import { ApiError } from "src/api"

type IApiErrorBody = { errors: { field: string; error: string }[] }
type IErrorMap = { [key: symbol | number | string]: string }

function isApiErrorBody(body: unknown): body is IApiErrorBody {
    return (
        body instanceof Object &&
        "errors" in body &&
        Array.isArray(body["errors"])
    )
}

function translateErrorMessage(message: string) {
    switch (message) {
        case "required":
            return t`errors.required`
        case "max":
            return t`errors.too-long`
        default:
            return message
    }
}

/**
 * `apiErrorToMap` extracts field errors from an ApiError. The returned map of
 * errors will contain both the snakecase and the camelcase version of the key.
 *
 * @param apiError Error exception straight from the API
 * @returns Map of errors
 */
export const apiErrorToMap = (apiError: ApiError): IErrorMap => {
    if (isApiErrorBody(apiError.body)) {
        return apiError.body.errors.reduce((acc: IErrorMap, error) => {
            acc[toSnakecase(error.field)] = translateErrorMessage(error.error)
            acc[toCamelcase(error.field)] = translateErrorMessage(error.error)
            return acc
        }, {} as IErrorMap)
    }

    return {}
}

/**
 * Extracts the error message from an ApiError exceptions. These errors aren't
 * documented in the api so this extracts errors on a best-effort basis.
 *
 * @param apiError Error exception straight from the API
 * @returns The error message as a string
 */
export function unknownApiErrorToGenericError(apiError: ApiError): string {
    const body = apiError.body

    if (body == null) {
        return ""
    }

    if (typeof body === "object") {
        if ("status" in body) {
            return body.status
        } else if ("error" in body) {
            return body.error
        } else if ("message" in body) {
            return body.message
        }
        return JSON.stringify(body)
    }

    return String(body)
}
