import { IIconProps } from "src/components/icons"

export const ArrowDropDownRoundedUp24 = (props: IIconProps) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ transform: "rotate(270deg)" }}
        {...props}
    >
        <path
            d="M8.70999 11.71L11.3 14.3C11.69 14.69 12.32 14.69 12.71 14.3L15.3 11.71C15.93 11.08 15.48 10 14.59 10H9.40999C8.51999 10 8.07999 11.08 8.70999 11.71Z"
            fill="currentColor"
        />
    </svg>
)
