import { t } from "@lingui/macro"
import { observer } from "mobx-react"

import { IStatusChipProps, StatusChip } from "src/components/StatusChip"
import { formatDate } from "src/lib/date"

interface IProps {
    date: Date | null
}

export const ContactFormImplementedChip = observer(({ date }: IProps) => {
    if (date == null) {
        return null
    }

    const chipProps = (): IStatusChipProps => {
        if (date < new Date()) {
            return {
                state: "positive",
                label: formatDate(date),
            }
        } else {
            return {
                state: "neutral",
                label: t({
                    id: `contact-forms-view.forms-tab.implemented-chip-booked-status`,
                    values: { date: formatDate(date) },
                }),
            }
        }
    }

    return <StatusChip {...chipProps()} />
})
