import { Stack, styled } from "@mui/material"
import { observer } from "mobx-react"
import { forwardRef } from "react"

import { EditCommentBody } from "./Comment/EditCommentBody"

import { Avatar } from "src/components/Avatar"
import {
    IComment,
    ViewCommunityPostStore,
} from "src/modals/view-community-post/store"
import { useStore } from "src/store/lib/useStore"
import { ViewCommentBody } from "src/modals/view-community-post/Post/Comment/ViewCommentBody"

const AvatarPosition = styled("div")({ marginTop: "4px" })

interface IProps {
    comment: IComment
}

export const Comment = observer(
    forwardRef((props: IProps, ref) => {
        const store = useStore(ViewCommunityPostStore)

        const isEditing = store.isEditingComment(props.comment.id)

        return (
            <Stack ref={ref} direction="row" spacing={1}>
                <AvatarPosition>
                    <Avatar size={32} name={props.comment.authorName} />
                </AvatarPosition>
                {isEditing ? (
                    <EditCommentBody comment={props.comment} />
                ) : (
                    <ViewCommentBody comment={props.comment} />
                )}
            </Stack>
        )
    }),
)
