import { makeAutoObservable } from "mobx"
import { createContext } from "react"

import { EmbedAdminService } from "src/api"
import { CustomDataHelper } from "src/api/_custom/services/DataHelper"

import { Channel } from "src/channel"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"
import { IEmbedIntegrationFormItemProps } from "src/views/embedded-integrations-v2/typed"
import { IAdvanceQueryModel } from "src/types/data-grid-pro"

export class EmbedIntegrationStore {
    _accessGroupId: number = DEFAULT_ACCESS_GROUP.id
    static Context = createContext<EmbedIntegrationStore | null>(null)
    static LoadingKeys = createLoadingKeys("loading")
    private repositoryUpdatesListenerDisposer?: () => void
    _segments: number[] = []

    embedIntegrations = new Pagination(
        async (query) => {
            const response =
                await EmbedAdminService.postV1AdminEmbedIntegrationList({
                    request: {
                        page_number: query.page,
                        page_size: query.pageSize,
                        filter: query.advanceQuery,
                        ...CustomDataHelper.PrepareVisibilityData(this),
                    },
                })

            const items: IEmbedIntegrationFormItemProps[] =
                response.integrations?.map((embed) => ({
                    id: embed.id ?? 0,
                    internalName: embed.name ?? "",
                    partner: embed.partner_name ?? "",
                    salesData: embed.sales_data,
                    type: embed.type ?? "",
                    categories: embed.categories ?? [],
                    segments: embed.segments ?? [],
                })) ?? []

            return {
                items: items,
                count: response.total_count ?? items?.length ?? 0,
            }
        },
        { loadingKey: EmbedIntegrationStore.LoadingKeys.loading },
    )

    constructor() {
        makeAutoObservable(this)
    }
    get segments() {
        return this._segments
    }
    async loadSegments(segments: number[]) {
        this.setSegments(segments)
        await this.embedIntegrations.loadInitialPage()
    }

    async query(advanceQuery: IAdvanceQueryModel) {
        await this.embedIntegrations.loadAdvanceQuery(advanceQuery)
    }

    async init(accessGroupId: number, advanceQuery?: IAdvanceQueryModel) {
        this._accessGroupId = accessGroupId
        await this.embedIntegrations.loadInitialPage(advanceQuery)

        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "embed-integrations"
                ) {
                    await this.embedIntegrations.reload()
                }
            },
        )
    }

    dispose() {
        this.repositoryUpdatesListenerDisposer?.()
    }
    private setSegments(segments: number[]) {
        this._segments = segments
    }
}
