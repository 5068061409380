import { t } from "@lingui/macro"
import { makeAutoObservable } from "mobx"
import React from "react"

import { InfoCenterAdminService, TagsAdminService, tags_Tag } from "src/api"
import { CustomDataHelper } from "src/api/_custom/services/DataHelper"
import { Channel } from "src/channel"
import { loads } from "src/channel/utils"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"
import { reportError } from "src/lib/report"
import { IAdvanceQueryModel } from "src/types/data-grid-pro"
import { ILibraryInformationItem } from "src/types/library"

export class LibraryInformationItemsStore {
    static Context = React.createContext<LibraryInformationItemsStore | null>(
        null,
    )
    static LoadingKeys = createLoadingKeys("init")
    _accessGroupId: number = DEFAULT_ACCESS_GROUP.id
    private repositoryUpdatesListenerDisposer?: () => void
    _segments: number[] = []
    _tags: tags_Tag[] | undefined

    informationItems = new Pagination(async (query) => {
        const response = await InfoCenterAdminService.infocenterGetItemListPost(
            {
                request: {
                    page_number: query.page,
                    page_size: query.pageSize,
                    filter: query.advanceQuery,
                    ...CustomDataHelper.PrepareVisibilityData(this),
                },
            },
        )

        const items: ILibraryInformationItem[] =
            response.result?.map((result) => ({
                ...result,
                id: result.information_item_id as number,
                rank: result.rank ?? 0,
            })) ?? []

        return {
            items: items,
            count: response.total_count ?? items?.length ?? 0,
        }
    })

    get segments() {
        return this._segments
    }

    get tags() {
        return this._tags
    }

    constructor() {
        makeAutoObservable(this)
    }

    async getTags() {
        const response = await TagsAdminService.postV1AdminTagList({
            request: { page_size: 10000 },
        })

        return response.tags
    }

    @loads(() => LibraryInformationItemsStore.LoadingKeys.init)
    async init(accessGroupId: number, advanceQuery?: IAdvanceQueryModel) {
        this._accessGroupId = accessGroupId
        await this.informationItems.loadInitialPage(advanceQuery)
        this._tags = await this.getTags()

        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "library-information-items"
                ) {
                    await this.informationItems.reload()
                }
            },
        )
    }

    async loadSegments(segments: number[]) {
        this.setSegments(segments)
        await this.informationItems.loadInitialPage()
    }

    dispose() {
        this.repositoryUpdatesListenerDisposer?.()
    }

    deleteItem = async (informationItemId: number) => {
        try {
            await InfoCenterAdminService.infocenterDeleteItem({
                informationItemId: informationItemId,
            })
        } catch (error) {
            reportError(
                t`library-information-items-view.delete-item-fail`,
                error,
            )
        } finally {
            await this.informationItems.reload()
        }
    }

    unpublishItem = async (informationItemId: number) => {
        try {
            await InfoCenterAdminService.infocenterPublish({
                informationItemId: informationItemId,
                request: {
                    published_in: [],
                },
            })

            await this.informationItems.reload()
        } catch (error) {
            reportError(
                t`library-information-items-view.unpublish-item-fail`,
                error,
            )
        }
    }

    private setSegments(segments: number[]) {
        this._segments = segments
    }

    async query(advanceQuery: IAdvanceQueryModel) {
        await this.informationItems.loadAdvanceQuery(advanceQuery)
    }

    async getItem(id: number) {
        const response = await InfoCenterAdminService.infocenterGetItem({
            informationItemId: id,
        })

        return {
            ...response,
            id: response.information_item_id as number,
            rank: response.rank ?? 0,
        }
    }
}
