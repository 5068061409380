/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class DashboardAdminService {
    /**
     * Get master metric dashboard link
     * Returns master metric dashboard link
     * @returns string OK
     * @throws ApiError
     */
    public static getV1AdminDashboardLink(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/dashboard/link",
        })
    }

    /**
     * Get dashboard link by dashboard ID
     * Returns dashboard link by dashboard ID
     * @returns string OK
     * @throws ApiError
     */
    public static getV1AdminDashboardLink1({
        dashboardId,
    }: {
        /** Dashboard ID **/
        dashboardId: string
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/dashboard/{dashboard_id}/link",
            path: {
                dashboard_id: dashboardId,
            },
        })
    }
}
