import { observer } from "mobx-react"
import { Grid, Typography, useTheme, Theme } from "@mui/material"
import { t } from "@lingui/macro"

import { EditProductStore } from "src/views/product-catalogue/edit/store"

import { useStore } from "src/store/lib/useStore"
import { CustomPaper } from "src/views/product-catalogue/edit"
import { AdminName } from "src/views/product-catalogue/edit/form-elements/AdminName"
import { Time } from "src/components/Time"

export const FirstSection = observer(() => {
    const store = useStore(EditProductStore)
    const theme = useTheme()
    const productReadyState = getProductReadyState(
        theme,
        store.product.productState,
        store.product.publishedIn,
    )
    const createdBy =
        store.admins.createdBy?.name ?? store.product.createdBy ?? ""
    const updatedBy =
        store.admins.updatedBy?.name ?? store.product.updatedBy ?? ""
    const createdAt = store.product.createdAt
    const updatedAt = store.product.updatedAt

    return (
        <>
            <Typography
                fontSize="14px"
                sx={{
                    display: { xs: "block", lg: "none" },
                    marginTop: "20px",
                    marginBottom: "-20px",
                }}
                style={{
                    backgroundColor: productReadyState.color,
                    borderRadius: "8px 8px 0 0",
                    padding: "16px",
                }}
            >
                <span style={{ opacity: "0.6", marginRight: "1em" }}>
                    {t`edit-product-view.status`}
                </span>{" "}
                <span style={{ fontWeight: 700 }}>
                    {productReadyState.text}
                </span>
            </Typography>
            <CustomPaper>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} lg={3}>
                        <Typography
                            component="h3"
                            fontSize="14px"
                            fontWeight="700"
                            sx={{ mb: 2 }}
                        >
                            {t`edit-product-view.product-name`}
                        </Typography>
                        <AdminName />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={2}>
                        <Typography
                            component="h3"
                            fontSize="14px"
                            fontWeight="700"
                        >
                            {t`edit-product-view.avy-product-id`}
                        </Typography>
                        <Typography fontSize="14px">
                            {store.product.productId ?? "-"}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={4} lg={2}>
                        <Typography
                            component="h3"
                            fontSize="14px"
                            fontWeight="700"
                        >
                            {t`edit-product-view.created`}
                        </Typography>
                        <Typography fontSize="14px">
                            {createdAt != null ? (
                                <>
                                    <Time date={createdAt} />
                                    {createdBy != null && (
                                        <>
                                            <br />
                                            {t`edit-product-view.by`}{" "}
                                            {createdBy}
                                        </>
                                    )}
                                </>
                            ) : (
                                "-"
                            )}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={4} lg={2}>
                        <Typography
                            component="h3"
                            fontSize="14px"
                            fontWeight="700"
                        >
                            {t`edit-product-view.last-saved`}
                        </Typography>
                        <Typography fontSize="14px">
                            {updatedAt != null ? (
                                <>
                                    <Time date={updatedAt} />
                                    {updatedBy != null && (
                                        <>
                                            <br />
                                            {t`edit-product-view.by`}{" "}
                                            {updatedBy}
                                        </>
                                    )}
                                </>
                            ) : (
                                "-"
                            )}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={4}
                        lg={3}
                        sx={{
                            display: { xs: "none", lg: "block" },
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: productReadyState.color,
                                borderRadius: "4px",
                                height: "100%",
                                padding: "16px",
                                boxSizing: "border-box",
                            }}
                        >
                            <Typography
                                component="h3"
                                fontSize="14px"
                                fontWeight="700"
                                style={{ opacity: "0.6" }}
                            >
                                {t`edit-product-view.status`}
                            </Typography>
                            <Typography fontSize="24px">
                                {productReadyState.text}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </CustomPaper>
        </>
    )
})

const getProductReadyState = (
    theme: Theme,
    productState?: string,
    publishedIn?: number[],
) => {
    if (productState === "draft") {
        return {
            text: t`edit-product-view.draft-incomplete`,
            color: theme.palette.grey[300],
        }
    }

    if (productState === "ready") {
        if (publishedIn != null && publishedIn.length > 0) {
            return {
                text: t`edit-product-view.published`,
                color: theme.palette.utility.appleFaded.main,
            }
        }

        return {
            text: t`edit-product-view.ready-for-publish`,
            color: theme.palette.utility.orangeFaded.main,
        }
    }

    return {
        text: "-",
        color: theme.palette.grey[300],
    }
}
