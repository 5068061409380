import { observer } from "mobx-react"

import { MultiLanguageTextField } from "src/components/Inputs/TextFields/MultiLanguageTextField"
import { getSectionFieldName } from "src/components/ConfigurableDynamicFormFields/helpers/fieldNameHelpers"
import { IMultiLanguageTextFieldValue } from "src/types/modal-text-field"
import { ModalTextField } from "src/components/ModalTextField"
import { ConfigurableDynamicFormFieldsStore } from "src/components/ConfigurableDynamicFormFields/store"
import { useStore } from "src/store/lib/useStore"
import { IConfigurableDynamicFormFieldsTextFieldProps } from "src/components/ConfigurableDynamicFormFields/types/form-field"

export const ConfigurableDynamicFormFieldsTextField = observer(
    ({
        section,
        field,
        index,
    }: IConfigurableDynamicFormFieldsTextFieldProps) => {
        const store = useStore(ConfigurableDynamicFormFieldsStore)
        // fields value can also be an array of data, incase there's field.length attribute
        const _formFieldKey = getSectionFieldName(section, field.name, index)

        const textFieldType: React.InputHTMLAttributes<unknown>["type"] =
            field.type === "integer" ? "number" : undefined
        const textFieldAttributes =
            field.type === "textarea"
                ? { rows: 3, maxLength: 400 }
                : { rows: 1, maxLength: 150 }

        return (
            <>
                {field.localised === true ? (
                    <MultiLanguageTextField
                        label={field.label}
                        fieldName={_formFieldKey}
                        data={
                            store.form.get(_formFieldKey) as
                                | IMultiLanguageTextFieldValue
                                | undefined // in case fo create new
                        }
                        onChange={store.form.setter(_formFieldKey)}
                        onSecondaryLanguageChange={store.form.setter(
                            _formFieldKey,
                        )}
                        helperText={store.form.error(_formFieldKey)}
                        error={Boolean(store.form.error(_formFieldKey))}
                        disabled={store.isReadOnly}
                        tooltipText={field.tooltip_key ?? ""}
                        rows={textFieldAttributes.rows}
                        maxLength={textFieldAttributes.maxLength}
                    />
                ) : (
                    <ModalTextField
                        variant="withToolTip"
                        label={field.label}
                        value={store.form.get(_formFieldKey) as string | number}
                        onChange={store.form.setter(_formFieldKey)}
                        helperText={store.form.error(_formFieldKey)}
                        error={Boolean(store.form.error(_formFieldKey))}
                        disabled={store.isReadOnly}
                        tooltipText={field.tooltip_key ?? ""}
                        rows={1}
                        type={textFieldType}
                    />
                )}
            </>
        )
    },
)
