import { styled } from "@mui/material"

const ChatInputImage = styled("img")(({ theme }) => ({
    display: "block",
    width: "80px",
    height: "80px",
    objectFit: "cover",
    borderRadius: "4px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.grey[500],
}))

const ChatInputDeleteButton = styled("button")({
    width: "16px",
    height: "16px",
    background: "#333",
    color: "#fff",
    borderRadius: "1000px",
    position: "absolute",
    top: "-8px",
    right: "-8px",
    border: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "12px",
    cursor: "pointer",
})

const ChatInputCell = styled("div")({ position: "relative" })

export { ChatInputImage, ChatInputDeleteButton, ChatInputCell }
