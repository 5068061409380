import { observer } from "mobx-react"
import { useCallback } from "react"
import { Button, Box, DialogActions, DialogTitle } from "@mui/material"
import { t } from "@lingui/macro"
import { useLocation, useNavigate } from "react-router-dom"

import {
    EditProductStore,
    IFormFields,
} from "src/views/product-catalogue/edit/store"

import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"

export const Buttons = observer(() => {
    const navigate = useNavigate()
    const location = useLocation()
    const store = useStore(EditProductStore)
    const globalStore = useStore(GlobalStore)
    const formIsDirty = store.formFields.getIsDirty()

    const publishedIn = store.formFields.get("publishedIn")
    const isPublished =
        store.product.publishedIn !== undefined &&
        store.product.publishedIn.length > 0
    const canBePublished =
        publishedIn != null &&
        publishedIn.length > 0 &&
        store.product.publishedIn !== publishedIn

    const validateRequiredFields = useCallback(
        (requiredFields: string[]) => {
            store.formFields.clearErrors()

            for (const field of requiredFields) {
                const requiredField = field as keyof IFormFields
                let formField = store.formFields.get(requiredField)
                if (
                    typeof formField === "string" ||
                    typeof formField === "number"
                ) {
                    formField = formField.toString().trimStart()
                }

                if (formField == null || formField === "") {
                    store.formFields.setError(requiredField, t`errors.required`)
                } else {
                    store.formFields.clearError(requiredField)
                }
            }
        },
        [store.formFields],
    )

    const scrollToElement = useCallback(() => {
        // Scroll to first error field
        // Let the DOM update before looking for classname
        setTimeout(() => {
            const errorElements = document.getElementsByClassName("Mui-error")

            if (errorElements.length > 0) {
                errorElements[0].scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                })
            }
        })
    }, [])

    const getRequiredFieldsForValidation = useCallback(() => {
        const commonFields = [
            "providerId",
            "productType",
            "name",
            "orderOutputId",
            "productDescription",
            "productListSubtitle",
            "orderDeliveryProcess",
            "mainImageUrl",
        ]
        const categoryIdField = ["categoryId"]
        const priceFields = ["includedInPrice", "priceCents", "pricePlan"]

        switch (store.formFields.get("productType")) {
            case "purchase":
                return [...commonFields, ...categoryIdField, ...priceFields]

            case "standard_improvement_single":
                return [...commonFields, ...priceFields]

            default:
                return [...commonFields, ...categoryIdField]
        }
    }, [store.formFields])

    const checkValidation = useCallback(() => {
        validateRequiredFields(getRequiredFieldsForValidation())
    }, [validateRequiredFields, getRequiredFieldsForValidation])

    const publishProducts = useCallback(
        async (event: React.FormEvent<HTMLButtonElement>) => {
            event.preventDefault()

            checkValidation()
            if (store.formFields.hasErrors()) {
                scrollToElement()
                return
            }
            trackModuleEvent("Products | Publish Product", {
                [MixpanelProperties.AccessGroupName]:
                    globalStore.session.accessGroup?.name,
                [MixpanelProperties.AccessGroupID]:
                    globalStore.session.accessGroup?.id,
                [MixpanelProperties.ItemID]: store.product.productId,
                [MixpanelProperties.ItemName]: store.product.name,
            })
            if (store.product.productId == null) {
                await store.createProduct(globalStore.session.accessGroupId)

                await store.publishProduct()
            } else {
                await store.publishProduct()
            }

            if (
                store.product.publishedIn != null &&
                store.product.publishedIn.length > 0
            ) {
                store.setSnackbar({
                    message: t`edit-product-view.success-published`,
                })
            }

            if (store.product.productId != null) {
                navigate("/product-catalogue/products/", { replace: true })
            }
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            globalStore.session.accessGroupId,
            store,
            navigate,
            checkValidation,
            scrollToElement,
        ],
    )

    const saveProduct = useCallback(
        async (event: React.FormEvent<HTMLButtonElement>) => {
            event.preventDefault()

            validateRequiredFields(["adminName", "providerId", "productType"])
            if (store.formFields.hasErrors()) {
                scrollToElement()
                return
            }
            if (
                store.product.productId == null ||
                store.product.productId === undefined
            ) {
                trackModuleEvent("Products | Save Draft", {
                    [MixpanelProperties.AccessGroupName]:
                        globalStore.session.accessGroup?.name,
                    [MixpanelProperties.AccessGroupID]:
                        globalStore.session.accessGroup?.id,
                    [MixpanelProperties.ItemID]: store.product.productId,
                    [MixpanelProperties.ItemName]: store.product.name,
                    [MixpanelProperties.ProductTypeName]:
                        store.product.productType,
                })
                await store.createProduct(globalStore.session.accessGroupId)
                navigate("/product-catalogue/products")
            } else {
                checkValidation()
                // When saving, only in case of published product we need to validate the required fields
                if (isPublished && store.formFields.hasErrors()) {
                    scrollToElement()
                    return
                }
                trackModuleEvent("Products | Save Changes", {
                    [MixpanelProperties.AccessGroupName]:
                        globalStore.session.accessGroup?.name,
                    [MixpanelProperties.AccessGroupID]:
                        globalStore.session.accessGroup?.id,
                    [MixpanelProperties.ItemID]: store.product.productId,
                })
                await store.updateProduct(globalStore.session.accessGroupId)
                navigate("/product-catalogue/products")
            }

            if (!store.formFields.hasErrors()) {
                store.setSnackbar({
                    message: t`edit-product-view.success-saved`,
                })

                if (store.product.productId != null) {
                    const editPathname = `/product-catalogue/products/${store.product.productId}`

                    if (editPathname !== location.pathname) {
                        navigate(editPathname, { replace: true })
                    }
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            validateRequiredFields,
            store,
            scrollToElement,
            globalStore.session.accessGroupId,
            navigate,
            checkValidation,
            isPublished,
            location.pathname,
        ],
    )

    const undoChanges = useCallback(() => {
        globalStore.modals.open(
            () => (
                <>
                    <DialogTitle
                        id="alert-dialog-title"
                        style={{ padding: "20px 20px 0" }}
                    >
                        {t`edit-product-view.confirm-undo-changes-title`}
                    </DialogTitle>
                    <DialogActions
                        style={{
                            justifyContent: "flex-start",
                            padding: "20px",
                        }}
                    >
                        <Button
                            color="info"
                            variant="contained"
                            onClick={() => {
                                store.formFields.setErrors({})
                                store.initFormFields()
                                globalStore.modals.pop()
                            }}
                            autoFocus
                        >
                            {t`edit-product-view.undo-changes`}
                        </Button>
                        <Button
                            color="info"
                            onClick={() => globalStore.modals.pop()}
                            style={{ marginLeft: "10px" }}
                        >
                            {t`edit-product-view.cancel`}
                        </Button>
                    </DialogActions>
                </>
            ),
            { variant: "slide-up-w600" },
        )
    }, [store, globalStore.modals])

    return (
        <Box
            sx={{
                display: "grid",
                gridAutoColumns: {
                    md: "minmax(min-content, max-content)",
                },
                gridAutoFlow: {
                    xs: "row",
                    md: "column",
                },
                gap: "20px",
            }}
        >
            <Button
                color="info"
                variant="contained"
                type="button"
                disabled={!formIsDirty}
                onClick={saveProduct}
            >
                {store.product.productId == null
                    ? t`edit-product-view.save-draft`
                    : t`edit-product-view.save-changes`}
            </Button>

            <Button
                color="primary"
                variant="contained"
                type="button"
                onClick={publishProducts}
                disabled={!canBePublished}
            >
                {t`edit-product-view.publish`}
            </Button>

            <Button
                color="info"
                variant="text"
                onClick={undoChanges}
                disabled={!formIsDirty}
                type="button"
            >
                {t`edit-product-view.undo-changes`}
            </Button>
        </Box>
    )
})
