import { styled } from "@mui/material"
import Accordion from "@mui/material/Accordion/Accordion"

export const DynamicConfigurableFormFieldsStyledAccordion = styled(Accordion, {
    shouldForwardProp: (prop) => prop !== "backgroundColor",
})<{ backgroundColor: string }>(({ backgroundColor, theme }) => ({
    borderWidth: 0,
    boxShadow: "none",
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&:is(:last-child)": {
        borderBottom: 0,
    },
    "&::before": {
        display: "none",
    },
    backgroundColor,
}))
