import React from "react"
import { makeAutoObservable } from "mobx"
import camelcaseKeys from "camelcase-keys"
import { t } from "@lingui/macro"

import { Pagination } from "src/lib/pagination"
import { Channel } from "src/channel"
import { InfoCenterAdminService } from "src/api"
import { reportError } from "src/lib/report"
import { ILibraryCategory } from "src/types/library"
import { createLoadingKeys } from "src/lib/loading"
import { loads } from "src/channel/utils"

export class LibraryCategoriesStore {
    static Context = React.createContext<LibraryCategoriesStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "loadCategories")

    categories: ILibraryCategory[] = []
    private repositoryUpdatesListenerDisposer?: () => void

    constructor() {
        makeAutoObservable(this)
    }

    @loads(() => LibraryCategoriesStore.LoadingKeys.init)
    async init() {
        this.categories = await this.fetchCategories()
        await this.categoriesPagination.loadInitialPage()

        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "library-categories"
                ) {
                    this.categories = await this.fetchCategories()
                    await this.categoriesPagination.reload()
                }
            },
        )
    }

    @loads(() => LibraryCategoriesStore.LoadingKeys.loadCategories)
    private async fetchCategories() {
        try {
            const categories = camelcaseKeys(
                await InfoCenterAdminService.infocenterGetCategory(),
            )

            const categoriesWithItemsAndIds: ILibraryCategory[] =
                await Promise.all(
                    categories.map(async (category) => {
                        const items =
                            camelcaseKeys(
                                await InfoCenterAdminService.getV1AdminInfocenterCategoryInformationItem(
                                    {
                                        categoryId:
                                            category.categoryId as number,
                                    },
                                ),
                            ) ?? []

                        return {
                            ...category,
                            id: category.categoryId as number,
                            items: items.map((item) => ({
                                ...item,
                                id: item.informationItemId as number,
                                rank: item.rank ?? 0,
                            })),
                            rank: category.rank ?? 0,
                        }
                    }),
                )

            return categoriesWithItemsAndIds
        } catch (error) {
            throw error
        }
    }

    deleteCategory = async (categoryId: number) => {
        try {
            await InfoCenterAdminService.infocenterDeleteCategory({
                categoryId: categoryId,
            })
        } catch (error) {
            reportError(
                t`library-information-items-view.delete-item-fail`,
                error,
            )
        } finally {
            this.categories = await this.fetchCategories()
            await this.categoriesPagination.reload()
        }
    }

    dispose() {
        this.repositoryUpdatesListenerDisposer?.()
    }

    categoriesPagination = new Pagination(
        (params) => {
            const pageStart = (params.page - 1) * params.pageSize
            const page = this.categories.slice(
                pageStart,
                pageStart + params.pageSize,
            )

            return {
                items: page,
                sourceItems: this.categories,
                count: this.categories.length,
            }
        },
        { static: true },
    )
}
