import { t } from "@lingui/macro"
import { TextField } from "@mui/material"
import { observer } from "mobx-react"

import { FeatureConfigurationDetailStore } from "src/modals/feature-configuration-detail/store"
import { useStore } from "src/store/lib/useStore"

export const SMSFromNameTemplateSection = observer(() => {
    const store = useStore(FeatureConfigurationDetailStore)

    return (
        <>
            <TextField
                label={t`feature-configuration-detail-modal.sms-template-section.sms_sender_name`}
                value={store.form.get("SMSFromPhone")}
                onChange={store.form.setter("SMSFromPhone")}
            />
        </>
    )
})
