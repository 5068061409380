import { Stack, Typography, useTheme } from "@mui/material"
import { observer } from "mobx-react"
import { t } from "@lingui/macro"

import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"

export const UserInfo = observer(() => {
    const {
        session: { user, mainPropertyName },
    } = useStore(GlobalStore)
    const theme = useTheme()

    return (
        <Stack direction="column">
            <Typography color={theme.palette.grey[900]}>
                {user?.name}
            </Typography>
            <Typography
                fontSize={14}
                color={theme.palette.grey[700]}
                lineHeight={1.14}
            >
                {user?.isSuper === true
                    ? t`global.super-user`
                    : mainPropertyName}
            </Typography>
        </Stack>
    )
})
