import { IAntIconProps } from "src/layouts/DefaultLayout/Navigation/icons/props"

export const AntIconCampaign = (props: IAntIconProps) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M18 12C18 12.55 18.45 13 19 13H21C21.55 13 22 12.55 22 12C22 11.45 21.55 11 21 11H19C18.45 11 18 11.45 18 12Z"
            fill="currentColor"
        />
        <path
            d="M16.59 16.82C16.26 17.26 16.35 17.87 16.79 18.19C17.32 18.58 17.88 19 18.41 19.4C18.85 19.73 19.47 19.64 19.79 19.2C19.79 19.19 19.8 19.19 19.8 19.18C20.13 18.74 20.04 18.12 19.6 17.8C19.07 17.4 18.51 16.98 17.99 16.59C17.55 16.26 16.93 16.36 16.6 16.8C16.6 16.81 16.59 16.82 16.59 16.82Z"
            fill="currentColor"
        />
        <path
            d="M19.81 4.81001C19.81 4.80001 19.8 4.80001 19.8 4.79001C19.47 4.35001 18.85 4.26001 18.42 4.59001C17.89 4.99001 17.32 5.41001 16.8 5.81001C16.36 6.14001 16.28 6.76001 16.61 7.19001C16.61 7.20001 16.62 7.20001 16.62 7.21001C16.95 7.65001 17.56 7.74001 18 7.41001C18.53 7.02001 19.09 6.59001 19.62 6.19001C20.05 5.87001 20.13 5.25001 19.81 4.81001Z"
            fill="currentColor"
        />
        <path
            d="M8 9H4C2.9 9 2 9.9 2 11V13C2 14.1 2.9 15 4 15H5V18C5 18.55 5.45 19 6 19C6.55 19 7 18.55 7 18V15H8L13 18V6L8 9Z"
            fill="currentColor"
        />
        <path
            d="M15.5 12C15.5 10.67 14.92 9.46999 14 8.64999V15.34C14.92 14.53 15.5 13.33 15.5 12Z"
            fill="currentColor"
        />
    </svg>
)
