import { observer } from "mobx-react"
import { MenuItem, TextField } from "@mui/material"
import { t } from "@lingui/macro"
import React from "react"

import { ManageUsersCreateOrEditModalStore } from "./store"

import { FormFieldSet } from "src/components/FormFieldSet"
import { useStore } from "src/store/lib/useStore"
import { ISessionPropertyOwner } from "src/store/session"

interface IProps {
    propertyOwners?: ISessionPropertyOwner[]
}

export const NameAndEmailSection = observer((props: IProps) => {
    const store = useStore(ManageUsersCreateOrEditModalStore)

    return (
        <>
            <FormFieldSet header={""}>
                <TextField
                    label={t`manage-users-create-edit-copy-modal.full-name`}
                    placeholder={t`manage-users-create-edit-copy-modal.full-name`}
                    onChange={store.fields.setter("name")}
                    value={store.fields.get("name")}
                    helperText={store.fields.error("name")}
                    error={Boolean(store.fields.error("name"))}
                />
            </FormFieldSet>
            <FormFieldSet header={""}>
                <TextField
                    label={t`manage-users-create-edit-copy-modal.email-address`}
                    placeholder={t`manage-users-create-edit-copy-modal.email-address`}
                    onChange={store.fields.setter("email")}
                    value={store.fields.get("email")}
                    helperText={store.fields.error("email")}
                    error={Boolean(store.fields.error("email"))}
                />
            </FormFieldSet>
            {props.propertyOwners != null && (
                <FormFieldSet header={""}>
                    <TextField
                        value={
                            store.fields.get("createUserPropertyOwnerId") ?? ""
                        }
                        onChange={(event) => {
                            store.fields.set(
                                "createUserPropertyOwnerId",
                                Number(event.target.value),
                            )
                        }}
                        select
                        label={"Select property owner"}
                        helperText={store.fields.error(
                            "createUserPropertyOwnerId",
                        )}
                        error={Boolean(
                            store.fields.error("createUserPropertyOwnerId"),
                        )}
                    >
                        {props.propertyOwners?.map((propertyOwner) => {
                            return (
                                <MenuItem
                                    key={propertyOwner.id}
                                    value={propertyOwner.id}
                                >
                                    {propertyOwner.legalName}
                                </MenuItem>
                            )
                        })}
                    </TextField>
                </FormFieldSet>
            )}
        </>
    )
})
