import { t } from "@lingui/macro"
import { Typography } from "@mui/material"
import { observer } from "mobx-react"

import { FormFieldSet } from "src/components/FormFieldSet"
import { SegmentPickerField } from "src/components/SegmentPickerField"
import { EditProductStore } from "src/views/product-catalogue/edit/store"
import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"

export const PublishSettingsSection = observer(() => {
    const store = useStore(EditProductStore)
    const globalStore = useStore(GlobalStore)

    return (
        <>
            <Typography
                component="h2"
                fontSize="20px"
                fontWeight="500"
                sx={{ mb: 2 }}
            >
                {t`edit-product-view.product-publishing-settings`}
            </Typography>
            <FormFieldSet header={t`edit-product-view.product-visible-for`}>
                <SegmentPickerField
                    value={store.formFields.get("publishedIn") ?? []}
                    accessGroupId={globalStore.session.accessGroupId}
                    onChange={(segments) => {
                        store.formFields.set("publishedIn", segments)
                        // await store.publishProduct()

                        // if (
                        //     store.product.publishedIn != null &&
                        //     store.product.publishedIn.length > 0
                        // ) {
                        //     store.setSnackbar({
                        //         message: t`edit-product-view.success-published`,
                        //     })
                        // }
                    }}
                />
            </FormFieldSet>
        </>
    )
})
