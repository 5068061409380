import React from "react"
import { t } from "@lingui/macro"
import { FormHelperText, IconButton } from "@mui/material"

import { ImagePreview } from "src/components/AttachmentsField/ImageAttachments/ImagePreview"
import { ImagePreviewHoverOptions } from "src/components/AttachmentsField/ImageAttachments/ImagePreviewHoverOptions"
import { View24 } from "src/components/icons/View24"
import { Bin24 } from "src/components/icons/Bin24"
import { UploadImageField } from "src/components/AttachmentsField/ImageAttachments/UploadImageField"
import { IImageHandlerProps } from "src/components/ImageSelectionSection/types"
import { Wrapper } from "src/components/ImageSelectionSection/styled"

export default function ImageSelectionSection({
    imageUrl,
    onImageDelete,
    onImageChange,
    errorMessage,
}: IImageHandlerProps) {
    return (
        <Wrapper>
            {imageUrl != null && imageUrl !== "" ? (
                <ImagePreview url={imageUrl ?? ""} aspectRatio="1/1">
                    <ImagePreviewHoverOptions>
                        <IconButton
                            href={imageUrl ?? ""}
                            target="_blank"
                            color="inherit"
                            title={t`common.open-in-new-tab`}
                        >
                            <View24 />
                        </IconButton>
                        <IconButton
                            onClick={onImageDelete}
                            color="inherit"
                            title={t`common.delete`}
                        >
                            <Bin24 />
                        </IconButton>
                    </ImagePreviewHoverOptions>
                </ImagePreview>
            ) : (
                <UploadImageField
                    onChange={onImageChange}
                    aspectRatio={"1/1"}
                />
            )}
            {Boolean(errorMessage) && (
                <FormHelperText error={true}>{errorMessage}</FormHelperText>
            )}
        </Wrapper>
    )
}
