import { IAntIconProps } from "src/layouts/DefaultLayout/Navigation/icons/props"

export const AntIconProvider = (props: IAntIconProps) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M7.51 9.90999L5.51 8.70999C5.19 8.51999 4.8 8.51999 4.48 8.70999L2.48 9.90999C2.18 10.09 2 10.42 2 10.77L2 13.22C2 13.57 2.18 13.9 2.49 14.08L4.49 15.28C4.81 15.47 5.2 15.47 5.52 15.28L7.52 14.08C7.82 13.91 8 13.58 8 13.23L8 10.78C8 10.42 7.82 10.09 7.51 9.90999Z"
            fill="currentColor"
        />
        <path
            d="M9.49 14.09L11.49 15.29C11.81 15.48 12.2 15.48 12.52 15.29L14.52 14.09C14.82 13.91 15.01 13.58 15.01 13.23L15.01 10.77C15.01 10.42 14.83 10.09 14.52 9.91L12.52 8.71C12.2 8.52 11.81 8.52 11.49 8.71L9.49 9.91C9.18 10.09 9 10.42 9 10.77L9 13.23C9 13.58 9.18 13.91 9.49 14.09Z"
            fill="currentColor"
        />
        <path
            d="M11.51 6.99L11.51 4.54001C11.51 4.19001 11.33 3.86 11.02 3.68001L9.02 2.48C8.7 2.29 8.31 2.29 7.99 2.48L5.99 3.68001C5.69 3.86 5.5 4.19001 5.5 4.54001L5.5 6.99C5.5 7.34 5.68 7.67001 5.99 7.85001L7.99 9.05C8.31 9.24 8.7 9.24 9.02 9.05L11.02 7.85001C11.33 7.66001 11.51 7.34 11.51 6.99Z"
            fill="currentColor"
        />
        <path
            d="M12.51 4.53001L12.51 6.99C12.51 7.34 12.69 7.67001 13 7.85001L15 9.05C15.32 9.24 15.71 9.24 16.03 9.05L18.03 7.85001C18.33 7.67001 18.52 7.34 18.52 6.99L18.52 4.53001C18.52 4.18001 18.34 3.85 18.03 3.67L16.03 2.47C15.71 2.28 15.32 2.28 15 2.47L13 3.67C12.7 3.85 12.51 4.18001 12.51 4.53001Z"
            fill="currentColor"
        />
        <path
            d="M11.03 16.16L9.03001 14.96C8.71001 14.77 8.32001 14.77 8.00001 14.96L6.00001 16.16C5.70001 16.34 5.51001 16.66 5.51001 17.01L5.51001 19.47C5.51001 19.82 5.70001 20.15 6.00001 20.33L8.00001 21.53C8.32001 21.72 8.71001 21.72 9.03001 21.53L11.03 20.33C11.33 20.15 11.52 19.82 11.52 19.47L11.52 17.02C11.51 16.66 11.33 16.34 11.03 16.16Z"
            fill="currentColor"
        />
        <path
            d="M12.51 17.01L12.51 19.47C12.51 19.82 12.69 20.15 13 20.33L15 21.53C15.32 21.72 15.71 21.72 16.03 21.53L18.03 20.33C18.33 20.15 18.52 19.82 18.52 19.47L18.52 17.01C18.52 16.66 18.34 16.33 18.03 16.15L16.03 14.95C15.71 14.76 15.32 14.76 15 14.95L13 16.15C12.7 16.34 12.51 16.66 12.51 17.01Z"
            fill="currentColor"
        />
        <path
            d="M16.49 14.09L18.49 15.29C18.81 15.48 19.2 15.48 19.52 15.29L21.52 14.09C21.82 13.91 22.01 13.58 22.01 13.23L22.01 10.77C22.01 10.42 21.83 10.09 21.52 9.91L19.52 8.71C19.2 8.52 18.81 8.52 18.49 8.71L16.49 9.91C16.19 10.09 16 10.42 16 10.77L16 13.23C16 13.58 16.18 13.91 16.49 14.09Z"
            fill="currentColor"
        />
    </svg>
)
