import { styled, Checkbox, useTheme, Palette } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"
import { t } from "@lingui/macro"

import { useStore } from "src/store/lib/useStore"
import {
    FeatureTogglesStore,
    ITableCell,
} from "src/views/feature-toggles/store"
import { Tooltip } from "src/components/Tooltips/Tooltip"
import { CenteredSpinner } from "src/components/CenteredSpinner"
import { reportUnhandledApiError } from "src/lib/report"

export const FeatureTogglesTableView = observer(() => {
    const store = useStore(FeatureTogglesStore)
    const theme = useTheme()

    const handleFeatureToggle = useCallback(
        (cell, cellIndex?: number) => {
            try {
                store.setIsLoading(true)
                const value = !Boolean(cell.selected)

                if (cellIndex != null) {
                    store.tableData.forEach((row) => {
                        store.setTableCellSelected(row[cellIndex], value)
                    })
                } else {
                    store.setTableCellSelected(cell, value)
                }
            } catch (e) {
                reportUnhandledApiError(e)
            } finally {
                store.setIsLoading(false)
            }
        },
        [store],
    )

    return (
        <StyledTable palette={theme.palette}>
            <table data-testid="features-table" cellPadding={0} cellSpacing={0}>
                <StyledTableHead palette={theme.palette}>
                    <tr>
                        {store.tableData[0].map((cell, cellIndex) =>
                            cellIndex === 0 ? (
                                <th key={cellIndex}>
                                    {store.isLoading && (
                                        <CenteredSpinner height="40px" />
                                    )}
                                </th>
                            ) : (
                                <th key={cellIndex}>
                                    {cell.label}
                                    <br />
                                    <Tooltip
                                        title={t`feature-toggles-view.select-all-in-column`}
                                        arrow
                                    >
                                        <Checkbox
                                            checked={cell.selected}
                                            onChange={() => {
                                                handleFeatureToggle(
                                                    cell,
                                                    cellIndex,
                                                )
                                            }}
                                            inputProps={
                                                toggleColumnCheckboxInputProps
                                            }
                                        />
                                    </Tooltip>
                                </th>
                            ),
                        )}
                    </tr>
                </StyledTableHead>
                <StyledTableBody
                    palette={theme.palette}
                    style={{
                        opacity: store.isLoading === true ? 0.2 : 1,
                    }}
                >
                    {store.tableData.slice(1).map((row, rowIndex) => (
                        <TableBodyRowView
                            key={rowIndex}
                            row={row}
                            handleFeatureToggle={handleFeatureToggle}
                        />
                    ))}
                </StyledTableBody>
            </table>
        </StyledTable>
    )
})

const TableBodyRowView = observer(
    (props: {
        row: ITableCell[]
        handleFeatureToggle: (cell: ITableCell, cellIndex?: number) => void
    }) => (
        <tr data-testid="features-table-body-row">
            {props.row.map((cell, cellIndex) => (
                <TableBodyCellView
                    key={cellIndex}
                    cell={cell}
                    cellIndex={cellIndex}
                    handleFeatureToggle={props.handleFeatureToggle}
                />
            ))}
        </tr>
    ),
)

const TableBodyCellView = observer(
    (props: {
        cell: ITableCell
        cellIndex: number
        handleFeatureToggle: (cell: ITableCell, cellIndex?: number) => void
    }) =>
        props.cellIndex === 0 ? (
            <th>{props.cell?.label}</th>
        ) : (
            <td>
                <Checkbox
                    checked={props.cell.selected}
                    onChange={() => props.handleFeatureToggle(props.cell)}
                    inputProps={featureCheckboxInputProps}
                />
            </td>
        ),
)

const StyledTable = styled("div")((props: { palette: Palette }) => ({
    maxHeight: "70vh",
    overflowX: "auto",
    color: props.palette.text.primary,
}))

const StyledTableHead = styled("thead")((props: { palette: Palette }) => ({
    insetBlockStart: 0,
    position: "sticky",
    background: props.palette?.common.white,
    zIndex: 2,

    th: {
        whiteSpace: "nowrap",
        borderRight: `1px solid ${props.palette.grey[500]}`,
        borderBottom: `1px solid ${props.palette.grey[500]}`,
        zIndex: 1,
        fontWeight: 500,
        fontSize: "14px",
        padding: "16px 16px 4px",
    },

    "th:first-of-type": {
        zIndex: 2,
        left: 0,
        position: "sticky",
        background: props.palette?.common.white,
    },
}))

const StyledTableBody = styled("tbody")((props: { palette: Palette }) => ({
    th: {
        position: "sticky",
        insetInlineStart: 0,
        background: props.palette?.common.white,
        borderRight: `1px solid ${props.palette.grey[500]}`,
        borderBottom: `1px solid ${props.palette.grey[500]}`,
        zIndex: 1,
        whiteSpace: window.innerWidth > 400 ? "nowrap" : "normal",
        textAlign: "left",
        fontWeight: 500,
        fontSize: "14px",
        padding: "0px 16px",
    },

    td: {
        textAlign: "center",
        borderRight: `1px solid ${props.palette.grey[500]}`,
        borderBottom: `1px solid ${props.palette.grey[500]}`,
        padding: "0px 16px",
    },

    "tr:hover > *": {
        background: props.palette.grey[200],
    },
}))

const featureCheckboxInputProps: InputProps = {
    "data-testid": "table-body-feature-checkbox",
}

const toggleColumnCheckboxInputProps: InputProps = {
    "data-testid": "table-head-bulk-checkbox",
}

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
    [key: string]: string | undefined
}
