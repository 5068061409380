import { FormControlLabel, Stack, Switch } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { t } from "@lingui/macro"

import { CommunityDetailStore } from "./store"

import { FormFieldSet } from "src/components/FormFieldSet"
import { useStore } from "src/store/lib/useStore"

export const CommunityToggleSwtich = observer(() => {
    const store = useStore(CommunityDetailStore)

    const handleDigitalAccessIntegrationChange = useCallback(
        (_: unknown, checked: boolean) => {
            store.fields.set("adminNotificationsEnabled", checked)
        },
        [store.fields],
    )

    const statusLabel = store.fields.get("adminNotificationsEnabled")
        ? t`community-detail-modal.toggle-notification-enabled`
        : t`community-detail-modal.toggle-notification-disabled`

    return (
        <FormFieldSet>
            <Stack spacing={2}>
                <FormControlLabel
                    checked={store.fields.get("adminNotificationsEnabled")}
                    onChange={handleDigitalAccessIntegrationChange}
                    control={<Switch color="success" />}
                    label={statusLabel}
                />
            </Stack>
        </FormFieldSet>
    )
})
