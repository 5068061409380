/* eslint-disable import/no-relative-parent-imports */
import { Stack, Box } from "@mui/material"
import { observer } from "mobx-react"

import { t } from "@lingui/macro"

import {
    CardContainer,
    CardItemContainer,
    Separator,
    Typography400,
    Typography500,
} from "../styled"

import { useWindowWide } from "src/views/feature-toggles/features/useWindowWide"
import { formatDateTime } from "src/lib/date"

interface IConversationDetailProps {
    conversationID: string
    tenantID: number
    endDate: string
    resolution: string
}

export const ConversationDetail = observer(
    (props: IConversationDetailProps) => {
        const currentWidth = useWindowWide(0)
        return (
            <Stack direction="row" justifyContent="space-between">
                <Box
                    sx={{
                        display: "grid",
                        gap: "24px",
                        gridTemplateColumns: {
                            xs: "1fr",
                            sm: "1fr 1fr",
                            lg: "1fr 1fr 1fr",
                        },
                        alignItems: "start",
                    }}
                >
                    <CardContainer windowWidth={currentWidth}>
                        <CardItemContainer>
                            <Typography500>
                                {t`chatbot-view.list.conversation-id`}
                            </Typography500>
                            <Typography400>
                                {props.conversationID}
                            </Typography400>
                        </CardItemContainer>
                        <Separator />
                        <CardItemContainer>
                            <Typography500>
                                {t`chatbot-view.list.tenant-id`}
                            </Typography500>
                            <Typography400>{props.tenantID}</Typography400>
                        </CardItemContainer>
                    </CardContainer>
                    <CardContainer windowWidth={currentWidth}>
                        <CardItemContainer>
                            <Typography500>
                                {t`chatbot-view.modal.chat-end-date`}
                            </Typography500>
                            <Typography400>
                                {formatDateTime(new Date(props.endDate))}
                            </Typography400>
                        </CardItemContainer>
                        <Separator />
                        <CardItemContainer>
                            <Typography500>
                                {t`chatbot-view.modal.resolution`}
                            </Typography500>
                            <Typography400>
                                {props.resolution.split(":")[0]}
                            </Typography400>
                        </CardItemContainer>
                    </CardContainer>
                </Box>
            </Stack>
        )
    },
)
