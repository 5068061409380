import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { FormPanel } from "src/components/FormPanel"
import { OrderableList } from "src/components/OrderableList"
import { LibraryCategoriesModal } from "src/modals/library-categories"
import { LibraryCategoriesOrderStore } from "src/modals/library-categories-order/store"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { ILibraryCategory } from "src/types/library"

export const LibraryCategoriesOrderOrderingSection = observer(() => {
    const store = useStore(LibraryCategoriesOrderStore)
    const globalStore = useStore(GlobalStore)

    const rowActionsRenderer = useCallback(
        (category: ILibraryCategory) => [
            {
                text: t`library-categories-order-modal.edit-category-button`,
                onClick: () =>
                    globalStore.modals.open(() => (
                        <LibraryCategoriesModal category={category} />
                    )),
            },
        ],
        [globalStore.modals],
    )

    return (
        <FormPanel
            sections={[
                {
                    header: t`library-categories-order-modal.modal-header`,
                    subHeader: t`library-categories-order-modal.modal-subheader`,
                    content: (
                        <OrderableList
                            items={store.categories}
                            onSort={(nextCategories) =>
                                store.setCategories(nextCategories)
                            }
                            rowRenderer={(item) => item.adminName}
                            rowActionsRenderer={rowActionsRenderer}
                        />
                    ),
                },
            ]}
        />
    )
})
