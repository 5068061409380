import { t } from "@lingui/macro"
import { Button, Stack, useTheme } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useMemo, useState } from "react"

import { Icon } from "src/components/icons"
import { Cross16 } from "src/components/icons/Cross16"
import { SegmentPickerSearch } from "src/components/SegmentPicker/SegmentPickerSearch"
import { SegmentPickerSelected } from "src/components/SegmentPicker/components/SegmentPickerSelected"
import {
    BottomBar,
    TabBar,
    TabButton,
    TabContent,
} from "src/components/SegmentPicker/TabbedSegmentPicker/styled"
import { ITabbedSegmentPickerProps } from "src/components/SegmentPicker/TabbedSegmentPicker/types"

export const TabbedSegmentPicker = observer(
    (props: ITabbedSegmentPickerProps) => {
        const [tabIndex, setTabIndex] = useState(0)
        const theme = useTheme()

        const tabs = useMemo(
            () => [
                <SegmentPickerSearch />,
                <SegmentPickerSelected
                    onSave={props.onSave}
                    onClose={props.onClose}
                    initialSegmentIds={props.initialSegmentIds}
                />,
            ],
            [props],
        )

        const handleTabChange = useCallback(
            (_: unknown, index: number) => setTabIndex(index),
            [],
        )

        return (
            <Stack height="100%">
                <TabBar
                    value={tabIndex}
                    onChange={handleTabChange}
                    variant="fullWidth"
                >
                    <TabButton
                        label={t`segment-picker.find-segments-tab`}
                        value={0}
                    />
                    <TabButton
                        label={t`segment-picker.selected-segments-tab`}
                        value={1}
                    />
                </TabBar>
                <TabContent>{tabs[tabIndex]}</TabContent>
                <BottomBar
                    direction="row"
                    justifyContent="space-between"
                    padding={2}
                >
                    <Button
                        startIcon={
                            <Icon
                                icon={<Cross16 />}
                                size={20}
                                color={theme.palette.info.main}
                            />
                        }
                        color="info"
                        onClick={props.onClose}
                    >
                        {t`segment-picker.bottom-bar.close`}
                    </Button>
                    <Button variant="contained" onClick={props.onSave}>
                        {t`segment-picker.bottom-bar.save-selected`}
                    </Button>
                </BottomBar>
            </Stack>
        )
    },
)
