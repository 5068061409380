import { IAntIconProps } from "src/layouts/DefaultLayout/Navigation/icons/props"

export const AntIconContactForms = (props: IAntIconProps) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M17 16C16.45 16 16 15.55 16 15H8C8 15.55 7.55 16 7 16C6.45 16 6 15.55 6 15H2V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V15H18C18 15.55 17.55 16 17 16Z"
            fill="currentColor"
        />
        <path
            d="M20 8H17V6C17 4.9 16.1 4 15 4H9C7.9 4 7 4.9 7 6V8H4C2.9 8 2 8.9 2 10V14H6V13C6 12.45 6.45 12 7 12C7.55 12 8 12.45 8 13V14H16V13C16 12.45 16.45 12 17 12C17.55 12 18 12.45 18 13V14H22V10C22 8.9 21.1 8 20 8ZM15 8H9V6H15V8Z"
            fill="currentColor"
        />
    </svg>
)
