/* eslint-disable @typescript-eslint/no-explicit-any */
import { t } from "@lingui/macro"
import { makeAutoObservable } from "mobx"
import React from "react"

import { FormTypeDropdownKeys } from "./types/formTypeDropdownValues"

import {
    ConnectorAdminService,
    ContactFormV2AdminService,
    contactv2_FormFieldSource,
    contactv2_FormMetadataField,
    contactv2_FormMetadataFieldSource,
    contactv2_FormType,
    contactv2_FormTypeField,
    contactv2_FormTypeWithSegments,
} from "src/api"
import { Channel } from "src/channel"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { FormFields } from "src/lib/form-fields"
import { OutputType } from "src/types/output"
import { IIntegrationConnectorItem } from "src/views/integration-connector/type"

export class FormDetailStore {
    static Context = React.createContext<FormDetailStore | null>(null)
    formFields = new FormFields<IFormFields>({
        name: "",
        type: "",
        description: "",
        accessGroupId: -1,
        segmentIds: [],
        email: [],
        externalFieldList: [],
        selectedConnectorId: -1,
        formFieldDescription: "",
        outputTypeSelectedValue: "" as OutputType,
        formFieldTitle: "",
        externalFormId: "",
        iconUrl: "",
        fields: [],
        messagingEnabled: false,
        metaDataFields: [],
        metaDataFieldsList: [],
    })

    isLoading = false
    segmentIds: number[] = []
    accessGroupId: number = DEFAULT_ACCESS_GROUP.id
    hasError: boolean = false
    isEditMode = false

    constructor() {
        makeAutoObservable(this)
    }

    get fields() {
        return this.formFields.get("fields")
    }

    get externalDynamicFieldsList() {
        const list = this.fields?.map((item) => ({
            isRequired: item.required ?? false,
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            isAddOptionsVisible: item.options?.length ? true : false,
            externalFieldTypeDropdownValue: {
                value: item.source ?? "",
                errorMessage: "",
            },
            fieldTypeDropdownValue: {
                value: item.type ?? "",
                errorMessage: "",
            },
            label: { value: item.label ?? "", uuid: "", errorMessage: "" },
            inputList: item.options?.map((v) => ({
                value: v.option ?? "",
                errorMessage: "",
            })),
            question: { value: item.label ?? "", uuid: "", errorMessage: "" },
            uuid: item.uuid ?? "",
            relationshipDropdownValue: {
                value: item.parent_field_uuid ?? "",
                errorMessage: "",
            },
            helpingText: { value: item.tooltip ?? "", errorMessage: "" },
        }))

        return list
    }

    get dynamicFieldsList() {
        const list = this.fields?.map((item) => ({
            isRequired: item.required ?? false,
            dropdownValue: { value: item.type ?? "", errorMessage: "" },
            label: { value: item.label ?? "", uuid: "", errorMessage: "" },
            inputList: item.options?.map((v) => ({
                value: v.option ?? "",
                uuid: v.uuid ?? "",
                errorMessage: "",
            })),
            answer: {
                value:
                    item.type === FormTypeDropdownKeys.Paragraph ||
                    item.type === FormTypeDropdownKeys.ShortAnswer
                        ? item.placeholder_value ?? ""
                        : "",
                uuid: "",
                errorMessage: "",
            },
            uuid: item.uuid ?? "",
            helpingText: { value: item.tooltip ?? "", errorMessage: "" },
        }))

        return list
    }

    get metaDataFields() {
        return this.formFields.get("metaDataFields").map((item) => ({
            source: { value: item.source ?? "", errorMessage: "" },
            label: { value: item.label ?? "", errorMessage: "" },
        }))
    }

    getOutputTypeSelectedValue = (
        integration_connector_id?: number,
        sendEmail?: boolean,
    ) => {
        if (
            integration_connector_id !== undefined &&
            integration_connector_id > 0
        ) {
            return OutputType.Integration
        }
        return sendEmail === true ? OutputType.Email : OutputType.CaseEngine
    }

    init = async (
        connectorItems: IIntegrationConnectorItem[],
        accessGroupId: number,
        formId?: number,
    ) => {
        this.setIsLoading(true)

        let response: contactv2_FormTypeWithSegments | undefined
        let integrationConnectorTypeResponse:
            | contactv2_FormFieldSource[]
            | undefined

        let metaDataSourcesResponse:
            | contactv2_FormMetadataFieldSource[]
            | undefined

        if (formId !== undefined) {
            response =
                await ContactFormV2AdminService.getV2AdminContactFormType1({
                    formTypeId: formId as number,
                })

            integrationConnectorTypeResponse = await this.getAllSources(
                response.integration_connector_id,
            )
            metaDataSourcesResponse = await this.getMetaDataSources(
                response.integration_connector_id,
            )
        }

        this.formFields.init({
            connectorList: connectorItems,
            segmentIds: response?.published_in ?? [],
            name: response?.name ?? "",
            description: response?.description ?? "",
            email: response?.email ?? [],
            iconUrl: response?.icon_url,
            accessGroupId: response?.access_group_id ?? accessGroupId,
            fields: response?.fields ?? [],
            selectedConnectorId: response?.integration_connector_id,
            externalFormId: response?.external_id,
            sendEmail: response?.send_email,
            formFieldTitle:
                response?.fields != null ? response.fields[0].name : "",
            formFieldDescription:
                response?.fields != null ? response.fields[0].description : "",
            outputTypeSelectedValue:
                response !== undefined
                    ? this.getOutputTypeSelectedValue(
                          response.integration_connector_id,
                          response.send_email,
                      )
                    : undefined,
            externalFieldList: integrationConnectorTypeResponse ?? undefined,
            messagingEnabled: response?.messaging_enabled ?? false,
            useAiTitle: response?.use_ai_title ?? false,
            metaDataFields: response?.metadata_fields ?? [],
            metaDataFieldsList: metaDataSourcesResponse ?? [],
        })

        this.isEditMode = response?.form_type_id !== undefined
        this.setIsLoading(false)
    }

    async getAllIntegrations(
        type: string,
    ): Promise<{ items: IIntegrationConnectorItem[] }> {
        const response =
            await ConnectorAdminService.getV1AdminConnectorIntegration({
                type,
            })
        const items: IIntegrationConnectorItem[] =
            response
                .filter(
                    (i) =>
                        i.type === "avy_dev" ||
                        i.type === "unit4_case" ||
                        i.type === "vitec_case" ||
                        i.type === "dedu_case" ||
                        i.type === "balder_case" ||
                        i.type === "lime_case" ||
                        i.type === "fellowmind_case",
                )
                .map((item) => ({
                    id: item.integration_id ?? -1,
                    accessGroupId: item.access_group_id ?? -1,
                    accessType: item.access_type ?? "",
                    createdAt: item.created_at ?? "",
                    deletedAt: item.deleted_at ?? "",
                    integrationId: item.integration_id ?? -1,
                    integrationTypeId: item.integration_type_id ?? -1,
                    metaData: item.metadata as Record<string, string>,
                    name: item.name ?? "",
                    type: item.type ?? "",
                    updatedAt: item.updated_at ?? "",
                })) ?? []

        return {
            items,
        }
    }

    async getAllSources(integrationConnectorId?: number) {
        if (
            integrationConnectorId !== undefined &&
            integrationConnectorId > 0
        ) {
            return await ContactFormV2AdminService.getV2AdminContactFormTypeConnectorSource(
                {
                    integrationConnectorId,
                },
            )
        }
    }

    async getMetaDataSources(integrationConnectorId?: number) {
        if (
            integrationConnectorId !== undefined &&
            integrationConnectorId > 0
        ) {
            return await ContactFormV2AdminService.getV2AdminContactFormTypeConnectorMetadataSource(
                {
                    integrationConnectorId,
                },
            )
        }
    }

    createUpdateForm = async (id: number, request: contactv2_FormType) => {
        this.setIsLoading(true)
        const publishedIn = this.formFields.get("segmentIds")
        let itemResponse: any = {}
        let formId = null

        try {
            if (!this.isEditMode) {
                // Create
                await this.formFields.catchErrors(async () => {
                    itemResponse =
                        await ContactFormV2AdminService.postV2AdminContactFormType(
                            {
                                request,
                            },
                        )
                })
                formId = itemResponse.form_type_id
            } else {
                // Update
                await this.formFields.catchErrors(async () => {
                    itemResponse =
                        await ContactFormV2AdminService.putV2AdminContactFormType(
                            {
                                formTypeId: id,
                                request,
                            },
                        )
                })
                formId = id
            }
            // Publish
            if (formId !== null) {
                await ContactFormV2AdminService.putV2AdminContactFormTypePublish(
                    {
                        formTypeId: formId as number,
                        request: {
                            published_in: publishedIn,
                        },
                    },
                )
            }
            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "contact-forms-v2",
                    action: this.isEditMode ? "update" : "create",
                },
            })
        } catch (error) {
            reportError(error)
        } finally {
            this.setIsLoading(false)
        }
    }

    validateRequiredFields = () => {
        const fieldList1: string[] = [
            "name",
            "description",
            "accessGroupId",
            "iconUrl",
            "selectedConnectorId",
            "email",
        ]

        const fieldList2: string[] = [
            "name",
            "description",
            "accessGroupId",
            "iconUrl",
        ]

        const requiredFields =
            this.formFields.get("outputTypeSelectedValue") ===
            OutputType.Integration
                ? fieldList1
                : fieldList2

        this.formFields.clearErrors()

        requiredFields.forEach((field) => {
            const requiredField = field as keyof IFormFields
            const formField = this.formFields.get(requiredField)

            if (
                formField == null ||
                formField.toString().trim() === "" ||
                formField === -1 ||
                formField === "<p></p>\n"
            ) {
                this.formFields.setError(requiredField, t`errors.required`)
            } else {
                this.formFields.clearError(requiredField)
            }
        })
    }

    setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading)
    setHasError = (hasError: boolean) => (this.hasError = hasError)
}

export interface IFormFields {
    name?: string
    type?: string
    description?: string
    accessGroupId?: number
    segmentIds?: number[]
    outputTypeSelectedValue?: OutputType
    email?: string[]
    connectorList?: IIntegrationConnectorItem[]
    externalFieldList?: { type?: string; source?: string; internal?: boolean }[]
    selectedConnectorId?: number
    formFieldDescription?: string
    formFieldTitle?: string
    iconUrl?: string
    fields?: Array<contactv2_FormTypeField>
    sendEmail?: boolean
    externalFormId?: string
    messagingEnabled?: boolean
    useAiTitle?: boolean
    metaDataFields: Array<contactv2_FormMetadataField>
    metaDataFieldsList?: contactv2_FormMetadataFieldSource[]
}
