import { Stack, styled } from "@mui/material"
import { observer } from "mobx-react"
import React from "react"

import { Label } from "src/components/ImageUploadField/Label"
import { TooltipWithIcon } from "src/components/Tooltips/TooltipWithIcon"

const ImagePreviewWrap = styled("div")(({ theme }) => ({
    aspectRatio: "16/9",
    backgroundColor: theme.palette.common.white,
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    position: "relative",
    overflow: "hidden",
}))

interface IProps {
    url: string
    label?: string
    tooltip?: string
    children?: React.ReactNode
}

export const ImagePreview = observer((props: IProps) => {
    return (
        <div>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                {Boolean(props.label) && <Label>{props.label}</Label>}
                {Boolean(props.tooltip) && (
                    <TooltipWithIcon tooltipText={props.tooltip ?? ""} />
                )}
            </Stack>
            <ImagePreviewWrap sx={{ backgroundImage: `url(${props.url})` }}>
                {props.children}
            </ImagePreviewWrap>
        </div>
    )
})
