import { IconButton, TextField } from "@mui/material"
import { observer } from "mobx-react"

import { DropdownItemParentContainer, TextFieldContainer } from "./styled"

import { Cross16 } from "src/components/icons/Cross16"
import { CheckBoxOutlined } from "src/components/icons/CheckBoxOutlined"
import { RadioButtonOutlined } from "src/components/icons/RadioButtonOutlined"
import { IAddDynamicOptionsSectionProps } from "src/types/add-dynamic-options-section/addDynamicOptionsSection"

export const AddDynamicOptionsSection = observer(
    ({
        index,
        type,
        item,
        onInputChange,
        onRemoveClick,
        disabled,
        label,
    }: IAddDynamicOptionsSectionProps) => {
        return (
            <DropdownItemParentContainer key={index}>
                {type === "Dropdown" ? (
                    index + 1
                ) : type === "Checkboxes" ? (
                    <CheckBoxOutlined />
                ) : (
                    <RadioButtonOutlined />
                )}

                <TextFieldContainer>
                    <TextField
                        label={label}
                        value={item.value}
                        onChange={(e) => onInputChange(index, e)}
                        error={Boolean(item.errorMessage)}
                        helperText={item.errorMessage}
                    />
                </TextFieldContainer>
                <IconButton
                    data-testid="remove-options"
                    size="small"
                    onClick={() => onRemoveClick(index)}
                    color="inherit"
                    disabled={disabled}
                >
                    <Cross16 />
                </IconButton>
            </DropdownItemParentContainer>
        )
    },
)
