import { t } from "@lingui/macro"
import { alpha, IconButton, Link, Stack, styled, useTheme } from "@mui/material"
import { observer } from "mobx-react"

import { Bin24 } from "src/components/icons/Bin24"
import { Pdf24 } from "src/components/icons/Pdf24"
import { isLocalFile } from "src/lib/file"
import { formatFileSize } from "src/lib/number"
import { truncateText } from "src/lib/text"

const Wrap = styled(Stack)(({ theme }) => ({
    height: "56px",
    padding: "0 0.75rem",
    background: alpha(theme.palette.utility.appleFaded.main, 0.5),
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.grey[500],
    borderRadius: theme.shape.borderRadius,
}))

interface IProps {
    file: IFile
    onRemove: () => void
    disabled?: boolean
}

export const Document = observer((props: IProps) => {
    const theme = useTheme()

    const linkFileName: string = isLocalFile(props.file)
        ? props.file.file.name
        : props.file.name

    return (
        <Wrap
            direction="row"
            alignItems="center"
            justifyContent="space-between"
        >
            <Stack
                component="span"
                spacing={1}
                direction="row"
                alignItems="center"
            >
                <Pdf24 style={{ flexShrink: "0" }} />
                <Link
                    sx={{ wordBreak: "break-word" }}
                    target="_blank"
                    href={props.file.url}
                    color={theme.palette.grey[900]}
                    title={linkFileName}
                >
                    {truncateText(linkFileName, 50)}
                </Link>
            </Stack>
            <Stack
                component="span"
                spacing={1}
                direction="row"
                alignItems="center"
            >
                <span style={{ whiteSpace: "nowrap" }}>
                    {isLocalFile(props.file)
                        ? formatFileSize(props.file.file.size)
                        : ""}
                </span>
                <IconButton
                    onClick={props.onRemove}
                    title={t`attachments-field-component.upload-document-field.remove-button`}
                    disabled={props.disabled}
                >
                    <Bin24 />
                </IconButton>
            </Stack>
        </Wrap>
    )
})
