import { t } from "@lingui/macro"
import { TextField } from "@mui/material"
import { observer } from "mobx-react"

import { FormFieldSet } from "src/components/FormFieldSet"
import { ProductCategoriesCreateOrEditStore } from "src/modals/product-categories-create-edit/store"
import { useStore } from "src/store/lib/useStore"

export const CreateSection = observer(() => {
    const store = useStore(ProductCategoriesCreateOrEditStore)

    return (
        <FormFieldSet
            header={t`notice-board-post-detail-modal.settings-section.internal-name-header`}
            tooltip={t`notice-board-post-detail-modal.settings-section.internal-name-tooltip`}
        >
            <TextField
                label={t`notice-board-post-detail-modal.settings-section.internal-name-label`}
                placeholder={t`notice-board-post-detail-modal.settings-section.internal-name-placeholder`}
                onChange={store.fields.setter("name")}
                value={store.fields.get("name")}
                helperText={store.fields.error("name")}
                error={Boolean(store.fields.error("name"))}
            />
        </FormFieldSet>
    )
})
