import { t } from "@lingui/macro"
import { FormControlLabel, Stack, Switch } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { ContactFormTypeDetailStore } from "./store"

import { FormFieldSet } from "src/components/FormFieldSet"
import { useStore } from "src/store/lib/useStore"

export const ToggleChatAccessSection = observer(() => {
    const store = useStore(ContactFormTypeDetailStore)

    const handleDigitalAccessIntegrationChange = useCallback(
        (_: unknown, checked: boolean) => {
            store.accessToggleFields.set("accessToggle", checked)
        },
        [store.accessToggleFields],
    )

    const statusLabel = store.accessToggleFields.get("accessToggle")
        ? t`contact-form-type-detail-modal.enable-direct-message.value-enabled`
        : t`contact-form-type-detail-modal.enable-direct-message.value-disabled`

    return (
        <FormFieldSet>
            <Stack spacing={2}>
                <FormControlLabel
                    checked={store.accessToggleFields.get("accessToggle")}
                    onChange={handleDigitalAccessIntegrationChange}
                    control={<Switch color="success" />}
                    label={statusLabel}
                />
            </Stack>
        </FormFieldSet>
    )
})
