import { Stack } from "@mui/material"
import React from "react"

import {
    ActionDropdown,
    IActionDropdownItem,
} from "src/components/ActionDropdown"
import { ListItem } from "src/components/ActionList/Item/styled"

interface IProps {
    rowActions?: IActionDropdownItem[]
    children: React.ReactNode
}

export const Item = (props: IProps) => {
    return (
        <ListItem data-testid="action-list-item">
            <Stack
                direction="row"
                alignItems="center"
                style={{ margin: "0 0.5em" }}
            >
                {props.children}
            </Stack>
            {props.rowActions != null && (
                <ActionDropdown items={props.rowActions} />
            )}
        </ListItem>
    )
}
