import { Box, CircularProgress, styled } from "@mui/material"

const CenteredSpinnerContainer = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
})

export const CenteredSpinner = (props: { height?: string }) => (
    <CenteredSpinnerContainer sx={{ height: props.height ?? "100vh" }}>
        <CircularProgress />
    </CenteredSpinnerContainer>
)
