import { t } from "@lingui/macro"
import { useCallback } from "react"
import { observer } from "mobx-react"

import { EditRounded } from "@material-ui/icons"

import { useTheme } from "@mui/material"

import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { IEditPrefixProps } from "src/components/SegmentPicker/components/SegmentItemListPrefix/types"

export const EditPrefix = observer((props: IEditPrefixProps) => {
    const gstore = useStore(GlobalStore)
    const { palette } = useTheme()

    const handleEditClick = useCallback(() => {
        // Replace with the actual modal component when available
        gstore.modals.open(
            () => (
                <div>{`${t`modal-content-placeholder`} ${props.segment}`}</div>
            ),
            {
                hasPreview: true,
            },
        )
    }, [gstore.modals, props.segment])

    return (
        <div
            onClick={handleEditClick}
            title={t`open-edit-modal`}
            style={{
                background: "white",
                width: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
            }}
        >
            <EditRounded
                style={{
                    width: "16px",
                    height: "16px",
                    color: palette.grey[600],
                }}
            />
        </div>
    )
})
