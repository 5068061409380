import { observer } from "mobx-react"
import { Alert, Button, Stack } from "@mui/material"
import { t } from "@lingui/macro"

import React, { useCallback, useEffect } from "react"

import { CreateLegalEntityModalStore, IFormFields } from "./store"
import { SettingsSection } from "./SettingsSection"

import { ConfirmDeleteModal } from "src/modals/confirm-delete"

import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { Form } from "src/components/Form"
import { ModalHeader } from "src/components/ModalHeader"
import { FormPanel } from "src/components/FormPanel"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { IPropertyOwner } from "src/views/client-management/store"
import { useCloseConfirmationForForm } from "src/store/modals/use-close-confirmation-for-form"
import { CenteredSpinner } from "src/components/CenteredSpinner"

interface IProps {
    propertyOwnersList: IPropertyOwner[]
    chosenPropertyOwnerId?: number | undefined
    id?: number | undefined
}

const CreateNewLegalEntity = observer(
    ({ propertyOwnersList, chosenPropertyOwnerId, id }: IProps) => {
        const globalStore = useStore(GlobalStore)
        const store = useStore(CreateLegalEntityModalStore)

        useCloseConfirmationForForm(store.fields)

        useEffect(() => {
            ;(async () => await store.init(chosenPropertyOwnerId, id))()
        }, [store, chosenPropertyOwnerId, id])

        const inLoading =
            globalStore.loading.is(
                CreateLegalEntityModalStore.LoadingKeys.init,
            ) ||
            globalStore.loading.is(
                CreateLegalEntityModalStore.LoadingKeys.submit,
            ) ||
            globalStore.loading.is(
                CreateLegalEntityModalStore.LoadingKeys.delete,
            )

        const validateRequiredFields = useCallback(
            (requiredFields: string[]) => {
                for (const field of requiredFields) {
                    const requiredField = field as keyof IFormFields
                    const formField = store.fields.get(requiredField)

                    if (formField == null || formField === "") {
                        store.fields.setError(requiredField, t`errors.required`)
                    } else {
                        store.fields.clearError(requiredField)
                    }
                }
            },
            [store.fields],
        )

        const handleSubmit = useCallback(async () => {
            let requiredFields = [
                "legalName",
                "organisationId",
                "propertyOwnerId",
            ]

            validateRequiredFields(requiredFields)
            if (store.fields.hasErrors()) {
                return
            }
            await store.submit()

            if (!store.fields.hasErrors()) {
                globalStore.modals.pop()
            }
        }, [store, globalStore, validateRequiredFields])

        const deleteEntity = useCallback(
            async (id: number | string) => {
                await store.delete(id)
                globalStore.modals.pop()
                globalStore.modals.pop()
            },
            [store, globalStore],
        )

        const handleDeleteEntityCall = useCallback(async () => {
            if (store.fields.data.id !== undefined) {
                await globalStore.modals.open(
                    () => (
                        <ConfirmDeleteModal
                            variant="single"
                            deleteItem={deleteEntity}
                            itemId={store.fields.data.id ?? 0}
                            title={t`common.delete`}
                            content={t`create-new-legal-entity-modal.delete-warning`}
                            data-testID={"confirm-delete-legal-entity"}
                        />
                    ),
                    { variant: "slide-up-w600" },
                )
            }
        }, [deleteEntity, globalStore.modals, store.fields.data.id])

        if (inLoading) {
            return <CenteredSpinner height="100vh" />
        }

        return (
            <Stack spacing={2}>
                <Form onSubmit={store.isLoading ? () => null : handleSubmit}>
                    <ModalHeader>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={store.isLoading}
                            data-testid={"save-button"}
                        >
                            {t`create-new-legal-entity-modal.save-button`}
                        </Button>
                    </ModalHeader>
                    {Boolean(store.fields.error("generic")) && (
                        <Alert severity="error">
                            {store.fields.error("generic")}
                        </Alert>
                    )}
                    <FormPanel
                        sections={[
                            {
                                header: Boolean(store.fields.data.id)
                                    ? t`create-new-legal-entity-modal.section-header.update-legal-entity`
                                    : t`create-new-legal-entity-modal.section-header.create-new-legal-entity`,
                                content: (
                                    <SettingsSection
                                        propertyOwnersList={propertyOwnersList}
                                    />
                                ),
                            },
                        ]}
                    />
                </Form>

                {store.fields.data.id !== undefined && (
                    <FormPanel
                        sections={[
                            {
                                header: "Delete",
                                content: (
                                    <>
                                        <div>{t`create-new-legal-entity-modal.delete-warning`}</div>
                                        <Button
                                            type="submit"
                                            style={{ marginRight: "auto" }}
                                            variant="contained"
                                            data-testid={"delete-button"}
                                            onClick={handleDeleteEntityCall}
                                        >
                                            {t`common.delete`}
                                        </Button>
                                    </>
                                ),
                            },
                        ]}
                    />
                )}
            </Stack>
        )
    },
)

export const CreateNewLegalEntityModal = ({
    propertyOwnersList,
    chosenPropertyOwnerId,
    id,
}: IProps) => (
    <StoreProvider Store={CreateLegalEntityModalStore}>
        <CreateNewLegalEntity
            chosenPropertyOwnerId={chosenPropertyOwnerId}
            propertyOwnersList={propertyOwnersList}
            id={id}
        />
    </StoreProvider>
)
