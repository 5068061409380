/* eslint-disable import/no-relative-parent-imports */
import { Stack } from "@mui/material"
import { t } from "@lingui/macro"
import { observer } from "mobx-react"

import { DynamicSection } from "../dynamicFields"

import { IDynamicViewsProps } from "./types"

import { FormPanel } from "src/components/FormPanel"

export const DynamicViews = observer((props: IDynamicViewsProps) => {
    return (
        <FormPanel
            sections={[
                {
                    header: t`integration-connector-modal.configuration`,
                    content: (
                        <Stack spacing={2}>
                            <DynamicSection
                                fields={props.inputFields}
                                setInputFields={props.setInputFields}
                                setSaveButtonEnabled={
                                    props.setSaveButtonEnabled
                                }
                                disabled={props.disabled}
                                connectorType={props.connectorType}
                            />
                        </Stack>
                    ),
                },
            ]}
        />
    )
})
