import { useCallback } from "react"

import {
    PassthroughDragAndDropListProps,
    DragAndDropList,
} from "src/components/DragAndDropList"
import { IOrderable, sortListFromResult } from "src/lib/ordering"

interface IProps<TItem extends IOrderable>
    extends PassthroughDragAndDropListProps<TItem> {
    onSort: (item: TItem[]) => void
}

/**
 * OrderableList sorts items that conforms to the {@link IOrderable} interface.
 *
 * A thin wrapper around {@link DragAndDropList}
 */
export const OrderableList = <TItem extends IOrderable>(
    props: IProps<TItem>,
) => {
    const handleDrop = useCallback(
        (result: IDropResult) => {
            props.onSort(sortListFromResult(props.items, result))
        },
        [props],
    )

    return (
        <DragAndDropList
            items={props.items}
            onDrop={handleDrop}
            rowRenderer={props.rowRenderer}
            rowActionsRenderer={props.rowActionsRenderer}
        />
    )
}
