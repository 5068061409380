import { Box, styled } from "@mui/material"

export const Divider = styled("hr")(({ theme }) => ({
    background: theme.palette.grey[500],
    minWidth: "1px",
    width: "1px",
    margin: "40px 0 40px -1px",
    border: "none",
}))

export const ModalColumn = styled(Box)({
    flex: "0 0 50%",
    width: "50%",
    padding: "32px 32px 24px 32px",
    boxSizing: "border-box",
})
