/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { domain_AdminGetInvoicesResponse } from "../models/domain_AdminGetInvoicesResponse"
import type { domain_AdminInvoice } from "../models/domain_AdminInvoice"
import type { domain_GetInvoicesRequest } from "../models/domain_GetInvoicesRequest"
import type { domain_MarkAsPaid } from "../models/domain_MarkAsPaid"
import type { domain_ShareInvoicesRequest } from "../models/domain_ShareInvoicesRequest"
import type { invoice_Invoice } from "../models/invoice_Invoice"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class InvoiceAdminService {
    /**
     * Get invoices for tenant
     * Get invoices for one tenant.
     * @returns domain_AdminInvoice OK
     * @throws ApiError
     */
    public static getV1AdminInvoice({
        tenantId,
        _export,
    }: {
        /** Tenant ID **/
        tenantId: string
        /** Is Export Request **/
        _export?: boolean
    }): CancelablePromise<Array<domain_AdminInvoice>> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/invoice",
            query: {
                tenant_id: tenantId,
                export: _export,
            },
        })
    }

    /**
     * Get invoices
     * Get invoices filtered by some criteria. If consumer passes header "Accept": "text/csv" reponse will be csv format
     * @returns domain_AdminGetInvoicesResponse OK
     * @throws ApiError
     */
    public static postV1AdminInvoice({
        request,
    }: {
        /** The filter object needed for searching invoices, we can't blindly browse **/
        request: domain_GetInvoicesRequest
    }): CancelablePromise<domain_AdminGetInvoicesResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/invoice",
            body: request,
        })
    }

    /**
     * Share invoices
     * Share invoices from one tenant to another
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminInvoiceShare({
        request,
    }: {
        /** Request body to specify invoice sharing between tenants **/
        request: domain_ShareInvoicesRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/invoice/share",
            body: request,
        })
    }

    /**
     * Update invoice
     * Update invoice
     * @returns invoice_Invoice OK
     * @throws ApiError
     */
    public static patchV1AdminInvoiceMarkAsPaid({
        invoiceId,
        request,
    }: {
        /** ID of the invoice **/
        invoiceId: number
        /** Invoice fields to update **/
        request: domain_MarkAsPaid
    }): CancelablePromise<invoice_Invoice> {
        return __request(OpenAPI, {
            method: "PATCH",
            url: "/v1/admin/invoice/{invoice_id}/mark-as-paid",
            path: {
                invoice_id: invoiceId,
            },
            body: request,
        })
    }
}
