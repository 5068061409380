import { observer } from "mobx-react"

import { Invoices } from "./Invoices"
import { StatusFilter } from "./StatusFilter"
import { InvoicesStore, IInvoice } from "./store"

import { StoreProvider } from "src/store/lib/StoreProvider"

const InvoicesView = observer(() => (
    <StoreProvider Store={InvoicesStore}>
        <Invoices />
    </StoreProvider>
))

export { InvoicesStore, InvoicesView, StatusFilter }
export type { IInvoice }
