import { t } from "@lingui/macro"
import { makeAutoObservable } from "mobx"
import React from "react"

import { CommunityAdminService, community_CommunityCreateUpdate } from "src/api"
import { Channel } from "src/channel"
import { loads } from "src/channel/utils"
import { FormFields } from "src/lib/form-fields"
import { createLoadingKeys } from "src/lib/loading"

interface IFormFields {
    id: number | null
    name: string
    internalName: string
    email: string
    segmentIds: number[]
    accessType: string
    accessGroupId: number | null
    adminNotificationsEnabled: boolean
}

type IInitOptions = { accessGroupId: number } | { id: number }

export class CommunityDetailStore {
    static Context = React.createContext<CommunityDetailStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "submit")

    fields = new FormFields<IFormFields>({
        id: null,
        name: "",
        internalName: "",
        email: "",
        segmentIds: [],
        accessGroupId: null,
        accessType: "",
        adminNotificationsEnabled: false,
    })

    constructor() {
        makeAutoObservable(this)
    }

    @loads(() => CommunityDetailStore.LoadingKeys.init)
    async init(options: IInitOptions) {
        if ("id" in options) {
            await this.loadCommunity(options.id)
        } else {
            this.fields.set("accessGroupId", options.accessGroupId)
        }
    }

    @loads(() => CommunityDetailStore.LoadingKeys.submit)
    async submit() {
        this.validate()
        if (this.fields.hasErrors()) {
            return
        }

        await this.fields.catchErrors(async () => {
            const { data } = this.fields
            const requestObject: community_CommunityCreateUpdate = {
                admin_emails: this.convertEmailsStringToArray(),
                category_ids: [],
                internal_name: data.internalName,
                name: data.name,
                access_group_id: data.accessGroupId ?? undefined,
                admin_notifications_enabled: data.adminNotificationsEnabled,
            }
            if (data.id == null) {
                const response =
                    await CommunityAdminService.postV1AdminCommunity({
                        request: requestObject,
                    })
                response.community_id !== undefined &&
                    (await CommunityAdminService.putV1AdminCommunityPublish({
                        communityId: response.community_id,
                        request: {
                            segment_ids: [...data.segmentIds],
                        },
                    }))

                Channel.send({
                    name: "repository/updated",
                    payload: {
                        repository: "community",
                        action: "create",
                        item: {
                            id: response.community_id ?? -1,
                            name: data.name,
                        },
                    },
                })
            } else {
                await CommunityAdminService.patchV1AdminCommunity({
                    communityId: data.id,
                    request: requestObject,
                })
                await CommunityAdminService.putV1AdminCommunityPublish({
                    communityId: data.id,
                    request: {
                        segment_ids: [...data.segmentIds],
                    },
                })
                Channel.send({
                    name: "repository/updated",
                    payload: {
                        repository: "community",
                        action: "update",
                        item: { id: data.id, name: data.name },
                    },
                })
            }
        })
    }

    private async loadCommunity(id: number) {
        try {
            const response = await CommunityAdminService.getV1AdminCommunity1({
                communityId: id,
            })

            this.fields.init({
                id,
                name: response.name ?? "",
                internalName: response.internal_name ?? "",
                email: this.convertEmailsArrayToString(
                    response.admin_emails ?? [],
                ),
                segmentIds: response.segment_ids ?? [],
                accessGroupId: response.access_group_id ?? null,
                accessType: response.access_type ?? "",
                adminNotificationsEnabled:
                    response.admin_notifications_enabled ?? false,
            })
        } catch {
            reportError(t`errors.request-failed-with-server-error-alert`)
        }
    }

    private validate() {
        this.fields.clearErrors()

        if (this.fields.get("segmentIds").length === 0) {
            this.fields.setError(
                "segmentIds",
                t`community-detail-modal.no-segments-error`,
            )
        }

        if (this.fields.get("internalName").length === 0) {
            this.fields.setError("internalName", t`errors.required`)
        }

        if (this.fields.get("name").length === 0) {
            this.fields.setError("name", t`errors.required`)
        }
    }

    private convertEmailsStringToArray() {
        const emailString = this.fields.get("email")
        const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi

        return emailString.match(emailRegex) as string[] | []
    }

    private convertEmailsArrayToString(emails: string[]) {
        return emails.join(", ")
    }
}
