import { t } from "@lingui/macro"
import {
    Collapse,
    List,
    ListItem,
    ListItemButton,
    styled,
    useTheme,
} from "@mui/material"
import { useCallback, useState } from "react"

import {
    ChevronIconWrapper,
    EllipseIconWrapper,
    ListItemText,
} from "src/layouts/DefaultLayout/styled"

import { Icon } from "src/components/icons"
import { ChevronDown24 } from "src/components/icons/ChevronDown24"
import { ChevronUp24 } from "src/components/icons/ChevronUp24"
import { isNodeEnvironment } from "src/config/variables"
import { Link } from "src/layouts/DefaultLayout/Navigation/Link"
import { ListItemIcon } from "src/layouts/DefaultLayout/Navigation/ListItem/ListItemIcon"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { INavigationItemGroup } from "src/store/navigation/types"
import { Ellipse2 } from "src/components/icons/Ellipse2"

interface IProps {
    item: INavigationItemGroup
}

const CustomLink = styled(Link)(({ theme }) => ({
    color: theme.palette.grey[800],
}))

/**
 * ListItemGroup is a navigation item that has one or more nested child items.
 *
 * The visibility of these nested items depends on the window.location's
 * pathname for large screen and is user togglable on small screens.
 */
export const ListItemGroup = (props: IProps) => {
    const gstore = useStore(GlobalStore)

    const theme = useTheme()

    const [open, setOpen] = useState(
        !gstore.navigation.getSingleSection(props.item.prefix),
    )

    const handleRootButtonClick = useCallback(() => {
        setOpen(!open)
        gstore.navigation.setClosedSections(props.item.prefix)
    }, [gstore.navigation, open, props.item.prefix])

    // Check if the group is correctly configured. Children of the group must
    // have their `to` prop start with but not be equal to the root prefix.
    // Otherwise the open/close of the accordion and the highlighting of active
    // items won't work.
    if (isNodeEnvironment("development", "test")) {
        for (const child of props.item.children) {
            if (typeof child.to === "string") {
                if (child.to === props.item.prefix) {
                    throw new Error(
                        `Children in navigation group cannot have \`to\` props that are equal to their parent's prefix. ${child.to} was set to both the root prefix and a child's \`to\` prop.`,
                    )
                } else if (!child.to.startsWith(props.item.prefix)) {
                    throw new Error(
                        `Children in navigation group must have \`to\` props that start with their parent's prefix. ${child.to} does not start with ${props.item.prefix}`,
                    )
                }
            }
        }
    }

    return (
        <>
            <ListItem disablePadding>
                <ListItemButton
                    component={CustomLink}
                    title={t({ id: props.item.text })}
                    to={handleRootButtonClick}
                    parent
                >
                    <ListItemIcon>
                        <Icon
                            icon={props.item.icon}
                            size={24}
                            color={theme.palette.grey[800]}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={t({ id: props.item.text })}
                        color={theme.palette.grey[800]}
                    />
                    <ChevronIconWrapper>
                        {open ? <ChevronUp24 /> : <ChevronDown24 />}
                    </ChevronIconWrapper>
                </ListItemButton>
            </ListItem>
            <Collapse component="li" in={open} unmountOnExit>
                <List disablePadding>
                    {props.item.children.map((child) => (
                        <ListItem key={t({ id: child.text })} disablePadding>
                            <ListItemButton
                                component={Link}
                                title={t({ id: child.text })}
                                to={child.to}
                                child
                            >
                                <EllipseIconWrapper>
                                    <Ellipse2 />
                                </EllipseIconWrapper>
                                <ListItemText
                                    color={theme.palette.grey[800]}
                                    primary={t({ id: child.text })}
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Collapse>
        </>
    )
}
