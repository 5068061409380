/*
 *** Context Menu component is designed to be used in Data Grid Pro as a custom right click menu for it.
 *** Currently it only supports copying feature for some particular cells.
 *** It's scope can be expanded in future as a custom menu
 */
import { t } from "@lingui/macro"
import { ContentCopy } from "@mui/icons-material"
import { Menu, MenuItem, Typography } from "@mui/material"

import { useCallback } from "react"

import { copyToClipboard } from "src/lib/copy-to-clipboard"
import { reportError, reportSuccess } from "src/lib/report"
import { DefaultTheme } from "src/themes/default"

interface IProps {
    value: string | null
    contextMenu: { mouseX: number; mouseY: number } | null
    onClose: () => void
}

const ContextMenu = ({ value, contextMenu, onClose }: IProps) => {
    const handleCopy = useCallback(async () => {
        try {
            await copyToClipboard(value ?? "")
            reportSuccess(t`copy-to-clipboard-success`)
        } catch (e) {
            reportError(t`copy-to-clipboard-error`, e)
        } finally {
            onClose()
        }
    }, [value, onClose])

    return (
        <Menu
            open={value !== null && contextMenu !== null}
            onClose={onClose}
            anchorReference="anchorPosition"
            anchorPosition={
                contextMenu !== null
                    ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                    : undefined
            }
        >
            <MenuItem
                onClick={handleCopy}
                sx={{
                    padding: "14px 20px 14px 14px",
                    ".css-6hp17o-MuiList-root-MuiMenu-list": {
                        paddingTop: 0,
                        paddingBottom: 0,
                    },
                }}
                data-testid="copy-menu-item"
            >
                <Typography
                    variant="body2"
                    sx={{
                        marginRight: "12px",
                        maxWidth: "343px",
                        whiteSpace: "normal",
                    }}
                >
                    {value}
                </Typography>
                <ContentCopy
                    fontSize="small"
                    htmlColor={DefaultTheme.palette.utility.darkBlue.main}
                />
            </MenuItem>
        </Menu>
    )
}

export default ContextMenu
