import { observer } from "mobx-react"
import {
    Alert,
    Button,
    IconButton,
    InputAdornment,
    Link,
    TextField,
    Typography,
} from "@mui/material"
import { useCallback } from "react"
import { Link as RouterLink, useSearchParams } from "react-router-dom"
import { t } from "@lingui/macro"
import { VisibilityOff, Visibility } from "@mui/icons-material"

import { NewPasswordStore } from "./store"

import { track } from "src/analytics/AnalyticsManager"

import { useStore } from "src/store/lib/useStore"

export const NewPassword = observer(() => {
    const store = useStore(NewPasswordStore)
    const [searchParams] = useSearchParams()
    const token = searchParams.get("token")

    const handleFormSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            if (token != null) {
                await store.newPassword(token)
            }
            track("Password | New Password")
        },
        [store, token],
    )
    return (
        <>
            <Typography
                variant="h1"
                sx={{
                    fontSize: "34px",
                    fontWeight: 400,
                    textAlign: "center",
                    maxWidth: "286px",
                }}
            >{t`new-password-view.heading`}</Typography>
            {token == null || token === "" ? (
                <Alert
                    sx={{ mt: 3 }}
                    severity="error"
                    data-testid="broken-link-error"
                >
                    {t`new-password-view.broken-link`}
                    <br />
                    <Link component={RouterLink} to="/login">
                        {t`new-password-view.return-to-login-page`}
                    </Link>
                    .
                </Alert>
            ) : store.isSuccess ? (
                <Alert
                    sx={{ mt: 3 }}
                    severity="success"
                    data-testid="submit-password-success"
                >
                    {t`new-password-view.success-message`}
                    <br />
                    <Link component={RouterLink} to="/login">
                        {t`new-password-view.return-to-login-page`}
                    </Link>
                    .
                </Alert>
            ) : (
                <>
                    <Typography sx={{ maxWidth: "312px", textAlign: "center" }}>
                        {t`new-password-view.preamble`}
                    </Typography>
                    <form style={{ width: "100%" }} onSubmit={handleFormSubmit}>
                        <TextField
                            type={store.showPassword ? "text" : "password"}
                            value={store.fields.get("password")}
                            onChange={(event) => {
                                store.fields.set("password", event.target.value)
                                store.validatePassword()
                            }}
                            label={t`new-password-view.password`}
                            helperText={store.fields.error("password")}
                            error={Boolean(store.fields.error("password"))}
                            sx={{
                                mt: 1,
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label={t`new-password-view.toggle-password-visibility`}
                                            onClick={() =>
                                                store.setShowPassword(
                                                    !store.showPassword,
                                                )
                                            }
                                            edge="end"
                                        >
                                            {store.showPassword ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            type={
                                store.showRepeatedPassword ? "text" : "password"
                            }
                            value={store.fields.get("repeatPassword")}
                            onChange={store.fields.setter("repeatPassword")}
                            label={t`new-password-view.repeat-password`}
                            helperText={store.fields.error("repeatPassword")}
                            error={Boolean(
                                store.fields.error("repeatPassword"),
                            )}
                            disabled={!store.isValidPassword}
                            sx={{
                                mt: 3,
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label={t`new-password-view.toggle-password-visibility`}
                                            onClick={() =>
                                                store.setShowRepeatedPassword(
                                                    !store.showRepeatedPassword,
                                                )
                                            }
                                            edge="end"
                                            disabled={!store.isValidPassword}
                                        >
                                            {store.showRepeatedPassword ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {Boolean(store.fields.error("generic")) && (
                            <Alert sx={{ mt: 3 }} severity="error">
                                {store.fields.error("generic")}
                            </Alert>
                        )}
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth={true}
                            sx={{ mt: 3 }}
                            type="submit"
                            disabled={
                                store.isLoading ||
                                store.fields.get("password") === "" ||
                                store.fields.get("repeatPassword") === ""
                            }
                        >
                            {t`new-password-view.save-new-password`}
                        </Button>
                    </form>
                </>
            )}
        </>
    )
})
