/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { avy_api_pkg_segment_PublishResourceRequest } from "../models/avy_api_pkg_segment_PublishResourceRequest"
import type { avy_api_pkg_segment_PublishResourceResponse } from "../models/avy_api_pkg_segment_PublishResourceResponse"
import type { infocenter_Category } from "../models/infocenter_Category"
import type { infocenter_CreateUpdateCategory } from "../models/infocenter_CreateUpdateCategory"
import type { infocenter_CreateUpdateInformationItem } from "../models/infocenter_CreateUpdateInformationItem"
import type { infocenter_InformationItem } from "../models/infocenter_InformationItem"
import type { infocenter_InformationItemListRequest } from "../models/infocenter_InformationItemListRequest"
import type { infocenter_InformationItemListResponse } from "../models/infocenter_InformationItemListResponse"
import type { sort_SortResourcesRequest } from "../models/sort_SortResourcesRequest"
import type { tags_ApplyTagsRequest } from "../models/tags_ApplyTagsRequest"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class InfoCenterAdminService {
    /**
     * List categories
     * List available categories for property owner
     * @returns infocenter_Category OK
     * @throws ApiError
     */
    public static infocenterGetCategory(): CancelablePromise<
        Array<infocenter_Category>
    > {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/infocenter/category",
        })
    }

    /**
     * Create category
     * Create new category
     * @returns infocenter_Category OK
     * @throws ApiError
     */
    public static infocenterCreateCategroy({
        request,
    }: {
        /** Request body to create category. **/
        request: infocenter_CreateUpdateCategory
    }): CancelablePromise<infocenter_Category> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/infocenter/category",
            body: request,
        })
    }

    /**
     * Sort categories
     * Rank information item categories within property owner
     * @returns string OK
     * @throws ApiError
     */
    public static patchV1AdminInfocenterCategoryRank({
        request,
    }: {
        /** Request body rank categories **/
        request: sort_SortResourcesRequest
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: "PATCH",
            url: "/v1/admin/infocenter/category/rank",
            body: request,
        })
    }

    /**
     * Get category
     * Get category by id
     * @returns infocenter_Category OK
     * @throws ApiError
     */
    public static infocenterGetCategoryById({
        categoryId,
    }: {
        /** ID of category to get **/
        categoryId: number
    }): CancelablePromise<infocenter_Category> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/infocenter/category/{category_id}",
            path: {
                category_id: categoryId,
            },
        })
    }

    /**
     * Delete category
     * Delete category by id
     * @returns string OK
     * @throws ApiError
     */
    public static infocenterDeleteCategory({
        categoryId,
    }: {
        /** ID of category to delete **/
        categoryId: number
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/infocenter/category/{category_id}",
            path: {
                category_id: categoryId,
            },
        })
    }

    /**
     * Update category
     * Update category by id
     * @returns infocenter_Category OK
     * @throws ApiError
     */
    public static infocenterUpdateCategory({
        categoryId,
        request,
    }: {
        /** ID of category to update **/
        categoryId: number
        /** Request body to update category **/
        request: infocenter_CreateUpdateCategory
    }): CancelablePromise<infocenter_Category> {
        return __request(OpenAPI, {
            method: "PATCH",
            url: "/v1/admin/infocenter/category/{category_id}",
            path: {
                category_id: categoryId,
            },
            body: request,
        })
    }

    /**
     * Get category information items
     * Get information items published in category
     * @returns infocenter_InformationItem OK
     * @throws ApiError
     */
    public static getV1AdminInfocenterCategoryInformationItem({
        categoryId,
    }: {
        /** ID of category to display **/
        categoryId: number
    }): CancelablePromise<Array<infocenter_InformationItem>> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/infocenter/category/{category_id}/information-item",
            path: {
                category_id: categoryId,
            },
        })
    }

    /**
     * Sort category items
     * Rank information item categories within property owner
     * @returns string OK
     * @throws ApiError
     */
    public static patchV1AdminInfocenterCategoryRank1({
        categoryId,
        request,
    }: {
        /** ID of category to rank **/
        categoryId: number
        /** Request body rank categories **/
        request: sort_SortResourcesRequest
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: "PATCH",
            url: "/v1/admin/infocenter/category/{category_id}/rank",
            path: {
                category_id: categoryId,
            },
            body: request,
        })
    }

    /**
     * @deprecated
     * Get information item list for admin
     * Get information items visible to currently logged in admin
     * @returns infocenter_InformationItemListResponse OK
     * @throws ApiError
     */
    public static infocenterGetItemList({
        pageNumber = 1,
        pageSize = 10,
        accessGroupId,
    }: {
        /** Pagination - number of page to load **/
        pageNumber?: number
        /** Pagination - size of pages to load **/
        pageSize?: number
        /** Access group ID for which to load the information items **/
        accessGroupId?: number
    }): CancelablePromise<infocenter_InformationItemListResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/infocenter/information-item",
            query: {
                page_number: pageNumber,
                page_size: pageSize,
                access_group_id: accessGroupId,
            },
        })
    }

    /**
     * Create informationItem
     * Create new informationItem
     * @returns infocenter_InformationItem OK
     * @throws ApiError
     */
    public static infocenterCreateItem({
        request,
    }: {
        /** Request body to create informationItem **/
        request: infocenter_CreateUpdateInformationItem
    }): CancelablePromise<infocenter_InformationItem> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/infocenter/information-item",
            body: request,
        })
    }

    /**
     * List Information Item types
     * List available informationItem types
     * @returns string OK
     * @throws ApiError
     */
    public static infocenterGetInformationTypes(): CancelablePromise<
        Array<string>
    > {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/infocenter/information-item-type",
        })
    }

    /**
     * Get information item list for admin
     * Get information items visible to currently logged in admin
     * @returns infocenter_InformationItemListResponse OK
     * @throws ApiError
     */
    public static infocenterGetItemListPost({
        request,
    }: {
        /** Request body to filter information items **/
        request: infocenter_InformationItemListRequest
    }): CancelablePromise<infocenter_InformationItemListResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/infocenter/information-item/list",
            body: request,
        })
    }

    /**
     * Get informationItem
     * Get informationItem by id
     * @returns infocenter_InformationItem OK
     * @throws ApiError
     */
    public static infocenterGetItem({
        informationItemId,
    }: {
        /** ID of informationItem to display **/
        informationItemId: number
    }): CancelablePromise<infocenter_InformationItem> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/infocenter/information-item/{information_item_id}",
            path: {
                information_item_id: informationItemId,
            },
        })
    }

    /**
     * Delete Information Item
     * Delete informationItem by id
     * @returns string OK
     * @throws ApiError
     */
    public static infocenterDeleteItem({
        informationItemId,
    }: {
        /** ID of informationItem to delete **/
        informationItemId: number
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/infocenter/information-item/{information_item_id}",
            path: {
                information_item_id: informationItemId,
            },
        })
    }

    /**
     * Update informationItem
     * Update informationItem by id
     * @returns infocenter_InformationItem OK
     * @throws ApiError
     */
    public static infocenterUpdateItem({
        informationItemId,
        request,
    }: {
        /** ID of informationItem to update **/
        informationItemId: number
        /** Request body to update informationItem **/
        request: infocenter_CreateUpdateInformationItem
    }): CancelablePromise<infocenter_InformationItem> {
        return __request(OpenAPI, {
            method: "PATCH",
            url: "/v1/admin/infocenter/information-item/{information_item_id}",
            path: {
                information_item_id: informationItemId,
            },
            body: request,
        })
    }

    /**
     * Publish Information Item
     * Publish informationItem by id
     * @returns avy_api_pkg_segment_PublishResourceResponse OK
     * @throws ApiError
     */
    public static infocenterPublish({
        informationItemId,
        request,
    }: {
        /** ID of informationItem to publish **/
        informationItemId: number
        /** Request body to set where informationItems are published **/
        request: avy_api_pkg_segment_PublishResourceRequest
    }): CancelablePromise<avy_api_pkg_segment_PublishResourceResponse> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/infocenter/information-item/{information_item_id}/publish",
            path: {
                information_item_id: informationItemId,
            },
            body: request,
        })
    }

    /**
     * Tag Information Item
     * Apply tags to informationItem by id
     * @returns any OK
     * @throws ApiError
     */
    public static infocenterTag({
        informationItemId,
        request,
    }: {
        /** ID of informationItem to publish **/
        informationItemId: number
        /** Request body to set where informationItems are published **/
        request: tags_ApplyTagsRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/infocenter/information-item/{information_item_id}/tag",
            path: {
                information_item_id: informationItemId,
            },
            body: request,
        })
    }
}
