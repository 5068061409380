import { observer } from "mobx-react"

import { ConfigurableDynamicFormFieldsUnhandledElementUi } from "src/components/ConfigurableDynamicFormFields/components/UnhandledUIElement"
import { ConfigurableDynamicFormFieldsAssetLibraryPicker } from "src/components/ConfigurableDynamicFormFields/formFields/asset-picker"
import { ConfigurableDynamicFormFieldsTextList } from "src/components/ConfigurableDynamicFormFields/formFields/text-list"
import { ConfigurableDynamicFormFieldsTextField } from "src/components/ConfigurableDynamicFormFields/formFields/text-field"
import { IConfigurableDynamicFormFieldsProps } from "src/components/ConfigurableDynamicFormFields/types/form-field"

export const DynamicConfigurableFormField = observer(
    ({ section, field }: IConfigurableDynamicFormFieldsProps) => {
        switch (field.type) {
            case "text":
            case "integer":
            case "textarea":
                return (
                    <ConfigurableDynamicFormFieldsTextField
                        field={field}
                        section={section}
                    />
                )
            case "icon":
                return (
                    <ConfigurableDynamicFormFieldsAssetLibraryPicker
                        activeAssetType="icons"
                        section={section}
                        field={field}
                    />
                )
            case "image":
                return (
                    <ConfigurableDynamicFormFieldsAssetLibraryPicker
                        activeAssetType="images"
                        section={section}
                        field={field}
                    />
                )
            case "text_list":
                return (
                    <ConfigurableDynamicFormFieldsTextList
                        field={field}
                        section={section}
                    />
                )
            default:
                // TODO: handle file type, but currently not being used
                return <ConfigurableDynamicFormFieldsUnhandledElementUi />
        }
    },
)
