import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useCallback, useEffect, useMemo, useState } from "react"
import { PersonSearch } from "@mui/icons-material"
import { TabContext, TabList } from "@mui/lab"
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Switch,
    Tab,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material"
import { SyntheticEvent } from "react-draft-wysiwyg"
import { useNavigate, useParams } from "react-router-dom"

import { StatusFilter } from "./StatusFilter"

import { TenantsDetailsStore } from "./store"

import {
    ActionButton,
    AddressWrapper,
    AppSettingsWrapper,
    BackCursor,
    BreadcrumbContainer,
    ContactDetailsWrapper,
    ContactGridWrapper,
    DetailsHeaderView,
    EditButtonWrapper,
    FormControlContainer,
    GridContactsWrapper,
    GridLeftWrapper,
    GridRightWrapper,
    HeaderWrapper,
    InvoiceFilterContainer,
    InvoiceSwitchWrapper,
    TabPanelContainer,
    TabPanelWrapper,
    UserAvatarWrapper,
} from "./styled"

import { useStore } from "src/store/lib/useStore"

import { Avatar } from "src/components/Avatar"
import { Icon } from "src/components/icons"
import { StoreProvider } from "src/store/lib/StoreProvider"

import { H1 } from "src/components/PageHeader"

import { MixpanelProperties } from "src/analytics/constants/properties"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { CenteredSpinner } from "src/components/CenteredSpinner"
import { FormFieldSet } from "src/components/FormFieldSet"
import { InvoicesList } from "src/components/InvoicesList"
import { ListPage } from "src/components/ListPage"
import { IPageFilterProps } from "src/components/PageFilter"
import { EditPencil } from "src/components/icons/EditPencil"
import { formatDate } from "src/lib/date"
import { reportError } from "src/lib/report"
import { maskText } from "src/lib/text"
import { ConfirmModal } from "src/modals/confirm"
import { GlobalStore } from "src/store"

const View = observer(() => {
    const store = useStore(TenantsDetailsStore)
    const gstore = useStore(GlobalStore)
    const theme = useTheme()
    const navigate = useNavigate()
    const { tenantId } = useParams()
    const [tab, setTab] = useState<string>("details")
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))
    const tableIsLoading = store.invoices.meta.initialized === false

    const tenantDetailsLoading = gstore.loading.is(
        TenantsDetailsStore.LoadingKeys.init,
    )

    const tenantDetailsSubmitLoading = gstore.loading.is(
        TenantsDetailsStore.LoadingKeys.submit,
    )

    useEffect(() => {
        if (tenantId !== undefined) {
            ;(async () => {
                await store.init(
                    parseInt(tenantId.toString()),
                    gstore.session.segmentIds,
                    gstore.session.hasAccessToModule("invoices"),
                )
            })()
        } else {
            reportError(t`generic-error`)
        }
    }, [store, tenantId, gstore.session.segmentIds, gstore.session])

    const handleTabChange = (event: SyntheticEvent, newValue: string) => {
        if (store.fields.getIsDirty() === true) {
            showConfirmModal(newValue)
        } else {
            store.isEdit && store.setIsEdit(false)
            setTab(newValue)
        }
    }

    const showConfirmModal = useCallback(
        (newValue: string) => {
            gstore.modals.open(
                () => (
                    <ConfirmModal
                        onConfirm={(confirmed: boolean) => {
                            if (confirmed) {
                                setTab(newValue)
                                store.setTenantDetails(store.tenantDetails)
                                store.setIsEdit(false)
                                gstore.modals.pop()
                            }
                        }}
                        title={t`modal-index.confirm-close-modal-title`}
                        content={t`modal-index.confirm-close-modal-content`}
                    />
                ),
                { variant: "slide-up-w600" },
            )
        },
        [gstore.modals, store],
    )

    const handleEditChange = () => {
        store.setIsEdit(!store.isEdit)
    }

    const handleSaveDetails = async () => {
        trackModuleEvent("Residents | Save contact details", {
            [MixpanelProperties.TenantID]: store.tenantId,
            [MixpanelProperties.UserID]: gstore.session.user?.adminId,
        })
        await store.submit()
    }

    const updateInvoicingInApp = async (_: unknown, checked: boolean) => {
        store.fields.set("is_post_notification", checked)
        await store.submit()
    }

    const getTabStyle = (tabName: string) => {
        const tabActive = tab === tabName
        return {
            backgroundColor: tabActive
                ? theme.palette.common.white
                : theme.palette.grey[300],
            marginRight: "8px",
            boxShadow: tabActive ? "0px 0px 10px rgba(0, 0, 0, 0.1)" : "",
            borderRadius: "4px 4px 0px 0px",
            width: "196px",
        }
    }

    const ContactDetailsRow = (key: string, value: string | undefined) => (
        <GridContactsWrapper container>
            <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                <Typography variant="subtitle2" color="#333333" noWrap>
                    {key}
                </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={8} lg={8} xl={8}>
                <Typography variant="body2" color="#333333" noWrap>
                    {value}
                </Typography>
            </Grid>
        </GridContactsWrapper>
    )

    const AppSettingsRow = (key: string, value: string | undefined) => (
        <GridContactsWrapper container>
            <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <Typography variant="subtitle2" color="#333333" noWrap>
                    {key}
                </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={9} lg={9} xl={9}>
                <Typography variant="body2" color="#333333" noWrap>
                    {value}
                </Typography>
            </Grid>
        </GridContactsWrapper>
    )

    const handleContractChange = useCallback(
        (event: SelectChangeEvent) => {
            store.setSelectedContract(Number(event.target.value))
        },
        [store],
    )

    const header = useMemo(
        () => ({
            header: "",
            breadcrumbs: [""],
        }),
        [],
    )

    const handleDownloadTenantsInvoicesAsCsv = useCallback(
        () => store.export(),
        [store],
    )

    const filter: IPageFilterProps = {
        actions: (
            <InvoiceFilterContainer>
                <Grid container>
                    <Grid item xs={0} sm={0} md={8} lg={8} xl={8} />
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <StatusFilter />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <ActionButton
                                    color="error"
                                    variant="outlined"
                                    type="button"
                                    onClick={handleDownloadTenantsInvoicesAsCsv}
                                    disabled={
                                        !store.canDownloadInvoiceCsv ||
                                        gstore.loading.is(
                                            TenantsDetailsStore.LoadingKeys
                                                .export,
                                        )
                                    }
                                    loading={gstore.loading.is(
                                        TenantsDetailsStore.LoadingKeys.export,
                                    )}
                                    sx={{
                                        width: "100%",
                                    }}
                                >
                                    <span>{t`invoices-view.export-button`}</span>
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                </ActionButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </InvoiceFilterContainer>
        ),
        searchFieldPlaceholder: t`invoices-view.search-field-placeholder`,
    }

    const handleSubHeaderClick = () => {
        navigate(`/tenants`)
    }

    const handleSimulateUser = async () => {
        await store.simulateUser()
    }

    const renderSimulateUser = () => {
        return (
            <Button
                data-testid="simulate-user-button"
                startIcon={
                    <Icon
                        icon={<PersonSearch />}
                        size={24}
                        color={theme.palette.text.primary}
                    />
                }
                variant="contained"
                onClick={handleSimulateUser}
                style={{
                    backgroundColor: theme.palette.grey[200],
                    color: theme.palette.text.primary,
                }}
                sx={{ mt: 2 }}
            >
                {t`tenant-details.contract-section-simulate-user-button-label`}
            </Button>
        )
    }

    return (
        <TabContext value={tab}>
            <HeaderWrapper>
                <H1>{t`tenants-list.header-title`}</H1>
                <BreadcrumbContainer>
                    <BackCursor>
                        <Typography
                            onClick={handleSubHeaderClick}
                            variant="body1"
                            color="#737373"
                        >
                            {t`tenants-list.header-title` + ` / `}
                        </Typography>
                    </BackCursor>
                    <Typography variant="body1" color="#333333">
                        {store.tenantDetails.name}
                    </Typography>
                </BreadcrumbContainer>
            </HeaderWrapper>
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                }}
                data-testid="details-tabs"
            >
                <TabList
                    variant={smallScreen ? "fullWidth" : "standard"}
                    onChange={handleTabChange}
                >
                    <Tab
                        style={getTabStyle("details")}
                        label={t`tenants-details.details-tab`.toUpperCase()}
                        value="details"
                    />
                    {gstore.session.hasAccessToModule("invoices") && (
                        <Tab
                            style={getTabStyle("invoices")}
                            label={t`tenants-details.invoices-tab`.toUpperCase()}
                            value="invoices"
                            data-testid="invoice-tab"
                        />
                    )}
                </TabList>
            </Box>
            {tab === "details" && (
                <TabPanelWrapper>
                    <TabPanelContainer value="details">
                        {tenantDetailsLoading || tenantDetailsSubmitLoading ? (
                            <CenteredSpinner height="500px" />
                        ) : (
                            <div>
                                <DetailsHeaderView>
                                    <UserAvatarWrapper>
                                        <Avatar
                                            size={40}
                                            name={
                                                store.tenantDetails.name ?? ""
                                            }
                                        />
                                        <Typography
                                            variant="h5"
                                            color="#333333"
                                            marginLeft={"16px"}
                                            data-testid="tenantName"
                                        >
                                            {store.tenantDetails.name}
                                        </Typography>
                                    </UserAvatarWrapper>
                                </DetailsHeaderView>

                                <ContactGridWrapper container>
                                    <GridLeftWrapper
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={6}
                                        xl={6}
                                    >
                                        <ContactDetailsWrapper>
                                            <Typography
                                                variant="h6"
                                                color="#333333"
                                            >
                                                <EditButtonWrapper container>
                                                    {t`tenants-details.contact-section-title`}
                                                    <span>
                                                        {!smallScreen && (
                                                            <Button
                                                                variant="contained"
                                                                disabled={
                                                                    store.isEdit &&
                                                                    !store.fields.getIsDirty()
                                                                }
                                                                startIcon={
                                                                    !store.isEdit ? (
                                                                        <Icon
                                                                            icon={
                                                                                <EditPencil />
                                                                            }
                                                                            color={
                                                                                theme
                                                                                    .palette
                                                                                    .text
                                                                                    .primary
                                                                            }
                                                                        />
                                                                    ) : null
                                                                }
                                                                style={{
                                                                    backgroundColor:
                                                                        store.isEdit
                                                                            ? theme
                                                                                  .palette
                                                                                  .brand
                                                                                  .red
                                                                                  .main
                                                                            : theme
                                                                                  .palette
                                                                                  .grey[200],
                                                                    color: store.isEdit
                                                                        ? theme
                                                                              .palette
                                                                              .common
                                                                              .white
                                                                        : theme
                                                                              .palette
                                                                              .text
                                                                              .primary,
                                                                }}
                                                                onClick={
                                                                    store.isEdit
                                                                        ? handleSaveDetails
                                                                        : handleEditChange
                                                                }
                                                            >
                                                                {store.isEdit
                                                                    ? t`tenants-details.button-save-edit-label`
                                                                    : t`tenants-details.button-edit-label`}
                                                            </Button>
                                                        )}
                                                    </span>
                                                </EditButtonWrapper>
                                            </Typography>
                                            {store.isEdit ? (
                                                <TextField
                                                    label={t`manage-users-create-edit-copy-modal.full-name`}
                                                    placeholder={t`manage-users-create-edit-copy-modal.full-name`}
                                                    onChange={store.fields.setter(
                                                        "fullname",
                                                    )}
                                                    value={store.fields.get(
                                                        "fullname",
                                                    )}
                                                    helperText={store.fields.error(
                                                        "fullname",
                                                    )}
                                                    error={Boolean(
                                                        store.fields.error(
                                                            "fullname",
                                                        ),
                                                    )}
                                                    style={{
                                                        marginTop: "16px",
                                                    }}
                                                />
                                            ) : (
                                                ContactDetailsRow(
                                                    t`manage-users-create-edit-copy-modal.full-name`,
                                                    store.tenantDetails.name,
                                                )
                                            )}

                                            {store.isEdit ? (
                                                <TextField
                                                    disabled
                                                    label={t`tenants-details.personal-id-tag`}
                                                    placeholder={t`tenants-details.personal-id-tag`}
                                                    onChange={store.fields.setter(
                                                        "personal_id",
                                                    )}
                                                    value={store.fields.get(
                                                        "personal_id",
                                                    )}
                                                    helperText={store.fields.error(
                                                        "personal_id",
                                                    )}
                                                    style={{
                                                        marginTop: "16px",
                                                    }}
                                                />
                                            ) : (
                                                ContactDetailsRow(
                                                    t`tenants-details.personal-id-tag`,
                                                    maskText(
                                                        store.tenantDetails
                                                            .personal_id,
                                                        4,
                                                        "back",
                                                    ),
                                                )
                                            )}

                                            {store.isEdit ? (
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={6}
                                                        lg={6}
                                                        xl={6}
                                                        style={{
                                                            paddingRight:
                                                                smallScreen
                                                                    ? "0px"
                                                                    : "8px",
                                                        }}
                                                    >
                                                        <TextField
                                                            label={t`manage-users-create-edit-copy-modal.email-address`}
                                                            placeholder={t`manage-users-create-edit-copy-modal.email-address`}
                                                            onChange={store.fields.setter(
                                                                "email",
                                                            )}
                                                            value={store.fields.get(
                                                                "email",
                                                            )}
                                                            helperText={store.fields.error(
                                                                "email",
                                                            )}
                                                            error={Boolean(
                                                                store.fields.error(
                                                                    "email",
                                                                ),
                                                            )}
                                                            style={{
                                                                marginTop:
                                                                    "16px",
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={6}
                                                        lg={6}
                                                        xl={6}
                                                        style={{
                                                            paddingLeft:
                                                                smallScreen
                                                                    ? "0px"
                                                                    : "8px",
                                                        }}
                                                    >
                                                        <TextField
                                                            label={t`contact-form-detail-modal.reporter-section.tenant-phonenumber-label`}
                                                            placeholder={t`contact-form-detail-modal.reporter-section.tenant-phonenumber-label`}
                                                            onChange={store.fields.setter(
                                                                "phone_no",
                                                            )}
                                                            value={store.fields.get(
                                                                "phone_no",
                                                            )}
                                                            helperText={store.fields.error(
                                                                "phone_no",
                                                            )}
                                                            error={Boolean(
                                                                store.fields.error(
                                                                    "phone_no",
                                                                ),
                                                            )}
                                                            style={{
                                                                marginTop:
                                                                    "16px",
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            ) : (
                                                <div>
                                                    {ContactDetailsRow(
                                                        t`manage-users-create-edit-copy-modal.email-address`,
                                                        store.tenantDetails
                                                            .email,
                                                    )}
                                                    {ContactDetailsRow(
                                                        t`contact-form-detail-modal.reporter-section.tenant-phonenumber-label`,
                                                        store.tenantDetails
                                                            .phone_no,
                                                    )}
                                                </div>
                                            )}
                                            {smallScreen && (
                                                <Button
                                                    variant="contained"
                                                    disabled={
                                                        store.isEdit &&
                                                        !store.fields.getIsDirty()
                                                    }
                                                    startIcon={
                                                        !store.isEdit ? (
                                                            <Icon
                                                                icon={
                                                                    <EditPencil />
                                                                }
                                                                color={
                                                                    theme
                                                                        .palette
                                                                        .text
                                                                        .primary
                                                                }
                                                            />
                                                        ) : null
                                                    }
                                                    style={{
                                                        backgroundColor:
                                                            store.isEdit
                                                                ? theme.palette
                                                                      .brand.red
                                                                      .main
                                                                : theme.palette
                                                                      .grey[200],
                                                        color: store.isEdit
                                                            ? theme.palette
                                                                  .common.white
                                                            : theme.palette.text
                                                                  .primary,
                                                        width: "100%",
                                                        marginTop: "24px",
                                                    }}
                                                    onClick={
                                                        store.isEdit
                                                            ? handleSaveDetails
                                                            : handleEditChange
                                                    }
                                                >
                                                    {store.isEdit
                                                        ? t`tenants-details.button-save-edit-label`
                                                        : t`tenants-details.button-edit-label`}
                                                </Button>
                                            )}
                                        </ContactDetailsWrapper>
                                        <AppSettingsWrapper>
                                            <Typography
                                                variant="h6"
                                                color="#333333"
                                            >
                                                {t`tenants-details.app-settings-section-title`}
                                            </Typography>
                                            {AppSettingsRow(
                                                t`tenants-details.registered-tag`,
                                                store.tenantDetails
                                                    .is_registered === true
                                                    ? "Yes"
                                                    : "No",
                                            )}
                                            {AppSettingsRow(
                                                t`tenants-details.registered-date-tag`,
                                                store.tenantDetails
                                                    .registration_date !==
                                                    undefined
                                                    ? formatDate(
                                                          new Date(
                                                              store.tenantDetails.registration_date,
                                                          ),
                                                      )
                                                    : "",
                                            )}
                                            {gstore.session.hasAccessToModule(
                                                "tenants_invoice_in_app",
                                            ) && (
                                                <>
                                                    <Typography
                                                        variant="h6"
                                                        color="#333333"
                                                        marginTop={"24px"}
                                                    >
                                                        {t`tenants-details.invoicing-section-title`}
                                                    </Typography>
                                                    <UserAvatarWrapper>
                                                        <FormControlContainer
                                                            checked={store.fields.get(
                                                                "is_post_notification",
                                                            )}
                                                            onChange={
                                                                updateInvoicingInApp
                                                            }
                                                            control={
                                                                <Switch color="error" />
                                                            }
                                                            label={t`tenants-details.opted-out-from-invoices-in-app-tag`}
                                                        />
                                                        <InvoiceSwitchWrapper>
                                                            <FormFieldSet
                                                                children
                                                                header={""}
                                                                tooltip={t`tenants-details.invoicing-in-app-tooltip`}
                                                            />
                                                        </InvoiceSwitchWrapper>
                                                    </UserAvatarWrapper>
                                                </>
                                            )}
                                        </AppSettingsWrapper>
                                    </GridLeftWrapper>
                                    <GridRightWrapper
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={6}
                                        xl={6}
                                    >
                                        <ContactDetailsWrapper>
                                            <Typography
                                                variant="h6"
                                                color="#333333"
                                            >
                                                {t`tenants-details.contract-details-section-title`}
                                            </Typography>
                                            {store.tenantDetails.contracts !==
                                                undefined &&
                                                store.tenantDetails.contracts
                                                    .length > 1 && (
                                                    <FormControl
                                                        sx={{
                                                            width: "100%",
                                                            marginTop: "16px",
                                                        }}
                                                    >
                                                        <InputLabel id="status-filter-label">
                                                            {t`tenants-details.address-tag`}
                                                        </InputLabel>
                                                        <Select
                                                            labelId="address-select-label"
                                                            id="address-select"
                                                            label={t`tenants-details.address-tag`}
                                                            value={`${store.selectedContractID}`}
                                                            onChange={
                                                                handleContractChange
                                                            }
                                                            data-testid="address-select"
                                                        >
                                                            {store.tenantDetails.contracts?.map(
                                                                (contract) => (
                                                                    <MenuItem
                                                                        key={
                                                                            contract.address
                                                                        }
                                                                        value={
                                                                            contract.lease_contract_id
                                                                        }
                                                                        data-testid="address-select-item"
                                                                    >
                                                                        {
                                                                            contract.address
                                                                        }
                                                                    </MenuItem>
                                                                ),
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            {store.showContract && (
                                                <>
                                                    <AddressWrapper>
                                                        {ContactDetailsRow(
                                                            t`tenants-details.full-address-tag`,
                                                            store
                                                                .selectedContractObject
                                                                .address,
                                                        )}
                                                        {ContactDetailsRow(
                                                            t`tenants-details.property-owner-name-label`,
                                                            store
                                                                .selectedContractObject
                                                                .property_owner_name,
                                                        )}
                                                        {ContactDetailsRow(
                                                            t`tenants-details.legal-entity-name-label`,
                                                            store
                                                                .selectedContractObject
                                                                .legal_entity_name,
                                                        )}
                                                        {ContactDetailsRow(
                                                            t`client-management-view.property`,
                                                            store
                                                                .selectedContractObject
                                                                .property_name,
                                                        )}
                                                        {ContactDetailsRow(
                                                            t`tenants-details.agreement-no-label`,
                                                            store
                                                                .selectedContractObject
                                                                .agreement_no,
                                                        )}
                                                        {ContactDetailsRow(
                                                            t`tenants-details.object-no-tag`,
                                                            store
                                                                .selectedContractObject
                                                                .object_id,
                                                        )}
                                                        {ContactDetailsRow(
                                                            t`tenants-details.object-no-lantmäteriet-tag`,
                                                            store
                                                                .selectedContractObject
                                                                .lantmateriet_number,
                                                        )}
                                                        {ContactDetailsRow(
                                                            t`tenants-details.contract-status-tag`,
                                                            store
                                                                .selectedContractObject
                                                                .status ===
                                                                "EXPIRED"
                                                                ? "Inactive"
                                                                : "Active",
                                                        )}
                                                        {ContactDetailsRow(
                                                            t`tenants-details.move-in-date-tag`,
                                                            store
                                                                .selectedContractObject
                                                                .lease_from !=
                                                                null
                                                                ? formatDate(
                                                                      new Date(
                                                                          store.selectedContractObject.lease_from,
                                                                      ),
                                                                  )
                                                                : "",
                                                        )}
                                                        {ContactDetailsRow(
                                                            t`tenants-details.move-out-date-tag`,
                                                            store
                                                                .selectedContractObject
                                                                .lease_to !=
                                                                null
                                                                ? formatDate(
                                                                      new Date(
                                                                          store.selectedContractObject.lease_to,
                                                                      ),
                                                                  )
                                                                : "",
                                                        )}
                                                        {ContactDetailsRow(
                                                            t`tenants-details.role-tag`,
                                                            store
                                                                .selectedContractObject
                                                                .role,
                                                        )}
                                                        {ContactDetailsRow(
                                                            t`tenants-details.size-tag`,
                                                            store.selectedContractObject.area?.toString(),
                                                        )}
                                                        {ContactDetailsRow(
                                                            t`tenants-details.rent-fee-tag`,
                                                            store
                                                                .selectedContractObject
                                                                .annual_rent,
                                                        )}
                                                        {ContactDetailsRow(
                                                            t`tenants-details.contract-id-tag`,
                                                            store.selectedContractObject.lease_contract_id?.toString(),
                                                        )}
                                                        {ContactDetailsRow(
                                                            t`tenants-details.lease-external-id-label`,
                                                            store
                                                                .selectedContractObject
                                                                .lease_contract_external_id,
                                                        )}
                                                        {ContactDetailsRow(
                                                            t`tenants-details.tenant-external-id-label`,
                                                            store
                                                                .selectedContractObject
                                                                .lease_contract_tenant_external_id,
                                                        )}
                                                        {ContactDetailsRow(
                                                            t`tenants-details.object-external-id-label`,
                                                            store
                                                                .selectedContractObject
                                                                .property_object_external_id,
                                                        )}
                                                    </AddressWrapper>
                                                    {gstore.session.hasAccessToModule(
                                                        "simulate_tenant",
                                                    ) && renderSimulateUser()}
                                                </>
                                            )}
                                        </ContactDetailsWrapper>
                                    </GridRightWrapper>
                                </ContactGridWrapper>
                            </div>
                        )}
                    </TabPanelContainer>
                </TabPanelWrapper>
            )}
            {tab === "invoices" && (
                <TabPanelWrapper>
                    <TabPanelContainer value="invoices">
                        <ListPage
                            header={header}
                            filter={filter}
                            loading={tableIsLoading}
                        >
                            <InvoicesList invoices={store.invoices} />
                        </ListPage>
                    </TabPanelContainer>
                </TabPanelWrapper>
            )}
        </TabContext>
    )
})

export const TenantsDetailView = observer(() => (
    <StoreProvider Store={TenantsDetailsStore}>
        <View />
    </StoreProvider>
))
