import React from "react"
import { makeAutoObservable } from "mobx"
import { t } from "@lingui/macro"

import { FormFields } from "src/lib/form-fields"
import { Channel } from "src/channel"
import { reportError } from "src/lib/report"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { IChatbotDispatchesFormFields } from "src/modals/chatbot-dispatches/types/formFields"
import { IChatbotDispatch } from "src/types/chatbot-dispatches/chatbotDispatch"
import {
    ChatbotAdminService,
    chatbot_CreateDispatchRequest,
    chatbot_DispatchResponse,
} from "src/api"
import { shouldPublishSegments } from "src/helpers/shouldPublishSegments"
import { shouldUpdatePublishedSegments } from "src/helpers/shouldUpdatePublishedSegments"

export class ChatbotDispatchesStore {
    static Context = React.createContext<ChatbotDispatchesStore | null>(null)
    formFields = new FormFields<IChatbotDispatchesFormFields>({})
    isLoading = false

    constructor() {
        makeAutoObservable(this)
    }

    //#region setters
    setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading)

    setPublishedSegments = (segments: number[]) =>
        this.formFields.set("segment_ids", segments)

    determineAccessGroupId = (
        mode: string | undefined,
        accessGroupId: number,
        dispatch: IChatbotDispatch | undefined,
    ) => {
        if (
            mode === "Copy" &&
            (accessGroupId === DEFAULT_ACCESS_GROUP.id ||
                accessGroupId === undefined)
        ) {
            return undefined
        }

        if (mode === "Copy" && accessGroupId !== DEFAULT_ACCESS_GROUP.id) {
            this.formFields.setError("access_group_id", "")
        }

        return dispatch?.access_group_id ?? accessGroupId
    }

    //#endregion

    //#region store operations
    init = (
        accessGroupId: number,
        dispatch?: IChatbotDispatch,
        mode?: string,
    ) => {
        this.formFields.init({
            dispatch_id: dispatch?.dispatch_id ?? undefined,
            name: dispatch?.name ?? "",
            access_group_id: this.determineAccessGroupId(
                mode,
                accessGroupId,
                dispatch,
            ),
            ai_name: dispatch?.ai_name ?? "",
            description: dispatch?.description ?? "",
            message: dispatch?.message ?? "",
            button_text: dispatch?.button_text ?? "",
            dispatch_url: dispatch?.dispatch_url ?? "",
            access_type:
                mode === "Copy" ? "WRITE" : dispatch?.access_type ?? undefined,
            segment_ids: dispatch?.segment_ids ?? [],
            dispatch_category: dispatch?.dispatch_category,
        })

        if (mode === "Copy") {
            this.formFields.set("dispatch_id", undefined)
            this.formFields.set("segment_ids", [])
        }
    }

    create = async () => {
        const publishedIn = this.formFields.get("segment_ids")
        let createResponse: chatbot_DispatchResponse = {}

        try {
            // create
            await this.formFields.catchErrors(async () => {
                createResponse =
                    await ChatbotAdminService.postV1AdminChatbotDispatch({
                        request: this.getRequestData(),
                    })
            })

            // Publish
            if (
                createResponse.dispatch_id !== undefined &&
                shouldPublishSegments(publishedIn)
            ) {
                await ChatbotAdminService.putV1AdminChatbotDispatchPublish({
                    dispatchId: createResponse.dispatch_id.toString(),
                    request: {
                        published_in: publishedIn,
                    },
                })
            }

            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "chatbot-dispatches",
                    action: "update",
                },
            })
        } catch (error) {
            reportError(t`chatbot-dispatches-modal.save-dispatch-fail`, error)
        } finally {
            this.setIsLoading(false)
        }
    }

    update = async (item: IChatbotDispatch) => {
        const publishedIn = this.formFields.get("segment_ids")
        try {
            await this.formFields.catchErrors(async () => {
                await ChatbotAdminService.putV1AdminChatbotDispatch({
                    request: this.getRequestData(),
                    dispatchId: item.dispatch_id.toString(),
                })
            })

            // Publish
            if (shouldUpdatePublishedSegments(item.segment_ids, publishedIn)) {
                await ChatbotAdminService.putV1AdminChatbotDispatchPublish({
                    dispatchId: item.dispatch_id.toString(),
                    request: {
                        published_in: publishedIn,
                    },
                })
            }

            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "chatbot-dispatches",
                    action: "update",
                },
            })
        } catch (error) {
            reportError(t`chatbot-dispatches-modal.save-dispatch-fail`, error)
        } finally {
            this.setIsLoading(false)
        }
    }
    //#endregion

    //#region helpers
    isEditMode = () => this.formFields.get("dispatch_id") !== undefined

    getRequestData = (): chatbot_CreateDispatchRequest => {
        return {
            name: this.formFields.get("name"),
            ai_name: this.formFields.get("ai_name"),
            access_group_id: this.formFields.get("access_group_id"),
            description: this.formFields.get("description"),
            message: this.formFields.get("message"),
            button_text: this.formFields.get("button_text"),
            data: {
                dispatch_url: this.formFields.get("dispatch_url"),
                dispatch_identifier: "LINK", // only supported identifier for now
                dispatch_category: this.formFields.get("dispatch_category"),
            },
        }
    }

    getPublishedSegments = () => this.formFields.get("segment_ids") ?? []

    private isValidDispatchLink = (url: string) => {
        try {
            new URL(url)
            return true
        } catch (error) {
            return false
        }
    }

    validateRequiredFields = (
        requiredFields: (keyof IChatbotDispatchesFormFields)[],
    ) => {
        this.formFields.clearErrors()
        requiredFields.forEach((field) => {
            const requiredField = field
            const formField = this.formFields.get(requiredField)
            const value =
                typeof formField === "string" ? formField.trim() : formField
            const isValid = this.isValidDispatchLink(value as string)

            if (value == null || value === "") {
                this.formFields.setError(requiredField, t`errors.required`)
            } else if (requiredField === "dispatch_url" && !isValid) {
                this.formFields.setError(requiredField, t`errors.invalid-url`)
            } else {
                this.formFields.clearError(requiredField)
            }
        })
    }

    validateAndSubmit = async (
        item?: IChatbotDispatch,
        mode?: string | undefined,
    ) => {
        this.setIsLoading(true)
        this.validateRequiredFields([
            "name",
            "message",
            "ai_name",
            "description",
            "button_text",
            "dispatch_url",
            "access_group_id",
        ])
        if (this.formFields.hasErrors() === true) {
            this.setIsLoading(false)
            return
        }

        mode === "Copy" || item?.dispatch_id == null
            ? await this.create()
            : await this.update(item)
    }

    toggleSelfServiceStatistics = () => {
        this.formFields.set(
            "dispatch_category",
            this.getDispatchCategoryAsBoolean() ? undefined : "SELF_SERVICE",
        )
    }

    getDispatchCategoryAsBoolean = () =>
        this.formFields.get("dispatch_category") === "SELF_SERVICE"

    //#endregion
}
