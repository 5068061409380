import React from "react"
import { makeAutoObservable } from "mobx"

import { t } from "@lingui/macro"

import { Channel } from "src/channel"
import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"
import { AccessGroupsAdminService } from "src/api"
import { reportError, reportUnhandledApiError } from "src/lib/report"

interface IAccessGroups {
    name: string
    id: number
}

export class AccessGroupsStore {
    static Context = React.createContext<AccessGroupsStore | null>(null)
    static LoadingKeys = createLoadingKeys("pageLoad")

    constructor() {
        makeAutoObservable(this)
    }

    private repositoryUpdatesListenerDisposer?: () => void

    private _accessGroups: IAccessGroups[] = []
    private setAccessGroups(accessGroups: IAccessGroups[]) {
        this._accessGroups = accessGroups
    }

    accessGroups = new Pagination(
        (params) => {
            const pageStart = (params.page - 1) * params.pageSize
            const pageAccessGroups = this._accessGroups.slice(
                pageStart,
                pageStart + params.pageSize,
            )
            return {
                items: pageAccessGroups,
                count: this._accessGroups.length,
                sourceItems: this._accessGroups,
            }
        },
        { static: true },
    )

    async init() {
        this.listenToAccessGroupsRepositoryUpdated()
        await this.loadAccessGroups()
    }

    dispose() {
        this.repositoryUpdatesListenerDisposer?.()
    }

    private listenToAccessGroupsRepositoryUpdated() {
        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "access-groups"
                ) {
                    await this.loadAccessGroups()
                }
            },
        )
    }

    async deleteAccessGroup(id: number) {
        try {
            await AccessGroupsAdminService.deleteV1AdminAccessGroup({
                accessGroupId: id,
            })
        } catch (e) {
            reportUnhandledApiError(e)
        } finally {
            await this.loadAccessGroups()
        }
    }

    private async loadAccessGroups() {
        try {
            const response =
                await AccessGroupsAdminService.getV1AdminAccessGroup()

            this.setAccessGroups(
                response?.map((accessGroup) => ({
                    name: accessGroup.name ?? "",
                    id: accessGroup.id ?? 0,
                })) ?? [],
            )
        } catch (e) {
            reportError(t`access-groups-store.failed-to-load-access-groups`, e)
        } finally {
            await this.accessGroups.loadInitialPage()
        }
    }
}
