import { action, makeAutoObservable } from "mobx"

import { Channel } from "src/channel"

export class LoadingStore implements IDisposable {
    private active: { [K in symbol]: true } = {}
    private loadingMessagesListenerDisposer?: () => void

    constructor() {
        makeAutoObservable(this)
        this.listenToLoadingMessages()
    }

    is(symbol: symbol) {
        return Boolean(this.active[symbol])
    }

    dispose() {
        this.loadingMessagesListenerDisposer?.()
    }

    private listenToLoadingMessages() {
        this.loadingMessagesListenerDisposer = Channel.addListener(
            action((message) => {
                if (message.name === "loading/started") {
                    this.active[message.payload.key] = true
                } else if (message.name === "loading/stopped") {
                    delete this.active[message.payload.key]
                }
            }),
        )
    }
}
