import { styled } from "@mui/material"

const Wrap = styled("div")(({ theme }) => ({
    background: theme.palette.grey[100],
    borderRadius: "0 0 4px 4px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    padding: "16px",
    flex: 0,
}))

const InputWrapper = styled("div")(() => ({
    width: "100%",
    display: "flex",
    flexDirection: "row",
}))

export { Wrap, InputWrapper }
