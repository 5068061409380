/* eslint-disable @typescript-eslint/no-explicit-any */
import { t } from "@lingui/macro"

import {
    generalFieldsVerificationDataSet,
    purchaseCollapsibleLinkFieldsVerificationDataSet,
    purchaseRichLinkFieldsVerificationDataSet,
    purchaseSimpleLinkFieldsVerificationDataSet,
    salesFieldsVerificationDataSet,
} from "src/modals/embedded-integration-v2/constants/fieldsVerificationDataSet"
import { IDynamicFieldItemProps } from "src/modals/embedded-integration-v2/types/dynamicFieldItem"
import {
    IGeneralFieldsVerificationDataSet,
    IPurchaseCollapsibleLinkVerificationDataSet,
    IPurchaseRichLinkVerificationDataSet,
    IPurchaseSimpleLinkVerificationDataSet,
    ISalesFieldsVerificationDataSet,
} from "src/modals/embedded-integration-v2/types/fieldsVerificationDataset"
import { IGeneralDynamicFieldsItemProps } from "src/modals/embedded-integration-v2/types/generalDynamicFieldsItem"
import { IPurchaseCollapsibleLinkDynamicFieldsItemProps } from "src/modals/embedded-integration-v2/types/purchaseCollapsibleLinkFieldsItem"
import { IPurchaseRichLinkDynamicFieldsItemProps } from "src/modals/embedded-integration-v2/types/purchaseRichLinkFieldsItem"
import { IPurchaseSimpleLinkDynamicFieldsItemProps } from "src/modals/embedded-integration-v2/types/purchaseSimplelinkFieldsItem"
import { ISalesDynamicFieldsItemProps } from "src/modals/embedded-integration-v2/types/salesDynamicFieldsItem"

export const validateGeneralDynamicFields = (
    generalDynamicFieldsList: IGeneralDynamicFieldsItemProps[],
    setHasError: (value: boolean) => void,
) => {
    generalDynamicFieldsList.map((dynamicField) => {
        Object.keys(generalFieldsVerificationDataSet).forEach((key: string) => {
            const fieldKey = key as keyof IGeneralFieldsVerificationDataSet
            const dynamicFieldItem = dynamicField[fieldKey]
            const dynamicFieldItemIsArray = dynamicFieldItem instanceof Array

            if (dynamicFieldItemIsArray) {
                dynamicFieldItem.map((item) => {
                    if (item.description === "" && item.required !== false) {
                        item.errorMessage = t`errors.required`
                        setHasError(true)
                    }

                    return item
                })
            } else if (dynamicFieldItem.value === "") {
                dynamicFieldItem.errorMessage = t`errors.required`
                setHasError(true)
            }
        })
        return dynamicField
    })
    return generalDynamicFieldsList
}

export const validateSalesDynamicFields = (
    salesDynamicFieldsList: ISalesDynamicFieldsItemProps[],
    setHasError: (value: boolean) => void,
) => {
    salesDynamicFieldsList.map((dynamicField) => {
        Object.keys(salesFieldsVerificationDataSet).forEach((key: string) => {
            const fieldKey = key as keyof ISalesFieldsVerificationDataSet
            const dynamicFieldItem = dynamicField[fieldKey]
            if (dynamicFieldItem.value === "") {
                dynamicFieldItem.errorMessage = t`errors.required`
                setHasError(true)
            }
        })
        return dynamicField
    })
    return salesDynamicFieldsList
}

export const validatePurchaseRichLinkDynamicFields = (
    purchaseRichLinkDynamicFieldsList: IPurchaseRichLinkDynamicFieldsItemProps[],
    setHasError: (value: boolean) => void,
) => {
    purchaseRichLinkDynamicFieldsList.map((dynamicField) => {
        Object.keys(purchaseRichLinkFieldsVerificationDataSet).forEach(
            (key: string) => {
                const fieldKey =
                    key as keyof IPurchaseRichLinkVerificationDataSet
                const dynamicFieldItem = dynamicField[fieldKey]
                if (dynamicFieldItem.value === "") {
                    dynamicFieldItem.errorMessage = t`errors.required`
                    setHasError(true)
                }
            },
        )
        return dynamicField
    })
    return purchaseRichLinkDynamicFieldsList
}

export const validatePurchaseSimpleLinkDynamicFields = (
    purchaseSimpleLinkDynamicFieldsList: IPurchaseSimpleLinkDynamicFieldsItemProps[],
    setHasError: (value: boolean) => void,
) => {
    purchaseSimpleLinkDynamicFieldsList.map((dynamicField) => {
        Object.keys(purchaseSimpleLinkFieldsVerificationDataSet).forEach(
            (key: string) => {
                const fieldKey =
                    key as keyof IPurchaseSimpleLinkVerificationDataSet
                const dynamicFieldItem = dynamicField[fieldKey]
                if (dynamicFieldItem.value === "") {
                    dynamicFieldItem.errorMessage = t`errors.required`
                    setHasError(true)
                }
            },
        )
        return dynamicField
    })
    return purchaseSimpleLinkDynamicFieldsList
}

export const validatePurchaseCollapsibleLinkDynamicFields = (
    purchaseCollapsibleLinkDynamicFieldsList: IPurchaseCollapsibleLinkDynamicFieldsItemProps[],
    setHasError: (value: boolean) => void,
) => {
    purchaseCollapsibleLinkDynamicFieldsList.map((dynamicField) => {
        Object.keys(purchaseCollapsibleLinkFieldsVerificationDataSet).forEach(
            (key: string) => {
                const fieldKey =
                    key as keyof IPurchaseCollapsibleLinkVerificationDataSet
                const dynamicFieldItem = dynamicField[fieldKey]
                if (dynamicFieldItem.value === "") {
                    dynamicFieldItem.errorMessage = t`errors.required`
                    setHasError(true)
                }
            },
        )
        return dynamicField
    })
    return purchaseCollapsibleLinkDynamicFieldsList
}

export const validatePurchaseStatusInfoPriceFields = (
    validatingFields: any,
    setHasError: (value: boolean) => void,
) => {
    validatingFields.map((item: IDynamicFieldItemProps) => {
        if (item.value === "") {
            item.errorMessage = t`errors.required`
            setHasError(true)
        }
        return item
    })
}
