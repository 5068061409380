import { MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { Flag, ItemContainer } from "src/layouts/DefaultLayout/styled"

import { useUniqueId } from "src/lib/unique-id"
import { i18n } from "src/locales"
import { Locale, Locales } from "src/locales/locale"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"

interface IProps {
    fullWidth?: boolean
}

export const I18nSelect = observer((props: IProps) => {
    const labelId = useUniqueId()
    const inputId = useUniqueId()

    const { session } = useStore(GlobalStore)

    const handleLanguageChange = useCallback(
        (event: SelectChangeEvent<Locale>) => {
            i18n.activate(event.target.value)
        },
        [],
    )

    return (
        <Select
            labelId={labelId}
            id={inputId}
            value={session.language}
            onChange={handleLanguageChange}
            size="small"
            variant="filled"
            renderValue={(value) => {
                switch (value) {
                    case Locale.English:
                        return (
                            <ItemContainer>
                                <Flag src={Locales[Locale.English].icon} />
                                {Locales[Locale.English].name}
                            </ItemContainer>
                        )
                    case Locale.Swedish:
                        return (
                            <ItemContainer>
                                <Flag src={Locales[Locale.Swedish].icon} />
                                {Locales[Locale.Swedish].name}
                            </ItemContainer>
                        )
                }
            }}
            fullWidth={props.fullWidth}
        >
            <MenuItem value={Locale.English}>
                <ItemContainer>
                    <Flag src={Locales[Locale.English].icon} />
                    {Locales[Locale.English].name}
                </ItemContainer>
            </MenuItem>
            <MenuItem value={Locale.Swedish}>
                <ItemContainer>
                    <Flag src={Locales[Locale.Swedish].icon} />
                    {Locales[Locale.Swedish].name}
                </ItemContainer>
            </MenuItem>
        </Select>
    )
})
