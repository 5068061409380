import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useMemo } from "react"

import { IStatusChipProps, StatusChip } from "src/components/StatusChip"

interface IProps {
    status: string
}

export const CampaignsStatusChip = observer(({ status }: IProps) => {
    const chipProps = useMemo((): IStatusChipProps => {
        switch (status) {
            case "active":
                return {
                    state: "positive",
                    label: t`campaign-view.status-chip-active-status`,
                }
            case "unpublished":
                return {
                    state: "negative",
                    label: t`campaign-view.status-chip-unpublished-status`,
                }
            case "scheduled":
                return {
                    state: "info",
                    label: t`campaign-view.status-chip-scheduled-status`,
                }
            default:
                return {
                    state: "other",
                    label: t`campaign-view.status-chip-draft-status`,
                }
        }
    }, [status])

    return <StatusChip {...chipProps} />
})
