/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { admin_GetMessagesRequest } from "../models/admin_GetMessagesRequest"
import type { admin_GetMessagesResponse } from "../models/admin_GetMessagesResponse"
import type { avy_api_pkg_segment_PublishResourceRequest } from "../models/avy_api_pkg_segment_PublishResourceRequest"
import type { avy_api_pkg_segment_PublishResourceResponse } from "../models/avy_api_pkg_segment_PublishResourceResponse"
import type { message_CreateMessageRequest } from "../models/message_CreateMessageRequest"
import type { message_CreateMessageResponse } from "../models/message_CreateMessageResponse"
import type { message_Message } from "../models/message_Message"
import type { message_PatchMessage } from "../models/message_PatchMessage"
import type { message_UpdateMessage } from "../models/message_UpdateMessage"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class MessageAdminService {
    /**
     * Create message
     * Create message creates and schedules a message
     * @returns message_CreateMessageResponse OK
     * @throws ApiError
     */
    public static noticeBoardCreateMessage({
        request,
    }: {
        /** Request body create message **/
        request: message_CreateMessageRequest
    }): CancelablePromise<message_CreateMessageResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/message",
            body: request,
        })
    }

    /**
     * Get messages
     * Get messages by admin id
     * @returns admin_GetMessagesResponse OK
     * @throws ApiError
     */
    public static noticeBoardGetMessageList({
        request,
        pageNumber = 1,
        pageSize = 10,
        accessGroupId,
    }: {
        /** Request body for get messages **/
        request: admin_GetMessagesRequest
        /** Pagination - number of page to load **/
        pageNumber?: number
        /** Pagination - size of pages to load **/
        pageSize?: number
        /** Access group ID filter **/
        accessGroupId?: number
    }): CancelablePromise<admin_GetMessagesResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/message/list",
            query: {
                page_number: pageNumber,
                page_size: pageSize,
                access_group_id: accessGroupId,
            },
            body: request,
        })
    }

    /**
     * Get message
     * Get message by id
     * @returns message_Message OK
     * @throws ApiError
     */
    public static noticeBoardGetMessage({
        messageId,
    }: {
        /** Message id **/
        messageId: number
    }): CancelablePromise<message_Message> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/message/{message_id}",
            path: {
                message_id: messageId,
            },
        })
    }

    /**
     * Update message
     * Update message updates partially and optionally re-publishes message. Only send fields that have to be updated.
     * @returns any OK
     * @throws ApiError
     */
    public static noticeBoardUpdateMessage({
        messageId,
        request,
    }: {
        /** Message ID **/
        messageId: number
        /** Request body update message **/
        request: message_UpdateMessage
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/message/{message_id}",
            path: {
                message_id: messageId,
            },
            body: request,
        })
    }

    /**
     * Delete message
     * Delete message by message id
     * @returns any OK
     * @throws ApiError
     */
    public static noticeBoardDeleteMessage({
        messageId,
    }: {
        /** Message ID **/
        messageId: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/message/{message_id}",
            path: {
                message_id: messageId,
            },
        })
    }

    /**
     * @deprecated
     * Partially update message
     * Update message updates partially and optionally re-publishes message. Only send fields that have to be updated.
     * @returns any OK
     * @throws ApiError
     */
    public static noticeBoardUpdateMessage1({
        messageId,
        request,
    }: {
        /** Message ID **/
        messageId: number
        /** Request body partially update message **/
        request: message_PatchMessage
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PATCH",
            url: "/v1/admin/message/{message_id}",
            path: {
                message_id: messageId,
            },
            body: request,
        })
    }

    /**
     * Publish message
     * Publish message by ID to selected segments
     * @returns avy_api_pkg_segment_PublishResourceResponse OK
     * @throws ApiError
     */
    public static noticeBoardPublishMessage({
        messageId,
        request,
    }: {
        /** ID of message to publish **/
        messageId: number
        /** Request body to set where message is published **/
        request: avy_api_pkg_segment_PublishResourceRequest
    }): CancelablePromise<avy_api_pkg_segment_PublishResourceResponse> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/message/{message_id}/publish",
            path: {
                message_id: messageId,
            },
            body: request,
        })
    }
}
