/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { avy_api_pkg_segment_PublishResourceRequest } from "../models/avy_api_pkg_segment_PublishResourceRequest"
import type { avy_api_pkg_segment_PublishResourceResponse } from "../models/avy_api_pkg_segment_PublishResourceResponse"
import type { contact_Admin } from "../models/contact_Admin"
import type { contact_ContactFormAdminUpdate } from "../models/contact_ContactFormAdminUpdate"
import type { contact_ContactFormListResponse } from "../models/contact_ContactFormListResponse"
import type { contact_ContactFormTypeListResponse } from "../models/contact_ContactFormTypeListResponse"
import type { contact_ContactFormTypeUpdate } from "../models/contact_ContactFormTypeUpdate"
import type { contact_ContactFormWithTypeAndMessages } from "../models/contact_ContactFormWithTypeAndMessages"
import type { contact_CreateMessage } from "../models/contact_CreateMessage"
import type { contact_DefaultAssigneeUpdate } from "../models/contact_DefaultAssigneeUpdate"
import type { contact_GetDefaultAssigneesResponse } from "../models/contact_GetDefaultAssigneesResponse"
import type { contact_Message } from "../models/contact_Message"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class ContactFormAdminService {
    /**
     * Get contact forms
     * Get contact form list
     * @returns contact_ContactFormListResponse OK
     * @throws ApiError
     */
    public static getV1AdminContactForm({
        assignee,
        pageNumber = 1,
        pageSize = 10,
        accessGroupId,
        filter,
    }: {
        /** Optional assignee filter **/
        assignee?: number
        /** Pagination - number of page to load **/
        pageNumber?: number
        /** Pagination - size of pages to load **/
        pageSize?: number
        /** Access group ID for which to load the information items **/
        accessGroupId?: number
        /** Advanced grid filter (JSON object) **/
        filter?: string
    }): CancelablePromise<contact_ContactFormListResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/contact-form",
            query: {
                assignee: assignee,
                page_number: pageNumber,
                page_size: pageSize,
                access_group_id: accessGroupId,
                filter: filter,
            },
        })
    }

    /**
     * Get contact form handlers
     * Get a list of admins that can be assigned to contact forms from a certain property or a list of assigned admins
     * @returns contact_Admin OK
     * @throws ApiError
     */
    public static getV1AdminContactFormAdmins({
        propertyId,
    }: {
        /** Property ID **/
        propertyId?: number
    }): CancelablePromise<Array<contact_Admin>> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/contact-form/admins",
            query: {
                property_id: propertyId,
            },
        })
    }

    /**
     * List default assignees
     * Returns a list of properties with their default assignees
     * @returns contact_GetDefaultAssigneesResponse OK
     * @throws ApiError
     */
    public static getV1AdminContactFormDefaultAssignee({
        pageNumber = 1,
        pageSize = 10,
    }: {
        /** Pagination - number of page to load **/
        pageNumber?: number
        /** Pagination - size of pages to load **/
        pageSize?: number
    }): CancelablePromise<contact_GetDefaultAssigneesResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/contact-form/default-assignee",
            query: {
                page_number: pageNumber,
                page_size: pageSize,
            },
        })
    }

    /**
     * Update default assignee
     * Updates the default assignee for a property
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminContactFormDefaultAssignee({
        request,
    }: {
        /** Request body default assignee update **/
        request: contact_DefaultAssigneeUpdate
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/contact-form/default-assignee",
            body: request,
        })
    }

    /**
     * List contact form types
     * List contact form types
     * @returns contact_ContactFormTypeListResponse OK
     * @throws ApiError
     */
    public static getV1AdminContactFormType({
        pageNumber = 1,
        pageSize = 10,
        sort,
    }: {
        /** Pagination - number of page to load **/
        pageNumber?: number
        /** Pagination - size of pages to load **/
        pageSize?: number
        /** Sorting - column to use for sort **/
        sort?: string
    }): CancelablePromise<contact_ContactFormTypeListResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/contact-form/type",
            query: {
                page_number: pageNumber,
                page_size: pageSize,
                sort: sort,
            },
        })
    }

    /**
     * Update contact form type
     * Update contact form type by id
     * @returns any OK
     * @throws ApiError
     */
    public static patchV1AdminContactFormType({
        contactFormTypeId,
        request,
    }: {
        /** ID of contact form type **/
        contactFormTypeId: number
        /** Request body contact form type **/
        request: contact_ContactFormTypeUpdate
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PATCH",
            url: "/v1/admin/contact-form/type/{contact_form_type_id}",
            path: {
                contact_form_type_id: contactFormTypeId,
            },
            body: request,
        })
    }

    /**
     * Publish contact form type
     * Publish contact form type by id
     * @returns avy_api_pkg_segment_PublishResourceResponse OK
     * @throws ApiError
     */
    public static putV1AdminContactFormTypePublish({
        contactFormTypeId,
        request,
    }: {
        /** ID of contact form type to publish **/
        contactFormTypeId: number
        /** Request body to set where contact form types are published **/
        request: avy_api_pkg_segment_PublishResourceRequest
    }): CancelablePromise<avy_api_pkg_segment_PublishResourceResponse> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/contact-form/type/{contact_form_type_id}/publish",
            path: {
                contact_form_type_id: contactFormTypeId,
            },
            body: request,
        })
    }

    /**
     * Get contact form
     * Get contact form by ID
     * @returns contact_ContactFormWithTypeAndMessages OK
     * @throws ApiError
     */
    public static contactFormGetOne({
        contactFormId,
    }: {
        /** ID of contact form **/
        contactFormId: number
    }): CancelablePromise<contact_ContactFormWithTypeAndMessages> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/contact-form/{contact_form_id}",
            path: {
                contact_form_id: contactFormId,
            },
        })
    }

    /**
     * Update contact form
     * Update contact form by ID
     * @returns any OK
     * @throws ApiError
     */
    public static contactFormUpdate({
        contactFormId,
        request,
    }: {
        /** ID of contact form **/
        contactFormId: number
        /** Request body contact form **/
        request: contact_ContactFormAdminUpdate
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/contact-form/{contact_form_id}",
            path: {
                contact_form_id: contactFormId,
            },
            body: request,
        })
    }

    /**
     * Delete contact form
     * Delete contact form by ID
     * @returns any OK
     * @throws ApiError
     */
    public static deleteV1AdminContactForm({
        contactFormId,
    }: {
        /** ID of contact form **/
        contactFormId: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/contact-form/{contact_form_id}",
            path: {
                contact_form_id: contactFormId,
            },
        })
    }

    /**
     * Get potential contact form handlers
     * Get a list of admins that can be assigned to contact form by ID
     * @returns contact_Admin OK
     * @throws ApiError
     */
    public static getV1AdminContactFormAdmins1({
        contactFormId,
    }: {
        /** ID of contact form **/
        contactFormId: number
    }): CancelablePromise<Array<contact_Admin>> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/contact-form/{contact_form_id}/admins",
            path: {
                contact_form_id: contactFormId,
            },
        })
    }

    /**
     * Create contact form message
     * Create new message in contact form
     * @returns contact_Message OK
     * @throws ApiError
     */
    public static contactFormCreateMessage({
        contactFormId,
        request,
    }: {
        /** ID of contact form **/
        contactFormId: number
        /** Request body contact form message **/
        request: contact_CreateMessage
    }): CancelablePromise<contact_Message> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/contact-form/{contact_form_id}/message",
            path: {
                contact_form_id: contactFormId,
            },
            body: request,
        })
    }
}
