import { TextField } from "@mui/material"
import { observer } from "mobx-react"

import { ITextFieldProps } from "./types"

export const TextFields = observer((props: ITextFieldProps) => {
    return (
        <TextField
            data-testid="dynamic-textField"
            label={props.item.label}
            error={Boolean(props.item.errorMessage)}
            helperText={props.item.errorMessage}
            value={props.item.name}
            onChange={(event) => props.handleFormChange(props.index, event)}
            disabled={props.disabled}
        />
    )
})
