import { styled } from "@mui/material"

const ListItem = styled("li")(() => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "8px",
    width: "100%",
    borderRadius: "4px",
    backgroundColor: "white",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    minHeight: "54px",
}))

export { ListItem }
