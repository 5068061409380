import { makeAutoObservable } from "mobx"
import React from "react"

import { IAssigneeListItem } from "./type"

import { Channel } from "src/channel"

import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"
import { loads } from "src/channel/utils"

import { contact_DefaultAssignee, ContactFormAdminService } from "src/api"

export class AutoAssigneeStore {
    static Context = React.createContext<AutoAssigneeStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "loading", "pageLoad")
    modifiedList: contact_DefaultAssignee[] = []

    private repositoryUpdatesListenerDisposer?: () => void

    result = new Pagination(
        async (query) => {
            const response =
                await ContactFormAdminService.getV1AdminContactFormDefaultAssignee(
                    {
                        pageNumber: query.page,
                        pageSize: query.pageSize,
                    },
                )
            const items =
                response.result?.map((item) => ({
                    id: item.property_id as number,
                    adminId: item.admin_id as number,
                    adminName: item.admin_name as string,
                    propertyId: item.property_id as number,
                    propertyName: item.property_name as string,
                })) ?? []

            return {
                items,
                rawItems: response.result ?? [],
                count: response.total_count ?? 0,
            }
        },
        { loadingKey: AutoAssigneeStore.LoadingKeys.pageLoad },
    )

    constructor() {
        makeAutoObservable(this)
    }

    dispose() {
        this.repositoryUpdatesListenerDisposer?.()
    }

    private listenToAutomaticAssigneeRepositoryUpdated() {
        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "cases"
                ) {
                    let updatedObject = this.result?.items.find(
                        (data) => data.propertyId === event.payload?.item?.id,
                    )
                    if (updatedObject !== undefined) {
                        updatedObject.adminId =
                            event.payload?.item?.extraId ?? -1
                        updatedObject.adminName =
                            event.payload?.item?.name ?? ""
                    }
                }
                const newList = [...this.result?.items]
                this.setModifiedList(newList)
            },
        )
    }

    @loads(() => AutoAssigneeStore.LoadingKeys.init)
    async init() {
        this.listenToAutomaticAssigneeRepositoryUpdated()
        await this.result.loadAllPage()
    }

    setModifiedList = (list: IAssigneeListItem[]) => {
        this.modifiedList = list
    }
}
