/* eslint-disable import/no-relative-parent-imports */
import { t } from "@lingui/macro"

import {
    IDynamicFieldsListItemProps,
    IExternalDynamicFieldsListItemProps,
} from "../types/fieldsListItem"

import { OutputType } from "src/types/output"
import { shouldHaveOptionsArray } from "src/modals/contact-forms-form-builder/helper/shouldHaveOptionsArray"
import { contactv2_FieldSource } from "src/api"
import { FormTypeDropdownKeys } from "src/modals/contact-forms-form-builder/types/formTypeDropdownValues"
import { getExternalFieldsListOptions } from "src/modals/contact-forms-form-builder/helper/getExternalFieldsListOptionsHelper"
import { getDynamicFieldsListOptions } from "src/modals/contact-forms-form-builder/helper/getDynamicFieldsListOptionsHelper"

export const getFieldsArrayRequest = (
    outputTypeSelectedValue: string,
    formFieldDescription: string,
    formFieldTitle: string,
    externalDynamicFieldsList: IExternalDynamicFieldsListItemProps[],
    dynamicFieldsList: IDynamicFieldsListItemProps[],
) => {
    if (outputTypeSelectedValue === OutputType.Integration) {
        return externalDynamicFieldsList.map(
            (item: IExternalDynamicFieldsListItemProps) => ({
                description: formFieldDescription,
                label: item.question.value ?? "",
                name: formFieldTitle,
                source:
                    (item.externalFieldTypeDropdownValue
                        .value as contactv2_FieldSource) ?? "",
                required: item.isRequired,
                type: item.fieldTypeDropdownValue.value,
                options:
                    item.fieldTypeDropdownValue.value ===
                        FormTypeDropdownKeys.Dropdown &&
                    item.inputList !== undefined &&
                    item.isAddOptionsVisible
                        ? getExternalFieldsListOptions(item)
                        : [],
                parent_field_uuid:
                    item.relationshipDropdownValue.value ===
                    t`contact-form-forms-detail-modal.form-fields-settings-external-field-relationship-dropdown.default-value`
                        ? ""
                        : item.relationshipDropdownValue.value,
                uuid: item.uuid ?? "",
                tooltip: item.helpingText.value,
            }),
        )
    } else {
        return dynamicFieldsList.map((item: IDynamicFieldsListItemProps) => ({
            description: formFieldDescription,
            label: item.label.value.trim(),
            name: formFieldTitle,
            required: item.isRequired,
            type: item.dropdownValue.value,
            options: shouldHaveOptionsArray(item.dropdownValue.value)
                ? getDynamicFieldsListOptions(item)
                : [],
            parent_field_uuid: "",
            placeholder_value: item.answer.value.trim() ?? "",
            uuid: item.uuid ?? "",
        }))
    }
}
