const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
export const MENU_PROPS = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}
