import { styled, IconButton } from "@mui/material"

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: "0.875rem",
    fontWeight: 500,
    paddingLeft: 0,
    paddingRight: 1,
    backgroundColor: "transparent",
    ":hover": {
        backgroundColor: "transparent",
    },
}))

const IconContainer = styled("span")(() => ({
    paddingTop: "5px",
}))

export { StyledIconButton, IconContainer }
