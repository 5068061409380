import React from "react"
import { captureException } from "@sentry/browser"
import { Alert as MUIAlert, styled } from "@mui/material"
import { t } from "@lingui/macro"

const Alert = styled(MUIAlert)({ margin: "16px" })

interface IState {
    hasError: boolean
}

export class ErrorBoundary extends React.Component<unknown, IState> {
    state: IState = { hasError: false }

    componentDidCatch(error: unknown) {
        captureException(error)
    }

    static getDerivedStateFromError() {
        return { hasError: true }
    }

    render() {
        if (this.state.hasError) {
            return (
                <Alert icon={false} severity="error">
                    {t`global.render-error`}
                </Alert>
            )
        }
        return this.props.children
    }
}
