import { t } from "@lingui/macro"
import { useTheme } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { AutoCompleteTextField } from "src/components/AutoCompleteTextField"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { locationItemsOptionListArray } from "src/views/content-items/constants/locationItemsOptionList"
import { ContentItemsStore } from "src/views/content-items/store"

export const LocationFilterSelector = observer(() => {
    const { palette } = useTheme()
    const globalStore = useStore(GlobalStore)
    const store = useStore(ContentItemsStore)

    const handleSelectOnChange = useCallback(
        async (newValue) => {
            await store.setLocationsFilterSelected(newValue)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    return (
        <AutoCompleteTextField
            limitTags={1}
            disableCloseOnSelect
            options={locationItemsOptionListArray}
            defaultValues={store.locationFilterSelectedValue}
            placeholder={t`content-items-view-location-filter-placeholder`}
            onChange={handleSelectOnChange}
            loading={
                globalStore.loading.is(ContentItemsStore.LoadingKeys.init) ||
                globalStore.loading.is(ContentItemsStore.LoadingKeys.loading)
            }
            sx={{
                width: "300px",
                backgroundColor: palette.common.white,
            }}
        />
    )
})
