import { t } from "@lingui/macro"
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    RadioGroup,
    Radio,
    FormControlLabel,
    Checkbox,
} from "@mui/material"
import { observer } from "mobx-react"
import { useMemo } from "react"

import { FormFieldSet } from "src/components/FormFieldSet"
import { useUniqueId } from "src/lib/unique-id"
import { StaticAttachments } from "src/modals/cases-v2-detail/components/StaticAttachments"
import { CaseDetailStore } from "src/modals/cases-v2-detail/store"
import { useStore } from "src/store/lib/useStore"
import {
    ListItem,
    CheckboxParagraph,
} from "src/modals/cases-v2-detail/sections/styled"
import { ECasesInputType } from "src/modals/cases-v2-detail/types/casesInputType"

import type { contactv2_FormField } from "src/api/models/contactv2_FormField"

export const AnswersSection = observer(() => {
    const store = useStore(CaseDetailStore)
    const statusLabelId = useUniqueId()

    const fieldsWithAnswers = useMemo(
        () => store.form.get("fieldsWithAnswers"),
        [store.form],
    )

    return (
        <FormFieldSet>
            <Stack spacing={2}>
                {fieldsWithAnswers.length > 0 &&
                    store.form
                        .get("fieldsWithAnswers")
                        .map((field: contactv2_FormField) => {
                            const {
                                type,
                                answer = [],
                                options = [],
                                label,
                            } = field

                            const firstAnswerData = answer[0] ?? ""

                            switch (type) {
                                case ECasesInputType.RADIO:
                                    return (
                                        <FormFieldSet
                                            key={label}
                                            header={t({
                                                id: "cases-detail-modal.answers-section.radio.label",
                                                values: { label },
                                            })}
                                        >
                                            <RadioGroup
                                                row
                                                value={firstAnswerData}
                                            >
                                                {options.map((option) => (
                                                    <FormControlLabel
                                                        disabled
                                                        key={option.option}
                                                        value={option.option}
                                                        control={<Radio />}
                                                        label={
                                                            option.option ?? ""
                                                        }
                                                    />
                                                ))}
                                            </RadioGroup>
                                        </FormFieldSet>
                                    )

                                case ECasesInputType.FILE:
                                    return (
                                        <FormFieldSet
                                            key={label}
                                            header={t({
                                                id: "cases-detail-modal.answers-section.file.label",
                                                values: { label },
                                            })}
                                        >
                                            <StaticAttachments
                                                attachments={answer}
                                                maxAttachments={3}
                                            />
                                        </FormFieldSet>
                                    )

                                case ECasesInputType.TEXT:
                                case ECasesInputType.TEXTAREA:
                                    return (
                                        <TextField
                                            key={label}
                                            label={t({
                                                id: "cases-detail-modal.answers-section.text.label",
                                                values: { label },
                                            })}
                                            value={firstAnswerData}
                                            rows={
                                                type ===
                                                ECasesInputType.TEXTAREA
                                                    ? 3
                                                    : undefined
                                            }
                                            multiline={
                                                type ===
                                                ECasesInputType.TEXTAREA
                                            }
                                            disabled
                                        />
                                    )

                                case ECasesInputType.CHECKBOX:
                                    return (
                                        <FormFieldSet
                                            key={label}
                                            header={t({
                                                id: "cases-detail-modal.answers-section.checkbox.label",
                                                values: { label },
                                            })}
                                        >
                                            {options.map((item) => (
                                                <ListItem
                                                    key={item?.option ?? ""}
                                                >
                                                    <Checkbox
                                                        disabled
                                                        checked={answer.includes(
                                                            item?.option ?? "",
                                                        )}
                                                    />
                                                    <CheckboxParagraph>
                                                        {item?.option ?? ""}
                                                    </CheckboxParagraph>
                                                </ListItem>
                                            ))}
                                        </FormFieldSet>
                                    )

                                case ECasesInputType.SELECT:
                                    return (
                                        <FormControl key={label}>
                                            <InputLabel id={statusLabelId}>
                                                {t({
                                                    id: "cases-detail-modal.answers-section.select.label",
                                                    values: { label },
                                                })}
                                            </InputLabel>
                                            <Select
                                                label={t({
                                                    id: "cases-detail-modal.answers-section.select.label",
                                                    values: { label },
                                                })}
                                                labelId={statusLabelId}
                                                fullWidth
                                                disabled
                                                value={firstAnswerData}
                                            >
                                                {options.map((option) => (
                                                    <MenuItem
                                                        key={option.option}
                                                        value={option.option}
                                                    >
                                                        {option.option}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )

                                default:
                                    return null
                            }
                        })}
            </Stack>
        </FormFieldSet>
    )
})
