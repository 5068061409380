import { Stack } from "@mui/material"
import { observer } from "mobx-react"

import { CenteredSpinner } from "src/components/CenteredSpinner"
import { IPageFilterProps, PageFilter } from "src/components/PageFilter"
import { IPageHeaderProps, PageHeader } from "src/components/PageHeader"

interface IProps {
    header: IPageHeaderProps
    filter?: IPageFilterProps
    children: React.ReactNode
    loading?: boolean
}

export type IListPageProps = IProps

export const ListPage = observer((props: IProps) => {
    return (
        <Stack spacing={2.5}>
            <PageHeader {...props.header} />
            {props.filter !== undefined && <PageFilter {...props.filter} />}
            {props.loading === true ? (
                <CenteredSpinner height="500px" />
            ) : (
                props.children
            )}
        </Stack>
    )
})
