import { makeAutoObservable } from "mobx"

export class FeatureStore {
    private features = {
        ShowNewLibrarySorting: false,

        // Hide the pinned checkbox in the community post detail modal until it
        // makes sense.
        // https://avy.atlassian.net/browse/AVY-6924
        ShowCommunityPostPinnedCheckbox: false,

        // Hide tabs util full feature is done
        ShowCasesDetailTabs: true,
        ShowNewInvoices: false,
        // Hide button until full feature is done; https://avy.atlassian.net/browse/AVY-10128
        ShowTenantSimulateUserButton: false,
    }

    constructor() {
        makeAutoObservable(this)
    }

    enabled(feature: keyof typeof this.features) {
        return this.features[feature]
    }

    set(feature: keyof typeof this.features, value: boolean) {
        this.features[feature] = value
    }
}
