import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useMemo } from "react"

import { IStatusChipProps, StatusChip } from "src/components/StatusChip"
import { exhaustiveCheck } from "src/lib/exhaustive-check"
import { ContactFormStatus } from "src/types/contact-form"

interface IProps {
    status: ContactFormStatus
}

export const ContactFormStatusChip = observer(({ status }: IProps) => {
    const chipProps = useMemo((): IStatusChipProps => {
        switch (status) {
            case ContactFormStatus.Done:
                return {
                    state: "positive",
                    label: t`contact-forms-view.forms-tab.status-chip-done-status`,
                }
            case ContactFormStatus.InProgress:
                return {
                    state: "neutral",
                    label: t`contact-forms-view.forms-tab.status-chip-inprogress-status`,
                }
            case ContactFormStatus.Sent:
                return {
                    state: "info",
                    label: t`contact-forms-view.forms-tab.status-chip-sent-status`,
                }
            case ContactFormStatus.SentFallback:
                return {
                    state: "info",
                    label: t`contact-forms-view.forms-tab.status-chip-sentfallback-status`,
                }
            case ContactFormStatus.Unknown:
                return {
                    state: "other",
                    label: t`contact-forms-view.forms-tab.status-chip-unknown-status`,
                }
            default:
                exhaustiveCheck(status)
                return {
                    state: "other",
                    label: t`contact-forms-view.forms-tab.status-chip-unknown-status`,
                }
        }
    }, [status])

    return <StatusChip {...chipProps} />
})
