import { makeAutoObservable } from "mobx"
import React from "react"

import { MessageAdminService as CustomMessageAdminService } from "src/api/_custom/services/MessageAdminService"
import { MessageAdminService } from "src/api"
import { parseDate } from "src/lib/date"
import { stripTags } from "src/lib/html"
import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { Channel } from "src/channel"
import { reportUnhandledApiError } from "src/lib/report"

export class NoticeBoardStore implements IDisposable {
    static Context = React.createContext<NoticeBoardStore | null>(null)
    static LoadingKeys = createLoadingKeys("pageLoad")

    posts = new Pagination(
        async (query) => {
            const response =
                await MessageAdminService.noticeBoardGetMessageList({
                    request: {
                        segment_ids: this._segments,
                        query: query.search ?? undefined,
                    },
                    pageNumber: query.page,
                    pageSize: query.pageSize,
                    accessGroupId: this.getAccountGroupId(),
                })

            const items =
                response.messages?.map((message) => ({
                    id: message.message_id as number,
                    adminTitle: message.admin_title as string,
                    message: stripTags(message.text as string),
                    accessType: message.access_type as string,
                    pinned: message.pinned as boolean,
                    createdAt: parseDate(message.created_at),
                    receiverCount: message.receiver_count as number,
                    readCount: message.read_count as number,
                    sender: message.user_name as string,
                    status: this.getStatus(message.status),
                    includeInChatbot: message.include_in_chatbot as boolean,
                })) ?? []

            return {
                items,
                rawItems: response.messages ?? [],
                count: response.count ?? 0,
            }
        },
        { loadingKey: NoticeBoardStore.LoadingKeys.pageLoad },
    )

    private _segments: number[] = []
    private _accessGroupId: number = DEFAULT_ACCESS_GROUP.id
    private repositoryUpdatesListenerDisposer?: () => void

    get segments() {
        return this._segments
    }

    constructor() {
        makeAutoObservable(this)
    }

    dispose() {
        this.repositoryUpdatesListenerDisposer?.()
    }

    async init(accessGroupId: number) {
        this.listenToMessageRepositoryUpdated()
        this.setAccountGroupId(accessGroupId)
        await this.posts.loadInitialPage()
    }

    async loadSegments(segments: number[]) {
        this.setSegments(segments)
        await this.posts.loadInitialPage()
    }

    /**
     * `status` is documented as just a string in the api
     * current statuses accepted from Chip: "published" "unpublished"
     * if status from api is something else it will be set to "-"
     */
    private getStatus(status?: string): NoticeBoardStatus {
        if (status === "published" || status === "unpublished") {
            return status
        } else {
            return "scheduled"
        }
    }

    async savePinnedStatus(id: number, pinned: boolean) {
        const rawPost = this.posts.rawItems.find(
            (item) => item.message_id === id,
        )

        if (rawPost == null) {
            // Bail if the post cannot be found in the paginator.
            return
        }

        try {
            this.posts.updateItem({ id }, (item) => ({ ...item, pinned }))

            await CustomMessageAdminService.patchV1AdminMessage({
                // The types are a bit inconsistent so we have to cast the new
                // post to `any` to get it to work.
                request: {
                    access_group_id: rawPost.access_group_id,
                    pinned,
                    send_push: false,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                },
                messageId: id,
            })
        } catch (e) {
            this.posts.updateItem({ id }, (item) => ({
                ...item,
                pinned: rawPost.pinned ?? false,
            }))
            reportUnhandledApiError(e)
        }
    }

    async deletePost(id: number) {
        try {
            await MessageAdminService.noticeBoardDeleteMessage({
                messageId: id,
            })
        } catch (e) {
            reportUnhandledApiError(e)
        } finally {
            await this.posts.reload()
        }
    }

    private listenToMessageRepositoryUpdated() {
        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "messages"
                ) {
                    await this.posts.reload()
                }
            },
        )
    }

    private getAccountGroupId() {
        return this._accessGroupId !== DEFAULT_ACCESS_GROUP.id
            ? this._accessGroupId
            : undefined
    }

    private setAccountGroupId(accessGroupId: number) {
        this._accessGroupId = accessGroupId
    }

    private setSegments(segments: number[]) {
        this._segments = segments
    }
}
