import { memo } from "react"

import { QRCodeStyled } from "./styled"
import { IQRCodeProps } from "./types/qrCodeProps"

/**
 * QRCode component.
 * @param {IQRCodeProps} props - The props for the component.
 * @returns {JSX.Element} The rendered QRCode.
 */
export const QRCodeComponent = memo(({ value, ...rest }: IQRCodeProps) => {
    return <QRCodeStyled value={value} {...rest} />
})
