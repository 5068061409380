import { Circle } from "@mui/icons-material"
import { alpha } from "@mui/material"
import React from "react"

import { Address24 } from "src/components/icons/segment-types/Address24"
import { Area24 } from "src/components/icons/segment-types/Area24"
import { Building24 } from "src/components/icons/segment-types/Building24"
import { LegalEntity24 } from "src/components/icons/segment-types/LegalEntity24"
import { Object24 } from "src/components/icons/segment-types/Object24"
import { Property24 } from "src/components/icons/segment-types/Property24"
import { PropertyOwner24 } from "src/components/icons/segment-types/PropertyOwner24"
import { DefaultTheme } from "src/themes/default"

// Hard-code the theme for now. We don't use multiple themes so it's not a
// problem. When we do we can change this.
const theme = DefaultTheme

type IconMap = {
    [key in ISegmentForSearch["type"]]: {
        icon: React.ReactNode
        color: string
        backgroundColor: string
    }
}

const icons: IconMap = Object.freeze({
    propertyowner: {
        icon: <PropertyOwner24 />,
        color: theme.palette.utility.blue.main,
        backgroundColor: alpha(theme.palette.utility.blueFaded.main, 0.5),
    },
    legalentity: {
        icon: <LegalEntity24 />,
        color: theme.palette.utility.apple.main,
        backgroundColor: alpha(theme.palette.utility.appleFaded.main, 0.5),
    },
    property: {
        icon: <Property24 />,
        color: theme.palette.utility.rustRed.main,
        backgroundColor: alpha(theme.palette.utility.rustRedFaded.main, 0.5),
    },
    building: {
        icon: <Building24 />,
        color: theme.palette.utility.purple.main,
        backgroundColor: alpha(theme.palette.utility.purpleFaded.main, 0.5),
    },
    address: {
        icon: <Address24 />,
        color: theme.palette.utility.aquaBlue.main,
        backgroundColor: alpha(theme.palette.utility.aquaBlueFaded.main, 0.5),
    },
    propertyobject: {
        icon: <Object24 />,
        color: theme.palette.utility.orange.main,
        backgroundColor: alpha(theme.palette.utility.orangeFaded.main, 0.5),
    },
    district: {
        icon: <Area24 />,
        color: theme.palette.utility.blue.main,
        backgroundColor: alpha(theme.palette.utility.blueFaded.main, 0.5),
    },
    area1: {
        icon: <Area24 />,
        color: theme.palette.utility.apple.main,
        backgroundColor: alpha(theme.palette.utility.appleFaded.main, 0.5),
    },
    area2: {
        icon: <Area24 />,
        color: theme.palette.utility.rustRed.main,
        backgroundColor: alpha(theme.palette.utility.rustRedFaded.main, 0.5),
    },
    area3: {
        icon: <Area24 />,
        color: theme.palette.utility.purple.main,
        backgroundColor: alpha(theme.palette.utility.purpleFaded.main, 0.5),
    },
    other: {
        icon: <Circle />,
        color: theme.palette.grey[500],
        backgroundColor: alpha(theme.palette.grey[500], 0.5),
    },
})

export const getSegmentTypeIcon = (type: ISegmentForSearch["type"]) =>
    icons[type] ?? icons["other"]
