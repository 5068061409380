import { observer } from "mobx-react"
import {
    FormControlLabel,
    FormHelperText,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    TextField,
} from "@mui/material"

import { t } from "@lingui/macro"

import { useCallback } from "react"

import { CreatePropertyModalStore } from "./store"

import { useStore } from "src/store/lib/useStore"
import { FormFieldSet } from "src/components/FormFieldSet"
import { IPropertyOwner } from "src/views/client-management/store"

interface IProps {
    propertyOwnersList: IPropertyOwner[]
}

export const CreateSection = observer(({ propertyOwnersList }: IProps) => {
    const store = useStore(CreatePropertyModalStore)

    const disabledField =
        store.fields.get("propertyOwnerId") === undefined ||
        Boolean(store.fields.get("id"))

    const handlePropertyOwnerDropdownChange = useCallback(
        async (event: SelectChangeEvent) => {
            const propertyOwnerIdFromDropdown = Number(event.target.value)
            store.fields.set("legalEntityId", undefined)
            store.fields.set("propertyOwnerId", propertyOwnerIdFromDropdown)
            await store.getLegalEntities(propertyOwnerIdFromDropdown)
        },
        [store],
    )

    const handleLegalEntityDropdownChange = useCallback(
        (event: SelectChangeEvent) => {
            const legalEntityIdFromDropdown: number = Number(event.target.value)
            store.fields.set("legalEntityId", legalEntityIdFromDropdown)
        },
        [store.fields],
    )

    return (
        <>
            <FormFieldSet
                header={t`create-new-property-modal.property-owner`}
                tooltip={t`create-new-property-modal.property-owner`}
            >
                {propertyOwnersList.length > 0 && (
                    <Select
                        labelId="property-owner-label"
                        id="property-owner"
                        value={
                            store.fields.get("propertyOwnerId") === undefined
                                ? ""
                                : String(store.fields.get("propertyOwnerId"))
                        }
                        onChange={handlePropertyOwnerDropdownChange}
                        sx={{ width: "100%" }}
                        data-testid="property-owner-dropdown"
                        error={Boolean(store.fields.error("propertyOwnerId"))}
                        disabled={Boolean(store.fields.get("id"))}
                    >
                        {propertyOwnersList.map((propertyOwner, index) => {
                            return (
                                <MenuItem
                                    value={propertyOwner.propertyOwnerId}
                                    key={index}
                                >
                                    {propertyOwner.legalName}
                                </MenuItem>
                            )
                        })}
                    </Select>
                )}
                {Boolean(store.fields.error("legalEntityId")) && (
                    <FormHelperText error={true}>
                        {store.fields.error("legalEntityId")}
                    </FormHelperText>
                )}
            </FormFieldSet>
            <FormFieldSet
                header={t`create-new-property-modal.legal-entity`}
                tooltip={t`create-new-property-modal.legal-entity-tooltip`}
            >
                <Select
                    labelId="legal-entity-label"
                    id="legal-entity"
                    value={
                        store.fields.get("legalEntityId") === undefined ||
                        store.legalEntities.length < 1
                            ? ""
                            : String(store.fields.get("legalEntityId"))
                    }
                    onChange={handleLegalEntityDropdownChange}
                    sx={{ width: "100%" }}
                    data-testid="legal-entity-dropdown"
                    error={Boolean(store.fields.error("legalEntityId"))}
                >
                    {store.legalEntities.map((legalEntity, index) => {
                        return (
                            <MenuItem
                                value={legalEntity.legalEntityId}
                                key={index}
                            >
                                {legalEntity.legalName}
                            </MenuItem>
                        )
                    })}
                </Select>
                {Boolean(store.fields.error("legalEntityId")) && (
                    <FormHelperText error={true}>
                        {store.fields.error("legalEntityId")}
                    </FormHelperText>
                )}
            </FormFieldSet>
            <FormFieldSet
                header={t`create-new-property-modal.property-name`}
                tooltip={t`create-new-property-modal.property-name`}
            >
                <TextField
                    label={t`create-new-property-modal.property-name`}
                    placeholder={t`create-new-property-modal.property-name`}
                    onChange={store.fields.setter("name")}
                    value={store.fields.get("name")}
                    helperText={store.fields.error("name")}
                    error={Boolean(store.fields.error("name"))}
                />
            </FormFieldSet>
            <FormFieldSet
                header={t`create-new-property-modal.city`}
                tooltip={t`create-new-property-modal.city`}
            >
                <TextField
                    label={t`create-new-property-modal.city`}
                    placeholder={t`create-new-property-modal.city`}
                    onChange={store.fields.setter("city")}
                    value={store.fields.get("city")}
                    helperText={store.fields.error("city")}
                    error={Boolean(store.fields.error("city"))}
                />
            </FormFieldSet>
            <FormFieldSet
                header={t`create-new-property-modal.external-id`}
                tooltip={t`create-new-property-modal.external-id`}
            >
                <TextField
                    label={t`create-new-property-modal.external-id`}
                    placeholder={t`create-new-property-modal.external-id`}
                    onChange={store.fields.setter("externalId")}
                    value={store.fields.get("externalId")}
                    helperText={store.fields.error("externalId")}
                    error={Boolean(store.fields.error("externalId"))}
                />
            </FormFieldSet>
            <FormFieldSet
                header={t`create-new-property-modal.integration-id`}
                tooltip={t`create-new-property-modal.integration-id`}
            >
                <TextField
                    label={t`create-new-property-modal.integration-id`}
                    placeholder={t`create-new-property-modal.integration-id`}
                    onChange={(event) => {
                        store.fields.set(
                            "integrationId",
                            Number(event.target.value) > 0
                                ? Number(event.target.value)
                                : undefined,
                        )
                    }}
                    value={store.fields.get("integrationId")}
                    helperText={store.fields.error("integrationId")}
                    error={Boolean(store.fields.error("integrationId"))}
                />
            </FormFieldSet>
            <FormFieldSet
                header={t`create-new-property-modal.self-onboarding`}
                tooltip={t`create-new-property-modal.self-onboarding`}
            >
                <RadioGroup
                    row
                    value={store.fields.get("selfOnboardingEnabled")}
                    defaultChecked={true}
                    onChange={(event) => {
                        store.fields.set(
                            "selfOnboardingEnabled",
                            event.target.value === "true",
                        )
                    }}
                >
                    <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label={t`create-new-property-modal.yes`}
                        disabled={disabledField}
                    />
                    <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label={t`create-new-property-modal.no`}
                        disabled={disabledField}
                    />
                </RadioGroup>
            </FormFieldSet>
            <FormFieldSet
                header={t`create-new-property-modal.integration-active`}
                tooltip={t`create-new-property-modal.integration-active`}
            >
                <RadioGroup
                    row
                    value={store.fields.get("integrationActive")}
                    // defaultChecked={true}
                    onChange={(event) => {
                        store.fields.set(
                            "integrationActive",
                            event.target.value === "true",
                        )
                    }}
                >
                    <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label={t`create-new-property-modal.yes`}
                    />
                    <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label={t`create-new-property-modal.no`}
                    />
                </RadioGroup>
            </FormFieldSet>
        </>
    )
})
