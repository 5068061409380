import { Box, styled } from "@mui/material"

export const Logo = styled(Box)(({ theme }) => ({
    background: "url(/static/images/avy-logo.png)",
    width: "280px",
    height: "24px",
    backgroundSize: "contain",
    backgroundPosition: "left",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("lg")]: {
        display: "none",
    },
}))
