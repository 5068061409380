import SegmentsDatabase from "src/api/_indexedDB/database/Segments"
import { reportUnhandledApiError } from "src/lib/report"

const db = new SegmentsDatabase()

export const readSegments = async (accessGroupId: string) => {
    return await db
        .transaction("r", db.segments, async () => {
            if ((await db.segments.where({ accessGroupId }).count()) > 0) {
                const segments = await db.segments
                    .where("accessGroupId")
                    .equals(accessGroupId)
                    .first()
                return segments
            }
            return null
        })
        .catch((e) => {
            reportUnhandledApiError(e)
            return null
        })
}

export const writeSegments = async ({
    accessGroupId,
    segments,
}: {
    accessGroupId: string
    segments: ISegmentForSearch[]
}) => {
    await db
        .transaction("rw", db.segments, async () => {
            await db.segments.put({
                accessGroupId,
                segments,
            })
        })
        .catch((e) => {
            reportUnhandledApiError(e)
        })
}

export const removeAccessGroup = async (accessGroupId: string) => {
    await db
        .transaction("rw", db.segments, async () => {
            await db.segments.where({ accessGroupId }).delete()
        })
        .catch((e) => {
            reportUnhandledApiError(e)
        })
}
