import { makeAutoObservable } from "mobx"
import React from "react"

import { parseDate } from "src/lib/date"
import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { CampaignAdminService } from "src/api/services/CampaignAdminService"
import { Channel } from "src/channel"
import {
    campaign_AdminCampaignCreateData,
    campaign_AdminListCampaignsFilter,
} from "src/api"
import { reportUnhandledApiError } from "src/lib/report"

export class CampaignsStore {
    static Context = React.createContext<CampaignsStore | null>(null)
    static LoadingKeys = createLoadingKeys("pageLoad")

    campaigns = new Pagination(
        async (query) => {
            const request: campaign_AdminListCampaignsFilter = {
                access_group_ids:
                    this._accessGroupId === -1 ? [] : [this._accessGroupId],
                page_number: query.page,
                page_size: query.pageSize,
                segment_ids: this._accessGroupId === -1 ? [] : this._segments,
            }

            const response = await CampaignAdminService.postV1AdminCampaignList(
                { request: request },
            )

            const items =
                response.campaigns?.map((campaign) => ({
                    id: campaign.campaign_id ?? 0,
                    internalName: campaign.internal_name ?? "",
                    segmentsIds: campaign.segment_ids ?? [],
                    publishedAt: campaign.publish_at ?? null,
                    unpublishedAt: campaign.unpublish_at ?? null,
                    sendPush: campaign.send_push ?? false,
                    offerType: campaign.offer_type ?? "",
                    header: campaign.header ?? "",
                    tagLine: campaign.tag_line ?? "",
                    description: campaign.description ?? "",
                    image: campaign.image ?? "",
                    createdAt: parseDate(campaign.created_at),
                    updated_at: parseDate(campaign.updated_at),
                    status: campaign.status,
                })) ?? []

            return {
                items,
                rawItems: response.campaigns ?? [],
                count: response.total_count ?? 0,
            }
        },
        { loadingKey: CampaignsStore.LoadingKeys.pageLoad },
    )

    private _segments: number[] = []
    private _accessGroupId: number = DEFAULT_ACCESS_GROUP.id
    private repositoryUpdatesListenerDisposer?: () => void
    get segments() {
        return this._segments
    }

    constructor() {
        makeAutoObservable(this)
    }

    async init(accessGroupId: number, segments: number[]) {
        this.listenToMessageRepositoryUpdated()
        this.setAccountGroupId(accessGroupId)
        await this.loadSegments(segments)
    }

    async loadSegments(segments: number[]) {
        this.setSegments(segments)
        await this.campaigns.loadInitialPage()
    }

    private getAccountGroupId() {
        return this._accessGroupId !== DEFAULT_ACCESS_GROUP.id
            ? this._accessGroupId
            : undefined
    }

    private setAccountGroupId(accessGroupId: number) {
        this._accessGroupId = accessGroupId
    }

    private setSegments(segments: number[]) {
        this._segments = segments
    }

    async duplicateResource(id: number) {
        const rawCampaign = this.campaigns.rawItems.find(
            (item) => item.campaign_id === id,
        )

        if (rawCampaign == null) {
            // Bail if the campaign cannot be found in the paginator.
            return
        }

        try {
            const request: campaign_AdminCampaignCreateData = {
                is_draft: true,
                access_group_id: rawCampaign.access_group_id ?? 0,
                internal_name: rawCampaign.internal_name + " DUPLICATE" ?? "",
                segment_ids: rawCampaign.segment_ids ?? [],
                publish_at: rawCampaign.publish_at ?? undefined,
                unpublish_at: rawCampaign.unpublish_at ?? undefined,
                send_push: rawCampaign.send_push,
                offer_id: rawCampaign.offer_id ?? undefined,
                offer_type: rawCampaign.offer_type ?? "",
                header: rawCampaign.header ?? "",
                tag_line: rawCampaign.tag_line ?? "",
                description: rawCampaign.description ?? "",
                image_url: rawCampaign.image ?? "",
            }

            await CampaignAdminService.postV1AdminCampaign({
                request,
            })
        } catch (e) {
            reportUnhandledApiError(e)
        } finally {
            await this.campaigns.reload()
        }
    }

    dispose() {
        this.repositoryUpdatesListenerDisposer?.()
    }

    private listenToMessageRepositoryUpdated() {
        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "campaigns"
                ) {
                    await this.campaigns.reload()
                }
            },
        )
    }
}
