import { t } from "@lingui/macro"
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Stack,
} from "@mui/material"
import { observer } from "mobx-react"

import { DateTimePicker } from "src/components/DateTimePicker"
import { Avatar } from "src/components/Avatar"
import { FormFieldSet } from "src/components/FormFieldSet"
import { useUniqueId } from "src/lib/unique-id"
import { ContactFormDetailStore } from "src/modals/contact-form-detail/store"
import { useStore } from "src/store/lib/useStore"

export const AssigneeSection = observer(() => {
    const store = useStore(ContactFormDetailStore)

    const assignedToId = useUniqueId()

    const handleOnDateChangeClick = (date: Date) => {
        store.form.set("implementationDate", date)
    }

    const onSelectValueChange = (assignedToId: string) => {
        store.form.set("assignedToId", Number(assignedToId))
    }

    return (
        <FormFieldSet>
            <Stack spacing={2} paddingBottom={{ sm: 4, md: 4 }}>
                <FormControl fullWidth>
                    <InputLabel id={assignedToId}>
                        {t`contact-form-detail-modal.assignee-section.assigned-to-label`}
                    </InputLabel>
                    <Select
                        fullWidth
                        value={String(store.form.get("assignedToId") ?? "")}
                        onChange={(event) =>
                            onSelectValueChange(event.target.value)
                        }
                        labelId={assignedToId}
                        label={t`contact-form-detail-modal.assignee-section.assigned-to-label`}
                        error={Boolean(store.form.error("assignedToId"))}
                    >
                        {store.handlers.map((handler) => (
                            <MenuItem key={handler.id} value={handler.id}>
                                <Stack direction="row" spacing={1}>
                                    <Avatar name={handler.name} />
                                    <span>{handler.name}</span>
                                </Stack>
                            </MenuItem>
                        ))}
                    </Select>
                    {Boolean(store.form.error("assignedToId")) && (
                        <FormHelperText
                            error={Boolean(store.form.error("assignedToId"))}
                        >
                            {store.form.error("assignedToId")}
                        </FormHelperText>
                    )}
                </FormControl>
                <DateTimePicker
                    label={t`contact-form-detail-modal.assignee-section.implementation-date-label`}
                    value={store.form.get("implementationDate")}
                    onChangePicker={(date) =>
                        handleOnDateChangeClick(date as Date)
                    }
                    helperText={store.form.error("implementationDate")}
                    error={Boolean(store.form.error("implementationDate"))}
                    inputId="implementation-date"
                />
            </Stack>
        </FormFieldSet>
    )
})
