import { t } from "@lingui/macro"
import { MenuItem, TextField } from "@mui/material"
import { observer } from "mobx-react"

import { ChangeEvent } from "react"

import { CampaignDetailStore } from "src/modals/campaign-detail/store"
import { useStore } from "src/store/lib/useStore"

export const ConnectSubscriptionSection = observer(() => {
    const store = useStore(CampaignDetailStore)

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const offerItem = store.fields
            .get("offerList")
            ?.find((item) => item.name === event.target.value)
        store.fields.set("offerId", offerItem?.id ?? null)
        store.fields.set("offerType", offerItem?.type ?? "")
        store.fields.set("offerName", offerItem?.name ?? "")
    }
    return (
        <TextField
            disabled={Boolean(store.fields.get("status") === "active")}
            select
            label={t`campaign-detail-modal.connect-subscription.data-header`}
            placeholder={t`campaign-detail-modal.connect-subscription.select-one`}
            value={store.fields.get("offerName")}
            onChange={handleChange}
            helperText={store.fields.error("offerType")}
            error={Boolean(store.fields.error("offerType"))}
        >
            <div />
            {store.fields.get("offerList")?.map((option) => (
                <MenuItem
                    key={`${option.id ?? 0}${option.type ?? ""}`}
                    value={`${option.name}`}
                >
                    {option.name}
                </MenuItem>
            ))}
        </TextField>
    )
})
