import React from "react"
import { makeAutoObservable } from "mobx"
import { t } from "@lingui/macro"

import { FormFields } from "src/lib/form-fields"
import { reportError } from "src/lib/report"
import { AuthenticationAdminService } from "src/api"

interface IFormFields {
    password: string
    repeatPassword: string
}

export class NewPasswordStore {
    static Context = React.createContext<NewPasswordStore | null>(null)
    token = ""
    isLoading = false
    fields = new FormFields<IFormFields>({
        password: "",
        repeatPassword: "",
    })
    isValidPassword = false
    isSuccess = false
    showPassword = false
    showRepeatedPassword = false

    constructor() {
        makeAutoObservable(this)
    }

    private setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading
    }

    private setIsSuccess(isSuccess: boolean) {
        this.isSuccess = isSuccess
    }

    setShowPassword(showPassword: boolean) {
        this.showPassword = showPassword
    }

    setShowRepeatedPassword(showRepeatedPassword: boolean) {
        this.showRepeatedPassword = showRepeatedPassword
    }

    validatePassword() {
        this.isValidPassword = this.fields.get("password").length > 5

        if (!this.isValidPassword) {
            this.fields.set("repeatPassword", "")
        }
    }

    async newPassword(token: string) {
        this.setIsLoading(true)
        this.fields.clearErrors()

        const { data } = this.fields

        if (!this.isValidPassword) {
            this.fields.setError(
                "password",
                t`new-password-view.password-not-valid`,
            )
            this.setIsLoading(false)
            return
        }

        if (data.repeatPassword !== data.password) {
            this.fields.setError(
                "repeatPassword",
                t`new-password-view.passwords-dont-match`,
            )
            this.setIsLoading(false)
            return
        }

        try {
            await AuthenticationAdminService.postV1AdminAuthNewPassword({
                token: token,
                request: {
                    password: data.password,
                },
            })

            this.setIsSuccess(true)
        } catch (error) {
            reportError(t`new-password-view.new-password-fail`, error)
        } finally {
            this.setIsLoading(false)
        }
    }
}
