import {
    styled,
    ListItem as MUIListItem,
    ListItemText as MUIListItemText,
} from "@mui/material"

export const ListItemInnerSearch = styled(MUIListItem)(({ theme }) => ({
    background: theme.palette.grey[100],
    marginBottom: "2px",
    borderRadius: "4px",
    padding: 0,
    overflow: "hidden",
    alignItems: "stretch",
    paddingRight: "90px",
}))

export const ListItemTextSearch = styled(MUIListItemText)({
    [`& .MuiListItemText-secondary`]: {
        lineHeight: "inherit",
    },
})

export const SegmentNameButton = styled("button")(({ theme }) => ({
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.grey[900],
    lineHeight: 1.4,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    border: "none",
    background: "transparent",
    padding: 0,
    cursor: "pointer",
    maxWidth: "100%",
    display: "inherit",
    "&:hover": {
        textDecoration: "underline",
    },
}))

export const SegmentNameStatic = styled("span")(({ theme }) => ({
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.grey[900],
    lineHeight: 1.4,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    border: "none",
    background: "transparent",
    maxWidth: "100%",
    display: "inherit",
    padding: 0,
}))
