import { content_ContentItemLocation } from "src/api"

export const locationSwitch = (location: content_ContentItemLocation) => {
    const locationPriorityMap: Record<content_ContentItemLocation, string> = {
        navigation: "priority_main_navigation",
        move_in_guide: "priority_move_in_guide",
        overview: "priority_overview",
        spotlight: "priority_spotlight",
        move_in_wizard: "priority_move_in_wizard",
        my_subscriptions: "priority_my_subscriptions",
    }
    return locationPriorityMap[location]
}
