import { useCallback, useEffect } from "react"
import { observer } from "mobx-react"
import { t } from "@lingui/macro"

import { Link } from "@mui/material"

import { ILegalEntity, LegalEntityStore } from "./store"

import { IPropertyOwner } from "src/views/client-management/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { Table } from "src/components/Table"
import { useStore } from "src/store/lib/useStore"
import { reportSuccess, reportUnhandledApiError } from "src/lib/report"

import { GlobalStore } from "src/store"
import { CreateNewLegalEntityModal } from "src/modals/create-new-legalEntity-modal"

const View = observer(({ propertyOwner, propertyOwnersList }: IProps) => {
    const store = useStore(LegalEntityStore)
    const gstore = useStore(GlobalStore)
    useEffect(() => {
        if (propertyOwner != null) {
            ;(async () => {
                await store.init(propertyOwner.propertyOwnerId)
            })()
        }
    })

    const propertyOwnerHasFastApiEnabled: boolean =
        propertyOwner?.integrationType === "fastapi"

    const handleLegalEntityFastApiClick = useCallback(
        (propertyOwnerId: number, legalEntityId: number, fullLoad: boolean) =>
            async () => {
                try {
                    await store.legalEntityFastApiRequest(
                        propertyOwnerId,
                        legalEntityId,
                        fullLoad,
                    )
                    reportSuccess("The load was successful")
                } catch (e) {
                    reportUnhandledApiError(e)
                }
            },
        [store],
    )
    const handleOpenOnEditClick = useCallback(
        (id: number) => {
            gstore.modals.open(() => (
                <CreateNewLegalEntityModal
                    id={id}
                    propertyOwnersList={propertyOwnersList}
                    chosenPropertyOwnerId={propertyOwner?.propertyOwnerId}
                />
            ))
        },
        [gstore, propertyOwner, propertyOwnersList],
    )

    const handleOffboardLegalEntity = useCallback(
        (legalEntityId: number) => async () => {
            try {
                await store.offboardLegalEntity(legalEntityId)
                reportSuccess(t`legal-entity-view.offboard-success-message`)
            } catch (e) {
                reportUnhandledApiError(e)
            }
        },
        [store],
    )

    const rowsRenderers = (item: ILegalEntity) => ({
        legalEntityId: item.legalEntityId,
        legalName: (
            <Link
                component="button"
                onClick={() => handleOpenOnEditClick(item.legalEntityId)}
                data-testid="edit-modal-button"
                sx={{ textAlign: "left" }}
            >
                {item.legalName}
            </Link>
        ),
        organisationId: item.organisationId,
        type: item.type,
        selfOnboardingEnabled:
            item.selfOnboardingEnabled === true
                ? t`legal-entity-view.self-onboarding-enabled-true`
                : t`legal-entity-view.self-onboarding-enabled-false`,
        selfOnboardingCode: item.selfOnboardingCode,
        leafletUrl:
            item.leafletUrl != null && item.leafletUrl !== "" ? (
                <Link href={item.leafletUrl.toString()} target="_blank">
                    {t`legal-entity-view.leaflet-link`}
                </Link>
            ) : null,
        externalId: item.externalId,
        integrationId: item.integrationId,
        integrationActive:
            item.integrationActive === true
                ? t`legal-entity-view.integration-active-true`
                : t`legal-entity-view.integration-active-false`,
    })

    const rowsActionsRenderers = (item: ILegalEntity) => {
        let rowActionOptions = [
            {
                text: t`row-action-renderer.offboard-legal-entity`,
                onClick: handleOffboardLegalEntity(item.legalEntityId),
            },
        ]
        return propertyOwnerHasFastApiEnabled
            ? rowActionOptions.concat([
                  {
                      text: t`row-action-renderer-fast-api.48h-load`,
                      onClick: handleLegalEntityFastApiClick(
                          item.propertyOwnerId,
                          item.legalEntityId,
                          false,
                      ),
                  },
                  {
                      text: t`row-action-renderer-fast-api.full-load`,
                      onClick: handleLegalEntityFastApiClick(
                          item.propertyOwnerId,
                          item.legalEntityId,
                          true,
                      ),
                  },
              ])
            : rowActionOptions
    }

    return (
        <Table
            paginator={store.legalEntity}
            headers={[
                {
                    key: "legalEntityId",
                    name: t`legal-entity-view.legal-entity-id-header`,
                },
                {
                    key: "legalName",
                    name: t`legal-entity-view.legal-name-header`,
                },
                {
                    key: "organisationId",
                    name: t`legal-entity-view.organisation-id-header`,
                },
                {
                    key: "type",
                    name: t`legal-entity-view.type-header`,
                },
                {
                    key: "externalId",
                    name: t`legal-entity-view.external-id-header`,
                },
                {
                    key: "integrationId",
                    name: t`legal-entity-view.integration-id-header`,
                },
                {
                    key: "integrationActive",
                    name: t`legal-entity-view.integration-active-header`,
                },
                {
                    key: "selfOnboardingEnabled",
                    name: t`legal-entity-view.self-onboarding-header`,
                },
                {
                    key: "selfOnboardingCode",
                    name: t`legal-entity-view.self-onboarding-code-header`,
                },
                {
                    key: "leafletUrl",
                    name: t`legal-entity-view.self-leaflet-url-header`,
                },
            ]}
            rowRenderer={(item) => rowsRenderers(item)}
            rowActionsRenderer={(item) => rowsActionsRenderers(item)}
        />
    )
})

export const LegalEntityTableView = ({
    propertyOwner,
    propertyOwnersList,
}: IProps) => (
    <StoreProvider Store={LegalEntityStore}>
        <View
            propertyOwner={propertyOwner}
            propertyOwnersList={propertyOwnersList}
        />
    </StoreProvider>
)

interface IProps {
    propertyOwner?: IPropertyOwner
    propertyOwnersList: IPropertyOwner[]
}
