import { t } from "@lingui/macro"

import { variables, IAuth } from "./variables"

import { Locale } from "src/locales/locale"

enum AvyConfigurableLanguages {
    EN = "en",
    SV = "sv",
    FI = "fi",
}

/**
 * Export all raw environment variables.
 */
export const environment = variables

export const DEFAULT_ACCESS_GROUP = {
    id: -1,
    // Lazily evaluate as `t` cannot be called a import time.
    get name() {
        return t`head.all-access-groups`
    },
}

export const DEFAULT_LANGUAGE = Locale.Swedish

const commonConfigurableLanguage = `${AvyConfigurableLanguages.SV}, ${AvyConfigurableLanguages.EN}`

export const CONFIGURABLE_LANGUAGE_VALUES = {
    "- None -": "",
    [commonConfigurableLanguage]: commonConfigurableLanguage,
    [`${AvyConfigurableLanguages.FI}, ${commonConfigurableLanguage}`]: `${AvyConfigurableLanguages.FI}, ${commonConfigurableLanguage}`,
}

export const DEFAULT_CONFIGURABLE_LANGUAGE_VALUE = `${AvyConfigurableLanguages.FI}, ${commonConfigurableLanguage}`

export type { IAuth }
