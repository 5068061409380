import { styled } from "@mui/material"

import { ContactFormReadUnreadStatus } from "src/types/contact-form"

export const RowCellContainer = styled("div")(({ theme }) => ({
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word",
    [theme.breakpoints.down("md")]: {
        width: "100%",
    },
}))

export const StatusReadUnReadDot = styled("span", {
    shouldForwardProp: () => true,
})<{
    status: ContactFormReadUnreadStatus
}>(({ status, theme }) => ({
    height: "8px",
    width: "8px",
    marginLeft: "20px",
    borderRadius: "10000px",
    background: {
        [ContactFormReadUnreadStatus.UnRead]: theme.palette.brand.red.main,
    }[status],
}))
