import { IIconProps } from "src/components/icons"
import { useUniqueId } from "src/lib/unique-id"

export const Attach24 = (props: IIconProps) => {
    const clipPathId = useUniqueId()
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath={`url(#${clipPathId})`}>
                <path
                    d="M12 2C10.4529 2 8.96918 2.60884 7.87522 3.69257C6.78125 4.77631 6.16667 6.24617 6.16667 7.77881V17.578C6.14119 18.1494 6.23278 18.7199 6.43592 19.2552C6.63906 19.7905 6.94954 20.2795 7.34863 20.6927C7.74772 21.106 8.22716 21.4348 8.75804 21.6595C9.28891 21.8842 9.86022 22 10.4375 22C11.0148 22 11.5861 21.8842 12.117 21.6595C12.6478 21.4348 13.1273 21.106 13.5264 20.6927C13.9255 20.2795 14.236 19.7905 14.4391 19.2552C14.6422 18.7199 14.7338 18.1494 14.7083 17.578V10.016C14.7083 9.2957 14.4195 8.60486 13.9053 8.0955C13.3912 7.58615 12.6938 7.29999 11.9667 7.29999C11.2395 7.29999 10.5422 7.58615 10.028 8.0955C9.51386 8.60486 9.22501 9.2957 9.22501 10.016V16.447C9.22501 16.666 9.3128 16.876 9.46908 17.0308C9.62536 17.1856 9.83733 17.2726 10.0583 17.2726C10.2794 17.2726 10.4913 17.1856 10.6476 17.0308C10.8039 16.876 10.8917 16.666 10.8917 16.447V10.016C10.8917 9.73359 11.0049 9.46272 11.2065 9.263C11.4081 9.06328 11.6816 8.95108 11.9667 8.95108C12.2518 8.95108 12.5252 9.06328 12.7268 9.263C12.9284 9.46272 13.0417 9.73359 13.0417 10.016V17.578C13.0617 17.9291 13.0092 18.2805 12.8874 18.6108C12.7656 18.941 12.5771 19.2432 12.3333 19.4988C12.0895 19.7543 11.7956 19.9579 11.4696 20.0971C11.1437 20.2362 10.7924 20.308 10.4375 20.308C10.0826 20.308 9.73136 20.2362 9.40538 20.0971C9.07941 19.9579 8.78554 19.7543 8.54175 19.4988C8.29796 19.2432 8.10939 18.941 7.98758 18.6108C7.86577 18.2805 7.81329 17.9291 7.83334 17.578V7.77881C7.83334 6.68407 8.27233 5.63417 9.05373 4.86007C9.83513 4.08597 10.8949 3.65109 12 3.65109C13.1051 3.65109 14.1649 4.08597 14.9463 4.86007C15.7277 5.63417 16.1667 6.68407 16.1667 7.77881V13.9704C16.1667 14.1893 16.2545 14.3993 16.4108 14.5541C16.567 14.709 16.779 14.7959 17 14.7959C17.221 14.7959 17.433 14.709 17.5893 14.5541C17.7455 14.3993 17.8333 14.1893 17.8333 13.9704V7.77881C17.8333 6.24617 17.2188 4.77631 16.1248 3.69257C15.0308 2.60884 13.5471 2 12 2Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id={clipPathId}>
                    <rect
                        width="20"
                        height="20"
                        fill="currentColor"
                        transform="translate(2 2)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}
