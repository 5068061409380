import { styled } from "@mui/material"

export const Label = styled("label")(({ theme }) => ({
    display: "block",
    fontWeight: 500,
    color: theme.palette.grey[900],
    fontSize: "14px",
    lineHeight: "22px",
    marginBottom: "8px",
}))
