import { t } from "@lingui/macro"
import { useMemo } from "react"
import { Typography, Box } from "@mui/material"
import Markdown from "markdown-to-jsx"

import { MessageBubble } from "src/components/ChatMessageJourney/sections/ResponseSection/styled"
import { IResponseSectionProps } from "src/components/ChatMessageJourney/types"

const ResponseSection = ({ message }: IResponseSectionProps) => {
    const responseText = useMemo(() => message?.text ?? "", [message?.text])

    return (
        <Box component="section" sx={{ marginBottom: 2 }}>
            <Typography variant="subtitle2" sx={{ marginBottom: 0.5 }}>
                {t`chat-message-journey.response`}
            </Typography>
            <MessageBubble>
                <Markdown>{responseText}</Markdown>
            </MessageBubble>
        </Box>
    )
}

export default ResponseSection
