import { IIconProps } from "src/components/icons"

export const LegalEntity24 = (props: IIconProps) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.258 4.5H17C18.6569 4.5 20 5.84315 20 7.5V17C20 18.6569 18.6569 20 17 20H3C1.34315 20 0 18.6569 0 17V7.5C0 5.84315 1.34315 4.5 3 4.5H4.75V3.25C4.7522 1.45599 6.20599 0.00220641 8 2.46507e-06H12C12.863 -0.00105905 13.6911 0.340754 14.3021 0.950248C14.9131 1.55974 15.2569 2.38699 15.258 3.25V4.5ZM8 2.5C7.58579 2.5 7.25 2.83579 7.25 3.25V4.5H12.75V3.25C12.75 2.83579 12.4142 2.5 12 2.5H8ZM14.5 16H5.5C4.94772 16 4.5 15.5523 4.5 15C4.5 14.4477 4.94772 14 5.5 14H14.5C15.0523 14 15.5 14.4477 15.5 15C15.5 15.5523 15.0523 16 14.5 16ZM5.5 10.5H14.5C15.0523 10.5 15.5 10.0523 15.5 9.5C15.5 8.94772 15.0523 8.5 14.5 8.5H5.5C4.94772 8.5 4.5 8.94772 4.5 9.5C4.5 10.0523 4.94772 10.5 5.5 10.5Z"
            fill="currentColor"
        />
    </svg>
)
