import { t } from "@lingui/macro"
import { observer } from "mobx-react"

import { FormFieldSet } from "src/components/FormFieldSet"
import { SegmentPickerField } from "src/components/SegmentPickerField"
import { ResourceDetailStore } from "src/modals/resource-detail/store"
import { useStore } from "src/store/lib/useStore"

export const PublishingSettingsSection = observer(() => {
    const vstore = useStore(ResourceDetailStore)

    return (
        <FormFieldSet
            header={t`resource-detail-modal.publishing-settings-section.field-set-header`}
        >
            <SegmentPickerField
                value={vstore.fields.get("segmentIds")}
                accessGroupId={vstore.fields.get("accessGroupId")}
                onChange={(segmentIds) =>
                    vstore.fields.set("segmentIds", segmentIds)
                }
                disabled={vstore.fields.get("accessType") === "READ"}
                // https://avy.atlassian.net/browse/AVY-7422
                skipLoadingSegments={vstore.fields.get("accessType") === "READ"}
            />
        </FormFieldSet>
    )
})
