import { initialState } from "src/modals/embedded-integration-v2/constants/initialState"
import { initialPurchaseRichLinkDynamicFieldState } from "src/modals/embedded-integration-v2/constants/initialPurchaseRichLinkDynamicFieldState"
import { initialPurchaseSimpleLinkDynamicFieldState } from "src/modals/embedded-integration-v2/constants/initialPurchaseSimpleLinkDynamicFieldState"
import { initialPurchaseCollapsibleLinkDynamicFieldState } from "src/modals/embedded-integration-v2/constants/initialPurchaseCollapsibleLinkDynamicFieldState"

export const initialPurchaseTabFieldsState = {
    statusBadge: { value: false, errorMessage: "" },
    infoBox: { value: false, errorMessage: "" },
    priceBox: { value: false, errorMessage: "" },
    statusBadgeText: { ...initialState },
    statusBadgeBackgroundColor: { ...initialState },
    infoBoxTitle: { ...initialState },
    infoBoxTextDescription: { ...initialState },
    infoBoxBackgroundColor: { ...initialState },
    priceBoxName: { ...initialState },
    priceBoxAmount: { value: 0, errorMessage: "" },
    priceBoxCurrency: { ...initialState },
    priceBoxPeriod: { ...initialState },
    purchaseRichLinkDynamicFieldsList: [
        { ...initialPurchaseRichLinkDynamicFieldState },
    ],
    purchaseSimpleLinkDynamicFieldsList: [
        { ...initialPurchaseSimpleLinkDynamicFieldState },
    ],
    purchaseCollapsibleLinkDynamicFieldsList: [
        { ...initialPurchaseCollapsibleLinkDynamicFieldState },
    ],
}
