import { verificationType } from "src/modals/embedded-integration-v2/constants/verificationType"
import {
    IGeneralFieldsVerificationDataSet,
    IPurchaseCollapsibleLinkVerificationDataSet,
    IPurchaseRichLinkVerificationDataSet,
    IPurchaseSimpleLinkVerificationDataSet,
    ISalesFieldsVerificationDataSet,
} from "src/modals/embedded-integration-v2/types/fieldsVerificationDataset"

export const generalFieldsVerificationDataSet: IGeneralFieldsVerificationDataSet =
    {
        categoryDropdownValue: verificationType,
        categoryFields: verificationType,
    }

export const salesFieldsVerificationDataSet: ISalesFieldsVerificationDataSet = {
    salesCardHeadline: verificationType,
    salesCardSubtitle: verificationType,
    salesCardDescription: verificationType,
}

export const purchaseRichLinkFieldsVerificationDataSet: IPurchaseRichLinkVerificationDataSet =
    {
        linkTitle: verificationType,
        linkSubTitle: verificationType,
        linkUrl: verificationType,
        linkSvgFile: verificationType,
    }

export const purchaseSimpleLinkFieldsVerificationDataSet: IPurchaseSimpleLinkVerificationDataSet =
    {
        linkTitle: verificationType,
        linkUrl: verificationType,
    }

export const purchaseCollapsibleLinkFieldsVerificationDataSet: IPurchaseCollapsibleLinkVerificationDataSet =
    {
        sectionTitle: verificationType,
        sectionContent: verificationType,
    }
