import { Channel } from "src/channel"

/**
 * Wrap a method with a loading function. The `key` provided will be marked as
 * loaded during the duration of the original merhod call.
 *
 * @param keyFn A function returning the symbol to load.
 * @returns A decorated method wrapped by the loading function.
 */
export function loads(keyFn: () => symbol) {
    return (
        /* eslint-disable @typescript-eslint/no-explicit-any */
        target: any,
        memberName: string,
        propertyDescriptor: PropertyDescriptor,
    ) => {
        const member = target[memberName]
        propertyDescriptor.value = async function wrap(
            /* eslint-disable @typescript-eslint/no-explicit-any */
            this: any,
            /* eslint-disable @typescript-eslint/no-explicit-any */
            ...args: any[]
        ) {
            return await loadsWrap(keyFn(), () => member.apply(this, args))
        }
    }
}

export async function loadsWrap(
    key: symbol,
    fn: (...args: any[]) => Promise<any>,
) {
    try {
        Channel.send({
            name: "loading/started",
            payload: { key },
        })
        return await fn()
    } finally {
        Channel.send({
            name: "loading/stopped",
            payload: { key },
        })
    }
}
