import {
    RemoveRequiredFieldsTypes,
    DeduRemoveFieldsTypes,
} from "src/modals/integration-connector-detail/type/removeRequiredFields"

export const getRemoveRequiredFields = (
    connectorType: string | undefined,
): string[] => {
    switch (connectorType) {
        case RemoveRequiredFieldsTypes.DeduCase:
            return [
                DeduRemoveFieldsTypes.ProfessionID,
                DeduRemoveFieldsTypes.TaskpriorityID,
                DeduRemoveFieldsTypes.TasktypeID,
            ]
        default:
            return []
    }
}
