import { styled, ToggleButtonGroup, ToggleButton, Button } from "@mui/material"

export const ToggleChipGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    overflowX: "scroll",
    "::-webkit-scrollbar": {
        display: "none",
    },
    "& .MuiToggleButtonGroup-grouped": {
        marginRight: "6px",
        color: theme.palette.grey[900],
        padding: "8px",
        height: "36px",
        textTransform: "none",
        fontSize: "14px",
        borderRadius: "4px",
        border: "1px solid rgba(0, 0, 0, 0.2)",
        "&.Mui-selected, &.Mui-selected:hover": {
            background: theme.palette.grey[900],
            color: theme.palette.common.white,
        },
        "&:first-of-type": {
            marginLeft: 0,
        },
        "&:last-of-type": {
            marginRight: 0,
        },
        "&:not(:first-of-type)": {
            marginLeft: "0px",
            borderRadius: "4px",
            border: "1px solid rgba(0, 0, 0, 0.2)",
        },
        "&:not(:last-of-type)": {
            borderRadius: "4px",
            border: "1px solid rgba(0, 0, 0, 0.2)",
        },
    },
}))

export const ToggleButtons = styled(ToggleButton)(() => ({
    whiteSpace: "nowrap",
    textAlign: "center",
}))

export const ItemContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "420px",
    marginBottom: "10px",
}))

export const TitleContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
}))

export const CardContainer = styled("div")(
    ({ windowWidth }: { windowWidth: boolean }) => ({
        marginLeft: windowWidth ? 8 : 0,
        marginRight: windowWidth ? 8 : 0,
        maxWidth: "420px",
        minWidth: "275px",
        borderWidth: 1,
        padding: "16px",
        backgroundColor: "white",
    }),
)

export const Separator = styled("div")(() => ({
    border: "1px solid #EFEFEF",
    marginTop: "20px",
}))

export const SegmentContainer = styled("div")(() => ({
    paddingTop: "16px",
    paddingBottom: "16px",
}))

export const Segments = styled("div")(() => ({
    marginTop: "16px",
    marginBottom: "16px",
}))

export const EditSegmentButton = styled(Button)(({ theme }) => ({
    marginTop: "16px",
    borderColor: theme.palette.grey[900],
    color: theme.palette.grey[900],
}))
