import { observer } from "mobx-react"

import { ListItemGroup } from "src/layouts/DefaultLayout/Navigation/ListItem/ListItemGroup"
import { ListItemSingle } from "src/layouts/DefaultLayout/Navigation/ListItem/ListItemSingle"
import { INavigationItem } from "src/store/navigation/types"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"

interface IProps {
    item: INavigationItem
}

export const CustomListItem = observer((props: IProps) => {
    const gstore = useStore(GlobalStore)

    if (props.item.hidden === true) {
        return null
    } else if (props.item.module != null) {
        const modules = Array.isArray(props.item.module)
            ? props.item.module
            : [props.item.module]
        const hasAccess = modules.some((module) =>
            gstore.session.hasAccessToModule(module),
        )
        if (!hasAccess) {
            return null
        }
    }

    if ("prefix" in props.item) {
        return <ListItemGroup item={props.item} />
    } else {
        return <ListItemSingle item={props.item} />
    }
})
