export enum ChatbotStatus {
    Positive = "positive",
    Negative = "negative",
    Unknown = "Not rated",
}

export const parseStatus = (status: string): ChatbotStatus => {
    switch (status) {
        case ChatbotStatus.Positive:
        case ChatbotStatus.Negative:
            return status
        default:
            return ChatbotStatus.Unknown
    }
}
