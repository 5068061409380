import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useCallback, useMemo } from "react"

import { SliderPanel } from "src/components/WeekdayRangePicker/SliderPanel"
import {
    Distribution,
    IWeekdayRangesValue,
    Weekdays,
} from "src/components/WeekdayRangePicker/utils"

type IProps = {
    distribution: Distribution
    /**
     * `step` defines the slot resolution by minutes.
     */
    step: number
    value: IWeekdayRangesValue
    onChange: (value: IWeekdayRangesValue) => void
    disabled?: boolean
}

export type IPickerProps = IProps

export const Picker = observer(({ onChange, value, ...props }: IProps) => {
    // `handleSingleChange` is used when `individualDays: true` and we need to
    // update a single weekday in `value`
    const handleSingleChange = useMemo(() => {
        return Object.values(Weekdays).reduce((acc, weekday) => {
            acc[weekday] = (weekdayValue) =>
                onChange({ ...value, [weekday]: weekdayValue })
            return acc
        }, {} as { [key in Weekdays]: (value: number[] | null) => void })
    }, [onChange, value])

    // `handleAllChange` is used when `individualDays: false` and we need to
    // update all days to the same value.
    const handleAllChange = useCallback(
        (value: number[] | null) => {
            const nextValue = Object.values(Weekdays).reduce((acc, weekday) => {
                acc[weekday] = value
                return acc
            }, {} as IWeekdayRangesValue)
            onChange(nextValue)
        },
        [onChange],
    )

    const weekdayLabels = useMemo(
        () => ({
            [Weekdays.Monday]: t`weekday-range-picker.monday-panel-label`,
            [Weekdays.Tuesday]: t`weekday-range-picker.tuesday-panel-label`,
            [Weekdays.Wednesday]: t`weekday-range-picker.wednesday-panel-label`,
            [Weekdays.Thursday]: t`weekday-range-picker.thursday-panel-label`,
            [Weekdays.Friday]: t`weekday-range-picker.friday-panel-label`,
            [Weekdays.Saturday]: t`weekday-range-picker.saturday-panel-label`,
            [Weekdays.Sunday]: t`weekday-range-picker.sunday-panel-label`,
        }),
        [],
    )

    if (props.distribution === Distribution.IndividualDays) {
        return (
            <>
                {Object.values(Weekdays).map((weekday) => (
                    <SliderPanel
                        key={weekday}
                        label={weekdayLabels[weekday]}
                        step={props.step}
                        value={value[weekday] ?? null}
                        onChange={handleSingleChange[weekday]}
                        disabled={props.disabled}
                    />
                ))}
            </>
        )
    } else {
        return (
            <SliderPanel
                label={t`weekday-range-picker.all-week-panel-label`}
                step={props.step}
                togglable={false}
                // All days are equal so we can pick any as the value for the
                // slider.
                value={value[Weekdays.Monday] ?? null}
                onChange={handleAllChange}
                disabled={props.disabled}
            />
        )
    }
})
