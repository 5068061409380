import { t } from "@lingui/macro"
import { Button, Stack, Typography } from "@mui/material"
import { observer } from "mobx-react"

import { ModalHeader } from "src/components/ModalHeader"
import { StatusChip } from "src/components/StatusChip"
import { formatDateTime } from "src/lib/date"
import {
    RequestDetailsModalStyledBox,
    RequestDetailsModalStyledTable,
} from "src/views/self-registration/components/RequestDetailsModal/styled"
import { IRequestDetailsModalProps } from "src/views/self-registration/types/requestDetailModalProps"

export const SelfRegistrationRequestsDetailsModal = observer(
    (props: IRequestDetailsModalProps) => {
        return (
            <>
                <ModalHeader>
                    {props.item.status !== "approved" &&
                        props.item.status !== "rejected" && (
                            <Stack direction="row" spacing={2}>
                                <Button
                                    color="info"
                                    variant="contained"
                                    onClick={props.onApprove}
                                >
                                    {t`self-registration-view.approve`}
                                </Button>
                                <Button
                                    color="error"
                                    variant="contained"
                                    onClick={props.onReject}
                                    autoFocus
                                >
                                    {t`self-registration-view.reject`}
                                </Button>
                            </Stack>
                        )}
                </ModalHeader>
                <Stack direction="column" spacing={3} sx={{ mt: 2 }}>
                    <RequestDetailsModalStyledBox>
                        <Typography
                            component="h2"
                            fontSize="24px"
                            fontWeight="400"
                        >
                            {props.item.firstName} {props.item.lastName}
                        </Typography>
                    </RequestDetailsModalStyledBox>
                    <RequestDetailsModalStyledBox>
                        <Typography
                            component="h3"
                            fontSize="20px"
                            fontWeight="500"
                            sx={{ mb: 1 }}
                        >
                            {t`self-registration-view.request`}
                        </Typography>
                        <RequestDetailsModalStyledTable>
                            <tbody>
                                <tr>
                                    <th>{t`self-registration-view.status`}</th>
                                    {props.item.status != null ? (
                                        <td style={{ padding: 0 }}>
                                            <StatusChip
                                                label={
                                                    props.item.status ===
                                                    "approved"
                                                        ? t`self-registration-view.approved`
                                                        : props.item.status ===
                                                          "rejected"
                                                        ? t`self-registration-view.rejected`
                                                        : t`self-registration-view.new`
                                                }
                                                state={
                                                    props.item.status ===
                                                    "approved"
                                                        ? "positive"
                                                        : props.item.status ===
                                                          "rejected"
                                                        ? "negative"
                                                        : "other"
                                                }
                                            />
                                        </td>
                                    ) : (
                                        <td>-</td>
                                    )}
                                </tr>
                                <tr>
                                    <th>{t`self-registration-view.request-id`}</th>
                                    <td>{props.item.lobbyId}</td>
                                </tr>
                                <tr>
                                    <th>{t`self-registration-view.request-date`}</th>
                                    <td>
                                        {props.item.createdAt != null &&
                                            formatDateTime(
                                                props.item.createdAt,
                                            )}
                                    </td>
                                </tr>
                            </tbody>
                        </RequestDetailsModalStyledTable>
                    </RequestDetailsModalStyledBox>
                    <RequestDetailsModalStyledBox>
                        <Typography
                            component="h3"
                            fontSize="20px"
                            fontWeight="500"
                            sx={{ mb: 1 }}
                        >
                            {t`self-registration-view.requester`}
                        </Typography>
                        <RequestDetailsModalStyledTable>
                            <tbody>
                                <tr>
                                    <th>{t`self-registration-view.first-name`}</th>
                                    <td>{props.item.firstName}</td>
                                </tr>
                                <tr>
                                    <th>{t`self-registration-view.last-name`}</th>
                                    <td>{props.item.lastName}</td>
                                </tr>
                                <tr>
                                    <th>{t`self-registration-view.social-security-number`}</th>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <th>{t`self-registration-view.email-address`}</th>
                                    <td>{props.item.email}</td>
                                </tr>
                                <tr>
                                    <th>{t`self-registration-view.phone-number`}</th>
                                    <td>{props.item.phone}</td>
                                </tr>
                            </tbody>
                        </RequestDetailsModalStyledTable>
                    </RequestDetailsModalStyledBox>
                    <RequestDetailsModalStyledBox>
                        <Typography
                            component="h3"
                            fontSize="20px"
                            fontWeight="500"
                            sx={{ mb: 1 }}
                        >
                            {t`self-registration-view.property`}
                        </Typography>
                        <RequestDetailsModalStyledTable>
                            <tbody>
                                <tr>
                                    <th>{t`self-registration-view.property`}</th>
                                    <td>{props.item.propertyName}</td>
                                </tr>
                                <tr>
                                    <th>{t`self-registration-view.property-code`}</th>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <th>{t`self-registration-view.street`}</th>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <th>{t`self-registration-view.city`}</th>
                                    <td>-</td>
                                </tr>
                            </tbody>
                        </RequestDetailsModalStyledTable>
                    </RequestDetailsModalStyledBox>
                </Stack>
            </>
        )
    },
)
