import { useMemo } from "react"
import { t } from "@lingui/macro"
import { Box, Stack, Tooltip, Typography } from "@mui/material"

import { IColumn } from "src/types/data-grid-pro"
import { LocationChips } from "src/views/content-items/components/LocationChips"
import { TooltipContainer } from "src/styledComponents/TooltipContainer"
import { AccessTypeHeader } from "src/components/AccessTypeHeader"
import { useStore } from "src/store/lib/useStore"
import { ContentItemsStore } from "src/views/content-items/store"
import { RowCellContainer } from "src/views/contact-forms/styled"
import {
    IContentItems,
    IContentTypes,
} from "src/views/content-items/types/content-items"
import { locationSwitch } from "src/views/content-items/helper/locationToPrioritySwtich"
import { ImageCellContainer } from "src/views/content-items/styled"

export const useContentItemColumns = (): IColumn<IContentItems>[] => {
    const store = useStore(ContentItemsStore)
    const contentTypes: IContentTypes[] = useMemo(() => {
        return [
            {
                label: t`navigation-items-view.columns.dropdown.module`,
                value: "module",
            },
            {
                label: t`navigation-items-view.columns.dropdown.embed`,
                value: "embed",
            },
        ]
    }, [])

    return useMemo(() => {
        const columns: IColumn<IContentItems>[] = [
            {
                field: "id",
                headerName: t`navigation-items-view.columns.item-id`,
                renderCell: (params) => (
                    <RowCellContainer>{params.value}</RowCellContainer>
                ),
                type: "number",
                minWidth: 150,
                flex: 1,
            },
            {
                field: "icon",
                headerName: t`navigation-items-view.columns.icon`,
                renderCell: (params) => (
                    <RowCellContainer>
                        {Boolean(params.value) ? (
                            <Box sx={{ width: 24, height: 24 }}>
                                <ImageCellContainer src={params.value} />
                            </Box>
                        ) : (
                            "-"
                        )}
                    </RowCellContainer>
                ),
                type: "string",
                filterable: false,
                sortable: false,
                width: 120,
            },
            {
                field: "internal_name",
                headerName: t`navigation-items-view.columns.internal-name`,
                renderCell: (params) => (
                    <RowCellContainer>{params.value}</RowCellContainer>
                ),
                type: "string",
                minWidth: 178,
                flex: 1,
            },
            {
                field: "entity",
                headerName: t`navigation-items-view.columns.type`,
                renderCell: (params) => (
                    <RowCellContainer>{params.value}</RowCellContainer>
                ),
                // @ts-ignore
                // Value options is available on one of the options for type of TColumn(GridSingleSelectColDef), but it's not reading here
                valueOptions: contentTypes,
                minWidth: 189,
                type: "singleSelect",
                flex: 1,
            },
            {
                field: "location",
                headerName: t`navigation-items-view.columns.location`,
                renderCell: (params) => (
                    <RowCellContainer>
                        <Stack direction="row" spacing={1}>
                            <LocationChips locations={params.value} />
                        </Stack>
                    </RowCellContainer>
                ),
                minWidth: 200,
                type: "string",
                filterable: false,
                sortable: false,
                flex: 1,
            },
            {
                field: "published_to",
                headerName: t`navigation-items-view.columns.published-to`,
                renderCell: (params) => {
                    const { value, row } = params
                    return (
                        <TooltipContainer sx={{ cursor: "pointer" }}>
                            <Tooltip
                                arrow
                                title={row?.segment_names?.join(", ") ?? ""}
                            >
                                <Typography>{value}</Typography>
                            </Tooltip>
                        </TooltipContainer>
                    )
                },
                filterable: false,
                sortable: false,
                type: "string",
                minWidth: 170,
            },
            {
                field: "access_type",
                headerName: t`navigation-items-view.columns.access-type`,
                renderCell: (params) => (
                    <AccessTypeHeader accessType={params.value} />
                ),
                type: "string",
                filterable: false,
                sortable: false,
                flex: 1,
                minWidth: 70,
            },
        ]

        // Dynamically add the priority column based on the store's priorityColumnName
        const priorityField = store.priorityColumnName // Assuming it's a single value
        if (priorityField.length > 0) {
            let startPosition = 5 // Specify the position to start inserting
            priorityField.forEach((field) => {
                columns.splice(startPosition++, 0, {
                    field: locationSwitch(field) as keyof IContentItems,
                    headerName: `${t`navigation-items-view.columns.priority`} ${field.replaceAll(
                        "_",
                        " ",
                    )}`,
                    renderCell: (params) => (
                        <RowCellContainer>
                            {Boolean(params.value) ? params.value : "-"}
                        </RowCellContainer>
                    ),
                    type: "string",
                    minWidth: 150,
                    filterable: false,
                    flex: 1,
                })
            })
        }

        return columns
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.priorityColumnName])
}
