import { Button, DialogActions, DialogContent } from "@mui/material"

import { isVideoByUrl } from "src/lib/video"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"

interface IProps {
    url: string
}

export const AttachmentModal = ({ url }: IProps) => {
    const gstore = useStore(GlobalStore)

    const handleClose = () => {
        gstore.modals.pop()
    }

    return (
        <>
            <DialogActions>
                <Button color="info" onClick={handleClose}>
                    {"Close"}
                </Button>
            </DialogActions>
            <DialogContent
                sx={{
                    alignContent: "center",
                    justifyContent: "center",
                    display: "flex",
                }}
            >
                {isVideoByUrl(url) ? (
                    <video
                        autoPlay
                        controls
                        style={{
                            objectFit: "contain",
                        }}
                    >
                        <source src={url} />
                    </video>
                ) : (
                    <img
                        src={url}
                        alt="Full size"
                        style={{
                            objectFit: "contain",
                        }}
                    />
                )}
            </DialogContent>
        </>
    )
}
