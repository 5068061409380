import { Stack, Typography } from "@mui/material"

import { Icon } from "src/components/icons"
import {
    AncestorTooltip,
    ChipWrapper,
} from "src/components/SegmentPicker/components/SegmentPickerChip/styled"
import { ISegmentPickerChipProps } from "src/components/SegmentPicker/components/SegmentPickerChip/types"
import { formatInteger } from "src/lib/number"

export const SegmentPickerChip = (props: ISegmentPickerChipProps) => {
    const chip = (
        <ChipWrapper variant={props.variant}>
            <Stack direction="row" spacing={0.5}>
                <Icon icon={props.icon} />
                <Typography fontSize="12px" color={props.textColor}>
                    {formatInteger(props.tenantCount)}
                </Typography>
            </Stack>
        </ChipWrapper>
    )
    if (props.tooltip != null) {
        return (
            <AncestorTooltip title={props.tooltip} placement="right" arrow>
                {chip}
            </AncestorTooltip>
        )
    } else {
        return chip
    }
}
