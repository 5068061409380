import { observer } from "mobx-react"

import { Stack, FormHelperText, FormControl, FormLabel } from "@mui/material"

import { t } from "@lingui/macro"

import { useCallback } from "react"

import { DynamicCardLayout } from "src/components/DynamicCardLayout"
import { ModalTextField } from "src/components/ModalTextField"
import { IDynamicFieldItemProps } from "src/modals/embedded-integration-v2/types/dynamicFieldItem"
import { IPurchaseTabDynamicRichLinkFieldsProps } from "src/modals/embedded-integration-v2/types/purchaseCollapsibleDynamicFields"
import { useStore } from "src/store/lib/useStore"
import { ContentSectionStore } from "src/modals/embedded-integration-v2/sections/ContentSection/store"
import { WysiwygEditor } from "src/components/WysiwygEditor"
import { IPurchaseCollapsibleLinkDynamicFieldsItemProps } from "src/modals/embedded-integration-v2/types/purchaseCollapsibleLinkFieldsItem"

export const EmbeddedIntegrationCollapsibleCardSection = observer(
    ({
        index,
        item,
        purchaseType,
        onRemoveClick,
    }: IPurchaseTabDynamicRichLinkFieldsProps) => {
        const contentStore = useStore(ContentSectionStore)
        const purchaseCollapsibleLinkDynamicFieldsList =
            purchaseType.purchaseCollapsibleLinkDynamicFieldsList
        const salePurchaseSelectedType =
            contentStore.fields.data.salesPurchaseType

        const handleTextInputChange = (
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            key: string,
        ) => {
            const purchaseObject = {
                ...purchaseType,
            }
            const data = [...purchaseCollapsibleLinkDynamicFieldsList]
            const obj = JSON.parse(JSON.stringify(data[index]))
            obj[key].errorMessage = ""
            obj[key].value =
                event.target.value.trim() !== "" ? event.target.value : ""
            data[index] = obj
            purchaseObject.purchaseCollapsibleLinkDynamicFieldsList = data
            contentStore.fields.set(salePurchaseSelectedType, purchaseObject)
        }

        const handleWysiwygEditorChange = useCallback(
            (value: string, key: string) => {
                type PurchaseCollapsibleLinkFieldKey =
                    keyof IPurchaseCollapsibleLinkDynamicFieldsItemProps
                const updatedFieldsList =
                    purchaseCollapsibleLinkDynamicFieldsList.map(
                        (item, idx) => {
                            if (idx === index) {
                                const updatedItem = { ...item }
                                updatedItem[
                                    key as PurchaseCollapsibleLinkFieldKey
                                ] = {
                                    ...item[
                                        key as PurchaseCollapsibleLinkFieldKey
                                    ],
                                    errorMessage: "",
                                    value: value.trim() !== "" ? value : "",
                                }
                                return updatedItem
                            }
                            return item
                        },
                    )

                const purchaseObject = {
                    ...purchaseType,
                    purchaseCollapsibleLinkDynamicFieldsList: updatedFieldsList,
                }

                contentStore.fields.set(
                    salePurchaseSelectedType,
                    purchaseObject,
                )
            },
            [
                contentStore.fields,
                index,
                purchaseCollapsibleLinkDynamicFieldsList,
                purchaseType,
                salePurchaseSelectedType,
            ],
        )

        const renderTextInput = (
            label: string,
            item: IDynamicFieldItemProps,
            key: string,
            rowLength: number,
            maxLength?: number,
        ) => {
            return (
                <ModalTextField
                    variant="default"
                    label={label}
                    value={item?.value}
                    onChange={(event) => handleTextInputChange(event, key)}
                    helperText={item.errorMessage}
                    error={Boolean(item.errorMessage)}
                    maxLength={maxLength ?? 255}
                    rows={rowLength}
                />
            )
        }

        const renderWysiwygEditorInput = (
            label: string,
            item: IDynamicFieldItemProps,
            key: string,
        ) => {
            return (
                <FormControl>
                    <FormLabel>{label}</FormLabel>
                    <WysiwygEditor
                        placeholder={t`embed-integrations-modal.content-section.purchase-tab.collapsible-section.enter-content-here`}
                        initialValue={item?.value ?? ""}
                        onChange={(value) =>
                            handleWysiwygEditorChange(value, key)
                        }
                        invalid={Boolean(item.errorMessage)}
                        readOnly={false}
                    />
                    <FormHelperText
                        sx={{
                            margin: "3px 14px 0",
                        }}
                        error={Boolean(item.errorMessage)}
                    >
                        {item.errorMessage}
                    </FormHelperText>
                </FormControl>
            )
        }

        return (
            <DynamicCardLayout
                index={index}
                showDraggableIndicator={true}
                hideCopyContentIcon={true}
                hideBinIcon={false}
                onRemoveClick={onRemoveClick}
            >
                <Stack spacing={2}>
                    {renderTextInput(
                        t`embed-integrations-modal.content-section.purchase-tab.collapsible-section.section-title`,
                        item.sectionTitle,
                        "sectionTitle",
                        1,
                        255,
                    )}
                    {renderWysiwygEditorInput(
                        t`embed-integrations-modal.content-section.purchase-tab.collapsible-section.section-content`,
                        item.sectionContent,
                        "sectionContent",
                    )}
                </Stack>
            </DynamicCardLayout>
        )
    },
)
