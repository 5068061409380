import React from "react"

import {
    contact_ContactFormTypeWithSegments,
    ContactFormAdminService,
} from "src/api"
import { Channel } from "src/channel"

import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"

export class ContactFormSettingsViewStore implements IDisposable {
    static Context = React.createContext<ContactFormSettingsViewStore | null>(
        null,
    )
    static LoadingKeys = createLoadingKeys("pageLoad")

    private repositoryUpdatesListenerDisposer?: () => void

    contactFormTypes = new Pagination(
        async (params) => {
            const response =
                await ContactFormAdminService.getV1AdminContactFormType({
                    pageNumber: params.page,
                    pageSize: params.pageSize,
                })

            const items = (response.result ?? []).map((item) => ({
                id: item.contact_form_type_id ?? -1,
                name: item.name ?? "",
                publishedIn: item.published_in ?? [],
                fields: this.extractFields(item),
                messagingEnabled: item.messaging_enabled,
            }))

            return { items, count: response.total_count ?? 0 }
        },
        { loadingKey: ContactFormSettingsViewStore.LoadingKeys.pageLoad },
    )

    dispose() {
        this.repositoryUpdatesListenerDisposer?.()
    }

    async init() {
        await this.contactFormTypes.loadInitialPage()

        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "contact-form-types"
                ) {
                    await this.contactFormTypes.reload()
                }
            },
        )
    }

    private extractFields(item: contact_ContactFormTypeWithSegments): string[] {
        if (typeof item.fields === "object" && item.fields != null) {
            return Object.values(item.fields).map(String)
        }
        return []
    }
}
