import { t } from "@lingui/macro"
import { Alert } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useEffect } from "react"

import { CenteredSpinner } from "src/components/CenteredSpinner"
import { Form } from "src/components/Form"
import { FormPanel } from "src/components/FormPanel"
import { AssigneeSection } from "src/modals/contact-form-detail/AssigneeSection"
import { ErrandSection } from "src/modals/contact-form-detail/ErrandSection"
import { ReporterSection } from "src/modals/contact-form-detail/ReporterSection"

import { ContactFormDetailStore } from "src/modals/contact-form-detail/store"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"

interface IProps {
    id: number
    formId: string
}

export const FormDetail = observer((props: IProps) => {
    const store = useStore(ContactFormDetailStore)
    const gstore = useStore(GlobalStore)

    useEffect(() => {
        ;(async () => {
            await store.init(props.id)
        })()
    }, [store, props.id])

    const handleSubmit = useCallback(async () => {
        await store.submit()
        if (!store.form.hasErrors()) {
            gstore.modals.pop()
        }
    }, [store, gstore])

    if (
        !store.initialized ||
        gstore.loading.is(ContactFormDetailStore.LoadingKeys.init)
    ) {
        return <CenteredSpinner height="100vh" />
    }

    return (
        <Form formId={props.formId} onSubmit={handleSubmit}>
            {Boolean(store.form.error("generic")) && (
                <Alert severity="error">{store.form.error("generic")}</Alert>
            )}
            <FormPanel
                sections={[
                    {
                        header: t`contact-form-detail-modal.errand-section.header`,
                        subHeader: t`contact-form-detail-modal.errand-section.manage-errand-header`,
                        content: <ErrandSection id={props.id} />,
                    },
                    {
                        header: t`contact-form-detail-modal.reporter-section.header`,
                        content: <ReporterSection />,
                    },
                    {
                        header: t`contact-form-detail-modal.assignee-section.header`,
                        subHeader: t`contact-form-detail-modal.assignee-section.sub-header`,
                        content: <AssigneeSection />,
                    },
                ]}
            />
        </Form>
    )
})
