import { observer } from "mobx-react"
import { TextField, Box, useTheme, Typography } from "@mui/material"
import { t } from "@lingui/macro"

import { EditProductStore } from "src/views/product-catalogue/edit/store"
import { useStore } from "src/store/lib/useStore"
import { Alert16 } from "src/components/icons/Alert16"
import { Icon } from "src/components/icons"
import { Tooltip } from "src/components/Tooltips/Tooltip"

export const ProductListSubtitle = observer(() => {
    const store = useStore(EditProductStore)
    const theme = useTheme()

    return (
        <Box
            sx={{ display: "flex", gap: "8px" }}
            style={{ breakInside: "avoid" }}
        >
            <TextField
                label={t`edit-product-view.product-list-subtitle.label`}
                value={store.formFields.get("productListSubtitle") ?? ""}
                onChange={store.formFields.setter("productListSubtitle")}
                error={Boolean(store.formFields.error("productListSubtitle"))}
                helperText={
                    store.formFields.error("productListSubtitle") ??
                    t`edit-product-view.product-list-subtitle.helper`
                }
            />
            <Tooltip
                title={t`edit-product-view.product-list-subtitle.tooltip`}
                arrow
            >
                <Typography sx={{ marginTop: "16px" }}>
                    <Icon
                        icon={<Alert16 width="20px" height="20px" />}
                        color={theme.palette.grey[700]}
                    />
                </Typography>
            </Tooltip>
        </Box>
    )
})
