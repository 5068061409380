import { t } from "@lingui/macro"
import { FormControl, FormControlLabel, Switch } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { LibraryInformationItemStore } from "src/modals/library-information-items/store"
import { useStore } from "src/store/lib/useStore"

export const LibraryInformationItemsDavyControls = observer(() => {
    const store = useStore(LibraryInformationItemStore)
    const showInApp = store.formFields.get("showInApp")
    const includeInChatbot = store.formFields.get("includeInChatbot")

    const handleShowInAppChange = useCallback(
        (_: unknown, checked: boolean) => {
            store.formFields.set("showInApp", checked)
        },
        [store.formFields],
    )

    const handleIncludeInChatbotChange = useCallback(
        (_: unknown, checked: boolean) => {
            store.formFields.set("includeInChatbot", checked)
        },
        [store.formFields],
    )

    return (
        <FormControl style={{ breakInside: "avoid" }}>
            <FormControlLabel
                checked={showInApp}
                onChange={handleShowInAppChange}
                control={<Switch color="success" />}
                label={t`edit-information-item-modal.show-in-app-label`}
                sx={{ ml: 1 }}
            />
            <FormControlLabel
                checked={includeInChatbot}
                onChange={handleIncludeInChatbotChange}
                control={<Switch color="success" />}
                label={t`edit-information-item-modal.include-in-chat-bot-label`}
                sx={{ ml: 1, mt: 2 }}
            />
        </FormControl>
    )
})
