import { Stack } from "@mui/material"
import * as React from "react"

interface IProps {
    onSubmit: React.FormEventHandler<HTMLFormElement>
    formId?: string
}

export class Form extends React.Component<IProps> {
    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        this.props.onSubmit(event)
    }

    render() {
        return (
            <form id={this.props.formId} onSubmit={this.handleSubmit}>
                <Stack spacing={2}>{this.props.children}</Stack>
            </form>
        )
    }
}
