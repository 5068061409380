import React from "react"
import { makeAutoObservable } from "mobx"

import { Channel } from "src/channel"
import { createLoadingKeys } from "src/lib/loading"
import { loads } from "src/channel/utils"
import { FormFields } from "src/lib/form-fields"
import { AccessGroupsAdminService, AdminService } from "src/api"

interface IFormFields {
    name: string
    email: string
    legalEntityIds: number[]
    permissionIds: number[]
    accessGroupIds: number[]
    createUserPropertyOwnerId?: number
}

export interface IMultiSelectItem {
    id: number
    name: string
}

export class ManageUsersCreateOrEditModalStore {
    static Context =
        React.createContext<ManageUsersCreateOrEditModalStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "submit")

    fields = new FormFields<IFormFields>({
        name: "",
        email: "",
        legalEntityIds: [],
        permissionIds: [],
        accessGroupIds: [],
        createUserPropertyOwnerId: undefined,
    })

    private _propertyOwnerId?: number = undefined
    private setPropertyOwnerId(propertyOwnerId: number) {
        this._propertyOwnerId = propertyOwnerId
    }

    private _legalEntities: IMultiSelectItem[] = []
    private setLegalEntities(legalEntities: IMultiSelectItem[]) {
        this._legalEntities = legalEntities
    }
    get legalEntities() {
        return this._legalEntities
    }

    private _accessGroups: IMultiSelectItem[] = []
    private setAccessGroups(accessGroups: IMultiSelectItem[]) {
        this._accessGroups = accessGroups
    }
    get accessGroups() {
        return this._accessGroups
    }

    constructor() {
        makeAutoObservable(this)
    }

    @loads(() => ManageUsersCreateOrEditModalStore.LoadingKeys.init)
    async init(
        duplicate: boolean,
        adminId?: number,
        loggedInPropertyOwnerId?: number,
    ) {
        if (adminId != null) {
            const response = await AdminService.getV1Admin1({
                adminId: adminId,
            })

            this.setPropertyOwnerId(response.property_owner_id ?? 0)

            if (this._propertyOwnerId != null && this._propertyOwnerId !== 0) {
                const legalEntitiesResponse =
                    await AdminService.getV1AdminLegalEntity({
                        propertyOwnerId: this._propertyOwnerId,
                    })
                this.setLegalEntities(
                    legalEntitiesResponse.map(
                        (legalEntity): IMultiSelectItem => {
                            return {
                                id: legalEntity.legal_entity_id ?? 0,
                                name: legalEntity.legal_name ?? "",
                            }
                        },
                    ),
                )
            }

            const accessGroupsResponse =
                await AccessGroupsAdminService.getV1AdminAccessGroup()
            this.setAccessGroups(
                accessGroupsResponse.map((accessGroup) => ({
                    name: accessGroup.name ?? "",
                    id: accessGroup.id ?? 0,
                })),
            )

            this.fields.init({
                name: duplicate ? "" : response.name ?? "",
                email: duplicate ? "" : response.email ?? "",
                legalEntityIds: response.legal_entity_ids ?? [],
                permissionIds:
                    response.modules?.map((module) => module.module_id ?? 0) ??
                    [],
                accessGroupIds: response.access_group_ids ?? [],
                createUserPropertyOwnerId:
                    loggedInPropertyOwnerId != null
                        ? loggedInPropertyOwnerId
                        : undefined,
            })
        }
    }

    @loads(() => ManageUsersCreateOrEditModalStore.LoadingKeys.submit)
    async submit(adminId: number | undefined, duplicate: boolean) {
        await this.fields.catchErrors(async () => {
            const { data } = this.fields
            if (duplicate) {
                if (adminId != null) {
                    await AdminService.postV1AdminClone({
                        request: {
                            email: data.email,
                            name: data.name,
                        },
                        adminId: adminId,
                    })

                    Channel.send({
                        name: "repository/updated",
                        payload: {
                            repository: "manage-users",
                            action: "duplicate",
                        },
                    })
                }
            } else {
                if (adminId != null) {
                    await AdminService.patchV1Admin({
                        request: {
                            access_group_ids: data.accessGroupIds,
                            email: data.email,
                            name: data.name,
                        },
                        adminId: adminId,
                    })

                    Channel.send({
                        name: "repository/updated",
                        payload: {
                            repository: "manage-users",
                            action: "update",
                        },
                    })

                    await AdminService.putV1AdminAccess({
                        request: {
                            access_type: "legalentity",
                            values: data.legalEntityIds,
                        },
                        adminId: adminId,
                    })

                    Channel.send({
                        name: "repository/updated",
                        payload: {
                            repository: "manage-users",
                            action: "update",
                        },
                    })

                    await AdminService.putV1AdminAccess({
                        request: {
                            access_type: "module",
                            values: data.permissionIds,
                        },
                        adminId: adminId,
                    })

                    Channel.send({
                        name: "repository/updated",
                        payload: {
                            repository: "manage-users",
                            action: "update",
                        },
                    })
                } else {
                    await AdminService.postV1Admin({
                        request: {
                            email: data.email,
                            name: data.name,
                            property_owner_id: this.fields.get(
                                "createUserPropertyOwnerId",
                            ),
                        },
                    })

                    Channel.send({
                        name: "repository/updated",
                        payload: {
                            repository: "manage-users",
                            action: "create",
                        },
                    })
                }
            }
        })
    }
}
