import { t } from "@lingui/macro"
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
} from "@mui/material"
import { observer } from "mobx-react"

import { useCallback } from "react"

import { MixpanelProperties } from "src/analytics/constants/properties"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"

import { FormFieldSet } from "src/components/FormFieldSet"
import { useUniqueId } from "src/lib/unique-id"
import { CaseDetailStore } from "src/modals/cases-v2-detail/store"
import { ICasesErrandSectionProps } from "src/modals/cases-v2-detail/types/errandSectionProps"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { StatusDot } from "src/modals/cases-v2-detail/sections/styled"
import { parseStatusCasesV2 } from "src/helpers/parseStatusCasesV2"
import { StatusCasesV2 } from "src/types/status-casesV2"

const statuses = [
    StatusCasesV2.ExternallyHandled,
    StatusCasesV2.InProgress,
    StatusCasesV2.Done,
    StatusCasesV2.Created,
]

export const ErrandSection = observer(({ id }: ICasesErrandSectionProps) => {
    const store = useStore(CaseDetailStore)
    const globalStore = useStore(GlobalStore)
    const statusLabelId = useUniqueId()

    const statusTranslations = {
        [StatusCasesV2.Created]: t`cases-detail-modal.errand-section.status-created`,
        [StatusCasesV2.Done]: t`cases-detail-modal.errand-section.status-done`,
        [StatusCasesV2.InProgress]: t`cases-detail-modal.errand-section.status-in-progress`,
        [StatusCasesV2.ExternallyHandled]: t`cases-detail-modal.errand-section.status-externally-handled`,
        [StatusCasesV2.Unknown]: t`cases-detail-modal.errand-section.status-unknown`,
    }

    const onSelectValueChange = useCallback(
        (value: string) => {
            trackModuleEvent("Cases | Change status", {
                [MixpanelProperties.ItemID]: id,
                [MixpanelProperties.AccessGroupName]:
                    globalStore.session.accessGroup?.name,
                [MixpanelProperties.AccessGroupID]:
                    globalStore.session.accessGroup?.id,
            })
            store.form.set("status", parseStatusCasesV2(value))
        },
        [
            globalStore.session.accessGroup?.id,
            globalStore.session.accessGroup?.name,
            id,
            store.form,
        ],
    )
    return (
        <FormFieldSet>
            <Stack spacing={2}>
                <Stack spacing={2} direction="row">
                    <TextField
                        label={t`cases-detail-modal.errand-section.form-type-name`}
                        value={store.form.get("formTypeName")}
                        onChange={store.form.setter("formTypeName")}
                        disabled
                    />
                </Stack>
                <FormControl>
                    <InputLabel id={statusLabelId}>
                        {t`cases-detail-modal.errand-section.status-label`}
                    </InputLabel>
                    <Select
                        label={t`cases-detail-modal.errand-section.status-label`}
                        labelId={statusLabelId}
                        fullWidth
                        value={store.form.get("status")}
                        onChange={(event) =>
                            onSelectValueChange(event.target.value)
                        }
                    >
                        {statuses.map((status) => (
                            <MenuItem key={status} value={status}>
                                <StatusDot status={status} />
                                {statusTranslations[status]}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {Boolean(store.form.get("title")) && (
                    <TextField
                        label={t`cases-detail-modal.errand-section.title-label`}
                        value={store.form.get("title")}
                        onChange={store.form.setter("title")}
                        disabled
                    />
                )}
                {Boolean(store.form.get("objectId")) && (
                    <TextField
                        label={t`cases-detail-modal.forms-tab.object-id-header`}
                        value={store.form.get("objectId")}
                        disabled
                    />
                )}
                {Boolean(store.form.get("objectAddress")) && (
                    <TextField
                        label={t`cases-detail-modal.forms-tab.object-address`}
                        value={store.form.get("objectAddress")}
                        disabled
                    />
                )}
                {Boolean(store.form.get("externalFormId")) && (
                    <TextField
                        label={t`cases-detail-modal.forms-tab.external-form-id-label`}
                        value={store.form.get("externalFormId")}
                        disabled
                    />
                )}
            </Stack>
        </FormFieldSet>
    )
})
