import { observer } from "mobx-react"

import { Button } from "@mui/material"

import { Add24 } from "src/components/icons/Add24"
import { Icon } from "src/components/icons"

import { AddDynamicOptionsSection } from "src/components/AddDynamicOptionSection/AddDynamicOptionsSection"
import { FormPanel } from "src/components/FormPanel"
import { ISalesDynamicBulletOptionsProps } from "src/modals/embedded-integration-v2/types/salesDynamicBulletOptions"

export const EmbeddedIntegrationSalesTabBulletOptionSection = observer(
    ({
        headerLabel,
        textInputLabel,
        buttonText,
        item,
        theme,
        onInputChange,
        onRemoveClick,
        onAddOptionClick,
        disabled,
    }: ISalesDynamicBulletOptionsProps) => (
        <FormPanel
            sections={[
                {
                    header: headerLabel,
                    content: (
                        <>
                            {item.map((input, index) => (
                                <AddDynamicOptionsSection
                                    type={"Dropdown"}
                                    index={index}
                                    item={input}
                                    onInputChange={onInputChange}
                                    onRemoveClick={onRemoveClick}
                                    disabled={false}
                                    label={textInputLabel}
                                />
                            ))}
                            <Button
                                sx={{ alignSelf: "flex-start" }}
                                startIcon={
                                    <Icon
                                        icon={<Add24 />}
                                        size={20}
                                        color={theme.palette.info.main}
                                    />
                                }
                                disabled={disabled}
                                color="info"
                                onClick={onAddOptionClick}
                            >
                                {buttonText}
                            </Button>
                        </>
                    ),
                },
            ]}
        />
    ),
)
