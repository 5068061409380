import { IIconProps } from "src/components/icons"

export const HamburgerClose32 = (props: IIconProps) => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 9.33333C4 10.0667 4.6 10.6667 5.33333 10.6667H20C20.7333 10.6667 21.3333 10.0667 21.3333 9.33333C21.3333 8.6 20.7333 8 20 8H5.33333C4.6 8 4 8.6 4 9.33333ZM16 17.3333H5.33333C4.6 17.3333 4 16.7333 4 16C4 15.2667 4.6 14.6667 5.33333 14.6667H16C16.7333 14.6667 17.3333 15.2667 17.3333 16C17.3333 16.7333 16.7333 17.3333 16 17.3333ZM20 24H5.33333C4.6 24 4 23.4 4 22.6667C4 21.9333 4.6 21.3333 5.33333 21.3333H20C20.7333 21.3333 21.3333 21.9333 21.3333 22.6667C21.3333 23.4 20.7333 24 20 24ZM23.4557 16.6601L27.581 20.4269C28.1397 20.937 28.1253 21.774 27.581 22.2841C27.0224 22.7942 26.12 22.7942 25.5613 22.2841L20.419 17.5887C19.8603 17.0787 19.8603 16.2547 20.419 15.7446L25.5613 11.0492C26.12 10.5391 27.0224 10.5391 27.581 11.0492C28.1397 11.5593 28.1397 12.3833 27.581 12.8934L23.4557 16.6601Z"
                fill="currentColor"
            />
        </svg>
    )
}
