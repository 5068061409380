import { observer } from "mobx-react"
import { useEffect, useMemo } from "react"
import { useNavigate, useParams } from "react-router"
import { t } from "@lingui/macro"

import { ListPage } from "src/components/ListPage"
import { Tabs } from "src/components/Tabs"
import { useStore } from "src/store/lib/useStore"
import { SelfRegistrationStore } from "src/views/self-registration/store"
import { GlobalStore } from "src/store"
import { SelfRegistrationRequestTab } from "src/views/self-registration/views/request"
import { SelfRegistrationLegalEntityTab } from "src/views/self-registration/views/legalEntity"
import { SelfRegistrationPropertyTab } from "src/views/self-registration/views/property"
import { StoreProvider } from "src/store/lib/StoreProvider"

const View = observer(() => {
    const store = useStore(SelfRegistrationStore)
    const params = useParams()
    const navigate = useNavigate()
    const globalStore = useStore(GlobalStore)

    const handleTabChange = (event: React.SyntheticEvent, id: string) => {
        store.setSelectedTabId(id)
        navigate(`/self-registration/${id}`)
    }

    const tabsData = useMemo(() => {
        let tabs = []

        if (globalStore.session.hasAccessToModule("lobby")) {
            tabs.push({
                label: t`self-registration-view.request`,
                id: "request",
                view: <SelfRegistrationRequestTab />,
            })
        }

        if (
            globalStore.session.hasAccessToModule("self_registration_leaflet")
        ) {
            tabs.push(
                {
                    label: t`self-registration-view.legal-entity`,
                    id: "legal-entity",
                    view: <SelfRegistrationLegalEntityTab />,
                },
                {
                    label: t`self-registration-view.property`,
                    id: "property",
                    view: <SelfRegistrationPropertyTab />,
                },
            )
        }

        return tabs
    }, [globalStore.session])

    // Auto select initial tab
    useEffect(() => {
        if (tabsData.length > 0) {
            if (params.tab == null) {
                store.setSelectedTabId(tabsData[0].id)
            } else {
                const paramsTabExistInTabData = tabsData.some(
                    (value) => value.id === params.tab,
                )

                if (paramsTabExistInTabData) {
                    store.setSelectedTabId(params.tab as string)
                }
            }
        }
    }, [store, tabsData, params.tab])

    // Redirect if path don't exist as tab
    useEffect(() => {
        if (tabsData.length > 0 && params.tab != null) {
            const paramsTabExistInTabData = tabsData.some(
                (value) => value.id === params.tab,
            )

            if (!paramsTabExistInTabData) {
                navigate("/self-registration/")
            }
        }
    }, [params.tab, tabsData, navigate])

    return (
        <ListPage
            header={{
                header: t`self-registration-view.title`,
                breadcrumbs: [t`self-registration-view.title`],
            }}
        >
            {tabsData.length > 0 && store.selectedTabId !== "" && (
                <Tabs
                    data={tabsData}
                    selectedTabId={store.selectedTabId}
                    handleTabChange={handleTabChange}
                />
            )}
        </ListPage>
    )
})

export const SelfRegistrationView = () => (
    <StoreProvider Store={SelfRegistrationStore}>
        <View />
    </StoreProvider>
)
