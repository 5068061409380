import { observer } from "mobx-react"
import {
    Box,
    useTheme,
    Typography,
    IconButton,
    FormHelperText,
} from "@mui/material"
import { useCallback } from "react"
import { t } from "@lingui/macro"

import { EditProductStore } from "src/views/product-catalogue/edit/store"
import { useStore } from "src/store/lib/useStore"
import { Alert16 } from "src/components/icons/Alert16"
import { Icon } from "src/components/icons"
import { Tooltip } from "src/components/Tooltips/Tooltip"
import { ImagePreview } from "src/components/AttachmentsField/ImageAttachments/ImagePreview"
import { ImagePreviewHoverOptions } from "src/components/AttachmentsField/ImageAttachments/ImagePreviewHoverOptions"
import { UploadImageField } from "src/components/AttachmentsField/ImageAttachments/UploadImageField"
import { Bin24 } from "src/components/icons/Bin24"
import { View24 } from "src/components/icons/View24"

export const ProductImage = observer(() => {
    const store = useStore(EditProductStore)
    const theme = useTheme()

    const handleImageChange = useCallback(
        (images: IFile[]) => {
            store.setMainImage(images[0])
            store.formFields.set("mainImageUrl", images[0].url)
        },
        [store],
    )

    const handleImageDelete = useCallback(() => {
        store.setMainImage({})
        store.formFields.set("mainImageUrl", undefined)
    }, [store])

    return (
        <div>
            <Box
                sx={{ display: "flex", gap: "8px", marginBottom: "16px" }}
                style={{ breakInside: "avoid" }}
            >
                <Typography component="h3" fontSize="14px" fontWeight="700">
                    {t`edit-product-view.product-image.label`}
                </Typography>

                <Tooltip
                    title={t`edit-product-view.product-image.tooltip`}
                    arrow
                >
                    <Typography sx={{ lineHeight: 0 }}>
                        <Icon
                            icon={<Alert16 width="20px" height="20px" />}
                            color={theme.palette.grey[700]}
                        />
                    </Typography>
                </Tooltip>
            </Box>
            {store.formFields.get("mainImageUrl") == null ? (
                <UploadImageField
                    onChange={handleImageChange}
                    aspectRatio="16/9"
                />
            ) : (
                <div
                    style={{
                        borderWidth: "1px",
                        borderStyle: "dashed",
                        borderColor: theme.palette.grey[500],
                        borderRadius: theme.shape.borderRadius,
                    }}
                >
                    <ImagePreview
                        url={store.formFields.get("mainImageUrl") ?? ""}
                        aspectRatio="16/9"
                    >
                        <ImagePreviewHoverOptions>
                            <IconButton
                                href={
                                    store.formFields.get("mainImageUrl") ?? ""
                                }
                                target="_blank"
                                color="inherit"
                                title={t`attachments-field-component.image-attachments.preview-title`}
                            >
                                <View24 />
                            </IconButton>
                            <IconButton
                                onClick={handleImageDelete}
                                color="inherit"
                                title={t`attachments-field-component.image-attachments.remove-button`}
                            >
                                <Bin24 />
                            </IconButton>
                        </ImagePreviewHoverOptions>
                    </ImagePreview>
                </div>
            )}
            {Boolean(store.formFields.error("mainImageUrl")) && (
                <FormHelperText error={true}>
                    {store.formFields.error("mainImageUrl")}
                </FormHelperText>
            )}
        </div>
    )
})
