import { StatusCasesV2 } from "src/types/status-casesV2"

export const parseStatusCasesV2 = (status: string): StatusCasesV2 => {
    switch (status) {
        case StatusCasesV2.Created:
        case StatusCasesV2.InProgress:
        case StatusCasesV2.Done:
        case StatusCasesV2.ExternallyHandled:
            return status
        default:
            return StatusCasesV2.Unknown
    }
}
