export const removeLastIdSegment = (path: string): string => {
    const segments = path.split("/")

    // Get the last segment
    const lastSegment = segments[segments.length - 1]

    // Convert the last segment to a number
    const lastSegmentAsNumber = Number(lastSegment)

    // Check if the conversion results in a valid number
    if (!isNaN(lastSegmentAsNumber) && lastSegment.trim() !== "") {
        // Remove the last segment if it's a number
        segments.pop()
    }

    // Join the remaining segments back into a path
    return segments.join("/")
}
