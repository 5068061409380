import { t } from "@lingui/macro"
import { Grid } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useEffect, useMemo } from "react"

import { FeaturesStore } from "./store"

import { FeatureListItem } from "./featureListItem"

import { ToggleButtons, ToggleChipGroup } from "./styled"

import { ListPage } from "src/components/ListPage"
import { useStore } from "src/store/lib/useStore"

import { StoreProvider } from "src/store/lib/StoreProvider"
import { GlobalStore } from "src/store"
import { CenteredSpinner } from "src/components/CenteredSpinner"

const View = observer(() => {
    const store = useStore(FeaturesStore)
    const gstore = useStore(GlobalStore)
    const initLoading = gstore.loading.is(FeaturesStore.LoadingKeys.init)
    const submitLoading = gstore.loading.is(FeaturesStore.LoadingKeys.submit)
    const header = useMemo(
        () => ({
            header: t`features-view.header`,
            breadcrumbs: [t`feature-toggles-view.header`],
        }),
        [],
    )

    useEffect(() => {
        ;(async () => {
            await store.init(gstore.session.accessGroupId)
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gstore.session.accessGroupId])

    const handleChange = useCallback(
        (_: unknown, value: ICategorySearchNode | null) => {
            if (value !== null) {
                store.setCategory(value)
            }
        },
        [store],
    )

    const categoryTranslations = useMemo(
        (): { [key in ICategorySearchNode]: string } => ({
            allFeatures: t`features-category.all-features`,
            general: t`features-category.general`,
            contactChannels: t`features-category.contact-channels`,
            payment: t`features-category.payment`,
            communication: t`features-category.communication`,
            commerce: t`features-category.commerce`,
            integrations: t`features-category.integrations`,
            bookingAndAccess: t`features-category.booking-and-access`,
            coreViews: t`features-category.core-views`,
            services: t`features-category.services`,
            other: t`features-category.other`,
            notInUse: t`features-category.notInUse`,
        }),
        [],
    )

    return (
        <ListPage header={header}>
            <ToggleChipGroup
                value={store.selectedCategory}
                onChange={handleChange}
                exclusive
                orientation="horizontal"
            >
                {store.categories.map((type) => (
                    <ToggleButtons key={type} value={type}>
                        {categoryTranslations[type]}
                    </ToggleButtons>
                ))}
            </ToggleChipGroup>
            {initLoading || submitLoading ? (
                <CenteredSpinner height="100vh" />
            ) : (
                <Grid container rowSpacing={2} direction="row">
                    {store.featuresFlagList.map((flag) => (
                        <Grid
                            key={flag.name}
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={4}
                            xl={3}
                        >
                            <FeatureListItem
                                id={flag.id}
                                key={flag.name}
                                apiName={flag.name}
                                header={flag.displayName}
                                tag={flag.tag}
                                description={flag.description}
                                segmentGroups={flag.segment_groups ?? []}
                            />
                        </Grid>
                    ))}
                </Grid>
            )}
        </ListPage>
    )
})

export const FeaturesView = observer(() => (
    <StoreProvider Store={FeaturesStore}>
        <View />
    </StoreProvider>
))
