import { t } from "@lingui/macro"
import {
    Button,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { Cross16 } from "src/components/icons/Cross16"
import { Search24 } from "src/components/icons/Search24"
import { SegmentsEmptyMessage } from "src/components/SegmentPicker/components/SegmentsEmptyMessage"
import {
    List,
    TablePaginationWithUncontrolledPageSize,
} from "src/components/SegmentPicker/components/SegmentPickerSelected/styled"
import { SegmentPickerStore } from "src/components/SegmentPicker/store"
import { useStore } from "src/store/lib/useStore"
import { ListItem } from "src/components/SegmentPicker/components/SegmentPickerSelectListItem"

export const SegmentPickerSelected = observer(
    (props: {
        onSave: () => void
        onClose: () => void
        initialSegmentIds: number[]
    }) => {
        const store = useStore(SegmentPickerStore)
        const theme = useTheme()
        const smallScreen = useMediaQuery(theme.breakpoints.down("md"))

        const initialized = store.selectedSegments.meta.initialized

        const handleClearAllSegments = useCallback(
            (ids: number[]) => {
                store.setInitialSegmentIds(ids ?? [])
            },
            [store],
        )

        return (
            <Stack
                sx={{ height: "100%" }}
                data-testid="SegmentPickerModal/Selected"
            >
                <Stack spacing={2}>
                    {!smallScreen && (
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            <Typography
                                component="h2"
                                fontSize="1.25rem"
                                lineHeight={2}
                                fontWeight={500}
                            >{t`segment-picker.selected.header`}</Typography>
                            <Stack direction="row" spacing={1}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => props.onClose()}
                                >
                                    {t`segment-picker.selected.close`}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => props.onSave()}
                                >
                                    {t`segment-picker.selected.save`}
                                </Button>
                            </Stack>
                        </Stack>
                    )}
                    <TextField
                        value={store.selectedSegments.meta.search ?? ""}
                        onChange={async (event) =>
                            await store.selectedSegments.loadSearch(
                                event.target.value,
                            )
                        }
                        placeholder={t`segment-picker.selected.search-placeholder`}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    sx={{ color: theme.palette.grey[900] }}
                                >
                                    <Search24 />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                    sx={{ color: theme.palette.grey[900] }}
                                >
                                    <IconButton
                                        aria-label={t`segment-picker.selected.clear-search`}
                                        onClick={() =>
                                            store.selectedSegments.loadSearch(
                                                "",
                                            )
                                        }
                                    >
                                        <Cross16 />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        size="small"
                    />
                    {store.selectedSegments.meta.count != null && (
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            <Typography
                                fontSize={12}
                                fontWeight={400}
                                color={theme.palette.grey[700]}
                                lineHeight={3}
                            >
                                {t({
                                    id: "segment-picker.selected.segment-count-selected",
                                    values: {
                                        count: store.selectedSegments.meta
                                            .count,
                                    },
                                })}
                            </Typography>
                            <Stack direction="row" spacing={1}>
                                <Button
                                    variant="text"
                                    color="info"
                                    onClick={() =>
                                        handleClearAllSegments(
                                            store.segmentIdsNoRelatedAccessGroup,
                                        )
                                    }
                                    disabled={
                                        store.selectedSegments.meta.count === 0
                                    }
                                >
                                    {t`segment-picker.available.deselect-all-segments`}
                                </Button>
                            </Stack>
                        </Stack>
                    )}
                </Stack>
                {initialized && store.selectedSegments.items.length === 0 && (
                    <SegmentsEmptyMessage
                        header={t`segment-picker.selected.no-segments-selected-header`} // "No segments selected."
                        message={t`segment-picker.selected.no-segments-selected-message`} // "Add segments by selecting them in the list in the left panel."
                    />
                )}

                {initialized && store.selectedSegments.items.length > 0 && (
                    <>
                        <List>
                            {store.selectedSegments.items.map((segment) => (
                                <ListItem key={segment.id} segment={segment} />
                            ))}
                        </List>
                        <TablePaginationWithUncontrolledPageSize
                            component="div"
                            count={store.selectedSegments.meta.count ?? -1}
                            page={store.selectedSegments.meta.page}
                            onPageChange={(_, page) =>
                                store.selectedSegments.loadPage(page)
                            }
                            rowsPerPage={store.selectedSegments.meta.pageSize}
                            labelRowsPerPage={t`table-pagination-component.rows-per-page`}
                            labelDisplayedRows={(info) =>
                                t({
                                    id: "table-pagination-component.displayed-rows",
                                    values: {
                                        from: info.from,
                                        count: info.count,
                                        to: info.to,
                                        page: info.page,
                                    },
                                })
                            }
                        />
                    </>
                )}
            </Stack>
        )
    },
)
