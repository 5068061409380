import { IAntIconProps } from "src/layouts/DefaultLayout/Navigation/icons/props"

export const AntIconProductCatalogue = (props: IAntIconProps) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M21.9 7.89L20.85 4.52C20.63 3.62 19.85 3 18.94 3H5.05001C4.15001 3 3.36001 3.63 3.15001 4.52L2.10001 7.89C1.64001 9.86 2.95001 11 3.00001 11.06V19C3.00001 20.1 3.90001 21 5.00001 21H19C20.1 21 21 20.1 21 19V11.06C22.12 9.94 22.09 8.65 21.9 7.89ZM13 5H14.96L15.5 8.52C15.59 9.23 15.11 10 14.22 10C13.55 10 13 9.41 13 8.69V5ZM6.44001 8.86C6.36001 9.51 5.84001 10 5.23001 10C4.30001 10 3.88001 9.03 4.04001 8.36L5.05001 5H7.02001L6.44001 8.86ZM11 8.69C11 9.41 10.45 10 9.71001 10C8.96001 10 8.41001 9.3 8.49001 8.52L9.04001 5H11V8.69ZM18.77 10C18.16 10 17.63 9.51 17.56 8.86L16.98 5L18.91 4.99L19.96 8.36C20.12 9.03 19.71 10 18.77 10Z"
            fill="currentColor"
        />
    </svg>
)
