import { makeAutoObservable } from "mobx"
import React from "react"

import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"
import { ProductCatalogueAdminService } from "src/api"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { reportUnhandledApiError } from "src/lib/report"

export class ProductCatalogueStore {
    static Context = React.createContext<ProductCatalogueStore | null>(null)
    static LoadingKeys = createLoadingKeys("pageLoad")

    products = new Pagination(
        async (query) => {
            const response =
                await ProductCatalogueAdminService.getV1AdminCatalogueProduct({
                    pageNumber: query.page,
                    pageSize: query.pageSize,
                    accessGroupId: this.getAccountGroupId(),
                })

            const items =
                response.products?.map((product) => ({
                    id: product?.product_id ?? 0,
                    adminName: product.admin_name ?? "",
                    category: product.category ?? "",
                    editable: product.editable ?? false,
                    mainImageUrl: product.main_image_url ?? "",
                    productId: product.product_id ?? 0,
                    productState: product.product_state ?? "",
                    productType: product.product_type ?? "",
                    provider: product.provider ?? "",
                    publicProductId: product.public_product_id ?? "",
                    published: product.published ?? [],
                })) ?? []

            return {
                items: items,
                count: response.total_count ?? 0,
            }
        },
        { loadingKey: ProductCatalogueStore.LoadingKeys.pageLoad },
    )

    private _segments: number[] = []
    private _accessGroupId: number = DEFAULT_ACCESS_GROUP.id

    get segments() {
        return this._segments
    }

    constructor() {
        makeAutoObservable(this)
    }

    async loadSegments(segments: number[]) {
        this.setSegments(segments)
        await this.products.loadInitialPage()
    }

    async init(accessGroupId: number, segments: number[]) {
        this.setAccountGroupId(accessGroupId)
        await this.loadSegments(segments)
    }

    async duplicateProduct(id: number) {
        try {
            await ProductCatalogueAdminService.postV1AdminCatalogueProductClone(
                {
                    productId: id,
                },
            )
        } catch (e) {
            reportUnhandledApiError(e)
        } finally {
            await this.products.reload()
        }
    }

    async deleteProduct(id: number) {
        try {
            await ProductCatalogueAdminService.deleteV1AdminCatalogueProduct({
                productId: id,
            })
        } catch (e) {
            reportUnhandledApiError(e)
        } finally {
            await this.products.reload()
        }
    }

    private getAccountGroupId() {
        return this._accessGroupId !== DEFAULT_ACCESS_GROUP.id
            ? this._accessGroupId
            : undefined
    }

    private setAccountGroupId(accessGroupId: number) {
        this._accessGroupId = accessGroupId
    }

    private setSegments(segments: number[]) {
        this._segments = segments
    }
}
