import { alpha, styled, ToggleButtonGroup } from "@mui/material"

export const ToggleChipGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    flexWrap: "wrap",
    marginBottom: "-8px",
    "& .MuiToggleButtonGroup-grouped": {
        margin: "0 8px 8px",
        background: theme.palette.grey[300],
        border: "none",
        color: theme.palette.grey[900],
        padding: "0 12px",
        height: "32px",
        textTransform: "none",
        fontSize: "12px",
        "&:disabled:not(.Mui-selected)": {
            border: "none",
            background: alpha(theme.palette.common.black, 0.08),
            opacity: 0.38,
        },
        "&.Mui-selected, &.Mui-selected:hover": {
            background: theme.palette.grey[900],
            color: theme.palette.common.white,
        },
        "&:not(:first-of-type), &:first-of-type": {
            borderRadius: "1000px",
        },
        "&:first-of-type": {
            marginLeft: 0,
        },
        "&:last-of-type": {
            marginRight: 0,
        },
        "& svg": {
            marginRight: "4px",
        },
    },
}))
