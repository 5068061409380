/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"

import { useStore } from "./store/lib/useStore"

import { GlobalStore } from "./store"

import {
    resetTimeout,
    trackUserSession,
} from "./analytics/helpers/mixpanel_tracking"

import { ModalFragment } from "src/fragments/modal"
import { ToastFragment } from "src/fragments/toast"
import { Routes } from "src/routes"

export const App = () => {
    const globalStore = useStore(GlobalStore)

    const events = [
        "load",
        "mousemove",
        "mousedown",
        "click",
        "scroll",
        "keypress",
        "keydown",
        "keyup",
    ]

    for (let i in events) {
        document.addEventListener(events[i], () => resetTimeout())
    }

    /**
     * Sets the navigation data for tracking in session storage.
     * This data is not meant to be user specific, hence all menu items are used.
     * This data is never getting unset when user logs out
     */
    useEffect(() => {
        globalStore.session.setNavigationDataForTracking(
            globalStore.navigation.allMenuItems,
        )
    }, [])

    useEffect(() => {
        if (globalStore.session.user !== null) {
            trackUserSession(globalStore.session.user.adminId)
        }
    }, [globalStore.session.user])

    return (
        <>
            <Routes />
            <ModalFragment />
            <ToastFragment />
        </>
    )
}
