import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useEffect, useMemo } from "react"

import { ListPage } from "src/components/ListPage"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { ChatbotInsightsStore } from "src/views/chatbot-insights/store"

const View = observer(() => {
    const store = useStore(ChatbotInsightsStore)
    const globalStore = useStore(GlobalStore)

    useEffect(() => {
        ;(async () => {
            await store.init()
        })()
    }, [store])

    const header = useMemo(
        () => ({
            header: t`chatbot-insights-view.header`,
            breadcrumbs: [
                t`navigation.chatbot`,
                t`chatbot-insights-view.header`,
            ],
        }),
        [],
    )

    return (
        <ListPage
            header={header}
            loading={globalStore.loading.is(
                ChatbotInsightsStore.LoadingKeys.init,
            )}
        >
            {store.link != null && (
                <iframe
                    title="Chatbot Insights Dashboard"
                    width="100%"
                    height="1084px"
                    src={store.link}
                    style={{
                        border: 0,
                    }}
                />
            )}
        </ListPage>
    )
})

export const ChatbotInsightsView = () => (
    <StoreProvider Store={ChatbotInsightsStore}>
        <View />
    </StoreProvider>
)
