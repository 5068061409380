import { t } from "@lingui/macro"
import { Stack, TextField } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { CommunityToggleSwtich } from "./CommunityToggleSwtich"

import { FormFieldSet } from "src/components/FormFieldSet"
import { SegmentPickerField } from "src/components/SegmentPickerField"
import { CommunityDetailStore } from "src/modals/community-detail/store"
import { useStore } from "src/store/lib/useStore"

export const SettingsSection = observer(() => {
    const vstore = useStore(CommunityDetailStore)

    const handleSegmentsChange = useCallback(
        (segmentIds: number[]) => {
            vstore.fields.set("segmentIds", segmentIds)
        },
        [vstore.fields],
    )

    return (
        <>
            <FormFieldSet
                header={t`community-detail-modal.community-name-header`}
            >
                <Stack spacing={2}>
                    <TextField
                        label={t`community-detail-modal.internal-name-label`}
                        onChange={vstore.fields.setter("internalName")}
                        value={vstore.fields.get("internalName")}
                        error={Boolean(vstore.fields.error("internalName"))}
                        helperText={vstore.fields.error("internalName")}
                        disabled={vstore.fields.get("accessType") === "READ"}
                    />
                    <TextField
                        label={t`community-detail-modal.external-name-label`}
                        onChange={vstore.fields.setter("name")}
                        value={vstore.fields.get("name")}
                        error={Boolean(vstore.fields.error("name"))}
                        helperText={vstore.fields.error("name")}
                        disabled={vstore.fields.get("accessType") === "READ"}
                    />
                </Stack>
            </FormFieldSet>
            <FormFieldSet header={t`community-detail-modal.segments-header`}>
                <SegmentPickerField
                    value={vstore.fields.get("segmentIds")}
                    accessGroupId={vstore.fields.get("accessGroupId")}
                    onChange={handleSegmentsChange}
                    error={Boolean(vstore.fields.error("segmentIds"))}
                    helperText={vstore.fields.error("segmentIds")}
                    disabled={vstore.fields.get("accessType") === "READ"}
                />
            </FormFieldSet>
            <FormFieldSet header={t`community-detail-modal.email-header`}>
                <TextField
                    label={t`community-detail-modal.email-label`}
                    onChange={vstore.fields.setter("email")}
                    value={vstore.fields.get("email")}
                    error={Boolean(vstore.fields.error("email"))}
                    helperText={vstore.fields.error("email")}
                    disabled={vstore.fields.get("accessType") === "READ"}
                />
            </FormFieldSet>
            <FormFieldSet
                header={t`community-detail-modal.toggle-notification-label`}
            >
                <CommunityToggleSwtich />
            </FormFieldSet>
        </>
    )
})
