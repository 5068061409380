import { t } from "@lingui/macro"
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
} from "@mui/material"
import { useCallback } from "react"

import styled from "@emotion/styled"

import { observer } from "mobx-react"

import { useStore } from "src/store/lib/useStore"
import { useUniqueId } from "src/lib/unique-id"
import { ViewCommunityPostStore } from "src/modals/view-community-post/store"
import { GlobalStore } from "src/store"
import { Avatar } from "src/components/Avatar"

const Author = styled("span")({})

export const AuthorSelect = observer(() => {
    const gstore = useStore(GlobalStore)
    const store = useStore(ViewCommunityPostStore)

    const authorSelectId = useUniqueId()

    const isSubmitting = gstore.loading.is(
        ViewCommunityPostStore.LoadingKeys["submit-comment"],
    )

    const handleAuthorChange = useCallback(
        (event: SelectChangeEvent<string | null>) => {
            store.commentForm.set("authorId", event.target.value)
            event.target.value != null &&
                store.setAuthorIdToLocalStore(event.target.value)
        },
        [store],
    )

    return (
        <FormControl sx={{ flex: 1 }}>
            <InputLabel id={authorSelectId}>
                {t`view-community-post-modal.settings-section.author-label`}
            </InputLabel>
            <Select
                labelId={authorSelectId}
                label={t`view-community-post-modal.settings-section.author-label`}
                value={store.commentForm.get("authorId")}
                onChange={handleAuthorChange}
                error={Boolean(store.commentForm.error("authorId"))}
                disabled={isSubmitting || store.post.accessType === "READ"}
                size="small"
                fullWidth
            >
                {store.authors.map((author) => (
                    <MenuItem key={author.id} value={author.id}>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Avatar size={24} name={author.name} />
                            <Author>{author.name}</Author>
                        </Stack>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
})
