import { t } from "@lingui/macro"
import { alpha, Box, Grid, IconButton, styled } from "@mui/material"
import { observer } from "mobx-react"
import { useMemo } from "react"

import { View24 } from "src/components/icons/View24"
import { range } from "src/lib/array"
import { isVideoByUrl } from "src/lib/video"

const EmptySlot = styled(Box)(({ theme }) => ({
    borderWidth: "1px",
    borderStyle: "dashed",
    borderColor: theme.palette.grey[500],
    borderRadius: "4px",
    width: "100%",
    height: "124px",
}))

const Slot = styled(Box)(({ theme }) => ({
    position: "relative",
    overflow: "hidden",
    borderRadius: "4px",
    width: "100%",
    height: "124px",
    background: theme.palette.common.white,
}))

const Img = styled("img")({
    width: "100%",
    height: "100%",
    objectFit: "contain",
})

const Video = styled("video")({
    width: "100%",
    height: "100%",
    objectFit: "contain",
})

const ImagePreviewHoverOptions = styled("div")(({ theme }) => ({
    background: alpha(theme.palette.grey[900], 0.7),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: 0,
    position: "absolute",
    inset: 0,
    zIndex: 1,
    color: theme.palette.common.white,
    "&:hover": {
        opacity: 1,
    },
}))

interface IProps {
    attachments: string[]
    maxAttachments: number
}

export const StaticAttachments = observer((props: IProps) => {
    const urls = useMemo(() => {
        if (props.attachments.length > props.maxAttachments) {
            return props.attachments.slice(0, props.maxAttachments)
        } else {
            return [
                ...props.attachments,
                ...range(props.maxAttachments - props.attachments.length).map(
                    () => null,
                ),
            ]
        }
    }, [props.attachments, props.maxAttachments])

    return (
        <Grid spacing={2} container>
            {urls.map((url, i) => (
                <Grid key={`${url}-${i}`} item xs={12} sm={4}>
                    {url != null ? (
                        <Slot>
                            <ImagePreviewHoverOptions>
                                <IconButton
                                    href={url}
                                    target="_blank"
                                    color="inherit"
                                    title={t`contact-form-detail-modal.errand-section.preview-title`}
                                >
                                    <View24 />
                                </IconButton>
                            </ImagePreviewHoverOptions>
                            {isVideoByUrl(url) ? (
                                <Video src={url} />
                            ) : (
                                <Img src={url} />
                            )}
                        </Slot>
                    ) : (
                        <EmptySlot />
                    )}
                </Grid>
            ))}
        </Grid>
    )
})
