/* eslint-disable import/no-relative-parent-imports */
import { t } from "@lingui/macro"
import { observer } from "mobx-react"

import { IntegrationConnectorItemStore } from "../store"

import { FormFieldSet } from "src/components/FormFieldSet"
import { SegmentPickerField } from "src/components/SegmentPickerField"
import { useStore } from "src/store/lib/useStore"

export const PublishingSettingsSection = observer(() => {
    const vstore = useStore(IntegrationConnectorItemStore)

    return (
        <FormFieldSet
            header={t`integration-connector-modal.publishing-settings-section.field-set-header`}
        >
            <SegmentPickerField
                value={vstore.formFields.get("segmentIds") as number[]}
                accessGroupId={vstore.formFields.get("accessGroupId")}
                onChange={(segmentIds) =>
                    vstore.formFields.set("segmentIds", segmentIds)
                }
                disabled={vstore.formFields.get("accessType") === "READ"}
            />
        </FormFieldSet>
    )
})
