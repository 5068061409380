import { t } from "@lingui/macro"
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Box,
    FormHelperText,
    useTheme,
} from "@mui/material"
import { observer } from "mobx-react"
import Typography from "@mui/material/Typography"

import { ITwoFaAuthenticationProps } from "./types/twoFaAuthenticationProps"

import { DialogContainer, BoxContainer } from "./styled"

import { QRCodeComponent } from "src/components/QRCode"
import { OTP } from "src/components/Inputs"
import { CenteredSpinner } from "src/components/CenteredSpinner"

export const TwoFaAuthentication = observer(
    (props: ITwoFaAuthenticationProps) => {
        const {
            mfaConfigured,
            auth2faUri,
            otp,
            setOtp,
            onVerify2faOtp,
            error,
            isLoader,
            onCloseModal,
        } = props

        const theme = useTheme()

        return (
            <Dialog
                maxWidth="xs"
                open={true}
                data-testid="two-fa-authentication"
            >
                <DialogTitle>{t`two-fa-authentication.title`}</DialogTitle>
                <DialogContainer>
                    {!mfaConfigured ? (
                        <>
                            <BoxContainer>
                                <Typography variant="h6">
                                    {t`two-fa-authentication.download-authenticator-app`}
                                </Typography>
                                <Typography>
                                    {t`two-fa-authentication.download-and-install`}
                                </Typography>
                            </BoxContainer>
                            <BoxContainer>
                                <Typography variant="h6">
                                    {t`two-fa-authentication.scan-the-qr-code`}
                                </Typography>
                                <Typography>
                                    {t`two-fa-authentication.open-the-authenticator`}
                                </Typography>
                                <Box textAlign={"center"} pt={2.5} pb={1}>
                                    {auth2faUri.length === 0 ? (
                                        <CenteredSpinner height="200px" />
                                    ) : (
                                        <QRCodeComponent value={auth2faUri} />
                                    )}
                                </Box>
                            </BoxContainer>
                        </>
                    ) : null}
                    <BoxContainer>
                        {!mfaConfigured && auth2faUri.length > 0 ? (
                            <Typography variant="h6">
                                {t`two-fa-authentication.enter-your-code`}
                            </Typography>
                        ) : null}
                        <Typography>
                            {t`two-fa-authentication.enter-the-6-digits`}
                        </Typography>
                    </BoxContainer>
                    <BoxContainer>
                        <OTP
                            length={6}
                            value={otp}
                            onChange={setOtp}
                            isError={Boolean(error)}
                            disabled={isLoader}
                        />
                        {error.length > 0 && (
                            <FormHelperText
                                style={{ color: theme.palette.error.main }}
                            >
                                {error}
                            </FormHelperText>
                        )}
                    </BoxContainer>
                </DialogContainer>
                <DialogActions sx={{ p: 1 }}>
                    <Button color="primary" onClick={onCloseModal}>
                        {t`two-fa-authentication.cancel`}
                    </Button>
                    <Button
                        variant="contained"
                        color="info"
                        disabled={isLoader}
                        onClick={onVerify2faOtp}
                    >
                        {t`two-fa-authentication.submit`}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    },
)

export const TwoFaAuthenticationModal = (props: ITwoFaAuthenticationProps) => (
    <TwoFaAuthentication {...props} />
)
