import React from "react"
import { makeAutoObservable } from "mobx"
import camelcaseKeys from "camelcase-keys"
import { format } from "date-fns"

import { Pagination } from "src/lib/pagination"
import { domain_GetInvoicesRequest, InvoiceAdminService } from "src/api"
import { parseDate } from "src/lib/date"
import { autoDownloadFile } from "src/lib/file"
import { Channel } from "src/channel"
import { CustomInvoiceAdminService } from "src/api/_custom/services/InvoiceAdminService"
import { createLoadingKeys } from "src/lib/loading"
import { loads } from "src/channel/utils"

interface IDateRangeFilter {
    from: Date | null
    to: Date | null
}

export class InvoicesStore {
    static Context = React.createContext<InvoicesStore | null>(null)
    static LoadingKeys = createLoadingKeys("export")

    segments: number[] = []
    statusFilter: string[] = []
    dateRangeFilter: IDateRangeFilter = {
        from: null,
        to: null,
    }

    private listenToRepositoryChangesDisposer?: () => void

    constructor() {
        makeAutoObservable(this)
    }

    dispose() {
        this.listenToRepositoryChangesDisposer?.()
    }

    async init() {
        await this.invoices.loadInitialPage()
        this.listenToRepositoryChange()
    }

    invoices = new Pagination(async (query) => {
        const response = await InvoiceAdminService.postV1AdminInvoice({
            request: {
                page_number: query.page,
                page_size: query.pageSize,
                search_term: query.search ?? undefined,
                segment_ids: this.segments,
                statuses: this.statusFilter,
                date_range: this.dateRangeQuery(),
            },
        })

        const items =
            (response.result ?? []).map((invoice) => ({
                ...camelcaseKeys(invoice),
                id: invoice.invoice_id as number,
                dueDate:
                    invoice.due_date != null
                        ? parseDate(invoice.due_date)
                        : undefined,
                invoiceDate:
                    invoice.invoice_date != null
                        ? parseDate(invoice.invoice_date)
                        : undefined,
                selected: false,
                transactionDate:
                    // TODO: sync api client when these are available
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (invoice as any).transaction_date != null
                        ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          parseDate((invoice as any).transaction_date)
                        : undefined,
            })) ?? []

        return {
            items: items,
            count: response.total_count ?? 0,
        }
    })

    get canDownloadInvoiceCsv() {
        return (this.invoices.meta.count ?? 0) > 0
    }

    @loads(() => InvoicesStore.LoadingKeys.export)
    async export() {
        const meta = this.invoices.meta

        const blob = await CustomInvoiceAdminService.postV1AdminInvoiceCsvList({
            request: {
                search_term: meta.search ?? undefined,
                segment_ids: this.segments,
                statuses: this.statusFilter,
                date_range: this.dateRangeQuery(),
            },
        })

        const url = URL.createObjectURL(blob)

        autoDownloadFile(
            url,
            `AVY-TMPL-Invoices-${new Date().toDateString()}.csv`,
            "export-invoices-link",
        )

        // Keep the url alive for a minute just to be sure.
        setTimeout(() => {
            URL.revokeObjectURL(url)
        }, 60_000)
    }

    private setSegments(segments: number[]) {
        this.segments = segments
    }

    async loadSegments(segments: number[]) {
        this.setSegments(segments)
        await this.invoices.loadInitialPage()
    }

    async setStatusFilter(statusFilter: string[]) {
        this.statusFilter = statusFilter
        await this.invoices.loadInitialPage()
    }

    async setDateRangeFilter(value: IDateRangeFilter) {
        this.dateRangeFilter = value
        await this.invoices.loadInitialPage()
    }

    private dateRangeQuery(): domain_GetInvoicesRequest["date_range"] {
        const query: domain_GetInvoicesRequest["date_range"] = {}
        if (this.dateRangeFilter.from != null) {
            query.from = this.formatDateToDateRangeQuery(
                this.dateRangeFilter.from,
            )
        }
        if (this.dateRangeFilter.to != null) {
            query.to = this.formatDateToDateRangeQuery(this.dateRangeFilter.to)
        }

        return Object.keys(query).length > 0 ? query : undefined
    }

    private formatDateToDateRangeQuery(date: Date) {
        return format(date, "yyyyMM")
    }

    private listenToRepositoryChange() {
        this.listenToRepositoryChangesDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "invoices" &&
                    event.payload.action === "update"
                ) {
                    await this.invoices.reload()
                }
            },
        )
    }
}

export interface IInvoice {
    bankgiro?: string
    bankgiroMandate_id?: number
    bankgiroName?: string
    currency?: string
    dbCurrency?: string
    dueDate?: Date
    id: number
    invoiceDate?: Date
    invoiceId?: number
    invoiceNumber?: string
    isPaidLater?: boolean
    isPayable?: boolean
    legalEntityId?: number
    ocr?: string
    parentId?: number
    pdfUrl?: string
    status?: string
    tenantId?: number
    tenantName?: string
    totalAmount?: number
    transactionDate?: Date
    type?: string
}
