import { t } from "@lingui/macro"
import { Grid, IconButton } from "@mui/material"
import { useMemo } from "react"

import { View24 } from "src/components/icons/View24"

import { isVideoByUrl } from "src/lib/video"
import {
    EmptySlot,
    Slot,
    Img,
    Video,
    ImagePreviewHoverOptions,
} from "src/modals/cases-v2-detail/components/StaticAttachments/styled"

import { IStaticAttachmentsProps } from "src/modals/cases-v2-detail/types/staticAttachmentsProps"

export const StaticAttachments = ({
    attachments,
    maxAttachments,
}: IStaticAttachmentsProps) => {
    const urls = useMemo(() => {
        const additionalSlots = maxAttachments - attachments.length
        return additionalSlots > 0
            ? [...attachments, ...Array(additionalSlots).fill(null)]
            : attachments.slice(0, maxAttachments)
    }, [attachments, maxAttachments])

    return (
        <Grid container spacing={2}>
            {urls.map((url, i) => (
                <Grid key={`${url ?? "empty"}-${i}`} item xs={12} sm={4}>
                    {Boolean(url) ? (
                        <Slot>
                            <ImagePreviewHoverOptions>
                                <IconButton
                                    href={url}
                                    target="_blank"
                                    color="inherit"
                                    title={t`cases-detail-modal.preview-title`}
                                >
                                    <View24 />
                                </IconButton>
                            </ImagePreviewHoverOptions>
                            {isVideoByUrl(url) ? (
                                <Video src={url} />
                            ) : (
                                <Img src={url} />
                            )}
                        </Slot>
                    ) : (
                        <EmptySlot />
                    )}
                </Grid>
            ))}
        </Grid>
    )
}
