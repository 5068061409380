import { t } from "@lingui/macro"
import { Alert, Button } from "@mui/material"
import { observer } from "mobx-react"
import { useEffect, useState } from "react"

import { Form } from "src/components/Form"
import { ModalHeader } from "src/components/ModalHeader"
import { LibraryCategoriesOrderingSection } from "src/modals/library-categories/sections/Ordering"
import { LibraryCategoriesSettingsSection } from "src/modals/library-categories/sections/Settings"
import { LibraryCategoriesStore } from "src/modals/library-categories/store"
import { ILibraryCategoriesModalProps } from "src/modals/library-categories/types/libraryCategoriesProps"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"

const Modal = observer((props: ILibraryCategoriesModalProps) => {
    const store = useStore(LibraryCategoriesStore)
    const globalStore = useStore(GlobalStore)
    const [thisModal] = useState(
        globalStore.modals.active[globalStore.modals.active.length - 1],
    )
    thisModal.confirmOnOverlayClick = () => store.formFields.getIsDirty()

    const handleSubmit = async () => {
        store.setIsLoading(true)
        store.validateRequiredFields(["internalName", "nameInApp"])

        if (store.formFields.hasErrors()) {
            store.setIsLoading(false)
            return
        }

        if (props.category == null) {
            await store.createCategory()
        } else {
            await store.updateCategory(props.category)
        }

        globalStore.modals.pop()
    }

    useEffect(() => {
        store.init(props.category)
    }, [store, props.category])

    const isButtonDisabled =
        store.formFields.getIsDirty() === false || store.isLoading
    return (
        <Form onSubmit={handleSubmit}>
            <>
                <ModalHeader>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={isButtonDisabled}
                        data-testid="submit-item-form"
                    >
                        {t`global.save`}
                    </Button>
                </ModalHeader>
                {Boolean(store.formFields.error("generic")) && (
                    <Alert severity="error">
                        {store.formFields.error("generic")}
                    </Alert>
                )}
                <LibraryCategoriesSettingsSection />
                {globalStore.features.enabled("ShowNewLibrarySorting") &&
                    store.items.length > 0 && (
                        <LibraryCategoriesOrderingSection />
                    )}
            </>
        </Form>
    )
})

export const LibraryCategoriesModal = (props: ILibraryCategoriesModalProps) => (
    <StoreProvider Store={LibraryCategoriesStore}>
        <Modal {...props} />
    </StoreProvider>
)
