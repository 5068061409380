export const RightSideStatusBar = () => (
    <svg
        width="65"
        height="18"
        viewBox="0 0 65 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.3389 3.66675H15.3389C14.7866 3.66675 14.3389 4.11446 14.3389 4.66675V13.3334C14.3389 13.8857 14.7866 14.3334 15.3389 14.3334H16.3389C16.8912 14.3334 17.3389 13.8857 17.3389 13.3334V4.66675C17.3389 4.11446 16.8912 3.66675 16.3389 3.66675ZM10.6722 6.00008H11.6722C12.2245 6.00008 12.6722 6.4478 12.6722 7.00008V13.3334C12.6722 13.8857 12.2245 14.3334 11.6722 14.3334H10.6722C10.1199 14.3334 9.6722 13.8857 9.6722 13.3334V7.00008C9.6722 6.4478 10.1199 6.00008 10.6722 6.00008ZM7.00553 8.33341H6.00553C5.45325 8.33341 5.00553 8.78113 5.00553 9.33341V13.3334C5.00553 13.8857 5.45325 14.3334 6.00553 14.3334H7.00553C7.55782 14.3334 8.00553 13.8857 8.00553 13.3334V9.33341C8.00553 8.78113 7.55782 8.33341 7.00553 8.33341ZM2.33887 10.3334H1.33887C0.786582 10.3334 0.338867 10.7811 0.338867 11.3334V13.3334C0.338867 13.8857 0.786582 14.3334 1.33887 14.3334H2.33887C2.89115 14.3334 3.33887 13.8857 3.33887 13.3334V11.3334C3.33887 10.7811 2.89115 10.3334 2.33887 10.3334Z"
            fill="#333333"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.0059 5.78448C31.2307 5.78457 33.3703 6.63869 34.9827 8.17028C35.1041 8.28852 35.2982 8.28703 35.4177 8.16693L36.5784 6.9966C36.6389 6.93569 36.6727 6.85318 36.6722 6.76733C36.6717 6.68148 36.6369 6.59937 36.5757 6.53917C32.3438 2.48694 25.6674 2.48694 21.4355 6.53917C21.3742 6.59933 21.3394 6.68141 21.3389 6.76726C21.3383 6.85311 21.372 6.93565 21.4325 6.9966L22.5935 8.16693C22.713 8.28721 22.9072 8.28871 23.0285 8.17028C24.6411 6.63859 26.781 5.78447 29.0059 5.78448ZM29.0059 9.59206C30.2283 9.59199 31.407 10.0459 32.3131 10.8657C32.4356 10.9821 32.6287 10.9795 32.7481 10.86L33.9074 9.6897C33.9685 9.62831 34.0023 9.54504 34.0015 9.4585C34.0006 9.37196 33.965 9.28939 33.9027 9.22926C31.1436 6.66485 26.8707 6.66485 24.1115 9.22926C24.0492 9.28939 24.0136 9.37201 24.0128 9.45857C24.012 9.54513 24.046 9.6284 24.1071 9.6897L25.2661 10.86C25.3855 10.9795 25.5786 10.9821 25.7011 10.8657C26.6066 10.0465 27.7844 9.59257 29.0059 9.59206ZM31.3282 12.1539C31.3299 12.2407 31.2958 12.3243 31.2338 12.3851L29.2285 14.4071C29.1697 14.4666 29.0896 14.5 29.0059 14.5C28.9223 14.5 28.8422 14.4666 28.7834 14.4071L26.7778 12.3851C26.7158 12.3243 26.6817 12.2406 26.6835 12.1538C26.6854 12.067 26.723 11.9849 26.7875 11.9267C28.0681 10.8444 29.9438 10.8444 31.2244 11.9267C31.2888 11.9849 31.3264 12.0671 31.3282 12.1539Z"
            fill="#333333"
        />
        <rect
            opacity="0.35"
            x="41.1724"
            y="3.83325"
            width="21"
            height="10.3333"
            rx="2.16667"
            stroke="#333333"
        />
        <path
            opacity="0.4"
            d="M63.6724 7V11C64.4771 10.6612 65.0004 9.87313 65.0004 9C65.0004 8.12687 64.4771 7.33878 63.6724 7Z"
            fill="#333333"
        />
        <rect
            x="42.6724"
            y="5.33325"
            width="18"
            height="7.33333"
            rx="1.33333"
            fill="#333333"
        />
    </svg>
)
