export enum ESystemMessageType {
    Status = "status_changed",
    Date = "implementation_date_changed",
    Assignee = "handler_changed",
    ReporterEdit = "reporter_edited_case",
}

export type TSystemMessageType =
    | ESystemMessageType.Status
    | ESystemMessageType.Date
    | ESystemMessageType.Assignee
    | ESystemMessageType.ReporterEdit

export interface ISystemMessage {
    systemMessageId: string
    /* contains the type of the system message */
    systemMessageType: TSystemMessageType
    /* the content of the message */
    systemMessage: string
}

export interface ISystemMessageAPIResponse {
    system_message_type: ESystemMessageType
    parameters: { old_value: string; new_value: string }
}
