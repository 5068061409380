import { makeAutoObservable } from "mobx"
import React from "react"

import { CommunityAdminService } from "src/api"
import { Channel } from "src/channel"
import { loads } from "src/channel/utils"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { parseDate } from "src/lib/date"
import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"
import { reportUnhandledApiError } from "src/lib/report"
import { IAdvanceQueryModel } from "src/types/data-grid-pro"
import { ICommentItem } from "src/views/community-comments/type"

export class CommunityCommentsStore implements IDisposable {
    static Context = React.createContext<CommunityCommentsStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "loading", "delete-comment")

    private repositoryChangeListenerDisposer?: () => void

    private accessGroupId = DEFAULT_ACCESS_GROUP.id

    comments = new Pagination(
        async (params) => {
            const response =
                await CommunityAdminService.getV1AdminCommunityComments({
                    pageNumber: params.page,
                    pageSize: params.pageSize,
                    accessGroupId: this.getAccessGroupId(),
                    filter: JSON.stringify(params.advanceQuery),
                })

            const items: ICommentItem[] = (response.comments ?? []).map(
                (comment) => ({
                    id: comment.comment_id ?? -1,
                    post_id: comment.post_id ?? -1,
                    text: comment.text ?? "-",
                    "author.author_name": comment.author?.author_name ?? "-",
                    community_name: comment.community_name ?? "-",
                    likeCount: comment.likes?.length ?? 0,
                    created_date: parseDate(comment.created_date),
                    access_type: comment.access_type ?? "",
                    author_name: comment.author_name ?? "-",
                    internal_apartment_id: comment.internal_apartment_id ?? "-",
                    apartment_no: comment.apartment_no ?? "-",
                    updated_at: parseDate(comment.updated_at),
                }),
            )

            return {
                items,
                count: response.total_count ?? 0,
            }
        },
        { loadingKey: CommunityCommentsStore.LoadingKeys.loading },
    )

    constructor() {
        makeAutoObservable(this)
    }

    @loads(() => CommunityCommentsStore.LoadingKeys.init)
    async init(accessGroupId: number, advanceQuery?: IAdvanceQueryModel) {
        this.setAccessGroupId(accessGroupId)
        await this.comments.loadInitialPage(advanceQuery)
        this.listenToRepositoryChanges()
    }

    async query(advanceQuery: IAdvanceQueryModel) {
        await this.comments.loadAdvanceQuery(advanceQuery)
    }

    @loads(() => CommunityCommentsStore.LoadingKeys["delete-comment"])
    async deleteComment(id: number) {
        try {
            await CommunityAdminService.deleteV1AdminCommunityComment({
                commentId: id,
            })

            Channel.send({
                name: "repository/updated",
                payload: { repository: "community-comments", action: "delete" },
            })
        } catch (e) {
            reportUnhandledApiError(e)
        }
    }

    dispose() {
        this.repositoryChangeListenerDisposer?.()
    }

    private setAccessGroupId(accessGroupId: number) {
        this.accessGroupId = accessGroupId
    }

    private getAccessGroupId() {
        return this.accessGroupId !== DEFAULT_ACCESS_GROUP.id
            ? this.accessGroupId
            : undefined
    }

    private listenToRepositoryChanges() {
        this.repositoryChangeListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "community-comments"
                ) {
                    if (
                        event.payload.action === "update" ||
                        event.payload.action === "delete"
                    ) {
                        await this.comments.reload()
                    } else {
                        await this.comments.loadInitialPage()
                    }
                }
            },
        )
    }
}
