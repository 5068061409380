import { observer } from "mobx-react"
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    useTheme,
    Box,
    SelectChangeEvent,
    Typography,
    FormHelperText,
} from "@mui/material"
import { t } from "@lingui/macro"

import { EditProductStore } from "src/views/product-catalogue/edit/store"

import { useStore } from "src/store/lib/useStore"
import { Alert16 } from "src/components/icons/Alert16"
import { Icon } from "src/components/icons"
import { Tooltip } from "src/components/Tooltips/Tooltip"

export const Provider = observer(() => {
    const store = useStore(EditProductStore)
    const theme = useTheme()

    const handleChange = async (event: SelectChangeEvent<number | string>) => {
        store.formFields.set("providerId", Number(event.target.value))
        store.setOrderOutputs(
            await store.fetchOrderOutputs(Number(event.target.value)),
        )
    }

    return (
        <Box
            sx={{
                display: "flex",
                gap: "8px",
            }}
        >
            <FormControl style={{ breakInside: "avoid" }}>
                <InputLabel id="provider-label">{t`edit-product-view.provider.label`}</InputLabel>
                <Select
                    labelId="provider-label"
                    id="provider"
                    value={store.formFields.get("providerId") ?? ""}
                    label={t`edit-product-view.provider.label`}
                    onChange={handleChange}
                    error={Boolean(store.formFields.error("providerId"))}
                >
                    {store.providers.map((provider) => (
                        <MenuItem
                            value={provider.providerId}
                            key={provider.providerId}
                        >
                            {provider.name}
                        </MenuItem>
                    ))}
                </Select>
                {Boolean(store.formFields.error("providerId")) && (
                    <FormHelperText error={true}>
                        {store.formFields.error("providerId")}
                    </FormHelperText>
                )}
            </FormControl>
            <Tooltip title={t`edit-product-view.provider.tooltip`} arrow>
                <Typography sx={{ marginTop: "16px" }}>
                    <Icon
                        icon={<Alert16 width="20px" height="20px" />}
                        color={theme.palette.grey[700]}
                    />
                </Typography>
            </Tooltip>
        </Box>
    )
})
