import { t } from "@lingui/macro"
import { Stack, TextField } from "@mui/material"
import { observer } from "mobx-react"

import { AccessDetailStore } from "./store"

import { Type } from "./form-fields/Type"

import { FormFieldSet } from "src/components/FormFieldSet"
import { useStore } from "src/store/lib/useStore"

export const ResourceSection = observer(() => {
    const store = useStore(AccessDetailStore)

    const onNameTextChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const value = event.target.value.trim() !== "" ? event.target.value : ""
        store.formFields.set("name", value)
    }

    const onExternalIdTextChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const value = event.target.value.trim() !== "" ? event.target.value : ""
        store.formFields.set("externalId", value)
    }

    return (
        <FormFieldSet
            header={t`access-detail-modal.resource-section.field-set-header`}
        >
            <Stack spacing={2}>
                <TextField
                    label={t`access-detail-modal.resource-section.name-label`}
                    value={store.formFields.get("name")}
                    onChange={onNameTextChange}
                    helperText={store.formFields.error("name")}
                    error={Boolean(store.formFields.error("name"))}
                />
                <Type />
                <TextField
                    label={t`access-detail-modal.resource-section.external-id-label`}
                    value={store.formFields.get("externalId")}
                    onChange={onExternalIdTextChange}
                    helperText={store.formFields.error("externalId")}
                    error={Boolean(store.formFields.error("externalId"))}
                />
            </Stack>
        </FormFieldSet>
    )
})
