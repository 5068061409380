import { observer } from "mobx-react"
import React, { useCallback } from "react"

import { HighlightedQuery } from "src/components/HighlightedQuery"
import {
    BreadcrumbDefaultButton,
    BreadcrumbTooltipButton,
    DefaultBreadcrumbWrapper,
} from "src/components/SegmentPicker/components/ListItemBreadcrumb/styled"
import { Tooltip } from "src/components/Tooltips/Tooltip"
import { SegmentPickerStore } from "src/components/SegmentPicker/store"
import { useStore } from "src/store/lib/useStore"
import { IListItemBreadcrumbProps } from "src/components/SegmentPicker/components/ListItemBreadcrumb/types"

type BreadcrumbButton =
    | typeof BreadcrumbDefaultButton
    | typeof BreadcrumbTooltipButton

type PathPair = { path: string; index: number }

const TRUNCATED_PATH_PLACEHOLDER = "…"

function truncatePathParts(parts: PathPair[]): PathPair[] {
    return parts.length > 2
        ? [
              parts[0],
              { path: TRUNCATED_PATH_PLACEHOLDER, index: -1 },
              parts[parts.length - 1],
          ]
        : parts
}

function toPathPairs(paths: string[]): PathPair[] {
    return paths.map((path, index) => ({ path, index }))
}

function joinBreadcrumbs(breadcrumbs: React.ReactNode[]) {
    return breadcrumbs.map((breadcrumb, i) => (
        <React.Fragment key={i}>
            {i > 0 && <span style={{ margin: "0 4px" }}>/</span>}
            {breadcrumb}
        </React.Fragment>
    ))
}

export const ListItemBreadcrumb = observer(
    (props: IListItemBreadcrumbProps) => {
        const store = useStore(SegmentPickerStore)

        const createBreadcrumbClickHandler = useCallback(
            (id: number) => async () => {
                store.setParentId(id)
                await store.availableSegments.loadInitialPage()
            },
            [store],
        )

        const allPathParts = toPathPairs(props.segment.path.split(" / "))
        const truncatedPathParts = truncatePathParts(allPathParts)

        const buildBreadcrumbs = useCallback(
            (pathPairs: PathPair[], Component: BreadcrumbButton) => {
                return pathPairs.map((pair) => {
                    if (pair.path === TRUNCATED_PATH_PLACEHOLDER) {
                        return (
                            <span key={TRUNCATED_PATH_PLACEHOLDER}>
                                {TRUNCATED_PATH_PLACEHOLDER}
                            </span>
                        )
                    }

                    const parent =
                        store.getAncestorAtIndex(
                            props.segment.id,
                            pair.index,
                        ) ?? props.segment.id

                    const parentSegment = store.getSegmentWithId(parent)
                    if (
                        parentSegment != null &&
                        store.hasChildSegmentType(parentSegment)
                    ) {
                        return (
                            <Component
                                key={parent}
                                onClick={createBreadcrumbClickHandler(parent)}
                            >
                                <HighlightedQuery
                                    text={pair.path}
                                    query={store.selectedSegments.meta.search}
                                />
                            </Component>
                        )
                    }

                    return (
                        <HighlightedQuery
                            key={parent}
                            text={pair.path}
                            query={store.selectedSegments.meta.search}
                        />
                    )
                })
            },
            [props.segment, store, createBreadcrumbClickHandler],
        )

        const tooltipBreadcrumbs = joinBreadcrumbs(
            buildBreadcrumbs(allPathParts, BreadcrumbTooltipButton),
        )
        const breadcrumbs = joinBreadcrumbs(
            buildBreadcrumbs(truncatedPathParts, BreadcrumbDefaultButton),
        )

        return (
            <Tooltip
                disableInteractive={false}
                enterDelay={250}
                disableFocusListener
                title={<span>{tooltipBreadcrumbs}</span>}
                arrow
            >
                <DefaultBreadcrumbWrapper
                    data-testid={`ListItemBreadcrumb/DefaultBreadcrumbWrapper-${props.segment.type}`}
                >
                    {breadcrumbs}
                </DefaultBreadcrumbWrapper>
            </Tooltip>
        )
    },
)
