import { styled } from "@mui/material/styles"
import {
    Tabs,
    Tab,
    Box,
    Typography,
    Button,
    Dialog,
    DialogTitle,
} from "@mui/material"

export const AssetPickerDialog = styled(Dialog)(() => ({
    "& .MuiDialog-paper": {
        width: "100%",
        maxWidth: 600,
        maxHeight: "calc(100% - 64px)",
    },
}))

export const AssetPickerTitle = styled(DialogTitle)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "left",
    padding: theme.spacing(2),
}))

export const AssetPickerTabs = styled(Tabs)(({ theme }) => ({
    flex: 1,
    borderBottom: `1px solid ${theme.palette.divider}`,
}))

export const AssetPickerTab = styled(Tab)({
    flex: 1,
})

export const UploadArea = styled(Box)(({ theme }) => ({
    border: `2px dashed ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    width: "100px",
    height: "100px",
    padding: theme.spacing(0),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
        backgroundColor: theme.palette.action.hover,
    },
}))

export const OrText = styled(Typography)(({ theme }) => ({
    margin: theme.spacing(0, 2),
}))

export const AddButton = styled(Button)(() => ({
    textTransform: "none",
    height: "38px",
}))
