import { useCallback, useEffect } from "react"
import { observer } from "mobx-react"
import { Button } from "@mui/material"
import { t } from "@lingui/macro"
import { useNavigate } from "react-router-dom"

import { ProductCategoriesOrderStore } from "./store"

import { CenteredSpinner } from "src/components/CenteredSpinner"

import { ConfirmDeleteModal } from "src/modals/confirm-delete"
import { ProductCategoriesCreateOrEditModal } from "src/modals/product-categories-create-edit"
import { Form } from "src/components/Form"
import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { FormPanel } from "src/components/FormPanel"
import { ProductCategoriesCreateOrEditStore } from "src/modals/product-categories-create-edit/store"
import { ModalHeader } from "src/components/ModalHeader"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { reportError } from "src/lib/report"
import { RankableList } from "src/components/RankableList"

const ProductCategoriesOrder = observer(() => {
    const globalStore = useStore(GlobalStore)
    const store = useStore(ProductCategoriesOrderStore)
    const navigate = useNavigate()

    useEffect(() => {
        ;(async () => {
            await store.init()
        })()
    }, [store])

    const handleSubmit = useCallback(async () => {
        await store.submit()
        // TODO: handle error
        globalStore.modals.pop()
        // reload page, timeout just to not break tests
        setTimeout(() => {
            navigate(0)
        }, 100)
    }, [store, globalStore, navigate])

    const deleteCategory = useCallback(
        (id: number) => async () => {
            try {
                await store.deleteCategory(id)
            } catch {
                reportError(t`product-catalogue-view.failed-to-delete-product`)
            }
            globalStore.modals.pop()
            await store.init()
        },
        [store, globalStore.modals],
    )

    const deleteCategoryClickHandler = useCallback(
        (id: number) => async () => {
            await globalStore.modals.open(
                () => (
                    <ConfirmDeleteModal
                        variant="single"
                        itemId={id}
                        deleteItem={deleteCategory(id)}
                        title={t`confirm-delete-modal.title`}
                        content={t`confirm-delete-modal.content`}
                    />
                ),
                { variant: "slide-up-w600" },
            )
        },
        [globalStore, deleteCategory],
    )

    if (globalStore.loading.is(ProductCategoriesOrderStore.LoadingKeys.init)) {
        return <CenteredSpinner height="100vh" />
    }

    return (
        <Form onSubmit={handleSubmit}>
            <ModalHeader>
                <Button
                    data-testid="sort-categories-modal-submit-button"
                    type="submit"
                    variant="contained"
                    disabled={
                        globalStore.loading.is(
                            ProductCategoriesCreateOrEditStore.LoadingKeys
                                .submit,
                        ) || !store.hasUpdatedCategories
                    }
                >
                    {t`product-categories-order-modal.submit-button`}
                </Button>
            </ModalHeader>
            <FormPanel
                sections={[
                    {
                        header: t`product-categories-order-modal.modal-header`,
                        subHeader: t`product-categories-order-modal.modal-subheader`,
                        content: (
                            <RankableList
                                items={store.categories}
                                onSort={(nextCategories) =>
                                    store.setCategories(nextCategories)
                                }
                                rowRenderer={(item) => (
                                    <>
                                        <img
                                            src={item.imageUrl}
                                            style={{
                                                width: "38px",
                                                height: "38px",
                                                objectFit: "cover",
                                                objectPosition: "center",
                                                marginRight: "12px",
                                                borderRadius: "4px",
                                            }}
                                            alt={item.name}
                                        />
                                        <p>{item.name}</p>
                                    </>
                                )}
                                rowActionsRenderer={(category) => [
                                    {
                                        text: t`product-categories-order-modal.row-actions-renderer-configure-category`,
                                        onClick: () => {
                                            globalStore.modals.pop()
                                            globalStore.modals.open(() => (
                                                <ProductCategoriesCreateOrEditModal
                                                    categoryData={category}
                                                />
                                            ))
                                        },
                                    },
                                    {
                                        text: t`product-categories-order-modal.row-actions-renderer-delete-category`,
                                        onClick: deleteCategoryClickHandler(
                                            category.id,
                                        ),
                                        destructive: true,
                                    },
                                ]}
                            />
                        ),
                    },
                ]}
            />
        </Form>
    )
})

export const ProductCategoriesOrderModal = () => (
    <StoreProvider Store={ProductCategoriesOrderStore}>
        <ProductCategoriesOrder />
    </StoreProvider>
)
