import { IIconProps } from "src/components/icons"

export const Area24 = (props: IIconProps) => (
    <svg
        width={14}
        height={20}
        viewBox="0 0 14 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.333374 6.66667C0.337507 2.98648 3.31986 0.00413324 7.00004 0C10.6802 0.00413324 13.6626 2.98648 13.6667 6.66667C13.6667 9.595 9.50004 16.6883 7.71004 19.6025C7.5596 19.8505 7.29009 20.0014 7.00004 20C6.71054 20.0001 6.44174 19.8499 6.29004 19.6033C4.50004 16.6875 0.333374 9.59167 0.333374 6.66667ZM4.08337 6.66667C4.08337 8.2775 5.38921 9.58333 7.00004 9.58333C8.61087 9.58333 9.91671 8.2775 9.91671 6.66667C9.91671 5.05584 8.61087 3.75 7.00004 3.75C5.38921 3.75 4.08337 5.05584 4.08337 6.66667Z"
            fill="currentColor"
        />
    </svg>
)
