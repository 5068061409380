/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { lobby_LobbyListResponse } from "../models/lobby_LobbyListResponse"
import type { lobby_LobbyStatusRequest } from "../models/lobby_LobbyStatusRequest"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class LobbyAdminService {
    /**
     * List lobby requests
     * List lobby requests
     * @returns lobby_LobbyListResponse OK
     * @throws ApiError
     */
    public static getV1AdminLobby({
        pageNumber = 1,
        pageSize = 10,
        sort,
        accessGroupId,
        status,
    }: {
        /** Pagination - number of page to load **/
        pageNumber?: number
        /** Pagination - size of pages to load **/
        pageSize?: number
        /** Sorting - column to use for sort **/
        sort?: string
        /** Access group ID for which to filter **/
        accessGroupId?: number
        /** Lobby status filter **/
        status?: string
    }): CancelablePromise<lobby_LobbyListResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/lobby",
            query: {
                page_number: pageNumber,
                page_size: pageSize,
                sort: sort,
                access_group_id: accessGroupId,
                status: status,
            },
        })
    }

    /**
     * Approve all
     * Approve all lobby requests
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminLobbyApproveAll({
        accessGroupId,
    }: {
        /** Access group ID for which to approve requests **/
        accessGroupId?: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/lobby/approve-all",
            query: {
                access_group_id: accessGroupId,
            },
        })
    }

    /**
     * Respond to lobby request
     * Respond to lobby request
     * @returns any OK
     * @throws ApiError
     */
    public static putV1AdminLobby({
        lobbyId,
        request,
    }: {
        /** ID of lobby request to respond to **/
        lobbyId: number
        /** Request body to change lobby status **/
        request: lobby_LobbyStatusRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/lobby/{lobby_id}",
            path: {
                lobby_id: lobbyId,
            },
            body: request,
        })
    }
}
