import { makeAutoObservable } from "mobx"
import React from "react"

import { ProductCatalogueAdminService } from "src/api/services/ProductCatalogueAdminService"
import { Channel } from "src/channel"
import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"

export class ProvidersStore {
    static Context = React.createContext<ProvidersStore | null>(null)
    static LoadingKeys = createLoadingKeys("pageLoad")

    providers = new Pagination(
        async () => {
            const providers =
                await ProductCatalogueAdminService.getV1AdminCatalogueProvider()

            const items =
                providers.providers?.map((provider) => ({
                    id: provider.provider_id as number,
                    name: provider.name as string,
                    contact_details: provider.contact_details as string,
                    has_tracking: provider.has_tracking as boolean,
                    privacy_policy: provider.privacy_policy as string,
                    terms_and_conditions:
                        provider.terms_and_conditions as string,
                })) ?? []

            return {
                items,
                rawItems: providers.providers ?? [],
                count: providers.providers?.length ?? 0,
            }
        },
        { loadingKey: ProvidersStore.LoadingKeys.pageLoad },
    )

    private repositoryUpdatesListenerDisposer?: () => void

    constructor() {
        makeAutoObservable(this)
    }

    async init() {
        this.listenToMessageRepositoryUpdated()
        await this.providers.loadInitialPage()
    }

    private listenToMessageRepositoryUpdated() {
        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "providers"
                ) {
                    await this.providers.reload()
                }
            },
        )
    }
}
