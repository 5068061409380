/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { avy_api_pkg_segment_PublishResourceRequest } from "../models/avy_api_pkg_segment_PublishResourceRequest"
import type { avy_api_pkg_segment_PublishResourceResponse } from "../models/avy_api_pkg_segment_PublishResourceResponse"
import type { lock_AdminResourceListResponse } from "../models/lock_AdminResourceListResponse"
import type { lock_LockImportRow } from "../models/lock_LockImportRow"
import type { lock_LockResource } from "../models/lock_LockResource"
import type { lock_LockResourceCreate } from "../models/lock_LockResourceCreate"
import type { lock_LockResourceUpdate } from "../models/lock_LockResourceUpdate"
import type { lock_LockResourceWithSegments } from "../models/lock_LockResourceWithSegments"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class LockAdminService {
    /**
     * Get lock resource list
     * Get lock resource list
     * @returns lock_AdminResourceListResponse OK
     * @throws ApiError
     */
    public static getV1AdminLockResource({
        accessGroupId,
        pageNumber = 1,
        pageSize = 10,
    }: {
        /** Access group ID for which to fetch locks **/
        accessGroupId?: number
        /** Pagination - number of page to load **/
        pageNumber?: number
        /** Pagination - size of pages to load **/
        pageSize?: number
    }): CancelablePromise<lock_AdminResourceListResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/lock/resource",
            query: {
                access_group_id: accessGroupId,
                page_number: pageNumber,
                page_size: pageSize,
            },
        })
    }

    /**
     * Create lock resource
     * Create new lock resource
     * @returns lock_LockResource OK
     * @throws ApiError
     */
    public static postV1AdminLockResource({
        request,
    }: {
        /** Request body lock resource **/
        request: lock_LockResourceCreate
    }): CancelablePromise<lock_LockResource> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/lock/resource",
            body: request,
        })
    }

    /**
     * Import lock resources
     * Create new lock resource from csv
     * @returns lock_LockImportRow OK
     * @throws ApiError
     */
    public static postV1AdminLockResourceImport({
        file,
        accessGroupId,
        delimiter,
    }: {
        /** File to be uploaded **/
        file: Blob
        /** Access group ID for which to create locks **/
        accessGroupId: number
        /** CSV Delimiter (default ',') **/
        delimiter?: string
    }): CancelablePromise<lock_LockImportRow> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/lock/resource/import",
            query: {
                access_group_id: accessGroupId,
                delimiter: delimiter,
            },
            formData: {
                file: file,
            },
        })
    }

    /**
     * Get lock resource
     * Fetch specified lock resource
     * @returns lock_LockResourceWithSegments OK
     * @throws ApiError
     */
    public static getV1AdminLockResource1({
        resourceId,
    }: {
        /** Resource ID **/
        resourceId: number
    }): CancelablePromise<lock_LockResourceWithSegments> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/lock/resource/{resource_id}",
            path: {
                resource_id: resourceId,
            },
        })
    }

    /**
     * Update lock resource
     * Update lock resource
     * @returns lock_LockResource OK
     * @throws ApiError
     */
    public static putV1AdminLockResource({
        resourceId,
        request,
    }: {
        /** Resource ID **/
        resourceId: number
        /** Request body lock resource **/
        request: lock_LockResourceUpdate
    }): CancelablePromise<lock_LockResource> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/lock/resource/{resource_id}",
            path: {
                resource_id: resourceId,
            },
            body: request,
        })
    }

    /**
     * Publish lock resource
     * Publish lock resource
     * @returns avy_api_pkg_segment_PublishResourceResponse OK
     * @throws ApiError
     */
    public static putV1AdminLockResourcePublish({
        resourceId,
        request,
    }: {
        /** Resource ID **/
        resourceId: number
        /** Request body to set where lock is published **/
        request: avy_api_pkg_segment_PublishResourceRequest
    }): CancelablePromise<avy_api_pkg_segment_PublishResourceResponse> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/lock/resource/{resource_id}/publish",
            path: {
                resource_id: resourceId,
            },
            body: request,
        })
    }
}
