import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useEffect, useMemo } from "react"

import { ListPage } from "src/components/ListPage"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { TenantsInsightsStore } from "src/views/tenants-insights/store"

const View = observer(() => {
    const store = useStore(TenantsInsightsStore)
    const globalStore = useStore(GlobalStore)

    useEffect(() => {
        ;(async () => {
            await store.init()
        })()
    }, [store])

    const header = useMemo(
        () => ({
            header: t`tenants-insights-view.header`,
            breadcrumbs: [t`navigation.tenants-insights`],
        }),
        [],
    )

    return (
        <ListPage
            header={header}
            loading={globalStore.loading.is(
                TenantsInsightsStore.LoadingKeys.init,
            )}
        >
            {store.link != null && (
                <iframe
                    title="Tenants Insights Dashboard"
                    width="100%"
                    height="1084px"
                    src={store.link}
                    style={{
                        border: 0,
                    }}
                />
            )}
        </ListPage>
    )
})

export const TenantsInsightsView = () => (
    <StoreProvider Store={TenantsInsightsStore}>
        <View />
    </StoreProvider>
)
