import { t } from "@lingui/macro"
import {
    Box,
    Stack,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material"
import { observer } from "mobx-react"
import { useMemo } from "react"

import { FormPanel } from "src/components/FormPanel"
import { Icon } from "src/components/icons"
import { Alert16 } from "src/components/icons/Alert16"
import { TagsComponent } from "src/components/Tags"
import { formFields } from "src/modals/library-information-items/formFields"
import { LibraryInformationItemStore } from "src/modals/library-information-items/store"
import { ISettingsSectionProps } from "src/modals/library-information-items/types/settingsSectionProps"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { ITag } from "src/types/tags/tag"

export const LibraryInformationItemsSettingsSection = observer(
    (props: ISettingsSectionProps) => {
        const store = useStore(LibraryInformationItemStore)
        const globalStore = useStore(GlobalStore)
        const theme = useTheme()

        const showDavyControls = useMemo(() => {
            const isSuperUser = globalStore.session.user?.isSuper === true

            return (
                isSuperUser ||
                globalStore.session.hasAccessToModule("ai_chatbot")
            )
        }, [globalStore.session])

        return (
            <FormPanel
                sections={[
                    {
                        header: t`edit-information-item-modal.settings`,
                        content: (
                            <Stack spacing={2}>
                                <Box
                                    sx={{ display: "flex", gap: "8px" }}
                                    style={{ breakInside: "avoid" }}
                                >
                                    <TextField
                                        label={t`edit-information-item-modal.internal-name`}
                                        onChange={store.formFields.setter(
                                            "internalName",
                                        )}
                                        value={
                                            store.formFields.get(
                                                "internalName",
                                            ) ?? ""
                                        }
                                        helperText={store.formFields.error(
                                            "internalName",
                                        )}
                                        error={Boolean(
                                            store.formFields.error(
                                                "internalName",
                                            ),
                                        )}
                                        disabled={
                                            store.formFields.get(
                                                "accessType",
                                            ) === "READ"
                                        }
                                        inputProps={{ maxLength: 50 }}
                                    />
                                    <div>
                                        <Tooltip
                                            title={t`edit-information-item-modal.internal-name-tooltip`}
                                            arrow
                                        >
                                            <Typography
                                                sx={{ marginTop: "16px" }}
                                            >
                                                <Icon
                                                    icon={
                                                        <Alert16
                                                            width="20px"
                                                            height="20px"
                                                        />
                                                    }
                                                    color={
                                                        theme.palette.grey[700]
                                                    }
                                                />
                                            </Typography>
                                        </Tooltip>
                                    </div>
                                </Box>
                                <formFields.LibraryInformationItemsAccessGroup />
                                <formFields.LibraryInformationItemsType />
                                <formFields.LibraryInformationItemsCategory />
                                {showDavyControls && (
                                    <formFields.LibraryInformationItemsDavyControls />
                                )}
                                <Typography variant="h6">
                                    {t`edit-information-item-modal.tags`}
                                </Typography>
                                <TagsComponent
                                    id={props.id}
                                    tagIds={store.formFields.get("tag_ids")}
                                    onChange={(value: ITag[]) =>
                                        store.formFields.set(
                                            "tag_ids",
                                            value.map((t) => t.id),
                                        )
                                    }
                                />
                            </Stack>
                        ),
                    },
                ]}
            />
        )
    },
)
