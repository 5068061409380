import { observer } from "mobx-react"
import { useEffect, useCallback } from "react"
import { t } from "@lingui/macro"
import { Button, Link } from "@mui/material"

import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { Table } from "src/components/Table"
import { ListPage } from "src/components/ListPage"
import { ConfirmModal } from "src/modals/confirm"
import { ILibraryCategory } from "src/types/library"
import { LibraryCategoriesOrderModal } from "src/modals/library-categories-order"
import { IPageFilterProps } from "src/components/PageFilter"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { LibraryCategoriesStore } from "src/views/library-categories/store"
import { LibraryCategoriesModal } from "src/modals/library-categories"

export const View = observer(() => {
    const store = useStore(LibraryCategoriesStore)
    const globalStore = useStore(GlobalStore)

    useEffect(() => {
        ;(async () => {
            await store.init()
        })()
    }, [store])

    const header = {
        header: t`library-view.heading`,
        breadcrumbs: [
            t`library-view.heading`,
            t`library-categories-view.breadcrumb`,
        ],
        createOptions: {
            onClick: () =>
                globalStore.modals.open(() => <LibraryCategoriesModal />),
            item: "",
        },
    }

    const filter: IPageFilterProps | undefined = globalStore.features.enabled(
        "ShowNewLibrarySorting",
    )
        ? {
              actions: (
                  <Button
                      variant="outlined"
                      color="primary"
                      onClick={() =>
                          globalStore.modals.open(() => (
                              <LibraryCategoriesOrderModal
                                  categories={store.categories}
                              />
                          ))
                      }
                      // Disable the sorting button to prevent possible race-conditions.
                      // If the user re-orders the categories and then quickly opens the
                      // order modal again the categories will be in the wrong order.
                      disabled={
                          globalStore.loading.is(
                              LibraryCategoriesStore.LoadingKeys.init,
                          ) ||
                          globalStore.loading.is(
                              LibraryCategoriesStore.LoadingKeys.loadCategories,
                          )
                      }
                  >
                      {t`page-filter.sort-button`}
                  </Button>
              ),
          }
        : undefined

    const handleEditCategory = useCallback(
        (categoryId: number) => {
            globalStore.modals.open(() => (
                <LibraryCategoriesModal
                    category={store.categoriesPagination.items.find(
                        (item) => item.categoryId === categoryId,
                    )}
                />
            ))
        },
        [globalStore.modals, store.categoriesPagination.items],
    )

    const handleDeleteCategory = useCallback(
        (category: ILibraryCategory) => {
            globalStore.modals.open(
                () => (
                    <ConfirmModal
                        onConfirm={async (confirmed) => {
                            if (confirmed) {
                                await store.deleteCategory(
                                    category.categoryId as number,
                                )
                            }
                        }}
                        title={t`library-categories-view.are-you-sure`}
                        content={t({
                            id: "library-categories-view.confirm-delete-category",
                            values: { adminName: category.adminName },
                        })}
                    />
                ),
                {
                    variant: "slide-up-w600",
                },
            )
        },
        [globalStore.modals, store],
    )

    const rowRenderer = useCallback(
        (category: ILibraryCategory) => {
            return {
                categoryName:
                    (
                        <Link
                            component="button"
                            onClick={() =>
                                handleEditCategory(
                                    category.categoryId as number,
                                )
                            }
                            sx={{ textAlign: "left" }}
                        >
                            {category.displayName}
                        </Link>
                    ) ?? "",
                itemsInCategory: category.items.length,
            }
        },
        [handleEditCategory],
    )

    const rowActionsRenderer = useCallback(
        (category: ILibraryCategory) => {
            const actions = [
                {
                    text: t`library-categories-view.edit-category`,
                    onClick: () =>
                        handleEditCategory(category.categoryId as number),
                },
                {
                    text: t`library-categories-view.delete-category`,
                    onClick: () => handleDeleteCategory(category),
                },
            ]

            return actions
        },
        [handleEditCategory, handleDeleteCategory],
    )

    return (
        <ListPage
            header={header}
            filter={filter}
            loading={!store.categoriesPagination.meta.initialized}
        >
            <Table
                paginator={store.categoriesPagination}
                headers={[
                    {
                        key: "categoryName",
                        name: t`library-categories-view.category-name`,
                    },
                    {
                        key: "itemsInCategory",
                        name: t`library-categories-view.items-in-category`,
                    },
                ]}
                rowRenderer={rowRenderer}
                rowActionsRenderer={rowActionsRenderer}
            />
        </ListPage>
    )
})

export const LibraryCategoriesView = () => (
    <StoreProvider Store={LibraryCategoriesStore}>
        <View />
    </StoreProvider>
)
