import { observer } from "mobx-react"
import { Alert, Button } from "@mui/material"
import { t } from "@lingui/macro"
import { useCallback } from "react"

import { CreatePropertyOwnerModalStore, IFormFields } from "./store"
import { SettingsSection } from "./SettingsSection"

import { FormPanel } from "src/components/FormPanel"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { Form } from "src/components/Form"
import { ModalHeader } from "src/components/ModalHeader"
import { useCloseConfirmationForForm } from "src/store/modals/use-close-confirmation-for-form"
import { IntegrationType } from "src/types/integration"

interface ICreateNewPropertyOwnerProps {
    propertyOwnerId?: number
    legalName?: string
    integrationType?: string
    fastapiConnectorIntegrationId?: number
    mfaRequired?: boolean
    syncTenantContact?: boolean
}

const CreateNewPropertyOwner = observer(
    (props: ICreateNewPropertyOwnerProps) => {
        const globalStore = useStore(GlobalStore)
        const store = useStore(CreatePropertyOwnerModalStore)

        useCloseConfirmationForForm(store.fields)

        const validateRequiredFields = useCallback(
            (requiredFields: string[]) => {
                for (const field of requiredFields) {
                    const requiredField = field as keyof IFormFields
                    const formField = store.fields.get(requiredField)
                    if (
                        store.fields.get("integrationType") ===
                        IntegrationType.FastApi
                    ) {
                        if (store.fields.get("selectedConnectorId") === 0) {
                            store.fields.setError(
                                "selectedConnectorId",
                                t`errors.required`,
                            )
                        }
                    }
                    if (
                        formField == null ||
                        formField === "" ||
                        String(formField).trim().length === 0
                    ) {
                        store.fields.setError(requiredField, t`errors.required`)
                    } else {
                        store.fields.clearError(requiredField)
                    }
                }
            },
            [store.fields],
        )

        const handleSubmit = useCallback(async () => {
            validateRequiredFields(["legalName", "integrationType"])
            if (!store.fields.hasErrors()) {
                await store.submit(
                    Boolean(props.propertyOwnerId),
                    props.propertyOwnerId ?? 0,
                )
                globalStore.modals.pop()
            }
        }, [
            props.propertyOwnerId,
            globalStore.modals,
            store,
            validateRequiredFields,
        ])

        const isButtonDisabled =
            globalStore.loading.is(
                CreatePropertyOwnerModalStore.LoadingKeys.submit,
            ) ||
            store.fields.getIsDirty() === false ||
            (props.integrationType === store.fields.get("integrationType") &&
                props.fastapiConnectorIntegrationId ===
                    store.fields.get("selectedConnectorId") &&
                props.mfaRequired === store.fields.get("mfaRequired") &&
                props.syncTenantContact ===
                    store.fields.get("syncTenantContact"))

        const Title =
            props.propertyOwnerId !== undefined && props.propertyOwnerId > 0
                ? t`edit-category-modal.settings`
                : t`create-new-property-owner-modal.form-panel-header-create-property-owner`

        return (
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={isButtonDisabled}
                        data-testid="save-button"
                    >
                        {t`create-new-property-owner-modal.create-button`}
                    </Button>
                </ModalHeader>
                {Boolean(store.fields.error("generic")) && (
                    <Alert severity="error">
                        {store.fields.error("generic")}
                    </Alert>
                )}
                <FormPanel
                    sections={[
                        {
                            header: Title,
                            content: (
                                <SettingsSection
                                    legalName={props.legalName}
                                    integrationType={props.integrationType}
                                    isDisabled={Boolean(props.propertyOwnerId)}
                                    fastapiConnectorIntegrationId={
                                        props.fastapiConnectorIntegrationId
                                    }
                                    mfaRequired={props.mfaRequired}
                                    syncTenantContact={props.syncTenantContact}
                                />
                            ),
                        },
                    ]}
                />
            </Form>
        )
    },
)

export const CreateNewPropertyOwnerModal = (
    props: ICreateNewPropertyOwnerProps,
) => (
    <StoreProvider Store={CreatePropertyOwnerModalStore}>
        <CreateNewPropertyOwner
            legalName={props.legalName}
            integrationType={props.integrationType}
            propertyOwnerId={props.propertyOwnerId}
            fastapiConnectorIntegrationId={props.fastapiConnectorIntegrationId}
            mfaRequired={props.mfaRequired}
            syncTenantContact={props.syncTenantContact}
        />
    </StoreProvider>
)
