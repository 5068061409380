import { alpha, styled } from "@mui/material"
import { DataGridPro } from "@mui/x-data-grid-pro"

const DataGridContainer = styled("div")({
    width: "100%",
})

const StyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
    ".MuiDataGrid-cell:focus": {
        outline: "none",
    },
    ".MuiDataGrid-cell:focus-within": {
        outline: "none",
    },
    ".MuiDataGrid-row.Mui-selected": {
        backgroundColor: alpha(theme.palette.utility.darkBlue.main, 0.04),
    },
    ".MuiDataGrid-row.Mui-selected:hover": {
        backgroundColor: alpha(theme.palette.utility.darkBlue.main, 0.04),
    },
    ".MuiDataGrid-columnHeader:focus": {
        outline: "none",
    },
    ".MuiDataGrid-columnHeader:focus-within": {
        outline: "none",
    },
}))

export { DataGridContainer, StyledDataGridPro }
