/* eslint-disable @typescript-eslint/no-explicit-any */
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material"
import { observer } from "mobx-react"

import { IDropDownProps } from "./types"

export const DropDown = observer((props: IDropDownProps) => {
    return (
        <FormControl style={{ breakInside: "avoid" }}>
            <InputLabel id="type-label">{props.item.label}</InputLabel>
            <Select
                labelId={props.item.label}
                id={props.item.label}
                value={props.value}
                label={props.item.label}
                error={Boolean(props.item.errorMessage)}
                onChange={(event) =>
                    props.onSelectValueChange(props.index, event.target.value)
                }
            >
                {props.item.allowed_values?.map((item: string) => (
                    <MenuItem value={item} key={item}>
                        {item}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
})
