import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useEffect, useMemo } from "react"

import { ListPage } from "src/components/ListPage"
import { Table } from "src/components/Table"
import { formatDateTime, formatDateTimeRange } from "src/lib/date"
import { GlobalStore } from "src/store"

import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { BookingStatusChip } from "src/views/bookings/BookingStatusChip"
import { BookingsStore } from "src/views/bookings/store"

const View = observer(() => {
    const vstore = useStore(BookingsStore)
    const gstore = useStore(GlobalStore)

    useEffect(() => {
        ;(async () => {
            await vstore.init(gstore.session.accessGroupId)
        })()
    }, [vstore, gstore.session.accessGroupId])

    const header = useMemo(
        () => ({
            header: t`bookings-view.header`,
            breadcrumbs: [
                t`bookings-view.booking-breadcrumb`,
                t`bookings-view.bookings-breadcrumb`,
            ],
        }),
        [],
    )

    const loading = gstore.loading.is(BookingsStore.LoadingKeys.pageLoad)

    return (
        <ListPage header={header} loading={loading}>
            <Table
                paginator={vstore.bookings}
                headers={[
                    { key: "id", name: t`bookings-view.id-header` },
                    {
                        key: "tenantName",
                        name: t`bookings-view.tenant-name-header`,
                    },
                    {
                        key: "propertyObjectAddress",
                        name: t`bookings-view.property-object-address-header`,
                    },
                    { key: "resource", name: t`bookings-view.resource-header` },
                    { key: "created", name: t`bookings-view.created-header` },
                    {
                        key: "bookingSlot",
                        name: t`bookings-view.booking-slot-header`,
                    },
                    { key: "status", name: t`bookings-view.status-header` },
                ]}
                rowRenderer={(row) => ({
                    id: row.id,
                    tenantName: row.tenantName,
                    propertyObjectAddress: row.propertyObjectAddress,
                    resource: row.resourceName,
                    created:
                        row.createdAt != null
                            ? formatDateTime(row.createdAt)
                            : null,
                    bookingSlot:
                        row.startTime != null && row.endTime != null
                            ? formatDateTimeRange([row.startTime, row.endTime])
                            : null,
                    status: <BookingStatusChip status={row.status} />,
                })}
            />
        </ListPage>
    )
})

export const BookingsView = () => (
    <StoreProvider Store={BookingsStore}>
        <View />
    </StoreProvider>
)
