import { t } from "@lingui/macro"
import { Fab, Stack } from "@mui/material"
import { observer } from "mobx-react"
import { Container, Draggable, DropResult } from "react-smooth-dnd"

import { Add24 } from "src/components/icons/Add24"
import { initialMetaDataFieldsState } from "src/modals/contact-forms-form-builder/constants/initialFieldState"
import { MetaDataFieldsSection } from "src/modals/contact-forms-form-builder/DynamicViewComponents/MetaDataFieldsSection"
import { FormDetailStore } from "src/modals/contact-forms-form-builder/store"
import { FabButtonContainer } from "src/modals/contact-forms-form-builder/styled"
import {
    IExternalDynamicFieldsListItemProps,
    IMetaDataFieldListItemProps,
} from "src/modals/contact-forms-form-builder/types/fieldsListItem"
import { DraggableContainer } from "src/modals/embedded-integration-v2/sections/ContentSection/styled"
import { useStore } from "src/store/lib/useStore"

interface IMetaDataSectionProps {
    metaDataFields: IMetaDataFieldListItemProps[]
    setMetaDataFields: (value: IMetaDataFieldListItemProps[]) => void
}

export const MetaDataSection = observer(
    ({ metaDataFields, setMetaDataFields }: IMetaDataSectionProps) => {
        const store = useStore(FormDetailStore)

        const handleRemoveClick = (index: number) => {
            store.setHasError(false)

            const list = [...metaDataFields]
            list.splice(index, 1)
            setMetaDataFields(list)
        }

        const handleAddClick = () => {
            setMetaDataFields([
                ...metaDataFields,
                {
                    ...initialMetaDataFieldsState,
                },
            ])
        }

        const handleContentCopyClick = (index: number) => {
            const list = [...metaDataFields]
            const obj = JSON.parse(JSON.stringify(list[index]))
            list.splice(list.length, 0, obj)
            setMetaDataFields(list)
        }

        const sortListFromResult = (dropResult: IDropResult) => {
            const items = JSON.parse(JSON.stringify(metaDataFields))
            const item = items[dropResult.removedIndex]

            items.splice(dropResult.removedIndex, 1)
            items.splice(dropResult.addedIndex, 0, item)

            setMetaDataFields(
                items.map((item: IExternalDynamicFieldsListItemProps) => ({
                    ...item,
                })),
            )
        }

        const onDrop = (dropResult: DropResult) => {
            if (
                dropResult.removedIndex !== null &&
                dropResult.addedIndex !== null &&
                dropResult.addedIndex !== dropResult.removedIndex
            ) {
                sortListFromResult({
                    removedIndex: dropResult.removedIndex,
                    addedIndex: dropResult.addedIndex,
                })
            }
        }

        return (
            <Stack spacing={2}>
                <Container onDrop={onDrop}>
                    {metaDataFields.map((_, index) => (
                        <Draggable key={index}>
                            <DraggableContainer>
                                <MetaDataFieldsSection
                                    metaDataFields={metaDataFields}
                                    setMetaDataFields={setMetaDataFields}
                                    index={index}
                                    handleRemoveClick={handleRemoveClick}
                                    handleContentCopyClick={
                                        handleContentCopyClick
                                    }
                                />
                            </DraggableContainer>
                        </Draggable>
                    ))}
                </Container>
                <FabButtonContainer>
                    <Fab
                        variant="extended"
                        color="primary"
                        size="medium"
                        disabled={store.isEditMode}
                        onClick={handleAddClick}
                    >
                        <Add24 />
                        {t`contact-form-forms-detail-modal.form-field-section.add-field-label`}
                    </Fab>
                </FabButtonContainer>
            </Stack>
        )
    },
)
