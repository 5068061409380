import { t } from "@lingui/macro"
import { Alert, Button, Typography } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useEffect } from "react"

import { Form } from "src/components/Form"
import { FormPanel } from "src/components/FormPanel"
import { ModalHeader } from "src/components/ModalHeader"
import { TagsContentSection } from "src/modals/tags/sections/Content"
import { TagsStore } from "src/modals/tags/store"
import { StyledDiv } from "src/modals/tags/styled"
import { ITagsModalProps } from "src/modals/tags/types/tagsModalProps"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { useCloseConfirmationForForm } from "src/store/modals/use-close-confirmation-for-form"

export const Modal = observer((props: ITagsModalProps) => {
    const store = useStore(TagsStore)
    const globalStore = useStore(GlobalStore)

    useCloseConfirmationForForm(store.formFields)

    useEffect(() => {
        store.init(props.tag)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.tag])

    const handleSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            await store.validateAndSubmit(props.tag)

            if (!store.formFields.hasErrors()) {
                globalStore.modals.pop()
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.tag],
    )

    const isButtonDisabled = () => {
        return store.isLoading || store.formFields.getIsDirty() === false
    }

    return (
        <StyledDiv variant={props.variant}>
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={isButtonDisabled()}
                        data-testid="submit-tags-form"
                    >
                        {t`global.save`}
                    </Button>
                </ModalHeader>
                {Boolean(store.formFields.error("generic")) && (
                    <Alert severity="error">
                        {store.formFields.error("generic")}
                    </Alert>
                )}
                <FormPanel
                    sections={[
                        {
                            header: t`tags-modal.section.tag-id`,
                            content: (
                                <Typography variant="body2">
                                    {store.formFields.get("id") ?? "-"}
                                </Typography>
                            ),
                        },
                    ]}
                />
                <TagsContentSection />
            </Form>
        </StyledDiv>
    )
})

export const TagsModal = (props: ITagsModalProps) => (
    <StoreProvider Store={TagsStore}>
        <Modal {...props} />
    </StoreProvider>
)
