import { t } from "@lingui/macro"
import { IconButton, Stack, Toolbar } from "@mui/material"
import { observer } from "mobx-react"

import { HeadUserInfo } from "./Head/HeadUserInfo"

import { StyledAppBar } from "src/layouts/DefaultLayout/styled"

import { Icon } from "src/components/icons"
import { Hamburger32 } from "src/components/icons/Hamburger32"
import { Tooltip } from "src/components/Tooltips/Tooltip"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"

interface IProps {
    onMenuClick: () => void
}

export const AppBar = observer((props: IProps) => {
    const gstore = useStore(GlobalStore)

    const user = gstore.session.user
    const mainPropertyName = gstore.session.mainPropertyName

    const userTooltip = (
        <>
            <div>{user?.name}</div>
            <div>
                {user?.isSuper === true
                    ? t`global.super-user`
                    : mainPropertyName}
            </div>
        </>
    )
    return (
        <StyledAppBar>
            <Toolbar
                component={Stack}
                direction="row"
                justifyContent="space-between"
                padding="0 8px"
            >
                <IconButton onClick={props.onMenuClick}>
                    <Icon icon={<Hamburger32 />} color="#333" />
                </IconButton>
                <Tooltip title={userTooltip} placement="bottom-end" arrow>
                    <HeadUserInfo />
                </Tooltip>
            </Toolbar>
        </StyledAppBar>
    )
})
