import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useCallback, useEffect, useMemo } from "react"

import { Grid, InputAdornment, useMediaQuery, useTheme } from "@mui/material"
import { useNavigate } from "react-router"

import { TenantsListStore } from "./store"

import {
    ActionsContainer,
    ActionsLargeScreenContainer,
    ButtonContainerLargeScreen,
    ButtonsContainer,
    HeaderContainer,
    SearchField,
    SegmentPicker,
} from "./styled"

import { useStore } from "src/store/lib/useStore"

import { ListPage } from "src/components/ListPage"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"

import { ActionDropdown } from "src/components/ActionDropdown"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { NoWrap } from "src/components/NoWrap"
import { H1, Subheader } from "src/components/PageHeader"
import { Table } from "src/components/Table"
import { Search24 } from "src/components/icons/Search24"
import { formatDate } from "src/lib/date"
import { truncateText } from "src/lib/text"
import { ConfirmDeleteModal } from "src/modals/confirm-delete"
import { reportError, reportSuccess } from "src/lib/report"
import { MixpanelProperties } from "src/analytics/constants/properties"

const View = observer(() => {
    const store = useStore(TenantsListStore)
    const gstore = useStore(GlobalStore)
    const theme = useTheme()
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))
    const navigate = useNavigate()
    const selectedTenantSegments =
        window.localStorage.getItem("SelectedTenantSegments") !== null
            ? JSON.parse(
                  window.localStorage.getItem("SelectedTenantSegments") ?? "",
              )
            : []
    const header = useMemo(
        () => ({
            header: "",
            breadcrumbs: [""],
        }),
        [],
    )

    useEffect(() => {
        ;(async () => {
            // Preselect only propertyowner segments if they exist
            // This is temporary mitigation of performance issues, long term
            // we need to figure out something better
            let propertyOwnerSegmentIDs =
                selectedTenantSegments?.length > 0
                    ? selectedTenantSegments
                    : gstore.session.segments
                          .filter((segment) => segment.type === "propertyowner")
                          .map((segmentObject) => segmentObject.id)

            await store.init(
                gstore.session.accessGroupId,
                propertyOwnerSegmentIDs,
                gstore.session.user?.adminId,
            )
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        store,
        gstore.session.accessGroupId,
        gstore.session.segments,
        gstore.session.user,
    ])

    const tableIsLoading =
        !store.tenants.meta.initialized ||
        gstore.loading.is(TenantsListStore.LoadingKeys.pageLoad)

    const handleSegmentChange = useCallback(
        (segments: number[]) => store.loadSegments(segments),
        [store],
    )

    const searchFieldInputProps = {
        startAdornment: (
            <InputAdornment
                position="start"
                sx={{ color: theme.palette.grey[900] }}
            >
                <Search24 />
            </InputAdornment>
        ),
        type: "search",
    }

    const handleSearchChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            store.tenants.loadSearch(event.target.value),
        [store.tenants],
    )

    const handleDownloadSelectedTenantsAsCsv = useCallback(() => {
        trackModuleEvent("Residents | Export Tenant Data", {
            [MixpanelProperties.UserID]: gstore.session.user?.adminId,
            [MixpanelProperties.SegmentID]: store.segments,
        })
        return store.export()
    }, [store, gstore.session.user])

    const handleDownloadSelectedContractAsCsv = useCallback(() => {
        trackModuleEvent("Residents | Export Contract Data", {
            [MixpanelProperties.UserID]: gstore.session.user?.adminId,
            [MixpanelProperties.SegmentID]: store.segments,
        })
        return store.exportContract()
    }, [store, gstore.session.user])

    const handleDeleteTenantsWithExpiredContacts = useCallback(
        () => async () => {
            try {
                await store.deleteTenantsWithExpiredContracts()
                reportSuccess(t`tenants-list-view.tenants-delete-success`)
            } catch (error) {
                reportError(t`tenants-list-view.tenants-delete-failed`, error)
            }
            gstore.modals.pop()
        },
        [store, gstore.modals],
    )

    const deleteTenantsWithExpiredClickHandler = useCallback(async () => {
        await gstore.modals.open(
            () => (
                <ConfirmDeleteModal
                    variant="multiple"
                    deleteItems={handleDeleteTenantsWithExpiredContacts()}
                    title={t`tenants-list-view-confirm-delete-modal.title`}
                    content={t`tenants-list-view-confirm-delete-modal.content`}
                    additionalConfirmation
                />
            ),
            { variant: "slide-up-w600" },
        )
    }, [gstore, handleDeleteTenantsWithExpiredContacts])

    const handleTenantClick = (tenantId: number) => {
        trackModuleEvent("Residents | Detail View", {
            [MixpanelProperties.TenantID]: tenantId,
            [MixpanelProperties.UserID]: gstore.session.user?.adminId,
        })
        navigate(`/tenants/${tenantId}`)
    }

    const renderSegmentButton = useMemo(
        () => (
            <SegmentPicker
                value={store.segments}
                onChange={handleSegmentChange}
                allowedSegmentTypes={[
                    "propertyowner",
                    "legalentity",
                    "property",
                ]}
            />
        ),
        [handleSegmentChange, store.segments],
    )

    const rowActionsRenderer = useMemo(
        () => [
            {
                text: t`tenants-list-view.export-tenant-option`,
                onClick: handleDownloadSelectedTenantsAsCsv,
            },
            {
                text: t`tenants-list-view.export-contract-option`,
                onClick: handleDownloadSelectedContractAsCsv,
            },
            {
                text: t`tenants-list-view.delete-menu-option`,
                onClick: deleteTenantsWithExpiredClickHandler,
                destructive: true,
            },
        ],
        [
            handleDownloadSelectedTenantsAsCsv,
            deleteTenantsWithExpiredClickHandler,
            handleDownloadSelectedContractAsCsv,
        ],
    )

    const renderActionMenu = useMemo(() => {
        return <ActionDropdown items={rowActionsRenderer} />
    }, [rowActionsRenderer])

    return (
        <>
            <div>
                <H1>{t`tenants-list.header-title`}</H1>
                <Subheader>{t`tenants-list.header-title`}</Subheader>
            </div>
            <HeaderContainer container>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <SearchField
                        value={store.tenants.meta.search ?? ""}
                        placeholder={t`segment-picker.available.search-placeholder`}
                        title={t`segment-picker.available.search-placeholder`}
                        onChange={handleSearchChange ?? undefined}
                        size="small"
                        InputProps={searchFieldInputProps}
                        data-testid="tenant-search"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    {smallScreen ? (
                        <Grid container>
                            <Grid item xs={0} sm={0} md={0} lg={6} xl={6} />
                            <ButtonsContainer
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={4}
                                xl={4}
                            >
                                {renderSegmentButton}
                            </ButtonsContainer>
                            <ActionsContainer>
                                {renderActionMenu}
                            </ActionsContainer>
                        </Grid>
                    ) : (
                        <ButtonContainerLargeScreen>
                            {renderSegmentButton}
                            <ActionsLargeScreenContainer>
                                {renderActionMenu}
                            </ActionsLargeScreenContainer>
                        </ButtonContainerLargeScreen>
                    )}
                </Grid>
            </HeaderContainer>
            <ListPage header={header} loading={tableIsLoading}>
                <Table
                    paginator={store.tenants}
                    onRowClick={(item) => handleTenantClick(item.id)}
                    headers={[
                        {
                            key: "name",
                            name: t`tenant-list.tenant-name`,
                        },
                        {
                            key: "id",
                            name: t`tenant-list.tenant-id`,
                        },
                        {
                            key: "email",
                            name: t`tenant-list.email`,
                        },
                        {
                            key: "phone_no",
                            name: t`tenant-list.phone-number`,
                        },
                        {
                            key: "is_registered",
                            name: t`tenant-list.is-registered`,
                        },
                        {
                            key: "registration_date",
                            name: t`tenant-list.registration-date`,
                        },
                    ]}
                    rowRenderer={(item) => ({
                        name: (
                            <NoWrap title={item.name}>
                                {truncateText(item.name, 30)}
                            </NoWrap>
                        ),
                        id: <NoWrap>{item.id}</NoWrap>,
                        email: (
                            <NoWrap title={item.email}>
                                {truncateText(item.email, 30)}
                            </NoWrap>
                        ),
                        phone_no: (
                            <NoWrap title={item.phone_no}>
                                {truncateText(item.phone_no, 30)}
                            </NoWrap>
                        ),
                        is_registered: item.is_registered ? "Yes" : "No",
                        registration_date: (
                            <NoWrap title={item.registration_date}>
                                {item.registration_date !== undefined
                                    ? formatDate(
                                          new Date(item.registration_date),
                                      )
                                    : ""}
                            </NoWrap>
                        ),
                    })}
                />
            </ListPage>
        </>
    )
})

export const TenantsListView = observer(() => (
    <StoreProvider Store={TenantsListStore}>
        <View />
    </StoreProvider>
))
