import { makeAutoObservable } from "mobx"
import React from "react"

import { MixpanelProperties } from "src/analytics/constants/properties"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"

import { BookingAdminService } from "src/api"
import { Channel } from "src/channel"
import { DEFAULT_ACCESS_GROUP } from "src/config"

import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"
import { reportUnhandledApiError } from "src/lib/report"
import { ResourceType } from "src/types/resource"

export class ResourcesStore implements IDisposable {
    static Context = React.createContext<ResourcesStore | null>(null)
    static LoadingKeys = createLoadingKeys("pageLoad")

    constructor() {
        makeAutoObservable(this)
    }

    private _accessGroupId: number = DEFAULT_ACCESS_GROUP.id
    private repositoryUpdatesListenerDisposer?: () => void

    resources = new Pagination(
        async (query) => {
            const response =
                await BookingAdminService.getV1AdminBookingResource({
                    accessGroupId: this.getAccessGroupId(),
                    pageNumber: query.page,
                    pageSize: query.pageSize,
                })

            const items = (response.result ?? []).map((resource) => ({
                id: resource.resource_id ?? -1,
                name: resource.name,
                publishedIn: resource.published_in,
                publicRecordingId: resource.public_resource_id,
                resourceType: resource.resource_type,
                accessType: resource.access_type,
            }))

            return { items, count: response.total_count ?? 0 }
        },
        {
            loadingKey: ResourcesStore.LoadingKeys.pageLoad,
        },
    )

    async init(accessGroupId: number) {
        this.listenToResourcesRepositoryUpdated()
        this.setAccountGroupId(accessGroupId)
        await this.resources.loadInitialPage()
    }

    dispose() {
        this.repositoryUpdatesListenerDisposer?.()
    }

    async duplicateResource(id: number, accessGroupName: string) {
        try {
            const response =
                await BookingAdminService.postV1AdminBookingResourceClone({
                    resourceId: id,
                })
            trackModuleEvent("Booking | Duplicate resource", {
                [MixpanelProperties.ItemID]: response.resource_id,
                [MixpanelProperties.ItemName]: response.name,
                [MixpanelProperties.AccessGroupName]: accessGroupName,
                [MixpanelProperties.ResourceType]:
                    response.integration_connector_id !== null
                        ? ResourceType.Integration
                        : ResourceType.BookingEngine,
            })
        } catch (e) {
            reportUnhandledApiError(e)
        } finally {
            await this.resources.reload()
        }
    }

    async deleteResource(id: number) {
        try {
            await BookingAdminService.deleteV1AdminBookingResource({
                resourceId: id,
            })
        } catch (e) {
            reportUnhandledApiError(e)
        } finally {
            await this.resources.reload()
        }
    }

    private listenToResourcesRepositoryUpdated() {
        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "resources"
                ) {
                    await this.resources.reload()
                }
            },
        )
    }

    private getAccessGroupId() {
        return this._accessGroupId !== DEFAULT_ACCESS_GROUP.id
            ? this._accessGroupId
            : undefined
    }

    private setAccountGroupId(accessGroupId: number) {
        this._accessGroupId = accessGroupId
    }
}
