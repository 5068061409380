import { IIconProps } from "src/components/icons"

export const Property24 = (props: IIconProps) => (
    <svg
        width={18}
        height={18}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.8 15.517C5.13137 15.517 5.4 15.7949 5.4 16.1377V17.379C5.4 17.7218 5.66863 17.9997 6 17.9997H7.8C8.13137 17.9997 8.4 17.7218 8.4 17.379V8.06888C8.40045 7.83355 8.27219 7.61819 8.0688 7.51276L4.4688 5.65073C4.29963 5.56304 4.10037 5.56304 3.9312 5.65073L0.331201 7.51276C0.12781 7.61819 -0.000445977 7.83355 1.16539e-06 8.06888V17.379C1.16539e-06 17.7218 0.26863 17.9997 0.600001 17.9997H2.4C2.73137 17.9997 3 17.7218 3 17.379V16.1377C3 15.7949 3.26863 15.517 3.6 15.517H4.8ZM2.4 8.68956H6C6.33137 8.68956 6.6 8.96744 6.6 9.31023C6.6 9.65302 6.33137 9.93091 6 9.93091H2.4C2.06863 9.93091 1.8 9.65302 1.8 9.31023C1.8 8.96744 2.06863 8.68956 2.4 8.68956ZM1.8 12.4136C1.8 12.7564 2.06863 13.0343 2.4 13.0343H6C6.33137 13.0343 6.6 12.7564 6.6 12.4136C6.6 12.0708 6.33137 11.7929 6 11.7929H2.4C2.06863 11.7929 1.8 12.0708 1.8 12.4136Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.4 18H10.2C9.86861 18 9.59998 17.7221 9.59998 17.3793V0.621092C9.59984 0.413567 9.69998 0.219704 9.86682 0.104474C10.0337 -0.0107548 10.2451 -0.0321018 10.4304 0.0475878L17.6304 3.15096C17.8543 3.2473 18.0001 3.4736 18 3.72447V17.3793C18 17.7221 17.7313 18 17.4 18ZM15.6 5.58649H12C11.6686 5.58649 11.4 5.86438 11.4 6.20717C11.4 6.54996 11.6686 6.82784 12 6.82784H15.6C15.9313 6.82784 16.2 6.54996 16.2 6.20717C16.2 5.86438 15.9313 5.58649 15.6 5.58649ZM12 8.68987H15.6C15.9313 8.68987 16.2 8.96776 16.2 9.31055C16.2 9.65334 15.9313 9.93122 15.6 9.93122H12C11.6686 9.93122 11.4 9.65334 11.4 9.31055C11.4 8.96776 11.6686 8.68987 12 8.68987ZM15.6 11.7932H12C11.6686 11.7932 11.4 12.0711 11.4 12.4139C11.4 12.7567 11.6686 13.0346 12 13.0346H15.6C15.9313 13.0346 16.2 12.7567 16.2 12.4139C16.2 12.0711 15.9313 11.7932 15.6 11.7932Z"
            fill="currentColor"
        />
    </svg>
)
