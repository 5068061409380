import { message_UpdateMessage } from "src/api"
import { CancelablePromise } from "src/api/core/CancelablePromise"
import { OpenAPI } from "src/api/core/OpenAPI"
import { request as __request } from "src/api/core/request"

export class MessageAdminService {
    /**
     * Updates message a message. This is re-implemented as the generated one
     * is non-functioning as it misses the path parameter.
     *
     * (orignal description)
     * Update message updates and optionally re-publishes message. Important:
     * fields that are not sent (null) in request body are going to be emptied
     * in database.
     *
     * @throws ApiError
     */
    public static patchV1AdminMessage({
        request,
        messageId,
    }: {
        /** Request body update message **/
        request: message_UpdateMessage
        messageId: number
    }): CancelablePromise<unknown> {
        return __request(OpenAPI, {
            method: "PATCH",
            url: "/v1/admin/message/{message_id}",
            body: request,
            path: {
                message_id: messageId,
            },
        })
    }
}
