import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useMemo } from "react"

import { IStatusChipProps, StatusChip } from "src/components/StatusChip"

interface IProps {
    status: string
}

export const ProductCatalogueStatusChip = observer(({ status }: IProps) => {
    const chipProps = useMemo((): IStatusChipProps => {
        if (status === "published") {
            return {
                state: "positive",
                label: t`product-catalogue-view.status-chip-published-status`,
            }
        } else if (status === "unpublished") {
            return {
                state: "negative",
                label: t`product-catalogue-view.status-chip-unpublished-status`,
            }
        } else if (status === "ready") {
            return {
                state: "neutral",
                label: t`product-catalogue-view.status-chip-ready-for-publish-status`,
            }
        } else {
            return {
                state: "other",
                label: t`product-catalogue-view.status-chip-draft-status`,
            }
        }
    }, [status])

    return <StatusChip {...chipProps} />
})
