import { makeAutoObservable } from "mobx"
import React from "react"

import { avy_api_pkg_segment_SegmentForSearch } from "src/api"
import { CustomSegmentsAdminService } from "src/api/_custom/services/SegmentsAdminService"
import { loads } from "src/channel/utils"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { createLoadingKeys } from "src/lib/loading"

type ISegmentType = ISegmentForSearch["type"]

export interface ISegment {
    id: number
    name: string
    type: ISegmentType
}

export class SegmentPickerFieldStore {
    static Context = React.createContext<SegmentPickerFieldStore | null>(null)
    static LoadingKeys = createLoadingKeys("init")

    private accessGroupId = DEFAULT_ACCESS_GROUP.id
    segments: ISegment[] = []

    constructor() {
        makeAutoObservable(this)
    }

    @loads(() => SegmentPickerFieldStore.LoadingKeys.init)
    async init(
        accessGroupId: number | null,
        skipLoadingSegments: boolean | undefined,
    ) {
        this.setAccessGroupId(accessGroupId)
        skipLoadingSegments !== true && (await this.loadSegments())
    }

    private setAccessGroupId(accessGroudId: number | null) {
        this.accessGroupId = accessGroudId ?? DEFAULT_ACCESS_GROUP.id
    }

    private async loadSegments() {
        const segments =
            await CustomSegmentsAdminService.getV1AdminSegmentSearch({
                accessGroupId:
                    this.accessGroupId !== DEFAULT_ACCESS_GROUP.id
                        ? String(this.accessGroupId)
                        : undefined,
            })

        const transformed = segments.map(this.transformSegment)
        this.setSegments(transformed)
    }

    private setSegments(segments: ISegment[]) {
        this.segments = segments
    }

    private transformSegment(
        segment: avy_api_pkg_segment_SegmentForSearch,
    ): ISegment {
        return {
            id: segment.id ?? -1,
            name: segment.name ?? "",
            type: segment.type as ISegmentType,
        }
    }
}
