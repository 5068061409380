import { t } from "@lingui/macro"
import {
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Stack,
    styled,
} from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useEffect } from "react"

import { ConfirmModal } from "src/modals/confirm"
import { CopyFeatureConfigurationStore } from "src/modals/copy-feature-configuration/store"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { ISessionLegalEntity } from "src/store/session"

const Wrap = styled(Stack)({ maxHeight: "inherit" })

const List = styled("ul")({
    overflow: "auto",
    margin: 0,
    padding: "0 0 0 1rem",
})

const ListItem = styled("li")({
    listStyle: "none",
})

interface IProps {
    from: ISessionLegalEntity
    legalEntities: ISessionLegalEntity[]
}

const View = observer((props: IProps) => {
    const store = useStore(CopyFeatureConfigurationStore)
    const gstore = useStore(GlobalStore)

    useEffect(() => {
        store.init(props.from, props.legalEntities)
    }, [store, props.from, props.legalEntities])

    const handleConfirmCopyClick = useCallback(() => {
        gstore.modals.open(
            () => (
                <ConfirmModal
                    onConfirm={async (confirmed) => {
                        if (confirmed) {
                            await store.submit()
                            gstore.modals.pop()
                        }
                    }}
                    title={t`copy-feature-configuration-modal.confirm-title`}
                    content={t`copy-feature-configuration-modal.confirm-content`}
                />
            ),
            {
                variant: "slide-up-w600",
            },
        )
    }, [gstore, store])

    const copyButtonIsDisabled =
        !store.hasSelected() ||
        gstore.loading.is(CopyFeatureConfigurationStore.LoadingKeys.submit)

    return (
        <Wrap>
            <DialogTitle>{t`copy-feature-configuration-modal.modal-header`}</DialogTitle>
            <DialogContent>
                <List>
                    {store.legalEntities.map((le) => (
                        <ListItem key={le.id}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={store.willCopy(le)}
                                        onChange={() => store.toggleCopy(le)}
                                    />
                                }
                                label={le.legalName as string}
                            />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "flex-end", padding: "16px" }}>
                <Button color="info" onClick={() => gstore.modals.pop()}>
                    {t`global.cancel`}
                </Button>
                <Button
                    variant="contained"
                    onClick={handleConfirmCopyClick}
                    disabled={copyButtonIsDisabled}
                >
                    {t`copy-feature-configuration-modal.modal-copy`}
                </Button>
            </DialogActions>
        </Wrap>
    )
})

export const CopyFeatureConfigurationModal = observer((props: IProps) => (
    <StoreProvider Store={CopyFeatureConfigurationStore}>
        <View {...props} />
    </StoreProvider>
))
