/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { admin_VerifyTOTPKeyRequest } from "../models/admin_VerifyTOTPKeyRequest"
import type { admin_VerifyTOTPKeyResponse } from "../models/admin_VerifyTOTPKeyResponse"
import type { avy_api_internal_auth_FALogInResult } from "../models/avy_api_internal_auth_FALogInResult"
import type { avy_api_internal_auth_LoginRequest } from "../models/avy_api_internal_auth_LoginRequest"
import type { avy_api_internal_auth_LogInResult } from "../models/avy_api_internal_auth_LogInResult"
import type { avy_api_internal_auth_mfa_TOTPKey } from "../models/avy_api_internal_auth_mfa_TOTPKey"
import type { avy_api_internal_auth_NewPasswordRequest } from "../models/avy_api_internal_auth_NewPasswordRequest"
import type { avy_api_internal_auth_OAuthAccessTokenRequest } from "../models/avy_api_internal_auth_OAuthAccessTokenRequest"
import type { avy_api_internal_auth_OAuthAccessTokenResponse } from "../models/avy_api_internal_auth_OAuthAccessTokenResponse"
import type { avy_api_internal_auth_ResetPasswordRequest } from "../models/avy_api_internal_auth_ResetPasswordRequest"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class AuthenticationAdminService {
    /**
     * SSO for Fastighetsagarna admins
     * This takes a token issued by Fastighetsagarna's authentication provider and issues an Avy JWT token.
     * @returns avy_api_internal_auth_FALogInResult OK
     * @throws ApiError
     */
    public static postV1AdminAuthFaLogin(): CancelablePromise<avy_api_internal_auth_FALogInResult> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/auth/fa/login",
        })
    }

    /**
     * Issues an Avy admin token for an Avy admin
     * This takes an email and a password and validates against what we have store
     * @returns avy_api_internal_auth_LogInResult OK
     * @throws ApiError
     */
    public static postV1AdminAuthLogin({
        request,
    }: {
        /** email and password must be provided **/
        request: avy_api_internal_auth_LoginRequest
    }): CancelablePromise<avy_api_internal_auth_LogInResult> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/auth/login",
            body: request,
        })
    }

    /**
     * Get TOTP key for MFA setup
     * Get the TOTP key for the user to setup MFA
     * @returns avy_api_internal_auth_mfa_TOTPKey OK
     * @throws ApiError
     */
    public static postV1AdminAuthMfaTotpCreateKey(): CancelablePromise<avy_api_internal_auth_mfa_TOTPKey> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/auth/mfa/totp/create-key",
        })
    }

    /**
     * Verify TOTP key
     * Verify the TOTP key for the user
     * @returns admin_VerifyTOTPKeyResponse OK
     * @throws ApiError
     */
    public static postV1AdminAuthMfaTotpVerifyKey({
        request,
    }: {
        /** Code must be provided **/
        request: admin_VerifyTOTPKeyRequest
    }): CancelablePromise<admin_VerifyTOTPKeyResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/auth/mfa/totp/verify-key",
            body: request,
        })
    }

    /**
     * SSO for Microsoft Azure
     * This takes a token issued by Microsfot Azure and issues an Avy JWT token.
     * @returns avy_api_internal_auth_LogInResult OK
     * @throws ApiError
     */
    public static postV1AdminAuthMsLogin(): CancelablePromise<avy_api_internal_auth_LogInResult> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/auth/ms/login",
        })
    }

    /**
     * Creates a new password for an admin
     * Takes password in the body. Requires there to be a reset token created prior (passed as queryparam)
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminAuthNewPassword({
        token,
        request,
    }: {
        /** the token created when resetting password **/
        token: string
        /** password must be provided **/
        request: avy_api_internal_auth_NewPasswordRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/auth/new-password",
            query: {
                token: token,
            },
            body: request,
        })
    }

    /**
     * Issues access token for integration client
     * Generates OAuth access token for client_id and client_secret combination
     * @returns avy_api_internal_auth_OAuthAccessTokenResponse OK
     * @throws ApiError
     */
    public static oauthToken({
        request,
    }: {
        /** Request for oauth access token **/
        request: avy_api_internal_auth_OAuthAccessTokenRequest
    }): CancelablePromise<avy_api_internal_auth_OAuthAccessTokenResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/auth/oauth/token",
            body: request,
        })
    }

    /**
     * Creates a reset token so the user can create a new password later
     * Takes an email as input and creates a token to be used for resetting password
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminAuthResetPassword({
        request,
    }: {
        /** email  must be provided **/
        request: avy_api_internal_auth_ResetPasswordRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/auth/reset-password",
            body: request,
        })
    }
}
