/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { accessgroup_AccessGroupSimpleResponse } from "../models/accessgroup_AccessGroupSimpleResponse"
import type { avy_api_pkg_admin_domain_AccessGroup } from "../models/avy_api_pkg_admin_domain_AccessGroup"
import type { avy_api_pkg_admin_domain_AccessGroupCreateUpdate } from "../models/avy_api_pkg_admin_domain_AccessGroupCreateUpdate"
import type { avy_api_pkg_segment_SegmentForSearch } from "../models/avy_api_pkg_segment_SegmentForSearch"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class AccessGroupsAdminService {
    /**
     * Get access groups
     * Returns all access groups.
     * @returns accessgroup_AccessGroupSimpleResponse OK
     * @throws ApiError
     */
    public static getV1AdminAccessGroup(): CancelablePromise<
        Array<accessgroup_AccessGroupSimpleResponse>
    > {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/access-group",
        })
    }

    /**
     * Create access group
     * Creates an access group.
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminAccessGroup({
        request,
    }: {
        /** Access group data. **/
        request: avy_api_pkg_admin_domain_AccessGroupCreateUpdate
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/access-group",
            body: request,
        })
    }

    /**
     * Segment picker for access groups
     * Returns available segments for entire property owner
     * @returns avy_api_pkg_segment_SegmentForSearch OK
     * @throws ApiError
     */
    public static getV1AdminAccessGroupSegment(): CancelablePromise<
        Array<avy_api_pkg_segment_SegmentForSearch>
    > {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/access-group/segment",
        })
    }

    /**
     * Get access group
     * Returns an access group by ID.
     * @returns avy_api_pkg_admin_domain_AccessGroup OK
     * @throws ApiError
     */
    public static getV1AdminAccessGroup1({
        accessGroupId,
    }: {
        /** Access Group ID **/
        accessGroupId: number
    }): CancelablePromise<avy_api_pkg_admin_domain_AccessGroup> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/access-group/{access_group_id}",
            path: {
                access_group_id: accessGroupId,
            },
        })
    }

    /**
     * Delete access group
     * Deletes an access group by ID.
     * @returns any OK
     * @throws ApiError
     */
    public static deleteV1AdminAccessGroup({
        accessGroupId,
    }: {
        /** Access group ID **/
        accessGroupId: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/access-group/{access_group_id}",
            path: {
                access_group_id: accessGroupId,
            },
        })
    }

    /**
     * Update access group
     * Updates an access group. Set user_ids and segment_ids to null if you don't want to update them.
     * @returns any OK
     * @throws ApiError
     */
    public static patchV1AdminAccessGroup({
        request,
        accessGroupId,
    }: {
        /** Access group data. **/
        request: avy_api_pkg_admin_domain_AccessGroupCreateUpdate
        /** Access group ID **/
        accessGroupId: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PATCH",
            url: "/v1/admin/access-group/{access_group_id}",
            path: {
                access_group_id: accessGroupId,
            },
            body: request,
        })
    }
}
