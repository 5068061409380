import { t } from "@lingui/macro"
import {
    Box,
    InputAdornment,
    Stack,
    styled,
    TextField,
    useTheme,
} from "@mui/material"
import { observer } from "mobx-react"
import React, { useCallback } from "react"

import { Search24 } from "src/components/icons/Search24"

const SearchField = styled(TextField)(({ theme }) => ({
    minWidth: "320px",
    background: theme.palette.common.white,
    [theme.breakpoints.down("sm")]: {
        width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
        width: "320px",
    },
}))

interface IProps {
    query?: string
    onQueryChange?: (query: string) => void
    segmentPicker?: {
        segments: number[]
        onChange: (segments: number[]) => void
    }
    actions?: React.ReactNode
    searchFieldPlaceholder?: string
}

export type IPageFilterProps = IProps

export const PageFilter = observer((props: IProps) => {
    const theme = useTheme()

    const searchFieldInputProps = {
        startAdornment: (
            <InputAdornment
                position="start"
                sx={{ color: theme.palette.grey[900] }}
            >
                <Search24 />
            </InputAdornment>
        ),
        type: "search",
    }

    const onQueryChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            props.onQueryChange != null && props.query != null
                ? props.onQueryChange(event.target.value)
                : null,
        [props],
    )

    return (
        <Stack spacing={2.5}>
            {props.onQueryChange !== undefined && props.query !== undefined && (
                <Box
                    sx={{
                        flexGrow: 1,
                        width: {
                            xs: "100%",
                            sm: "auto",
                        },
                    }}
                >
                    <SearchField
                        value={props.query}
                        placeholder={
                            props.searchFieldPlaceholder ??
                            t`page-filter.search-title`
                        }
                        title={
                            props.searchFieldPlaceholder ??
                            t`page-filter.search-title`
                        }
                        onChange={onQueryChange ?? undefined}
                        size="small"
                        InputProps={searchFieldInputProps}
                    />
                </Box>
            )}
            {props.actions != null && (
                <Stack
                    direction={{
                        xs: "column",
                        sm: "row",
                    }}
                    justifyContent={"flex-start"}
                    alignItems="stretch"
                    gap={1.25}
                    flexWrap="wrap"
                >
                    {props.actions}
                </Stack>
            )}
        </Stack>
    )
})
