import React from "react"

import { ContactFormAdminService, contact_Admin } from "src/api"
import { Channel } from "src/channel"
import { loads } from "src/channel/utils"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { parseDate } from "src/lib/date"

import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"
import { parseStatus } from "src/types/contact-form"
import {
    IAdvanceQueryModel,
    ISingleSelectOptions,
} from "src/types/data-grid-pro"
import { IContactFormItem } from "src/views/contact-forms/type"

export class ContactFormsViewStore implements IDisposable {
    static Context = React.createContext<ContactFormsViewStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "loading", "getAssignees")

    private _accessGroupId: number = DEFAULT_ACCESS_GROUP.id
    private repositoryUpdatesListenerDisposer?: () => void
    private _assignees: ISingleSelectOptions[] = []

    private readValidation = (
        date1: string | undefined,
        date2: string | undefined,
    ) => {
        return Date.parse(date1 as string) > Date.parse(date2 as string)
    }

    contactForms = new Pagination(
        async (params) => {
            const response =
                await ContactFormAdminService.getV1AdminContactForm({
                    pageNumber: params.page,
                    pageSize: params.pageSize,
                    accessGroupId: this.getAccessGroupId(),
                    filter: JSON.stringify(params.advanceQuery),
                })

            const items: IContactFormItem[] = (response.result ?? []).map(
                (item) => ({
                    id: item.contact_form_id ?? -1,
                    read:
                        (item.admin_read_at !== null ||
                            item.admin_read_at !== undefined) &&
                        this.readValidation(
                            item.admin_read_at,
                            item.last_activity_at,
                        ),
                    title: item.title ?? "",
                    object_address: item.object_address ?? "",
                    object_id: item.object_id ?? "",
                    tenant_id: item.tenant_id ?? -1,
                    type_name: item.type_name ?? "",
                    assigned_to: item.assigned_to_name ?? "",
                    implemented: parseDate(item.implementation_date),
                    status: parseStatus(item.status ?? ""),
                    updated_at: parseDate(item.updated_at),
                    created_at: parseDate(item.created_at),
                }),
            )

            return { items, count: response.total_count ?? 0 }
        },
        {
            loadingKey: ContactFormsViewStore.LoadingKeys.loading,
        },
    )

    unreads = {
        chats: true,
        forms: false,
    }

    dispose() {
        this.repositoryUpdatesListenerDisposer?.()
    }

    @loads(() => ContactFormsViewStore.LoadingKeys.init)
    async init(accessGroupId: number, advanceQuery?: IAdvanceQueryModel) {
        this.setAccountGroupId(accessGroupId)
        this.listenToContactFormRepositoryUpdates()
        await this.contactForms.loadInitialPage(advanceQuery)
    }

    @loads(() => ContactFormsViewStore.LoadingKeys.getAssignees)
    async getAssignees() {
        this.setAssignees(
            await ContactFormAdminService.getV1AdminContactFormAdmins({
                propertyId: -1,
            }),
        )
    }

    async query(advanceQuery: IAdvanceQueryModel) {
        await this.contactForms.loadAdvanceQuery(advanceQuery)
    }

    get assignees() {
        return this._assignees
    }

    private setAssignees(data: contact_Admin[]) {
        this._assignees = [...data].map((d) => ({
            value: d.admin_id ?? -1,
            label: d.name ?? "",
        }))
    }

    private setAccountGroupId(accessGroupId: number) {
        this._accessGroupId = accessGroupId
    }

    private listenToContactFormRepositoryUpdates() {
        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "contact-forms"
                ) {
                    await this.contactForms.reload()
                }
            },
        )
    }

    private getAccessGroupId() {
        return this._accessGroupId !== DEFAULT_ACCESS_GROUP.id
            ? this._accessGroupId
            : undefined
    }
}
