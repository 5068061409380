import { t } from "@lingui/macro"
import { useMediaQuery, useTheme } from "@mui/material"
import { observer } from "mobx-react"
import React, { useCallback, useRef } from "react"

import { FileInputButton } from "src/components/AttachmentsField/FileInputButton"
import { Icon } from "src/components/icons"

import { UploadCloud56 } from "src/components/icons/UploadCloud56"

const ACCEPTED_FILE_TYPES = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/svg+xml",
]

const removeImageTypes = (typesToRemove: string[]) => {
    const remainingTypes = ACCEPTED_FILE_TYPES.filter(
        (type) => !typesToRemove.includes(type),
    )
    return remainingTypes.join(",")
}

interface IProps {
    onChange: (files: IFile[]) => void
    aspectRatio?: string
    disabled?: boolean
    removeTypes?: string[]
}

export const UploadImageField = observer((props: IProps) => {
    const inputRef = useRef<HTMLInputElement | null>(null)
    const createdObjectUrls = useRef<string[]>([])

    const theme = useTheme()
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))

    // This useEffect is taking our current fields and revoke object url and indirectly making  issues in preview tab that's why it's commented and if we need in future then this will work
    // useEffect(() => {
    //     const urls = createdObjectUrls.current

    //     return () => {
    //         // Timeout so ImagePreview component can access images before they gets removed by component cleanup

    //         setTimeout(() => {
    //             urls.forEach((url) => {
    //                 URL.revokeObjectURL(url)
    //             })
    //         })
    //     }
    // }, [])

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if ("files" in event.target && event.target.files != null) {
                const files = Array.from(event.target.files).map(
                    (file): IFile => {
                        const url = URL.createObjectURL(file)
                        createdObjectUrls.current.push(url)
                        return { file, url }
                    },
                )
                props.onChange(files)
            }
            event.target.value = ""
        },
        [props],
    )

    return (
        <>
            <FileInputButton
                type="button"
                title={t`attachment-field-component.image-button`}
                disabled={props.disabled}
                onClick={() => inputRef.current?.click()}
                sx={{
                    aspectRatio: props.aspectRatio,
                    height:
                        props.aspectRatio == null
                            ? { xs: "64px", sm: "128px" }
                            : "auto",
                }}
            >
                <Icon icon={<UploadCloud56 />} size={smallScreen ? 32 : 56} />
            </FileInputButton>
            <input
                data-testid="UploadImageField/Input"
                ref={inputRef}
                style={{ display: "none" }}
                type="file"
                onChange={handleChange}
                accept={removeImageTypes(props.removeTypes ?? [])}
                multiple
            />
        </>
    )
})
