/**
 * IOrderable items are ordered based on a numerical `rank` in ascending order.
 */
export type IOrderable = { rank: number }

export const sortListFromResult = <TItem extends IOrderable>(
    list: TItem[],
    dropResult: IDropResult,
) => {
    const items = JSON.parse(JSON.stringify(list)) as TItem[]

    const item = items[dropResult.removedIndex]

    items.splice(dropResult.removedIndex, 1)
    items.splice(dropResult.addedIndex, 0, item)

    return items.map((item, rank) => ({ ...item, rank }))
}
