import { Collapse, ListItem } from "@mui/material"
import { useCallback, useState } from "react"

import { t } from "@lingui/macro"

import { CustomListItem } from "./ListItem"

import {
    CustomListItemButton,
    CustomListItemText,
} from "src/layouts/DefaultLayout/styled"

import { ArrowDropDownRoundedDown24 } from "src/components/icons/ArrowDropDownRoundedDown24"
import { INavigationItemHighGroup } from "src/store/navigation/types"
import { ArrowDropDownRoundedUp24 } from "src/components/icons/ArrowDropDownRoundedUp24"
import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"

interface IProps {
    item: INavigationItemHighGroup
}

/**
 * GroupItemList is a navigation item that has one or more nested child items.
 *
 * This high order section allows to group and divide each element
 *
 * The visibility of these nested items depends on the window.location's
 * pathname for large screen and is user togglable on small screens.
 */

export const GroupItemList = (props: IProps) => {
    const gstore = useStore(GlobalStore)

    const [open, setOpen] = useState(
        !gstore.navigation.getSingleSection(props.item.group),
    )

    const handleRootButtonClick = useCallback(() => {
        setOpen(!open)
        gstore.navigation.setClosedSections(props.item.group)
    }, [gstore.navigation, open, props.item.group])

    return (
        <>
            <ListItem disablePadding>
                <CustomListItemButton
                    title={t({ id: props.item.text })}
                    onClick={handleRootButtonClick}
                >
                    {open ? (
                        <ArrowDropDownRoundedDown24 />
                    ) : (
                        <ArrowDropDownRoundedUp24 />
                    )}
                    <CustomListItemText>
                        {t({ id: props.item.text })}
                    </CustomListItemText>
                </CustomListItemButton>
            </ListItem>
            <Collapse
                component="li"
                in={open}
                unmountOnExit
                sx={{ marginBottom: "10px" }}
            >
                <>
                    {props.item.children.map((child, index) => (
                        <CustomListItem key={index} item={child} />
                    ))}
                </>
            </Collapse>
        </>
    )
}
