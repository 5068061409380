import Dexie, { Table } from "dexie"

export interface ISegmentsDB {
    accessGroupId: string
    segments: ISegmentForSearch[]
}

export default class SegmentsDatabase extends Dexie {
    public segments!: Table<ISegmentsDB, number> // id is number in this case

    public constructor() {
        super("SegmentsDatabase")
        this.version(1).stores({
            segments: "++accessGroupId,segments",
        })
    }
}
