/**
 * This component currently is not designed in a generalized way. It is designed specifically to handle system messages supported by cases-v1(alias contact-forms)
 * There is currently no requirement and knowledge about how other system messages are structured and what they contain.
 */
import { t } from "@lingui/macro"
import { memo, useMemo } from "react"
import { format } from "date-fns"

import {
    Wrapper,
    DivisionLine,
    Bubble,
    SystemMessageLabel,
    SystemMessageLabelBold,
} from "./styled"

import { ContactFormStatus } from "src/types/contact-form"

import { ESystemMessageType } from "src/types/chat-message/systemMessage"
import { IAvyChatSystemMessageProps } from "src/components/Chat/types/systemMessage"

const AvyChatSystemMessage = (props: IAvyChatSystemMessageProps) => {
    const { systemMessage, systemMessageType } = props.message

    // Status translation list
    const statusTranslations = useMemo(
        () => ({
            [ContactFormStatus.Done]: t`contact-form-detail-modal.errand-section.status-done`,
            [ContactFormStatus.InProgress]: t`contact-form-detail-modal.errand-section.status-in-progress`,
            [ContactFormStatus.Sent]: t`contact-form-detail-modal.errand-section.status-sent`,
            [ContactFormStatus.SentFallback]: t`contact-form-detail-modal.errand-section.status-sent-fallback`,
            [ContactFormStatus.Unknown]: t`contact-form-detail-modal.errand-section.status-unknown`,
        }),
        [],
    )

    // this function will select between the available system message cases
    const labelSelector = useMemo(() => {
        switch (systemMessageType) {
            case ESystemMessageType.Status:
                return (
                    <SystemMessageLabel>
                        {t`chat-component.system-message.status-prefix`}{" "}
                        <SystemMessageLabelBold>
                            {
                                statusTranslations[
                                    systemMessage as ContactFormStatus
                                ]
                            }
                        </SystemMessageLabelBold>
                    </SystemMessageLabel>
                )
            case ESystemMessageType.Assignee:
                return (
                    <SystemMessageLabel>
                        <SystemMessageLabelBold>
                            {systemMessage}{" "}
                        </SystemMessageLabelBold>
                        {t`chat-component.system-message.assigned-to`}
                    </SystemMessageLabel>
                )
            case ESystemMessageType.Date:
                return (
                    <SystemMessageLabel>
                        {t`chat-component.system-message.implementation-time-prefix`}{" "}
                        <SystemMessageLabelBold>
                            {`${format(
                                new Date(systemMessage),
                                "yyyy-MM-dd",
                            )} ${format(new Date(systemMessage), "hh:mm")}`}
                        </SystemMessageLabelBold>
                    </SystemMessageLabel>
                )
            case ESystemMessageType.ReporterEdit:
                return (
                    <SystemMessageLabel>
                        {t`chat-component.system-message.reported-edited-case`}
                    </SystemMessageLabel>
                )
            default:
                return
        }
    }, [statusTranslations, systemMessage, systemMessageType])

    return (
        <Wrapper>
            <DivisionLine />
            <Bubble>{labelSelector}</Bubble>
            <DivisionLine />
        </Wrapper>
    )
}

export default memo(
    AvyChatSystemMessage,
    (prevProps, nextProps) =>
        prevProps.message.systemMessageId === nextProps.message.systemMessageId,
)
