import { t } from "@lingui/macro"

import {
    FormControlLabel,
    FormHelperText,
    Radio,
    RadioGroup,
    Stack,
} from "@mui/material"
import { observer } from "mobx-react"

import { DateTimePicker } from "src/components/DateTimePicker"

import { FormFieldSet } from "src/components/FormFieldSet"
import { SegmentPickerField } from "src/components/SegmentPickerField"
import { CampaignDetailStore } from "src/modals/campaign-detail/store"
import { useStore } from "src/store/lib/useStore"

export const PublishSettingsSection = observer(() => {
    const store = useStore(CampaignDetailStore)
    const isEditMode = Boolean(store.getId())

    return (
        <>
            <FormFieldSet
                header={t`campaign-detail-modal.publish-settings-section.resource-header`}
            >
                <SegmentPickerField
                    disabled={Boolean(store.fields.get("status") === "active")}
                    value={store.fields.get("segmentIds")}
                    accessGroupId={store.accessGroupId}
                    onChange={async (segments) => {
                        store.fields.set("segmentIds", segments)
                        store.fields.set("offerId", null)
                        store.fields.set("offerType", "")
                        store.fields.set("offerList", [])
                        await store.getCampaignOfferList()
                    }}
                />
            </FormFieldSet>
            {Boolean(store.fields.error("segmentIds")) && (
                <FormHelperText error margin="dense">
                    {store.fields.error("segmentIds")}
                </FormHelperText>
            )}
            <FormFieldSet
                header={t`campaign-detail-modal.publish-settings-section.publish-date-header`}
            >
                <Stack
                    spacing={2}
                    direction={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                    }}
                >
                    <DateTimePicker
                        label={t`campaign-detail-modal.publish-settings-section.publish-start-label`}
                        onChangePicker={(date) =>
                            store.fields.set("publishAt", date)
                        }
                        value={store.fields.get("publishAt")}
                        minDate={
                            isEditMode
                                ? store.fields.get("publishAt")
                                : new Date()
                        }
                        maxDate={store.fields.get("unpublishAt")}
                        error={Boolean(store.fields.error("publishAt"))}
                        helperText={store.fields.error("publishAt")}
                        inputId="publish-at"
                    />
                    <DateTimePicker
                        label={t`campaign-detail-modal.publish-settings-section.publish-end-label`}
                        onChangePicker={(date) =>
                            store.fields.set("unpublishAt", date)
                        }
                        value={store.fields.get("unpublishAt")}
                        minDate={store.fields.get("publishAt") ?? new Date()}
                        error={Boolean(store.fields.error("unpublishAt"))}
                        helperText={store.fields.error("unpublishAt")}
                        inputId="un-publish-at"
                    />
                    {!Boolean(store.fields.error("unpublishAt")) && <></>}
                </Stack>
            </FormFieldSet>
            <FormFieldSet
                header={t`campaign-detail-modal.publish-settings-section.push-notificaiton-header`}
                description={t`campaign-detail-modal.publish-settings-section.push-notification-description`}
            >
                <RadioGroup
                    value={store.fields.get("sendPushNotification")}
                    onChange={(_, value) => {
                        store.fields.set(
                            "sendPushNotification",
                            value === "true",
                        )
                    }}
                >
                    <FormControlLabel
                        label={t`campaign-detail-modal.publish-settings-section.push-notificaiton-no`}
                        value={false}
                        control={<Radio color="info" />}
                    />
                    <FormControlLabel
                        label={t`campaign-detail-modal.publish-settings-section.push-notificaiton-yes`}
                        value={true}
                        control={<Radio color="info" />}
                    />
                </RadioGroup>
            </FormFieldSet>
        </>
    )
})
