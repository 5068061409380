import { makeAutoObservable } from "mobx"
import React from "react"
import camelcaseKeys from "camelcase-keys"

import {
    admin_ProductResponse,
    admin_GetProviderListResponse,
    admin_ProviderResponse,
    admin_GetProductTypeListResponse,
    admin_GetCategoriesResponse,
    admin_Category,
    admin_GetOrderOutputListResponse,
    admin_OrderOutputResponse,
    admin_GetPricePlanListResponse,
    admin_GetAdminResponse,
    AdminService,
    ProductCatalogueAdminService,
} from "src/api"

import { persistFiles } from "src/lib/file"
import { FormFields } from "src/lib/form-fields"

export class EditProductStore {
    static Context = React.createContext<EditProductStore | null>(null)

    constructor() {
        makeAutoObservable(this)
    }

    async init(productId: number) {
        this.setProviders(await this.fetchProviders())
        this.setProductTypes(await this.fetchProductTypes())
        this.setCategories(await this.fetchCategories())

        if (!isNaN(productId)) {
            this.setProduct(await this.fetchProduct(productId))

            if (this._product.createdBy != null) {
                this.setAdmin(
                    await this.fetchAdmin(this._product.createdBy),
                    "createdBy",
                )
            }

            if (this._product.updatedBy != null) {
                this.setAdmin(
                    await this.fetchAdmin(this._product.updatedBy),
                    "updatedBy",
                )
            }
        }

        if (this._product.providerId != null) {
            this.setOrderOutputs(
                await this.fetchOrderOutputs(this._product.providerId),
            )
        }

        if (this._product.productType != null) {
            this.setPricePlans(
                await this.fetchPricePlans(this._product.productType),
            )
        }

        this.initFormFields()
    }

    /* SNACKBAR */
    private _snackbar = {
        message: "",
    }

    get snackbar() {
        return this._snackbar
    }

    setSnackbar = (value: { message: string }) => {
        this._snackbar = value
    }

    /* ADMINS */
    private _admins = {
        createdBy: { name: "" },
        updatedBy: { name: "" },
    }

    fetchAdmin = async (adminId: number) => {
        try {
            return await AdminService.getV1Admin1({
                adminId,
            })
        } catch {
            return null
        }
    }

    setAdmin = (adminResponse: admin_GetAdminResponse | null, type: string) => {
        if (type != null) {
            const admin = this._admins[type as keyof typeof this._admins]

            if (admin != null) {
                admin.name = adminResponse?.name ?? ""
            }
        }
    }

    get admins() {
        return this._admins
    }

    /* FORM FIELDS */
    formFields = new FormFields<IFormFields>({})

    initFormFields = () => {
        this.formFields.init({
            adminName: this._product.adminName,
            affiliateLink: this._product.affiliateLink,
            categoryId: this._product.categoryId,
            includedInOnboarding: this._product.includedInOnboarding,
            includedInPrice: this._product.includedInPrice,
            mainImageUrl: this._product.mainImageUrl,
            name: this._product.name,
            nextSteps: this._product.nextSteps,
            orderDeliveryProcess: this._product.orderDeliveryProcess,
            orderOutputId: this._product.orderOutputId,
            priceCents: this._product.priceCents,
            priceDescription: this._product.priceDescription,
            pricePlan: this._product.pricePlan,
            priceText: this._product.priceText,
            productDescription: this._product.productDescription,
            productListSubtitle: this._product.productListSubtitle,
            productType: this._product.productType,
            providerId: this._product.providerId,
            publishedIn: this._product.publishedIn,
        })
    }

    /* MAIN IMAGE */
    private _mainImage = {}

    setMainImage = (image: IFile | {}) => {
        this._mainImage = image
    }

    /* PRODUCT */
    private _product: IProduct = {}

    private fetchProduct = async (productId: number) =>
        await ProductCatalogueAdminService.getV1AdminCatalogueProduct1({
            productId,
        })

    private setProduct = (productResponse: admin_ProductResponse) => {
        this._product = {
            ...camelcaseKeys(productResponse),
            createdAt:
                productResponse.created_at != null
                    ? new Date(productResponse.created_at)
                    : undefined,
            updatedAt:
                productResponse.updated_at != null
                    ? new Date(productResponse.updated_at)
                    : undefined,
        }
    }

    get product() {
        return this._product
    }

    private async getProductRequestPayload(accessGroupId: number) {
        if (Object.keys(this._mainImage).length > 0) {
            const images = await persistFiles(
                [this._mainImage as IFile],
                "image",
            )

            if (images.length > 0) {
                this.formFields.set("mainImageUrl", images[0].url)
                this._mainImage = {}
            }
        }

        const { data: formData } = this.formFields
        let accessId =
            accessGroupId !== -1 ? accessGroupId : this.product.accessGroupId

        return {
            access_group_id: accessId ?? -1,
            admin_name: formData.adminName ?? "",
            affiliate_link: formData.affiliateLink,
            category_id: formData.categoryId,
            currency: this.product.currency ?? "SEK",
            included_in_onboarding: formData.includedInOnboarding,
            included_in_price: formData.includedInPrice,
            main_image_url: formData.mainImageUrl,
            name: formData.name,
            next_steps: formData.nextSteps,
            order_delivery_process: formData.orderDeliveryProcess,
            order_output_id: formData.orderOutputId,
            price_cents: formData.priceCents,
            price_description: formData.priceDescription,
            price_plan: formData.pricePlan,
            price_text: formData.priceText,
            product_description: formData.productDescription,
            product_list_subtitle: formData.productListSubtitle,
            product_type: formData.productType,
            provider_id: formData.providerId,
        }
    }
    createProduct = async (accessGroupId: number) => {
        const payload = await this.getProductRequestPayload(accessGroupId)

        let response: admin_ProductResponse = {}

        await this.formFields.catchErrors(async () => {
            response =
                await ProductCatalogueAdminService.postV1AdminCatalogueProduct({
                    request: payload,
                })
        })

        if (response.product_id != null) {
            this.setProduct(response)
            // this.initFormFields()
        }
    }

    updateProduct = async (accessGroupId: number) => {
        let segment = this.formFields.get("publishedIn")
        if (segment === undefined || segment.length <= 0) {
            await this.unpublishProduct()
        }
        const payload = await this.getProductRequestPayload(accessGroupId)
        let response: admin_ProductResponse = {}

        response =
            await ProductCatalogueAdminService.putV1AdminCatalogueProduct({
                productId: this._product.productId ?? 0,
                request: payload,
            })

        if (response.product_id != null) {
            this.setProduct(response)
            this.initFormFields()
        }
    }

    publishProduct = async () => {
        await this.formFields.catchErrors(async () => {
            await ProductCatalogueAdminService.putV1AdminCatalogueProductPublish(
                {
                    productId: Number(this.product.productId),
                    request: {
                        published_in: this.formFields.get("publishedIn"),
                    },
                },
            )
        })

        if (!this.formFields.hasErrors()) {
            this.setProduct(
                await this.fetchProduct(Number(this.product.productId)),
            )
            this.initFormFields()
        }
    }

    unpublishProduct = async () => {
        await this.formFields.catchErrors(async () => {
            await ProductCatalogueAdminService.putV1AdminCatalogueProductPublish(
                {
                    productId: Number(this.product.productId),
                    request: {
                        published_in: [],
                    },
                },
            )
        })
    }

    /* PROVIDERS */
    private _providers: IProvider[] = []

    private fetchProviders = async () =>
        await ProductCatalogueAdminService.getV1AdminCatalogueProvider()

    private setProviders = (
        providersResponse: admin_GetProviderListResponse,
    ) => {
        this._providers =
            providersResponse.providers?.map(
                (provider: admin_ProviderResponse) => camelcaseKeys(provider),
            ) ?? []
    }

    get providers() {
        return this._providers
    }

    /* PRODUCT TYPES */
    private _productTypes: string[] = []

    private fetchProductTypes = async () =>
        await ProductCatalogueAdminService.getV1AdminCatalogueProductType()

    private setProductTypes = (
        providersResponse: admin_GetProductTypeListResponse,
    ) => {
        this._productTypes = providersResponse.product_types ?? []
    }

    get productTypes() {
        return this._productTypes
    }

    /* CATEGORIES */
    private _categories: ICategory[] = []

    private fetchCategories = async () =>
        await ProductCatalogueAdminService.getV1AdminCatalogueCategory()

    private setCategories = (
        categoriesResponse: admin_GetCategoriesResponse,
    ) => {
        this._categories =
            categoriesResponse.categories?.map((category: admin_Category) =>
                camelcaseKeys(category),
            ) ?? []
    }

    get categories() {
        return this._categories
    }

    /* ORDER OUTPUTS */
    private _orderOutputs: IOrderOutput[] = []

    fetchOrderOutputs = async (providerId: number) =>
        await ProductCatalogueAdminService.getV1AdminCatalogueProviderOrderOutput(
            { providerId },
        )

    setOrderOutputs = (
        orderOutputsResponse: admin_GetOrderOutputListResponse,
    ) => {
        this._orderOutputs =
            orderOutputsResponse.order_outputs?.map(
                (orderOutput: admin_OrderOutputResponse) =>
                    camelcaseKeys(orderOutput),
            ) ?? []
    }

    get orderOutputs() {
        return this._orderOutputs
    }

    /* PRICE PLANS */
    private _pricePlans: string[] = []

    fetchPricePlans = async (productType: string) =>
        await ProductCatalogueAdminService.getV1AdminCataloguePricePlan1({
            productType,
        })

    setPricePlans = (pricePlansResponse: admin_GetPricePlanListResponse) => {
        this._pricePlans = pricePlansResponse.price_plans ?? []
    }

    get pricePlans() {
        return this._pricePlans
    }
}

interface IProduct {
    accessGroupId?: number
    adminName?: string
    affiliateLink?: string
    categoryId?: number
    createdAt?: Date
    createdBy?: number
    currency?: string
    editable?: boolean
    includedInOnboarding?: boolean
    includedInPrice?: string
    mainImageUrl?: string
    name?: string
    nextSteps?: string
    orderDeliveryProcess?: string
    orderOutputId?: number
    priceCents?: number
    priceDescription?: string
    pricePlan?: string
    priceText?: string
    productDescription?: string
    productId?: number
    productListSubtitle?: string
    productState?: string
    productType?: string
    propertyOwnerId?: number
    providerId?: number
    publishedIn?: Array<number>
    updatedAt?: Date
    updatedBy?: number
}

interface IProvider {
    contactDetails?: string
    hasTracking?: boolean
    name?: string
    privacyPolicy?: string
    providerId?: number
    termsAndConditions?: string
}

interface ICategory {
    categoryId?: number
    iconUrl?: string
    imageUrl?: string
    name?: string
}

interface IOrderOutput {
    email?: string
    name?: string
    orderOutputId?: number
    providerId?: number
    type?: string
}

export interface IFormFields {
    adminName?: string
    affiliateLink?: string
    categoryId?: number
    includedInOnboarding?: boolean
    includedInPrice?: string
    mainImageUrl?: string
    name?: string
    nextSteps?: string
    orderDeliveryProcess?: string
    orderOutputId?: number
    priceCents?: number
    priceDescription?: string
    pricePlan?: string
    priceText?: string
    productDescription?: string
    productListSubtitle?: string
    productType?: string
    providerId?: number
    publishedIn?: number[]
}
