import { Tooltip, Chip, useTheme, SxProps } from "@mui/material"

import { TooltipContainer } from "src/styledComponents/TooltipContainer"
import { IContentItemsLocationChipsProps } from "src/views/content-items/types/location-chip"

export const LocationChips = ({
    locations,
}: IContentItemsLocationChipsProps) => {
    if (locations?.length <= 1) {
        return (
            <>
                {locations.map((location) => (
                    <UiElement key={location} title={location} />
                ))}
            </>
        )
    }

    const [firstLocation, ...additionalLocations] = locations
    return (
        <>
            <UiElement title={firstLocation} />
            <UiElement
                title={
                    <div>
                        {additionalLocations.map((location) => (
                            <div key={location}>{location}</div>
                        ))}
                    </div>
                }
                label={`+${additionalLocations.length}`}
            />
        </>
    )
}

const UiElement = ({
    title,
    label,
}: {
    title: NonNullable<React.ReactNode>
    label?: string
}) => {
    const { palette } = useTheme()

    const chipStyle: SxProps = {
        backgroundColor: palette.utility.darkBlue.main,
        color: palette.common.white,
    }
    return (
        <TooltipContainer>
            <Tooltip title={title} key={title.toString()}>
                <Chip label={label ?? title} sx={chipStyle} />
            </Tooltip>
        </TooltipContainer>
    )
}
