import { t } from "@lingui/macro"
import {
    Button,
    Chip,
    Fab,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useEffect } from "react"

import { AutoCompleteTextField } from "src/components/AutoCompleteTextField"
import { Add24 } from "src/components/icons/Add24"
import { TagsComponentStore } from "src/components/Tags/store"
import { StyledFormControl } from "src/components/Tags/styled"
import { ITagsComponentProps } from "src/components/Tags/types/tagsProps"
import { TagsModal } from "src/modals/tags"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { ITag } from "src/types/tags/tag"

const Component = observer((props: ITagsComponentProps<ITag>) => {
    const store = useStore(TagsComponentStore)
    const globalStore = useStore(GlobalStore)

    const theme = useTheme()
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))

    useEffect(() => {
        ;(async () => {
            await store.init()
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id])

    const handleCreateNewTag = useCallback(() => {
        globalStore.modals.open(() => <TagsModal variant="slide-up-w600" />, {
            variant: "slide-up-w600",
        })
    }, [globalStore.modals])

    const renderEditableView = () => (
        <Stack direction="row" alignItems="center" mt={2}>
            <StyledFormControl>
                <AutoCompleteTextField
                    options={store.tags.items}
                    defaultValues={store.getTagsFromIds(props.tagIds)}
                    placeholder={t`tags-component.add-tags`}
                    onChange={props.onChange}
                    loading={
                        globalStore.loading.is(
                            TagsComponentStore.LoadingKeys.init,
                        ) ||
                        globalStore.loading.is(
                            TagsComponentStore.LoadingKeys.loading,
                        )
                    }
                />
            </StyledFormControl>
            {smallScreen ? (
                <Fab
                    sx={{ marginLeft: "18px" }}
                    data-testid="create-button"
                    variant="circular"
                    color="primary"
                    size="small"
                    onClick={handleCreateNewTag}
                >
                    <Add24 />
                </Fab>
            ) : (
                <Button
                    sx={{ marginLeft: "8px" }}
                    onClick={handleCreateNewTag}
                    color="info"
                >
                    {t`tags-component.create-new-button`}
                </Button>
            )}
        </Stack>
    )

    const renderUneditableView = () => (
        <Stack direction="row" alignItems="center">
            {props.tagIds !== undefined && props.tagIds.length > 0 ? (
                props.tagIds.map((tagId) => {
                    const tag = store.tags.items.find((t) => t.id === tagId)
                    const name = tag !== undefined ? tag.name : ""
                    return (
                        <Chip data-testid="tag-chip" key={tagId} label={name} />
                    )
                })
            ) : (
                <Typography variant="body2">
                    {t`tags-component.no-tags`}
                </Typography>
            )}
        </Stack>
    )

    return (
        <>
            {globalStore.session.hasAccessToModule("tags")
                ? renderEditableView()
                : renderUneditableView()}
        </>
    )
})

export const TagsComponent = (props: ITagsComponentProps<ITag>) => (
    <StoreProvider Store={TagsComponentStore}>
        <Component {...props} />
    </StoreProvider>
)
