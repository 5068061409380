export const getVideoIdFromYoutubeUrl = (url: string) => {
    const regExp =
        // eslint-disable-next-line no-useless-escape
        /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
    const match = url.match(regExp)
    if (match !== null && match[2].length === 11) {
        return match[2]
    } else {
        return ""
    }
}
