import { IAntIconProps } from "src/layouts/DefaultLayout/Navigation/icons/props"

export const AntIconSelfRegistration = (props: IAntIconProps) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M10 19C10 19.55 9.55 20 9 20H5C4.45 20 4 19.55 4 19C4 18.45 4.45 18 5 18H6.73C5.06 16.54 4 14.4 4 12C4 8.81 5.87 6.07 8.56 4.78C9.23 4.47 10 4.96 10 5.7C10 6.08 9.78 6.42 9.43 6.58C7.41 7.55 6 9.61 6 12C6 13.77 6.78 15.34 8 16.44V15C8 14.45 8.45 14 9 14C9.55 14 10 14.45 10 15V19ZM15 4C14.45 4 14 4.45 14 5V9C14 9.55 14.45 10 15 10C15.55 10 16 9.55 16 9V7.56C17.22 8.66 18 10.23 18 12H20C20 9.6 18.94 7.46 17.27 6H19C19.55 6 20 5.55 20 5C20 4.45 19.55 4 19 4H15ZM20 17V16C20 14.9 19.1 14 18 14C16.9 14 16 14.9 16 16V17C15.45 17 15 17.45 15 18V21C15 21.55 15.45 22 16 22H20C20.55 22 21 21.55 21 21V18C21 17.45 20.55 17 20 17ZM19 17H17V16C17 15.45 17.45 15 18 15C18.55 15 19 15.45 19 16V17Z"
            fill="currentColor"
        />
    </svg>
)
