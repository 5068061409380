export const chatbotConversationsConstants = {
    adminFeedbackReasons: [
        "Wrong sources were used",
        "Incorrect answer from source",
        "Incorrect dispatch",
        "Low quality source",
        "Other",
    ],
    feedbackThumbStatusOptions: [
        { label: "Negative", value: 0 },
        { label: "Positive", value: 1 },
    ],
    tenantFeedbackReasons: [
        "I don't trust the answer",
        "I didn't get enough help",
        "It took too long",
        "I don't like chatting with AI/bots",
        "Other",
    ],
}
