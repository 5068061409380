import { Alert as MUIAlert, Collapse, IconButton, styled } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useEffect } from "react"
import { TransitionGroup } from "react-transition-group"

import { Cross16 } from "src/components/icons/Cross16"
import { ToastFragmentStore } from "src/fragments/toast/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"

const Wrap = styled("aside")(({ theme }) => ({
    padding: "16px",
    position: "fixed",
    bottom: 0,
    right: 0,
    zIndex: theme.zIndex.tooltip,
}))

const Alert = styled(MUIAlert)({ marginTop: "8px" })

const View = observer(() => {
    const vstore = useStore(ToastFragmentStore)

    useEffect(() => vstore.init(), [vstore])

    const createRemoveNotificationClickHandler = useCallback(
        (id: number) => () => vstore.removeNotification(id),
        [vstore],
    )

    return (
        <Wrap>
            <TransitionGroup>
                {vstore.notifications.map((notification) => (
                    <Collapse key={notification.id}>
                        <Alert
                            severity={notification.severity}
                            variant="filled"
                            icon={false}
                            action={
                                <IconButton
                                    size="small"
                                    onClick={createRemoveNotificationClickHandler(
                                        notification.id,
                                    )}
                                    color="inherit"
                                >
                                    <Cross16 />
                                </IconButton>
                            }
                        >
                            {notification.message}
                        </Alert>
                    </Collapse>
                ))}
            </TransitionGroup>
        </Wrap>
    )
})

export const ToastFragment = observer(() => (
    <StoreProvider Store={ToastFragmentStore}>
        <View />
    </StoreProvider>
))
