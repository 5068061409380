import { t } from "@lingui/macro"
import { Alert, Button } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { EmbeddedIntegrationStore } from "./store"

import { useStore } from "src/store/lib/useStore"

import { Form } from "src/components/Form"
import { FormPanel } from "src/components/FormPanel"

import { ModalHeader } from "src/components/ModalHeader"
import { PublishSettingsSection } from "src/modals/embedded-integration/PublishSettingsSection"

import { avy_api_pkg_embed_Segment } from "src/api"
import { CenteredSpinner } from "src/components/CenteredSpinner"
import { useInitializer } from "src/lib/initializer"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useCloseConfirmationForForm } from "src/store/modals/use-close-confirmation-for-form"

interface IProps {
    id?: number
    segments?: avy_api_pkg_embed_Segment[]
}

const EmbeddedIntegration = observer((props: IProps) => {
    const gstore = useStore(GlobalStore)
    const store = useStore(EmbeddedIntegrationStore)

    useCloseConfirmationForForm(store.fields)

    const initialized = useInitializer(
        () =>
            store.init(gstore.session.accessGroupId, props.id, props.segments),
        [store, props, gstore.session.accessGroupId, props.segments],
    )

    const handleSubmit = useCallback(async () => {
        await store.submit()
        if (!store.fields.hasErrors()) {
            gstore.modals.pop()
        }
    }, [store, gstore])

    if (!initialized) {
        return <CenteredSpinner height="100vh" />
    }
    return (
        <Form onSubmit={handleSubmit}>
            <ModalHeader>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={gstore.loading.is(
                        EmbeddedIntegrationStore.LoadingKeys.submit,
                    )}
                >{t`embed-integrations-modal.publish-button`}</Button>
            </ModalHeader>
            {Boolean(store.fields.error("generic")) && (
                <Alert severity="error">{store.fields.error("generic")}</Alert>
            )}
            <FormPanel
                sections={[
                    {
                        header: t`embed-integrations-modal.publish-settings-section.header`,
                        content: <PublishSettingsSection />,
                    },
                ]}
            />
        </Form>
    )
})

export const EmbeddedIntegrationsModal = (props: IProps) => (
    <StoreProvider Store={EmbeddedIntegrationStore}>
        <EmbeddedIntegration {...props} />
    </StoreProvider>
)
