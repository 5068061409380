import { observer } from "mobx-react"
import { IconButton } from "@mui/material"

import { CardBottomContainer, VerticalSeparator } from "./styled"

import { DeleteBinOutlined } from "src/components/icons/DeleteBinOutlined"
import { ContentCopy } from "src/components/icons/ContentCopy"
import { IBottomContainerSectionProps } from "src/components/DynamicCardLayout/types/bottomContainerSectionProps"

export const BottomContainerSection = observer(
    ({
        index,
        hideCopyContentIcon,
        hideBinIcon,
        onDeleteClick,
        onContentCopyClick,
    }: IBottomContainerSectionProps) => {
        return (
            <CardBottomContainer>
                {hideCopyContentIcon === true ? <VerticalSeparator /> : null}
                {hideCopyContentIcon === false ? (
                    <IconButton
                        data-testid="content-copy"
                        size="small"
                        color="inherit"
                        disabled={false}
                        onClick={() => onContentCopyClick(index)}
                    >
                        <ContentCopy />
                    </IconButton>
                ) : null}
                {hideBinIcon === false ? (
                    <IconButton
                        data-testid="remove-card"
                        size="small"
                        color="inherit"
                        disabled={false}
                        onClick={() => onDeleteClick(index)}
                    >
                        <DeleteBinOutlined />
                    </IconButton>
                ) : null}
            </CardBottomContainer>
        )
    },
)
