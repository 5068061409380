import { t } from "@lingui/macro"
import { Alert, Button } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useEffect } from "react"

import { AddContentsSection } from "./AddContentsSection"
import { CampaignImageSection } from "./CampaignImageSection"

import { CenteredSpinner } from "src/components/CenteredSpinner"
import { Form } from "src/components/Form"
import { FormPanel } from "src/components/FormPanel"

import {
    CampaignDetailStore,
    IFormFields,
} from "src/modals/campaign-detail/store"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { ModalHeader } from "src/components/ModalHeader"
import { SettingsSection } from "src/modals/campaign-detail/SettingsSection"
import { PublishSettingsSection } from "src/modals/campaign-detail/PublishSettingsSection"
import { ConnectSubscriptionSection } from "src/modals/campaign-detail/ConnectSubscriptionSection"
import { useInitializer } from "src/lib/initializer"
import { useCloseConfirmationForForm } from "src/store/modals/use-close-confirmation-for-form"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"

interface IProps {
    id?: number
}

const CampaignDetail = observer((props: IProps) => {
    const gstore = useStore(GlobalStore)
    const store = useStore(CampaignDetailStore)

    useCloseConfirmationForForm(store.fields)

    const validateRequiredFields = useCallback(
        (requiredFields: string[]) => {
            for (const field of requiredFields) {
                const requiredField = field as keyof IFormFields
                const formField = store.fields.get(requiredField)

                if (formField == null || formField === "") {
                    store.fields.setError(requiredField, t`errors.required`)
                } else {
                    store.fields.clearError(requiredField)
                }
            }
        },
        [store.fields],
    )

    const initialized = useInitializer(
        () => store.init(gstore.session.accessGroupId, props.id),
        [store, props.id, gstore.session.accessGroupId],
    )

    useEffect(() => {
        if (props.id !== null) {
            trackModuleEvent("Campaign | Campaign Detailed View", {
                [MixpanelProperties.AccessGroupName]:
                    gstore.session.accessGroup?.name,
                [MixpanelProperties.AccessGroupID]:
                    gstore.session.accessGroup?.id,
                [MixpanelProperties.ItemID]: props.id,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id])

    const handleSubmit = useCallback(async () => {
        let requiredFields = [
            "internalName",
            "segmentIds",
            "publishAt",
            "unpublishAt",
            "offerType",
            "header",
            "description",
            "campaignBanner",
        ]
        validateRequiredFields(requiredFields)
        if (store.fields.hasErrors()) {
            return
        }
        await store.submit(false)

        trackModuleEvent("Campaign | Publish Campaign", {
            [MixpanelProperties.AccessGroupName]:
                gstore.session.accessGroup?.name,
            [MixpanelProperties.AccessGroupID]: gstore.session.accessGroup?.id,
            [MixpanelProperties.ItemID]: props.id,
        })

        if (!store.fields.hasErrors()) {
            gstore.modals.pop()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store, gstore, validateRequiredFields])

    const handleDraft = useCallback(async () => {
        let requiredFields = ["internalName"]

        validateRequiredFields(requiredFields)
        if (store.fields.hasErrors()) {
            return
        }

        await store.submit(true)
        trackModuleEvent("Campaign | Campaign Save Draft", {
            [MixpanelProperties.AccessGroupName]:
                gstore.session.accessGroup?.name,
            [MixpanelProperties.AccessGroupID]: gstore.session.accessGroup?.id,
            [MixpanelProperties.ItemID]: props.id,
        })

        if (!store.fields.hasErrors()) {
            gstore.modals.pop()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store, gstore, validateRequiredFields])

    if (
        !initialized ||
        gstore.loading.is(CampaignDetailStore.LoadingKeys.init)
    ) {
        return <CenteredSpinner height="100vh" />
    }
    const isButtonDisabled =
        gstore.loading.is(CampaignDetailStore.LoadingKeys.submit) ||
        store.fields.getIsDirty() === false

    return (
        <Form onSubmit={handleSubmit}>
            <ModalHeader>
                <div>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={handleDraft}
                        type="button"
                        style={{ marginRight: 16 }}
                        disabled={isButtonDisabled}
                    >
                        {t`campaign-detail-modal.save-as-draft`}
                    </Button>
                    <Button
                        data-testid="campaign-create/edit-modal-submit-button"
                        type="submit"
                        variant="contained"
                        disabled={isButtonDisabled}
                    >{t`campaign-detail-modal.save-button`}</Button>
                </div>
            </ModalHeader>
            {Boolean(store.fields.error("generic")) && (
                <Alert severity="error">{store.fields.error("generic")}</Alert>
            )}
            <FormPanel
                sections={[
                    {
                        header: t`campaign-detail-modal.settings-section.header`,
                        content: <SettingsSection />,
                    },
                ]}
            />
            <FormPanel
                sections={[
                    {
                        header: t`campaign-detail-modal.publish-settings-section.header`,
                        content: <PublishSettingsSection />,
                    },
                ]}
            />
            <FormPanel
                sections={[
                    {
                        header: t`campaign-detail-modal.connect-subscription.header`,
                        content: <ConnectSubscriptionSection />,
                    },
                ]}
            />
            <FormPanel
                sections={[
                    {
                        header: t`campaign-detail-modal.add-content-section.header`,
                        content: <AddContentsSection />,
                    },
                    {
                        header: t`campaign-detail-modal.add-content-section.image-header`,
                        content: <CampaignImageSection />,
                    },
                ]}
            />
        </Form>
    )
})

export const CampaignDetailModal = (props: IProps) => (
    <StoreProvider Store={CampaignDetailStore}>
        <CampaignDetail {...props} />
    </StoreProvider>
)
