import { Typography } from "@mui/material"
import { observer } from "mobx-react"

interface IProps {
    text: string
    max: number
}

/**
 * TruncatedText truncates the provided `text` at `max` length and adds "..." at the end.
 */
export const TruncatedText = observer((props: IProps) => {
    const suffix = "..."

    if (props.text.length <= props.max) {
        return <Typography title={props.text}>{props.text}</Typography>
    }

    const truncated =
        props.text.substring(0, props.max - suffix.length) + suffix

    return (
        <Typography
            title={props.text}
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
        >
            {truncated}
        </Typography>
    )
})
