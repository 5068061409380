import { t } from "@lingui/macro"
import {
    Alert,
    Button,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material"
import {
    memo,
    SyntheticEvent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react"
import { Refresh } from "@material-ui/icons"

import { CenteredSpinner } from "src/components/CenteredSpinner"
import { Open } from "src/components/icons/Open"
import { IPreviewProps, IPreviewTabId } from "src/components/Preview/types"
import { Tabs } from "src/components/Tabs"
import { RightSideStatusBar } from "src/components/Preview/assets/rightSideStatusBar"
import { LeftSideStatusBar } from "src/components/Preview/assets/leftSideStatusBar"
import "./style.css"
import { Icon } from "src/components/icons"
import { Cross16 } from "src/components/icons/Cross16"
import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"

export interface IPreviewButtonProps {
    icon: JSX.Element
    label: string
    onClick: () => void
    disabled: boolean
}

export const AvyPreview = (props: IPreviewProps) => {
    const [selectedTabId, setSelectedTabId] = useState<IPreviewTabId>(
        IPreviewTabId.Detail,
    )
    const [detailsUrl, setDetailsUrl] = useState("")
    const [listUrl, setListUrl] = useState("")

    const theme = useTheme()
    const gstore = useStore(GlobalStore)
    const smallScreen = useMediaQuery(theme.breakpoints.down("lg"))

    const handleRefresh = useCallback(async () => {
        switch (selectedTabId) {
            case IPreviewTabId.Detail:
                props.details !== undefined &&
                    setDetailsUrl(await props.details())
                break

            case IPreviewTabId.List:
                props.list !== undefined && setListUrl(await props.list())
                break
        }
    }, [props, selectedTabId])

    useEffect(() => {
        ;(async () => {
            await handleRefresh()
        })()
    }, [handleRefresh])

    const tabsData = useMemo(
        () => [
            {
                label: t`preview-modal.detail`,
                id: IPreviewTabId.Detail,
                view: (
                    <PreviewIFrameView
                        url={detailsUrl}
                        onRefresh={handleRefresh}
                    />
                ),
            },
            {
                label: t`preview-modal.list`,
                id: IPreviewTabId.List,
                view: (
                    <PreviewIFrameView
                        url={listUrl}
                        onRefresh={handleRefresh}
                    />
                ),
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [detailsUrl, listUrl],
    )

    const handleTabChange = (_: SyntheticEvent, id: string) => {
        setSelectedTabId(id as IPreviewTabId)
    }

    if (props.details !== undefined && props.list === undefined) {
        return <PreviewIFrameView url={detailsUrl} onRefresh={handleRefresh} />
    }

    if (props.details === undefined && props.list !== undefined) {
        return <PreviewIFrameView url={listUrl} onRefresh={handleRefresh} />
    }

    if (props.details === undefined && props.list === undefined) {
        return <Typography>{t`global.no-content`}</Typography>
    }

    return (
        <>
            {smallScreen && (
                <Stack
                    sx={{ pb: 1, pt: 1 }}
                    justifyContent="end"
                    direction="row"
                >
                    <Button
                        data-testid="close-button"
                        startIcon={
                            <Icon
                                icon={<Cross16 />}
                                size={20}
                                color={theme.palette.info.main}
                            />
                        }
                        color="info"
                        onClick={() => gstore.modals.popPreview()}
                    />
                </Stack>
            )}
            <Tabs
                data={tabsData}
                handleTabChange={handleTabChange}
                selectedTabId={selectedTabId}
                tabListStyles={{ padding: 0 }}
                tabContentStyles={{ flex: 1 }}
                variant="fullWidth"
            />
        </>
    )
}

const PreviewButton = ({
    icon,
    label,
    onClick,
    disabled,
}: IPreviewButtonProps) => (
    <Button
        startIcon={icon}
        size="small"
        variant="outlined"
        color="primary"
        onClick={onClick}
        disabled={disabled}
    >
        {label}
    </Button>
)

const PreviewIFrameView = memo(
    ({ url, onRefresh }: { url: string; onRefresh: () => void }) => {
        const theme = useTheme()
        const [isLoading, setIsLoading] = useState(true)

        const isError = !isLoading && url === ""
        const disabled = isError || isLoading

        const btnColor = disabled
            ? theme.palette.grey[500]
            : theme.palette.primary.main

        return (
            <Stack spacing={3} direction="column">
                <Alert
                    icon={false}
                    severity={isError ? "error" : "info"}
                    sx={{ justifyContent: "center" }}
                >
                    <Typography variant="body2" textAlign="center">
                        {isError
                            ? t`global.render-error`
                            : t`preview-modal-note`}
                    </Typography>
                </Alert>
                <Stack
                    justifyContent="space-between"
                    direction="row"
                    spacing={2}
                    style={{ marginRight: "1rem", marginLeft: "1rem" }}
                >
                    <PreviewButton
                        icon={<Refresh style={{ color: btnColor }} />}
                        label={t`preview-modal.refresh`}
                        onClick={onRefresh}
                        disabled={disabled}
                    />
                    <PreviewButton
                        icon={<Open style={{ color: btnColor }} />}
                        label={t`preview-modal.open-responsive-design`}
                        onClick={() => window.open(url, "_blank")}
                        disabled={disabled}
                    />
                </Stack>
                {isLoading && <CenteredSpinner height="100vh" />}
                {url !== "" && (
                    <Stack alignItems={"center"}>
                        <div className="iphone-frame">
                            <div className="iphone-notch" />
                            <div className="left-buttons">
                                {[...Array(3)].map((_, i) => (
                                    <div className="button" key={i} />
                                ))}
                            </div>
                            <div className="right-button" />
                            <div className="status-bar">
                                <LeftSideStatusBar />
                                <RightSideStatusBar />
                            </div>
                            <div className="iphone-screen">
                                <iframe
                                    title="Preview"
                                    width="100%"
                                    height="100%"
                                    src={url}
                                    style={{ border: 0 }}
                                    onLoad={() => setIsLoading(false)}
                                />
                            </div>
                        </div>
                    </Stack>
                )}
            </Stack>
        )
    },
    (prevProps, nextProps) => prevProps.url === nextProps.url,
)
