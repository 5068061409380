import React from "react"
import { makeAutoObservable } from "mobx"
import { t } from "@lingui/macro"

import { ChatbotAdminService } from "src/api/services/ChatbotAdminService"
import { reportUnhandledApiError } from "src/lib/report"
import {
    EMessageSender,
    EMessageType,
    IChatMessage,
} from "src/types/chat-message/chatMessage"
import {
    ISystemMessage,
    ISystemMessageAPIResponse,
} from "src/types/chat-message/systemMessage"
import { chatbot_Message } from "src/api"
import { Channel } from "src/channel"

export class CasesMessagesStore {
    static Context = React.createContext<CasesMessagesStore | null>(null)

    //#region init variable
    initialized = false
    messages: IChatMessage[] = []
    //#endregion

    constructor() {
        makeAutoObservable(this)
    }

    //#region setters
    setMessages(messages: IChatMessage[]) {
        this.messages = messages
    }
    private setInitialized() {
        this.initialized = true
    }
    //#endregion

    //#region store operations
    async init(conversationId: string) {
        await this.loadMessages(conversationId)
    }

    loadMessages = async (conversationId: string) => {
        try {
            const messagesResponse =
                await ChatbotAdminService.getV1AdminChatbotConversationsMessages(
                    {
                        conversationId,
                    },
                )

            const newMessagesFormat = messagesResponse.map(
                (message): IChatMessage => {
                    const isAdmin = message?.role === "ADMIN"
                    const sentByUser = message?.role === "HUMAN"
                    return {
                        authorType: message?.role ?? "",
                        authorName: isAdmin
                            ? t`chatbot-view.modal.chat.admin`
                            : sentByUser
                            ? t`chatbot-view.modal.chat.tenant`
                            : t`chatbot-view.modal.chat.chatbot`,
                        createdAt: message?.created_at ?? "",
                        messageId: message?.message_id ?? "",
                        text: message?.message ?? "",
                        source: message?.sources ?? [],
                        messageSender: isAdmin
                            ? EMessageSender.Admin
                            : sentByUser
                            ? EMessageSender.User
                            : EMessageSender.Chatbot,
                        messageType: message?.message_type ?? undefined,
                        nodeInfo: message?.node_info ?? undefined,
                        sourceEval: message?.source_eval ?? undefined,
                        systemMessage: this.getSystemMessage(message),
                        systemMessageId: message?.message_id ?? "",
                    }
                },
            )

            this.setMessages(newMessagesFormat ?? [])
            this.setInitialized()
        } catch (error) {
            reportUnhandledApiError(error)
        }
    }

    getSystemMessage = (
        message: chatbot_Message,
    ): ISystemMessage | undefined => {
        if (message.message_type === EMessageType.SystemMessageV2) {
            const messageText: ISystemMessageAPIResponse | undefined =
                message.message !== undefined
                    ? (JSON.parse(message.message) as ISystemMessageAPIResponse)
                    : undefined

            return messageText !== undefined
                ? {
                      systemMessage: messageText.parameters.new_value,
                      systemMessageId: message?.message_id ?? "",
                      systemMessageType: messageText.system_message_type,
                  }
                : messageText
        }
    }

    async submitCommentForm(
        conversationId: string,
        images: IPersistedFile[],
        text: string,
    ) {
        try {
            let imagesString: string = `${text} \n\n`
            images?.forEach((image: IPersistedFile) => {
                imagesString += `${image.url}`
            })
            await ChatbotAdminService.postV1AdminChatbotConversationsMessage({
                conversationId: conversationId,
                request: {
                    message: imagesString,
                },
            })
            await this.init(conversationId as string)
        } catch (e) {
            throw e
        }
    }

    updateChannel = (id: number, conversationId: string) => {
        Channel.send({
            name: "repository/updated",
            payload: {
                repository: "cases-v2",
                action: "update",
                item: { id, name: conversationId },
            },
        })
    }
    //#endregion
}
