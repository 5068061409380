import { t } from "@lingui/macro"
import { memo } from "react"
import { Typography, Box, Tooltip, Stack } from "@mui/material"
import { InfoRounded } from "@mui/icons-material"

export const AnalysisHeader = memo(() => (
    <Box sx={{ marginBottom: "16px" }}>
        <Typography
            variant="subtitle2"
            sx={{ display: "flex", alignItems: "center" }}
        >
            {t`chat-message-journey.analysis.text`}
            <Tooltip
                title={t`chat-message-journey.analysis.chatgpt-written-analysis`}
                placement="right"
                arrow
            >
                <Stack
                    component="span"
                    alignItems="center"
                    justifyContent="center"
                >
                    <InfoRounded
                        fontSize="small"
                        color="action"
                        sx={{ marginLeft: 0.5 }}
                    />
                </Stack>
            </Tooltip>
        </Typography>
    </Box>
))
