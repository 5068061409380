/* eslint-disable import/no-relative-parent-imports */
import { t } from "@lingui/macro"
import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material"
import { observer } from "mobx-react"

import { useEffect } from "react"

import { ResourceDetailStore } from "./store"

import { FormFieldSet } from "src/components/FormFieldSet"
import { useStore } from "src/store/lib/useStore"

import { IntegrationConnector } from "src/modals/resource-detail/components/IntegrationConnector"
import { ResourceType } from "src/types/resource"

export const ResourceSettingsSection = observer(() => {
    const store = useStore(ResourceDetailStore)
    const disabled =
        !store.isCreatingResource || store.fields.get("accessType") === "READ"

    const resourceTypeSelectedValue = store.fields.get(
        "resourceTypeSelectedValue",
    )
    useEffect(() => {
        ;(async () => {
            if (resourceTypeSelectedValue === ResourceType.Integration)
                await store.getBookingsPortalIntegrations()
        })()
    }, [store, resourceTypeSelectedValue])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        store.fields.set(
            "resourceTypeSelectedValue",
            (event.target as HTMLInputElement).value as ResourceType,
        )
        store.resetDigitalAccessSection()
    }
    return (
        <>
            <FormFieldSet
                header={""}
                description={t`resource-detail-modal.resource-type`}
            >
                <RadioGroup
                    value={store.fields.get("resourceTypeSelectedValue")}
                    onChange={handleChange}
                >
                    <FormControlLabel
                        label={
                            <Typography variant="body1">
                                {t`resource-detail-modal.booking-engine`}
                            </Typography>
                        }
                        value={ResourceType.BookingEngine}
                        disabled={disabled}
                        control={<Radio color="info" />}
                    />
                    <FormControlLabel
                        label={
                            <Typography variant="body1">
                                {t`resource-detail-modal.integration`}
                            </Typography>
                        }
                        value={ResourceType.Integration}
                        disabled={disabled}
                        control={<Radio color="info" />}
                    />
                </RadioGroup>
                {store.fields.get("resourceTypeSelectedValue") !==
                    ResourceType.BookingEngine && <IntegrationConnector />}
            </FormFieldSet>
        </>
    )
})
