import { Grid, Stack } from "@mui/material"
import { observer } from "mobx-react"

import {
    Header,
    StyledGrid,
    SubHeader,
    Wrap,
} from "src/components/FormPanel/styled"
import { IFormPanelProps } from "src/components/FormPanel/types/form-panel"
import { ISection } from "src/components/FormPanel/types/section"

export const FormPanel = observer((props: IFormPanelProps) => {
    const renderHeader = (section: ISection) =>
        section.headerStatusChip !== undefined ? (
            <StyledGrid container>
                <Grid item>
                    <Header>{section.header}</Header>
                </Grid>
                <Grid item>{section.headerStatusChip}</Grid>
            </StyledGrid>
        ) : (
            <Header>{section.header}</Header>
        )

    return (
        <Wrap>
            <Stack spacing={4}>
                {props.sections.map((section) => (
                    <Stack key={section.header} spacing={3}>
                        <Stack spacing={1}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                {renderHeader(section)}
                                {section.rightComponent}
                            </div>

                            {section.subHeader != null && (
                                <SubHeader>{section.subHeader}</SubHeader>
                            )}
                        </Stack>
                        {section.content}
                    </Stack>
                ))}
            </Stack>
        </Wrap>
    )
})
