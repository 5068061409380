export interface ILikeAuthor {
    authorName?: string
    objectId: number
    objectType: "admin_user" | "tenant" | "none"
}

export interface IWithAuthor {
    author_name?: string
    author?: {
        author_name?: string
    }
}

export function getAuthorName(item: IWithAuthor) {
    if (item.author_name != null && item.author_name !== "") {
        return item.author_name
    }
    return item.author?.author_name ?? ""
}
