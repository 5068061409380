import { observer } from "mobx-react"

import { Fab, Stack } from "@mui/material"

import { t } from "@lingui/macro"

import { Tabs } from "src/components/Tabs"

import { useStore } from "src/store/lib/useStore"
import { FabButtonContainer } from "src/modals/embedded-integration-v2/styled"
import { Add24 } from "src/components/icons/Add24"
import { initialGeneralDynamicFieldsState } from "src/modals/embedded-integration-v2/constants/initialFieldState"
import { EmbeddedIntegrationGeneralTabSection } from "src/modals/embedded-integration-v2/sections/ContentSection/Tabs/GeneralTabScreen"
import { ITabId } from "src/modals/embedded-integration-v2/types/tabId"
import { EmbeddedIntegrationSalesTabSection } from "src/modals/embedded-integration-v2/sections/ContentSection/Tabs/SalesTabScreen"
import { TabWrapper } from "src/modals/embedded-integration-v2/sections/ContentSection/styled"
import { EmbeddedIntegrationPurchaseTabSection } from "src/modals/embedded-integration-v2/sections/ContentSection/Tabs/PurchaseTabScreen"
import { ContentSectionStore } from "src/modals/embedded-integration-v2/sections/ContentSection/store"
import { EmbeddedIntegrationStore } from "src/modals/embedded-integration-v2/store"
import { EmbeddedIntegrationSettingType } from "src/modals/embedded-integration-v2/types/contentDynamicFields"

const EmbedIntegrationContentSection = observer(() => {
    const contentStore = useStore(ContentSectionStore)
    const store = useStore(EmbeddedIntegrationStore)

    const generalDynamicFieldsList = contentStore.generalTabFields

    const handleTabChange = (_event: React.SyntheticEvent, id: string) => {
        contentStore.setSelectedTabId(id)
    }
    const handleRemoveClick = (index: number) => {
        let list = [...generalDynamicFieldsList]
        if (list.length > 1) {
            list.splice(index, 1)
            contentStore.fields.set("generalDynamicFieldsList", list)
        }
    }

    const handleContentCopyClick = (index: number) => {
        let list = [...generalDynamicFieldsList]
        list.splice(list.length, 0, list[index])
        contentStore.fields.set("generalDynamicFieldsList", list)
    }
    const handleAddClick = () => {
        contentStore.fields.set("generalDynamicFieldsList", [
            ...generalDynamicFieldsList,
            initialGeneralDynamicFieldsState,
        ])
    }
    const salesTabObject = {
        label: t`embed-integrations-modal.publish-content-section.tab-sales`,
        id: ITabId.SALES,
        view: (
            <TabWrapper>
                <EmbeddedIntegrationSalesTabSection />
            </TabWrapper>
        ),
    }
    const purchaseTabObject = {
        label: t`embed-integrations-modal.publish-content-section.tab-purchase`,
        id: ITabId.PURCHASE,
        view: (
            <TabWrapper>
                <EmbeddedIntegrationPurchaseTabSection />
            </TabWrapper>
        ),
    }
    const tabsData = [
        {
            label: t`embed-integrations-modal.publish-content-section.tab-general`,
            id: ITabId.GENERAL,
            view: (
                <Stack spacing={1}>
                    {generalDynamicFieldsList?.map((item, index) => (
                        <EmbeddedIntegrationGeneralTabSection
                            index={index}
                            categoryFields={item.categoryFields}
                            onRemoveClick={handleRemoveClick}
                            onContentCopyClick={handleContentCopyClick}
                        />
                    ))}
                    <FabButtonContainer>
                        <Fab
                            variant="extended"
                            color="primary"
                            size="medium"
                            disabled={false}
                            onClick={handleAddClick}
                        >
                            <Add24 />
                            {t`embed-integrations-modal.publish-content-section.general-tab.add-category`}
                        </Fab>
                    </FabButtonContainer>
                </Stack>
            ),
        },
    ]
    const getTabsObject = (showSalesTab: boolean) => {
        switch (showSalesTab) {
            case true:
                if (
                    store.fields.data.type ===
                    EmbeddedIntegrationSettingType.Subscription
                ) {
                    return [...tabsData, salesTabObject, purchaseTabObject]
                } else {
                    return [...tabsData, salesTabObject]
                }

            case false:
                if (
                    store.fields.data.type ===
                    EmbeddedIntegrationSettingType.Subscription
                ) {
                    return [...tabsData, purchaseTabObject]
                } else {
                    return tabsData
                }
        }
    }
    return (
        <>
            <Tabs
                data={getTabsObject(store.fields.data.useSalesPage ?? false)}
                selectedTabId={contentStore.selectedTabId}
                handleTabChange={handleTabChange}
                tabListStyles={{
                    padding: 2,
                }}
                tabContentStyles={{
                    flex: 1,
                }}
            />
        </>
    )
})

export const EmbeddedIntegrationContentSection = () => (
    <EmbedIntegrationContentSection />
)
