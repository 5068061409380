import { t } from "@lingui/macro"
import { Alert, Button } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useEffect, useState } from "react"

import { MixpanelProperties } from "src/analytics/constants/properties"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { Form } from "src/components/Form"
import { ModalHeader } from "src/components/ModalHeader"
import { LibraryInformationItemsContentSection } from "src/modals/library-information-items/sections/Content"
import { LibraryInformationItemsPublishSettingsSection } from "src/modals/library-information-items/sections/PublishSettings"
import { LibraryInformationItemsSettingsSection } from "src/modals/library-information-items/sections/Settings"
import { LibraryInformationItemStore } from "src/modals/library-information-items/store"
import { ILibraryInformationItemModalProps } from "src/modals/library-information-items/types/libraryInformationItemProps"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"

const Modal = observer((props: ILibraryInformationItemModalProps) => {
    const store = useStore(LibraryInformationItemStore)
    const globalStore = useStore(GlobalStore)

    const [thisModal] = useState(
        globalStore.modals.active[globalStore.modals.active.length - 1],
    )
    thisModal.confirmOnOverlayClick = () => store.formFields.getIsDirty()

    useEffect(() => {
        const isSuperUser = globalStore.session.user?.isSuper === true
        if (props.informationItem !== undefined) {
            trackModuleEvent("Library | Detailed view", {
                [MixpanelProperties.ItemID]: props.informationItem.id,
                [MixpanelProperties.AccessGroupName]:
                    globalStore.session.accessGroup?.name,
                [MixpanelProperties.AccessGroupID]:
                    globalStore.session.accessGroup?.id,
            })
        }

        store.init(isSuperUser, props.informationItem)
    }, [store, props.informationItem, globalStore.session])

    const handleSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            store.setIsLoading(true)

            store.validateRequiredFields([
                "category",
                "internalName",
                "nameInApp",
                "type",
                "accessGroupId",
                store.formFields.get("type") ?? "",
            ])

            if (store.formFields.hasErrors() === true) {
                store.setIsLoading(false)
                return
            }
            trackModuleEvent("Library | Save", {
                [MixpanelProperties.ItemName]: store.formFields.data.nameInApp,
                [MixpanelProperties.ShowInApp]: store.formFields.data.showInApp,
                [MixpanelProperties.UsedByChatbot]:
                    store.formFields.data.includeInChatbot,
                [MixpanelProperties.AccessGroupName]:
                    globalStore.session.accessGroup?.name,
                [MixpanelProperties.AccessGroupID]:
                    globalStore.session.accessGroup?.id,
            })
            if (props.informationItem == null) {
                await store.createItem()
            } else {
                await store.updateItem(props.informationItem)
            }

            if (!Boolean(store.formFields.error("generic"))) {
                globalStore.modals.pop()
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [globalStore.modals, props.informationItem, store],
    )

    const isButtonDisabled =
        store.formFields.get("accessType") === "READ" ||
        store.formFields.getIsDirty() === false ||
        store.isLoading

    return (
        <Form onSubmit={handleSubmit}>
            <>
                <ModalHeader>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={isButtonDisabled}
                        data-testid="submit-item-form"
                    >
                        {t`global.save`}
                    </Button>
                </ModalHeader>
                {Boolean(store.formFields.error("generic")) && (
                    <Alert severity="error">
                        {store.formFields.error("generic")}
                    </Alert>
                )}
                <LibraryInformationItemsSettingsSection
                    id={props.informationItem?.information_item_id ?? 0}
                />
                <LibraryInformationItemsContentSection />
                <LibraryInformationItemsPublishSettingsSection />
            </>
        </Form>
    )
})

export const LibraryInformationItemModal = (
    props: ILibraryInformationItemModalProps,
) => (
    <StoreProvider Store={LibraryInformationItemStore}>
        <Modal {...props} />
    </StoreProvider>
)
