import { CloseRounded } from "@mui/icons-material"
import { Stack } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import {
    ChatInputCell,
    ChatInputDeleteButton,
    ChatInputImage,
} from "src/components/Chat/Input/ImageList/styled"
import { AvyChatInputStore } from "src/components/Chat/Input/store"

import { useStore } from "src/store/lib/useStore"

export const ChatInputImageList = observer(
    ({ isSubmitting }: { isSubmitting: boolean }) => {
        const store = useStore(AvyChatInputStore)

        const createDeleteClickHandler = useCallback(
            (index: number) => () => {
                store.deleteImageAtIndex(index)
            },
            [store],
        )

        return (
            <Stack direction="row" spacing={1}>
                {store.chatInputForm.get("images").map((image, index) => (
                    <ChatInputCell key={image.url}>
                        <ChatInputImage src={image.url} alt={image.file.name} />
                        <ChatInputDeleteButton
                            onClick={createDeleteClickHandler(index)}
                            disabled={isSubmitting}
                            type="button"
                        >
                            <CloseRounded fontSize="inherit" />
                        </ChatInputDeleteButton>
                    </ChatInputCell>
                ))}
            </Stack>
        )
    },
)
