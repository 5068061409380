import { IIconProps } from "src/components/icons"

export const Object24 = (props: IIconProps) => (
    <svg
        width={18}
        height={18}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M18 6.4959C17.9999 6.124 17.816 5.77616 17.5088 5.56665L9.63375 0.197405C9.25224 -0.0658016 8.74776 -0.0658016 8.36625 0.197405L0.49125 5.56665C0.183968 5.77616 8.54427e-05 6.124 0 6.4959V16.8752C0 17.4965 0.50368 18.0002 1.125 18.0002H5.625C6.03921 18.0002 6.375 17.6644 6.375 17.2502C6.375 16.8359 6.03921 16.5002 5.625 16.5002H1.875C1.66789 16.5002 1.5 16.3323 1.5 16.1252V6.89265C1.49997 6.76873 1.56117 6.6528 1.6635 6.5829L8.7885 1.7244C8.91605 1.63729 9.08395 1.63729 9.2115 1.7244L16.3365 6.5829C16.4388 6.6528 16.5 6.76873 16.5 6.89265V16.1252C16.5 16.3323 16.3321 16.5002 16.125 16.5002H12.375C11.9608 16.5002 11.625 16.8359 11.625 17.2502C11.625 17.6644 11.9608 18.0002 12.375 18.0002H16.875C17.1734 18.0002 17.4595 17.8816 17.6705 17.6707C17.8815 17.4597 18 17.1735 18 16.8752V6.4959Z"
            fill="currentColor"
        />
        <circle cx={9} cy={6.375} r={1.875} fill="currentColor" />
        <path
            d="M11.625 14.2502C11.8321 14.2502 12 14.0823 12 13.8752V12.0752C12 10.4183 10.6569 9.0752 9 9.0752C7.34315 9.0752 6 10.4183 6 12.0752V13.8752C6 14.0823 6.16789 14.2502 6.375 14.2502H7.1865C7.28171 14.2503 7.36187 14.3214 7.37325 14.4159L7.75275 17.6687C7.77493 17.8587 7.93651 18.0015 8.12775 18.0002H9.87825C10.0695 18.0015 10.2311 17.8587 10.2533 17.6687L10.6328 14.4159C10.6438 14.3236 10.7206 14.2532 10.8135 14.2502H11.625Z"
            fill="currentColor"
        />
        <path
            d="M18 6.4959C17.9999 6.124 17.816 5.77616 17.5088 5.56665L9.63375 0.197405C9.25224 -0.0658016 8.74776 -0.0658016 8.36625 0.197405L0.49125 5.56665C0.183968 5.77616 8.54427e-05 6.124 0 6.4959V16.8752C0 17.4965 0.50368 18.0002 1.125 18.0002H5.625C6.03921 18.0002 6.375 17.6644 6.375 17.2502C6.375 16.8359 6.03921 16.5002 5.625 16.5002H1.875C1.66789 16.5002 1.5 16.3323 1.5 16.1252V6.89265C1.49997 6.76873 1.56117 6.6528 1.6635 6.5829L8.7885 1.7244C8.91605 1.63729 9.08395 1.63729 9.2115 1.7244L16.3365 6.5829C16.4388 6.6528 16.5 6.76873 16.5 6.89265V16.1252C16.5 16.3323 16.3321 16.5002 16.125 16.5002H12.375C11.9608 16.5002 11.625 16.8359 11.625 17.2502C11.625 17.6644 11.9608 18.0002 12.375 18.0002H16.875C17.1734 18.0002 17.4595 17.8816 17.6705 17.6707C17.8815 17.4597 18 17.1735 18 16.8752V6.4959Z"
            fill="currentColor"
        />
        <circle cx={9} cy={6.375} r={1.875} fill="currentColor" />
        <path
            d="M11.625 14.2502C11.8321 14.2502 12 14.0823 12 13.8752V12.0752C12 10.4183 10.6569 9.0752 9 9.0752C7.34315 9.0752 6 10.4183 6 12.0752V13.8752C6 14.0823 6.16789 14.2502 6.375 14.2502H7.1865C7.28171 14.2503 7.36187 14.3214 7.37325 14.4159L7.75275 17.6687C7.77493 17.8587 7.93651 18.0015 8.12775 18.0002H9.87825C10.0695 18.0015 10.2311 17.8587 10.2533 17.6687L10.6328 14.4159C10.6438 14.3236 10.7206 14.2532 10.8135 14.2502H11.625Z"
            fill="currentColor"
        />
    </svg>
)
