import { t } from "@lingui/macro"
import { Grid, Stack, TextField } from "@mui/material"
import { observer } from "mobx-react"

import { FormFieldSet } from "src/components/FormFieldSet"
import { ImageUploadField } from "src/components/ImageUploadField"
import { FeatureConfigurationDetailStore } from "src/modals/feature-configuration-detail/store"
import { useStore } from "src/store/lib/useStore"

export const CustomerBrandingSection = observer(() => {
    const store = useStore(FeatureConfigurationDetailStore)

    return (
        <>
            <FormFieldSet
                header={t`feature-configuration-detail-modal.customer-branding-section.app-icon-name-header`}
            >
                <TextField
                    label={t`feature-configuration-detail-modal.customer-branding-section.app-icon-name-label`}
                    value={store.form.get("appIconName")}
                    onChange={store.form.setter("appIconName")}
                />
            </FormFieldSet>
            <FormFieldSet
                header={t`feature-configuration-detail-modal.customer-branding-section.primary-color-header`}
            >
                <TextField
                    label={t`feature-configuration-detail-modal.customer-branding-section.primary-color-label`}
                    value={store.form.get("primaryColor")}
                    onChange={store.form.setter("primaryColor")}
                />
            </FormFieldSet>
            <FormFieldSet
                header={t`feature-configuration-detail-modal.customer-branding-section.email-settings-header`}
            >
                <Stack spacing={2}>
                    <TextField
                        label={t`feature-configuration-detail-modal.customer-branding-section.property-owner-email-address-label`}
                        value={store.form.get("propertyOwnerEmailAddress")}
                        onChange={store.form.setter(
                            "propertyOwnerEmailAddress",
                        )}
                    />
                    <TextField
                        label={t`feature-configuration-detail-modal.customer-branding-section.sender-name-label`}
                        value={store.form.get("senderName")}
                        onChange={store.form.setter("senderName")}
                    />
                    <TextField
                        label={t`feature-configuration-detail-modal.customer-branding-section.sender-email-address-label`}
                        value={store.form.get("senderAddress")}
                        onChange={store.form.setter("senderAddress")}
                    />
                </Stack>
            </FormFieldSet>
            <FormFieldSet
                header={t`feature-configuration-detail-modal.customer-branding-section.branding-assets-header`}
            >
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <ImageUploadField
                            label={t`feature-configuration-detail-modal.customer-branding-section.login-screen-background-image`}
                            value={store.form.get("brandingBackgroundImage")}
                            onChange={(image) =>
                                store.form.set("brandingBackgroundImage", image)
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ImageUploadField
                            label={t`feature-configuration-detail-modal.customer-branding-section.login-screen-logo`}
                            value={store.form.get("brandingLogo")}
                            onChange={(image) =>
                                store.form.set("brandingLogo", image)
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ImageUploadField
                            label={t`feature-configuration-detail-modal.customer-branding-section.overview-header-image`}
                            value={store.form.get("overviewHeaderImage")}
                            onChange={(image) =>
                                store.form.set("overviewHeaderImage", image)
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ImageUploadField
                            label={t`feature-configuration-detail-modal.customer-branding-section.overview-logo`}
                            value={store.form.get("overviewLogo")}
                            onChange={(image) =>
                                store.form.set("overviewLogo", image)
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ImageUploadField
                            label={t`feature-configuration-detail-modal.customer-branding-section.email-logo`}
                            value={store.form.get("propertyOwnerEmailLogo")}
                            onChange={(image) =>
                                store.form.set("propertyOwnerEmailLogo", image)
                            }
                        />
                    </Grid>
                </Grid>
            </FormFieldSet>
        </>
    )
})
