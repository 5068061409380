import { observer } from "mobx-react"

import { CheckboxPrefix } from "src/components/SegmentPicker/components/SegmentItemListPrefix/CheckboxPrefix"
import { EditPrefix } from "src/components/SegmentPicker/components/SegmentItemListPrefix/EditPrefix"
import { ISegmentItemListPrefixProps } from "src/components/SegmentPicker/components/SegmentItemListPrefix/types"
import { SegmentPickerStore } from "src/components/SegmentPicker/store"
import { useStore } from "src/store/lib/useStore"

export const SegmentItemListPrefix = observer(
    (props: ISegmentItemListPrefixProps) => {
        const store = useStore(SegmentPickerStore)
        switch (store.segmentListItemPrefixTypeValue) {
            case "checkbox":
                return <CheckboxPrefix segment={props.segment} />
            case "edit":
                return <EditPrefix segment={props.segment} />
            default:
                return null
        }
    },
)
