import { IIconProps } from "src/components/icons"

export const Property16 = (props: IIconProps) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.2 12.3449C5.42091 12.3449 5.6 12.5301 5.6 12.7586V13.5862C5.6 13.8147 5.77909 14 6 14H7.2C7.42091 14 7.6 13.8147 7.6 13.5862V7.37946C7.6003 7.22257 7.51479 7.079 7.3792 7.00871L4.9792 5.76736C4.86642 5.7089 4.73358 5.7089 4.6208 5.76736L2.2208 7.00871C2.08521 7.079 1.9997 7.22257 2 7.37946V13.5862C2 13.8147 2.17909 14 2.4 14H3.6C3.82091 14 4 13.8147 4 13.5862V12.7586C4 12.5301 4.17909 12.3449 4.4 12.3449H5.2ZM3.6 7.79324H6C6.22091 7.79324 6.4 7.9785 6.4 8.20703C6.4 8.43555 6.22091 8.62081 6 8.62081H3.6C3.37909 8.62081 3.2 8.43555 3.2 8.20703C3.2 7.9785 3.37909 7.79324 3.6 7.79324ZM3.2 10.2759C3.2 10.5045 3.37909 10.6897 3.6 10.6897H6C6.22091 10.6897 6.4 10.5045 6.4 10.2759C6.4 10.0474 6.22091 9.86216 6 9.86216H3.6C3.37909 9.86216 3.2 10.0474 3.2 10.2759Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.5999 14H8.7999C8.57899 14 8.3999 13.8147 8.3999 13.5862V2.41406C8.39981 2.27571 8.46657 2.14647 8.5778 2.06965C8.68903 1.99283 8.83001 1.9786 8.9535 2.03173L13.7535 4.10064C13.9028 4.16487 14 4.31573 13.9999 4.48298V13.5862C13.9999 13.8147 13.8208 14 13.5999 14ZM12.3999 5.72433H9.9999C9.77899 5.72433 9.5999 5.90959 9.5999 6.13811C9.5999 6.36664 9.77899 6.5519 9.9999 6.5519H12.3999C12.6208 6.5519 12.7999 6.36664 12.7999 6.13811C12.7999 5.90959 12.6208 5.72433 12.3999 5.72433ZM9.9999 7.79325H12.3999C12.6208 7.79325 12.7999 7.9785 12.7999 8.20703C12.7999 8.43556 12.6208 8.62081 12.3999 8.62081H9.9999C9.77899 8.62081 9.5999 8.43556 9.5999 8.20703C9.5999 7.9785 9.77899 7.79325 9.9999 7.79325ZM12.3999 9.86216H9.9999C9.77899 9.86216 9.5999 10.0474 9.5999 10.2759C9.5999 10.5045 9.77899 10.6897 9.9999 10.6897H12.3999C12.6208 10.6897 12.7999 10.5045 12.7999 10.2759C12.7999 10.0474 12.6208 9.86216 12.3999 9.86216Z"
            fill="currentColor"
        />
    </svg>
)
