import { t } from "@lingui/macro"
import { Stack } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { Document } from "src/components/AttachmentsField/DocumentAttachments/Document"

import { UploadDocumentField } from "src/components/AttachmentsField/DocumentAttachments/UploadDocumentField"
import { StyledFormTextHelper } from "src/components/AttachmentsField/DocumentAttachments/styled"
import { IDocumentAttachmentProps } from "src/components/AttachmentsField/types/documentAttatchmentProps"

export const DocumentAttachments = observer(
    (props: IDocumentAttachmentProps) => {
        const handleChange = useCallback(
            (files: IFile[]) => {
                props.onChange([...props.value, ...files])
            },
            [props],
        )

        const removeAtIndex = useCallback(
            (index: number) => {
                const value = props.value.slice(0)
                value.splice(index, 1)
                props.onChange(value)
            },
            [props],
        )

        return (
            <Stack
                component="ul"
                sx={{ listStyle: "none", margin: 0, padding: 0 }}
                spacing={2}
            >
                <li>
                    <UploadDocumentField
                        onChange={handleChange}
                        disabled={
                            props.readOnly === true ||
                            props.value.length >= props.max
                        }
                        max={props.max}
                        onError={props.onError}
                    />
                    <StyledFormTextHelper error={Boolean(props.documentError)}>
                        {props.documentError ??
                            t`general.document-upload-helper`}
                    </StyledFormTextHelper>
                </li>
                {props.value.slice(0, props.max).map((file, i) => (
                    <li key={file.url}>
                        <Document
                            file={file}
                            onRemove={() => removeAtIndex(i)}
                            disabled={props.readOnly}
                        />
                    </li>
                ))}
            </Stack>
        )
    },
)
