import { observer } from "mobx-react"
import {
    Checkbox,
    FormControlLabel,
    useTheme,
    Box,
    Typography,
} from "@mui/material"
import { t } from "@lingui/macro"

import { EditProductStore } from "src/views/product-catalogue/edit/store"

import { useStore } from "src/store/lib/useStore"
import { Alert16 } from "src/components/icons/Alert16"
import { Icon } from "src/components/icons"
import { Tooltip } from "src/components/Tooltips/Tooltip"

export const IncludeInOnboarding = observer(() => {
    const store = useStore(EditProductStore)
    const theme = useTheme()
    const checked = store.formFields.get("includedInOnboarding") ?? false

    const handleChange = () => {
        store.formFields.set("includedInOnboarding", !checked)
    }

    return (
        <Box
            sx={{ display: "flex", alignItems: "center" }}
            style={{ breakInside: "avoid" }}
        >
            <FormControlLabel
                control={<Checkbox checked={checked} onChange={handleChange} />}
                label={t`edit-product-view.include-in-onboarding.label`}
            />
            <Tooltip
                title={t`edit-product-view.include-in-onboarding.tooltip`}
                arrow
            >
                <Typography sx={{ lineHeight: 0 }}>
                    <Icon
                        icon={<Alert16 width="20px" height="20px" />}
                        color={theme.palette.grey[700]}
                    />
                </Typography>
            </Tooltip>
        </Box>
    )
})
