export enum FormTypeDropdownValues {
    RadioButton = "Radio Button",
    Paragraph = "Paragraph",
    Checkbox = "Checkboxes",
    ShortAnswer = "Short answer",
    Dropdown = "Dropdown",
    FileUpload = "File Upload",
}

export enum FormTypeDropdownKeys {
    RadioButton = "radio",
    Paragraph = "textarea",
    Checkbox = "checkbox",
    ShortAnswer = "text",
    Dropdown = "select",
    FileUpload = "file",
}
