/**
 * truncatedText truncates the provided `text` at `max` length and adds "..." at the end.
 */
export const truncateText = (text: string, max: number) => {
    const suffix = "..."

    return text.length <= max
        ? text
        : text.substring(0, max - suffix.length) + suffix
}

export const maskText = (
    text: string | undefined,
    maskingDigits: number,
    direction: string,
) => {
    return direction === "front"
        ? text?.substring(text.length, maskingDigits) + "****"
        : text?.substring(0, text.length - maskingDigits) + "****"
}
