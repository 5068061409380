import { t } from "@lingui/macro"
import {
    Button,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
} from "@mui/material"
import { observer } from "mobx-react"
import React, { useCallback } from "react"

import { FormFieldSet } from "src/components/FormFieldSet"
import { useUniqueId } from "src/lib/unique-id"
import { CopyFeatureConfigurationModal } from "src/modals/copy-feature-configuration"
import { FeatureConfigurationDetailStore } from "src/modals/feature-configuration-detail/store"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"

export const LegalEntitySection = observer(() => {
    const store = useStore(FeatureConfigurationDetailStore)
    const gstore = useStore(GlobalStore)
    const selectId = useUniqueId()

    const handleLegalEntityChange = useCallback(
        async (event: SelectChangeEvent<string | number>) => {
            const id = Number(event.target.value)
            let ok = true

            if (
                store.form.getIsDirty() &&
                store.selectedLegalEntity != null &&
                store.selectedLegalEntity.id !== id
            ) {
                // Temporary use of the built-in confirm modal to prevent loss
                // of form data. This can be swapped to a custom one if we
                // want/have time in the future.
                // eslint-disable-next-line no-restricted-globals
                ok = confirm(
                    t`feature-configuration-detail-modal.unsaved-changes-confirm-text`,
                )
            }

            if (ok) {
                store.setLegalEntity(id)
                await store.loadConfig()
            }
        },
        [store],
    )

    const handleOpenCopyFeatureConfigurationModal = useCallback(() => {
        const { selectedLegalEntity, copyableLegalEntities } = store
        if (selectedLegalEntity == null) {
            return
        }

        gstore.modals.open(
            () => (
                <CopyFeatureConfigurationModal
                    from={selectedLegalEntity}
                    legalEntities={copyableLegalEntities}
                />
            ),
            { variant: "slide-up-w600" },
        )
    }, [store, gstore])

    const showCopyButton =
        store.selectedLegalEntity != null &&
        store.copyableLegalEntities.length > 0

    const copyButton: React.ReactNode = showCopyButton && (
        <Button
            onClick={handleOpenCopyFeatureConfigurationModal}
            variant="outlined"
            color="info"
        >
            {t`feature-configuration-detail-modal.legal-entity-section.copy-settings-button`}
        </Button>
    )

    return (
        <FormFieldSet header="">
            <Stack spacing={1} alignItems="flex-end">
                <FormControl fullWidth>
                    <InputLabel htmlFor={selectId}>
                        {t`feature-configuration-detail-modal.legal-entity-section.select-legal-entity-label`}
                    </InputLabel>
                    <Select
                        id={selectId}
                        data-testid="Select/LegalEntity"
                        value={store.selectedLegalEntity?.id ?? ""}
                        onChange={handleLegalEntityChange}
                        label={t`feature-configuration-detail-modal.legal-entity-section.select-legal-entity-label`}
                        error={Boolean(store.form.error("generic"))}
                        fullWidth
                    >
                        {store.legalEntities.map((legalEntity) => (
                            <MenuItem
                                key={legalEntity.id}
                                value={legalEntity.id}
                            >
                                {legalEntity.legalName}
                            </MenuItem>
                        ))}
                    </Select>
                    {Boolean(store.form.error("generic")) && (
                        <FormHelperText error={true}>
                            {store.form.error("generic")}
                        </FormHelperText>
                    )}
                </FormControl>
                {copyButton}
            </Stack>
        </FormFieldSet>
    )
})
