import { IAntIconProps } from "src/layouts/DefaultLayout/Navigation/icons/props"

export const AntIconAccess = (props: IAntIconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="#424242"
            d="M20.59 10h-7.94a6.004 6.004 0 00-6.88-3.88c-2.29.46-4.15 2.3-4.63 4.58A6.002 6.002 0 007 18a5.99 5.99 0 005.65-4H13l1.29 1.29c.39.39 1.02.39 1.41 0L17 14l1.29 1.29c.39.39 1.03.39 1.42 0l2.59-2.61a.999.999 0 00-.01-1.42l-.99-.97c-.2-.19-.45-.29-.71-.29zM7 15c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z"
        />
    </svg>
)
