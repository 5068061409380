/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { avy_api_pkg_segment_PublishResourceRequest } from "../models/avy_api_pkg_segment_PublishResourceRequest"
import type { avy_api_pkg_segment_PublishResourceResponse } from "../models/avy_api_pkg_segment_PublishResourceResponse"
import type { chatbot_Conversation } from "../models/chatbot_Conversation"
import type { chatbot_CreateDispatchRequest } from "../models/chatbot_CreateDispatchRequest"
import type { chatbot_CreateTextEnhancementInstructionRequest } from "../models/chatbot_CreateTextEnhancementInstructionRequest"
import type { chatbot_DispatchListRequest } from "../models/chatbot_DispatchListRequest"
import type { chatbot_DispatchListResponse } from "../models/chatbot_DispatchListResponse"
import type { chatbot_DispatchResponse } from "../models/chatbot_DispatchResponse"
import type { chatbot_EnhanceTextContentRequest } from "../models/chatbot_EnhanceTextContentRequest"
import type { chatbot_EnhanceTextContentResponse } from "../models/chatbot_EnhanceTextContentResponse"
import type { chatbot_GetConversationsRequest } from "../models/chatbot_GetConversationsRequest"
import type { chatbot_GetConversationsResponse } from "../models/chatbot_GetConversationsResponse"
import type { chatbot_GetMessageTypesResponse } from "../models/chatbot_GetMessageTypesResponse"
import type { chatbot_GetTranslatedConversationResponse } from "../models/chatbot_GetTranslatedConversationResponse"
import type { chatbot_Message } from "../models/chatbot_Message"
import type { chatbot_SendMessageRequestStreaming } from "../models/chatbot_SendMessageRequestStreaming"
import type { chatbot_TextEnhancementInstructionResponse } from "../models/chatbot_TextEnhancementInstructionResponse"
import type { chatbot_UpdateFeedbackRequest } from "../models/chatbot_UpdateFeedbackRequest"
import type { chatbot_UpdateTextEnhancementInstructionRequest } from "../models/chatbot_UpdateTextEnhancementInstructionRequest"
import type { config_ConfigDefinition } from "../models/config_ConfigDefinition"
import type { config_CreateFeatureConfigRequest } from "../models/config_CreateFeatureConfigRequest"
import type { config_GetFeatureConfigListRequest } from "../models/config_GetFeatureConfigListRequest"
import type { config_GetFeatureConfigListResponse } from "../models/config_GetFeatureConfigListResponse"
import type { config_GetFeatureConfigResponse } from "../models/config_GetFeatureConfigResponse"
import type { config_PublishConfigRequest } from "../models/config_PublishConfigRequest"
import type { config_PublishConfigResponse } from "../models/config_PublishConfigResponse"
import type { config_UpdateFeatureConfigRequest } from "../models/config_UpdateFeatureConfigRequest"
import type { tags_ApplyTagsRequest } from "../models/tags_ApplyTagsRequest"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class ChatbotAdminService {
    /**
     * Create agent config
     * Creates a new agent configuration
     * @returns config_GetFeatureConfigResponse OK
     * @throws ApiError
     */
    public static postV1AdminChatbotAgentConfig({
        request,
    }: {
        /** CreateFeatureConfigRequest **/
        request: config_CreateFeatureConfigRequest
    }): CancelablePromise<config_GetFeatureConfigResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/chatbot/agent-config",
            body: request,
        })
    }

    /**
     * Get configurable agents
     * Gets all agents that can be configured
     * @returns config_ConfigDefinition OK
     * @throws ApiError
     */
    public static getV1AdminChatbotAgentConfigConfigDefinition(): CancelablePromise<config_ConfigDefinition> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/chatbot/agent-config/config-definition",
        })
    }

    /**
     * Get agent configs
     * Gets all agent configurations
     * @returns config_GetFeatureConfigListResponse OK
     * @throws ApiError
     */
    public static postV1AdminChatbotAgentConfigList({
        request,
    }: {
        /** GetFeatureConfigListRequest **/
        request: config_GetFeatureConfigListRequest
    }): CancelablePromise<config_GetFeatureConfigListResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/chatbot/agent-config/list",
            body: request,
        })
    }

    /**
     * Get agent config
     * Gets an agent configuration by ID
     * @returns config_GetFeatureConfigResponse OK
     * @throws ApiError
     */
    public static getV1AdminChatbotAgentConfig({
        configId,
    }: {
        /** Config ID **/
        configId: string
    }): CancelablePromise<config_GetFeatureConfigResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/chatbot/agent-config/{config_id}",
            path: {
                config_id: configId,
            },
        })
    }

    /**
     * Update agent config
     * Updates an existing agent configuration
     * @returns config_GetFeatureConfigResponse OK
     * @throws ApiError
     */
    public static putV1AdminChatbotAgentConfig({
        configId,
        request,
    }: {
        /** Config ID **/
        configId: string
        /** UpdateAgentConfigRequest **/
        request: config_UpdateFeatureConfigRequest
    }): CancelablePromise<config_GetFeatureConfigResponse> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/chatbot/agent-config/{config_id}",
            path: {
                config_id: configId,
            },
            body: request,
        })
    }

    /**
     * Delete agent config
     * Deletes an agent configuration
     * @returns void
     * @throws ApiError
     */
    public static deleteV1AdminChatbotAgentConfig({
        configId,
    }: {
        /** Config ID **/
        configId: string
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/chatbot/agent-config/{config_id}",
            path: {
                config_id: configId,
            },
        })
    }

    /**
     * Publish agent config
     * Publishes an agent configuration
     * @returns config_PublishConfigResponse OK
     * @throws ApiError
     */
    public static putV1AdminChatbotAgentConfigPublish({
        configId,
        request,
    }: {
        /** Config ID **/
        configId: string
        /** PublishConfigRequest **/
        request: config_PublishConfigRequest
    }): CancelablePromise<config_PublishConfigResponse> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/chatbot/agent-config/{config_id}/publish",
            path: {
                config_id: configId,
            },
            body: request,
        })
    }

    /**
     * Gets Chatbot conversations
     * Takes a request for getting conversations held between tenants and avy-chatbot
     * @returns chatbot_GetConversationsResponse OK
     * @throws ApiError
     */
    public static postV1AdminChatbotConversations({
        request,
        pageNumber = 1,
        pageSize = 10,
        accessGroupId,
    }: {
        /** How conversations should be filtered **/
        request: chatbot_GetConversationsRequest
        /** Pagination - number of page to load **/
        pageNumber?: number
        /** Pagination - size of pages to load **/
        pageSize?: number
        /** Access group ID for which to load conversations **/
        accessGroupId?: number
    }): CancelablePromise<chatbot_GetConversationsResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/chatbot/conversations",
            query: {
                page_number: pageNumber,
                page_size: pageSize,
                access_group_id: accessGroupId,
            },
            body: request,
        })
    }

    /**
     * Get Chatbot conversation
     * Gets a single conversation by ID
     * @returns chatbot_Conversation OK
     * @throws ApiError
     */
    public static getV1AdminChatbotConversations({
        conversationId,
    }: {
        /** Conversation ID **/
        conversationId: string
    }): CancelablePromise<chatbot_Conversation> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/chatbot/conversations/{conversation_id}",
            path: {
                conversation_id: conversationId,
            },
        })
    }

    /**
     * Send a chat message in a conversation
     * Takes a body containing a message to be sent to a conversation
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminChatbotConversationsMessage({
        conversationId,
        request,
    }: {
        /** Conversation ID **/
        conversationId: string
        /** SendMessageRequestStreaming **/
        request: chatbot_SendMessageRequestStreaming
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/chatbot/conversations/{conversation_id}/message",
            path: {
                conversation_id: conversationId,
            },
            body: request,
        })
    }

    /**
     * Get Chatbot conversation messages
     * Gets a single conversation's messages
     * @returns chatbot_Message OK
     * @throws ApiError
     */
    public static getV1AdminChatbotConversationsMessages({
        conversationId,
    }: {
        /** Conversation ID **/
        conversationId: string
    }): CancelablePromise<Array<chatbot_Message>> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/chatbot/conversations/{conversation_id}/messages",
            path: {
                conversation_id: conversationId,
            },
        })
    }

    /**
     * Get Chatbot conversation messages translated to requested language
     * Gets a single conversation's messages translated to requested language
     * @returns chatbot_GetTranslatedConversationResponse OK
     * @throws ApiError
     */
    public static getV1AdminChatbotConversationsMessagesTranslate({
        conversationId,
        lang,
    }: {
        /** Conversation ID **/
        conversationId: string
        /** Language to translate to **/
        lang: string
    }): CancelablePromise<chatbot_GetTranslatedConversationResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/chatbot/conversations/{conversation_id}/messages/translate",
            path: {
                conversation_id: conversationId,
            },
            query: {
                lang: lang,
            },
        })
    }

    /**
     * Apply tags to a conversation
     * Takes a body with tags to be applied to a conversation
     * @returns any OK
     * @throws ApiError
     */
    public static putV1AdminChatbotConversationsTag({
        conversationId,
        request,
    }: {
        /** Conversation ID **/
        conversationId: string
        /** ApplyTagsRequest **/
        request: tags_ApplyTagsRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/chatbot/conversations/{conversation_id}/tag",
            path: {
                conversation_id: conversationId,
            },
            body: request,
        })
    }

    /**
     * Update admin's feedback on a chatbot conversation
     * Takes a body with feedback to be proxied to avy-chatbot
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminChatbotConversationsUpdateFeedback({
        request,
        conversationId,
    }: {
        /** Update feedback **/
        request: chatbot_UpdateFeedbackRequest
        /** Conversation ID **/
        conversationId: string
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/chatbot/conversations/{conversation_id}/update-feedback",
            path: {
                conversation_id: conversationId,
            },
            body: request,
        })
    }

    /**
     * Create dispatch
     * Takes a body with dispatch to be created
     * @returns chatbot_DispatchResponse OK
     * @throws ApiError
     */
    public static postV1AdminChatbotDispatch({
        request,
    }: {
        /** CreateDispatchRequest **/
        request: chatbot_CreateDispatchRequest
    }): CancelablePromise<chatbot_DispatchResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/chatbot/dispatch",
            body: request,
        })
    }

    /**
     * Get dispatches
     * Gets all available dispatches
     * @returns chatbot_DispatchListResponse OK
     * @throws ApiError
     */
    public static postV1AdminChatbotDispatchList({
        request,
    }: {
        /** DispatchListRequest **/
        request: chatbot_DispatchListRequest
    }): CancelablePromise<chatbot_DispatchListResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/chatbot/dispatch/list",
            body: request,
        })
    }

    /**
     * Get dispatch
     * Gets one dispatch by ID
     * @returns chatbot_DispatchResponse OK
     * @throws ApiError
     */
    public static getV1AdminChatbotDispatch({
        dispatchId,
    }: {
        /** Dispatch ID **/
        dispatchId: string
    }): CancelablePromise<chatbot_DispatchResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/chatbot/dispatch/{dispatch_id}",
            path: {
                dispatch_id: dispatchId,
            },
        })
    }

    /**
     * Update dispatch
     * Takes a body with dispatch to be updated
     * @returns chatbot_DispatchResponse OK
     * @throws ApiError
     */
    public static putV1AdminChatbotDispatch({
        request,
        dispatchId,
    }: {
        /** CreateDispatchRequest **/
        request: chatbot_CreateDispatchRequest
        /** Dispatch ID **/
        dispatchId: string
    }): CancelablePromise<chatbot_DispatchResponse> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/chatbot/dispatch/{dispatch_id}",
            path: {
                dispatch_id: dispatchId,
            },
            body: request,
        })
    }

    /**
     * Delete dispatch
     * Takes a body with dispatch to be deleted
     * @returns any OK
     * @throws ApiError
     */
    public static deleteV1AdminChatbotDispatch({
        dispatchId,
    }: {
        /** Dispatch ID **/
        dispatchId: string
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/chatbot/dispatch/{dispatch_id}",
            path: {
                dispatch_id: dispatchId,
            },
        })
    }

    /**
     * Publish Dispatch
     * Publish dispatch by ID
     * @returns avy_api_pkg_segment_PublishResourceResponse OK
     * @throws ApiError
     */
    public static putV1AdminChatbotDispatchPublish({
        dispatchId,
        request,
    }: {
        /** ID of dispatch to publish **/
        dispatchId: string
        /** Request body to set where informationItems are published **/
        request: avy_api_pkg_segment_PublishResourceRequest
    }): CancelablePromise<avy_api_pkg_segment_PublishResourceResponse> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/chatbot/dispatch/{dispatch_id}/publish",
            path: {
                dispatch_id: dispatchId,
            },
            body: request,
        })
    }

    /**
     * Get message types
     * Get all available message types
     * @returns chatbot_GetMessageTypesResponse OK
     * @throws ApiError
     */
    public static getV1AdminChatbotMessageTypes(): CancelablePromise<chatbot_GetMessageTypesResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/chatbot/message-types",
        })
    }

    /**
     * Enhance text content
     * Enhances provided text content based on its type and context, considering property owner
     * @returns chatbot_EnhanceTextContentResponse OK
     * @throws ApiError
     */
    public static postV1AdminChatbotTextEnhancement({
        request,
    }: {
        /** Enhance Text Content Request **/
        request: chatbot_EnhanceTextContentRequest
    }): CancelablePromise<chatbot_EnhanceTextContentResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/chatbot/text-enhancement",
            body: request,
        })
    }

    /**
     * Create Text Enhancement Instruction
     * Creates a new text enhancement instruction for a specific property owner.
     * @returns chatbot_TextEnhancementInstructionResponse Created
     * @throws ApiError
     */
    public static postV1AdminChatbotTextEnhancementInstructions({
        request,
    }: {
        /** Create Text Enhancement Instruction Request **/
        request: chatbot_CreateTextEnhancementInstructionRequest
    }): CancelablePromise<chatbot_TextEnhancementInstructionResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/chatbot/text-enhancement/instructions",
            body: request,
        })
    }

    /**
     * Get Text Enhancement Instructions
     * Retrieves a specific text enhancement instruction by its ID.
     * @returns chatbot_TextEnhancementInstructionResponse OK
     * @throws ApiError
     */
    public static getV1AdminChatbotTextEnhancementInstructions({
        instructionId,
        propertyOwnerId,
    }: {
        /** Instruction ID **/
        instructionId: number
        /** Property Owner ID **/
        propertyOwnerId: number
    }): CancelablePromise<chatbot_TextEnhancementInstructionResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/chatbot/text-enhancement/instructions/{instruction_id}",
            query: {
                instructionID: instructionId,
                propertyOwnerID: propertyOwnerId,
            },
        })
    }

    /**
     * Update Text Enhancement Instruction
     * Updates an existing text enhancement instruction for a specific property owner.
     * @returns chatbot_TextEnhancementInstructionResponse OK
     * @throws ApiError
     */
    public static putV1AdminChatbotTextEnhancementInstructions({
        instructionId,
        request,
    }: {
        /** Instruction ID **/
        instructionId: number
        /** Update Text Enhancement Instruction Request **/
        request: chatbot_UpdateTextEnhancementInstructionRequest
    }): CancelablePromise<chatbot_TextEnhancementInstructionResponse> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/chatbot/text-enhancement/instructions/{instruction_id}",
            path: {
                instructionID: instructionId,
            },
            body: request,
        })
    }

    /**
     * Delete Text Enhancement Instruction
     * Deletes a text enhancement instruction for a specific property owner.
     * @returns void
     * @throws ApiError
     */
    public static deleteV1AdminChatbotTextEnhancementInstructions({
        instructionId,
        propertyOwnerId,
    }: {
        /** Instruction ID **/
        instructionId: number
        /** Property Owner ID **/
        propertyOwnerId: number
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/chatbot/text-enhancement/instructions/{instruction_id}",
            path: {
                instructionID: instructionId,
            },
            query: {
                propertyOwnerID: propertyOwnerId,
            },
        })
    }
}
