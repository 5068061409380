import { DefaultTheme } from "src/themes/default"

const colorPalette = DefaultTheme.palette.resources.avatarColorList

const getNumberReduction = (number: number): string => {
    if (number > colorPalette.length) {
        const numberReduce = number
            .toString()
            .split("")
            .map((num) => parseInt(num, 10))
            .reduce((a, b) => a + b, 0)
        return getNumberReduction(numberReduce)
    } else {
        return colorPalette[number]
    }
}

const calculateNumberFromString = (characterString: string): number => {
    let outputResult = 0
    if (typeof characterString === "string") {
        characterString = characterString.toUpperCase().replace(" ", "")
        let stringLength = characterString.length
        for (
            let characterPosition = 0;
            characterPosition < stringLength;
            characterPosition++
        ) {
            outputResult += characterString.charCodeAt(characterPosition)
        }
    }
    return outputResult
}

export const convertStringToColor = (string: string) => {
    if (!isNaN(parseInt(string, 10))) {
        return undefined
    }
    const stringToNumber = calculateNumberFromString(string) as number
    return getNumberReduction(stringToNumber)
}
