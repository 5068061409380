import { t } from "@lingui/macro"

import { range } from "src/lib/array"

export const maxActiveBookingsRange = [
    {
        get label() {
            return t`global.infinite`
        },
        value: Infinity,
    },
    ...range(10, 1).map((value) => ({ label: value, value })),
]

const timeSlotMinutes = [5, 10, 15, 30, 60, 120, 180, 240, 300, 360, 720, 1440]

export const timeSlots = timeSlotMinutes.map((minutes) => {
    if (minutes < 60) {
        return {
            get label() {
                return t({ id: "global.minutes", values: { count: minutes } })
            },
            value: minutes,
        }
    } else {
        return {
            get label() {
                return t({
                    id: "global.hours",
                    values: { count: minutes / 60 },
                })
            },
            value: minutes,
        }
    }
})
