import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useEffect } from "react"
import { Link } from "@mui/material"

import { ListPage } from "src/components/ListPage"
import { Table } from "src/components/Table"
import { FeatureConfigurationDetailModal } from "src/modals/feature-configuration-detail"
import { GlobalStore } from "src/store"

import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { FeatureConfigurationsStore } from "src/views/feature-configurations/store"

const View = observer(() => {
    const store = useStore(FeatureConfigurationsStore)
    const gstore = useStore(GlobalStore)

    useEffect(() => {
        if (gstore.session.propertyOwners.length > 0) {
            ;(async () => {
                await store.init(gstore.session.propertyOwners)
            })()
        }
    }, [store, gstore.session.propertyOwners])

    return (
        <ListPage
            header={{
                header: t`feature-configurations-view.header`,
                breadcrumbs: [],
            }}
            loading={gstore.loading.is(
                FeatureConfigurationsStore.LoadingKeys.init,
            )}
        >
            <Table
                paginator={store.propertyOwners}
                rowRenderer={(item) => ({
                    id: item.id,
                    name: (
                        <Link
                            component="button"
                            onClick={() =>
                                gstore.modals.open(() => (
                                    <FeatureConfigurationDetailModal
                                        legalEntities={item.legalEntities}
                                    />
                                ))
                            }
                        >
                            {item.legalName}
                        </Link>
                    ),
                    numberOfLegalEntities: item.legalEntities?.length ?? 0,
                })}
                headers={[
                    {
                        key: "name",
                        name: t`feature-configurations-view.name-header`,
                    },
                    {
                        key: "numberOfLegalEntities",
                        name: t`feature-configurations-view.number-of-legal-entities-header`,
                    },
                ]}
            />
        </ListPage>
    )
})

export const FeatureConfigurationsView = observer(() => (
    <StoreProvider Store={FeatureConfigurationsStore}>
        <View />
    </StoreProvider>
))
