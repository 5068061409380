import { t } from "@lingui/macro"
import { captureException } from "@sentry/browser"

import { Channel } from "src/channel"
import { isNodeEnvironment } from "src/config/variables"

/**
 * Shows a success message to the user as a success notification.
 *
 * @param message Message to show the user
 */
export function reportSuccess(message: string) {
    Channel.send({
        name: "notification/toast",
        payload: { severity: "success", message },
    })
}

/**
 * Reports an error to sentry and shows a message to the user as an error
 * notification.
 *
 * The exception cannot be typed as the exception from the catch block is always `unknown`.
 *
 * @param message Message to show the user
 * @param exc Optional exception. This is sent to sentry for tracking
 */
export function reportError(message: string, exc?: unknown) {
    captureException(exc ?? message)

    if (isNodeEnvironment("development", "test")) {
        // Print errors in development and test to console. `captureException`
        // won't trigger in development, so otherwise they are swallowed and no
        // details are shown.
        // eslint-disable-next-line no-console
        console.error(exc, message)
    }

    Channel.send({
        name: "notification/toast",
        payload: { severity: "error", message },
    })
}

/**
 * Reports a error to sentry and shows a generic messages for unhandled api responses to the user as an error notification.
 *
 * The exception cannot be typed as the exception from the catch block is always `unknown`.
 *
 * @param exc Exception sent to sentry for tracking
 */
export function reportUnhandledApiError(exc: unknown) {
    reportError(t`global.unhandled-api-error`, exc)
}
