import { t } from "@lingui/macro"
import { Stack, Typography, useTheme } from "@mui/material"
import { memo } from "react"

import { ConfigurableSecondaryLanguageStyledBox } from "./styled"

import { EditPencil } from "src/components/icons/EditPencil"
import { Warning } from "src/components/icons/Warning"
import { IConfigurableSecondaryLanguageProps } from "src/components/Inputs/types/MultiLanguageTextField"
import { TooltipWithIcon } from "src/components/Tooltips/TooltipWithIcon"
import { MultiLanguageEditModal } from "src/modals/multi-language-text-field-modal"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"

export const ConfigurableSecondaryLanguage = memo(
    ({
        language,
        data,
        areTranslationsStale,
        onSave,
    }: IConfigurableSecondaryLanguageProps) => {
        const globalStore = useStore(GlobalStore)
        const theme = useTheme()
        const text = data?.[language]
        const hasText = text !== undefined
        const showTranslationsAreStale = areTranslationsStale && hasText
        const color = showTranslationsAreStale
            ? theme.palette.error.dark
            : theme.palette.text.secondary

        const handleEditTranslation = () => {
            globalStore.modals.open(
                () => (
                    <MultiLanguageEditModal
                        language={language}
                        translation={text ?? ""}
                        onSave={onSave}
                    />
                ),
                {
                    variant: "slide-up-w600",
                },
            )
        }

        return (
            <Stack direction={"row"} spacing={1} mt={2} alignItems="center">
                <ConfigurableSecondaryLanguageStyledBox>
                    <Typography variant="subtitle2">{language}</Typography>
                </ConfigurableSecondaryLanguageStyledBox>
                {showTranslationsAreStale && (
                    <TooltipWithIcon
                        icon={<Warning />}
                        tooltipText={t`multi-language-text-field.stale-translation`}
                    />
                )}
                <Typography variant="body2" color={color}>
                    {hasText
                        ? text
                        : `(${t`multi-language-text-field.empty-language`})`}
                </Typography>
                {hasText && (
                    <TooltipWithIcon
                        tooltipText={t`multi-language-text-field.edit-translation`}
                        icon={<EditPencil width="16" height="16" />}
                        onClick={handleEditTranslation}
                    />
                )}
            </Stack>
        )
    },
)
