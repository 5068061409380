/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { avy_api_pkg_segment_PublishResourceRequest } from "../models/avy_api_pkg_segment_PublishResourceRequest"
import type { avy_api_pkg_segment_PublishResourceResponse } from "../models/avy_api_pkg_segment_PublishResourceResponse"
import type { booking_AdminBookingListRequest } from "../models/booking_AdminBookingListRequest"
import type { booking_AdminBookingListResponse } from "../models/booking_AdminBookingListResponse"
import type { booking_AdminBookingResource } from "../models/booking_AdminBookingResource"
import type { booking_AdminResourceListResponse } from "../models/booking_AdminResourceListResponse"
import type { booking_Resource } from "../models/booking_Resource"
import type { booking_ResourceCreateUpdate } from "../models/booking_ResourceCreateUpdate"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class BookingAdminService {
    /**
     * @deprecated
     * List bookings
     * Gets list of bookings for admin
     * @returns booking_AdminBookingListResponse OK
     * @throws ApiError
     */
    public static getV1AdminBooking({
        pageNumber = 1,
        pageSize = 10,
        accessGroupId,
    }: {
        /** Pagination - number of page to load **/
        pageNumber?: number
        /** Pagination - size of pages to load **/
        pageSize?: number
        /** Access group ID for which to load the bookings **/
        accessGroupId?: number
    }): CancelablePromise<booking_AdminBookingListResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/booking",
            query: {
                page_number: pageNumber,
                page_size: pageSize,
                access_group_id: accessGroupId,
            },
        })
    }

    /**
     * List bookings
     * Gets list of bookings for admin
     * @returns booking_AdminBookingListResponse OK
     * @throws ApiError
     */
    public static postV1AdminBookingList({
        filter,
    }: {
        /** Filter data. **/
        filter: booking_AdminBookingListRequest
    }): CancelablePromise<booking_AdminBookingListResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/booking/list",
            body: filter,
        })
    }

    /**
     * List resources
     * Gets list of booking resources for admin
     * @returns booking_AdminResourceListResponse OK
     * @throws ApiError
     */
    public static getV1AdminBookingResource({
        pageNumber = 1,
        pageSize = 10,
        accessGroupId,
    }: {
        /** Pagination - number of page to load **/
        pageNumber?: number
        /** Pagination - size of pages to load **/
        pageSize?: number
        /** Access group ID for which to load the resources **/
        accessGroupId?: number
    }): CancelablePromise<booking_AdminResourceListResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/booking/resource",
            query: {
                page_number: pageNumber,
                page_size: pageSize,
                access_group_id: accessGroupId,
            },
        })
    }

    /**
     * Create resource
     * Creates a booking resource.
     * @returns booking_Resource OK
     * @throws ApiError
     */
    public static postV1AdminBookingResource({
        request,
    }: {
        /** Resource data. **/
        request: booking_ResourceCreateUpdate
    }): CancelablePromise<booking_Resource> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/booking/resource",
            body: request,
        })
    }

    /**
     * Fetch single resource
     * Gets a booking resource.
     * @returns booking_AdminBookingResource OK
     * @throws ApiError
     */
    public static getV1AdminBookingResource1({
        resourceId,
    }: {
        /** Resource ID **/
        resourceId: number
    }): CancelablePromise<booking_AdminBookingResource> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/booking/resource/{resource_id}",
            path: {
                resource_id: resourceId,
            },
        })
    }

    /**
     * Delete resource
     * Deletes a resource by ID.
     * @returns any OK
     * @throws ApiError
     */
    public static deleteV1AdminBookingResource({
        resourceId,
    }: {
        /** Resource ID **/
        resourceId: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/booking/resource/{resource_id}",
            path: {
                resource_id: resourceId,
            },
        })
    }

    /**
     * Update resource
     * Updates a resource.
     * @returns any OK
     * @throws ApiError
     */
    public static patchV1AdminBookingResource({
        request,
        resourceId,
    }: {
        /** Resource data. **/
        request: booking_ResourceCreateUpdate
        /** Resource ID **/
        resourceId: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PATCH",
            url: "/v1/admin/booking/resource/{resource_id}",
            path: {
                resource_id: resourceId,
            },
            body: request,
        })
    }

    /**
     * Export bookings
     * Export bookings by resource ID.
     * @returns string OK
     * @throws ApiError
     */
    public static getV1AdminBookingResourceBookingsExport({
        resourceId,
    }: {
        /** Resource ID **/
        resourceId: number
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/booking/resource/{resource_id}/bookings/export",
            path: {
                resource_id: resourceId,
            },
        })
    }

    /**
     * Clone resource
     * Clones a resource by ID.
     * @returns booking_Resource OK
     * @throws ApiError
     */
    public static postV1AdminBookingResourceClone({
        resourceId,
    }: {
        /** Resource ID **/
        resourceId: number
    }): CancelablePromise<booking_Resource> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/booking/resource/{resource_id}/clone",
            path: {
                resource_id: resourceId,
            },
        })
    }

    /**
     * Delete resource intervals
     * Deletes intervals and thresholds for booking resource.
     * @returns string OK
     * @throws ApiError
     */
    public static deleteV1AdminBookingResourceIntervals(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/booking/resource/{resource_id}/intervals",
        })
    }

    /**
     * Publish booking resource
     * Publish booking resource by id
     * @returns avy_api_pkg_segment_PublishResourceResponse OK
     * @throws ApiError
     */
    public static putV1AdminBookingResourcePublish({
        resourceId,
        request,
    }: {
        /** ID of booking resource to publish **/
        resourceId: number
        /** Request body to set where booking is published **/
        request: avy_api_pkg_segment_PublishResourceRequest
    }): CancelablePromise<avy_api_pkg_segment_PublishResourceResponse> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/booking/resource/{resource_id}/publish",
            path: {
                resource_id: resourceId,
            },
            body: request,
        })
    }
}
