import { Stack } from "@mui/material"
import { observer } from "mobx-react"

import { EmptyMessageText } from "src/components/SegmentPicker/components/SegmentsEmptyMessage/styled"
import { ISegmentsEmptyMessageProps } from "src/components/SegmentPicker/components/SegmentsEmptyMessage/types"

export const SegmentsEmptyMessage = observer(
    (props: ISegmentsEmptyMessageProps) => (
        <Stack spacing={2} alignItems="center">
            <EmptyMessageText>{props.header}</EmptyMessageText>
            <EmptyMessageText>{props.message}</EmptyMessageText>
        </Stack>
    ),
)
