import { t } from "@lingui/macro"
import { InputAdornment, Stack, TextField } from "@mui/material"
import { observer } from "mobx-react"

import { FormFieldSet } from "src/components/FormFieldSet"
import { Link24 } from "src/components/icons/Link24"
import { ResourceDetailStore } from "src/modals/resource-detail/store"
import { useStore } from "src/store/lib/useStore"

export const DescriptionSection = observer(() => {
    const vstore = useStore(ResourceDetailStore)

    return (
        <FormFieldSet
            header={t`resource-detail-modal.description-section.field-set-header`}
        >
            <Stack spacing={2}>
                <TextField
                    label={t`resource-detail-modal.description-section.title-label`}
                    value={vstore.fields.get("name")}
                    onChange={(event) =>
                        vstore.fields.set("name", event.target.value)
                    }
                    helperText={vstore.fields.error("name")}
                    error={Boolean(vstore.fields.error("name"))}
                    disabled={vstore.fields.get("accessType") === "READ"}
                />
                <TextField
                    label={t`resource-detail-modal.description-section.address-label`}
                    value={vstore.fields.get("address")}
                    onChange={(event) =>
                        vstore.fields.set("address", event.target.value)
                    }
                    helperText={vstore.fields.error("address")}
                    error={Boolean(vstore.fields.error("address"))}
                    disabled={vstore.fields.get("accessType") === "READ"}
                />
                <TextField
                    label={t`resource-detail-modal.description-section.description-label`}
                    value={vstore.fields.get("description")}
                    onChange={(event) =>
                        vstore.fields.set("description", event.target.value)
                    }
                    helperText={vstore.fields.error("description")}
                    error={Boolean(vstore.fields.error("description"))}
                    rows={3}
                    multiline
                    disabled={vstore.fields.get("accessType") === "READ"}
                />
                <TextField
                    label={t`resource-detail-modal.description-section.url-label`}
                    value={vstore.fields.get("url")}
                    type="url"
                    onChange={(event) =>
                        vstore.fields.set("url", event.target.value)
                    }
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Link24 />
                            </InputAdornment>
                        ),
                    }}
                    helperText={vstore.fields.error("url")}
                    error={Boolean(vstore.fields.error("url"))}
                    disabled={vstore.fields.get("accessType") === "READ"}
                />
                <TextField
                    label={t`resource-detail-modal.description-section.url-title-label`}
                    value={vstore.fields.get("urlTitle")}
                    onChange={(event) =>
                        vstore.fields.set("urlTitle", event.target.value)
                    }
                    helperText={vstore.fields.error("urlTitle")}
                    error={Boolean(vstore.fields.error("urlTitle"))}
                    disabled={vstore.fields.get("accessType") === "READ"}
                />
            </Stack>
        </FormFieldSet>
    )
})
