import { v4 as uuidv4 } from "uuid"

export const initialExternalFieldsState = {
    isRequired: false,
    isAddOptionsVisible: false,
    externalFieldTypeDropdownValue: {
        value: "",
        errorMessage: "",
    },
    inputList: [{ value: "", uuid: "", errorMessage: "" }],
    fieldTypeDropdownValue: { value: "", errorMessage: "" },
    relationshipDropdownValue: { value: "", errorMessage: "" },
    question: { value: "", errorMessage: "" },
    helpingText: { value: "", errorMessage: "" },
    uuid: uuidv4(),
}

export const initialInternalFieldsState = {
    isRequired: false,
    dropdownValue: { value: "", errorMessage: "" },
    inputList: [{ value: "", uuid: "", errorMessage: "" }],
    label: { value: "", uuid: "", errorMessage: "" },
    answer: { value: "", uuid: "", errorMessage: "" },
    helpingText: { value: "", errorMessage: "" },
    uuid: uuidv4(),
}

export const initialMetaDataFieldsState = {
    source: { value: "", errorMessage: "" },
    label: { value: "", errorMessage: "" },
}
