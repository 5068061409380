import { useEffect, useMemo } from "react"
import {
    Autocomplete,
    FormControl,
    FormHelperText,
    TextField,
} from "@mui/material"
import { observer } from "mobx-react"
import { t } from "@lingui/macro"

import { useStore } from "src/store/lib/useStore"
import { LibraryInformationItemStore } from "src/modals/library-information-items/store"
import { reportError } from "src/lib/report"
import { GlobalStore } from "src/store"
import { DEFAULT_ACCESS_GROUP } from "src/config"

export const LibraryInformationItemsAccessGroup = observer(() => {
    const store = useStore(LibraryInformationItemStore)
    const gstore = useStore(GlobalStore)

    const accessGroupLabels = useMemo(() => {
        return Object.fromEntries(
            store.accessGroups.map((ag) => [ag.id, ag.name]),
        )
    }, [store.accessGroups])

    useEffect(() => {
        ;(async () => {
            try {
                await store.setAccessGroups(gstore.session.accessGroups)
            } catch (error) {
                reportError(
                    t`edit-information-item-modal.load-categories-fail`,
                    error,
                )
            }
        })()
    }, [store, gstore.session.accessGroups])

    useEffect(() => {
        ;(async () => {
            try {
                await store.setSelectedAccessGroupId(
                    gstore.session.accessGroup.id !== undefined
                        ? gstore.session.accessGroup.id
                        : DEFAULT_ACCESS_GROUP.id,
                )
            } catch (error) {
                reportError(
                    t`edit-information-item-modal.load-categories-fail`,
                    error,
                )
            }
        })()
    }, [store, gstore.session.accessGroup])

    const agPickerChange = async (accessGroupId: number | undefined) => {
        store.formFields.set("accessGroupId", accessGroupId as number)

        const accessGroup = gstore.session.accessGroups.find(
            (ag) => ag.id === accessGroupId,
        )

        if (accessGroup != null) {
            await gstore.session.setSelectedAccessGroup(accessGroup)
        } else {
            // TODO: do this in a better way
            throw new Error("accessgroup could not be selected.")
        }
    }

    const value = () =>
        store.accessGroups.length > 0 &&
        store.formFields.get("accessGroupId") != null
            ? store.formFields.get("accessGroupId")
            : DEFAULT_ACCESS_GROUP.id

    return (
        <FormControl style={{ breakInside: "avoid" }}>
            <Autocomplete
                id="accessGroupId"
                options={store.accessGroups.map((x) => x.id)}
                onChange={(_, value) => agPickerChange(value as number)}
                value={value()}
                renderInput={(props) => (
                    <TextField
                        {...props}
                        label={t`head.access-group-label`}
                        error={Boolean(store.formFields.error("accessGroupId"))}
                    />
                )}
                getOptionLabel={(option) =>
                    accessGroupLabels[String(option)] ?? ""
                }
                renderOption={(props, option) => (
                    <li {...props} key={Number(option)}>
                        {accessGroupLabels[String(option)]}
                    </li>
                )}
                title={t`head.access-group-label`}
                placeholder={t`head.access-group-label`}
                disabled={Boolean(store.isEditMode)}
                autoHighlight
                blurOnSelect
                disableClearable
            />
            {Boolean(store.formFields.error("accessGroupId")) && (
                <FormHelperText error={true}>
                    {store.formFields.error("accessGroupId")}
                </FormHelperText>
            )}
        </FormControl>
    )
})
