import { makeAutoObservable } from "mobx"
import { createContext } from "react"

import { EmbedAdminService } from "src/api"

import { Channel } from "src/channel"
import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"

export class EmbedStore {
    static Context = createContext<EmbedStore | null>(null)
    static LoadingKeys = createLoadingKeys("pageLoad")
    private repositoryUpdatesListenerDisposer?: () => void

    embedIntegrations = new Pagination(
        async () => {
            const response =
                await EmbedAdminService.getV1AdminEmbedIntegration()
            const items =
                response?.map((embed) => ({
                    id: embed.id ?? 0,
                    name: embed.name ?? "",
                    sales_data: embed.sales_data,
                    type: embed.type ?? "",
                    segments: embed.segments ?? [],
                })) ?? []

            return {
                items,
                rawItems: response ?? [],
                count: response?.length ?? 0,
            }
        },
        { loadingKey: EmbedStore.LoadingKeys.pageLoad },
    )

    constructor() {
        makeAutoObservable(this)
    }

    async init() {
        this.listenToMessageRepositoryUpdated()
        await this.embedIntegrations.loadInitialPage()
    }

    dispose() {
        this.repositoryUpdatesListenerDisposer?.()
    }

    private listenToMessageRepositoryUpdated() {
        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "embed-integrations"
                ) {
                    await this.embedIntegrations.reload()
                }
            },
        )
    }
}
