import { t } from "@lingui/macro"
import { observer } from "mobx-react"

import { FormFieldSet } from "src/components/FormFieldSet"
import { SegmentPickerField } from "src/components/SegmentPickerField"
import { FormDetailStore } from "src/modals/contact-forms-form-builder/store"
import { useStore } from "src/store/lib/useStore"

export const PublishingSettingsSection = observer(() => {
    const store = useStore(FormDetailStore)

    return (
        <FormFieldSet
            header={t`resource-detail-modal.publishing-settings-section.field-set-header`}
        >
            <SegmentPickerField
                value={store.formFields.get("segmentIds") ?? []}
                accessGroupId={store.formFields.get("accessGroupId")}
                onChange={(segmentIds) =>
                    store.formFields.set("segmentIds", segmentIds)
                }
            />
        </FormFieldSet>
    )
})
