import { t } from "@lingui/macro"
import { BoxProps } from "@mui/material"
import { observer } from "mobx-react"
import React, { useCallback, useRef } from "react"
import FocusLock from "react-focus-lock"

import { ErrorBoundary } from "src/components/ErrorBoundary"
import {
    AvyModal,
    AvyModalOverlay,
    AvyPreviewModal,
    AvyPreviewModalOverlay,
} from "src/fragments/modal/styled"
import { ConfirmModal } from "src/modals/confirm"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { IModalOptions } from "src/types/modal/modal-options"

export interface IModalProps extends BoxProps {
    closing: boolean
    variant: IModalOptions["variant"]
}

export interface IPreviewModalProps extends BoxProps {
    previewClosing: boolean
}

export interface IOverlayProps extends BoxProps {
    closing: boolean
    variant: IModalOptions["variant"]
}

export const ModalFragment = observer(() => {
    const gstore = useStore(GlobalStore)
    const overlays = useRef<HTMLDivElement[]>([])

    const showConfirmModal = useCallback(() => {
        gstore.modals.open(
            () => (
                <ConfirmModal
                    onConfirm={(confirmed: boolean) => {
                        if (confirmed) {
                            gstore.modals.popPreview()
                            gstore.modals.pop()
                        }
                    }}
                    title={t`modal-index.confirm-close-modal-title`}
                    content={t`modal-index.confirm-close-modal-content`}
                />
            ),
            { variant: "slide-up-w600" },
        )
    }, [gstore.modals])

    const handleOverlayClick = useCallback(
        (event: React.MouseEvent) => {
            if (
                overlays.current.some(
                    (overlay) =>
                        event.target instanceof Node &&
                        event.target.isSameNode(overlay),
                )
            ) {
                const thisModal =
                    gstore.modals.active[gstore.modals.active.length - 1]

                if (
                    thisModal.confirmOnOverlayClick != null &&
                    thisModal.confirmOnOverlayClick()
                ) {
                    showConfirmModal()
                } else {
                    gstore.modals.popPreview()
                    gstore.modals.pop()
                }
            }
        },
        [gstore.modals, showConfirmModal],
    )

    return (
        <>
            {gstore.modals.active.map((modal, i) => {
                const { Component, options } = modal

                return (
                    <FocusLock key={modal.id} returnFocus={true}>
                        <AvyModalOverlay
                            ref={(el: HTMLDivElement) =>
                                (overlays.current[i] = el)
                            }
                            closing={modal.isClosing}
                            onClick={handleOverlayClick}
                            data-testid="ModalFragment/Overlay"
                            variant={modal.options.variant}
                        >
                            {options.hasPreview === true &&
                                gstore.modals.previewActive.map(
                                    (previewModal, index) => {
                                        const { Component, isPreviewClosing } =
                                            previewModal

                                        return (
                                            <>
                                                <AvyPreviewModalOverlay
                                                    closing={isPreviewClosing}
                                                />
                                                <AvyPreviewModal
                                                    previewClosing={
                                                        isPreviewClosing
                                                    }
                                                    key={index}
                                                >
                                                    <ErrorBoundary>
                                                        <Component />
                                                    </ErrorBoundary>
                                                </AvyPreviewModal>
                                            </>
                                        )
                                    },
                                )}
                            <AvyModal
                                closing={modal.isClosing}
                                variant={modal.options.variant}
                            >
                                <ErrorBoundary>
                                    <Component />
                                </ErrorBoundary>
                            </AvyModal>
                        </AvyModalOverlay>
                    </FocusLock>
                )
            })}
        </>
    )
})
