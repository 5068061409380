import { makeAutoObservable } from "mobx"
import React from "react"

import { ContactFormAdminService } from "src/api"
import { CustomSegmentsAdminService } from "src/api/_custom/services/SegmentsAdminService"
import { Channel } from "src/channel"
import { FormFields } from "src/lib/form-fields"

interface IFormFields {
    id: number
    segmentIds: number[]
}

interface IAccessToggleFormFields {
    accessToggle: boolean
}

export class ContactFormTypeDetailStore {
    static Context = React.createContext<ContactFormTypeDetailStore | null>(
        null,
    )

    form = new FormFields<IFormFields>({ id: -1, segmentIds: [] })

    fields: string[] = []

    accessToggleFields = new FormFields<IAccessToggleFormFields>({
        accessToggle: false,
    })

    constructor() {
        makeAutoObservable(this)
    }

    init(
        id: number,
        segmentIds: number[],
        fields: string[],
        messagingEnabled: boolean,
    ) {
        // fetch chat access toggle and replace it in accessToggle
        this.form.init({ id, segmentIds })
        this.fields = fields
        this.accessToggleFields.init({
            accessToggle: messagingEnabled,
        })
    }

    async submit() {
        await this.form.catchErrors(async () => {
            await ContactFormAdminService.putV1AdminContactFormTypePublish({
                contactFormTypeId: this.form.get("id"),
                request: {
                    published_in: await this.getSegmentsForSubmission(),
                },
            })
            await ContactFormAdminService.patchV1AdminContactFormType({
                contactFormTypeId: this.form.get("id"),
                request: {
                    messaging_enabled:
                        this.accessToggleFields.get("accessToggle"),
                },
            })

            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "contact-form-types",
                    action: "update",
                },
            })
        })
    }

    private async getSegmentsForSubmission() {
        const ids = await this.getAccessibleSegmentIds()
        return this.form.get("segmentIds").filter((id) => ids.includes(id))
    }

    private async getAccessibleSegmentIds(): Promise<number[]> {
        const segments =
            await CustomSegmentsAdminService.getV1AdminSegmentSearch({})

        return segments.map((segment) => segment.id ?? -1)
    }
}
