import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useCallback, useEffect, useMemo } from "react"

import { ListPage } from "src/components/ListPage"
import { DataGridProTable } from "src/components/Table/DataGridPro"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import {
    getFilterModelForRepository,
    getSortModelForRepository,
} from "src/lib/data-grid-pro"
import { ConfirmModal } from "src/modals/confirm"
import { TagsModal } from "src/modals/tags"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { Repository } from "src/types/channel"
import { FilterModel, IColumn, SortModel } from "src/types/data-grid-pro"
import { ITag } from "src/types/tags/tag"
import { TagsStore } from "src/views/tags/store"

const repository: Repository = "tags"

const View = observer(() => {
    const store = useStore(TagsStore)
    const globalStore = useStore(GlobalStore)

    useEffect(() => {
        ;(async () => {
            if (globalStore.session.accessGroup.id !== undefined) {
                await store.init(
                    globalStore.session.accessGroup.id,
                    advanceQuery,
                )
            } else {
                await store.init(DEFAULT_ACCESS_GROUP.id, advanceQuery)
            }
        })()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalStore.session.accessGroup])

    const handleNewTagClick = useCallback(() => {
        globalStore.modals.open(() => <TagsModal />)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const header = useMemo(
        () => ({
            header: t`tags-view.header`,
            breadcrumbs: [t`navigation.chatbot`, t`tags-view.header`],
            createOptions: {
                item: t`tags-view.create-new`,
                onClick: handleNewTagClick,
            },
            disabled: globalStore.loading.is(TagsStore.LoadingKeys.init),
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [handleNewTagClick],
    )

    const advanceQuery = useMemo(() => {
        const filter = getFilterModelForRepository(
            repository,
            globalStore.session.dataGridFilterModel,
        )
        const sort = getSortModelForRepository(
            repository,
            globalStore.session.dataGridSortModel,
        )
        return { sort, filter }
    }, [
        globalStore.session.dataGridSortModel,
        globalStore.session.dataGridFilterModel,
    ])

    const handleFilterChange = useCallback(
        async (model: FilterModel) => {
            const sort = getSortModelForRepository(
                repository,
                globalStore.session.dataGridSortModel,
            )

            await store.query({ sort, filter: model })
        },
        [store, globalStore.session.dataGridSortModel],
    )

    const handleSortChange = useCallback(
        async (model: SortModel) => {
            const filter = getFilterModelForRepository(
                repository,
                globalStore.session.dataGridFilterModel,
            )

            await store.query({ filter, sort: model })
        },
        [store, globalStore.session.dataGridFilterModel],
    )

    const columns: IColumn<ITag>[] = [
        {
            field: "id",
            headerName: t`tags-view.id`,
            renderCell: (params) => params.value,
            type: "string",
            filterable: false,
            sortable: false,
        },
        {
            field: "name",
            headerName: t`tags-view.name`,
            renderCell: (params) => params.value,
            type: "string",
        },
    ]

    const handleEditItem = useCallback((item) => {
        if (item != null) {
            globalStore.modals.open(() => <TagsModal tag={item.row ?? item} />)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleDeleteItem = useCallback(
        (item: ITag) => {
            globalStore.modals.open(
                () => (
                    <ConfirmModal
                        onConfirm={async (confirmed) => {
                            if (confirmed) {
                                await store.deleteItem(item.id)
                            }
                        }}
                        title={t`tags-view.are-you-sure`}
                        content={t({
                            id: "tags-view.confirm-delete-item",
                            values: { dispatchName: item.name },
                        })}
                    />
                ),
                {
                    variant: "slide-up-w600",
                },
            )
        },
        [globalStore.modals, store],
    )

    const rowActionsRenderer = useCallback(
        (item: ITag) => {
            const actions = [
                {
                    text: t`tags-view.edit-item`,
                    onClick: () => handleEditItem(item),
                },
                {
                    text: t`tags-view.delete-item`,
                    onClick: () => handleDeleteItem(item),
                },
            ]

            return actions
        },
        [handleEditItem, handleDeleteItem],
    )

    return (
        <ListPage
            header={header}
            loading={globalStore.loading.is(TagsStore.LoadingKeys.init)}
        >
            <DataGridProTable
                paginator={store.tags}
                data={store.tags.items}
                columns={columns}
                advancedOperations={{
                    pagination: "server",
                    filtering: "server",
                    sorting: "server",
                }}
                onRowClickEvent={handleEditItem}
                rowActionsRenderer={rowActionsRenderer}
                repository={repository}
                loading={!store.tags.meta.initialized}
                onFilterChange={handleFilterChange}
                onSortChange={handleSortChange}
            />
        </ListPage>
    )
})

export const TagsView = () => (
    <StoreProvider Store={TagsStore}>
        <View />
    </StoreProvider>
)
