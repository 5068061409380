import { t } from "@lingui/macro"
import { Stack } from "@mui/material"
import { observer } from "mobx-react"

import { FormPanel } from "src/components/FormPanel"
import { SegmentPickerField } from "src/components/SegmentPickerField"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { LibraryInformationItemStore } from "src/modals/library-information-items/store"
import { useStore } from "src/store/lib/useStore"

export const LibraryInformationItemsPublishSettingsSection = observer(() => {
    const store = useStore(LibraryInformationItemStore)

    const setPublishedSegments = (segments: number[]) =>
        store.formFields.set("publishedIn", segments)

    const isDisabled =
        store.formFields.get("accessType") === "READ" ||
        store.formFields.get("accessGroupId") == null ||
        store.formFields.get("accessGroupId") === DEFAULT_ACCESS_GROUP.id

    return (
        <FormPanel
            sections={[
                {
                    header: t`edit-information-item-modal.publishing-settings`,
                    content: (
                        <Stack spacing={2}>
                            <SegmentPickerField
                                value={
                                    store.formFields.get("publishedIn") ?? []
                                }
                                accessGroupId={store.getAccessGroupIdForPublishSection()}
                                onChange={setPublishedSegments}
                                disabled={isDisabled}
                            />
                        </Stack>
                    ),
                },
            ]}
        />
    )
})
