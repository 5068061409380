import { useContext } from "react"

import { StoreProvider } from "src/store/lib/StoreProvider"

export const useStore = <T>(Store: {
    new (): T
    Context: React.Context<T>
}) => {
    const store = useContext(Store.Context)
    if (store == null) {
        throw new Error(
            `Could not find a context for ${Store.name}. Make sure you have added a ${StoreProvider.name} to a parent in the tree.`,
        )
    }
    return store as NonNullable<T>
}
