import { t } from "@lingui/macro"
import { observer } from "mobx-react"

import { EmbeddedIntegrationStore } from "./store"

import { FormFieldSet } from "src/components/FormFieldSet"
import { SegmentPickerField } from "src/components/SegmentPickerField"
import { useStore } from "src/store/lib/useStore"

export const PublishSettingsSection = observer(() => {
    const store = useStore(EmbeddedIntegrationStore)
    return (
        <>
            <FormFieldSet
                header={t`embed-integrations-modal.publish-settings-section.receivers-header`}
            >
                <SegmentPickerField
                    value={store.fields.get("segmentIds")}
                    accessGroupId={store.accessGroupId}
                    onChange={(segments) =>
                        store.fields.set("segmentIds", segments)
                    }
                />
            </FormFieldSet>
        </>
    )
})
