import { useCallback } from "react"

import { IRankable, sortListFromResult } from "src/lib/ranking"
import {
    PassthroughDragAndDropListProps,
    DragAndDropList,
} from "src/components/DragAndDropList"

interface IProps<TItem extends IRankable>
    extends PassthroughDragAndDropListProps<TItem> {
    onSort: (item: TItem[]) => void
}

/**
 * RankableList sorts items that confirms to the {@link IRankable} interface.
 *
 * A thin wrapper around {@link DragAndDropList}
 */
export const RankableList = <TItem extends IRankable>(props: IProps<TItem>) => {
    const handleDrop = useCallback(
        (result: IDropResult) => {
            props.onSort(sortListFromResult(props.items, result))
        },
        [props],
    )

    return (
        <DragAndDropList
            items={props.items}
            onDrop={handleDrop}
            rowRenderer={props.rowRenderer}
            rowActionsRenderer={props.rowActionsRenderer}
        />
    )
}
