import {
    IDynamicFieldsListItemProps,
    IExternalDynamicFieldsListItemProps,
    IMetaDataFieldListItemProps,
} from "src/modals/contact-forms-form-builder/types/fieldsListItem"

export const isBinButtonDisabled = (
    array:
        | IDynamicFieldsListItemProps[]
        | IExternalDynamicFieldsListItemProps[]
        | IMetaDataFieldListItemProps[],
) => array.length === 1
