import React from "react"
import { makeAutoObservable } from "mobx"
import { t } from "@lingui/macro"

import { ILegalEntity } from "./legal-entity/store"

import { reportError } from "src/lib/report"
import { Channel } from "src/channel"
import { loads } from "src/channel/utils"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { AdminService } from "src/api"
import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"

export interface IPropertyOwner {
    createdDate: string
    integrationType: string
    legalName: string
    modifiedDate: string
    organisationId: string
    propertyOwnerId: number
    fastapiConnectorIntegrationId?: number
    propertyOwnerType: string
    id: string | number
    mfaRequired: boolean
    syncTenantContact?: boolean
}

export class ClientManagementStore {
    static Context = React.createContext<ClientManagementStore | null>(null)
    static LoadingKeys = createLoadingKeys(
        "pageLoad",
        "init",
        "getProperties",
        "fastApiRequest",
    )

    private _segments: number[] = []
    private _accessGroupId: number = DEFAULT_ACCESS_GROUP.id

    private repositoryUpdatesListenerDisposer?: () => void

    get segments() {
        return this._segments
    }

    private _propertyOwners: IPropertyOwner[] = []
    get propertyOwners() {
        return this._propertyOwners
    }
    private setPropertyOwners(propertyOwners: IPropertyOwner[]) {
        this._propertyOwners = propertyOwners
    }

    private _legalEntities: ILegalEntity[] = []
    get legalEntities() {
        return this._legalEntities
    }
    setLegalEntities = (legalEntities: ILegalEntity[]) => {
        this._legalEntities = legalEntities
    }

    async loadSegments(segments: number[]) {
        this.setSegments(segments)
        await this.loadPropertyOwners()
    }

    @loads(() => ClientManagementStore.LoadingKeys.init)
    async init(accessGroupId: number, segments: number[]) {
        this.listenToResourcesRepositoryUpdated()
        this.setAccountGroupId(accessGroupId)
        await this.loadSegments(segments)
    }

    dispose() {
        this.repositoryUpdatesListenerDisposer?.()
    }

    private listenToResourcesRepositoryUpdated() {
        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "property-owners"
                ) {
                    await this.loadPropertyOwners()
                }
            },
        )
    }

    @loads(() => ClientManagementStore.LoadingKeys.getProperties)
    async getLegalEntities(propertyOwnerId: number) {
        const legalEntitiesResponse = await AdminService.getV1AdminLegalEntity({
            propertyOwnerId: propertyOwnerId,
        })

        this.setLegalEntities(
            legalEntitiesResponse.map((legalEntity) => {
                return {
                    createdDate: legalEntity.created_date ?? "",
                    integrationActive: legalEntity.integration_active ?? false,
                    integrationId: legalEntity.integration_id ?? "",
                    externalId: legalEntity.external_id ?? "",
                    integrationType: legalEntity.integration_type ?? "",
                    leafletUrl: legalEntity.leaflet_url ?? "",
                    legalEntityId: legalEntity.legal_entity_id ?? 0,
                    legalName: legalEntity.legal_name ?? "",
                    modifiedDate: legalEntity.modified_date ?? "",
                    organisationId: legalEntity.org_id ?? "",
                    propertyOwnerId: legalEntity.property_owner_id ?? 0,
                    selfOnboardingCode: legalEntity.self_onboarding_code ?? "",
                    selfOnboardingEnabled:
                        legalEntity.self_onboarding_enabled ?? false,
                    type: legalEntity.type ?? "",
                    id: legalEntity.legal_entity_id ?? 0,
                }
            }) ?? [],
        )
    }

    @loads(() => ClientManagementStore.LoadingKeys.fastApiRequest)
    async propertyOwnerFastApiRequest(
        propertyOwnerId: number,
        fullLoad: boolean,
    ) {
        await AdminService.postV1AdminIntegrationFastapiRequest({
            request: {
                property_owner_id: propertyOwnerId,
                full_load: fullLoad,
            },
        })
    }

    private setAccountGroupId(accessGroupId: number) {
        this._accessGroupId = accessGroupId
    }

    private setSegments(segments: number[]) {
        this._segments = segments
    }

    propertyOwner = new Pagination(
        (params) => {
            const pageStart = (params.page - 1) * params.pageSize
            const pagePropertyOwners = this._propertyOwners.slice(
                pageStart,
                pageStart + params.pageSize,
            )
            return {
                items: pagePropertyOwners,
                count: this._propertyOwners.length,
                sourceItems: this._propertyOwners,
            }
        },
        { static: true },
    )

    private async loadPropertyOwners() {
        try {
            const propertyOwnerResponse =
                await AdminService.getV1AdminPropertyOwner()
            this.setPropertyOwners(
                propertyOwnerResponse.map(
                    (propertyOwner): IPropertyOwner => ({
                        createdDate: propertyOwner.created_date ?? "",
                        integrationType: propertyOwner.integration_type ?? "",
                        fastapiConnectorIntegrationId:
                            propertyOwner.fastapi_connector_integration_id ?? 0,
                        legalName: propertyOwner.legal_name ?? "",
                        modifiedDate: propertyOwner.modified_date ?? "",
                        organisationId: propertyOwner.org_id ?? "",
                        propertyOwnerId: propertyOwner.property_owner_id ?? 0,
                        propertyOwnerType:
                            propertyOwner.property_owner_type ?? "",
                        id: propertyOwner.property_owner_id ?? 0,
                        mfaRequired: propertyOwner.mfa_required ?? false,
                        syncTenantContact:
                            propertyOwner.sync_tenant_contact ?? false,
                    }),
                ) ?? [],
            )
        } catch (e) {
            reportError(
                t`client-management-property-owners-table.failed-to-load-property-owners`,
                e,
            )
        }
        await this.propertyOwner.reload()
    }

    constructor() {
        makeAutoObservable(this)
    }
}
