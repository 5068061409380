import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material"
import { observer } from "mobx-react"
import { t } from "@lingui/macro"
import { useEffect } from "react"

import { InfoCenterAdminService } from "src/api"

import { getItemTypeText } from "src/helpers/getItemTypeText"
import { useStore } from "src/store/lib/useStore"
import { LibraryInformationItemStore } from "src/modals/library-information-items/store"
import { reportError } from "src/lib/report"
import { ItemTypes } from "src/types/library-information-items/item-types"

export const LibraryInformationItemsType = observer(() => {
    const store = useStore(LibraryInformationItemStore)

    useEffect(() => {
        ;(async () => {
            try {
                store.setItemTypes(
                    (await InfoCenterAdminService.infocenterGetInformationTypes()) as ItemTypes[],
                )
            } catch (error) {
                reportError(
                    t`edit-information-item-modal.load-types-fail`,
                    error,
                )
            }
        })()
    }, [store])

    return (
        <FormControl style={{ breakInside: "avoid" }}>
            <InputLabel id="type-label">{t`edit-information-item-modal.type`}</InputLabel>
            {store.itemTypes.length > 0 && (
                <Select
                    labelId="type-label"
                    id="type"
                    value={store.formFields.get("type") ?? ""}
                    label={t`edit-information-item-modal.type`}
                    onChange={(event) =>
                        store.formFields.set("type", event.target.value)
                    }
                    error={Boolean(store.formFields.error("type"))}
                    disabled={store.formFields.get("accessType") === "READ"}
                >
                    {store.itemTypes.map((type) => (
                        <MenuItem value={type} key={type}>
                            {getItemTypeText(type)}
                        </MenuItem>
                    ))}
                </Select>
            )}
            {Boolean(store.formFields.error("type")) && (
                <FormHelperText error={true}>
                    {store.formFields.error("type")}
                </FormHelperText>
            )}
        </FormControl>
    )
})
