import {
    Stack,
    Select,
    MenuItem,
    SelectChangeEvent,
    FormControl,
    IconButton,
    InputLabel,
    useTheme,
} from "@mui/material"
import ThumbUpIcon from "@mui/icons-material/ThumbUp"
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt"
import { observer } from "mobx-react"

import { t } from "@lingui/macro"

import { useCallback } from "react"

import {
    FeedbackContainer,
    ItemContainer,
    AdminItemContainer,
    Separator,
    TextContainer,
    IconContainer,
    IconBackground,
    FeedbackTextContainer,
    SelectContainer,
    Typography400,
    Typography500,
} from "src/modals/chatbot/styled"

import { ChatbotDetailStore } from "src/modals/chatbot/store"

import { useWindowWide } from "src/views/feature-toggles/features/useWindowWide"
import { Icon } from "src/components/icons"
import { useStore } from "src/store/lib/useStore"
import { ChatbotStatus } from "src/types/chatbot"
import { chatbotConversationsConstants } from "src/views/chatbot/constants"

interface IFeedbackProps {
    feedback: string
    userThumbStatus: number
    adminThumbStatus?: number
    adminReason: string
}

export const Feedback = observer((props: IFeedbackProps) => {
    const store = useStore(ChatbotDetailStore)
    const windowWidth = useWindowWide(0)
    const theme = useTheme()

    const handleChange = (event: SelectChangeEvent<string>) => {
        store.formFields.set("admin_feedback_reason", event.target.value)
    }

    const adminFeedbackThumbStatus = store.formFields.get(
        "admin_feedback_thumb_status",
    )

    const handleClick = useCallback(
        (status: number | undefined) => {
            const statusUpdate =
                adminFeedbackThumbStatus === status ? undefined : status

            store.formFields.set("admin_feedback_thumb_status", statusUpdate)
        },
        [store.formFields, adminFeedbackThumbStatus],
    )

    return (
        <Stack direction="column" justifyContent="space-between">
            <FeedbackContainer windowWidth={windowWidth}>
                <ItemContainer>
                    <TextContainer>
                        <Typography500>
                            {t`chatbot-view.modal.tenant-feedback`}
                        </Typography500>
                        <Typography400>
                            {props.feedback !== "" ? `"${props.feedback}"` : ""}
                        </Typography400>
                    </TextContainer>
                    {props.userThumbStatus === -1 ? (
                        <Typography400 color={theme.palette.grey[600]}>
                            {ChatbotStatus.Unknown}
                        </Typography400>
                    ) : (
                        <Icon
                            icon={
                                props.userThumbStatus > 0 ? (
                                    <ThumbUpIcon />
                                ) : (
                                    <ThumbDownAltIcon />
                                )
                            }
                            color={
                                props.userThumbStatus > 0
                                    ? theme.palette.generalColors.green
                                    : theme.palette.generalColors.red
                            }
                        />
                    )}
                </ItemContainer>
            </FeedbackContainer>
            <Separator />
            <FeedbackContainer windowWidth={windowWidth}>
                <AdminItemContainer>
                    <FeedbackTextContainer>
                        <Typography500>
                            {t`chatbot-view.modal.admin-feedback`}
                        </Typography500>
                    </FeedbackTextContainer>
                    <IconContainer>
                        <IconButton
                            onClick={() => handleClick(1)}
                            style={{
                                borderRadius: 15,
                            }}
                        >
                            {adminFeedbackThumbStatus === 1 ? (
                                <IconBackground>
                                    <Icon
                                        icon={<ThumbUpIcon />}
                                        color={
                                            theme.palette.generalColors.green
                                        }
                                    />
                                </IconBackground>
                            ) : (
                                <Icon
                                    icon={<ThumbUpIcon />}
                                    color={theme.palette.grey[600]}
                                />
                            )}
                        </IconButton>
                        <IconButton
                            onClick={() => handleClick(0)}
                            style={{
                                borderRadius: 15,
                            }}
                        >
                            {adminFeedbackThumbStatus === 0 ? (
                                <IconBackground>
                                    <Icon
                                        icon={<ThumbDownAltIcon />}
                                        color={theme.palette.generalColors.red}
                                    />
                                </IconBackground>
                            ) : (
                                <Icon
                                    icon={<ThumbDownAltIcon />}
                                    color={theme.palette.grey[600]}
                                />
                            )}
                        </IconButton>
                    </IconContainer>
                    {adminFeedbackThumbStatus === 0 && (
                        <SelectContainer>
                            <FormControl style={{ breakInside: "avoid" }}>
                                <InputLabel id="test-select-label">
                                    {t`chatbot-view.modal.select.label`}
                                </InputLabel>
                                <Select
                                    labelId="administrator-feedback-label"
                                    id="administrator"
                                    value={
                                        store.formFields.get(
                                            "admin_feedback_reason",
                                        ) ?? ""
                                    }
                                    label={t`chatbot-view.modal.select.label`}
                                    onChange={handleChange}
                                >
                                    {chatbotConversationsConstants.adminFeedbackReasons.map(
                                        (value) => (
                                            <MenuItem value={value} key={value}>
                                                {value}
                                            </MenuItem>
                                        ),
                                    )}
                                </Select>
                            </FormControl>
                        </SelectContainer>
                    )}
                </AdminItemContainer>
            </FeedbackContainer>
        </Stack>
    )
})
