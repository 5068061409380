import { t } from "@lingui/macro"
import { Box, Link } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"

import { PdfLink } from "./styled"

import { IStatusChipProps, StatusChip } from "src/components/StatusChip"

import { Table } from "src/components/Table"
import { formatDate } from "src/lib/date"
import { formatPrice } from "src/lib/number"
import { IInvoice } from "src/views/invoices"

import { Pagination } from "src/lib/pagination"
import { uniqueId } from "src/lib/unique-id"

import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { MarkInvoiceAsPaidModal } from "src/modals/mark-invoice-as-paid"

interface IProps {
    invoices: Pagination<IInvoice, unknown>
}

export const InvoicesList = observer((props: IProps) => {
    const globalStore = useStore(GlobalStore)

    const getStatusChipProps: (
        status: string,
        dueDate: Date | undefined,
    ) => IStatusChipProps = (status: string, dueDate: Date | undefined) => {
        switch (status) {
            case "Cancelled":
                return {
                    state: "neutral",
                    label: t`invoices-view.invoice-status.cancelled`,
                }
            case "Handled":
                return {
                    state: "negative",
                    label: t`invoices-view.invoice-status.handled`,
                }
            case "Late":
                if (dueDate != null && new Date() > dueDate) {
                    return {
                        state: "negative",
                        label: t`invoices-view.invoice-status.late`,
                    }
                } else {
                    return {
                        state: "other",
                        label: t`invoices-view.invoice-status.unknown`,
                    }
                }
            case "Paid":
                return {
                    state: "positive",
                    label: t`invoices-view.invoice-status.paid`,
                }
            case "Pending":
                return {
                    state: "neutral",
                    label: t`invoices-view.invoice-status.pending`,
                }
            case "Unpaid":
                return {
                    state: "negative",
                    label: t`invoices-view.invoice-status.unpaid`,
                }
            default:
                return {
                    state: "other",
                    label: t`invoices-view.invoice-status.unknown`,
                }
        }
    }

    const rowRenderer = useCallback((item: IInvoice) => {
        return {
            pdf:
                item.pdfUrl != null && item.pdfUrl !== "" ? (
                    <PdfLink target={"_blank"} href={item.pdfUrl}>
                        <PictureAsPdfIcon />
                    </PdfLink>
                ) : (
                    ""
                ),
            status:
                item.status != null ? (
                    <StatusChip
                        {...getStatusChipProps(item.status, item.dueDate)}
                    />
                ) : (
                    ""
                ),
            ocr: item.ocr,
            invoiceNumber: item.invoiceNumber ?? "",
            amount:
                item.totalAmount != null ? (
                    <span style={{ textAlign: "right", display: "block" }}>
                        {formatPrice(item.totalAmount, false, item.currency)}
                    </span>
                ) : (
                    ""
                ),
            dateIssued:
                item.invoiceDate != null ? formatDate(item.invoiceDate) : "",
            dateDue: item.dueDate != null ? formatDate(item.dueDate) : "",
            dateRegistered:
                item.transactionDate != null
                    ? formatDate(item.transactionDate)
                    : "",
            issuer: item.bankgiroName ?? "",
            recipient:
                item.tenantName != null ? (
                    <Link href={`/tenants/${item.tenantId}`}>
                        {item.tenantName}
                    </Link>
                ) : (
                    ""
                ),
        }
    }, [])

    return (
        <Table
            paginator={props.invoices}
            headers={[
                {
                    key: "pdf",
                    name: t`invoices-view.table-heading.pdf`,
                },
                {
                    key: "status",
                    name: t`invoices-view.table-heading.status`,
                },
                {
                    key: "ocr",
                    name: t`invoices-view.table-heading.ocr`,
                },
                {
                    key: "invoiceNumber",
                    name: t`invoices-view.table-heading.invoiceNumber`,
                },
                {
                    key: "amount",
                    name: (
                        <Box
                            sx={{ textAlign: "right" }}
                        >{t`invoices-view.table-heading.amount`}</Box>
                    ),
                },
                {
                    key: "dateIssued",
                    name: t`invoices-view.table-heading.dateIssued`,
                },
                {
                    key: "dateDue",
                    name: t`invoices-view.table-heading.dateDue`,
                },
                {
                    key: "dateRegistered",
                    name: t`invoices-view.table-heading.dateRegistered`,
                },
                {
                    key: "issuer",
                    name: t`invoices-view.table-heading.issuer`,
                },
                {
                    key: "recipient",
                    name: t`invoices-view.table-heading.recipient`,
                },
            ]}
            rowRenderer={rowRenderer}
            emptyStateText={t`invoices-view.table.no-information-text`}
            // There's no way to reliably set a unique key for each row with
            // the data provided. This will set the row key to a unique key
            // for every row and render. This will cause the row to rerender
            // for every change, which isn't optimal performance wise but
            // it's necessary for now.
            rowKeyResolver={uniqueId}
            rowActionsRenderer={(item: IInvoice) =>
                ["Unpaid", "Pending", "Handled"].includes(item.status ?? "")
                    ? [
                          {
                              text: t`invoices-view.table-action.mark-as-paid`,
                              onClick: () =>
                                  globalStore.modals.open(
                                      () => (
                                          <MarkInvoiceAsPaidModal
                                              id={item.id}
                                          />
                                      ),
                                      { variant: "slide-up-w600" },
                                  ),
                          },
                      ]
                    : []
            }
        />
    )
})
