import { Chip, SxProps, Theme, useTheme } from "@mui/material"
import { useCallback, useMemo } from "react"

import { CommunityInsightsChipDialog } from "src/modals/community-insights/CommunityInsightsChipDialog"
import { GlobalStore } from "src/store"

import { useStore } from "src/store/lib/useStore"
import useWarningColor, {
    warningPicker,
} from "src/views/community-insights/hook/useWarningColor"
import {
    CommunityInsightsSeverityLevel,
    ICommunitiesInsight,
    TCommunityPeriod,
} from "src/views/community-insights/types"

interface IRenderCellProps {
    period: TCommunityPeriod
    item: ICommunitiesInsight
}
export const RowCell = ({ period, item }: IRenderCellProps) => {
    const gstore = useStore(GlobalStore)
    const { palette } = useTheme()
    const warningColors = useWarningColor
    const chipColor = warningColors(period.warningScore)
    const textColor =
        warningPicker(period.warningScore) ===
            CommunityInsightsSeverityLevel.SEVERE ||
        warningPicker(period.warningScore) ===
            CommunityInsightsSeverityLevel.HIGH
            ? palette.common.white
            : palette.common.black

    const handleChipClick = useCallback(
        (period: TCommunityPeriod, item: ICommunitiesInsight) => {
            gstore.modals.open(
                () => (
                    <CommunityInsightsChipDialog
                        communityName={`${item.internal_name} (${item.id})`}
                        period={period.period}
                        hitsNumber={period.value}
                        color={chipColor}
                        subject="community"
                        clusters={period.clusters}
                        nbrThreads={period.nbrThreads}
                        nbrThreadsTopic={period.nbrThreadsTopic}
                    />
                ),
                { variant: "slide-up-w600" },
            )
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [gstore.modals],
    )

    const chipColorStyle = useMemo(
        (): SxProps<Theme> | undefined => ({
            "&:hover": {
                backgroundColor: chipColor,
            },
            flex: 1,
            width: "100%",
            height: 24,
            minWidth: 64,
            borderRadius: "4px",
            backgroundColor: chipColor,
            padding: 0,
            color: textColor,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [period.warningScore],
    )

    return (
        <Chip
            onClick={() => handleChipClick(period, item)}
            label={period.value}
            sx={chipColorStyle}
        />
    )
}
