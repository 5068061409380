import { styled, alpha, ToggleButtonGroup } from "@mui/material"

export const TabWrapper = styled("section")(({ theme }) => ({
    background: theme.palette.grey[100],
    padding: "1rem",
    borderRadius: "0.5rem",
}))

export const CardItemWrapper = styled("div")(() => ({
    paddingTop: "1rem",
}))

export const DraggableContainer = styled("div")(() => ({
    marginBottom: "20px",
}))

export const LinkHeader = styled("h2")(() => ({
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: 1.6,
    margin: 0,
}))

export const EmbedIntegrationToggleChipGroup = styled(ToggleButtonGroup)(
    ({ theme }) => ({
        flexWrap: "wrap",
        "& .MuiToggleButtonGroup-grouped": {
            margin: "0 8px 8px",
            background: "transparent",
            border: `1px solid ${theme.palette.divider}`,
            color: theme.palette.grey[600],
            padding: "0 12px",
            height: "32px",
            textTransform: "none",
            fontSize: "13px",
            fontFamily: "Roboto",
            fontWeight: "400",
            "&:disabled:not(.Mui-selected)": {
                border: "none",
                background: alpha(theme.palette.divider, 0.6),
            },
            "&.Mui-selected, &.Mui-selected:hover": {
                border: `1px solid ${theme.palette.info.main}`,
                background: alpha(theme.palette.info.main, 0.2),
                color: `${theme.palette.info.main} !important`,
            },
            "&:not(:first-of-type), &:first-of-type": {
                borderRadius: "100px",
            },
        },
    }),
)
