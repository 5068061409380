/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { tags_CreateUpdateTag } from "../models/tags_CreateUpdateTag"
import type { tags_GetTagsResponse } from "../models/tags_GetTagsResponse"
import type { tags_Tag } from "../models/tags_Tag"
import type { tags_TagFilter } from "../models/tags_TagFilter"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class TagsAdminService {
    /**
     * Create Tag
     * Takes a body with tag to be created
     * @returns tags_Tag OK
     * @throws ApiError
     */
    public static postV1AdminTag({
        request,
    }: {
        /** Tag **/
        request: tags_CreateUpdateTag
    }): CancelablePromise<tags_Tag> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/tag",
            body: request,
        })
    }

    /**
     * Get tags
     * Gets all available tags for the admin. Tags are global to propertyowner
     * @returns tags_GetTagsResponse OK
     * @throws ApiError
     */
    public static postV1AdminTagList({
        request,
    }: {
        /** Tag Filter **/
        request: tags_TagFilter
    }): CancelablePromise<tags_GetTagsResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/tag/list",
            body: request,
        })
    }

    /**
     * Get tag
     * Gets one tag by ID
     * @returns tags_Tag OK
     * @throws ApiError
     */
    public static getV1AdminTag({
        tagId,
    }: {
        /** Tag ID **/
        tagId: string
    }): CancelablePromise<tags_Tag> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/tag/{tag_id}",
            path: {
                tag_id: tagId,
            },
        })
    }

    /**
     * Update Tag
     * Takes a body with tag to be updated
     * @returns tags_Tag OK
     * @throws ApiError
     */
    public static putV1AdminTag({
        request,
        tagId,
    }: {
        /** Tag **/
        request: tags_CreateUpdateTag
        /** tag id **/
        tagId: string
    }): CancelablePromise<tags_Tag> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/tag/{tag_id}",
            path: {
                tag_id: tagId,
            },
            body: request,
        })
    }

    /**
     * Delete tag (soft delete)
     * Takes an id of the tag to be deleted
     * @returns any OK
     * @throws ApiError
     */
    public static deleteV1AdminTag({
        tagId,
    }: {
        /** Tag ID **/
        tagId: string
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/tag/{tag_id}",
            path: {
                tag_id: tagId,
            },
        })
    }
}
