import { IIconProps } from "src/components/icons"

export const PropertyOwner16 = (props: IIconProps) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.6666 7.99998C14.6666 4.31808 11.6818 1.33331 7.99992 1.33331C4.31802 1.33331 1.33325 4.31808 1.33325 7.99998C1.33325 11.6819 4.31802 14.6666 7.99992 14.6666H8.06659C11.7104 14.6636 14.6635 11.7105 14.6666 8.06665V7.99998ZM13.5555 7.99998C13.5544 8.49377 13.4872 8.98519 13.3555 9.46109C13.3358 9.53871 13.283 9.60374 13.211 9.63887C13.1469 9.67522 13.0712 9.68519 12.9999 9.66665C12.6559 9.5185 12.3624 9.27332 12.1555 8.96109L10.9221 7.11109C10.6642 6.72541 10.2306 6.49404 9.76659 6.49442H9.5277C8.68393 6.49442 7.99992 5.81041 7.99992 4.96665C7.99992 4.12288 8.68393 3.43887 9.5277 3.43887H11.0666C11.1243 3.43766 11.1808 3.45521 11.2277 3.48887C12.6854 4.52945 13.5521 6.20897 13.5555 7.99998ZM2.81659 6.74998C2.68201 6.74818 2.56632 6.84498 2.54436 6.97776C2.47701 7.31431 2.44351 7.65676 2.44436 7.99998C2.45472 10.2105 3.77465 12.2046 5.80547 13.0778C5.88755 13.1193 5.98451 13.1193 6.06659 13.0778C6.14687 13.0275 6.19526 12.9391 6.19436 12.8444V12.3833C6.19374 12.0433 6.31834 11.715 6.54436 11.4611L7.29992 10.6111C7.98401 9.84177 7.94991 8.67256 7.22214 7.94442L6.59436 7.30553C6.23017 6.94351 5.73561 6.74329 5.22214 6.74998H2.81659Z"
            fill="currentColor"
        />
    </svg>
)
