import { t } from "@lingui/macro"
import { useMemo } from "react"
import { Typography, Box } from "@mui/material"

import { IUserInputHistorySectionProps } from "src/components/ChatMessageJourney/types"

const UserInputHistorySection = ({
    messages,
}: IUserInputHistorySectionProps) => {
    const userInputHistory = useMemo(
        () =>
            messages.filter((message) => message.messageType === "USER_INPUT"),
        [messages],
    )

    if (userInputHistory.length === 0) {
        return null
    }

    return (
        <Box component="section" sx={{ marginBottom: 2 }}>
            <Typography variant="subtitle2" sx={{ marginBottom: 0.5 }}>
                {t`chat-message-journey.user-input-history`}
            </Typography>
            {userInputHistory.map((message, index) => (
                <Typography
                    key={index}
                    variant="body2"
                    sx={{ marginBottom: 0.5 }}
                >
                    {t`chat-message-journey.input`} {index + 1}:{" "}
                    {message?.text ?? ""}
                </Typography>
            ))}
        </Box>
    )
}

export default UserInputHistorySection
