import React from "react"
import { makeAutoObservable } from "mobx"

import { t } from "@lingui/macro"

import { Channel } from "src/channel"
import { ILegalEntity } from "src/views/client-management/legal-entity/store"
import { createLoadingKeys } from "src/lib/loading"
import { FormFields } from "src/lib/form-fields"
import { loads } from "src/channel/utils"
import { AdminService } from "src/api"
import { reportError, reportUnhandledApiError } from "src/lib/report"
import { ModalsStore } from "src/store/modals"

export interface IFormFields {
    propertyOwnerId: number | undefined
    legalEntityId: number | undefined
    name: string
    city: string
    externalId: string
    integrationId: number | undefined
    selfOnboardingEnabled: boolean
    integrationActive: boolean
    id: number | undefined | string
    leaflet: string
}

export class CreatePropertyModalStore {
    modals = new ModalsStore()

    static Context = React.createContext<CreatePropertyModalStore | null>(null)
    static LoadingKeys = createLoadingKeys(
        "submit",
        "init",
        "getLegalEntities",
        "editProperty",
        "delete",
    )
    private id?: number
    fields = new FormFields<IFormFields>({
        propertyOwnerId: undefined,
        legalEntityId: undefined,
        name: "",
        city: "",
        externalId: "",
        integrationId: undefined,
        selfOnboardingEnabled: false,
        integrationActive: false,
        id: "",
        leaflet: "",
    })

    private _legalEntities: ILegalEntity[] = []
    get legalEntities() {
        return this._legalEntities
    }

    //due the LoadingKeys isn't working properly we are testing this `isLoading` property
    //to replace the previous submit validation
    isLoading = false

    setLegalEntities = (legalEntities: ILegalEntity[]) => {
        this._legalEntities = legalEntities
    }

    constructor() {
        makeAutoObservable(this)
    }

    @loads(() => CreatePropertyModalStore.LoadingKeys.init)
    async init(
        propertyOwnerId: number | undefined,
        legalEntityId: number | undefined,
        id?: number,
    ) {
        this.setLegalId(id)
        this.setIsLoading(true)
        if (propertyOwnerId != null) {
            await this.getLegalEntities(propertyOwnerId)
        }
        try {
            if (id != null) {
                const response = await AdminService.getV1AdminProperty({
                    propertyId: id ?? 0,
                })
                this.fields.init({
                    legalEntityId: response.legal_entity_id ?? legalEntityId,
                    name: response.name ?? "",
                    city: response.area ?? "",
                    externalId: response.external_id ?? "",
                    integrationId: response.integration_id,
                    selfOnboardingEnabled:
                        response.self_onboarding_enabled ?? false,
                    integrationActive: response.integration_active ?? false,
                    id: id,
                    leaflet: response.leaflet ?? "",
                    propertyOwnerId:
                        propertyOwnerId != null ? propertyOwnerId : undefined,
                })
            } else {
                this.fields.init({
                    ...this.fields.data,
                    id,
                    propertyOwnerId:
                        propertyOwnerId != null ? propertyOwnerId : undefined,
                    legalEntityId:
                        legalEntityId != null ? legalEntityId : undefined,
                })
            }
        } catch (e) {
            reportUnhandledApiError(e)
        }
        this.setIsLoading(false)
    }

    @loads(() => CreatePropertyModalStore.LoadingKeys.getLegalEntities)
    async getLegalEntities(propertyOwnerId: number) {
        const legalEntitiesResponse = await AdminService.getV1AdminLegalEntity({
            propertyOwnerId: propertyOwnerId,
        })
        this.setLegalEntities(
            legalEntitiesResponse.map((legalEntity) => {
                return {
                    createdDate: legalEntity.created_date ?? "",
                    integrationActive: legalEntity.integration_active ?? false,
                    integrationId: legalEntity.integration_id ?? "",
                    externalId: legalEntity.external_id ?? "",
                    integrationType: legalEntity.integration_type ?? "",
                    leafletUrl: legalEntity.leaflet_url ?? "",
                    legalEntityId: legalEntity.legal_entity_id ?? 0,
                    legalName: legalEntity.legal_name ?? "",
                    modifiedDate: legalEntity.modified_date ?? "",
                    organisationId: legalEntity.org_id ?? "",
                    propertyOwnerId: legalEntity.property_owner_id ?? 0,
                    selfOnboardingCode: legalEntity.self_onboarding_code ?? "",
                    selfOnboardingEnabled:
                        legalEntity.self_onboarding_enabled ?? false,
                    type: legalEntity.type ?? "",
                    id: legalEntity.legal_entity_id ?? 0,
                }
            }) ?? [],
        )
    }

    @loads(() => CreatePropertyModalStore.LoadingKeys.submit)
    private setLegalId(legalId?: number) {
        this.id = legalId
    }
    async submit() {
        await this.fields.catchErrors(async () => {
            const { data } = this.fields
            this.setIsLoading(true)
            if (this.id == null) {
                const response = await AdminService.postV1AdminProperty({
                    request: [
                        {
                            city: data.city,
                            external_id: data.externalId,
                            integration_id: data.integrationId,
                            integration_active: data.integrationActive,
                            legal_entity_id: data.legalEntityId,
                            name: data.name,
                            self_onboarding_enabled: data.selfOnboardingEnabled,
                        },
                    ],
                })

                const getResponseId = (): number => {
                    return response?.property_ids != null &&
                        response.property_ids.length > 0
                        ? response.property_ids[0]
                        : 0
                }

                Channel.send({
                    name: "repository/updated",
                    payload: {
                        repository: "properties",
                        action: "create",
                        item: {
                            id: getResponseId(),
                            name: data.name,
                        },
                    },
                })
            } else {
                await AdminService.patchV1AdminProperty({
                    request: {
                        external_id: data.externalId,
                        integration_active: data.integrationActive ?? false,
                        integration_id: data.integrationId,
                        self_onboarding_enabled: data.selfOnboardingEnabled,
                        leaflet: data.leaflet,
                        name: data.name,
                        legal_entity_id: data.legalEntityId,
                        city: data.city,
                    },
                    propertyId: Number(data.id),
                })
                Channel.send({
                    name: "repository/updated",
                    payload: {
                        repository: "properties",
                        action: "create",
                        item: {
                            id: this.id,
                            name: data.name,
                        },
                    },
                })
            }
            this.setIsLoading(false)
        })
    }

    setIsLoading = (value: boolean) => {
        this.isLoading = value
    }
    @loads(() => CreatePropertyModalStore.LoadingKeys.delete)
    async delete(id: number | string) {
        const { data } = this.fields
        try {
            const propertyId = parseInt(id.toString())
            await AdminService.deleteV1AdminProperty({
                propertyId,
            })
            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "properties",
                    action: "create",
                    item: {
                        id: propertyId,
                        name: data.name,
                    },
                },
            })
        } catch (e) {
            reportError(
                t`create-new-property-modal.failed-deleting-property`,
                e,
            )
        }
    }
}
