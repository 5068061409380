import { t } from "@lingui/macro"
import { makeAutoObservable } from "mobx"
import React from "react"

import {
    ProductCatalogueAdminService,
    cataloguedomain_CreateProvider,
    cataloguedomain_CreateOrderOutput,
    admin_ProviderResponse,
    FileAdminService,
} from "src/api"
import { Channel } from "src/channel"
import { loads } from "src/channel/utils"
import { isLocalFile, isPersistedFile } from "src/lib/file"
import { FormFields } from "src/lib/form-fields"
import { createLoadingKeys } from "src/lib/loading"

interface IFormFields {
    providerName: string
    contact_details: string
    has_tracking: boolean
    termsAndConditionAttachments: IFile[]
    privacyPolicyAttachments: IFile[]
    orderOutputId: number
    orderOutputName: string
    orderOutputEmail: string
    orderOutputType: string
}

export class ProviderDetailStore {
    static Context = React.createContext<ProviderDetailStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "submit")

    private id?: number

    fields = new FormFields<IFormFields>({
        providerName: "",
        contact_details: "",
        has_tracking: false,
        termsAndConditionAttachments: [],
        privacyPolicyAttachments: [],
        orderOutputId: 0,
        orderOutputName: "",
        orderOutputEmail: "",
        orderOutputType: "",
    })

    constructor() {
        makeAutoObservable(this)
    }

    @loads(() => ProviderDetailStore.LoadingKeys.init)
    async init(id?: number) {
        this.setId(id)

        if (id != null) {
            const [responseProvider, responseOrderOutput] = await Promise.all([
                ProductCatalogueAdminService.getV1AdminCatalogueProvider1({
                    providerId: id,
                }),
                ProductCatalogueAdminService.getV1AdminCatalogueProviderOrderOutput(
                    { providerId: id },
                ),
            ])
            const orderOutput =
                responseOrderOutput.order_outputs != null
                    ? responseOrderOutput.order_outputs[0]
                    : null

            this.initFields({
                providerName: responseProvider.name ?? "",
                contact_details: responseProvider.contact_details ?? "",
                has_tracking: responseProvider.has_tracking ?? false,
                termsAndConditionAttachments: this.toPesistedFile(
                    responseProvider.terms_and_conditions ?? "",
                    "doc",
                ),
                privacyPolicyAttachments: this.toPesistedFile(
                    responseProvider.privacy_policy ?? "",
                    "doc",
                ),
                orderOutputId: orderOutput?.order_output_id ?? 0,
                orderOutputName: orderOutput?.name ?? "",
                orderOutputEmail: orderOutput?.email ?? "",
                orderOutputType: orderOutput?.type ?? "",
            })
        }
    }

    private validate() {
        this.fields.clearErrors()

        if (this.fields.get("providerName").length === 0) {
            this.fields.setError(
                "providerName",
                t`community-detail-modal.no-segments-error`,
            )
        }

        if (this.fields.get("contact_details").length === 0) {
            this.fields.setError("contact_details", t`errors.required`)
        }

        if (this.fields.get("termsAndConditionAttachments").length === 0) {
            this.fields.setError(
                "termsAndConditionAttachments",
                t`errors.required`,
            )
        }
        if (this.fields.get("privacyPolicyAttachments").length === 0) {
            this.fields.setError("privacyPolicyAttachments", t`errors.required`)
        }
    }

    @loads(() => ProviderDetailStore.LoadingKeys.submit)
    async submit() {
        this.validate()
        if (this.fields.hasErrors()) {
            return
        }
        const { data } = this.fields

        const requestProvider: cataloguedomain_CreateProvider = {
            name: data.providerName,
            contact_details: data.contact_details,
            has_tracking: data.has_tracking,
            terms_and_conditions: await this.uploadDocuments(
                data.termsAndConditionAttachments,
            ),
            privacy_policy: await this.uploadDocuments(
                data.privacyPolicyAttachments,
            ),
        }
        const requestOrderOutput: cataloguedomain_CreateOrderOutput = {
            name: data.orderOutputName ?? "",
            email: data.orderOutputEmail ?? "",
            type: this.id == null ? "email" : data.orderOutputType,
        }

        await this.fields.catchErrors(async () => {
            if (this.id === null || this.id === undefined) {
                const ProviderResponse: admin_ProviderResponse =
                    await ProductCatalogueAdminService.postV1AdminCatalogueProvider(
                        {
                            request: requestProvider,
                        },
                    )
                if (ProviderResponse.provider_id != null) {
                    await ProductCatalogueAdminService.postV1AdminCatalogueProviderOrderOutput(
                        {
                            providerId: ProviderResponse.provider_id,
                            request: requestOrderOutput,
                        },
                    )
                }
                Channel.send({
                    name: "repository/updated",
                    payload: {
                        repository: "providers",
                        action: "create",
                        item: {
                            id: ProviderResponse.provider_id ?? 0,
                            name: data.providerName,
                        },
                    },
                })
            } else {
                await ProductCatalogueAdminService.putV1AdminCatalogueProvider({
                    request: requestProvider,
                    providerId: this.id,
                })
                if (requestOrderOutput.type.length === 0) {
                    await ProductCatalogueAdminService.postV1AdminCatalogueProviderOrderOutput(
                        {
                            providerId: this.id,
                            request: {
                                ...requestOrderOutput,
                                type:
                                    data.orderOutputType.length === 0
                                        ? "email"
                                        : data.orderOutputType,
                            },
                        },
                    )
                } else if (data.orderOutputId != null) {
                    await ProductCatalogueAdminService.putV1AdminCatalogueProviderOrderOutput(
                        {
                            request: requestOrderOutput,
                            orderOutputId: data.orderOutputId,
                            providerId: this.id,
                        },
                    )
                } else {
                    await ProductCatalogueAdminService.postV1AdminCatalogueProviderOrderOutput(
                        {
                            providerId: this.id,
                            request: requestOrderOutput,
                        },
                    )
                }
                Channel.send({
                    name: "repository/updated",
                    payload: {
                        repository: "providers",
                        action: "update",
                        item: {
                            id: this.id ?? 0,
                            name: data.providerName,
                        },
                    },
                })
            }
        })
    }

    private async uploadDocuments(document: IFile[]) {
        const uploadedDocList = [
            ...(await this.persistAttachments(document, "document")),
        ]
        return uploadedDocList.length !== 0 ? uploadedDocList[0].url : ""
    }

    private setId(id?: number) {
        this.id = id
    }

    private initFields(fields: IFormFields) {
        this.fields.init(fields)
    }

    /**
     * Persist attachments to object storage before submitting the message
     * containing attachment references.
     *
     * @param attachments Attachments to persist
     * @param type Which type of attachment to persist @see {@link IPersistedFile}
     * @returns
     */
    private async persistAttachments(
        attachments: IFile[],
        type: IPersistedFile["type"],
    ) {
        const alreadyPersistedFiles = attachments.filter(isPersistedFile)
        const nonPersistedFiles = attachments.filter(isLocalFile)
        const urls = await Promise.all(
            nonPersistedFiles.map((file) =>
                FileAdminService.postV1AdminUpload({
                    file: file.file,
                }),
            ),
        )
        const newlyPersistedFiles: IPersistedFile[] = nonPersistedFiles.map(
            (file, i) => ({ url: urls[i], name: file.file.name, type }),
        )
        return [...alreadyPersistedFiles, ...newlyPersistedFiles].map(
            (file) => ({
                url: file.url,
                name: file.name,
                attachment_type: file.type,
            }),
        )
    }

    // private toPersistedFiles(
    //     messageAttachments: message_MessageAttachment[],
    //     type: "doc",
    // ) {
    //     return messageAttachments
    //         .filter((attachment) => attachment.attachment_type === type)
    //         .map((attachment) => this.toPesistedFile(attachment, type))
    // }

    private toPesistedFile(url: string, type: "doc"): IPersistedFile[] {
        return [
            {
                name: url ?? "",
                url: url ?? "",
                // The document type is called "doc" for this particular api. Rename
                // it to "document", to be consistent with other api:s.
                type: type === "doc" ? "document" : type,
            },
        ]
    }
}
