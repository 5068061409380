import { observer } from "mobx-react"
import { TextField, Box, useTheme, Typography } from "@mui/material"
import { t } from "@lingui/macro"

import { EditProductStore } from "src/views/product-catalogue/edit/store"
import { useStore } from "src/store/lib/useStore"
import { Alert16 } from "src/components/icons/Alert16"
import { Icon } from "src/components/icons"
import { Tooltip } from "src/components/Tooltips/Tooltip"

export const IncludedInPrice = observer(() => {
    const store = useStore(EditProductStore)
    const theme = useTheme()

    return (
        <div>
            <Box
                sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                style={{ breakInside: "avoid" }}
            >
                <div>
                    <Typography component="h3" fontSize="14px" fontWeight="700">
                        {store.formFields.get("productType") ===
                        "standard_improvement_single"
                            ? t`edit-product-view.about-offer.label`
                            : t`edit-product-view.included-in-price.label`}
                    </Typography>
                    <Typography fontSize="14px">
                        {t`edit-product-view.about-offer.description`}
                    </Typography>
                </div>
                <Tooltip title={t`edit-product-view.about-offer.tooltip`} arrow>
                    <Typography sx={{ lineHeight: 0 }}>
                        <Icon
                            icon={<Alert16 width="20px" height="20px" />}
                            color={theme.palette.grey[700]}
                        />
                    </Typography>
                </Tooltip>
            </Box>
            <TextField
                label={
                    store.formFields.get("productType") ===
                    "standard_improvement_single"
                        ? t`edit-product-view.about-offer.label`
                        : t`edit-product-view.included-in-price.label`
                }
                multiline
                rows={6}
                value={store.formFields.get("includedInPrice") ?? ""}
                helperText={
                    store.formFields.error("includedInPrice") ??
                    t`edit-product-view.about-offer.helper`
                }
                onChange={store.formFields.setter("includedInPrice")}
                sx={{ mt: 2.5 }}
                error={Boolean(store.formFields.error("includedInPrice"))}
            />
        </div>
    )
})
