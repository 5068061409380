import { makeAutoObservable } from "mobx"
import camelcaseKeys from "camelcase-keys"
import React from "react"
import { t } from "@lingui/macro"

import {
    LobbyAdminService,
    lobby_LobbyListResponse,
    AdminService,
    admin_GetLegalEntityResponse,
    admin_GetPropertyResponse,
} from "src/api"
import { Pagination } from "src/lib/pagination"
import { parseDate } from "src/lib/date"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { reportError } from "src/lib/report"
import { ISelfRegistrationLegalEntity } from "src/views/self-registration/types/selfRegistrationLegalEntity"
import { ISelfRegistrationProperty } from "src/views/self-registration/types/selfRegistrationProperty"

export class SelfRegistrationStore {
    static Context = React.createContext<SelfRegistrationStore | null>(null)

    constructor() {
        makeAutoObservable(this)
    }

    /* accessGroupId */
    private _accessGroupId: number = DEFAULT_ACCESS_GROUP.id

    setAccessGroupId(id: number) {
        this._accessGroupId = id
    }

    /* selectedTabId */
    private _selectedTabId: string = ""

    get selectedTabId() {
        return this._selectedTabId
    }

    setSelectedTabId(id: string) {
        this._selectedTabId = id
    }

    /* Requests */
    requestsPagination = new Pagination(async (query) => {
        try {
            const response = (await LobbyAdminService.getV1AdminLobby({
                pageNumber: query.page,
                pageSize: query.pageSize,
                sort: "created_at",
                accessGroupId: this._accessGroupId,
            })) as lobby_LobbyListResponse // Temp type declaration as the generated one is wrong at the moment
            const items =
                response.result?.map((lobby) => ({
                    ...camelcaseKeys(lobby),
                    id: lobby.lobbyID as number,
                    createdAt:
                        lobby.created_at !== undefined
                            ? parseDate(lobby.created_at)
                            : undefined,
                })) ?? []

            return { items: items, count: items.length }
        } catch (error) {
            reportError(
                t`self-registration-view.failed-to-load-requests`,
                error,
            )
            return { items: [], count: 0 }
        }
    })

    /* legalEntities */
    private _legalEntities: ISelfRegistrationLegalEntity[] = []

    get legalEntities() {
        return this._legalEntities
    }

    setLegalEntities = (legalEntities: admin_GetLegalEntityResponse[]) =>
        (this._legalEntities = legalEntities.map((legalEntity) => ({
            id: legalEntity.legal_entity_id as number,
            ...camelcaseKeys(legalEntity),
        })))

    fetchLegalEntities = async (propertyOwnerId: number) => {
        try {
            return await AdminService.getV1AdminLegalEntity({
                propertyOwnerId: propertyOwnerId,
            })
        } catch (error) {
            reportError(
                t`self-registration-view.failed-to-load-legal-entities`,
                error,
            )
            return []
        }
    }

    legalEntitiesPagination = new Pagination(
        (params) => {
            const pageStart = (params.page - 1) * params.pageSize
            const page = this.legalEntities.slice(
                pageStart,
                pageStart + params.pageSize,
            )

            return {
                items: page,
                sourceItems: this.legalEntities,
                count: this.legalEntities.length,
            }
        },
        { static: true },
    )

    /* Properties */
    private _properties: ISelfRegistrationProperty[] = []

    get properties() {
        return this._properties
    }

    setProperties = (properties: admin_GetPropertyResponse[]) =>
        (this._properties = properties.map((property) => ({
            id: property.property_id as number,
            ...camelcaseKeys(property),
        })))

    fetchProperties = async (selectedLegalEntityId?: number) => {
        try {
            return await AdminService.postV1AdminPropertySearch({
                request: {
                    legal_entity_ids: [selectedLegalEntityId ?? 0],
                    property_owner_ids: [],
                },
            })
        } catch (error) {
            reportError(
                t`self-registration-view.failed-to-load-properties`,
                error,
            )
            return []
        }
    }

    propertiesPagination = new Pagination(
        (params) => {
            const pageStart = (params.page - 1) * params.pageSize
            const page = this.properties.slice(
                pageStart,
                pageStart + params.pageSize,
            )

            return {
                items: page,
                sourceItems: this.properties,
                count: this.properties.length,
            }
        },
        { static: true },
    )

    /* selectedLegalEntity */
    private _selectedLegalEntity: string = ""

    setSelectedLegalEntity(value: string) {
        this._selectedLegalEntity = value
    }

    get selectedLegalEntity() {
        return this._selectedLegalEntity
    }
}
