/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { domain_GetInvoicesRequest } from "../../models/domain_GetInvoicesRequest"

import type { CancelablePromise } from "../../core/CancelablePromise"
import { OpenAPI } from "../../core/OpenAPI"
import { request as __request } from "../../core/request"

export class CustomInvoiceAdminService {
    /**
     * Get invoices
     * Get invoices filtered by some criteria. Segments being one of them
     * @returns domain_AdminGetInvoicesResponse OK
     * @throws ApiError
     */
    public static postV1AdminInvoiceCsvList({
        request,
    }: {
        /** The filter object needed for searching invoices, we can't blindly browse **/
        request: domain_GetInvoicesRequest
    }): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/invoice",
            body: request,
            headers: {
                Accept: "text/csv",
            },
        })
    }

    /**
     * Get invoices for tenant
     * Get invoices for one tenant.
     * @returns domain_AdminInvoice OK
     * @throws ApiError
     */
    public static getV1AdminInvoice({
        tenantId,
        _export,
    }: {
        /** Tenant ID **/
        tenantId: string
        /** Is Export Request **/
        _export?: boolean
    }): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/invoice",
            query: {
                tenant_id: tenantId,
                export: _export,
            },
        })
    }
}
