import { styled } from "@mui/material"

export const HrWithText = (props: {
    sx: React.CSSProperties
    text: string
}) => {
    return <StyledHr aria-label={props.text} {...props} />
}

const StyledHr = styled("hr", {
    skipSx: true,
    shouldForwardProp: (prop) => prop !== "sx" && prop !== "text",
})<{ sx: React.CSSProperties; text: string }>(({ sx, text }) => ({
    border: "none",
    borderTop: "1px solid " + sx.borderColor,
    overflow: "visible",
    position: "relative",
    top: ".4em",
    width: "100%",
    "&::after": {
        content: `'${text}'`,
        textAlign: "center",
        display: "block",
        marginTop: "-.675em",
        zIndex: 1,
        position: "relative",
        backgroundImage:
            "linear-gradient(90deg, transparent 45%, " +
            sx.backgroundColor +
            " 45%, " +
            sx.backgroundColor +
            " 55%, transparent 55%)",
    },
}))
