import { IIconProps } from "src/components/icons"

export const View24 = (props: IIconProps) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 5.83331C15.6667 5.78165 19.3225 8.34498 21.5267 10.77C22.1528 11.4685 22.1539 12.526 21.5292 13.2258C19.3483 15.6258 15.745 18.1666 12.1225 18.1666H11.8658C8.25583 18.1666 4.65167 15.6258 2.47333 13.2266C1.84776 12.5272 1.84776 11.4694 2.47333 10.77C4.6775 8.34581 8.33333 5.78081 12 5.83331ZM12 8.16665C9.88291 8.16665 8.16667 9.88289 8.16667 12C8.16667 14.1171 9.88291 15.8333 12 15.8333C14.1171 15.8333 15.8333 14.1171 15.8333 12C15.8333 9.88289 14.1171 8.16665 12 8.16665Z"
            fill="currentColor"
        />
        <circle cx="12" cy="11.9999" r="1.66667" fill="currentColor" />
    </svg>
)
