export const capitalize = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1)

export const removeHyphen = (string: string) =>
    string.replace(new RegExp("-", "g"), " ")

export const removeForwardSlash = (string: string) => {
    const paths = string.split("/")
    return paths !== undefined ? paths[1] : string.replace(/\//g, "")
}
