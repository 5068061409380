import React from "react"
import { makeAutoObservable } from "mobx"
import { t } from "@lingui/macro"
import { OAuth2AuthCodePkceClient, Configuration } from "oauth2-pkce"
import { Location } from "history"

import { environment, IAuth } from "src/config"
import { FormFields } from "src/lib/form-fields"
import {
    AdminService,
    AuthenticationAdminService,
    CancelablePromise,
    OpenAPI,
} from "src/api"
import { reportError } from "src/lib/report"

interface IFormFields {
    email: string
    password: string
    keepMeLoggedIn: boolean
}

interface IConfig {
    [key: string]: boolean
}

export class LoginStore {
    static Context = React.createContext<LoginStore | null>(null)
    isLoading = false
    fields = new FormFields<IFormFields>({
        email: "",
        password: "",
        keepMeLoggedIn: true,
    })
    showPassword = false
    setUser?: (user: {}, storage: Storage) => void

    mfaRequired = false
    mfaConfigured = false
    auth2faUri = ""
    totpCode = ""
    verifyTotpError = ""
    isAuthMfaVerified = false

    constructor() {
        makeAutoObservable(this)
    }

    async init(
        setUser: (user: {}, storage: Storage) => void,
        location: Location,
        searchParams: URLSearchParams,
    ) {
        this.setUser = setUser

        if (location.pathname === "/login/fa") {
            // Auto start login flow
            await this.oauthClientInit(
                this.getMappedVendorAuthConfig(
                    environment.AUTH.FASTIGHETSAGARNA,
                ),
            )
        }

        const callback = searchParams.get("callback")
        if (callback != null) {
            // Login user if redirected from external login provider (redirectUrl specified in authConfigs above)
            if (callback === "microsoft") {
                await this.oauthClientLogin(
                    this.getMappedVendorAuthConfig(environment.AUTH.MICROSOFT),
                    AuthenticationAdminService.postV1AdminAuthMsLogin,
                )
            } else if (callback === "fastighetsagarna") {
                await this.oauthClientLogin(
                    this.getMappedVendorAuthConfig(
                        environment.AUTH.FASTIGHETSAGARNA,
                    ),
                    AuthenticationAdminService.postV1AdminAuthFaLogin,
                )
            }
        }
    }

    getMappedVendorAuthConfig = (config: IAuth) => ({
        clientId: config.CLIENT_ID,
        authorizationUrl: config.AUTHORIZATION_URL,
        redirectUrl: config.REDIRECT_URL,
        tokenUrl: config.TOKEN_URL,
        scopes: config.SCOPES,
    })

    private setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading
    }

    setShowPassword(showPassword: boolean) {
        this.showPassword = showPassword
    }

    setMfaRequired(mfaRequired: boolean) {
        this.mfaRequired = mfaRequired
    }

    setMfaConfigured(mfaConfigured: boolean) {
        this.mfaConfigured = mfaConfigured
    }

    setAuthMfaURI(auth2faUri: string) {
        this.auth2faUri = auth2faUri
    }

    setTotpCode = (code: string) => {
        if (this.verifyTotpError.length > 0) {
            this.verifyTotpError = ""
        }

        this.totpCode = code
    }

    handleCloseModal = () => {
        this.mfaRequired = false
        this.mfaConfigured = false
        this.auth2faUri = ""
        this.totpCode = ""
        this.verifyTotpError = ""
        this.isAuthMfaVerified = false
    }

    async oauthClientInit(config: Configuration) {
        const oauthClient = new OAuth2AuthCodePkceClient(config)
        await oauthClient.requestAuthorizationCode()
    }

    private async oauthClientLogin(
        config: Configuration,
        loginEndpoint: () => CancelablePromise<{ token?: string }>,
    ) {
        const oauthClient = new OAuth2AuthCodePkceClient(config)

        try {
            await oauthClient.receiveCode()
            const oauthClientTokens = await oauthClient.getTokens()

            OpenAPI.TOKEN = oauthClientTokens.idToken
            const { token } = await loginEndpoint()

            if (token != null) {
                const userDetails = await this.getUserDetails(token)
                if (userDetails != null && this.setUser != null) {
                    this.setUser(userDetails, localStorage)
                    await oauthClient.reset()
                    window.location.href = "/"
                }
            }
        } catch (error) {
            reportError(t`login-view.login-fail`, error)
            this.setIsLoading(false)
        }
    }

    emailPasswordLogin = async () => {
        this.setIsLoading(true)

        if (this.mfaConfigured && this.totpCode.length !== 6) {
            this.verifyTotpError = t`login-view.please-enter-6-digits`
            this.setIsLoading(false)
            return
        }

        const { data } = this.fields
        const token = await this.getToken(
            data.email,
            data.password,
            this.totpCode,
        )

        if (token != null) {
            const userDetails = await this.getUserDetails(token)
            const storage =
                data.keepMeLoggedIn === true ? localStorage : sessionStorage

            if (userDetails != null && this.setUser != null) {
                this.setUser(userDetails, storage)
                window.location.href = "/"
            }
        }
    }

    private async getToken(email: string, password: string, totp_code: string) {
        try {
            const loginResponse =
                await AuthenticationAdminService.postV1AdminAuthLogin({
                    request: { email, password, totp_code },
                })

            if (
                (loginResponse.mfa_required ?? false) &&
                !this.isAuthMfaVerified
            ) {
                OpenAPI.TOKEN = loginResponse.token
                this.setMfaRequired(loginResponse.mfa_required ?? false)
                this.setMfaConfigured(loginResponse.mfa_configured ?? false)
                if (!(loginResponse.mfa_configured ?? false)) {
                    await this.createKey(loginResponse.token)
                } else {
                    this.setIsLoading(false)
                }
                return null
            } else {
                if (this.mfaRequired) {
                    this.handleCloseModal()
                }

                return loginResponse.token
            }
        } catch (error) {
            if (this.mfaConfigured) {
                this.verifyTotpError = t`login-view.invalid-code`
                this.setIsLoading(false)
            }
            reportError(t`login-view.login-fail`, error)
            this.setIsLoading(false)
        }
    }

    private async getUserDetails(token: string) {
        try {
            OpenAPI.TOKEN = token
            const userDetails = await AdminService.getV1AdminUserDetails()

            const moduleList: {}[] = []
            userDetails.modules?.forEach((module) => {
                if (module.is_for_super_admin === false) {
                    moduleList.push({
                        moduleId: module.module_id,
                        moduleName: module.module_name,
                    })
                }
            })

            const config: IConfig = {}
            userDetails.modules?.forEach(
                (module) =>
                    (config[module.module_name as keyof typeof config] =
                        Boolean(module.module_name != null)),
            )

            return {
                accessToken: "Bearer " + token,
                adminId: userDetails.admin_id,
                name: userDetails.name,
                isSuper: userDetails.is_super,
                config: config,
                moduleList: moduleList,
                raw: userDetails,
            }
        } catch (error) {
            reportError(t`login-view.login-fail`, error)
            this.setIsLoading(false)
        }
    }

    createKey = async (_token: string | undefined) => {
        try {
            const mfaKeyResponse =
                await AuthenticationAdminService.postV1AdminAuthMfaTotpCreateKey()
            this.setAuthMfaURI(mfaKeyResponse.uri ?? "")
            this.setIsLoading(false)
        } catch (error) {
            this.setIsLoading(false)
            reportError(t`generic-error`, error)
        }
    }

    onVerifyTotp = async () => {
        if (this.totpCode.length !== 6) {
            this.verifyTotpError = t`login-view.please-enter-6-digits`
            return
        }

        this.setIsLoading(true)

        try {
            const verifyResponse =
                await AuthenticationAdminService.postV1AdminAuthMfaTotpVerifyKey(
                    {
                        request: { code: this.totpCode },
                    },
                )
            if (Boolean(verifyResponse.code_valid)) {
                this.isAuthMfaVerified = true
                await this.emailPasswordLogin()
            } else {
                this.verifyTotpError = t`login-view.invalid-code`
                this.setIsLoading(false)
            }
        } catch (error) {
            this.setIsLoading(false)
            reportError(t`generic-error`, error)
        }
    }
}
