import { observer } from "mobx-react"

import { useTheme, Grid, Stack, Typography } from "@mui/material"

import {
    CommentRounded,
    FeedRounded,
    PercentRounded,
} from "@mui/icons-material"

import { t } from "@lingui/macro"

import { useMemo } from "react"

import { Icon } from "src/components/icons"

import { useStore } from "src/store/lib/useStore"
import { CommunitiesOverviewViewStore } from "src/views/community-overview/store"
import { convertHexToRgba } from "src/helpers/convertHexToRgba"

const iconSize = { fontSize: 35 }

export const StatisticsRow = observer(() => {
    const store = useStore(CommunitiesOverviewViewStore)
    const { palette } = useTheme()

    const items = useMemo(
        () => [
            {
                icon: (
                    <Icon
                        icon={<FeedRounded sx={iconSize} />}
                        size={36}
                        color={palette.utility.darkBlue.main}
                    />
                ),
                name: t`community-overview.statistics-row.number-of-posts`,
                value: store.numberOfPosts,
            },
            {
                icon: (
                    <Icon
                        icon={<CommentRounded sx={iconSize} />}
                        size={36}
                        color={palette.utility.darkBlue.main}
                    />
                ),
                name: t`community-overview.statistics-row.number-of-comments`,
                value: store.numberOfComments,
            },
            {
                icon: (
                    <Icon
                        icon={<PercentRounded sx={iconSize} />}
                        size={36}
                        color={palette.utility.darkBlue.main}
                    />
                ),
                name: t`community-overview.statistics-row.active-users`,
                value: `${store.activeUsers}%`,
            },
        ],
        [store, palette],
    )

    const renderItems = items.map((item, index) => (
        <Grid
            key={index}
            xs={4}
            padding={2}
            sx={{
                borderLeft:
                    index === 1 ? `1px solid ${palette.grey[200]}` : undefined,
                borderRight:
                    index === 1 ? `1px solid ${palette.grey[200]}` : undefined,
            }}
        >
            <Stack
                display={"flex"}
                justifyContent={"space-between"}
                direction="row"
            >
                <Stack direction="row" display={"flex"} alignItems={"center"}>
                    {item.icon}
                    <Typography
                        variant="h6"
                        marginLeft={2}
                        color={palette.text.secondary}
                    >
                        {item.name}
                    </Typography>
                </Stack>
                <Stack
                    paddingX={2}
                    paddingY={1}
                    borderRadius={100}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    bgcolor={convertHexToRgba(palette.utility.darkBlue.main)}
                >
                    <Typography
                        variant="h5"
                        color={palette.utility.darkBlue.main}
                    >
                        {item.value}
                    </Typography>
                </Stack>
            </Stack>
        </Grid>
    ))

    return (
        <Grid
            container
            sx={{
                borderTop: `1px solid ${palette.grey[200]}`,
                borderBottom: `1px solid ${palette.grey[200]}`,
            }}
        >
            {renderItems}
        </Grid>
    )
})
