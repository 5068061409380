import { observer } from "mobx-react"

import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material"
import { t } from "@lingui/macro"

import {
    IRelationshipDropdownProps,
    ISourceProps,
} from "src/modals/contact-forms-form-builder/types/formFieldsSection"

export const ExternalFieldRelationshipDropdown = observer(
    (props: IRelationshipDropdownProps) => {
        return (
            <FormControl style={{ breakInside: "avoid", width: "100%" }}>
                <InputLabel id="type-label">{t`contact-form-forms-detail-modal.form-fields-settings-external-field-relationship-dropdown.header`}</InputLabel>
                <Select
                    data-testid="external-field-relationship-dropdown"
                    labelId="external-field-relationship-label"
                    id="external-field-relationship"
                    value={props.selectedValue}
                    label={t`contact-form-forms-detail-modal.form-fields-settings-external-field-relationship-dropdown.header`}
                    onChange={(event) =>
                        props.onSelectionChange(event.target.value)
                    }
                    error={Boolean(props.errorMessage)}
                    disabled={props.disabled}
                >
                    {props.sources.map((item: ISourceProps) => (
                        <MenuItem value={item.value} key={item.key}>
                            {item.key}
                        </MenuItem>
                    ))}
                </Select>
                {Boolean(props.errorMessage) && (
                    <FormHelperText error={true}>
                        {props.errorMessage}
                    </FormHelperText>
                )}
            </FormControl>
        )
    },
)
