import { observer } from "mobx-react"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"

import { t } from "@lingui/macro"

import {
    IconContainer,
    ItemContainer,
} from "src/modals/contact-forms-form-builder/styled"

import {
    FormTypeDropdownKeys,
    FormTypeDropdownValues,
} from "src/modals/contact-forms-form-builder/types/formTypeDropdownValues"

import { IFormTypeDropdown } from "src/modals/contact-forms-form-builder/types/formFieldsSection"

import { ParagraphRound } from "src/components/icons/ParagraphRound"
import { CheckBoxFilled } from "src/components/icons/CheckBoxFilled"
import { ShortRoundText } from "src/components/icons/ShortTextRound"
import { FileUpload } from "src/components/icons/FileUpload"
import { ArrowDropdownCircle } from "src/components/icons/ArrowDropdownCircle"
import { RadioButtonFilled } from "src/components/icons/RadioButtonFilled"

const dropdownValues = [
    {
        name: FormTypeDropdownValues.ShortAnswer,
        icon: <ShortRoundText />,
        value: FormTypeDropdownKeys.ShortAnswer,
    },
    {
        name: FormTypeDropdownValues.Paragraph,
        icon: <ParagraphRound />,
        value: FormTypeDropdownKeys.Paragraph,
    },
    {
        name: FormTypeDropdownValues.RadioButton,
        icon: <RadioButtonFilled />,
        value: FormTypeDropdownKeys.RadioButton,
    },
    {
        name: FormTypeDropdownValues.Checkbox,
        icon: <CheckBoxFilled />,
        value: FormTypeDropdownKeys.Checkbox,
    },
    {
        name: FormTypeDropdownValues.Dropdown,
        icon: <ArrowDropdownCircle />,
        value: FormTypeDropdownKeys.Dropdown,
    },
    {
        name: FormTypeDropdownValues.FileUpload,
        icon: <FileUpload />,
        value: FormTypeDropdownKeys.FileUpload,
    },
]
export const FormTypeDropdown = observer(
    ({
        item,
        onSelectValueChange,
        disabled,
        isVeticOtherSelected,
    }: IFormTypeDropdown) => {
        const dropdownOptionsArray =
            isVeticOtherSelected === true
                ? dropdownValues.filter(
                      (item) =>
                          item.value === FormTypeDropdownKeys.ShortAnswer ||
                          item.value === FormTypeDropdownKeys.Dropdown,
                  )
                : dropdownValues
        const getDisplayName = (value: string) =>
            dropdownValues.find((item) => item.value === value)?.name

        const renderDropdownCell = (value: string) => (
            <ItemContainer>
                <IconContainer>
                    {dropdownValues.find((item) => item.value === value)?.icon}
                </IconContainer>
                {getDisplayName(value)}
            </ItemContainer>
        )

        return (
            <FormControl style={{ breakInside: "avoid", width: "100%" }}>
                <InputLabel id="type-label">{t`contact-form-forms-detail-modal.form-field-section.dropdown-label`}</InputLabel>
                <Select
                    data-testid="connector-dropdown"
                    labelId="connector-label"
                    id="connector"
                    value={item.value}
                    disabled={disabled}
                    error={Boolean(item.errorMessage)}
                    label={t`contact-form-forms-detail-modal.form-field-section.dropdown-label`}
                    onChange={(event) =>
                        onSelectValueChange(event.target.value)
                    }
                    renderValue={(value) => {
                        return renderDropdownCell(value)
                    }}
                >
                    {dropdownOptionsArray.map((item) => (
                        <MenuItem value={item.value}>
                            <ItemContainer>
                                <IconContainer>{item.icon}</IconContainer>
                                {item.name}
                            </ItemContainer>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    },
)
