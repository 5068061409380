import { t } from "@lingui/macro"
import { makeAutoObservable } from "mobx"

import React from "react"

import { IAsset } from "./types"

import { createLoadingKeys } from "src/lib/loading"
import { getFileType } from "src/components/AssetLibraryPicker/utils"

export class ImagePickerStore {
    static Context = React.createContext<ImagePickerStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "submit")

    private _activeTab: number = 0
    private _openModal: boolean = false
    private _icons: IAsset[] = []
    private _images: IAsset[] = []
    private _loading: boolean = false

    constructor() {
        makeAutoObservable(this)
    }

    //#region getters
    get openModal() {
        return this._openModal
    }
    get activeTab() {
        return this._activeTab
    }

    get icons() {
        return this._icons
    }

    get images() {
        return this._images
    }

    get loading() {
        return this._loading
    }
    //#endregion

    //#region setters
    setOpenModal(openModal: boolean) {
        this._openModal = openModal
    }

    setActiveTab(tabId: number) {
        this._activeTab = tabId
    }

    setIcons(asset: IAsset[]) {
        this._icons = asset
    }

    setImages(asset: IAsset[]) {
        this._images = asset
    }

    setLoading(value: boolean) {
        this._loading = value
    }
    //#endregion

    //#region operations
    handleIconSelect = async (
        icon: { name: string; path: string },
        onIconChange: (images: IFile[]) => void,
    ) => {
        try {
            const response = await fetch(icon.path)
            if (!response.ok) {
                throw new Error(t`asset-library-picker.failed-fetch-icon`)
            }

            const content = await response.arrayBuffer()
            const fileType = getFileType(icon.name, content)

            const blob = new Blob([content], { type: fileType })
            const url = URL.createObjectURL(blob)
            const file = new File([blob], icon.name, { type: fileType })

            const customFile: { file: File; url: string } = {
                file: file,
                url: url,
            }

            onIconChange([customFile])
            this.setOpenModal(false)
        } catch (error) {
            throw new Error(t`asset-library-picker.failed-fetch-icon`)
        }
    }
    //#endregion
}
