import React from "react"
import { makeAutoObservable } from "mobx"
import { t } from "@lingui/macro"

import { FormFields } from "src/lib/form-fields"
import { ISelectedAccessGroup } from "src/types/local-storage"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { IEditAccessDetailItem } from "src/types/access"
import {
    LockAdminService,
    lock_LockResource,
    lock_LockResourceType,
} from "src/api"
import { Channel } from "src/channel"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"

export interface IFormFields {
    type: lock_LockResourceType
    name: string
    externalId: string
    segmentIds: number[]
    resourceId: number
    accessGroupId: number
}
export class AccessDetailStore {
    static Context = React.createContext<AccessDetailStore | null>(null)
    formFields = new FormFields<IFormFields>({
        segmentIds: [],
        type: "" as lock_LockResourceType,
        name: "",
        externalId: "",
        resourceId: 0,
        accessGroupId: -1,
    })
    itemTypes: string[] = []
    isLoading = false
    accessGroups: ISelectedAccessGroup[] = []
    accessGroupId: number = DEFAULT_ACCESS_GROUP.id

    constructor() {
        makeAutoObservable(this)
    }

    init = async (item?: IEditAccessDetailItem) => {
        if (item != null) {
            const response = await LockAdminService.getV1AdminLockResource1({
                resourceId: item?.row.id,
            })

            this.formFields.init({
                name: response.name ?? "",
                accessGroupId: response.access_group_id ?? this.accessGroupId,
                externalId: response.parakey_resource_id ?? "",
                resourceId: response.resource_id ?? 0,
                segmentIds: response.segment_ids ?? [],
                type: response.type ?? ("" as lock_LockResourceType),
            })
        } else {
            this.formFields.set("accessGroupId", this.accessGroupId)
        }
    }
    getRequestData = () => ({
        access_group_id:
            this.formFields.data.accessGroupId ?? DEFAULT_ACCESS_GROUP.id,
        name: this.formFields.data.name,
        parakey_resource_id: this.formFields.data.externalId,
        type: this.formFields.data.type ?? "",
    })
    getUpdateRequestData = () => ({
        lockResourceID: this.formFields.data.resourceId,
        name: this.formFields.data.name,
        parakey_resource_id: this.formFields.data.externalId,
    })
    createItem = async () => {
        const publishedIn = this.formFields.get("segmentIds")
        let itemResponse: lock_LockResource = {}

        try {
            // Create
            await this.formFields.catchErrors(async () => {
                itemResponse = await LockAdminService.postV1AdminLockResource({
                    request: await this.getRequestData(),
                })
            })
            trackModuleEvent("Access | Create new Access", {
                [MixpanelProperties.ItemID]: itemResponse.resource_id,
            })
            // Publish
            if (
                itemResponse.resource_id != null &&
                publishedIn != null &&
                publishedIn.length > 0
            ) {
                await LockAdminService.putV1AdminLockResourcePublish({
                    resourceId: itemResponse.resource_id as number,
                    request: {
                        published_in: publishedIn,
                    },
                })
            }

            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "parakey",
                    action: "update",
                },
            })
        } catch (error) {
            reportError(error)
        } finally {
            this.setIsLoading(false)
        }
    }

    updateItem = async (item: IEditAccessDetailItem) => {
        const publishedIn = this.formFields.get("segmentIds")
        try {
            // Update
            await this.formFields.catchErrors(async () => {
                await LockAdminService.putV1AdminLockResource({
                    resourceId: item.row.id ?? 0,
                    request: await this.getUpdateRequestData(),
                })
            })

            // Publish
            await LockAdminService.putV1AdminLockResourcePublish({
                resourceId: item.row.id ?? 0,
                request: {
                    published_in: publishedIn,
                },
            })

            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "parakey",
                    action: "update",
                },
            })
        } catch (error) {
            reportError(error)
        } finally {
            this.setIsLoading(false)
        }
    }
    setAccessGroups = (accessGroups: ISelectedAccessGroup[]) => {
        this.accessGroups = accessGroups
    }

    setSelectedAccessGroupId = (accessGroupId: number) => {
        this.accessGroupId = accessGroupId
    }

    setItemTypes = (itemTypes: string[]) => {
        this.itemTypes = itemTypes
    }

    validateRequiredFields = (requiredFields: string[]) => {
        this.formFields.clearErrors()

        requiredFields.forEach((field) => {
            const requiredField = field as keyof IFormFields
            const formField = this.formFields.get(requiredField)

            if (
                formField == null ||
                formField === "" ||
                formField === "<p></p>\n"
            ) {
                this.formFields.setError(requiredField, t`errors.required`)
            } else {
                this.formFields.clearError(requiredField)
            }
        })
    }

    setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading)
}
