import { t } from "@lingui/macro"
import {
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Switch,
} from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { FormFieldSet } from "src/components/FormFieldSet"
import { WeekdayRangePicker } from "src/components/WeekdayRangePicker"
import {
    createDefaultWeekdayRanges,
    IWeekdayRangesValue,
} from "src/components/WeekdayRangePicker/utils"
import { useUniqueId } from "src/lib/unique-id"
import {
    maxActiveBookingsRange,
    timeSlots,
} from "src/modals/resource-detail/BookingRulesSection/config"
import { ResourceDetailStore } from "src/modals/resource-detail/store"
import { useStore } from "src/store/lib/useStore"

export const BookingRulesSection = observer(() => {
    const vstore = useStore(ResourceDetailStore)

    const timeSlotId = useUniqueId()
    const maxActiveBookingsId = useUniqueId()

    const handleWeekdayRangesChange = useCallback(
        (value: IWeekdayRangesValue) => {
            vstore.fields.set("weekdayRanges", value)
        },
        [vstore.fields],
    )

    const handleBookingEnabledChange = useCallback(
        (_: unknown, checked: boolean) => {
            vstore.fields.set("bookingEnabled", checked)
        },
        [vstore.fields],
    )

    const handleIntervalLengthChange = useCallback(
        (event: SelectChangeEvent<number>) => {
            const intervalLength = Number(event.target.value)
            vstore.fields.set("intervalLength", intervalLength)
            vstore.fields.set(
                "weekdayRanges",
                createDefaultWeekdayRanges(intervalLength),
            )
        },
        [vstore.fields],
    )

    const handleMaxBookingsPerApartmentChange = useCallback(
        (event: SelectChangeEvent<number>) => {
            vstore.fields.set(
                "maxBookingsPerApartment",
                Number(event.target.value),
            )
        },
        [vstore.fields],
    )

    const disabled =
        !vstore.isCreatingResource || vstore.fields.get("accessType") === "READ"

    return (
        <FormFieldSet
            header={t`resource-detail-modal.booking-rules-section.header`}
        >
            <Stack spacing={2}>
                <FormControlLabel
                    checked={vstore.fields.get("bookingEnabled")}
                    onChange={handleBookingEnabledChange}
                    control={<Switch color="success" />}
                    label={t`resource-detail-modal.booking-rules-section.booking-enabled-label`}
                    disabled={disabled}
                />
                {vstore.fields.get("bookingEnabled") && (
                    <>
                        <FormControl fullWidth>
                            <InputLabel id={timeSlotId}>
                                {t`resource-detail-modal.booking-rules-section.time-slot-label`}
                            </InputLabel>
                            <Select
                                labelId={timeSlotId}
                                label={t`resource-detail-modal.booking-rules-section.time-slot-label`}
                                value={vstore.fields.get("intervalLength")}
                                onChange={handleIntervalLengthChange}
                                disabled={disabled}
                            >
                                {timeSlots.map((item) => (
                                    <MenuItem
                                        key={item.value}
                                        value={item.value}
                                    >
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id={maxActiveBookingsId}>
                                {t`resource-detail-modal.booking-rules-section.max-active-bookings-label`}
                            </InputLabel>
                            <Select
                                labelId={maxActiveBookingsId}
                                label={t`resource-detail-modal.booking-rules-section.max-active-bookings-label`}
                                value={vstore.fields.get(
                                    "maxBookingsPerApartment",
                                )}
                                disabled={disabled}
                                onChange={handleMaxBookingsPerApartmentChange}
                            >
                                {maxActiveBookingsRange.map((item) => (
                                    <MenuItem
                                        key={item.value}
                                        value={item.value}
                                    >
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <WeekdayRangePicker
                            value={vstore.fields.get("weekdayRanges")}
                            onChange={handleWeekdayRangesChange}
                            step={vstore.fields.get("intervalLength")}
                            disabled={disabled}
                        />
                    </>
                )}
            </Stack>
        </FormFieldSet>
    )
})
