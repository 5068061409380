import {
    Palette,
    SxProps,
    tableCellClasses,
    Theme,
    Popper,
    styled,
} from "@mui/material"

export const tableStyles = (palette: Palette): SxProps<Theme> => ({
    border: "none",
    "& .MuiTableCell-sizeSmall": {
        padding: "0",
    },
    "& thead:first-of-type": {
        paddingTop: "16px",
        border: "none",
        backgroundColor: palette.utility.wildSand.main,
    },
    "& thead .MuiTableCell-root": {
        borderRight: "none",
    },
    "& tbody tr": {
        height: 32,
    },
    "& tbody td": {
        padding: 0,
        borderColor: "transparent",
        borderBottom: "none",
        borderTop: "none",
        borderRight: `1px dashed ${palette.grey[200]}`,
    },
    "& tbody tr td:first-of-type": {
        width: "200px",
    },
    "& tbody tr:first-of-type td": {
        paddingTop: "16px",
    },
    [`& .${tableCellClasses.root}`]: {
        padding: 0,
        borderColor: "transparent",
        borderBottom: "none",
        borderTop: "none",
        borderRight: `1px dashed ${palette.grey[200]}`,
    },
})

export const popperStyled = styled(Popper)({
    zIndex: 1,
    "&.MuiAutocomplete-popper": {
        zIndex: 1,
    },
})
