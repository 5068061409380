/* eslint-disable import/no-relative-parent-imports */
import { useCallback, useEffect } from "react"
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from "@mui/material"
import { observer } from "mobx-react"
import { t } from "@lingui/macro"

import { AccessDetailStore } from "../store"

import { useStore } from "src/store/lib/useStore"
import { reportError } from "src/lib/report"
import { GlobalStore } from "src/store"
import { lock_LockResourceType } from "src/api"

export const Type = observer(() => {
    const store = useStore(AccessDetailStore)
    const gStore = useStore(GlobalStore)
    useEffect(() => {
        ;(() => {
            try {
                store.setItemTypes(["Common", "Private"])
            } catch (error) {
                reportError(
                    t`edit-information-item-modal.load-types-fail`,
                    error,
                )
            }
        })()
    }, [store])

    const handleChange = useCallback(
        (event: SelectChangeEvent<string>) => {
            store.formFields.set(
                "type",
                event.target.value as lock_LockResourceType,
            )
            store.formFields.set("segmentIds", [])
            event.target.value != null &&
                gStore.session.setSelectedParakeyType(event.target.value)
        },
        [store, gStore.session],
    )

    return (
        <FormControl style={{ breakInside: "avoid" }}>
            <InputLabel id="type-label">{t`access-detail-modal.type`}</InputLabel>
            {store.itemTypes.length > 0 && (
                <Select
                    data-testid="type-dropdown"
                    labelId="type-label"
                    id="type"
                    value={store.formFields.get("type") ?? ""}
                    label={t`edit-information-item-modal.type`}
                    onChange={handleChange}
                    error={Boolean(store.formFields.error("type"))}
                    disabled={Boolean(store.formFields.get("resourceId"))}
                >
                    {store.itemTypes.map((type) => (
                        <MenuItem value={type} key={type}>
                            {type}
                        </MenuItem>
                    ))}
                </Select>
            )}
            {Boolean(store.formFields.error("type")) && (
                <FormHelperText error={true}>
                    {store.formFields.error("type")}
                </FormHelperText>
            )}
        </FormControl>
    )
})
