import {
    Button,
    Grid,
    Stack,
    TextField,
    Typography,
    useTheme,
} from "@mui/material"
import { useState } from "react"

import { ResetStore } from "./store"

import { PageHeader } from "src/components/PageHeader"
import { StoreProvider } from "src/store/lib/StoreProvider"

interface IItem {
    name: string
    service: string
    inputs: IInput[]
}

/* eslint-disable @typescript-eslint/no-explicit-any */
interface IInput {
    placeholder: string
    type: string
    name: string
}

interface IValues {
    [key: string]: { [key: string]: any }
}

const data: IItem[] = [
    {
        name: "Delete Embed Purchase",
        service: "deleteEmbedPurchase",
        inputs: [
            {
                placeholder: "Integration Code",
                type: "string",
                name: "integration_code",
            },
            { placeholder: "Tenant Id", type: "number", name: "tenant_id" },
        ],
    },
    {
        name: "Delete Standard Improvement Order",
        service: "deleteStandardImprovementOrder",
        inputs: [
            { placeholder: "Tenant Id", type: "number", name: "tenant_id" },
        ],
    },
    {
        name: "Enable MoveIn",
        service: "enableMoveIn",
        inputs: [
            {
                placeholder: "Lease Contract Id",
                type: "number",
                name: "lease_contract_id",
            },
        ],
    },
    {
        name: "Reset Axa",
        service: "resetAxa",
        inputs: [
            { placeholder: "Tenant Id", type: "number", name: "tenant_id" },
        ],
    },
    {
        name: "Unregister Tenant",
        service: "unregisterTenant",
        inputs: [
            { placeholder: "Tenant Id", type: "number", name: "tenant_id" },
        ],
    },
    {
        name: "Unsign Hedvig",
        service: "unsignHedvig",
        inputs: [
            { placeholder: "Tenant Id", type: "number", name: "tenant_id" },
        ],
    },
]

export const View = () => {
    const vstore = new ResetStore()
    const theme = useTheme()
    const [values, setValues] = useState<IValues>({})

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        name: string,
        service: string,
    ) => {
        setValues((prevState) => ({
            ...prevState,
            ...{
                [service]: {
                    ...prevState[service],
                    [name]: event.target.value,
                },
            },
        }))
    }

    const submitServiceSwitch = async (service: string) => {
        switch (service) {
            case "deleteEmbedPurchase":
                await vstore.deleteEmbedPurchase({
                    integrationCode: values[service].integration_code as string,
                    tenantId: parseInt(values[service].tenant_id),
                })
                break
            case "deleteStandardImprovementOrder":
                await vstore.deleteStandardImprovementOrder({
                    tenantId: parseInt(values[service].tenant_id),
                })
                break
            case "enableMoveIn":
                await vstore.enableMoveIn({
                    leaseContractId: parseInt(
                        values[service].lease_contract_id,
                    ),
                })
                break
            case "resetAxa":
                await vstore.resetAxa({
                    tenantId: parseInt(values[service].tenant_id),
                })
                break
            case "unregisterTenant":
                await vstore.unregisterTenant({
                    tenantId: parseInt(values[service].tenant_id),
                })
                break
            case "unsignHedvig":
                await vstore.unsignHedvig({
                    tenantId: parseInt(values[service].tenant_id),
                })
                break
            default:
                break
        }
    }

    return (
        <Stack spacing={2.5}>
            <PageHeader header={"RESET ENDPOINTS"} breadcrumbs={[]} />
            {data.map((item: IItem, dataIndex: number) => {
                return (
                    <Stack paddingBottom={4} spacing={1}>
                        <Typography
                            fontSize={16}
                            fontWeight={700}
                            color={theme.palette.grey[700]}
                        >
                            {item.name}
                        </Typography>
                        <Grid container key={dataIndex} alignItems="center">
                            {item.inputs.map(
                                (input: IInput, inputIndex: number) => {
                                    return (
                                        <Grid
                                            item
                                            xs={3}
                                            sm={3}
                                            lg={3}
                                            key={inputIndex}
                                            sx={{ marginRight: 6 }}
                                        >
                                            <TextField
                                                type={input.type}
                                                label={input.placeholder}
                                                value={
                                                    values[
                                                        `${dataIndex}${inputIndex}`
                                                    ]
                                                }
                                                onChange={(e) =>
                                                    handleChange(
                                                        e,
                                                        input.name,
                                                        item.service,
                                                    )
                                                }
                                            />
                                        </Grid>
                                    )
                                },
                            )}
                            <Button
                                data-testid="create-button"
                                variant="contained"
                                title={"Save"}
                                onClick={() =>
                                    submitServiceSwitch(item.service)
                                }
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Stack>
                )
            })}
        </Stack>
    )
}

export const ResetView = () => (
    <StoreProvider Store={ResetStore}>
        <View />
    </StoreProvider>
)
