import { makeAutoObservable } from "mobx"
import React from "react"

import { InfoCenterAdminService } from "src/api"
import { Channel } from "src/channel"

import { loads } from "src/channel/utils"
import { createLoadingKeys } from "src/lib/loading"
import { reportUnhandledApiError } from "src/lib/report"
import { ILibraryCategory } from "src/types/library"

export class LibraryCategoriesOrderStore {
    static Context = React.createContext<LibraryCategoriesOrderStore | null>(
        null,
    )
    static LoadingKeys = createLoadingKeys("init", "submit")

    categories: ILibraryCategory[] = []

    setCategories(categories: ILibraryCategory[]) {
        this.categories = categories
    }

    constructor() {
        makeAutoObservable(this)
    }

    init(categories: ILibraryCategory[]) {
        this.setCategories(categories)
    }

    @loads(() => LibraryCategoriesOrderStore.LoadingKeys.submit)
    async submit(): Promise<boolean> {
        try {
            await InfoCenterAdminService.patchV1AdminInfocenterCategoryRank({
                request: Object.fromEntries(
                    this.categories.map((category) => [
                        category.id,
                        category.rank,
                    ]),
                ),
            })

            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "library-categories",
                    action: "update",
                },
            })

            return true
        } catch (e) {
            reportUnhandledApiError(e)
        }
        return false
    }
}
