import { IIconProps } from "src/components/icons"

export const Cross16 = (props: IIconProps) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M11.6132 4.16056L11.6135 4.1602C11.6783 4.09546 11.7817 4.09546 11.8464 4.1602C11.8723 4.18608 11.89 4.22565 11.89 4.27331C11.89 4.32098 11.8723 4.36055 11.8464 4.38643L8.58643 7.64643L8.23287 7.99998L8.58643 8.35353L11.8464 11.6135C11.9112 11.6783 11.9112 11.7817 11.8464 11.8464C11.7817 11.9112 11.6783 11.9112 11.6135 11.8464L8.35353 8.58643L7.99998 8.23287L7.64643 8.58643L4.38643 11.8464L4.73998 12.2L4.38643 11.8464C4.32169 11.9112 4.21827 11.9112 4.15353 11.8464C4.0888 11.7817 4.0888 11.6783 4.15353 11.6135L7.41353 8.35353L7.76709 7.99998L7.41353 7.64643L4.15353 4.38643C4.0888 4.32169 4.0888 4.21827 4.15353 4.15353C4.21827 4.0888 4.32169 4.0888 4.38643 4.15353L7.64643 7.41353L7.99962 7.76673L8.35317 7.4139L11.6132 4.16056Z"
            stroke="currentColor"
        />
    </svg>
)
