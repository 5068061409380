import { t } from "@lingui/macro"
import { ListItem, ListItemButton, useTheme } from "@mui/material"

import { ListItemText } from "src/layouts/DefaultLayout/styled"

import { Icon } from "src/components/icons"
import { Link } from "src/layouts/DefaultLayout/Navigation/Link"
import { ListItemIcon } from "src/layouts/DefaultLayout/Navigation/ListItem/ListItemIcon"
import { INavigationItemSingle } from "src/store/navigation/types"

interface IProps {
    item: INavigationItemSingle
}

export const ListItemSingle = (props: IProps) => {
    const theme = useTheme()

    return (
        <ListItem disablePadding>
            <ListItemButton
                component={Link}
                title={t({ id: props.item.text })}
                to={props.item.to}
            >
                <ListItemIcon>
                    <Icon
                        icon={props.item.icon}
                        size={24}
                        color={theme.palette.grey[800]}
                    />
                </ListItemIcon>
                <ListItemText
                    primary={t({ id: props.item.text })}
                    color={theme.palette.grey[800]}
                />
            </ListItemButton>
        </ListItem>
    )
}
