import { observer } from "mobx-react"
import { useCallback, useEffect } from "react"
import { t } from "@lingui/macro"
import {
    Stack,
    Link,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Box,
    styled,
} from "@mui/material"

import { Table } from "src/components/Table"
import { Pdf24 } from "src/components/icons/Pdf24"
import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"
import { SelfRegistrationStore } from "src/views/self-registration/store"

export const SelfRegistrationPropertyTab = observer(() => {
    const store = useStore(SelfRegistrationStore)
    const globalStore = useStore(GlobalStore)

    useEffect(() => {
        trackModuleEvent("Self registration | List view", {
            [MixpanelProperties.SelfRegistrationTabName]: "Property",
        })
    }, [])

    const handleLegalEntityChange = useCallback(
        async (event: SelectChangeEvent) => {
            store.setSelectedLegalEntity(event.target.value)
            store.setProperties(
                await store.fetchProperties(Number(event.target.value)),
            )
            await store.propertiesPagination.loadInitialPage()
        },
        [store],
    )

    return (
        <Stack spacing={3}>
            <StyledBox
                sx={{
                    gridAutoColumns: {
                        md: "minmax(min-content, max-content)",
                    },
                }}
            >
                <FormControl fullWidth>
                    <InputLabel id="legal-entity-select-label">
                        {t`self-registration-view.legal-entity`}
                    </InputLabel>
                    <Select
                        labelId="legal-entity-select-label"
                        id="legal-entity-select"
                        label={t`self-registration-view.legal-entity`}
                        value={store.selectedLegalEntity}
                        onChange={handleLegalEntityChange}
                        sx={{
                            minWidth: 250,
                        }}
                        disabled={globalStore.session.user?.isSuper === true}
                        data-testid="legal-entity2-select"
                    >
                        {globalStore.session.propertyOwners[0].legalEntities?.map(
                            (legalEntity) => (
                                <MenuItem
                                    key={legalEntity.legalEntityId}
                                    value={legalEntity.legalEntityId}
                                >
                                    {legalEntity.legalName}
                                </MenuItem>
                            ),
                        )}
                    </Select>
                </FormControl>
            </StyledBox>
            {store.properties.length > 0 && (
                <Table
                    paginator={store.propertiesPagination}
                    headers={[
                        {
                            key: "property",
                            name: t`self-registration-view.property`,
                        },
                        {
                            key: "propertyCode",
                            name: t`self-registration-view.property-code`,
                        },
                        {
                            key: "address",
                            name: t`self-registration-view.address`,
                        },
                        {
                            key: "city",
                            name: t`self-registration-view.city`,
                        },
                        {
                            key: "codeDistributionLeaflet",
                            name: t`self-registration-view.code-distribution-leaflet`,
                        },
                    ]}
                    rowRenderer={(property) => ({
                        property: property.name,
                        propertyCode: property.propertyId,
                        address: "",
                        city: property.city,
                        codeDistributionLeaflet: property.leaflet != null &&
                            property.leaflet !== "" && (
                                <Link
                                    href={property.leaflet}
                                    target="_blank"
                                    sx={{
                                        textTransform: "uppercase",
                                        lineHeight: 0,
                                        display: "block",
                                    }}
                                >
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        gap={1}
                                        style={{
                                            display: "inline-flex",
                                            fontWeight: "500",
                                        }}
                                    >
                                        <Pdf24 />
                                        {t`self-registration-view.download`}
                                    </Stack>
                                </Link>
                            ),
                    })}
                />
            )}
        </Stack>
    )
})

const StyledBox = styled(Box)(() => ({
    display: "grid",
    gap: "20px",
    alignItems: "center",
}))
