import * as React from "react"
import Button from "@mui/material/Button"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { Checkbox } from "@mui/material"
import { debounce } from "lodash"

import { t } from "@lingui/macro"

import { observer } from "mobx-react"

import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { IConfirmDeleteModalProps } from "src/types/confirm-delete-modal"

export const ConfirmDeleteModal = observer(
    (props: IConfirmDeleteModalProps) => {
        const { title, content, additionalConfirmation } = props
        const gstore = useStore(GlobalStore)
        const [selected, setSelected] = React.useState<boolean>(false)

        const handleClose = () => {
            gstore.modals.pop()
        }

        const handleDelete = debounce(() => {
            props.variant === "single"
                ? props.deleteItem(props.itemId)
                : props.deleteItems()
        }, 500)

        return (
            <>
                <DialogTitle id="alert-dialog-title">
                    {title ?? t`confirm-delete-modal.default-title`}
                </DialogTitle>
                <DialogContent
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    {additionalConfirmation === true && (
                        <Checkbox
                            data-testid="confirm-delete-checkbox"
                            checked={selected}
                            onChange={() => setSelected((prev) => !prev)}
                        />
                    )}
                    <DialogContentText id="alert-dialog-description">
                        {content ?? t`confirm-delete-modal.default-content`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    style={{
                        justifyContent: "flex-start",
                        paddingLeft: "24px",
                    }}
                >
                    <Button color="info" onClick={handleClose}>
                        {t`confirm-delete-modal.cancel`}
                    </Button>
                    <Button
                        data-testid="Delete-button"
                        color="error"
                        onClick={handleDelete}
                        autoFocus
                        disabled={
                            additionalConfirmation === true ? !selected : false
                        }
                    >
                        {t`confirm-delete-modal.delete`}
                    </Button>
                </DialogActions>
            </>
        )
    },
)
