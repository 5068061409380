import { Button, styled } from "@mui/material"

const ChatInputControlsSquareButton = styled(Button)({
    padding: "6px 8px",
    minWidth: "auto",
})

const ChatInputControlsUploadButton = styled(ChatInputControlsSquareButton)(
    ({ theme }) => ({
        borderColor: theme.palette.grey[500],
        background: theme.palette.common.white,
        color: theme.palette.grey[700],
    }),
)

export { ChatInputControlsSquareButton, ChatInputControlsUploadButton }
