import { observer } from "mobx-react"
import React from "react"

import { List } from "@mui/material"

import { IActionDropdownItem } from "src/components/ActionDropdown"
import { Item } from "src/components/ActionList/Item"

interface IProps<TItem> {
    items: TItem[]
    rowRenderer: (item: TItem) => React.ReactNode
    rowActionsRenderer?: (item: TItem) => IActionDropdownItem[]
}

export const ActionList = observer(function <TItem>(props: IProps<TItem>) {
    return (
        <List component="ol">
            {props.items.map((item, index) => (
                <Item key={index} rowActions={props.rowActionsRenderer?.(item)}>
                    {props.rowRenderer(item)}
                </Item>
            ))}
        </List>
    )
})
