import { t } from "@lingui/macro"
import { FormHelperText, IconButton, Link, Stack } from "@mui/material"
import { observer } from "mobx-react"

import { UploadDocumentField } from "src/components/AttachmentsField/DocumentAttachments/UploadDocumentField"
import { Bin24 } from "src/components/icons/Bin24"
import { Pdf24 } from "src/components/icons/Pdf24"
import { formatFileSize } from "src/lib/number"
import { DocumentStyledStack } from "src/modals/library-information-items/formFields/styled"
import { LibraryInformationItemStore } from "src/modals/library-information-items/store"
import { useStore } from "src/store/lib/useStore"

export const LibraryInformationItemsDocument = observer(() => {
    const store = useStore(LibraryInformationItemStore)
    const document = store.formFields.get("document")

    return (
        <Stack spacing={2}>
            <div>
                <UploadDocumentField
                    onChange={(files) => {
                        store.formFields.set("document", files[0] as ILocalFile)
                    }}
                    disabled={store.formFields.get("accessType") === "READ"}
                    max={1}
                    placeholder={t`edit-information-item-modal.document-placeholder`}
                    onError={(errorMessage: string) =>
                        store.formFields.setError("document", errorMessage)
                    }
                />

                <FormHelperText
                    sx={{
                        margin: "3px 14px 0",
                    }}
                    error={Boolean(store.formFields.error("document"))}
                >
                    {store.formFields.error("document") ??
                        t`general.document-upload-helper`}
                </FormHelperText>
            </div>

            {document != null && (
                <DocumentStyledStack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack
                        component="span"
                        spacing={1}
                        direction="row"
                        alignItems="center"
                    >
                        <Pdf24 style={{ flexShrink: "0" }} />
                        <Link
                            href={
                                typeof document === "string"
                                    ? document
                                    : document.url
                            }
                            target="_blank"
                        >
                            {typeof document === "string"
                                ? `${t`edit-information-item-modal.uploaded-file-name`}.${document
                                      .split(".")
                                      .pop()}`
                                : document.file.name}
                        </Link>
                    </Stack>
                    <Stack
                        component="span"
                        spacing={1}
                        direction="row"
                        alignItems="center"
                    >
                        <span style={{ whiteSpace: "nowrap" }}>
                            {typeof document !== "string"
                                ? formatFileSize(document.file.size)
                                : ""}
                        </span>
                        <IconButton
                            onClick={() =>
                                store.formFields.set("document", undefined)
                            }
                            title={t`edit-information-item-modal.remove-document`}
                            disabled={
                                store.formFields.get("accessType") === "READ"
                            }
                        >
                            <Bin24 />
                        </IconButton>
                    </Stack>
                </DocumentStyledStack>
            )}
        </Stack>
    )
})
