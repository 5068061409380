/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { admin_FileUploadResponse } from "../models/admin_FileUploadResponse"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class FileAdminService {
    /**
     * File upload
     * Uploads a file (image or pdf)
     * @returns admin_FileUploadResponse OK
     * @throws ApiError
     */
    public static fileUpload({
        file,
    }: {
        /** File to be uploaded **/
        file: Blob
    }): CancelablePromise<admin_FileUploadResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/file/upload",
            formData: {
                file: file,
            },
        })
    }

    /**
     * Upload file
     * Uploads a file (image or pdf)
     * @returns string OK
     * @throws ApiError
     */
    public static postV1AdminUpload({
        file,
    }: {
        /** File to be uploaded **/
        file: Blob
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/upload",
            formData: {
                file: file,
            },
        })
    }
}
