export const LeftSideStatusBar = () => (
    <svg
        width="54"
        height="18"
        viewBox="0 0 54 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.5014 14.2637C8.98916 14.2637 7.48037 12.1323 7.48037 8.71924V8.70459C7.48037 5.2915 8.98916 3.16748 11.5014 3.16748C14.0136 3.16748 15.537 5.2915 15.537 8.70459V8.71924C15.537 12.1323 14.0136 14.2637 11.5014 14.2637ZM11.5014 12.7329C12.849 12.7329 13.6327 11.2241 13.6327 8.71924V8.70459C13.6327 6.19971 12.849 4.70557 11.5014 4.70557C10.1537 4.70557 9.38467 6.19971 9.38467 8.70459V8.71924C9.38467 11.2241 10.1537 12.7329 11.5014 12.7329ZM21.0524 3.16748C23.3083 3.16748 25.2712 4.77148 25.2712 8.58008V8.59473C25.2712 12.1543 23.6672 14.2637 21.0085 14.2637C19.0676 14.2637 17.6174 13.1138 17.2805 11.4951L17.2658 11.4146H19.1262L19.1481 11.4878C19.4265 12.2275 20.0783 12.7109 21.0085 12.7109C22.6857 12.7109 23.3962 11.0703 23.4768 9.04883C23.4768 8.96826 23.4841 8.8877 23.4841 8.80713H23.3376C22.9494 9.64209 22.0266 10.3818 20.5764 10.3818C18.5476 10.3818 17.1267 8.90234 17.1267 6.89551V6.88086C17.1267 4.73486 18.76 3.16748 21.0524 3.16748ZM21.0451 8.93164C22.239 8.93164 23.1545 8.06738 23.1545 6.87354V6.85889C23.1545 5.65039 22.239 4.70557 21.0671 4.70557C19.9025 4.70557 18.9724 5.63574 18.9724 6.81494V6.82959C18.9724 8.05273 19.8439 8.93164 21.0451 8.93164ZM28.2964 7.15186C27.6299 7.15186 27.1318 6.63916 27.1318 5.99463C27.1318 5.34277 27.6299 4.8374 28.2964 4.8374C28.9702 4.8374 29.4609 5.34277 29.4609 5.99463C29.4609 6.63916 28.9702 7.15186 28.2964 7.15186ZM28.2964 12.5864C27.6299 12.5864 27.1318 12.0811 27.1318 11.4292C27.1318 10.7773 27.6299 10.272 28.2964 10.272C28.9702 10.272 29.4609 10.7773 29.4609 11.4292C29.4609 12.0811 28.9702 12.5864 28.2964 12.5864ZM36.4485 14V11.9712H31.2776V10.4258C32.6473 8.04541 34.1487 5.61377 35.5843 3.43115H38.2576V10.4111H39.6785V11.9712H38.2576V14H36.4485ZM33.0354 10.4551H36.4778V4.93994H36.368C35.284 6.59521 34.0608 8.55078 33.0354 10.3452V10.4551ZM43.7584 14V5.2915H43.6339L41.0045 7.15186V5.37207L43.7657 3.43115H45.648V14H43.7584Z"
            fill="#333333"
        />
    </svg>
)
