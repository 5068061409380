import { makeAutoObservable } from "mobx"
import React from "react"

import { reportSuccess, reportUnhandledApiError } from "src/lib/report"

import { DevService, dev_DeleteEmbedPurchaseRequest } from "src/api"

export class ResetStore {
    static Context = React.createContext<ResetStore | null>(null)

    constructor() {
        makeAutoObservable(this)
    }

    public deleteEmbedPurchase = async ({
        integrationCode,
        tenantId,
    }: {
        integrationCode: string
        tenantId: number
    }) => {
        const request: dev_DeleteEmbedPurchaseRequest = {
            integration_code: integrationCode,
            tenant_id: tenantId,
        }
        try {
            await DevService.postV1AdminDevDeleteEmbedPurchase({ request })
            reportSuccess("Successful")
        } catch (e) {
            reportUnhandledApiError(e)
        }
    }

    public deleteStandardImprovementOrder = async ({
        tenantId,
    }: {
        tenantId: number
    }) => {
        try {
            await DevService.postV1AdminDevDeleteStandardImprovementOrder({
                request: { tenant_id: tenantId },
            })
            reportSuccess("Successful")
        } catch (e) {
            reportUnhandledApiError(e)
        }
    }

    public enableMoveIn = async ({
        leaseContractId,
    }: {
        leaseContractId: number
    }) => {
        try {
            await DevService.postV1AdminDevEnableMoveIn({
                request: { lease_contract_id: leaseContractId },
            })
            reportSuccess("Successful")
        } catch (e) {
            reportUnhandledApiError(e)
        }
    }

    public resetAxa = async ({ tenantId }: { tenantId: number }) => {
        try {
            await DevService.postV1AdminDevResetAxa({
                request: { tenant_id: tenantId },
            })
            reportSuccess("Successful")
        } catch (e) {
            reportUnhandledApiError(e)
        }
    }

    public unregisterTenant = async ({ tenantId }: { tenantId: number }) => {
        try {
            await DevService.postV1AdminDevUnregisterTenant({
                request: { tenant_id: tenantId },
            })
            reportSuccess("Successful")
        } catch (e) {
            reportUnhandledApiError(e)
        }
    }

    public unsignHedvig = async ({ tenantId }: { tenantId: number }) => {
        try {
            await DevService.postV1AdminDevUnsignHedvig({
                request: { tenant_id: tenantId },
            })
            reportSuccess("Successful")
        } catch (e) {
            reportUnhandledApiError(e)
        }
    }
}
