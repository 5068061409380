import {
    chatbot_MessageType,
    chatbot_Source,
    contact_MessageType,
} from "src/api"
import { ISystemMessage } from "src/types/chat-message/systemMessage"
import type { chatbot_NodeMetadata } from "src/api/models/chatbot_NodeMetadata"

type TMessageType = contact_MessageType | chatbot_MessageType

export enum EMessageType {
    Message = "message",
    SystemMessage = "system_message",
    SystemMessageV2 = "SYSTEM",
}

export enum EMessageSender {
    User = "USER",
    Chatbot = "CHATBOT",
    Admin = "ADMIN",
    System = "SYSTEM",
}

export type TMessageSender =
    | EMessageSender.User
    | EMessageSender.Chatbot
    | EMessageSender.Admin

export interface IChatMessage {
    authorType: string
    authorName: string
    createdAt: string
    messageId: string
    text: string
    /**
     * This indicates who is the message sender, if it was the user, chatbot or admin
     */
    messageSender?: TMessageSender
    source?: chatbot_Source[]
    /* messageType will describe if the message was sent by the admin or status changing in the system */
    messageType?: TMessageType
    /* object that contains messages coming from system only */
    systemMessage?: ISystemMessage
    systemMessageId?: string
    /* These are currently supported by contact-forms messages only */
    attachmentUrls?: string[]
    contentFormId?: number
    nodeInfo?: Array<chatbot_NodeMetadata>
    sourceEval?: string
    translationText?: string
}
