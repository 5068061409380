import { Stack, Typography, useTheme } from "@mui/material"
import { observer } from "mobx-react"
import React from "react"

import { Icon } from "src/components/icons"
import { Alert16 } from "src/components/icons/Alert16"
import { Tooltip } from "src/components/Tooltips/Tooltip"

interface IProps {
    header?: string
    description?: string
    tooltip?: string
    children: React.ReactNode
}

export const FormFieldSet = observer((props: IProps) => {
    const theme = useTheme()

    return (
        <Stack spacing={1}>
            {props.header != null && (
                <Stack>
                    <Stack
                        component="span"
                        direction="row"
                        spacing={1}
                        alignItems="center"
                    >
                        <Typography lineHeight={1.75}>
                            {props.header}
                        </Typography>
                        {props.tooltip != null && (
                            <Tooltip
                                title={props.tooltip}
                                placement="right"
                                arrow
                            >
                                <Stack
                                    component="span"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Icon
                                        icon={<Alert16 />}
                                        color={theme.palette.grey[700]}
                                    />
                                </Stack>
                            </Tooltip>
                        )}
                    </Stack>
                    {props.description != null && (
                        <Typography
                            fontSize="12px"
                            color={theme.palette.grey[900]}
                            lineHeight={1.66}
                        >
                            {props.description}
                        </Typography>
                    )}
                </Stack>
            )}
            <div>{props.children}</div>
        </Stack>
    )
})
