import { Button } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { IMultiSelectItem } from "./store"

import {
    Section,
    SubHeader,
    TextContainer,
    Header,
} from "src/modals/manage-users-create-edit-copy/styled"
import { ScrollableEditModal } from "src/modals/scrollable-edit-modal"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"

export interface IEditSectionProps {
    title: string
    subTitle: string
    allItems: IMultiSelectItem[]
    selectedItems: number[]
    handleCheckboxChange: (id: number, AllSelect: boolean) => void
    buttonText: string
    buttonDisabled: boolean
}

export const EditSection = observer(
    ({
        title,
        subTitle,
        buttonText,
        allItems,
        selectedItems,
        handleCheckboxChange,
        buttonDisabled,
    }: IEditSectionProps) => {
        const globalStore = useStore(GlobalStore)

        const handleOpenEditModal = useCallback(() => {
            globalStore.modals.open(
                () => (
                    <ScrollableEditModal
                        title={title}
                        items={allItems}
                        selectedItems={selectedItems}
                        handleCheckboxChange={handleCheckboxChange}
                    />
                ),
                {
                    variant: "slide-up-w600",
                },
            )
        }, [
            allItems,
            globalStore.modals,
            handleCheckboxChange,
            selectedItems,
            title,
        ])

        return (
            <Section>
                <TextContainer>
                    <Header>{title}</Header>
                    <SubHeader>{subTitle}</SubHeader>
                </TextContainer>
                <div>
                    <Button
                        variant="outlined"
                        color="info"
                        onClick={handleOpenEditModal}
                        disabled={buttonDisabled}
                    >
                        {buttonText}
                    </Button>
                </div>
            </Section>
        )
    },
)
