import { styled, Theme } from "@mui/material"
import React from "react"
import { NavLink as RRDLink } from "react-router-dom"

interface IPropsBase {
    to: string | (() => void)
    title: string
    children: React.ReactNode
}

type INestingRelationshipProps = { child?: boolean; parent?: boolean }

type IProps = IPropsBase & INestingRelationshipProps

const createStyle = (theme: Theme, props: INestingRelationshipProps) => ({
    fontSize: theme.typography.fontSize,
    backgroundColor: "transparent",
    display: "flex",
    alignItems: "center",
    color: theme.palette.grey[100],
    height: "40px",
    lineHeight: "40px",
    width: "100%",
    boxSizing: "border-box" as const,
    textDecoration: "none",
    border: 0,
    borderRadius: "8px 0px 0px 8px",
    cursor: "pointer",
    "&.active":
        props.parent == null
            ? {
                  backgroundColor: theme.palette.grey[50],
                  color: theme.palette.grey[100],
              }
            : {},
    "&:active": {
        backgroundColor: "transparent",
        color: theme.palette.grey[100],
        opacity: 0.8,
    },
    textAlign: "left" as const,
    marginLeft: props.child != null ? "45px" : "16px",
    padding: 0,
})

const styledOptions = {
    shouldForwardProp: (name: string) => !["child", "parent"].includes(name),
}

const InternalLink = styled(
    RRDLink,
    styledOptions,
)<INestingRelationshipProps>(({ theme, child, parent }) =>
    createStyle(theme, { child, parent }),
)

const ExternalLink = styled(
    "a",
    styledOptions,
)<INestingRelationshipProps>(({ theme, child, parent }) =>
    createStyle(theme, { child, parent }),
)

const Button = styled(
    "button",
    styledOptions,
)<INestingRelationshipProps>(({ theme, child, parent }) =>
    createStyle(theme, { child, parent }),
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Link = React.forwardRef<any, IProps>((props, ref) => {
    if (typeof props.to === "function") {
        return (
            <Button
                ref={ref}
                title={props.title}
                onClick={props.to}
                child={props.child}
                parent={props.parent}
            >
                {props.children}
            </Button>
        )
    } else if (props.to.match(/https?:\/\//) != null) {
        return (
            <ExternalLink
                ref={ref}
                title={props.title}
                href={props.to}
                child={props.child}
                parent={props.parent}
            >
                {props.children}
            </ExternalLink>
        )
    } else {
        return (
            <InternalLink
                ref={ref}
                title={props.title}
                to={props.to}
                child={props.child}
                parent={props.parent}
            >
                {props.children}
            </InternalLink>
        )
    }
})
