import { Stack, styled } from "@mui/material"

export const Field = styled(Stack)(({ theme }) => ({
    background: theme.palette.grey[100],
    color: theme.palette.grey[900],
    borderRadius: 1000,
    padding: "0 1rem",
    height: "40px",
    flex: 1,
    fontWeight: 500,
    fontSize: "14px",
}))
