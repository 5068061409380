import { t } from "@lingui/macro"
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    styled,
    TextField,
} from "@mui/material"
import { observer } from "mobx-react"
import { useMemo } from "react"

import { MixpanelProperties } from "src/analytics/constants/properties"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"

import { FormFieldSet } from "src/components/FormFieldSet"
import { useUniqueId } from "src/lib/unique-id"
import { StaticAttachments } from "src/modals/contact-form-detail/StaticAttachments"
import { ContactFormDetailStore } from "src/modals/contact-form-detail/store"
import { IErrandSectionProps } from "src/modals/contact-form-detail/types/errandSectionProps"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { ContactFormStatus, parseStatus } from "src/types/contact-form"

const StatusDot = styled("span", { shouldForwardProp: () => true })<{
    status: ContactFormStatus
}>(({ status, theme }) => ({
    display: "inline-block",
    height: "12px",
    width: "12px",
    marginRight: "8px",
    borderRadius: "10000px",
    background: {
        [ContactFormStatus.Done]: theme.palette.utility.apple.main,
        [ContactFormStatus.InProgress]: theme.palette.utility.orange.main,
        [ContactFormStatus.Sent]: theme.palette.utility.blue.main,
        [ContactFormStatus.SentFallback]: theme.palette.utility.blue.main,
        [ContactFormStatus.Unknown]: theme.palette.grey[700],
    }[status],
}))

export const ErrandSection = observer((props: IErrandSectionProps) => {
    const store = useStore(ContactFormDetailStore)
    const gstore = useStore(GlobalStore)
    const statusLabelId = useUniqueId()

    const statuses = [
        ContactFormStatus.Sent,
        ContactFormStatus.SentFallback,
        ContactFormStatus.InProgress,
        ContactFormStatus.Done,
    ]

    const statusTranslations = {
        [ContactFormStatus.Done]: t`contact-form-detail-modal.errand-section.status-done`,
        [ContactFormStatus.InProgress]: t`contact-form-detail-modal.errand-section.status-in-progress`,
        [ContactFormStatus.Sent]: t`contact-form-detail-modal.errand-section.status-sent`,
        [ContactFormStatus.SentFallback]: t`contact-form-detail-modal.errand-section.status-sent-fallback`,
        [ContactFormStatus.Unknown]: t`contact-form-detail-modal.errand-section.status-unknown`,
    }

    const hasAttachments = store.form
        .get("attachments")
        .some((attachment) => attachment !== "")

    const apartmentAccess = useMemo((): {
        value: string
        label: string
    } | null => {
        if (store.form.get("apartmentAccess") !== "") {
            return {
                value: store.form.get("apartmentAccess"),
                label: t`contact-form-detail-modal.errand-section.apartment-access-label`,
            }
        } else if (store.form.get("apartmentAccess1") !== "") {
            return {
                value: store.form.get("apartmentAccess1"),
                label: t`contact-form-detail-modal.errand-section.apartment-access-1-label`,
            }
        } else if (store.form.get("apartmentAccess2") !== "") {
            return {
                value: store.form.get("apartmentAccess2"),
                label: t`contact-form-detail-modal.errand-section.apartment-access-2-label`,
            }
        }

        return null
    }, [store.form])

    const onSelectValueChange = (value: string) => {
        trackModuleEvent("Cases | Change status", {
            [MixpanelProperties.ItemID]: props.id,
            [MixpanelProperties.AccessGroupName]:
                gstore.session.accessGroup?.name,
            [MixpanelProperties.AccessGroupID]: gstore.session.accessGroup?.id,
        })
        store.form.set("status", parseStatus(value))
    }
    return (
        <FormFieldSet>
            <Stack spacing={2}>
                <Stack spacing={2} direction="row">
                    <TextField
                        label={t`contact-form-detail-modal.errand-section.type-label`}
                        value={store.form.get("typeName")}
                        onChange={store.form.setter("typeName")}
                        disabled
                    />
                    {Boolean(store.form.get("location")) && (
                        <TextField
                            label={t`contact-form-detail-modal.errand-section.location-label`}
                            value={store.form.get("location")}
                            onChange={store.form.setter("location")}
                            disabled
                        />
                    )}
                </Stack>
                <FormControl>
                    <InputLabel id={statusLabelId}>
                        {t`contact-form-detail-modal.errand-section.status-label`}
                    </InputLabel>
                    <Select
                        label={t`contact-form-detail-modal.errand-section.status-label`}
                        labelId={statusLabelId}
                        fullWidth
                        value={store.form.get("status")}
                        onChange={(event) =>
                            onSelectValueChange(event.target.value)
                        }
                    >
                        {statuses.map((status) => (
                            <MenuItem key={status} value={status}>
                                <StatusDot status={status} />
                                {statusTranslations[status]}
                            </MenuItem>
                        ))}
                        {store.form.get("status") ===
                        ContactFormStatus.Unknown ? (
                            <MenuItem value={ContactFormStatus.Unknown}>
                                <StatusDot status={ContactFormStatus.Unknown} />
                                {statusTranslations[ContactFormStatus.Unknown]}
                            </MenuItem>
                        ) : null}
                    </Select>
                </FormControl>
                {Boolean(store.form.get("title")) && (
                    <TextField
                        label={t`contact-form-detail-modal.errand-section.title-label`}
                        value={store.form.get("title")}
                        onChange={store.form.setter("title")}
                        disabled
                    />
                )}
                {Boolean(store.form.get("objectId")) && (
                    <TextField
                        label={t`contact-forms-view.forms-tab.object-id-header`}
                        value={store.form.get("objectId")}
                        disabled
                    />
                )}
                {Boolean(store.form.get("externalObjectId")) && (
                    <TextField
                        label={t`contact-form-detail-modal.errand-section.external-object-id-label`}
                        value={store.form.get("externalObjectId")}
                        disabled
                    />
                )}
                {hasAttachments && (
                    <div>
                        <StaticAttachments
                            attachments={store.form.get("attachments")}
                            maxAttachments={3}
                        />
                    </div>
                )}
                {Boolean(store.form.get("description")) && (
                    <TextField
                        label={t`contact-form-detail-modal.errand-section.description-label`}
                        value={store.form.get("description")}
                        onChange={store.form.setter("description")}
                        rows={3}
                        multiline
                        disabled
                    />
                )}
                {apartmentAccess != null && (
                    <TextField
                        label={apartmentAccess.label}
                        value={apartmentAccess.value}
                        disabled
                    />
                )}
            </Stack>
        </FormFieldSet>
    )
})
