import React from "react"
import { observer } from "mobx-react-lite"
import { Grid, Typography, Box } from "@mui/material"

import {
    ImagesBox,
    ImageItem,
} from "src/components/AssetLibraryPicker/tabs/styled"

import {
    IAsset,
    IImageLibraryProps,
} from "src/components/AssetLibraryPicker/types"

const ImageLibrary: React.FC<IImageLibraryProps> = observer(
    ({ icons, handleIconSelect }: IImageLibraryProps) => {
        return (
            <Grid container spacing={2}>
                {icons.map((icon: IAsset, index: number) => (
                    <Grid
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                        }}
                        item
                        xs={6}
                        sm={4}
                        md={4}
                        key={icon?.name + index}
                    >
                        <ImageItem onClick={() => handleIconSelect(icon)}>
                            <ImagesBox className="icon-box">
                                <Box
                                    component="img"
                                    src={icon.path}
                                    alt={icon.name}
                                    sx={{ width: 116, height: 152 }}
                                />
                            </ImagesBox>
                        </ImageItem>
                        <Typography
                            sx={{ paddingLeft: 1 }}
                            variant="caption"
                            align="left"
                            noWrap
                        >
                            {`${icon.name}`.replace(".png", "")}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
        )
    },
)

export default ImageLibrary
