import { observer } from "mobx-react"

import { useCallback } from "react"

import { t } from "@lingui/macro"

import { Stack, useTheme } from "@mui/material"

import { persistFiles } from "src/lib/file"
import { formFields } from "src/modals/embedded-integration-v2/formFields"
import { DynamicCardLayout } from "src/components/DynamicCardLayout"
import { ModalTextField } from "src/components/ModalTextField"
import { ISalesDynamicFieldsTabProps } from "src/modals/embedded-integration-v2/types/salesDynamicFieldTab"
import { IDynamicFieldItemProps } from "src/modals/embedded-integration-v2/types/dynamicFieldItem"
import { initialState } from "src/modals/embedded-integration-v2/constants/initialState"
import { EmbeddedIntegrationSalesTabBulletOptionSection } from "src/modals/embedded-integration-v2/components/SalesBulletOptionView"
import { useStore } from "src/store/lib/useStore"
import { ContentSectionStore } from "src/modals/embedded-integration-v2/sections/ContentSection/store"

export const EmbeddedIntegrationSalesCardSection = observer(
    ({ index, item, onRemoveClick }: ISalesDynamicFieldsTabProps) => {
        const contentStore = useStore(ContentSectionStore)
        const salesDynamicFieldsList = contentStore.salesTabFields
        const theme = useTheme()
        const handleIconDelete = useCallback(
            (key: string) => {
                const data = [...salesDynamicFieldsList]
                const obj = JSON.parse(JSON.stringify(data[index]))
                obj[key].value = undefined
                data[index] = obj
                contentStore.fields.set("salesDynamicFieldsList", data)
            },
            [index, contentStore, salesDynamicFieldsList],
        )

        const handleIconChange = useCallback(
            async (images: IFile[], key: string) => {
                const data = [...salesDynamicFieldsList]
                const obj = JSON.parse(JSON.stringify(data[index]))
                const icon = images[0]
                if (Object.keys(icon).length > 0) {
                    const images = await persistFiles(
                        [icon as ILocalFile],
                        "image",
                    )

                    if (images.length > 0) {
                        obj[key].value = images[0].url
                        data[index] = obj
                        contentStore.fields.set("salesDynamicFieldsList", data)
                    }
                }
            },
            [index, contentStore, salesDynamicFieldsList],
        )

        const handleTextInputChange = (
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            key: string,
        ) => {
            const data = [...salesDynamicFieldsList]
            const obj = JSON.parse(JSON.stringify(data[index]))
            obj[key].errorMessage = ""
            obj[key].value =
                event.target.value.trim() !== "" ? event.target.value : ""
            data[index] = obj
            contentStore.fields.set("salesDynamicFieldsList", data)
        }

        const renderTextInput = (
            label: string,
            item: IDynamicFieldItemProps,
            key: string,
            rowLength: number,
            maxLength?: number,
        ) => {
            return (
                <ModalTextField
                    variant="default"
                    label={label}
                    value={item?.value}
                    onChange={(event) => handleTextInputChange(event, key)}
                    helperText={item.errorMessage}
                    error={Boolean(item.errorMessage)}
                    maxLength={maxLength ?? 255}
                    rows={rowLength}
                />
            )
        }
        const renderAddFileSection = (
            item: IDynamicFieldItemProps,
            key: string,
        ) => {
            return (
                <formFields.EmbeddedIntegrationSvgIconUpload
                    header={t`embed-integrations-modal.content-section.sales-tab.sales-card.svg-icon-header`}
                    subHeader={t`embed-integrations-modal.content-section.sales-tab.sales-card.svg-icon.subtitle`}
                    value={item.value ?? ""}
                    onIconDelete={() => handleIconDelete(key)}
                    onIconChange={(images) => handleIconChange(images, key)}
                    errorMessage={item.errorMessage ?? ""}
                    acceptedFormats={["image/svg+xml"]}
                    setErrorMessage={(error) => (item.errorMessage = error)}
                />
            )
        }
        const handleSalesCardBulletOptionsTextInputChange = (
            i: number,
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => {
            const data = [...salesDynamicFieldsList]
            const obj = JSON.parse(JSON.stringify(data[index]))
            obj.salesCardBulletOptions[i].errorMessage = ""
            obj.salesCardBulletOptions[i].value =
                event.target.value.trim() !== "" ? event.target.value : ""
            if (event.target.value.trim() === "") {
                obj.salesCardBulletOptions[i].errorMessage = t`errors.required`
            }
            data[index] = obj
            contentStore.fields.set("salesDynamicFieldsList", data)
        }
        const handleAddSalesCardBulletOptionClick = () => {
            const data = [...salesDynamicFieldsList]
            data[index].salesCardBulletOptions = [
                ...data[index].salesCardBulletOptions,
                initialState,
            ]
            contentStore.fields.set("salesDynamicFieldsList", data)
        }
        const handleSalesCardRemoveBulletOptionsClick = (i: number) => {
            const data = [...salesDynamicFieldsList]
            data[index].salesCardBulletOptions.splice(i, 1)
            contentStore.fields.set("salesDynamicFieldsList", data)
        }

        const renderSalesCardBulletOptionSection = (
            item: IDynamicFieldItemProps[],
        ) => (
            <EmbeddedIntegrationSalesTabBulletOptionSection
                headerLabel={t`embed-integrations-modal.content-section.sales-tab.sales-page-bullet-header`}
                textInputLabel={t`embed-integrations-modal.content-section.sales-tab.sales-page-bullet.text-placeholder`}
                buttonText={t`embed-integrations-modal.content-section.sales-tab.add-bullet-label`}
                item={item}
                theme={theme}
                onInputChange={handleSalesCardBulletOptionsTextInputChange}
                onRemoveClick={handleSalesCardRemoveBulletOptionsClick}
                onAddOptionClick={handleAddSalesCardBulletOptionClick}
                disabled={false}
            />
        )

        return (
            <DynamicCardLayout
                index={index}
                showDraggableIndicator={true}
                hideCopyContentIcon={true}
                hideBinIcon={false}
                onRemoveClick={onRemoveClick}
            >
                <Stack spacing={2}>
                    {renderTextInput(
                        t`embed-integrations-modal.content-section.sales-tab.sales-card-headline`,
                        item.salesCardHeadline,
                        "salesCardHeadline",
                        1,
                        255,
                    )}
                    {renderTextInput(
                        t`embed-integrations-modal.content-section.sales-tab.sales-card-subtitle`,
                        item.salesCardSubtitle,
                        "salesCardSubtitle",
                        1,
                        255,
                    )}
                    {renderAddFileSection(
                        item.salesCardFileUpload,
                        "salesCardFileUpload",
                    )}

                    {renderTextInput(
                        t`embed-integrations-modal.content-section.sales-tab.sales-card-description`,
                        item.salesCardDescription,
                        "salesCardDescription",
                        4,
                        10000,
                    )}
                    {renderSalesCardBulletOptionSection(
                        item.salesCardBulletOptions,
                    )}
                </Stack>
            </DynamicCardLayout>
        )
    },
)
