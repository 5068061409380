import Box from "@mui/material/Box"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select, { SelectChangeEvent } from "@mui/material/Select"

import { observer } from "mobx-react"

import { t } from "@lingui/macro"

import { useCallback } from "react"

import { useStore } from "src/store/lib/useStore"
import { CommunityInsightsStore } from "src/views/community-insights/store"
import { transformTopicKeyToReadable } from "src/views/community-insights/helpers"

export const TopicSingleSelect = observer(() => {
    const store = useStore(CommunityInsightsStore)

    const handleChange = useCallback(
        (event: SelectChangeEvent) => {
            store.selectedTopic = event.target.value
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [store.selectedTopic],
    )

    return (
        <Box sx={{ minWidth: 120, "& ul": { paddingTop: 0 } }}>
            <FormControl fullWidth>
                <InputLabel id="topic-single-select-label">{t`community-insights.community-insights-topics`}</InputLabel>
                <Select
                    id="topic-single-select"
                    value={store.selectedTopic?.toString() ?? ""}
                    label={t`community-insights.community-insights-topics`}
                    onChange={handleChange}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                "& .MuiMenu-list": {
                                    padding: 0,
                                },
                            },
                        },
                    }}
                >
                    {store.communityTopics.map((topic) => (
                        <MenuItem
                            key={topic.community_topic_id}
                            value={topic.community_topic_id}
                            sx={{ paddingTop: 2, paddingBottom: 2 }}
                        >
                            {transformTopicKeyToReadable(topic.topic ?? "")}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    )
})
