import { Chip, useTheme } from "@mui/material"
import { observer } from "mobx-react"
import { useMemo } from "react"

import { exhaustiveCheck } from "src/lib/exhaustive-check"
import { Status } from "src/types/status"
import { StatusCasesV2 } from "src/types/status-casesV2"

interface IProps {
    label: string
    state: "positive" | "neutral" | "negative" | "info" | "other"
    icon?: React.ReactElement
}

export type IStatusChipProps = IProps

export interface IStatusValueOptions {
    value: Status | StatusCasesV2
    label: string
}

export const StatusChip = observer((props: IProps) => {
    const theme = useTheme()

    const color = useMemo(() => {
        switch (props.state) {
            case "negative":
                return theme.palette.utility.rustRedFaded.main
            case "positive":
                return theme.palette.utility.appleFaded.main
            case "neutral":
                return theme.palette.utility.orangeFaded.main
            case "info":
                return theme.palette.utility.blueFaded.main
            case "other":
                return theme.palette.grey[300]
            default:
                exhaustiveCheck(props.state)
                return theme.palette.grey[100]
        }
    }, [theme, props.state])

    return props.icon !== undefined ? (
        <Chip
            variant="outlined"
            label={props.label}
            sx={{
                "& .MuiChip-icon": {
                    fontSize: "15px",
                    color:
                        props.label === "Negative"
                            ? theme.palette.generalColors.red
                            : theme.palette.generalColors.green,
                },
            }}
            icon={props.icon}
        />
    ) : (
        <Chip label={props.label} sx={{ backgroundColor: color }} />
    )
})
