import { makeAutoObservable } from "mobx"
import React from "react"

import { t } from "@lingui/macro"

import { FormFields } from "src/lib/form-fields"
import { createLoadingKeys } from "src/lib/loading"

import {
    EmbedAdminService,
    avy_api_internal_utils_db_JSONB,
    avy_api_pkg_embed_BulletPoint,
    avy_api_pkg_embed_CollapsibleBox,
    avy_api_pkg_embed_EmbedCategory,
    avy_api_pkg_embed_EmbedCategoryField,
    avy_api_pkg_embed_IntegrationLink,
    avy_api_pkg_embed_Link,
    avy_api_pkg_embed_PurchaseData,
    avy_api_pkg_embed_SalesCard,
    avy_api_pkg_embed_SalesData,
} from "src/api"
import { ITabId } from "src/modals/embedded-integration-v2/types/tabId"
import { IEmbeddedIntegrationContentSectionFormFields } from "src/modals/embedded-integration-v2/types/embeddedIntegrationContentSectionFormFields"
import { initialGeneralDynamicFieldsState } from "src/modals/embedded-integration-v2/constants/initialFieldState"
import { initialSalesDynamicFieldState } from "src/modals/embedded-integration-v2/constants/initialSalesDynamicFieldState"
import { initialPurchaseTabFieldsState } from "src/modals/embedded-integration-v2/constants/initialPurchaseTabFieldsState"
import { initialState } from "src/modals/embedded-integration-v2/constants/initialState"
import { IGeneralDynamicFieldsItemProps } from "src/modals/embedded-integration-v2/types/generalDynamicFieldsItem"
import { GeneralCategoriesFieldsTypes } from "src/modals/embedded-integration-v2/types/contentDynamicFields"
import { IDynamicFieldItemProps } from "src/modals/embedded-integration-v2/types/dynamicFieldItem"
import { ISalesDynamicFieldsItemProps } from "src/modals/embedded-integration-v2/types/salesDynamicFieldsItem"
import { avy_api_pkg_embed_StaticPurchaseDataMap } from "src/api/models/avy_api_pkg_embed_StaticPurchaseDataMap"
import { IPurchaseRichLinkDynamicFieldsItemProps } from "src/modals/embedded-integration-v2/types/purchaseRichLinkFieldsItem"
import { IPurchaseSimpleLinkDynamicFieldsItemProps } from "src/modals/embedded-integration-v2/types/purchaseSimplelinkFieldsItem"
import { IPurchaseCollapsibleLinkDynamicFieldsItemProps } from "src/modals/embedded-integration-v2/types/purchaseCollapsibleLinkFieldsItem"
import { IPurchaseTabFieldsProps } from "src/modals/embedded-integration-v2/types/defaultPurchaseTabFields"

export class ContentSectionStore {
    static Context = React.createContext<ContentSectionStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "submit")

    private _selectedTabId: string = ITabId.GENERAL
    _toggleChipOptions: string[] = []

    private salesPageTitle = ""

    //#region getters
    get selectedTabId() {
        return this._selectedTabId
    }

    get generalTabFields() {
        return this.fields.get("generalDynamicFieldsList")
    }
    get salesTabFields() {
        return this.fields.get("salesDynamicFieldsList")
    }

    async getCategories() {
        const list = await EmbedAdminService.getV1AdminEmbedCategory()
        this.fields.set("categoryList", list)
    }

    get firstToggleChipOptions() {
        return this._toggleChipOptions.length > 0
            ? this._toggleChipOptions[0]
            : "default"
    }
    //#endregion

    //#region setters

    setSelectedTabId(id: string) {
        this._selectedTabId = id
    }

    //#endregion

    fields = new FormFields<IEmbeddedIntegrationContentSectionFormFields>({
        categoryList: [],
        salesPurchaseType: "default",
        salesPageTitle: "",
        salesPageHeadline: "",
        salesPageDescription: "",
        bulletOptions: [{ ...initialState }],
        fileUploadUrl: "",
        ctaPrivacyPolicy: "",
        ctaButtonText: "",
        generalDynamicFieldsList: [{ ...initialGeneralDynamicFieldsState }],
        salesDynamicFieldsList: [{ ...initialSalesDynamicFieldState }],
    })

    constructor() {
        makeAutoObservable(this)
    }

    getLabelText = (fieldType: string, categoryType: string) => {
        return this.fields.data.categoryList
            .find((item) => item.name === categoryType)
            ?.fields?.fields?.find((fields) => fields.name === fieldType)?.label
    }

    getRequiredFields = (fieldType: string, categoryType: string) => {
        return this.fields.data.categoryList
            .find((item) => item.name === categoryType)
            ?.fields?.fields?.find((fields) => fields.name === fieldType)
            ?.required
    }

    getGeneralDynamicFields = (
        generalCategoryFields: avy_api_internal_utils_db_JSONB,
    ) => {
        if (
            generalCategoryFields !== undefined &&
            Object.keys(generalCategoryFields).length !== 0
        ) {
            const list: IGeneralDynamicFieldsItemProps[] = Object.keys(
                generalCategoryFields ?? {},
            ).map((key) => {
                const categoryListFields: Array<avy_api_pkg_embed_EmbedCategoryField> =
                    this.fields?.data?.categoryList?.find(
                        (item: avy_api_pkg_embed_EmbedCategory) =>
                            item.name === key,
                    )?.fields?.fields ?? []

                const categoryListKeys: string[] = categoryListFields
                    .filter(
                        (
                            item,
                        ): item is avy_api_pkg_embed_EmbedCategory & {
                            name: string
                        } => item.name !== undefined,
                    )
                    .map((item) => item.name)

                return {
                    categoryDropdownValue: {
                        value: key ?? "",
                        errorMessage: "",
                    },
                    categoryFields: categoryListKeys.map((categoryKey) => ({
                        name: categoryKey,
                        type:
                            categoryKey === "icon_url"
                                ? GeneralCategoriesFieldsTypes.File
                                : GeneralCategoriesFieldsTypes.Text,

                        label: this.getLabelText(categoryKey, key),
                        required: this.getRequiredFields(categoryKey, key),
                        description:
                            generalCategoryFields[
                                key as keyof avy_api_internal_utils_db_JSONB
                            ][categoryKey],
                    })),
                }
            })
            this.fields.set("generalDynamicFieldsList", list)
        }
        return this.fields.data.generalDynamicFieldsList
    }
    getBulletOptions = (bulletOptions: avy_api_pkg_embed_BulletPoint[]) => {
        if (bulletOptions !== undefined && bulletOptions.length !== 0) {
            return bulletOptions.map((item) => ({
                value: item.text,
                errorMessage: "",
            }))
        }
        return this.fields.data.bulletOptions
    }

    getSalesExtraCardDynamicFields = (
        salesExtraCardFields: avy_api_pkg_embed_SalesCard[],
    ) => {
        if (
            salesExtraCardFields !== undefined &&
            salesExtraCardFields.length !== 0
        ) {
            const list: ISalesDynamicFieldsItemProps[] =
                salesExtraCardFields.map((item) => ({
                    salesCardHeadline: {
                        value: item.headline ?? "",
                        errorMessage: "",
                    },
                    salesCardSubtitle: {
                        value: item.subtitle ?? "",
                        errorMessage: "",
                    },
                    salesCardFileUpload: {
                        value: item.icon_url ?? "",
                        errorMessage: "",
                    },
                    salesCardDescription: {
                        value: item.description ?? "",
                        errorMessage: "",
                    },
                    salesCardBulletOptions: this.getBulletOptions(
                        item.bullet_points ?? [],
                    ) as IDynamicFieldItemProps[],
                }))
            this.fields.set("salesDynamicFieldsList", list)
        }
        if (salesExtraCardFields?.length === 0 && this.salesPageTitle !== "") {
            this.fields.set("salesDynamicFieldsList", [])
        }
        return this.fields.data.salesDynamicFieldsList
    }

    getPurchaseRichLinkDynamicFields = (
        purchaseRichLinkDynamicFields: avy_api_pkg_embed_IntegrationLink[],
    ) => {
        if (
            purchaseRichLinkDynamicFields !== undefined &&
            purchaseRichLinkDynamicFields.length !== 0
        ) {
            const list: IPurchaseRichLinkDynamicFieldsItemProps[] =
                purchaseRichLinkDynamicFields.map((item) => ({
                    linkTitle: {
                        value: item.label ?? "",
                        errorMessage: "",
                    },
                    linkSubTitle: {
                        value: item.description ?? "",
                        errorMessage: "",
                    },
                    linkUrl: {
                        value: item.url ?? "",
                        errorMessage: "",
                    },
                    linkSvgFile: {
                        value: item.icon_url ?? "",
                        errorMessage: "",
                    },
                }))
            return list
        }
    }

    getPurchaseSimpleLinkDynamicFields = (
        purchaseSimpleLinkDynamicFields: avy_api_pkg_embed_Link[],
    ) => {
        if (
            purchaseSimpleLinkDynamicFields !== undefined &&
            purchaseSimpleLinkDynamicFields.length !== 0
        ) {
            const list: IPurchaseSimpleLinkDynamicFieldsItemProps[] =
                purchaseSimpleLinkDynamicFields.map((item) => ({
                    linkTitle: {
                        value: item.label ?? "",
                        errorMessage: "",
                    },
                    linkUrl: {
                        value: item.url ?? "",
                        errorMessage: "",
                    },
                }))
            return list
        }
    }

    getPurchaseCollapsibleLinkDynamicFields = (
        purchaseCollapsibleLinkDynamicFields: avy_api_pkg_embed_CollapsibleBox[],
    ) => {
        if (
            purchaseCollapsibleLinkDynamicFields !== undefined &&
            purchaseCollapsibleLinkDynamicFields.length !== 0
        ) {
            const list: IPurchaseCollapsibleLinkDynamicFieldsItemProps[] =
                purchaseCollapsibleLinkDynamicFields.map((item) => ({
                    sectionTitle: {
                        value: item.title ?? "",
                        errorMessage: "",
                    },
                    sectionContent: {
                        value: item.content ?? "",
                        errorMessage: "",
                    },
                }))
            return list
        }
    }

    getPurchaseTabFields = (
        purchaseTabFields: avy_api_pkg_embed_PurchaseData,
    ) => {
        if (
            purchaseTabFields !== undefined &&
            Object.keys(purchaseTabFields).length !== 0
        ) {
            const list = {
                statusBadge:
                    purchaseTabFields.hasOwnProperty("status") &&
                    purchaseTabFields.status?.text !== ""
                        ? { value: true, errorMessage: "" }
                        : { value: false, errorMessage: "" },
                statusBadgeText: {
                    value: purchaseTabFields.status?.text,
                    errorMessage: "",
                },
                statusBadgeBackgroundColor: {
                    value: purchaseTabFields.status?.color,
                    errorMessage: "",
                },
                priceBox:
                    purchaseTabFields.hasOwnProperty("price") &&
                    purchaseTabFields.price?.currency !== ""
                        ? { value: true, errorMessage: "" }
                        : { value: false, errorMessage: "" },
                priceBoxName: {
                    value: purchaseTabFields.purchase_name,
                    errorMessage: "",
                },
                priceBoxAmount: {
                    value: purchaseTabFields.price?.amount_cents,
                    errorMessage: "",
                },
                priceBoxCurrency: {
                    value: purchaseTabFields.price?.currency,
                    errorMessage: "",
                },
                priceBoxPeriod: {
                    value: purchaseTabFields.price?.period,
                    errorMessage: "",
                },
                infoBox:
                    purchaseTabFields.hasOwnProperty("info_box") &&
                    purchaseTabFields.info_box?.title !== ""
                        ? { value: true, errorMessage: "" }
                        : { value: false, errorMessage: "" },
                infoBoxTitle: {
                    value: purchaseTabFields.info_box?.title,
                    errorMessage: "",
                },
                infoBoxTextDescription: {
                    value: purchaseTabFields.info_box?.description,
                    errorMessage: "",
                },
                infoBoxBackgroundColor: {
                    value: purchaseTabFields.info_box?.color,
                    errorMessage: "",
                },
                purchaseRichLinkDynamicFieldsList:
                    this.getPurchaseRichLinkDynamicFields(
                        purchaseTabFields.integration_links ?? [],
                    ),
                purchaseSimpleLinkDynamicFieldsList:
                    this.getPurchaseSimpleLinkDynamicFields(
                        purchaseTabFields.links ?? [],
                    ),
                purchaseCollapsibleLinkDynamicFieldsList:
                    this.getPurchaseCollapsibleLinkDynamicFields(
                        purchaseTabFields.collapsible_boxes ?? [],
                    ),
            }
            return list
        }
    }

    setSalesPageTitle = (title: string) => {
        this.salesPageTitle = title
    }
    setToggleChipOptions(
        purchaseTabFields: avy_api_pkg_embed_StaticPurchaseDataMap,
    ) {
        const purchaseTabFieldsKeys =
            purchaseTabFields !== null ? Object.keys(purchaseTabFields) : []
        if (purchaseTabFieldsKeys.length > 0) {
            this.fields.set("salesPurchaseType", purchaseTabFieldsKeys[0])
        }
        this._toggleChipOptions =
            purchaseTabFieldsKeys.length > 0
                ? purchaseTabFieldsKeys.map((key) => key)
                : ["default"]
    }

    async init(
        generalCategoryFields: avy_api_internal_utils_db_JSONB,
        saleTabFields: avy_api_pkg_embed_SalesData,
        purchaseTabFields: avy_api_pkg_embed_StaticPurchaseDataMap,
    ) {
        await this.getCategories()
        this.setToggleChipOptions(purchaseTabFields)
        this.setSalesPageTitle(saleTabFields?.primary_sales_card?.title ?? "")

        const purchaseTabsData: { [key: string]: IPurchaseTabFieldsProps } = {}

        const purchaseTabFieldsKeys =
            purchaseTabFields !== null ? Object.keys(purchaseTabFields) : []

        ;(await purchaseTabFieldsKeys.length) > 0
            ? purchaseTabFieldsKeys.forEach((key: string) => {
                  purchaseTabsData[key] = this.getPurchaseTabFields(
                      purchaseTabFields[key],
                  ) as IPurchaseTabFieldsProps
              })
            : (purchaseTabsData["default"] = {
                  ...initialPurchaseTabFieldsState,
              })

        this.initFields({
            categoryList: this.fields.data.categoryList,
            salesPurchaseType: this.fields.get("salesPurchaseType"),
            salesPageTitle: saleTabFields?.primary_sales_card?.title ?? "",
            salesPageHeadline:
                saleTabFields?.primary_sales_card?.headline ?? "",
            salesPageDescription:
                saleTabFields?.primary_sales_card?.description ?? "",
            bulletOptions: this.getBulletOptions(
                saleTabFields?.primary_sales_card?.bullet_points ?? [],
            ) as IDynamicFieldItemProps[],
            fileUploadUrl: saleTabFields.header_image_url ?? "",
            ctaPrivacyPolicy: saleTabFields.privacy_policy_text ?? "",
            ctaButtonText: saleTabFields.sales_screen_button_text ?? "",
            generalDynamicFieldsList:
                this.getGeneralDynamicFields(generalCategoryFields) ?? [],
            salesDynamicFieldsList: this.getSalesExtraCardDynamicFields(
                saleTabFields?.extra_sales_cards ?? [],
            ),
            ...purchaseTabsData,
        })
    }
    private initFields(fields: IEmbeddedIntegrationContentSectionFormFields) {
        this.fields.init(fields)
    }

    validateRequiredFields = (requiredFields: string[]) => {
        this.fields.clearErrors()

        requiredFields.forEach((field) => {
            const requiredField =
                field as keyof IEmbeddedIntegrationContentSectionFormFields
            const formField = this.fields.get(requiredField)

            if (
                formField == null ||
                formField.toString().trim() === "" ||
                formField === "<p></p>\n"
            ) {
                this.fields.setError(requiredField, t`errors.required`)
            } else {
                this.fields.clearError(requiredField)
            }
        })
    }

    resetSalesDynamicFieldsList = () => {
        this.fields.set("salesDynamicFieldsList", [])
        this.fields.set("fileUploadUrl", "")
        this.fields.set("bulletOptions", [])
        this.fields.set("salesPageDescription", "")
        this.fields.set("salesPageHeadline", "")
        this.fields.set("salesPageTitle", "")
        this.fields.set("ctaPrivacyPolicy", "")
        this.fields.set("ctaButtonText", "")
    }
}
