import { IIconProps } from "src/components/icons"

export const UploadCloud56 = (props: IIconProps) => (
    <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M51.3333 27.0272C51.3263 23.7536 50.0837 20.6224 47.8883 18.346C46.0709 16.4591 43.7065 15.2941 41.1868 15.0439C41.0329 15.0289 40.8949 14.936 40.8155 14.7939C37.6726 9.16111 31.6397 6.15451 25.5948 7.2084C19.5499 8.26229 14.7176 13.1632 13.4031 19.5731C13.3594 19.802 13.1792 19.9721 12.9618 19.9897C8.2952 20.3469 4.6783 24.5055 4.67388 29.5189C4.57739 32.0082 5.4473 34.4292 7.07684 36.2063C9.01366 38.0382 11.5022 39.0598 14.0855 39.0834C14.78 39.0722 15.4163 38.6648 15.7546 38.0147C16.0928 37.3645 16.0817 36.5704 15.7254 35.9314C15.3691 35.2924 14.7217 34.9056 14.0271 34.9168C12.4776 34.928 10.977 34.3357 9.80447 33.2501C8.92331 32.2462 8.47303 30.8938 8.56216 29.5189C8.55759 27.8949 9.23952 26.356 10.4169 25.3335C11.596 24.3079 13.1429 23.9048 14.6298 24.2355C15.1821 24.3575 15.7566 24.2165 16.2043 23.8491C16.652 23.4817 16.9287 22.9241 16.9628 22.321C17.2606 16.9151 20.9414 12.4139 25.9034 11.3878C30.8654 10.3617 35.8449 13.072 37.9984 17.971C38.316 18.7383 39.0462 19.2122 39.8279 19.1585C41.8212 19.0598 43.7633 19.8528 45.1898 21.3481C46.6367 22.8295 47.4521 24.8828 47.445 27.0272C47.4179 31.0977 44.5393 34.4955 40.761 34.9168C39.741 35.0557 38.9983 36.0218 39.0663 37.1212C39.1343 38.2206 39.9895 39.0732 41.0177 39.0667C41.1029 39.0667 41.188 39.0605 41.2724 39.048C46.9959 38.3765 51.3308 33.1971 51.3333 27.0272Z"
            fill="currentColor"
        />
        <path
            d="M28.1128 47.8741C27.6118 47.8741 27.2056 47.4637 27.2056 46.9574V26.521L21.4969 32.2901C21.1422 32.6485 20.567 32.6485 20.2122 32.2901C19.8575 31.9317 19.8575 31.3506 20.2122 30.9922L27.4705 23.6593C27.6407 23.4869 27.8718 23.39 28.1128 23.39C28.3539 23.39 28.585 23.4869 28.7552 23.6593L36.0134 30.9922C36.3682 31.3506 36.3682 31.9317 36.0134 32.2901C35.6587 32.6485 35.0835 32.6485 34.7287 32.2901L29.0201 26.521V46.9574C29.0201 47.4637 28.6139 47.8741 28.1128 47.8741Z"
            fill="currentColor"
            stroke="currentColor"
        />
    </svg>
)
