import { observer } from "mobx-react"
import { useCallback } from "react"

import { t } from "@lingui/macro"

import { FormHelperText, Stack } from "@mui/material"

import { FormFieldSet } from "src/components/FormFieldSet"
import { ImageAttachments } from "src/components/AttachmentsField/ImageAttachments"

import { CampaignDetailStore } from "src/modals/campaign-detail/store"
import { useStore } from "src/store/lib/useStore"

export const CampaignImageSection = observer(() => {
    const store = useStore(CampaignDetailStore)

    const handleChange = useCallback(
        (images: IFile[]) => {
            if (images.length > 0) {
                const file = images[0]
                const MAX_FILE_SIZE = 1024 * 8
                if ("file" in file) {
                    const fileSizeKiloBytes = file.file.size / 1024
                    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
                        store.fields.setErrors({
                            campaignBanner: t`campaign-detail-modal.add-content-section.image-error-size`,
                        })
                        return
                    }

                    const img = new Image()
                    img.src = file.url
                    img.onload = () => {
                        if (img.height !== 86 && img.width !== 300) {
                            store.fields.set("campaignBanner", [])
                            store.fields.setErrors({
                                campaignBanner: t`campaign-detail-modal.add-content-section.image-error-dimension`,
                            })
                            return
                        } else {
                            store.fields.set("campaignBanner", images)
                        }
                    }
                }
            } else {
                store.fields.set("campaignBanner", images)
            }
        },
        [store.fields],
    )

    return (
        <>
            <Stack spacing={4}>
                <FormFieldSet
                    header={t`campaign-detail-modal.add-content-section.image-desc`}
                >
                    <ImageAttachments
                        value={store.fields.get("campaignBanner")}
                        onChange={handleChange}
                        max={1}
                    />
                </FormFieldSet>
            </Stack>
            {Boolean(store.fields.error("campaignBanner")) && (
                <FormHelperText error margin="dense">
                    {store.fields.error("campaignBanner")}
                </FormHelperText>
            )}
        </>
    )
})
