/* eslint-disable import/no-relative-parent-imports */
import { useState } from "react"

import {
    IDynamicFieldsListItemProps,
    IExternalDynamicFieldsListItemProps,
    IMetaDataFieldListItemProps,
} from "../types/fieldsListItem"
import {
    initialExternalFieldsState,
    initialInternalFieldsState,
    initialMetaDataFieldsState,
} from "../constants/initialFieldState"

const useFieldList = () => {
    const [dynamicFieldsList, setDynamicFieldsList] = useState<
        IDynamicFieldsListItemProps[]
    >([initialInternalFieldsState])
    const [externalDynamicFieldsList, setExternalDynamicFieldsList] = useState<
        IExternalDynamicFieldsListItemProps[]
    >([initialExternalFieldsState])
    const [metaDataFields, setMetaDataFields] = useState<
        IMetaDataFieldListItemProps[]
    >([initialMetaDataFieldsState])

    return {
        dynamicFieldsList,
        externalDynamicFieldsList,
        setDynamicFieldsList,
        setExternalDynamicFieldsList,
        metaDataFields,
        setMetaDataFields,
    }
}

export default useFieldList
