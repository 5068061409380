import { Grid, styled } from "@mui/material"

const Wrap = styled("section")(({ theme }) => ({
    background: theme.palette.grey[100],
    padding: "16px",
    borderRadius: "8px",
}))

const Header = styled("h2")(() => ({
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: 1.6,
    margin: 0,
}))

const SubHeader = styled("p")(() => ({
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    margin: 0,
}))

const StyledGrid = styled(Grid)(() => ({
    flexDirection: "row",
    justifyContent: "space-between",
}))

export { Wrap, Header, SubHeader, StyledGrid }
