import { observer } from "mobx-react"
import React, { useCallback, useEffect } from "react"
import { Alert, Button } from "@mui/material"
import { t } from "@lingui/macro"
import { action } from "mobx"

import { ManageUsersCreateOrEditModalStore } from "./store"
import { NameAndEmailSection } from "./NameAndEmailSection"
import { EditSection, IEditSectionProps } from "./EditSection"

import { ISessionPropertyOwner } from "src/store/session"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { Form } from "src/components/Form"
import { ModalHeader } from "src/components/ModalHeader"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { FormPanel } from "src/components/FormPanel"
import { useCloseConfirmationForForm } from "src/store/modals/use-close-confirmation-for-form"
import { MixpanelProperties } from "src/analytics/constants/properties"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"

interface IProps {
    adminId?: number
    duplicate: boolean
    propertyOwners?: ISessionPropertyOwner[]
}

const ManageUsersCreateOrEdit = observer(
    ({ adminId, duplicate, propertyOwners }: IProps) => {
        const store = useStore(ManageUsersCreateOrEditModalStore)
        const globalStore = useStore(GlobalStore)
        const loggedInPropertyOwnerId = globalStore.session.propertyOwnerId

        useCloseConfirmationForForm(store.fields)

        useEffect(() => {
            if (adminId != null) {
                trackModuleEvent("Users | User Detail View", {
                    [MixpanelProperties.AccessGroupName]:
                        globalStore.session.accessGroup?.name,
                    [MixpanelProperties.AccessGroupID]:
                        globalStore.session.accessGroup?.id,
                    [MixpanelProperties.ItemID]: adminId,
                })
            }
            ;(async () => {
                await store.init(duplicate, adminId, loggedInPropertyOwnerId)
            })()
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [duplicate, adminId, store, loggedInPropertyOwnerId])

        const validateRequiredFields = useCallback(
            (requiredFields: string[]) => {
                for (const field of requiredFields) {
                    const requiredField = field as keyof {
                        name: string
                        email: string
                    }
                    const formField = store.fields.get(requiredField)

                    if (formField == null || formField === "") {
                        store.fields.setError(requiredField, t`errors.required`)
                    } else {
                        store.fields.clearError(requiredField)
                    }
                }
            },
            [store.fields],
        )

        const handleSubmit = useCallback(async () => {
            const requiredFields: string[] = ["name", "email"]
            if (propertyOwners != null && propertyOwners?.length > 1) {
                requiredFields.push("createUserPropertyOwnerId")
            }
            validateRequiredFields(requiredFields)
            if (!store.fields.hasErrors()) {
                try {
                    if (adminId != null) {
                        trackModuleEvent("Users | Update User", {
                            [MixpanelProperties.AccessGroupName]:
                                globalStore.session.accessGroup?.name,
                            [MixpanelProperties.AccessGroupID]:
                                globalStore.session.accessGroup?.id,
                            [MixpanelProperties.ItemID]: adminId,
                        })
                    } else {
                        trackModuleEvent("Users | Create New User", {
                            [MixpanelProperties.AccessGroupName]:
                                globalStore.session.accessGroup?.name,
                            [MixpanelProperties.AccessGroupID]:
                                globalStore.session.accessGroup?.id,
                        })
                    }
                    await store.submit(adminId, duplicate)
                } catch {
                    if (duplicate) {
                        alert(
                            t`manage-users-create-edit-copy-modal.submit-duplicate-error`,
                        )
                    } else {
                        alert(
                            t`manage-users-create-edit-copy-modal.submit-error`,
                        )
                    }
                } finally {
                    globalStore.modals.pop()
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [
            propertyOwners,
            validateRequiredFields,
            store,
            adminId,
            duplicate,
            globalStore.modals,
        ])

        const updateArray = useCallback(
            (id: number, array: number[], AllSelect: boolean): number[] => {
                const idExists: boolean = array.includes(id)
                if (idExists) {
                    if (AllSelect) return array
                    const indexOfId = array.indexOf(id)
                    array.splice(indexOfId, 1)
                } else {
                    array.push(id)
                }
                return array
            },
            [],
        )

        const handleLegalEntityCheckboxChange = action(
            useCallback(
                (legalEntityId: number, AllSelect: boolean) => {
                    const selectedLegalEntityIds = updateArray(
                        legalEntityId,
                        store.fields.get("legalEntityIds"),
                        AllSelect,
                    )
                    store.fields.set("legalEntityIds", selectedLegalEntityIds)
                },
                [store.fields, updateArray],
            ),
        )

        const handlePermissionCheckboxChange = action(
            useCallback(
                (permissionId: number, AllSelect: boolean) => {
                    const selectedPermissionIds = updateArray(
                        permissionId,
                        store.fields.get("permissionIds"),
                        AllSelect,
                    )
                    store.fields.set("permissionIds", selectedPermissionIds)
                },
                [store.fields, updateArray],
            ),
        )

        const handleAccessGroupCheckboxChange = action(
            useCallback(
                (accessGroupId: number, AllSelect: boolean) => {
                    const selectedAccessGroupIds = updateArray(
                        accessGroupId,
                        store.fields.get("accessGroupIds"),
                        AllSelect,
                    )
                    store.fields.set("accessGroupIds", selectedAccessGroupIds)
                },
                [store.fields, updateArray],
            ),
        )

        const capitalize = (string: string) => {
            return string.charAt(0).toUpperCase() + string.slice(1)
        }

        const editSectionsData: IEditSectionProps[] = [
            {
                title: t`manage-users-create-edit-copy-modal.legal-entities-title`,
                subTitle: `${
                    store.fields.get("legalEntityIds").length
                } ${t`manage-users-create-edit-copy-modal.selected`}`,
                buttonText: t`manage-users-create-edit-copy-modal.edit-legal-entities`,
                allItems: store.legalEntities,
                selectedItems: store.fields.get("legalEntityIds"),
                handleCheckboxChange: handleLegalEntityCheckboxChange,
                buttonDisabled: duplicate,
            },
            {
                title: t`manage-users-create-edit-copy-modal.permissions-title`,
                subTitle: `${
                    store.fields.get("permissionIds").length
                } ${t`manage-users-create-edit-copy-modal.selected`}`,
                buttonText: t`manage-users-create-edit-copy-modal.edit-permissions`,
                allItems:
                    globalStore.session.user?.moduleList.map((module) => {
                        return {
                            name:
                                capitalize(
                                    module.moduleName.replace(/_/g, " "),
                                ) === "Integration connector"
                                    ? "Integration connectors"
                                    : capitalize(
                                          module.moduleName.replace(/_/g, " "),
                                      ),
                            id: module.moduleId,
                        }
                    }) ?? [],
                selectedItems: store.fields.get("permissionIds"),
                handleCheckboxChange: handlePermissionCheckboxChange,
                buttonDisabled: duplicate,
            },
            {
                title: t`manage-users-create-edit-copy-modal.access-groups-title`,
                subTitle: `${
                    store.fields.get("accessGroupIds").length
                } ${t`manage-users-create-edit-copy-modal.selected`}`,
                buttonText: t`manage-users-create-edit-copy-modal.edit-access-groups`,
                allItems: store.accessGroups,
                selectedItems: store.fields.get("accessGroupIds"),
                handleCheckboxChange: handleAccessGroupCheckboxChange,
                buttonDisabled: duplicate,
            },
        ]

        return (
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    <Button
                        data-testid="create/edit-modal-submit-button"
                        type="submit"
                        variant="contained"
                        disabled={store.fields.getIsDirty() === false}
                    >
                        {t`manage-users-create-edit-copy-modal.save-button`}
                    </Button>
                </ModalHeader>
                {Boolean(store.fields.error("generic")) && (
                    <Alert severity="error">
                        {store.fields.error("generic")}
                    </Alert>
                )}
                <FormPanel
                    sections={[
                        {
                            header: t`manage-users-create-edit-copy-modal.manage-access-and-permissions`,
                            content: (
                                <NameAndEmailSection
                                    propertyOwners={propertyOwners}
                                />
                            ),
                        },
                    ]}
                />
                {adminId != null &&
                    editSectionsData.map((editSection, index) => {
                        return (
                            <EditSection
                                key={index}
                                title={editSection.title}
                                buttonText={editSection.buttonText}
                                subTitle={editSection.subTitle}
                                allItems={editSection.allItems}
                                selectedItems={editSection.selectedItems}
                                handleCheckboxChange={
                                    editSection.handleCheckboxChange
                                }
                                buttonDisabled={editSection.buttonDisabled}
                            />
                        )
                    })}
            </Form>
        )
    },
)

export const ManageUsersCreateOrEditModal = (props: IProps) => (
    <StoreProvider Store={ManageUsersCreateOrEditModalStore}>
        <ManageUsersCreateOrEdit {...props} />
    </StoreProvider>
)
