import { observer } from "mobx-react"
import {
    Alert,
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    IconButton,
    InputAdornment,
    Link,
    TextField,
    Typography,
    useTheme,
} from "@mui/material"
import {
    Link as RouterLink,
    useSearchParams,
    useLocation,
} from "react-router-dom"
import { useCallback, useEffect } from "react"
import { t } from "@lingui/macro"
import { VisibilityOff, Visibility } from "@mui/icons-material"

import { LoginStore } from "./store"

import { track } from "src/analytics/AnalyticsManager"

import { environment } from "src/config"
import { useStore } from "src/store/lib/useStore"
import { HrWithText } from "src/components/HrWithText"
import { GlobalStore } from "src/store"
import { MicrosoftLoginIcon } from "src/components/icons/MicrosoftLoginIcon"
import { TwoFaAuthenticationModal } from "src/modals/two-fa-authentication"

export const Login = observer(() => {
    const store = useStore(LoginStore)
    const theme = useTheme()
    const [searchParams] = useSearchParams()
    const location = useLocation()
    const globalStore = useStore(GlobalStore)

    const handleFormSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            await store.emailPasswordLogin()
            track("Login Via Email Password")
        },
        [store],
    )

    const handleMicrosoftLogin = async () => {
        await store.oauthClientInit(
            store.getMappedVendorAuthConfig(environment.AUTH.MICROSOFT),
        )
        track("Login Via Microsoft")
    }

    useEffect(() => {
        // Make sure user is logged out
        if (globalStore.session.user != null) {
            globalStore.session.destroy()
        }

        ;(async () => {
            await store.init(
                globalStore.session.setUser,
                location,
                searchParams,
            )
        })()
    }, [store, globalStore.session, searchParams, location])

    useEffect(() => {
        ;(async () => {
            if (store.totpCode.length > 5) {
                store.mfaConfigured
                    ? await store.emailPasswordLogin()
                    : await store.onVerifyTotp()
            }
        })()
    }, [store, store.totpCode])

    return (
        <>
            <Typography
                variant="h1"
                sx={{
                    fontSize: "34px",
                    fontWeight: 400,
                    textAlign: "center",
                    maxWidth: "286px",
                }}
                // Used non breaking hyphen in translation string to keep the company name on one row
                dangerouslySetInnerHTML={{
                    __html: t`login-view.heading`,
                }}
            />
            <Button
                variant="contained"
                color="info"
                sx={{ marginTop: "24px", width: "100%" }}
                onClick={handleMicrosoftLogin}
            >
                <MicrosoftLoginIcon style={{ marginRight: 16 }} />

                {t`login-view.log-in-with-microsoft`}
            </Button>
            <HrWithText
                sx={{
                    backgroundColor: theme.palette.grey[100],
                    borderColor: theme.palette.grey[500],
                }}
                text="Or"
            />
            <form style={{ width: "100%" }} onSubmit={handleFormSubmit}>
                <TextField
                    type="email"
                    value={store.fields.get("email")}
                    onChange={store.fields.setter("email")}
                    label={t`login-view.email`}
                    helperText={store.fields.error("email")}
                    error={Boolean(store.fields.error("email"))}
                    sx={{
                        mt: 1,
                    }}
                />
                <TextField
                    type={store.showPassword ? "text" : "password"}
                    value={store.fields.get("password")}
                    onChange={store.fields.setter("password")}
                    label={t`login-view.password`}
                    helperText={store.fields.error("password")}
                    error={Boolean(store.fields.error("password"))}
                    sx={{
                        mt: 3,
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label={t`login-view.toggle-password-visibility`}
                                    onClick={() =>
                                        store.setShowPassword(
                                            !store.showPassword,
                                        )
                                    }
                                    edge="end"
                                >
                                    {store.showPassword ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mt: 1,
                    }}
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={store.fields.get("keepMeLoggedIn")}
                                onChange={(event) => {
                                    store.fields.set(
                                        "keepMeLoggedIn",
                                        event.target.checked,
                                    )
                                }}
                            />
                        }
                        label={t`login-view.keep-me-logged-in`}
                    />
                    <Link
                        component={RouterLink}
                        sx={{ fontSize: "1rem" }}
                        to="/forgot-password"
                    >
                        {t`login-view.forgot-password`}?
                    </Link>
                </Box>
                {Boolean(store.fields.error("generic")) && (
                    <Alert sx={{ mt: 1 }} severity="error">
                        {store.fields.error("generic")}
                    </Alert>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    sx={{ mt: 3 }}
                    type="submit"
                    disabled={
                        store.isLoading ||
                        store.fields.get("email") === "" ||
                        store.fields.get("password") === "" ||
                        store.mfaRequired
                    }
                >
                    {t`login-view.log-in`}
                </Button>
            </form>
            {store.mfaRequired && (
                <TwoFaAuthenticationModal
                    mfaRequired={store.mfaRequired}
                    mfaConfigured={store.mfaConfigured}
                    auth2faUri={store.auth2faUri}
                    setOtp={store.setTotpCode}
                    otp={store.totpCode}
                    onVerify2faOtp={
                        store.mfaConfigured
                            ? store.emailPasswordLogin
                            : store.onVerifyTotp
                    }
                    error={store.verifyTotpError}
                    isLoader={store.isLoading}
                    onCloseModal={store.handleCloseModal}
                />
            )}
        </>
    )
})
