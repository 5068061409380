import { observer } from "mobx-react"
import { TableCell as MUITableCell } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"

import { t } from "@lingui/macro"

import { AutoAssigneeStore } from "./store"

import { IAssigneeListItem } from "./type"

import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { IEditAutomaticAssigneeItem } from "src/types/automatic_assignee"
import { AutomaticAssigneeModal } from "src/modals/automatic-assignee-detail"
import { ActionDropdown } from "src/components/ActionDropdown"
import { DataGridTable } from "src/components/Table/DataGrid"
import { ListPage } from "src/components/ListPage"

const View = observer(() => {
    const vstore = useStore(AutoAssigneeStore)
    const gstore = useStore(GlobalStore)
    const [pageSize, setPageSize] = useState(10)
    const [data, setData] = useState<IAssigneeListItem[]>([])

    useEffect(() => {
        ;(async () => {
            await vstore.init()
        })()
    }, [vstore])

    useEffect(() => {
        setData(vstore.result.items)
    }, [vstore.result.items])

    useEffect(() => {
        setData(vstore.modifiedList)
    }, [vstore.modifiedList])

    useEffect(() => {
        setData(vstore.modifiedList)
    }, [vstore.modifiedList])

    const handleEditItem = useCallback(
        (item?: IEditAutomaticAssigneeItem) => {
            if (item != null) {
                gstore.modals.open(() => <AutomaticAssigneeModal item={item} />)
            }
        },
        [gstore.modals],
    )
    const rowActionsRenderer = useCallback(
        (item: IEditAutomaticAssigneeItem) => {
            const actions = [
                {
                    text: t`contact-form-automatic-assignee-view.contact-forms-menu-option`,
                    onClick: () => handleEditItem(item),
                },
            ]
            return actions
        },
        [handleEditItem],
    )

    const columns = [
        {
            field: "actions",
            type: "actions",
            width: 20,
            getActions: (params: IEditAutomaticAssigneeItem) => [
                <MUITableCell align="right" padding="none" width="40px">
                    <ActionDropdown items={rowActionsRenderer(params)} />
                </MUITableCell>,
            ],
        },
        {
            field: "propertyName",
            headerName: t`contact-form-automatic-assignee-view.column_header_property`,
            flex: 1,
        },
        {
            field: "adminName",
            headerName: t`contact-form-automatic-assignee-view.column_header_assignee`,
            flex: 1,
        },
    ]
    return (
        <ListPage
            header={{
                header: t`contact-form-automatic-assignee-view.header`,
                breadcrumbs: [
                    t`contact-form-automatic-assignee-view.contact-forms-breadcrumb`,
                    t`contact-form--automatic-assignee-view.assignee-breadcrumb`,
                ],
            }}
            loading={gstore.loading.is(AutoAssigneeStore.LoadingKeys.pageLoad)}
        >
            <DataGridTable
                paginator={vstore.result}
                data={data}
                columns={columns}
                setPageSize={setPageSize}
                pageSize={pageSize}
                isClientSidePagination={true}
            />
        </ListPage>
    )
})

export const AutoAssignView = () => (
    <StoreProvider Store={AutoAssigneeStore}>
        <View />
    </StoreProvider>
)
