import { t } from "@lingui/macro"
import { useCallback } from "react"

import { ClientManagementStore, IPropertyOwner } from "./store"

import { useStore } from "src/store/lib/useStore"
import { Pagination } from "src/lib/pagination"
import { Table } from "src/components/Table"
import { reportSuccess, reportUnhandledApiError } from "src/lib/report"
import { GlobalStore } from "src/store"
import { CreateNewPropertyOwnerModal } from "src/modals/create-new-propertyOwner-modal"

interface IProps<TItem extends { id: string | number }> {
    propertyOwners: Pagination<TItem, unknown>
}

export const PropertyOwnersTable = ({
    propertyOwners,
}: IProps<IPropertyOwner>) => {
    const store = useStore(ClientManagementStore)
    const globalStore = useStore(GlobalStore)

    const handlePropertyOwnerFastApiClick = useCallback(
        (propertyOwnerId: number, fullLoad: boolean) => async () => {
            try {
                await store.propertyOwnerFastApiRequest(
                    propertyOwnerId,
                    fullLoad,
                )
                reportSuccess("The load was successful")
            } catch (e) {
                reportUnhandledApiError(e)
            }
        },
        [store],
    )

    const openDetailModalHandler = useCallback(
        (item) => {
            if (item != null) {
                globalStore.modals.open(() => (
                    <CreateNewPropertyOwnerModal
                        propertyOwnerId={item.propertyOwnerId}
                        legalName={item.legalName}
                        integrationType={item.integrationType}
                        fastapiConnectorIntegrationId={
                            item.fastapiConnectorIntegrationId
                        }
                        mfaRequired={item.mfaRequired}
                        syncTenantContact={item.syncTenantContact ?? false}
                    />
                ))
            }
        },
        [globalStore],
    )

    return (
        <Table
            paginator={propertyOwners}
            headers={[
                {
                    key: "propertyOwnerId",
                    name: t`property-owner-view.property-owner-id-header`,
                },
                {
                    key: "legalName",
                    name: t`property-owner-view.property-owner-header`,
                },
            ]}
            rowRenderer={(item) => ({
                propertyOwnerId: item.propertyOwnerId,
                legalName: item.legalName,
            })}
            onRowClick={openDetailModalHandler}
            rowActionsRenderer={(item) =>
                item.integrationType === "fastapi"
                    ? [
                          {
                              text: t`row-action-renderer-fast-api.48h-load`,
                              onClick: handlePropertyOwnerFastApiClick(
                                  item.propertyOwnerId,
                                  false,
                              ),
                          },
                          {
                              text: t`row-action-renderer-fast-api.full-load`,
                              onClick: handlePropertyOwnerFastApiClick(
                                  item.propertyOwnerId,
                                  true,
                              ),
                          },
                      ]
                    : []
            }
        />
    )
}
