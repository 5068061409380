import { v4 as uuidv4 } from "uuid"

import { IDynamicFieldsListItemProps } from "src/modals/contact-forms-form-builder/types/fieldsListItem"

export const getDynamicFieldsListOptions = (
    item: IDynamicFieldsListItemProps,
) => {
    if (item.inputList.length > 0 && item.inputList[0].value.trim() !== "") {
        return item.inputList.map((i) => ({
            option: i.value.trim() ?? "",
            parent_option_uuid: "",
            uuid: uuidv4() ?? "",
        }))
    }
}
