import { IGenericAutoCompleteTextFieldValue } from "src/types/auto-complete-text-field"
import { IContentItemsLocationOption } from "src/views/content-items/types/content-items"

const locationItemsOptionList: IContentItemsLocationOption = {
    move_in_guide: "Move in guide",
    overview: "Overview",
    navigation: "Navigation",
    spotlight: "Spotlight",
    wizard: "Wizard",
}

export const locationItemsOptionListArray = Object.keys(
    locationItemsOptionList,
).reduce<IGenericAutoCompleteTextFieldValue[]>((acc, key) => {
    acc.push({
        id: key,
        name: locationItemsOptionList[key as keyof IContentItemsLocationOption],
    })
    return acc
}, [])
