/* eslint-disable @typescript-eslint/no-explicit-any */
import { observer } from "mobx-react"
import React, { useCallback } from "react"

import { DataGrid, GridColDef } from "@mui/x-data-grid"

import { DataGridContainer } from "./styled"

import { Pagination } from "src/lib/pagination"

import { filterPanelSlotProp } from "src/components/Table/constants"

interface IProps<TItem extends { id: string | number }> {
    paginator: Pagination<TItem, unknown>
    data: any
    minHeight?: boolean
    columns: GridColDef[]
    setPageSize: (page: number) => void
    pageSize: number
    isClientSidePagination?: boolean
    onRowClickEvent?: (params: any) => void
}

export const DataGridTable = observer(
    <TItem extends { id: string | number }>(props: IProps<TItem>) => {
        const handlePageChange = useCallback(
            (newPage: number) => {
                props.paginator.setPage(newPage)
            },
            [props],
        )

        const handleOnPageSizeChange = useCallback(
            (newPageSize: number) => {
                props.setPageSize(newPageSize)
            },
            [props],
        )
        const onClick = useCallback(
            (params: any) => {
                props.onRowClickEvent != null && props.onRowClickEvent(params)
            },
            [props],
        )

        return (
            <DataGridContainer>
                <DataGrid
                    sx={{ overflowX: "scroll" }}
                    rows={props.data}
                    columns={props.columns}
                    getRowId={(row) => row.id}
                    autoHeight={true}
                    getRowHeight={() =>
                        props.minHeight === true ? 52 : "auto"
                    }
                    rowCount={props.paginator?.meta?.count ?? -1}
                    page={props.paginator.meta.page}
                    onPageChange={handlePageChange}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    paginationMode={
                        props.isClientSidePagination === true
                            ? "client"
                            : "server"
                    }
                    pageSize={props.pageSize}
                    onPageSizeChange={(newPage: number) =>
                        handleOnPageSizeChange(newPage)
                    }
                    onRowClick={onClick}
                    pagination
                    componentsProps={{
                        filterPanel: filterPanelSlotProp,
                    }}
                />
            </DataGridContainer>
        )
    },
)
