import { IIconProps } from "src/components/icons"

export const ViewEye = (props: IIconProps) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M7.99999 3C4.66666 3 1.81999 5.07333 0.666656 8C1.81999 10.9267 4.66666 13 7.99999 13C11.3333 13 14.18 10.9267 15.3333 8C14.18 5.07333 11.3333 3 7.99999 3ZM7.99999 11.3333C6.15999 11.3333 4.66666 9.84 4.66666 8C4.66666 6.16 6.15999 4.66667 7.99999 4.66667C9.83999 4.66667 11.3333 6.16 11.3333 8C11.3333 9.84 9.83999 11.3333 7.99999 11.3333ZM7.99999 6C6.89332 6 5.99999 6.89333 5.99999 8C5.99999 9.10667 6.89332 10 7.99999 10C9.10666 10 9.99999 9.10667 9.99999 8C9.99999 6.89333 9.10666 6 7.99999 6Z"
            fill="black"
            fillOpacity="0.54"
        />
    </svg>
)
