import { DEFAULT_ACCESS_GROUP } from "src/config"

interface IVisibilityRequestData {
    _accessGroupId?: number
    _segments?: Array<number>
}

interface ICustomVisibilityRequestData {
    access_group_id?: number
    segment_ids?: number[]
}

export class CustomDataHelper {
    /**
     * Prepare visibility data
     */
    public static PrepareVisibilityData(
        data: IVisibilityRequestData,
    ): ICustomVisibilityRequestData {
        var visibilityRequestData: ICustomVisibilityRequestData = {}

        if ((data._segments ?? []).length > 0) {
            visibilityRequestData.segment_ids = data._segments
        } else if (this.getAccessGroupId(data) !== undefined) {
            visibilityRequestData.access_group_id = data._accessGroupId
        }

        return visibilityRequestData
    }

    private static getAccessGroupId(
        data: IVisibilityRequestData,
    ): number | undefined {
        return data._accessGroupId !== DEFAULT_ACCESS_GROUP.id
            ? data._accessGroupId
            : undefined
    }
}
