import { styled } from "@mui/material"

const InputElement = styled("input")(({ theme }) => ({
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    borderRadius: "4px",
    textAlign: "center",
    gap: "8px",
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[500]}`,
    boxShadow: `0px 1px 1px ${theme.palette.grey[300]}`,
    [theme.breakpoints.up("md")]: {
        width: "54px",
        height: "54px",
    },
    [theme.breakpoints.down("md")]: {
        width: "54px",
        height: "54px",
    },
    [theme.breakpoints.down("sm")]: {
        width: "36px",
        height: "36px",
    },
}))

const boxContainerStyle = {
    display: "flex",
    gap: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
}
export { InputElement, boxContainerStyle }
