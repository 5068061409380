import { Stack, alpha, styled } from "@mui/material"

export const DocumentStyledStack = styled(Stack)(({ theme }) => ({
    height: "56px",
    padding: "0 0.75rem",
    background: alpha(theme.palette.utility.appleFaded.main, 0.5),
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.grey[500],
    borderRadius: theme.shape.borderRadius,
}))
