/* eslint-disable react-hooks/exhaustive-deps */
import { t } from "@lingui/macro"
import { Alert, Button } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useEffect } from "react"

import { PublishingSettingsSection } from "./PublishingSettingsSection"

import { AccessDetailStore } from "./store"

import { ResourceSection } from "./ResourceSection"

import { Form } from "src/components/Form"
import { FormPanel } from "src/components/FormPanel"
import { ModalHeader } from "src/components/ModalHeader"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { IEditAccessDetailItem } from "src/types/access"
import { GlobalStore } from "src/store"
import { AccessGroup } from "src/components/ModalAccessGroup/AccessGroup"

interface IAccessDetailItemProps {
    accessItem?: IEditAccessDetailItem
}

const AccessDetail = observer((props: IAccessDetailItemProps) => {
    const globalStore = useStore(GlobalStore)
    const store = useStore(AccessDetailStore)

    useEffect(() => {
        ;(async () => {
            await store.init(props.accessItem)
            if (store.formFields.get("type") !== null) {
                globalStore.session.setSelectedParakeyType(
                    store.formFields.get("type"),
                )
            }
        })()
    }, [store, props.accessItem])

    useEffect(() => {
        store.formFields.set(
            "accessGroupId",
            globalStore.session.accessGroup.id as number,
        )
    }, [globalStore.session.accessGroup.id])

    const handleSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            globalStore.session.setSelectedParakeyType("")
            store.setIsLoading(true)

            store.validateRequiredFields([
                "type",
                "name",
                "externalId",
                "accessGroupId",
            ])

            if (store.formFields.hasErrors() === true) {
                store.setIsLoading(false)
                return
            }

            if (props.accessItem == null) {
                await store.createItem()
            } else {
                await store.updateItem(props.accessItem)
            }

            if (!Boolean(store.formFields.error("generic"))) {
                globalStore.modals.pop()
            }
        },
        [globalStore.modals, props.accessItem, store],
    )

    return (
        <Form onSubmit={handleSubmit}>
            <ModalHeader>
                <Button type="submit" variant="contained" disabled={false}>
                    {t`global.save`}
                </Button>
            </ModalHeader>
            {Boolean(store.formFields.error("generic")) && (
                <Alert severity="error">
                    {store.formFields.error("generic")}
                </Alert>
            )}

            <FormPanel
                sections={[
                    {
                        header: t`access-detail-modal.access-settings`,
                        content: (
                            <AccessGroup
                                accessGroupId={
                                    store.formFields.get(
                                        "accessGroupId",
                                    ) as number
                                }
                                errorMessage={
                                    store.formFields.error("accessGroupId") ??
                                    ""
                                }
                            />
                        ),
                    },
                ]}
            />
            <FormPanel
                sections={[
                    {
                        header: t`access-detail-modal.resource-section.section-header`,
                        content: <ResourceSection />,
                    },
                ]}
            />
            <FormPanel
                sections={[
                    {
                        header: t`access-detail-modal.publishing-settings-section.section-header`,
                        content: <PublishingSettingsSection />,
                    },
                ]}
            />
        </Form>
    )
})

export const AccessDetailModalView = (props: IAccessDetailItemProps) => (
    <StoreProvider Store={AccessDetailStore}>
        <AccessDetail {...props} />
    </StoreProvider>
)
