import { Box, styled, Stack, Link as MuiLink } from "@mui/material"

const HeadUnderline = styled(Box)(() => ({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flex: 1,
    flexDirection: "row",
}))

const Wrap = styled(Stack)({ flex: 0, padding: "16px" })

const Author = styled("div")({
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: 1.6,
})

const TimeSince = styled("time")(({ theme }) => ({
    fontSize: "16px",
    lineHeight: 1.5,
    color: theme.palette.grey[600],
}))

const ApartmentIdContainer = styled("span")(({ theme }) => ({
    fontSize: "16px",
    lineHeight: 1.5,
    color: theme.palette.grey[600],
    marginLeft: 8,
}))

const PostWrapper = styled("div")(({ theme }) => ({
    background: theme.palette.grey[100],
    borderRadius: "4px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    marginTop: "16px",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
}))

const HeaderWrapper = styled(Stack)(
    ({ smallScreen }: { smallScreen: boolean }) => ({
        width: "100%",
        flexDirection: smallScreen ? "column" : "row",
        alignItems: smallScreen ? "flex-start" : "center",
        justifyContent: smallScreen ? "flex-start" : "space-between",
    }),
)

const AuthorWrapper = styled(Stack)(
    ({ smallScreen }: { smallScreen: boolean }) => ({
        width: smallScreen ? "100%" : 311,
        marginTop: smallScreen ? 17 : 0,
    }),
)

const Text = styled("p")(({ theme }) => ({
    fontSize: "16px",
    lineHeight: 1.5,
    color: theme.palette.text.primary,
    margin: "0 0 16px",
}))

const PostText = styled("p")(({ theme }) => ({
    fontSize: "16px",
    lineHeight: 1.5,
    color: theme.palette.text.primary,
    margin: "0 0 16px",
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word",
}))

const ImageGrid = styled("div")<{ images: number }>(({ theme, images }) => {
    let gridTemplateAreas = '"img-0 img-1" "img-0 img-2"'
    if (images === 2) {
        gridTemplateAreas = '"img-0 img-1" "img-0 img-1"'
    } else if (images === 1) {
        gridTemplateAreas = '"img-0 ." "img-0 ."'
    }

    return {
        display: "grid",
        gridTemplateAreas,
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "1fr 1fr",
        height: "560px",
        width: "100%",
        gridGap: "8px",
        [theme.breakpoints.down("xl")]: {
            height: "480px",
        },
        [theme.breakpoints.down("lg")]: {
            height: "360px",
        },
        [theme.breakpoints.down("sm")]: {
            height: "240px",
        },
    }
})

const Link = styled("a")({
    display: "block",
    height: "100%",
    width: "100%",
    overflow: "hidden",
})

const Image = styled("img")({
    objectFit: "cover",
    width: "100%",
    height: "100%",
    borderRadius: "4px",
    overflow: "hidden",
    display: "block",
})

const LinkTypography = styled(MuiLink)(({ theme }) => ({
    fontSize: 16,
    lineHeight: "30px",
    letterSpacing: 0.11,
    color: theme.palette.common.black,
}))

const CommentText = styled("div")(() => ({
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word",
}))

const InternalObjectIDContainer = styled("span")(({ theme }) => ({
    fontSize: "16px",
    lineHeight: 1.5,
    color: theme.palette.grey[600],
    marginLeft: 8,
}))

export {
    HeadUnderline,
    Wrap,
    Author,
    TimeSince,
    ApartmentIdContainer,
    PostWrapper,
    HeaderWrapper,
    AuthorWrapper,
    Text,
    ImageGrid,
    Link,
    Image,
    LinkTypography,
    CommentText,
    PostText,
    InternalObjectIDContainer,
}
