import { t } from "@lingui/macro"
import { Alert, Button } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useEffect } from "react"

import { DigitalAccessIntegrationSection } from "./DigitalAccessIntegrationSection"

import { ResourceSettingsSection } from "./ResourceSettings"

import { CenteredSpinner } from "src/components/CenteredSpinner"
import { Form } from "src/components/Form"
import { FormPanel } from "src/components/FormPanel"
import { ModalHeader } from "src/components/ModalHeader"
import { useInitializer } from "src/lib/initializer"
import { AttachmentsSection } from "src/modals/resource-detail/AttachmentsSection"
import { BookingRulesSection } from "src/modals/resource-detail/BookingRulesSection"
import { DescriptionSection } from "src/modals/resource-detail/DescriptionSection"
import { PublishingSettingsSection } from "src/modals/resource-detail/PublishingSettingsSection"
import { ResourceDetailStore } from "src/modals/resource-detail/store"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { ResourceType } from "src/types/resource"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"

interface IProps {
    id?: number
}

const ResourceDetail = observer((props: IProps) => {
    const gstore = useStore(GlobalStore)
    const vstore = useStore(ResourceDetailStore)

    const initialized = useInitializer(
        () => vstore.init(gstore.session.accessGroupId, props.id),
        [vstore, props.id, gstore.session.accessGroupId],
    )
    useEffect(() => {
        if (props.id !== null) {
            trackModuleEvent("Bookings | Detailed View", {
                [MixpanelProperties.AccessGroupName]:
                    gstore.session.accessGroup?.name,
                [MixpanelProperties.AccessGroupID]:
                    gstore.session.accessGroup?.id,
                [MixpanelProperties.ItemID]: props.id,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id])
    const handleSubmit = useCallback(async () => {
        await vstore.submit(gstore.session.accessGroup?.name)
        if (!vstore.fields.hasErrors()) {
            gstore.modals.pop()
        }
    }, [vstore, gstore])

    if (
        !initialized ||
        gstore.loading.is(ResourceDetailStore.LoadingKeys.init)
    ) {
        return <CenteredSpinner height="100vh" />
    }
    const isButtonDisabled =
        gstore.loading.is(ResourceDetailStore.LoadingKeys.submit) ||
        vstore.fields.get("accessType") === "READ" ||
        vstore.fields.getIsDirty() === false

    return (
        <Form onSubmit={handleSubmit}>
            <ModalHeader>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={isButtonDisabled}
                >
                    {t`global.save`}
                </Button>
            </ModalHeader>
            {Boolean(vstore.fields.error("generic")) && (
                <Alert severity="error">{vstore.fields.error("generic")}</Alert>
            )}

            <FormPanel
                sections={[
                    {
                        header: t`resource-detail-modal.resource-settings`,
                        content: <ResourceSettingsSection />,
                    },
                ]}
            />
            <FormPanel
                sections={[
                    {
                        header: t`resource-detail-modal.publishing-settings-section.section-header`,
                        content: <PublishingSettingsSection />,
                    },
                ]}
            />
            <FormPanel
                sections={[
                    {
                        header: t`resource-detail-modal.description-section.section-header`,
                        content: <DescriptionSection />,
                    },
                    {
                        header: t`resource-detail-modal.attachments-section.section-header`,
                        content: <AttachmentsSection />,
                    },
                ]}
            />
            {vstore.fields.get("resourceTypeSelectedValue") ===
                ResourceType.BookingEngine && (
                <>
                    <FormPanel
                        sections={[
                            {
                                header: t`resource-detail-modal.booking-rules-section.section-header`, // "",
                                content: <BookingRulesSection />,
                            },
                        ]}
                    />
                    {(gstore.session.hasAccessToModule("parakey") ||
                        gstore.session.hasAccessToModule("accessy")) && (
                        <FormPanel
                            sections={[
                                {
                                    header: t`resource-detail-modal.digital-access-integration-section.section-header`,
                                    subHeader: t`resource-detail-modal.digital-access-integration-section.section-sub-header`,
                                    content: (
                                        <DigitalAccessIntegrationSection />
                                    ),
                                },
                            ]}
                        />
                    )}
                </>
            )}
        </Form>
    )
})

export const ResourceDetailModal = (props: IProps) => (
    <StoreProvider Store={ResourceDetailStore}>
        <ResourceDetail {...props} />
    </StoreProvider>
)
