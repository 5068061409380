import { TextField } from "@mui/material"
import { observer } from "mobx-react"

import { t } from "@lingui/macro"

import { AccessGroupsCreateOrEditStore } from "./store"

import { FormFieldSet } from "src/components/FormFieldSet"
import { useStore } from "src/store/lib/useStore"

export const NameSection = observer(() => {
    const store = useStore(AccessGroupsCreateOrEditStore)

    return (
        <FormFieldSet
            header={t`create-edit-access-group-modal.name-section.name-of-access-group`}
            tooltip={t`create-edit-access-group-modal.name-section.name-of-access-group`}
        >
            <TextField
                label={t`create-edit-access-group-modal.name-section.name-of-access-group`}
                placeholder={t`create-edit-access-group-modal.name-section.name-of-access-group`}
                onChange={store.fields.setter("name")}
                value={store.fields.get("name")}
                helperText={store.fields.error("name")}
                error={Boolean(store.fields.error("name"))}
                data-testid="name-input"
            />
        </FormFieldSet>
    )
})
