/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { subscription_Plan } from "../models/subscription_Plan"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class SubscriptionAdminService {
    /**
     * Get plans
     * Get all subscription plans
     * @returns subscription_Plan OK
     * @throws ApiError
     */
    public static getV1AdminSubscriptionPlan(): CancelablePromise<
        Array<subscription_Plan>
    > {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/subscription/plan",
        })
    }
}
