import { t } from "@lingui/macro"
import { TextField } from "@mui/material"
import { observer } from "mobx-react"

import { FormFieldSet } from "src/components/FormFieldSet"
import { CampaignDetailStore } from "src/modals/campaign-detail/store"
import { useStore } from "src/store/lib/useStore"

export const SettingsSection = observer(() => {
    const store = useStore(CampaignDetailStore)

    return (
        <FormFieldSet
            header={t`campaign-detail-modal.settings-section.internal-name-header`}
            tooltip={t`campaign-detail-modal.settings-section.internal-name-tooltip`}
        >
            <TextField
                label={t`campaign-detail-modal.settings-section.internal-name-label`}
                placeholder={t`campaign-detail-modal.settings-section.internal-name-placeholder`}
                onChange={store.fields.setter("internalName")}
                value={store.fields.get("internalName")}
                helperText={store.fields.error("internalName")}
                error={Boolean(store.fields.error("internalName"))}
            />
        </FormFieldSet>
    )
})
