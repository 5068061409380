import { t } from "@lingui/macro"
import { styled } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useEffect, useMemo } from "react"

import { ListPage } from "src/components/ListPage"
import { DataGridProTable } from "src/components/Table/DataGridPro"
import { Time } from "src/components/Time"
import { AccessTypeHeader } from "src/components/AccessTypeHeader"
import { TruncatedText } from "src/components/TruncatedText"
import {
    getFilterModelForRepository,
    getSortModelForRepository,
} from "src/lib/data-grid-pro"
import { formatInteger } from "src/lib/number"
import { ConfirmModal } from "src/modals/confirm"
import { ViewCommunityPostModal } from "src/modals/view-community-post"
import { GlobalStore } from "src/store"

import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { Repository } from "src/types/channel"
import {
    FilterModel,
    IColumn,
    SortModel,
    TGridRowParams,
} from "src/types/data-grid-pro"
import { CommunityCommentsStore } from "src/views/community-comments/store"
import { ICommentItem } from "src/views/community-comments/type"

const NoWrap = styled("span")({
    whiteSpace: "nowrap",
})

const repository: Repository = "community-comments"

const View = observer(() => {
    const vstore = useStore(CommunityCommentsStore)
    const gstore = useStore(GlobalStore)

    const advanceQuery = useMemo(() => {
        const filter = getFilterModelForRepository(
            repository,
            gstore.session.dataGridFilterModel,
        )
        const sort = getSortModelForRepository(
            repository,
            gstore.session.dataGridSortModel,
        )
        return { sort, filter }
    }, [gstore.session.dataGridSortModel, gstore.session.dataGridFilterModel])

    useEffect(() => {
        ;(async () => {
            await vstore.init(gstore.session.accessGroupId, advanceQuery)
        })()

        return () => vstore.dispose()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vstore, gstore.session.accessGroupId])

    const handleFilterChange = useCallback(
        async (model: FilterModel) => {
            const sort = getSortModelForRepository(
                repository,
                gstore.session.dataGridSortModel,
            )

            await vstore.query({ sort, filter: model })
        },
        [vstore, gstore.session.dataGridSortModel],
    )

    const handleSortChange = useCallback(
        async (model: SortModel) => {
            const filter = getFilterModelForRepository(
                repository,
                gstore.session.dataGridFilterModel,
            )

            await vstore.query({ filter, sort: model })
        },
        [vstore, gstore.session.dataGridFilterModel],
    )

    const createDeleteCommentConfirmHandler = useCallback(
        (id: number) => async (confirmed: boolean) => {
            if (confirmed) {
                await vstore.deleteComment(id)
            }
        },
        [vstore],
    )

    const createDeleteCommentConfirmModalHandler = useCallback(
        (id: number) => () => {
            gstore.modals.open(
                () => (
                    <ConfirmModal
                        onConfirm={createDeleteCommentConfirmHandler(id)}
                        title={t`community-comments-view.delete-comment-confirm-modal.title`}
                        content={t`community-comments-view.delete-comment-confirm-modal.content`}
                    />
                ),
                { variant: "slide-up-w600" },
            )
        },
        [gstore.modals, createDeleteCommentConfirmHandler],
    )

    const columns: IColumn<ICommentItem>[] = [
        {
            field: "text",
            headerName: t`community-comments-view.description-header`,
            renderCell: (params) => (
                <TruncatedText text={params.value ?? ""} max={20} />
            ),
            minWidth: 110,
            flex: 2,
        },
        {
            field: "author_name",
            headerName: t`community-comments-view.author-header`,
            renderCell: (params) => (
                <NoWrap>
                    <TruncatedText text={params.value ?? ""} max={20} />
                </NoWrap>
            ),
            minWidth: 110,
            flex: 2,
        },
        {
            field: "author.author_name",
            headerName: t`community-comments-view.creator-header`,
            renderCell: (params) => (
                <NoWrap>
                    <TruncatedText text={params.value ?? ""} max={20} />
                </NoWrap>
            ),
            minWidth: 110,
            flex: 2,
        },
        {
            field: "community_name",
            headerName: t`community-comments-view.community-name-header`,
            renderCell: (params) => (
                <NoWrap>
                    <TruncatedText text={params.value ?? ""} max={20} />
                </NoWrap>
            ),
            minWidth: 110,
            flex: 2,
        },
        {
            field: "internal_apartment_id",
            headerName: t`community-comments-view.internal-apartment-id-header`,
            renderCell: (params) => params.value,
        },
        {
            field: "apartment_no",
            headerName: t`community-comments-view.apartment-id-header`,
            renderCell: (params) => params.value,
        },
        {
            field: "likeCount",
            headerName: t`community-comments-view.likes-header`,
            filterable: false,
            sortable: false,
            renderCell: (params) => formatInteger(params.value),
        },
        {
            field: "created_date",
            headerName: t`community-comments-view.created-header`,
            renderCell: (params) => <Time date={params.value} />,
            minWidth: 110,
        },
        {
            field: "access_type",
            headerName: t`community-view.access-type`,
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <AccessTypeHeader accessType={params.value} />
            ),
        },
        {
            field: "updated_at",
            headerName: t`community-comments-view.last-update-header`,
            renderCell: (params) => <Time date={params.value} />,
            minWidth: 110,
        },
    ]

    const openDetailModalHandler = useCallback(
        (item: TGridRowParams) => {
            gstore.modals.open(
                () => <ViewCommunityPostModal id={item.row.post_id} />,
                {
                    hasPreview: true,
                },
            )
        },
        [gstore.modals],
    )

    return (
        <ListPage
            header={{
                header: t`community-comments-view.header`,
                breadcrumbs: [
                    t`community-comments-view.community-breadcrumb`,
                    t`community-comments-view.comments-breadcrumb`,
                ],
            }}
            loading={
                gstore.loading.is(CommunityCommentsStore.LoadingKeys.init) ||
                !vstore.comments.meta.initialized
            }
        >
            <DataGridProTable
                paginator={vstore.comments}
                data={vstore.comments.items}
                columns={columns}
                advancedOperations={{
                    pagination: "server",
                    filtering: "server",
                    sorting: "server",
                }}
                onRowClickEvent={openDetailModalHandler}
                repository={repository}
                rowActionsRenderer={(item) => [
                    {
                        text: t`community-comments-view.delete-comment-button`,
                        destructive: true,
                        onClick: createDeleteCommentConfirmModalHandler(
                            item.id,
                        ),
                    },
                ]}
                loading={gstore.loading.is(
                    CommunityCommentsStore.LoadingKeys.loading,
                )}
                onFilterChange={handleFilterChange}
                onSortChange={handleSortChange}
            />
        </ListPage>
    )
})

export const CommunityCommentsView = () => (
    <StoreProvider Store={CommunityCommentsStore}>
        <View />
    </StoreProvider>
)
