import { EditAutomaticAssigneeStore, IFormFields } from "./store"
import * as formFields from "./form-fields"
import {
    EditAutomaticAssignee,
    IEditAutomaticAssigneeProps,
} from "./EditAutomaticAssignee"

import { StoreProvider } from "src/store/lib/StoreProvider"

const AutomaticAssigneeModal = (props: IEditAutomaticAssigneeProps) => (
    <StoreProvider Store={EditAutomaticAssigneeStore}>
        <EditAutomaticAssignee {...props} />
    </StoreProvider>
)

export { AutomaticAssigneeModal, EditAutomaticAssigneeStore, formFields }

export type { IFormFields }
