/* eslint-disable import/no-relative-parent-imports */
import { observer } from "mobx-react"

import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material"

import { t } from "@lingui/macro"

import { IIntegrationConnectorItem } from "src/views/integration-connector/type"
interface IProps {
    connectors: IIntegrationConnectorItem[]
    selectedValue: string
    onSelectionChange: (value: number) => void
    errorMessage?: string
    disabled?: boolean
}

export const IntegrationsDropdown = observer((props: IProps) => {
    return (
        <FormControl style={{ breakInside: "avoid", width: "100%" }}>
            <InputLabel id="type-label">{t`resource-detail-modal.connector-label`}</InputLabel>
            <Select
                data-testid="connector-dropdown"
                labelId="connector-label"
                id="connector"
                value={props.selectedValue}
                label={t`resource-detail-modal.connector-label`}
                onChange={(event) =>
                    props.onSelectionChange(Number(event.target.value))
                }
                error={Boolean(props.errorMessage)}
                disabled={props.disabled}
            >
                {props.connectors.map((item) => (
                    <MenuItem
                        value={item.integrationId}
                        key={item.integrationId}
                    >
                        {item.name}
                    </MenuItem>
                ))}
            </Select>
            {Boolean(props.errorMessage) && (
                <FormHelperText error={true}>
                    {props.errorMessage}
                </FormHelperText>
            )}
        </FormControl>
    )
})
