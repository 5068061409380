import { t } from "@lingui/macro"
import { Alert, Button } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useState } from "react"

import { useLocation, useNavigate } from "react-router-dom"

import { IContentItemsModalViewProps } from "./types/contentItemDetailModalView"

import { CenteredSpinner } from "src/components/CenteredSpinner"
import { ModalHeader } from "src/components/ModalHeader"
import { useInitializer } from "src/lib/initializer"
import { ContentItemsModalStore } from "src/modals/content-items/store"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"

import { Form } from "src/components/Form"
import { FormPanel } from "src/components/FormPanel"
import { ConfigurableDynamicFormFieldsSection } from "src/components/ConfigurableDynamicFormFields"
import { removeLastIdSegment } from "src/helpers/removeLastIdSegment"
import { NavigationIDSection } from "src/modals/content-items/sections/IDSection"
import { PublishingSettingsSection } from "src/modals/content-items/sections/PublishingSettingsSection"
import { SettingsSection } from "src/modals/content-items/sections/SettingsSection"
import { ConfigurableDynamicFormFieldsStore } from "src/components/ConfigurableDynamicFormFields/store"

interface IExtraData {
    accessType?: string
}

const View = observer(({ id }: IContentItemsModalViewProps) => {
    const globalStore = useStore(GlobalStore)
    const contentItemsStore = useStore(ContentItemsModalStore)
    const configurableDynamicFormFieldsStore = useStore(
        ConfigurableDynamicFormFieldsStore,
    )

    const navigate = useNavigate()
    const location = useLocation()
    const locationState = location.state as IExtraData
    const parentPath = removeLastIdSegment(location.pathname)

    const [thisModal] = useState(
        globalStore.modals.active[globalStore.modals.active.length - 1],
    )

    thisModal.confirmOnOverlayClick = () => {
        if (
            !Boolean(contentItemsStore.form.getIsDirty()) &&
            !Boolean(configurableDynamicFormFieldsStore.form.getIsDirty())
        ) {
            navigate(parentPath)
        }
        return (
            contentItemsStore.form.getIsDirty() ||
            configurableDynamicFormFieldsStore.form.getIsDirty()
        )
    }

    const initialized = useInitializer(async () => {
        await contentItemsStore.init(
            id,
            globalStore.session.accessGroupId,
            locationState?.accessType,
        )
    }, [
        globalStore.session.accessGroupId,
        id,
        contentItemsStore,
        locationState?.accessType,
    ])

    const handleSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()

            const validationError = await contentItemsStore.handleSubmit(
                globalStore.session.accessGroup?.name,
                globalStore.session.accessGroup?.id,
                configurableDynamicFormFieldsStore.handleValidation,
                configurableDynamicFormFieldsStore.getConfigsApiPayload,
            )

            if (
                validationError &&
                !Boolean(contentItemsStore.form.error("generic"))
            ) {
                navigate(parentPath)
                globalStore.modals.pop()
            }
        },
        [
            globalStore,
            contentItemsStore,
            navigate,
            parentPath,
            configurableDynamicFormFieldsStore.handleValidation,
            configurableDynamicFormFieldsStore.getConfigsApiPayload,
        ],
    )

    if (
        !initialized ||
        globalStore.loading.is(ContentItemsModalStore.LoadingKeys.init)
    ) {
        return <CenteredSpinner height="100vh" />
    }

    const isSaveButtonDisabled =
        (contentItemsStore.form.getIsDirty() === false &&
            configurableDynamicFormFieldsStore.form.getIsDirty() === false) ||
        contentItemsStore.isLoading ||
        contentItemsStore.isReadOnly

    return (
        <Form onSubmit={handleSubmit}>
            <ModalHeader path={parentPath}>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={isSaveButtonDisabled}
                >
                    {t`global.save`}
                </Button>
            </ModalHeader>
            {Boolean(contentItemsStore.form.error("generic")) && (
                <Alert severity="error">
                    {contentItemsStore.form.error("generic")}
                </Alert>
            )}
            <FormPanel
                sections={[
                    {
                        header: t`navigation-items-view.form-panel.navigation-id`,
                        content: <NavigationIDSection id={id} />,
                    },
                ]}
            />
            <FormPanel
                sections={[
                    {
                        header: t`navigation-items-view.form-panel.settings`,
                        content: <SettingsSection />,
                    },
                ]}
            />

            <FormPanel
                sections={[
                    {
                        header: t`navigation-items-view.form-panel.publishing-settings`,
                        content: <PublishingSettingsSection />,
                    },
                ]}
            />
            <ConfigurableDynamicFormFieldsSection
                configDefinition={contentItemsStore.configDefinition}
                configs={contentItemsStore.form.get("configs")}
                isReadOnly={contentItemsStore.isReadOnly}
                location={contentItemsStore.form.get("location")}
            />
        </Form>
    )
})

export const ContentItemsModalView = (props: IContentItemsModalViewProps) => (
    <StoreProvider Store={ContentItemsModalStore}>
        <StoreProvider Store={ConfigurableDynamicFormFieldsStore}>
            <View {...props} />
        </StoreProvider>
    </StoreProvider>
)
