import { useMemo } from "react"
import { Box } from "@mui/material"

import {
    AnalysisHeader,
    AnalysisItem,
} from "src/components/ChatMessageJourney/components"

import {
    IAnalysisData,
    IAnalysisSectionProps,
} from "src/components/ChatMessageJourney/types"

const parseJSON = (str: string): IAnalysisData[] => {
    try {
        return JSON.parse(str)
    } catch (error) {
        return []
    }
}

const AnalysisSection = ({
    message,
    handleSourceClick,
}: IAnalysisSectionProps) => {
    const analysisData = useMemo(
        () => parseJSON(message?.sourceEval ?? ""),
        [message?.sourceEval],
    )

    return (
        <Box component="section" sx={{ marginBottom: 2 }}>
            <AnalysisHeader />
            {analysisData.map((data, index) => (
                <AnalysisItem
                    key={index}
                    data={data}
                    handleSourceClick={handleSourceClick}
                />
            ))}
        </Box>
    )
}

export default AnalysisSection
