/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { community_AdminPostCreate } from "../models/community_AdminPostCreate"
import type { message_CreateMessageRequest } from "../models/message_CreateMessageRequest"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class PreviewService {
    /**
     * Community post preview
     * Community post preview
     * @returns string OK
     * @throws ApiError
     */
    public static postV1PreviewCommunityPost({
        request,
    }: {
        /** Request body create community post preview **/
        request: community_AdminPostCreate
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/preview/community/post",
            body: request,
        })
    }

    /**
     * Message preview
     * Message preview
     * @returns string OK
     * @throws ApiError
     */
    public static postV1PreviewMessage({
        request,
    }: {
        /** Request body create message preview **/
        request: message_CreateMessageRequest
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/preview/message",
            body: request,
        })
    }

    /**
     * Message list preview
     * Message list preview
     * @returns string OK
     * @throws ApiError
     */
    public static postV1PreviewMessageList({
        request,
    }: {
        /** Request body list messages preview **/
        request: message_CreateMessageRequest
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/preview/message/list",
            body: request,
        })
    }
}
