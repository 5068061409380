export enum MixpanelProperties {
    AccessGroupID = "Access Group ID",
    AccessGroupName = "Access Group Name",
    UserID = "User ID",
    SessionDuration = "Session Time",
    PageName = "Page Name",
    SegmentName = "Segment Name",
    SegmentID = "Segment ID",
    TenantID = "Tenant ID",
    InvoiceStatus = "Status Name",
    SelfRegistrationTabName = "Request",
    Filters = "Filters",
    PublishStartDate = "Publish Start Date",
    PublishEndDate = "Publish End Date",
    PushNotification = "Push Notification",
    ItemID = "Item ID",
    ItemName = "Item name",
    ShowInApp = "Show in app",
    UsedByChatbot = "Used by chatbot",
    CommunityName = "Community name",
    ResourceType = "Resource Type",
    ProductTypeName = "Product Type Name",
}
