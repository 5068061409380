/**
 * Converts a hex color code to an RGBA color string.
 *
 * @param {string} hex - The hex color code (e.g., '#ffffff' or '#fff').
 * @param {number} [alpha] - The alpha value for the RGBA color. Defaults to 0.1 if not provided.
 * @returns {string} The RGBA color string (e.g., 'rgba(255, 255, 255, 0.1)').
 */
export const convertHexToRgba = (hex: string, alpha?: number): string => {
    // Remove the leading '#' if present
    hex = hex.replace(/^#/, "")

    // Validate hex code length (either 3 or 6 characters)
    if (![3, 6].includes(hex.length)) {
        throw new Error("Invalid hex color code")
    }

    // Handle shorthand hex codes (e.g., 'fff' -> 'ffffff')
    if (hex.length === 3) {
        hex = hex
            .split("")
            .map((char) => char + char)
            .join("")
    }

    // Parse the hex code into a number
    let bigint = parseInt(hex, 16)

    // Extract the red, green, and blue components
    let r = (bigint >> 16) & 255
    let g = (bigint >> 8) & 255
    let b = bigint & 255

    // Return the RGBA color string, defaulting alpha to 0.1 if not provided
    return `rgba(${r}, ${g}, ${b}, ${alpha ?? 0.1})`
}
