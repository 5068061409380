import React from "react"
import { t } from "@lingui/macro"
import { IconButton } from "@mui/material"

import { ImagePreview } from "src/components/AttachmentsField/ImageAttachments/ImagePreview"
import { ImagePreviewHoverOptions } from "src/components/AttachmentsField/ImageAttachments/ImagePreviewHoverOptions"
import { View24 } from "src/components/icons/View24"
import { Bin24 } from "src/components/icons/Bin24"
import { UploadImageField } from "src/components/AttachmentsField/ImageAttachments/UploadImageField"

interface IImageHandler {
    imageUrl: string | undefined
    handleImageDelete: () => void
    handleImageChange: (images: IFile[]) => void
}

export default function ImageHandler({
    imageUrl,
    handleImageDelete,
    handleImageChange,
}: IImageHandler) {
    return (
        <div
            style={{
                width: "100px",
            }}
        >
            {imageUrl != null && imageUrl !== "" ? (
                <ImagePreview url={imageUrl ?? ""} aspectRatio="1/1">
                    <ImagePreviewHoverOptions>
                        <IconButton
                            href={imageUrl ?? ""}
                            target="_blank"
                            color="inherit"
                            title={t`common.open-in-new-tab`}
                        >
                            <View24 />
                        </IconButton>
                        <IconButton
                            onClick={handleImageDelete}
                            color="inherit"
                            title={t`common.delete`}
                        >
                            <Bin24 />
                        </IconButton>
                    </ImagePreviewHoverOptions>
                </ImagePreview>
            ) : (
                <UploadImageField
                    onChange={handleImageChange}
                    aspectRatio={"1/1"}
                    removeTypes={["image/svg+xml"]}
                />
            )}
        </div>
    )
}
