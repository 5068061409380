import { IFeatureConfigurationDetailFormFields } from "src/modals/feature-configuration-detail/types/formFields"

export const defaultValuesFeatures: IFeatureConfigurationDetailFormFields = {
    appIconName: "",
    primaryColor: "",
    propertyOwnerEmailAddress: "",
    terminationConnectorId: -1,
    senderName: "",
    senderAddress: "",
    issueReportingExternalLink: "",
    issueReportingEmailToName: "",
    issueReportingEmailToAddress: "",
    emailTemplateTenantElectricityEmail: "",
    emailTemplateTenantFinalReminder: "",
    emailTemplateTenantInsuranceEmail: "",
    emailTemplateTenantMoveIn: "",
    emailTemplateTenantPostMoveInRemindEmail: "",
    emailTemplateTenantReminderRegister: "",
    brandingBackgroundImage: null,
    brandingLogo: null,
    overviewHeaderImage: null,
    overviewLogo: null,
    propertyOwnerEmailLogo: null,
    paymentDueDateBlocker: "",
    emailTemplateTenantRolloutRegistrationReminder: "",
    emailTemplateTenantRolloutRegistrationReminder2: "",
    emailTemplateTenantRolloutRegistrationReminderFinal: "",
    SMSTemplateTenantMoveIn: "",
    SMSFromPhone: "",
    SMSTemplateTenantFinalReminder: "",
    SMSTemplateTenantRolloutRegistrationReminder: "",
    SMSTemplateTenantRolloutRegistrationReminder2: "",
    DashPositionExternalAccess: "",
    DashPositionAmido: "",
    currency: "",
    activeLanguages: "",
    contactDetailsEditable: "",
    editContactDetails: "",
    // flutter keys
    useForPublicBranding: "",
    primaryColorFlutter: "",
    onPrimaryColor: "",
    primaryContainerColor: "",
    onPrimaryContainerColor: "",
    secondaryColorFlutter: "",
    onSecondaryColor: "",
    secondaryContainerColor: "",
    onSecondaryContainerColor: "",
    companyLogo: null,
    overviewAndAppIcon: null,
    overviewHeaderImageCompact: null,
    sidePaneImageExpanded: null,
    loginBackgroundCompact: null,
    loginBackgroundExpanded: null,
    webPageTitle: "",
    webPageDescription: "",
}
