import { makeAutoObservable } from "mobx"
import React from "react"

import { prepareRankableItems, IRankable } from "src/lib/ranking"
import { ICategoryData } from "src/views/product-categories"
import { persistFiles } from "src/lib/file"

import {
    ProductCatalogueAdminService,
    admin_CreateCategoryRequest,
} from "src/api"
import { loads } from "src/channel/utils"
import { FormFields } from "src/lib/form-fields"
import { createLoadingKeys } from "src/lib/loading"
import { Channel } from "src/channel"

interface IFormFields {
    imageUrl?: string
    name: string
    categoryId: number
    rank: string
}

export interface ICategoryProduct extends IRankable {
    id: number
    name: string
    imageUrl: string
    publishedIn: number[]
}

export class ProductCategoriesCreateOrEditStore {
    static Context =
        React.createContext<ProductCategoriesCreateOrEditStore | null>(null)
    static LoadingKeys = createLoadingKeys(
        "init",
        "submit",
        "submitRankedProducts",
    )

    private categoryId?: number

    public products: ICategoryProduct[] = []
    public image = {}
    public icon = {}

    private setCategoryId(id?: number) {
        this.categoryId = id
    }

    setProducts(products: ICategoryProduct[]) {
        this.products = products
    }

    fields = new FormFields<IFormFields>({
        imageUrl: undefined,
        name: "",
        categoryId: 0,
        rank: "",
    })

    get hasUpdatedProducts() {
        return this.products.some((product) => product.modifiedRank)
    }

    constructor() {
        makeAutoObservable(this)
    }

    @loads(() => ProductCategoriesCreateOrEditStore.LoadingKeys.init)
    async init(categoryData?: ICategoryData) {
        this.setCategoryId(categoryData?.categoryId)

        if (this.categoryId != null) {
            const response =
                await ProductCatalogueAdminService.getV1AdminCatalogueCategory1(
                    {
                        categoryId: this.categoryId,
                    },
                )

            this.fields.init({
                imageUrl: categoryData?.imageUrl ?? "",
                name: categoryData?.name ?? "",
                categoryId: this.categoryId,
                rank: categoryData?.rank ?? "",
            })

            const products = (response.products ?? []).map((product) => {
                return {
                    id: product.product_id ?? 0,
                    name: product.admin_name ?? "",
                    imageUrl: product.main_image_url ?? "",
                    publishedIn: product.published_in ?? [],
                    // @ts-ignore (Ignore error until rank field has been added to the api)
                    rank: product.rank ?? "",
                    modifiedRank: false,
                }
            })

            this.setProducts(prepareRankableItems(products))
        }
    }

    @loads(
        () =>
            ProductCategoriesCreateOrEditStore.LoadingKeys.submitRankedProducts,
    )
    submitRankedProducts() {
        // TODO: Make call to update product when the endpoint handles rank field
        // this.updatedProducts.forEach((updatedProduct: ISortableListItem) => {})
    }

    @loads(() => ProductCategoriesCreateOrEditStore.LoadingKeys.submit)
    async submit() {
        if (Object.keys(this.image).length > 0) {
            const images = await persistFiles(
                [this.image as ILocalFile],
                "image",
            )

            if (images.length > 0) {
                this.fields.set("imageUrl", images[0].url)
                this.image = {}
            }
        }

        if (Object.keys(this.icon).length > 0) {
            const images = await persistFiles(
                [this.icon as ILocalFile],
                "image",
            )

            if (images.length > 0) {
                this.icon = {}
            }
        }

        const { data } = this.fields

        const request: admin_CreateCategoryRequest = {
            image_url: data.imageUrl,
            name: data.name,
            rank: data.rank,
        }

        await this.fields.catchErrors(async () => {
            if (this.categoryId == null) {
                const response =
                    await ProductCatalogueAdminService.postV1AdminCatalogueCategory(
                        {
                            request,
                        },
                    )

                Channel.send({
                    name: "repository/updated",
                    payload: {
                        repository: "product-categories",
                        action: "create",
                        item: {
                            id: response.category_id ?? 0,
                            name: data.name,
                        },
                    },
                })
            } else {
                await ProductCatalogueAdminService.putV1AdminCatalogueCategory({
                    categoryId: this.categoryId,
                    request,
                })

                Channel.send({
                    name: "repository/updated",
                    payload: {
                        repository: "product-categories",
                        action: "update",
                        item: {
                            id: this.categoryId,
                            name: data.name,
                        },
                    },
                })
            }
        })
    }
}
