import {
    styled,
    Autocomplete as MUIAutocomplete,
    MenuItem,
} from "@mui/material"

export const HeadContainer = styled("header")(({ theme }) => ({
    background: theme.palette.grey[300],
    height: "64px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.only("xs")]: {
        padding: "0 16px",
    },
    paddingLeft: 24,
    paddingRight: 24,
    boxShadow:
        "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)",
}))

export const HeadRightAlignedContainer = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    "& > *": {
        marginLeft: "32px",
    },
    [theme.breakpoints.down("lg")]: {
        display: "none",
    },
}))

export const HeadWrapper = styled("header")(({ theme }) => ({
    [theme.breakpoints.up("lg")]: {
        width: "100%",
        height: "64px",
        position: "fixed",
        top: 0,
        right: 0,
        zIndex: 2,
        display: "block",
    },
    position: "relative",
    display: "none",
    width: "100%",
}))

export const Autocomplete = styled(MUIAutocomplete)(({ theme }) => ({
    minWidth: "100%",
    width: "100%",
    "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input": {
        paddingRight: "24px",
    },
    "& .MuiOutlinedInput-notchedOutline, & .MuiInputBase-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
        {
            border: 0,
        },
    "& .MuiAutocomplete-inputRoot": {
        background: theme.palette.common.white,
    },
    [theme.breakpoints.up("sm")]: {
        width: "300px",
        minWidth: "auto",
    },
}))

export const HeaderSubWrapper = styled("div")(() => ({
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
}))

export const CustomMenuItem = styled(MenuItem)(() => ({
    minWidth: 200,
    paddingRight: 36,
}))
