import { t } from "@lingui/macro"
import { IconButton, TextField } from "@mui/material"
import { observer } from "mobx-react"

import {
    DropdownItemParentContainer,
    TextFieldContainer,
} from "src/modals/contact-forms-form-builder/styled"

import { IAddDynamicOptionsSectionProps } from "src/modals/contact-forms-form-builder/types/addDynamicOptionsSection"

import { Cross16 } from "src/components/icons/Cross16"
import { CheckBoxOutlined } from "src/components/icons/CheckBoxOutlined"
import { RadioButtonOutlined } from "src/components/icons/RadioButtonOutlined"

export const AddDynamicOptionsSection = observer(
    (props: IAddDynamicOptionsSectionProps) => {
        return (
            <DropdownItemParentContainer key={props.index}>
                {props.type === "Dropdown" ? (
                    props.index + 1
                ) : props.type === "Checkboxes" ? (
                    <CheckBoxOutlined />
                ) : (
                    <RadioButtonOutlined />
                )}

                <TextFieldContainer>
                    <TextField
                        label={t`contact-form-forms-detail-modal.form-field-section.native-fields-question-label`}
                        value={props.item.value}
                        onChange={(e) =>
                            props.handleInputChange(props.index, e)
                        }
                        error={Boolean(props.item.errorMessage)}
                        helperText={props.item.errorMessage}
                    />
                </TextFieldContainer>
                <IconButton
                    size="small"
                    onClick={() => props.handleRemoveClick(props.index)}
                    color="inherit"
                    disabled={props.disabled}
                >
                    <Cross16 />
                </IconButton>
            </DropdownItemParentContainer>
        )
    },
)
