import { Stack } from "@mui/material"
import { observer } from "mobx-react"
import { useEffect, useRef } from "react"

import { Channel } from "src/channel"
import { Comment } from "src/modals/view-community-post/Post/Comment"
import { ViewCommunityPostStore } from "src/modals/view-community-post/store"
import { useStore } from "src/store/lib/useStore"

export const CommentList = observer(() => {
    const store = useStore(ViewCommunityPostStore)
    const latestComment = useRef<HTMLElement | null>(null)

    useEffect(() => {
        return Channel.addListener((event) => {
            if (
                event.name === "repository/updated" &&
                event.payload.repository === "community-comments"
            ) {
                latestComment?.current?.scrollIntoView()
            }
        })
    }, [])

    return (
        <Stack spacing="16px">
            {store.comments.map((comment, i) => (
                <Comment
                    key={comment.id}
                    ref={
                        store.comments.length - 1 === i
                            ? latestComment
                            : undefined
                    }
                    comment={comment}
                />
            ))}
        </Stack>
    )
})
