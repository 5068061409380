import { observer } from "mobx-react"
import { useMemo } from "react"

import ThumbUpIcon from "@mui/icons-material/ThumbUp"

import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt"

import { IStatusChipProps, StatusChip } from "src/components/StatusChip"
import { exhaustiveCheck } from "src/lib/exhaustive-check"
import { ChatbotStatus } from "src/types/chatbot"
import { IChatbotStatusChipProps } from "src/views/chatbot/types/chatbot-status-chip"

export const ChatbotStatusChip = observer(
    ({ status }: IChatbotStatusChipProps) => {
        const chipProps = useMemo((): IStatusChipProps => {
            switch (status) {
                case ChatbotStatus.Positive:
                    return {
                        state: "positive",
                        label: "Positive",
                        icon: <ThumbUpIcon />,
                    }
                case ChatbotStatus.Negative:
                    return {
                        state: "negative",
                        label: "Negative",
                        icon: <ThumbDownAltIcon />,
                    }
                case ChatbotStatus.Unknown:
                    return {
                        state: "other",
                        label: "Not rated",
                    }
                default:
                    exhaustiveCheck(status)
                    return {
                        state: "other",
                        label: "Not rated",
                    }
            }
        }, [status])

        return <StatusChip {...chipProps} />
    },
)
