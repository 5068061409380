import { t } from "@lingui/macro"
import { Tooltip, Typography, useTheme } from "@mui/material"

import { IAccessTypeHeaderProps } from "src/components/AccessTypeHeader/types/accessTypeHeaderProps"
import { TooltipContainer } from "src/styledComponents/TooltipContainer"
import { Icon } from "src/components/icons"
import { EditPencil } from "src/components/icons/EditPencil"
import { ViewEye } from "src/components/icons/ViewEye"

export const AccessTypeHeader = (props: IAccessTypeHeaderProps) => {
    const theme = useTheme()
    return (
        <TooltipContainer>
            <Tooltip
                title={
                    props.accessType === "READ"
                        ? t`community-view.read`
                        : t`community-view.write`
                }
                arrow
                placement="right-start"
            >
                <Typography sx={{ marginTop: "16px" }}>
                    <Icon
                        icon={
                            props.accessType === "READ" ? (
                                <ViewEye />
                            ) : (
                                <EditPencil />
                            )
                        }
                        color={theme.palette.grey[700]}
                    />
                </Typography>
            </Tooltip>
        </TooltipContainer>
    )
}
