import { Box, Chip, styled } from "@mui/material"
import { observer } from "mobx-react"

import { ISegment } from "./store"

import { Icon } from "src/components/icons"
import { getSegmentTypeIcon } from "src/lib/segment-type"

const IconWrapper = styled(Box)({
    marginLeft: "8px",
    marginRight: "-8px",
    display: "inline-flex",
    alignItems: "center",
})

const SegmentChipInner = styled(Chip)({
    fontSize: "13px",
    margin: "0 8px 8px 0",
})

export const SegmentChip = observer((props: { segment: ISegment }) => {
    const { icon, color, backgroundColor } = getSegmentTypeIcon(
        props.segment.type,
    )

    return (
        <SegmentChipInner
            variant="outlined"
            label={props.segment.name}
            icon={
                <IconWrapper>
                    <Icon icon={icon} color={color} size={16} />
                </IconWrapper>
            }
            sx={{
                borderColor: color,
                background: backgroundColor,
            }}
        />
    )
})
