import { observer } from "mobx-react"
import { Box, Typography, Divider } from "@mui/material"
import { t } from "@lingui/macro"

import { EditProductStore } from "src/views/product-catalogue/edit/store"

import { Name } from "src/views/product-catalogue/edit/form-elements/Name"
import { ProductListSubtitle } from "src/views/product-catalogue/edit/form-elements/ProductListSubtitle"
import { PriceDescription } from "src/views/product-catalogue/edit/form-elements/PriceDescription"
import { ProductImage } from "src/views/product-catalogue/edit/form-elements/ProductImage"
import { ProductDescription } from "src/views/product-catalogue/edit/form-elements/ProductDescription"
import { IncludedInPrice } from "src/views/product-catalogue/edit/form-elements/IncludedInPrice"
import { OrderDeliveryProcess } from "src/views/product-catalogue/edit/form-elements/OrderDeliveryProcess"
import { NextSteps } from "src/views/product-catalogue/edit/form-elements/NextSteps"
import { useStore } from "src/store/lib/useStore"

export const ContentSection = observer(() => {
    const store = useStore(EditProductStore)
    const productType = store.formFields.get("productType")

    const showFields = {
        includedInPrice:
            productType === "purchase" ||
            productType === "standard_improvement_single",
        nextSteps: productType !== "affiliate",
    }

    return (
        <>
            <Typography
                component="h2"
                fontSize="20px"
                fontWeight="500"
                sx={{ mb: 2 }}
            >
                {t`edit-product-view.product-content`}
            </Typography>
            <Box
                sx={{
                    display: "grid",
                    gap: "24px",
                    gridTemplateColumns: {
                        xs: "1fr",
                        sm: "1fr 1fr",
                        lg: "1fr 1fr 1fr",
                    },
                    alignItems: "start",
                }}
            >
                <Box
                    sx={{
                        display: "grid",
                        gap: "24px",
                    }}
                >
                    <Name />
                    <ProductListSubtitle />
                    <PriceDescription />
                </Box>
                <ProductImage />
                <ProductDescription />
            </Box>
            <Divider style={{ margin: "24px 0" }} />
            <Box
                sx={{
                    display: "grid",
                    gap: "24px",
                    gridTemplateColumns: {
                        xs: "1fr",
                        sm: "1fr 1fr",
                        lg: "1fr 1fr 1fr",
                    },
                    alignItems: "start",
                }}
            >
                {showFields.includedInPrice && <IncludedInPrice />}
                <OrderDeliveryProcess />
                {showFields.nextSteps && <NextSteps />}
            </Box>
        </>
    )
})
