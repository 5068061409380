import React from "react"
import { Stack, styled, useTheme } from "@mui/material"
import { t } from "@lingui/macro"
import DragIndicator from "@material-ui/icons/DragIndicator"

import {
    ActionDropdown,
    IActionDropdownItem,
} from "src/components/ActionDropdown"

const ListItem = styled("li")(() => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "8px",
    width: "100%",
    borderRadius: "4px",
    backgroundColor: "white",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    minHeight: "54px",
}))

interface IProps {
    rowActions?: IActionDropdownItem[]
    children: React.ReactNode
}

export const Item = (props: IProps) => {
    const theme = useTheme()
    return (
        <ListItem data-testid="sortable-list-item">
            <Stack direction="row" alignItems="center">
                <button
                    type="button"
                    className="drag-handle"
                    style={{
                        cursor: "move",
                        padding: 0,
                        border: 0,
                        background: "none",
                        lineHeight: 0,
                        fontSize: 0,
                    }}
                    title={t`sortable-list.button-title`}
                >
                    <DragIndicator
                        style={{
                            color: theme.palette.grey[700],
                            width: "20px",
                            height: "38px",
                            margin: "0 0.5em",
                        }}
                    />
                </button>
                {props.children}
            </Stack>
            {props.rowActions != null && (
                <ActionDropdown items={props.rowActions} />
            )}
        </ListItem>
    )
}
