import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useCallback, useEffect } from "react"

import { ListPage } from "src/components/ListPage"
import { NoWrap } from "src/components/NoWrap"
import { Table } from "src/components/Table"
import { CommunityDetailModal } from "src/modals/community-detail"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { CommunitiesViewStore, ICommunity } from "src/views/communities/store"
import { AccessTypeHeader } from "src/components/AccessTypeHeader"

const View = observer(() => {
    const vstore = useStore(CommunitiesViewStore)
    const gstore = useStore(GlobalStore)

    useEffect(() => {
        ;(async () => await vstore.init(gstore.session.accessGroupId))()
    }, [vstore, gstore.session.accessGroupId])

    const handleOpenCommunityDetailModalWithCommunity = useCallback(
        (item: ICommunity) => {
            gstore.modals.open(() => <CommunityDetailModal id={item.id} />)
        },
        [gstore],
    )

    const handleOpenCommunityDetailModalWithAccessGroup = useCallback(() => {
        gstore.modals.open(() => (
            <CommunityDetailModal
                accessGroupId={gstore.session.accessGroupId}
            />
        ))
    }, [gstore])

    return (
        <ListPage
            header={{
                header: t`community-view.header`,
                breadcrumbs: [
                    t`community-view.community-breadcrumb`,
                    t`community-view.communities-breadcrumb`,
                ],
                createOptions: {
                    item: t`community-view.create-button-text`,
                    onClick: handleOpenCommunityDetailModalWithAccessGroup,
                },
            }}
            loading={gstore.loading.is(CommunitiesViewStore.LoadingKeys.init)}
        >
            <Table
                paginator={vstore.communities}
                headers={[
                    {
                        key: "internalName",
                        name: t`community-view.internal-name-header`,
                    },
                    { key: "name", name: t`community-view.name-header` },
                    {
                        key: "adminEmails",
                        name: t`community-view.admin-email-header`,
                    },
                    {
                        key: "accessType",
                        name: t`community-view.access-type`,
                    },
                ]}
                rowRenderer={(item) => ({
                    name: <NoWrap>{item.name}</NoWrap>,
                    internalName: <NoWrap>{item.internalName}</NoWrap>,
                    adminEmails: <NoWrap>{item.adminEmails}</NoWrap>,
                    accessType: (
                        <AccessTypeHeader accessType={item.accessType} />
                    ),
                })}
                onRowClick={handleOpenCommunityDetailModalWithCommunity}
            />
        </ListPage>
    )
})

export const CommunitiesView = observer(() => (
    <StoreProvider Store={CommunitiesViewStore}>
        <View />
    </StoreProvider>
))
