import { makeAutoObservable } from "mobx"
import React from "react"
import { t } from "@lingui/macro"

import type { admin_GetFeatureFlagsResponse } from "src/api/models/admin_GetFeatureFlagsResponse"
import { FeaturesAdminService } from "src/api/services/FeaturesAdminService"
import type { domain_UpdateFeature } from "src/api/models/domain_UpdateFeature"
import { reportError } from "src/lib/report"
import { ISessionLegalEntity } from "src/store/session"

export class FeatureTogglesStore {
    static Context = React.createContext<FeatureTogglesStore | null>(null)

    constructor() {
        makeAutoObservable(this)
    }

    /* Selected property owner ID */
    private _selectedPropertyOwnerId: string = ""

    get selectedPropertyOwnerId() {
        return this._selectedPropertyOwnerId
    }

    public setSelectedPropertyOwnerId = (propertyOwnerId: string) => {
        this._selectedPropertyOwnerId = propertyOwnerId
    }

    /* Legal entities */
    private _legalEntities: ISessionLegalEntity[] = []

    get legalEntities() {
        return this._legalEntities
    }

    public setLegalEntities(legalEntities: ISessionLegalEntity[]) {
        this._legalEntities = legalEntities
    }

    /* Selected legal entities */
    private _selectedLegalEntities: ISessionLegalEntity[] = []

    get selectedLegalEntities() {
        return this._selectedLegalEntities
    }

    public setSelectedLegalEntities(
        selectedLegalEntities: ISessionLegalEntity[],
    ) {
        this._selectedLegalEntities = selectedLegalEntities
    }

    /* Features flag list */
    private _featuresFlagList: admin_GetFeatureFlagsResponse = {}

    get featuresFlagList() {
        return this._featuresFlagList
    }

    public setFeaturesFlagList(
        featuresFlagList: admin_GetFeatureFlagsResponse,
    ) {
        this._featuresFlagList = featuresFlagList
    }

    public async fetchFeaturesFlagList(legalEntityIds: number[]) {
        try {
            this.setFeaturesFlagList(
                await FeaturesAdminService.postV1AdminFeaturesFlagList({
                    request: {
                        legal_entity_ids: legalEntityIds,
                    },
                }),
            )
        } catch (e) {
            reportError(t`errors.request-failed-with-server-error-alert`, e)
            this.setTableData([])
        }
    }

    /* Columns */
    private _columns = [
        {
            label: "",
            id: "",
        },
        {
            label: t`global.features.appartment-menu`,
            id: "appartmentMenu",
        },
        {
            label: t`global.features.energy-deal`,
            id: "energyDeal",
        },
        {
            label: t`global.features.home-insurance-deal`,
            id: "home_insurance_deal",
        },
        {
            label: t`global.features.invoice-tab`,
            id: "invoiceTab",
        },
        {
            label: t`global.features.issue-reporting-email`,
            id: "issue_reporting_email",
        },
        {
            label: t`global.features.issue-reporting-link`,
            id: "issue_reporting_link",
        },
        {
            label: t`global.features.laundry-booking`,
            id: "laundry_booking",
        },
        {
            label: t`global.features.measurements`,
            id: "measurements",
        },
        {
            label: t`global.features.message-tab`,
            id: "messageTab",
        },
        {
            label: t`global.features.om-avy-menu`,
            id: "omAVYMenu",
        },
        {
            label: t`global.features.payment-config-menu`,
            id: "paymentConfigMenu",
        },
        {
            label: t`global.features.payment-provider-tink`,
            id: "payment_provider_tink",
        },
        {
            label: t`global.features.product-tab`,
            id: "productTab",
        },
        {
            label: t`global.features.profile-tab`,
            id: "profileTab",
        },
        {
            label: t`global.features.service-tab`,
            id: "service_tab",
        },
        {
            label: t`global.features.freshdesk`,
            id: "freshdesk",
        },
        {
            label: t`global.features.mobility46`,
            id: "mobility46",
        },
        {
            label: t`global.features.customer-support`,
            id: "customer_support",
        },
        {
            label: t`global.features.share-invoices`,
            id: "share_invoices",
        },
        {
            label: t`global.features.app-overview`,
            id: "app_overview",
        },
        {
            label: t`global.features.move-in-guide`,
            id: "move_in_guide",
        },
        {
            label: t`global.features.community`,
            id: "community",
        },
        {
            label: t`global.features.contact-form`,
            id: "contact_form",
        },
        {
            label: t`global.features.fastapi`,
            id: "fastapi",
        },
        {
            label: t`global.features.library`,
            id: "infocenter",
        },
        {
            label: t`global.features.aptus`,
            id: "aptus",
        },
        {
            label: t`global.features.qt-systems`,
            id: "qt_systems",
        },
        {
            label: t`global.features.invoice-distribution-channel`,
            id: "invoice_distribution_channel",
        },
        {
            label: t`global.features.safety-insurance`,
            id: "safety_insurance",
        },
        {
            label: t`global.features.email-login`,
            id: "email_login",
        },
        {
            label: t`global.features.dh-issue-report`,
            id: "dh_issue_report",
        },
        {
            label: t`global.features.pigello-sso`,
            id: "pigello_sso",
        },
        {
            label: t`global.features.storage-service`,
            id: "storage_service",
        },
        {
            label: t`global.features.is-lobby-enabled`,
            id: "is_lobby_enabled",
        },
        {
            label: t`global.features.paylater`,
            id: "paylater",
        },
        {
            label: t`global.features.booking-engine`,
            id: "booking_engine",
        },
        {
            label: t`global.features.guest-accounts`,
            id: "guest_accounts",
        },
        {
            label: t`global.features.lime`,
            id: "lime",
        },
        {
            label: t`global.features.force-registered-tenant-extin-api`,
            id: "force_registered_tenant_extin_api",
        },
    ]

    get columns() {
        return this._columns
    }

    /* Table data */
    private _tableData: ITableCell[][] = []

    get tableData() {
        return this._tableData
    }

    setTableData = (tableData: ITableCell[][]) => {
        this._tableData = tableData
    }

    public populateTableData = async () => {
        this.setDirtyFeatureFlags(false)

        const tableHead = this.columns.map((column) => ({
            label: column.label,
            selected: false,
        }))

        if (this.selectedLegalEntities.length === 0) {
            this.setTableData([])

            return
        }

        await this.fetchFeaturesFlagList(
            this.selectedLegalEntities.map((selectedLegalEntity) =>
                Number(selectedLegalEntity.legalEntityId),
            ),
        )

        const tableBody = this.selectedLegalEntities.map((legalEntity) => {
            return this.columns.map((column, columnIndex) => {
                if (columnIndex === 0) {
                    return {
                        legalEntityId: legalEntity.legalEntityId,
                        label: legalEntity.legalName,
                    }
                }

                if (this.featuresFlagList.feature_flags != null) {
                    for (
                        let i = 0;
                        i < this.featuresFlagList.feature_flags.length;
                        i += 1
                    ) {
                        const featureFlag =
                            this.featuresFlagList.feature_flags[i]

                        if (
                            featureFlag.feature_name === column.id &&
                            featureFlag.legal_entity_id ===
                                legalEntity.legalEntityId
                        ) {
                            return {
                                selected: true,
                                modified: false,
                                featureName: column.id,
                            }
                        }
                    }

                    return {
                        selected: false,
                        modified: false,
                        featureName: column.id,
                    }
                }

                return {}
            })
        })

        this.setTableData([tableHead, ...tableBody])
    }

    public setTableCellSelected(cell: ITableCell, value: boolean) {
        cell.selected = value
        cell.modified = true
        this._dirtyFeatureFlags = true
    }

    /* Dirty feature flags */
    private _dirtyFeatureFlags: boolean = false

    get dirtyFeatureFlags() {
        return this._dirtyFeatureFlags
    }

    setDirtyFeatureFlags = (value: boolean) => {
        this._dirtyFeatureFlags = value
    }

    /* Modified feature flags */
    private _modifiedFeatureFlags: Array<domain_UpdateFeature> = []

    get modifiedFeatureFlags() {
        return this._modifiedFeatureFlags
    }

    public setModifiedFeatureFlags = (
        modifiedFeatureFlags: Array<domain_UpdateFeature>,
    ) => {
        this._modifiedFeatureFlags = modifiedFeatureFlags
    }

    public populateModifiedFeatureFlags = () => {
        let modifiedFeatureFlags: Array<domain_UpdateFeature> = []

        this.tableData.forEach((row) => {
            let features = {}

            row.forEach((cell) => {
                if (cell.modified === true && cell.featureName != null) {
                    Object.assign(features, {
                        [cell.featureName]: cell.selected,
                    })
                }
            })

            if (
                Object.keys(features).length > 0 &&
                row[0].legalEntityId != null
            ) {
                modifiedFeatureFlags.push({
                    features: features,
                    legal_entity_id: row[0].legalEntityId,
                })
            }
        })

        this.setModifiedFeatureFlags(modifiedFeatureFlags)
    }

    /* isLoading */
    private _isLoading = false

    get isLoading() {
        return this._isLoading
    }

    setIsLoading = (value: boolean) => {
        this._isLoading = value
    }
}

export interface ITableCell {
    label?: string
    legalEntityId?: number
    selected?: boolean
    modified?: boolean
    featureName?: string
}
