import { Box, styled } from "@mui/material"

const Wrapper = styled(Box)(() => ({
    display: "flex",
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 36,
    flex: 1,
    margin: "24px 0px",
}))

const SystemMessageLabel = styled("span")(({ theme }) => ({
    fontWeight: "400",
    color: theme.palette.grey[900],
    fontSize: 13,
    letterSpacing: 0.16,
}))

const SystemMessageLabelBold = styled("span")(({ theme }) => ({
    fontWeight: "500",
    color: theme.palette.grey[900],
    fontSize: 13,
    letterSpacing: 0.46,
}))

const DivisionLine = styled("div")(({ theme }) => ({
    height: 1,
    flex: 1,
    backgroundColor: theme.palette.grey[400],
}))

const Bubble = styled("div")(() => ({
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    padding: "7px 10px",
    margin: "0px 10px",
    borderRadius: 16,
}))

export {
    Wrapper,
    SystemMessageLabel,
    SystemMessageLabelBold,
    DivisionLine,
    Bubble,
}
