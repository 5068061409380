import { IIconProps } from "src/components/icons"

export const CheckRounded24 = (props: IIconProps) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M9 16.1699L5.53 12.6999C5.14 12.3099 4.51 12.3099 4.12 12.6999C3.73 13.0899 3.73 13.7199 4.12 14.1099L8.3 18.2899C8.69 18.6799 9.32 18.6799 9.71 18.2899L20.29 7.70995C20.68 7.31995 20.68 6.68995 20.29 6.29995C19.9 5.90995 19.27 5.90995 18.88 6.29995L9 16.1699Z"
            fill="currentColor"
            fillOpacity="0.54"
        />
    </svg>
)
