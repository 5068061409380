import { observer } from "mobx-react"
import { TextField, InputAdornment } from "@mui/material"
import { useEffect } from "react"
import { t } from "@lingui/macro"

import { EditProductStore } from "src/views/product-catalogue/edit/store"

import { useStore } from "src/store/lib/useStore"

export const Price = observer(() => {
    const store = useStore(EditProductStore)
    const priceCents = store.formFields.get("priceCents")
    const price = priceCents != null ? priceCents / 100 : ""

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        store.formFields.set(
            "priceCents",
            Number(event.target.value.replace(/\D/g, "")) * 100 ?? 0,
        )
    }

    useEffect(() => {
        return () => {
            // Reset to initial value if component unmounts
            store.formFields.set("priceCents", store.product.priceCents)
        }
    }, [store])

    return (
        <TextField
            type="search"
            label={t`edit-product-view.price.label`}
            value={price}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        {store.product.currency ?? "SEK"}
                    </InputAdornment>
                ),
            }}
            onChange={handleChange}
            error={Boolean(store.formFields.error("priceCents"))}
            helperText={store.formFields.error("priceCents")}
        />
    )
})
