import { IIconProps } from "src/components/icons"

export const Ellipse2 = (props: IIconProps) => (
    <svg
        width="6"
        height="6"
        viewBox="0 0 6 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle cx="3" cy="3" r="3" fill="currentColor" />
    </svg>
)
