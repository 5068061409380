import { t } from "@lingui/macro"
import { Link, Stack } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"
import InsertLinkIcon from "@mui/icons-material/InsertLink"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"
import WebIcon from "@mui/icons-material/Web"

import { FormPanel } from "src/components/FormPanel"
import { OrderableList } from "src/components/OrderableList"
import { useStore } from "src/store/lib/useStore"
import { ILibraryInformationItem } from "src/types/library"
import { LibraryInformationItemModal } from "src/modals/library-information-items"
import { GlobalStore } from "src/store"
import { LibraryCategoriesStore } from "src/modals/library-categories/store"

export const LibraryCategoriesOrderingSection = observer(() => {
    const store = useStore(LibraryCategoriesStore)
    const globalStore = useStore(GlobalStore)

    const handleItemClick = useCallback(
        (item: ILibraryInformationItem) => {
            globalStore.modals.open(() => (
                <LibraryInformationItemModal informationItem={item} />
            ))
        },
        [globalStore.modals],
    )

    const handleItemsSort = useCallback(
        (items: ILibraryInformationItem[]) => {
            store.setItems(items)
        },
        [store],
    )

    const itemRowRenderer = useCallback(
        (item: ILibraryInformationItem) => (
            <Stack spacing={2} direction="row">
                {item.item_type === "url" && <InsertLinkIcon />}
                {item.item_type === "document" && <PictureAsPdfIcon />}
                {item.item_type === "page" && <WebIcon />}
                <Link
                    component="button"
                    type="button"
                    sx={{
                        fontWeight: 500,
                        flexGrow: 1,
                        display: "block",
                        textAlign: "left",
                        fontSize: "14px",
                    }}
                    onClick={() => handleItemClick(item)}
                >
                    {item.admin_name}
                </Link>
            </Stack>
        ),
        [handleItemClick],
    )

    const itemRowActionRenderer = useCallback(
        (item: ILibraryInformationItem) => [
            {
                text: t`edit-information-item-modal.edit-item`,
                onClick: () => handleItemClick(item),
            },
        ],
        [handleItemClick],
    )

    return (
        <FormPanel
            sections={[
                {
                    header: t`edit-category-modal.items-in-category`,
                    content: (
                        <OrderableList
                            items={store.items}
                            onSort={handleItemsSort}
                            rowRenderer={itemRowRenderer}
                            rowActionsRenderer={itemRowActionRenderer}
                        />
                    ),
                },
            ]}
        />
    )
})
