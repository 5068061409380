import { styled } from "@mui/material"

const OutPutParagraph = styled("p")(() => ({
    marginLeft: "14px",
}))

const SpanContainer = styled("p")(({ theme }) => ({
    color: theme.palette.error.main,
    marginTop: "4px",
    marginLeft: "14px",
    fontSize: "13px",
}))

export { OutPutParagraph, SpanContainer }
