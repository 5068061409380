import { t } from "@lingui/macro"
import { Stack, TextField } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { DateTimePicker } from "src/components/DateTimePicker"
import { FormFieldSet } from "src/components/FormFieldSet"
import { WysiwygEditor } from "src/components/WysiwygEditor"
import { NoticeBoardPostDetailStore } from "src/modals/notice-board-post-detail/store"
import { useStore } from "src/store/lib/useStore"

export const ContentSection = observer(() => {
    const store = useStore(NoticeBoardPostDetailStore)

    const handleTitleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            store.fields.set("title", event.target.value)
        },
        [store.fields],
    )

    return (
        <>
            <TextField
                label={t`notice-board-post-detail-modal.content-section.title-label`}
                placeholder={t`notice-board-post-detail-modal.content-section.title-placeholder`}
                onChange={handleTitleChange}
                value={store.fields.get("title")}
                helperText={store.fields.error("title")}
                error={Boolean(store.fields.error("title"))}
                disabled={store.fields.get("access_type") === "READ"}
            />
            <WysiwygEditor
                onChange={(value) => store.fields.set("text", value)}
                initialValue={store.fields.get("text")}
                readOnly={store.fields.get("access_type") === "READ"}
            />
            <FormFieldSet
                header={t`notice-board-post-detail-modal.content-section.event-date-header`}
                tooltip={t`notice-board-post-detail-modal.content-section.event-date-tooltip`}
            >
                <Stack
                    spacing={2}
                    direction={{
                        lg: "row",
                        md: "row",
                        sm: "row",
                        xs: "column",
                    }}
                >
                    <DateTimePicker
                        label={t`notice-board-post-detail-modal.content-section.event-start-label`}
                        onChangePicker={(date: Date | null) =>
                            store.fields.set("event_starts_at", date)
                        }
                        value={store.fields.get("event_starts_at")}
                        maxDate={store.fields.get("event_ends_at")}
                        inputId={"event-starts-at"}
                        disabled={store.fields.get("access_type") === "READ"}
                    />
                    <DateTimePicker
                        label={t`notice-board-post-detail-modal.content-section.event-end-label`}
                        onChangePicker={(date: Date | null) =>
                            store.fields.set("event_ends_at", date)
                        }
                        minDate={store.fields.get("event_starts_at")}
                        value={store.fields.get("event_ends_at")}
                        inputId={"event-ends-at"}
                        disabled={store.fields.get("access_type") === "READ"}
                    />
                </Stack>
            </FormFieldSet>
        </>
    )
})
