import { createTheme } from "@mui/material/styles"

const {
    palette: { augmentColor },
} = createTheme()

export const DefaultTheme = createTheme({
    typography: {
        fontSize: 14,
    },
    palette: {
        mode: "light",
        primary: { main: "#025A5C" },
        success: { main: "#62A144" },
        warning: { main: "#F4C74E" },
        error: { main: "#D84545", dark: "#E65100" },
        info: { main: "#4582D8" },
        brand: {
            green: augmentColor({ color: { main: "#025A5C" } }),
            red: augmentColor({ color: { main: "#FF5959" } }),
            darkGreen: augmentColor({ color: { main: "#1D3233" } }),
            lightBeige: augmentColor({ color: { main: "#FAF1E7" } }),
        },
        utility: {
            blue: augmentColor({ color: { main: "#5280DA" } }),
            aquaBlue: augmentColor({ color: { main: "#0C8DB6" } }),
            darkBlue: augmentColor({ color: { main: "#1976D2" } }),
            torquoise: augmentColor({ color: { main: "#3D9E93" } }),
            apple: augmentColor({ color: { main: "#819546" } }),
            orange: augmentColor({ color: { main: "#E7A55A" } }),
            rustRed: augmentColor({ color: { main: "#CA6363" } }),
            brightPink: augmentColor({ color: { main: "#CE73A1" } }),
            purple: augmentColor({ color: { main: "#A465B8" } }),
            blueFaded: augmentColor({ color: { main: "#DDE6F8" } }),
            aquaBlueFaded: augmentColor({ color: { main: "#CFF1FC" } }),
            torquoiseFaded: augmentColor({ color: { main: "#DAF1EE" } }),
            clementine: augmentColor({ color: { main: "#ED6C02" } }),
            appleFaded: augmentColor({ color: { main: "#EAEFDC" } }),
            orangeFaded: augmentColor({ color: { main: "#F9E7D2" } }),
            rustRedFaded: augmentColor({ color: { main: "#F2D9D9" } }),
            brightPinkFaded: augmentColor({ color: { main: "#F2D9E5" } }),
            purpleFaded: augmentColor({ color: { main: "#F0E7F4" } }),
            mothGreen: augmentColor({ color: { main: "#007405" } }),
            lightGreen: augmentColor({ color: { main: "#F1F8E9" } }),
            lightIndigo: augmentColor({ color: { main: "#E8EAF6" } }),
            concrete: augmentColor({ color: { main: "#F2F2F2" } }),
            varden: augmentColor({ color: { main: "#FFF7E0" } }),
            creamBrulee: augmentColor({ color: { main: "##FFE49D" } }),
            brightSun: augmentColor({ color: { main: "#FFCE4A" } }),
            neonCarrot: augmentColor({ color: { main: "#FFA336" } }),
            roofTerracotta: augmentColor({ color: { main: "#AA261D" } }),
            wildSand: augmentColor({ color: { main: "#F5F5F5" } }),
        },
        resources: {
            // These colors will only be used as background for community post
            // categories.
            communityPostCategories: [
                "rgba(147, 180, 139, 0.5)",
                "rgba(166, 177, 225, 0.5)",
                "rgba(181, 228, 140, 0.5)",
                "rgba(189, 224, 254, 0.5)",
                "rgba(202, 207, 214, 0.5)",
                "rgba(206, 160, 126, 0.5)",
                "rgba(217, 137, 133, 0.5)",
                "rgba(220, 214, 247, 0.5)",
                "rgba(226, 232, 192, 0.5)",
                "rgba(237, 217, 163, 0.5)",
                "rgba(240, 230, 209, 0.5)",
                "rgba(250, 225, 223, 0.5)",
                "rgba(255, 175, 204, 0.25)",
                "rgba(66, 72, 116, 0.25)",
            ],
            avatarColorList: [
                "#443742", //matterhorn
                "#846C5B", //cement
                "#CEA07E", //tan
                "#EDD9A3", //doubleColonialWhite
                "#E2E8C0", //berylGreen
                "#690500", //lonestar
                "#CACFD6", //ghost
                "#A6B1E1", // coldPurple
                "#DCD6F7", //moonRaker
                "#424874", //eastBay
                "#934B00", // brown
                "#767B91", //waterloo
                "#2A324B", //pickledBluewood
                "#4B644A", //axolotl
                "#93B48B", //norway
            ],
        },
        background: {
            default: "#FFFFFF",
            paper: "#FFFFFF",
            success: "#E5F3DF",
            warning: "#FFF3D3",
            error: "#FEECEC",
            info: "#F0F6FE",
        },
        text: {
            primary: "#333333",
            secondary: "#767676",
            disabled: "#9A9A9A",
        },
        grey: {
            50: "#FAFAFA",
            100: "#F9F9F9",
            200: "#E0E0E0",
            300: "#EFEFEF",
            500: "#D1D1D1",
            600: "#757575",
            700: "#767676",
            800: "#424242",
            900: "#333333",
        },
        common: {
            black: "#000",
            white: "#FFF",
        },
        generalColors: {
            green: "#43a047",
            red: "#e53a36",
        },
    },
    components: {
        MuiFormControl: {
            styleOverrides: {
                root: {
                    width: "100%",
                },
            },
        },
        MuiButton: {
            variants: [
                {
                    props: { color: "primary", variant: "contained" },
                    style: ({ theme }) => ({
                        background: theme.palette.brand.red.main,
                        color: theme.palette.common.white,
                        "&:hover": {
                            background: theme.palette.brand.red.dark,
                        },
                    }),
                },
                {
                    props: { color: "secondary", variant: "contained" },
                    style: ({ theme }) => ({
                        backgroundColor: theme.palette.grey[500],
                        color: theme.palette.common.white,
                        "&:hover": {
                            backgroundColor: theme.palette.grey[600],
                        },
                    }),
                },
            ],
            styleOverrides: {
                contained: { boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" },
            },
            defaultProps: {
                type: "button",
            },
        },
        MuiButtonBase: {
            defaultProps: { disableRipple: true, type: "button" },
        },
        MuiFab: {
            variants: [
                {
                    props: { color: "primary", variant: "circular" },
                    style: ({ theme }) => ({
                        background: theme.palette.brand.red.main,
                        color: theme.palette.common.white,
                        "&:hover": {
                            background: theme.palette.brand.red.dark,
                        },
                    }),
                },
            ],
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    "&::before": { content: "none" },
                    "&::after": { content: "none" },
                },
                input: ({ theme }) => ({
                    background: theme.palette.common.white,
                    borderRadius: theme.shape.borderRadius,
                    padding: "8px 32px 8px 12px",
                }),
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                        borderWidth: "1px",
                        borderStyle: "dotted",
                        borderColor: "rgba(0, 0, 0, 0.23)",
                    },
                },
                input: {
                    "&.Mui-disabled": {
                        color: "rgba(0, 0, 0, 0.60)",
                        textFillColor: "rgba(0, 0, 0, 0.60)",
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    "&.Mui-disabled": {
                        color: "rgba(0, 0, 0, 0.60)",
                    },
                },
            },
        },
        MuiCheckbox: {
            defaultProps: { disableRipple: true },
            variants: [
                {
                    props: { color: "primary" },
                    style: ({ theme }) => ({
                        "&.Mui-checked": { color: theme.palette.grey[900] },
                        "&.MuiCheckbox-indeterminate": {
                            color: theme.palette.grey[900],
                        },
                    }),
                },
            ],
        },
        MuiPaper: {
            styleOverrides: {
                root: ({ theme }) => ({
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: theme.palette.grey[500],
                }),
            },
        },
        MuiRadio: {
            defaultProps: { disableRipple: true },
            variants: [
                {
                    props: { color: "primary" },
                    style: ({ theme }) => ({
                        "&:hover": {
                            backgroundColor: "unset",
                        },
                        "&.Mui-checked": { color: theme.palette.grey[900] },
                        "&.MuiCheckbox-indeterminate": {
                            color: theme.palette.grey[900],
                        },
                    }),
                },
            ],
        },
        MuiSelect: {
            styleOverrides: {
                nativeInput: {
                    border: "none",
                },
                select: ({ theme }) => ({
                    background: theme.palette.common.white,
                    border: "none",
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                    },
                    "&.Mui-disabled": {
                        background: theme.palette.grey[100],
                    },
                }),
            },
        },
        MuiTable: {
            styleOverrides: {
                root: { boxShadow: "none" },
            },
        },
    },
})
