import { GridRenderCellParams, useGridApiContext } from "@mui/x-data-grid-pro"
import Box from "@mui/material/Box"
import { Stack } from "@mui/material"

import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material"
import { useCallback } from "react"

export const CustomGridTreeDataGroupingCell = ({
    id,
    field,
    rowNode,
    row,
}: GridRenderCellParams) => {
    const apiRef = useGridApiContext()

    const handleClick: React.MouseEventHandler<HTMLDivElement> = useCallback(
        (event) => {
            if (rowNode.type !== "group") {
                return
            }

            apiRef.current.setRowChildrenExpansion(
                id,
                !(rowNode.childrenExpanded ?? false),
            )
            apiRef.current.setCellFocus(id, field)
            event.stopPropagation()
        },
        [apiRef, field, id, rowNode],
    )

    return (
        <Box sx={{ ml: rowNode.depth * 4 }}>
            {row.itemType === "post" ? (
                <div onClick={handleClick}>
                    <Stack flexDirection="row">
                        {rowNode.type === "group" && (
                            <>
                                {rowNode.childrenExpanded === false ? (
                                    <KeyboardArrowRight />
                                ) : (
                                    <KeyboardArrowDown />
                                )}
                            </>
                        )}
                    </Stack>
                </div>
            ) : (
                <span />
            )}
        </Box>
    )
}
