import { t } from "@lingui/macro"
import { FormHelperText } from "@mui/material"
import { observer } from "mobx-react"

import { useStore } from "src/store/lib/useStore"

import { ChatInputImageList } from "src/components/Chat/Input/ImageList"
import {
    ChatInputImagesWrap,
    ChatInputPositioner,
    ChatInputStyledTextField,
} from "src/components/Chat/Input/TextField/styled"
import { AvyChatInputStore } from "src/components/Chat/Input/store"

export const ChatInputTextField = observer(
    ({ isSubmitting }: { isSubmitting: boolean }) => {
        const store = useStore(AvyChatInputStore)

        return (
            <>
                <ChatInputPositioner>
                    <ChatInputStyledTextField
                        onChange={store.chatInputForm.setter("text")}
                        value={store.chatInputForm.get("text")}
                        hasImages={store.chatInputForm.get("images").length > 0}
                        disabled={isSubmitting}
                        placeholder={t`chat-component.chat-input.placeholder`}
                        error={Boolean(store.chatInputForm.error("text"))}
                        size="small"
                        multiline
                        maxRows={5}
                    />
                    {store.chatInputForm.get("images").length > 0 && (
                        <ChatInputImagesWrap>
                            <ChatInputImageList isSubmitting={isSubmitting} />
                        </ChatInputImagesWrap>
                    )}
                </ChatInputPositioner>
                {Boolean(store.chatInputForm.error("text")) && (
                    <FormHelperText
                        error={Boolean(store.chatInputForm.error("text"))}
                    >
                        {store.chatInputForm.error("text")}
                    </FormHelperText>
                )}
            </>
        )
    },
)
