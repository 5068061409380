import { t } from "@lingui/macro"
import { observer } from "mobx-react"

import React, { useCallback, useEffect, useMemo } from "react"

import { ProvidersStore } from "./store"

import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { ProviderDetailModal } from "src/modals/provider-detail"
import { Table } from "src/components/Table"
import { ListPage } from "src/components/ListPage"

const View = observer(() => {
    const vstore = useStore(ProvidersStore)
    const gstore = useStore(GlobalStore)

    useEffect(() => {
        ;(async () => {
            await vstore.init()
        })()
    }, [vstore])

    const handleNewProviderClick = useCallback(() => {
        gstore.modals.open(() => <ProviderDetailModal />)
    }, [gstore])

    const handleOpenProviderClick = useCallback(
        (id: number) => {
            gstore.modals.open(() => <ProviderDetailModal id={id} />)
        },
        [gstore],
    )

    const createOpenProviderClickHandler = useCallback(
        (id: number) => (event: React.MouseEvent<Element>) => {
            event.preventDefault()
            handleOpenProviderClick(id)
        },
        [handleOpenProviderClick],
    )

    const header = useMemo(
        () => ({
            header: t`provider-view.header`,
            breadcrumbs: [t`provider-view.header`],
            createOptions: {
                item: t`providers-view.add-provider`,
                onClick: handleNewProviderClick,
            },
        }),
        [handleNewProviderClick],
    )

    const tableIsLoading =
        !vstore.providers.meta.initialized ||
        gstore.loading.is(ProvidersStore.LoadingKeys.pageLoad)

    return (
        <ListPage header={header} loading={tableIsLoading}>
            <Table
                paginator={vstore.providers}
                onRowClick={(item) => handleOpenProviderClick(item.id)}
                headers={[
                    {
                        key: "name",
                        name: t`provider-view.title-header`,
                    },
                    {
                        key: "id",
                        name: t`provider-view.id-header`,
                    },
                ]}
                rowRenderer={(item) => ({
                    name: item.name,
                    id: item.id,
                })}
                rowActionsRenderer={(item) => [
                    {
                        text: t`provider-view.edit-provider`,
                        onClick: createOpenProviderClickHandler(item.id),
                    },
                ]}
            />
        </ListPage>
    )
})

export const ProvidersView = () => (
    <StoreProvider Store={ProvidersStore}>
        <View />
    </StoreProvider>
)
