/* eslint-disable import/no-relative-parent-imports */
import { Alert, Button } from "@mui/material"
import { t } from "@lingui/macro"
import { observer } from "mobx-react"

import { IntegrationConnectorItemStore } from "../store"

import { Text } from "../styled"

import { AccessGroupDropdownAndConnectorView } from "./AccessGroupDropdownAndConnectorView"

import { ISideModalViewProps } from "./types"

import { FormPanel } from "src/components/FormPanel"
import { useStore } from "src/store/lib/useStore"
import { Form } from "src/components/Form"
import { ModalHeader } from "src/components/ModalHeader"

export const SideModalView = observer((props: ISideModalViewProps) => {
    const store = useStore(IntegrationConnectorItemStore)

    const isButtonEnabled =
        props.isSaveButtonEnabled === false ||
        store.formFields.get("accessType") === "READ" ||
        store.isLoading

    return (
        <Form onSubmit={props.handleSubmit}>
            <>
                <ModalHeader>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={isButtonEnabled}
                        data-testid="submit-item-form"
                    >
                        {t`global.save`}
                    </Button>
                </ModalHeader>
                {Boolean(store.formFields.error("generic")) && (
                    <Alert severity="error">
                        {store.formFields.error("generic")}
                    </Alert>
                )}
                <FormPanel
                    sections={[
                        {
                            header: t`integration-connector-modal.configuration-id`,
                            content: (
                                <Text>
                                    {store.formFields.get("connectionId")}
                                </Text>
                            ),
                        },
                    ]}
                />
                <AccessGroupDropdownAndConnectorView
                    setSaveButtonEnabled={props.setSaveButtonEnabled}
                    disabled={Boolean(store.isEditMode)}
                    accessGroupId={
                        store.formFields.get("accessGroupId") as number
                    }
                    errorMessage={store.formFields.error("accessGroupId") ?? ""}
                />
                {props.inputFields.length > 0 && props.renderDynamicViews()}
                {store.formFields.get("type") === "aktivbo"
                    ? props.renderPublishSettingView()
                    : null}
            </>
        </Form>
    )
})
