import { IAvyChatInputProps } from "src/components/Chat/types/chatInput"
import { IAvyChatThreadProps } from "src/components/Chat/types/chatThread"

export enum EAvyChatVariant {
    Chat = "chat",
    Transcript = "transcript",
}

export type TAvyChatProps =
    | (IAvyChatThreadProps & { variant: EAvyChatVariant.Transcript })
    | (IAvyChatInputProps &
          IAvyChatThreadProps & { variant: EAvyChatVariant.Chat })
