import { IIconProps } from "src/components/icons"

export const Warning = (props: IIconProps) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="WarningRounded">
            <path
                id="Vector"
                d="M2.97976 14.0004H13.0198C14.0464 14.0004 14.6864 12.8871 14.1731 12.0004L9.15309 3.32711C8.63975 2.44044 7.35976 2.44044 6.84642 3.32711L1.82642 12.0004C1.31309 12.8871 1.95309 14.0004 2.97976 14.0004ZM7.99975 9.33378C7.63309 9.33378 7.33309 9.03378 7.33309 8.66711V7.33378C7.33309 6.96711 7.63309 6.66711 7.99975 6.66711C8.36642 6.66711 8.66642 6.96711 8.66642 7.33378V8.66711C8.66642 9.03378 8.36642 9.33378 7.99975 9.33378ZM8.66642 12.0004H7.33309V10.6671H8.66642V12.0004Z"
                fill="#E65100"
            />
        </g>
    </svg>
)
