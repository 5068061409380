import { t } from "@lingui/macro"
import { CircularProgress, Stack, styled } from "@mui/material"
import { observer } from "mobx-react"

const Wrap = styled(Stack)(({ theme }) => ({
    lineHeight: 2,
    color: theme.palette.grey[700],
}))

export const PreselectedSegmentsLoader = observer(() => {
    return (
        <Wrap
            direction="row"
            alignItems="center"
            justifyContent="space-between"
        >
            <span>{t`segment-picker-field.fetching-segments`}</span>
            <CircularProgress size={20} color="inherit" />
        </Wrap>
    )
})
