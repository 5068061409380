import { Box, styled } from "@mui/material"

import {
    IModalProps,
    IOverlayProps,
    IPreviewModalProps,
} from "src/fragments/modal"
import { isMobile } from "src/lib/mobile"

const verticalSlideAnimation = (closing: boolean) =>
    closing
        ? "up-slide-out 550ms cubic-bezier(0.23, 1, 0.32, 1)"
        : "up-slide-in 550ms cubic-bezier(0.23, 1, 0.32, 1)"

const horizontalSlideAnimation = (closing: boolean) =>
    closing
        ? "right-slide-out 550ms cubic-bezier(0.23, 1, 0.32, 1)"
        : "right-slide-in 550ms cubic-bezier(0.23, 1, 0.32, 1)"

const AvyModalOverlay = styled(Box, {
    shouldForwardProp: () => true,
})<IOverlayProps>(({ closing, theme, variant }) => ({
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    alignItems: variant === "right-slide-in" ? "end" : "center",
    justifyContent: variant === "right-slide-in" ? "end" : "center",
    background: "rgba(8, 8, 8, 0.32)",
    zIndex: theme.zIndex.modal,
    animation: closing
        ? "fade-out 550ms cubic-bezier(0.23, 1, 0.32, 1)"
        : "fade-in 550ms cubic-bezier(0.23, 1, 0.32, 1)",
}))

const AvyPreviewModalOverlay = styled(Box, {
    shouldForwardProp: () => true,
})<{ closing: boolean }>(({ theme }) => ({
    position: "absolute",
    top: 0,
    right: 0,
    width: isMobile ? "100%" : "30%",
    minWidth: isMobile ? 0 : "768px",
    height: "7vh",
    display: "none",
    background: "rgba(8, 8, 8, 0.32)",
    zIndex: theme.zIndex.modal + 1,
    [theme.breakpoints.down("lg")]: {
        display: "flex",
    },
}))

const AvyModal = styled(Box, {
    shouldForwardProp: () => true,
})<IModalProps>(({ closing, variant, theme }) => ({
    background: "#fff",
    overflow: "auto",
    zIndex: theme.zIndex.modal,
    boxShadow: "0 8px 16px rgb(8 8 8 / 24%)",
    ...(variant === "right-slide-in"
        ? {
              height: "100vh",
              position: "relative",
              boxSizing: "border-box",
              animation: horizontalSlideAnimation(closing),
              [theme.breakpoints.down("md")]: {
                  width: "100%",
                  inset: 0,
                  padding: isMobile ? "1rem 1rem 4.375rem" : "1rem",
              },
              [theme.breakpoints.up("md")]: {
                  width: "40%",
                  minWidth: "768px",
                  right: 0,
                  padding: "2rem",
              },
          }
        : {}),
    ...(variant === "slide-up-w995"
        ? {
              transform: "translateY(0)",
              animation: verticalSlideAnimation(closing),
              [theme.breakpoints.down("md")]: {
                  position: "relative",
                  inset: 0,
              },
              [theme.breakpoints.up("md")]: {
                  borderRadius: theme.shape.borderRadius,
                  maxWidth: "995px",
                  width: "100%",
                  height: "90vh",
              },
          }
        : {}),
    ...(variant === "slide-up-w600"
        ? {
              transform: "translateY(0)",
              animation: verticalSlideAnimation(closing),
              borderRadius: theme.shape.borderRadius,
              width: "100%",
              maxHeight: "90vh",
              [theme.breakpoints.down("md")]: {
                  maxWidth: "90vw",
              },
              [theme.breakpoints.up("md")]: {
                  maxWidth: "600px",
              },
          }
        : {}),
}))

const AvyPreviewModal = styled(Box, {
    shouldForwardProp: () => true,
})<IPreviewModalProps>(({ previewClosing, theme }) => ({
    background: "#fff",
    overflow: "auto",
    boxShadow: "0 8px 16px rgb(8 8 8 / 24%)",
    borderRadius: "16px 0 0 0",
    [theme.breakpoints.up("lg")]: {
        height: "98vh",
        zIndex: theme.zIndex.modal,
        boxSizing: "border-box",
        minWidth: "450px",
        animation: horizontalSlideAnimation(previewClosing),
        position: "relative",
        top: "2vh",
    },
    [theme.breakpoints.down("lg")]: {
        height: "93vh",
        zIndex: theme.zIndex.modal + 1,
        width: isMobile ? "100%" : "30%",
        boxSizing: "border-box",
        minWidth: isMobile ? 0 : "768px",
        animation: verticalSlideAnimation(previewClosing),
        position: "absolute",
        right: 0,
        bottom: 0,
    },
}))

export { AvyModal, AvyModalOverlay, AvyPreviewModal, AvyPreviewModalOverlay }
