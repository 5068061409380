/* eslint-disable import/no-relative-parent-imports */
import { t } from "@lingui/macro"

import { IFormFields } from "../store"

import { emailRegex } from "src/lib/urlLinksHandler"

import { FormFields } from "src/lib/form-fields"

export const handleEmailFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    formFields: FormFields<IFormFields>,
) => {
    formFields.set("email", Array.from([event.target.value]))
    if (!emailRegex.test(event.target.value)) {
        formFields.setError("email", t`error.invalidEmail`)
    }
}
