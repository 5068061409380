import { observer } from "mobx-react"
import { t } from "@lingui/macro"
import { useCallback, useMemo } from "react"
import {
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
} from "@mui/material"

import { InvoicesStore } from "."

import { useStore } from "src/store/lib/useStore"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"

export const StatusFilter = observer(() => {
    const store = useStore(InvoicesStore)

    const statuses: Record<string, string> = useMemo(
        () => ({
            Cancelled: t`invoices-view.invoice-status.cancelled`,
            Handled: t`invoices-view.invoice-status.handled`,
            Paid: t`invoices-view.invoice-status.paid`,
            Pending: t`invoices-view.invoice-status.pending`,
            Unpaid: t`invoices-view.invoice-status.unpaid`,
        }),
        [],
    )

    const handleStatusFilterChange = useCallback(
        (event: SelectChangeEvent<typeof store.statusFilter>) => {
            const filters =
                typeof event.target.value === "string"
                    ? event.target.value.split(",")
                    : event.target.value

            filters.length > 0 &&
                trackModuleEvent("Invoices | Filter on status", {
                    [MixpanelProperties.SegmentID]: store.segments,
                    [MixpanelProperties.InvoiceStatus]: filters,
                })

            return store.setStatusFilter(filters)
        },
        [store],
    )

    return (
        <FormControl
            size="small"
            sx={{
                minWidth: "180px",
                width: "auto",
            }}
        >
            <InputLabel id="status-filter-label">{t`invoices-view.status-filter-label`}</InputLabel>
            <Select
                labelId="status-filter-label"
                id="status-filter"
                multiple
                value={store.statusFilter}
                onChange={handleStatusFilterChange}
                input={
                    <OutlinedInput
                        label={t`invoices-view.status-filter-label`}
                    />
                }
                renderValue={(selected) =>
                    selected.map((status) => statuses[status]).join(", ")
                }
            >
                {Object.keys(statuses).map((status) => (
                    <MenuItem key={status} value={status}>
                        <Checkbox
                            checked={store.statusFilter.indexOf(status) > -1}
                            sx={{ padding: ".25em .5rem .25rem .25rem" }}
                        />
                        <ListItemText primary={statuses[status]} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
})
