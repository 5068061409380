/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { avy_api_pkg_embed_EmbedCategory } from "../models/avy_api_pkg_embed_EmbedCategory"
import type { avy_api_pkg_embed_Integration } from "../models/avy_api_pkg_embed_Integration"
import type { avy_api_pkg_embed_IntegrationCreateUpdate } from "../models/avy_api_pkg_embed_IntegrationCreateUpdate"
import type { avy_api_pkg_embed_Partner } from "../models/avy_api_pkg_embed_Partner"
import type { avy_api_pkg_embed_PartnerCreateUpdate } from "../models/avy_api_pkg_embed_PartnerCreateUpdate"
import type { embed_GenerateTokenResponse } from "../models/embed_GenerateTokenResponse"
import type { embed_GetIntegrationsListRequest } from "../models/embed_GetIntegrationsListRequest"
import type { embed_GetIntegrationsListResponse } from "../models/embed_GetIntegrationsListResponse"
import type { embed_PublishIntegrationRequest } from "../models/embed_PublishIntegrationRequest"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class EmbedAdminService {
    /**
     * Get categories
     * Retrieves a list of all available embed categories.
     * @returns avy_api_pkg_embed_EmbedCategory OK
     * @throws ApiError
     */
    public static getV1AdminEmbedCategory(): CancelablePromise<
        Array<avy_api_pkg_embed_EmbedCategory>
    > {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/embed/category",
        })
    }

    /**
     * Get integrations
     * Retrieves a list of all available integrations.
     * @returns avy_api_pkg_embed_Integration OK
     * @throws ApiError
     */
    public static getV1AdminEmbedIntegration(): CancelablePromise<
        Array<avy_api_pkg_embed_Integration>
    > {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/embed/integration",
        })
    }

    /**
     * Create integration
     * Creates an embed integration.
     * @returns avy_api_pkg_embed_Integration OK
     * @throws ApiError
     */
    public static postV1AdminEmbedIntegration({
        request,
    }: {
        /** Integration data. **/
        request: avy_api_pkg_embed_IntegrationCreateUpdate
    }): CancelablePromise<avy_api_pkg_embed_Integration> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/embed/integration",
            body: request,
        })
    }

    /**
     * Get integrations
     * Retrieves a list of all available integrations.
     * @returns embed_GetIntegrationsListResponse OK
     * @throws ApiError
     */
    public static postV1AdminEmbedIntegrationList({
        request,
    }: {
        /** Filter data **/
        request: embed_GetIntegrationsListRequest
    }): CancelablePromise<embed_GetIntegrationsListResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/embed/integration/list",
            body: request,
        })
    }

    /**
     * Get integration
     * Retrieves a single integration.
     * @returns avy_api_pkg_embed_Integration OK
     * @throws ApiError
     */
    public static getV1AdminEmbedIntegration1({
        integrationId,
    }: {
        /** Integration ID **/
        integrationId: number
    }): CancelablePromise<avy_api_pkg_embed_Integration> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/embed/integration/{integration_id}",
            path: {
                integration_id: integrationId,
            },
        })
    }

    /**
     * Update integration
     * Updates an integration.
     * @returns any OK
     * @throws ApiError
     */
    public static putV1AdminEmbedIntegration({
        integrationId,
        request,
    }: {
        /** Integration ID **/
        integrationId: number
        /** Integration data. **/
        request: avy_api_pkg_embed_IntegrationCreateUpdate
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/embed/integration/{integration_id}",
            path: {
                integration_id: integrationId,
            },
            body: request,
        })
    }

    /**
     * Delete integration
     * Deletes an integration.
     * @returns any OK
     * @throws ApiError
     */
    public static deleteV1AdminEmbedIntegration({
        integrationId,
    }: {
        /** Integration ID **/
        integrationId: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/embed/integration/{integration_id}",
            path: {
                integration_id: integrationId,
            },
        })
    }

    /**
     * Publish integration
     * Publish (unpublish) an embedded integration to segments
     * @returns any OK
     * @throws ApiError
     */
    public static putV1AdminEmbedIntegrationPublish({
        integrationId,
        request,
    }: {
        /** ID of integration **/
        integrationId: number
        /** Request body to set where integration is published **/
        request: embed_PublishIntegrationRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/embed/integration/{integration_id}/publish",
            path: {
                integration_id: integrationId,
            },
            body: request,
        })
    }

    /**
     * Get partner
     * Retrieves a list of all available embed partners.
     * @returns avy_api_pkg_embed_Partner OK
     * @throws ApiError
     */
    public static getV1AdminEmbedPartner(): CancelablePromise<
        Array<avy_api_pkg_embed_Partner>
    > {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/embed/partner",
        })
    }

    /**
     * Create partner
     * Creates an embed partner.
     * @returns avy_api_pkg_embed_Partner OK
     * @throws ApiError
     */
    public static postV1AdminEmbedPartner({
        request,
    }: {
        /** Partner data. **/
        request: avy_api_pkg_embed_PartnerCreateUpdate
    }): CancelablePromise<avy_api_pkg_embed_Partner> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/embed/partner",
            body: request,
        })
    }

    /**
     * Get partner
     * Retrieves a single partner.
     * @returns avy_api_pkg_embed_Partner OK
     * @throws ApiError
     */
    public static getV1AdminEmbedPartner1({
        partnerId,
    }: {
        /** Partner ID **/
        partnerId: number
    }): CancelablePromise<avy_api_pkg_embed_Partner> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/embed/partner/{partner_id}",
            path: {
                partner_id: partnerId,
            },
        })
    }

    /**
     * Update partner
     * Updates a partner.
     * @returns any OK
     * @throws ApiError
     */
    public static putV1AdminEmbedPartner({
        partnerId,
        request,
    }: {
        /** Partner ID **/
        partnerId: number
        /** Partner data. **/
        request: avy_api_pkg_embed_PartnerCreateUpdate
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/embed/partner/{partner_id}",
            path: {
                partner_id: partnerId,
            },
            body: request,
        })
    }

    /**
     * Delete partner
     * Deletes a partner.
     * @returns any OK
     * @throws ApiError
     */
    public static deleteV1AdminEmbedPartner({
        partnerId,
    }: {
        /** Partner ID **/
        partnerId: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/embed/partner/{partner_id}",
            path: {
                partner_id: partnerId,
            },
        })
    }

    /**
     * Generate partner token
     * Generates an API token for the specified partner
     * @returns embed_GenerateTokenResponse OK
     * @throws ApiError
     */
    public static postV1AdminEmbedPartnerCreateToken({
        partnerId,
    }: {
        /** Partner ID **/
        partnerId: number
    }): CancelablePromise<embed_GenerateTokenResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/embed/partner/{partner_id}/create-token",
            path: {
                partner_id: partnerId,
            },
        })
    }
}
