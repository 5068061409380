import { t } from "@lingui/macro"

import sv from "date-fns/locale/sv"
import enGB from "date-fns/locale/en-GB"
import type { Locale as DateFnsLocale } from "date-fns"

import gbFlag from "src/assets/images/gb-flag.png"
import svFlag from "src/assets/images/sv-flag.png"

export enum Locale {
    English = "en",
    Swedish = "sv",
}

export interface ILocale {
    key: Locale
    icon: string
    dateFnsLocale: DateFnsLocale
    name: string
}

export const Locales: { [k in Locale]: ILocale } = {
    [Locale.English]: {
        key: Locale.English,
        icon: gbFlag,
        dateFnsLocale: enGB,
        // Lazily evaluate as `t` cannot be called a import time.
        get name() {
            return t`global.english`
        },
    },
    [Locale.Swedish]: {
        key: Locale.Swedish,
        icon: svFlag,
        dateFnsLocale: sv,
        // Lazily evaluate as `t` cannot be called a import time.
        get name() {
            return t`global.swedish`
        },
    },
}
