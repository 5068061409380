import { t } from "@lingui/macro"
import { DatePicker } from "@mui/lab"
import { DatePickerView } from "@mui/lab/DatePicker/shared"
import { Stack, TextField, TextFieldProps } from "@mui/material"
import { SxProps } from "@mui/system"
import { isValid, max, min } from "date-fns"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { IDateRangeValue } from "src/types/date_range_picker"

interface IDatePickerRangeProps {
    minDate?: Date
    maxDate?: Date
    views: readonly DatePickerView[]
    inputFormat: string
    values: IDateRangeValue
    onChange: (value: IDateRangeValue) => void
    sx?: SxProps
    // We probably shouldn't pass down sx props like this. We need to do this
    // here because we need the inputs' backgounds to be white in some cases.
    // There's a problem through-out the app that inputs should have a white
    // background in some places and grey in some. We need a general way to
    // handle that in the future.
    inputSx?: SxProps
}

export const DatePickerRangeField = observer((props: IDatePickerRangeProps) => {
    const handleOnChange = useCallback(
        (value: IDateRangeValue) => {
            // Coerce date range value to be within constraints if there are any.
            if (props.minDate != null) {
                value.from = max([value.from ?? props.minDate, props.minDate])
                value.to = max([
                    value.to ?? props.maxDate ?? new Date(),
                    props.minDate,
                ])
            }
            if (props.maxDate != null) {
                value.from = min([
                    value.from ?? props.minDate ?? new Date(),
                    props.maxDate,
                ])
                value.to = min([value.to ?? props.maxDate, props.maxDate])
            }

            value.from = isValid(value.from) ? value.from : null
            value.to = isValid(value.to) ? value.to : null

            props.onChange(value)
        },
        [props],
    )

    return (
        <Stack direction="row" spacing={1.25} sx={props.sx}>
            <DatePicker
                views={props.views}
                label={t`datepicker-range-component.from`}
                minDate={props.minDate}
                maxDate={props.values.to ?? props.maxDate}
                inputFormat={props.inputFormat}
                value={props.values.from}
                onChange={(value) =>
                    handleOnChange({ from: value, to: props.values.to })
                }
                renderInput={(textFieldProps: TextFieldProps) => (
                    <TextField
                        size="small"
                        {...textFieldProps}
                        sx={props.inputSx}
                        inputProps={{
                            ...textFieldProps.inputProps,
                            readOnly: true,
                        }}
                    />
                )}
            />
            <DatePicker
                views={props.views}
                label={t`datepicker-range-component.to`}
                minDate={props.values.from ?? props.minDate}
                maxDate={props.maxDate}
                inputFormat={props.inputFormat}
                value={props.values.to}
                onChange={(value) =>
                    handleOnChange({ from: props.values.from, to: value })
                }
                renderInput={(textFieldProps: TextFieldProps) => (
                    <TextField
                        size="small"
                        {...textFieldProps}
                        sx={props.inputSx}
                        inputProps={{
                            ...textFieldProps.inputProps,
                            readOnly: true,
                        }}
                    />
                )}
            />
        </Stack>
    )
})
