import { makeAutoObservable } from "mobx"

import { IModal, IPreviewModal } from "src/types/modal/modal"
import { IModalOptions } from "src/types/modal/modal-options"

export class ModalsStore {
    readonly CLOSING_ANIMATION_MS = 500

    private _active: IModal[] = []
    private _previewActive: IPreviewModal[] = []

    get active() {
        return this._active
    }
    get previewActive() {
        return this._previewActive
    }

    constructor() {
        makeAutoObservable(this)
    }

    open(Component: () => JSX.Element, options?: IModalOptions) {
        const modal: IModal = {
            id: Math.random().toString(),
            isClosing: false,
            Component,
            options: {
                variant: options?.variant ?? "right-slide-in",
                hasPreview: options?.hasPreview ?? false,
            },
        }
        if (
            modal.options.variant === "right-slide-in" &&
            this._active.length > 0
        ) {
            return
        } else {
            this._active.push(modal)
        }

        return () => {
            this.close(modal.id)
        }
    }

    close(id: string) {
        this.markModalAsClosing(id)
        setTimeout(() => this.removeModal(id), this.CLOSING_ANIMATION_MS)
    }

    pop() {
        const modals = this.nonClosingActive()
        if (modals.length > 0) {
            const last = modals[modals.length - 1]
            this.close(last.id)
        }
    }

    addConfirmCondition(fn: () => boolean) {
        const modal = this.topMostModal()
        if (modal != null) {
            modal.confirmOnOverlayClick = fn
        }
    }

    private nonClosingActive() {
        return this._active.filter((modal) => !modal.isClosing)
    }

    private topMostModal(): IModal | null {
        const modals = this.nonClosingActive()
        if (modals.length > 0) {
            const last = modals[modals.length - 1]
            return last
        }
        return null
    }

    private markModalAsClosing(id: string) {
        const modal = this._active.find((modal) => modal.id === id)
        if (modal != null) {
            modal.isClosing = true
        }
    }

    private removeModal(id: string) {
        this._active = this._active.filter((modal) => modal.id !== id)
    }

    togglePreview(Component: () => JSX.Element) {
        const modal: IPreviewModal = {
            id: Math.random().toString(),
            Component,
            isPreviewClosing: false,
        }

        // only 1 preview modal can be active at a time
        this._previewActive.length === 0
            ? this._previewActive.push(modal)
            : this.closePreview(this._previewActive[0].id)
    }

    popPreview() {
        if (this._previewActive.length > 0) {
            this.closePreview(this._previewActive[0].id)
        }
    }

    private closePreview(id: string) {
        this.markPreviewModalAsClosing(id)
        setTimeout(() => this.removePreviewModal(id), this.CLOSING_ANIMATION_MS)
    }

    private markPreviewModalAsClosing(id: string) {
        const modal = this._previewActive.find((modal) => modal.id === id)
        if (modal != null) {
            modal.isPreviewClosing = true
        }
    }

    private removePreviewModal(id: string) {
        this._previewActive = this._previewActive.filter(
            (modal) => modal.id !== id,
        )
    }
}
