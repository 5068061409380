export const truncatePath = (path: string) => {
    const pathSeparator = " / "
    const ellipsis = "…"

    if (path.indexOf(pathSeparator) === -1) {
        return path
    }

    const parts = path.split(pathSeparator)
    if (parts.length > 2) {
        return `${parts[0]}${pathSeparator}${ellipsis}${pathSeparator}${
            parts[parts.length - 1]
        }`
    } else {
        return path
    }
}
