import { t } from "@lingui/macro"
import { CircularProgress, TextField } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useMemo } from "react"

import { Autocomplete } from "./styled"

import { DEFAULT_ACCESS_GROUP } from "src/config"

import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { SessionStore } from "src/store/session"

export const HeadAccessGroupSelect = observer(() => {
    const gstore = useStore(GlobalStore)

    const accessGroups = useMemo(
        () => [DEFAULT_ACCESS_GROUP, ...gstore.session.accessGroups],
        [gstore],
    )
    const accessGroupLabels = useMemo(() => {
        return Object.fromEntries(accessGroups.map((ag) => [ag.id, ag.name]))
    }, [accessGroups])

    const hasNoAccessGroups = gstore.session.accessGroups.length === 0
    const loading = gstore.loading.is(
        SessionStore.LoadingKeys["reload-access-group-segments"],
    )
    const disabled = hasNoAccessGroups || loading

    const handleSelectAccessGroup = useCallback(
        async (_: unknown, value: unknown) => {
            if (value == null) {
                return
            }

            const accessGroup = accessGroups.find(
                (ag) => ag.id === Number(value),
            )

            if (accessGroup != null) {
                await gstore.session.setSelectedAccessGroup(accessGroup)
            } else {
                // TODO: do this in a better way
                throw new Error("accessgroup could not be selected.")
            }
        },
        [accessGroups, gstore],
    )

    const value =
        gstore.session.accessGroup.id != null
            ? gstore.session.accessGroup.id
            : DEFAULT_ACCESS_GROUP.id
    return (
        <Autocomplete
            options={accessGroups.map((x) => x.id)}
            size="small"
            onChange={handleSelectAccessGroup}
            value={value}
            renderInput={(props) => <TextField {...props} />}
            getOptionLabel={(option) => accessGroupLabels[String(option)]}
            renderOption={(props, option) => (
                <li {...props} key={Number(option)}>
                    {accessGroupLabels[String(option)]}
                </li>
            )}
            title={t`head.access-group-label`}
            placeholder={t`head.access-group-label`}
            popupIcon={
                loading ? (
                    <CircularProgress size={16} color="inherit" />
                ) : undefined
            }
            disabled={disabled}
            autoHighlight
            blurOnSelect
            disableClearable
        />
    )
})
