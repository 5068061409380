import { t } from "@lingui/macro"
import { Button, CircularProgress, styled, useTheme } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useEffect } from "react"

import { InvoicesStore, StatusFilter } from "."

import { DatePickerRangeField } from "src/components/DatePickerRangeField"
import { InvoicesList } from "src/components/InvoicesList"
import { ListPage } from "src/components/ListPage"
import { IPageFilterProps } from "src/components/PageFilter"
import { SegmentPickerButton } from "src/components/SegmentPickerButton"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { IDateRangeValue } from "src/types/date_range_picker"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"

const ActionButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== "loading",
})<{ loading?: boolean }>(({ theme, loading }) => ({
    background: theme.palette.common.white,
    position: "relative",
    "& .MuiCircularProgress-root": {
        display: loading === true ? "block" : "none",
        position: "absolute",
    },
    "& span:not(.MuiCircularProgress-root)": {
        visibility: loading === true ? "hidden" : "visible",
    },
}))

export const Invoices = observer(() => {
    const store = useStore(InvoicesStore)
    const globalStore = useStore(GlobalStore)
    const theme = useTheme()
    const tableIsLoading = store.invoices.meta.initialized === false

    const header = {
        header: t`invoices-view.header`,
        breadcrumbs: [t`invoices-view.header`],
    }

    const handleSearchChange = useCallback(
        (query: string) => store.invoices.loadSearch(query),
        [store],
    )

    const handleSegmentChange = useCallback(
        (segments: number[]) => store.loadSegments(segments),
        [store],
    )

    const handleDownloadSelectedInvoicesAsCsv = useCallback(() => {
        trackModuleEvent("Invoices | Export all", {
            [MixpanelProperties.UserID]: globalStore.session.user?.adminId,
            [MixpanelProperties.InvoiceStatus]: store.statusFilter,
            [MixpanelProperties.SegmentID]: store.segments,
        })
        return store.export()
    }, [store, globalStore.session.user])

    const handleDateRangeChange = useCallback(
        (value: IDateRangeValue) => {
            value.from !== null && trackModuleEvent("Invoices | From date")
            value.to !== null && trackModuleEvent("Invoices | To date")

            return store.setDateRangeFilter(value)
        },
        [store],
    )

    const filter: IPageFilterProps = {
        query: store.invoices.meta.search ?? "",
        onQueryChange: handleSearchChange,
        actions: (
            <>
                <StatusFilter />
                <DatePickerRangeField
                    minDate={new Date(2015, 0, 0)}
                    maxDate={new Date()}
                    views={["year", "month"]}
                    inputFormat={"yyyy-MM"}
                    values={store.dateRangeFilter}
                    onChange={handleDateRangeChange}
                    sx={{
                        width: {
                            xs: "100%",
                            sm: "260px",
                        },
                    }}
                    inputSx={{ background: theme.palette.common.white }}
                />
                <SegmentPickerButton
                    value={store.segments}
                    onChange={handleSegmentChange}
                    allowedSegmentTypes={["propertyowner", "legalentity"]}
                />
                <ActionButton
                    color="error"
                    variant="outlined"
                    type="button"
                    onClick={handleDownloadSelectedInvoicesAsCsv}
                    disabled={
                        !store.canDownloadInvoiceCsv ||
                        globalStore.loading.is(InvoicesStore.LoadingKeys.export)
                    }
                    loading={globalStore.loading.is(
                        InvoicesStore.LoadingKeys.export,
                    )}
                    sx={{
                        width: {
                            xs: "100%",
                            sm: "auto",
                        },
                    }}
                >
                    <span>{t`invoices-view.export-button`}</span>
                    <CircularProgress color="inherit" size={20} />
                </ActionButton>
            </>
        ),
        searchFieldPlaceholder: t`invoices-view.search-field-placeholder`,
    }

    useEffect(() => {
        ;(async () => {
            await store.init()
        })()

        return () => {
            store.dispose()
        }
    }, [store, globalStore.session.segments])

    return (
        <ListPage header={header} filter={filter} loading={tableIsLoading}>
            <InvoicesList invoices={store.invoices} />
        </ListPage>
    )
})
