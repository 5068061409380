import { t } from "@lingui/macro"
import {
    InputLabel,
    Select,
    Stack,
    TextField,
    MenuItem,
    FormControl,
} from "@mui/material"
import { observer } from "mobx-react"

import { ConnectorDropdown } from "src/components/ConnectorDropdown"

import { FormFieldSet } from "src/components/FormFieldSet"
import { useUniqueId } from "src/lib/unique-id"
import { contactDetailsEditableOptions } from "src/modals/feature-configuration-detail/constants/contactDetailsEditable"
import { FeatureConfigurationDetailStore } from "src/modals/feature-configuration-detail/store"
import { useStore } from "src/store/lib/useStore"

export const OtherSection = observer(() => {
    const store = useStore(FeatureConfigurationDetailStore)
    const contactDetailsEditableId = useUniqueId()

    if (
        store.form.get("contactDetailsEditable").toString() === "" ||
        store.form.get("contactDetailsEditable").toString() === "true"
    ) {
        store.form.set("editContactDetails", "")
    }

    return (
        <>
            <FormFieldSet
                header={t`feature-configuration-detail-modal.other-section.issue-reporting-header`}
            >
                <Stack spacing={2}>
                    <TextField
                        label={t`feature-configuration-detail-modal.other-section.external-link-label`}
                        value={store.form.get("issueReportingExternalLink")}
                        onChange={store.form.setter(
                            "issueReportingExternalLink",
                        )}
                    />

                    <TextField
                        fullWidth
                        label={t`feature-configuration-detail-modal.other-section.receiving-email-name-label`}
                        value={store.form.get("issueReportingEmailToName")}
                        onChange={store.form.setter(
                            "issueReportingEmailToName",
                        )}
                    />

                    <TextField
                        label={t`feature-configuration-detail-modal.other-section.receiving-email-address-label`}
                        value={store.form.get("issueReportingEmailToAddress")}
                        onChange={store.form.setter(
                            "issueReportingEmailToAddress",
                        )}
                    />
                </Stack>
            </FormFieldSet>
            <FormFieldSet
                header={t`feature-configuration-detail-modal.other-section.payments-header`}
            >
                <TextField
                    label={t`feature-configuration-detail-modal.other-section.payments-label`}
                    value={store.form.get("paymentDueDateBlocker")}
                    onChange={store.form.setter("paymentDueDateBlocker")}
                />
            </FormFieldSet>
            <FormFieldSet
                header={t`feature-configuration-detail-modal.other-section.dash-position-header`}
            >
                <Stack spacing={2}>
                    <TextField
                        label={t`feature-configuration-detail-modal.other-section.dash-position-external-label`}
                        value={store.form.get("DashPositionExternalAccess")}
                        onChange={store.form.setter(
                            "DashPositionExternalAccess",
                        )}
                    />
                    <TextField
                        label={t`feature-configuration-detail-modal.other-section.dash-position-amido-label`}
                        value={store.form.get("DashPositionAmido")}
                        onChange={store.form.setter("DashPositionAmido")}
                    />
                </Stack>
            </FormFieldSet>
            <FormFieldSet
                header={t`feature-configuration-detail-modal.other-section.connector-header`}
            >
                <ConnectorDropdown
                    connectors={store.allIntegrationConnectors ?? []}
                    selectedValue={String(
                        store.form.get("terminationConnectorId"),
                    )}
                    onSelectionChange={store.setTerminationConnectorId}
                    errorMessage={
                        store.form.error("terminationConnectorId") as string
                    }
                    disabled={false}
                />
            </FormFieldSet>
            <FormFieldSet
                header={t`feature-configuration-detail-modal.other-section.contact-details-header`}
            >
                <Stack spacing={2}>
                    <FormControl fullWidth>
                        <InputLabel
                            id={contactDetailsEditableId}
                        >{t`feature-configuration-detail-modal.other-section.editable-contact-details`}</InputLabel>
                        <Select
                            labelId={contactDetailsEditableId}
                            value={store.form
                                .get("contactDetailsEditable")
                                .toString()}
                            onChange={store.form.setter(
                                "contactDetailsEditable",
                            )}
                            label={t`feature-configuration-detail-modal.other-section.editable-contact-details`}
                        >
                            {Object.entries(contactDetailsEditableOptions).map(
                                ([key, value]) => (
                                    <MenuItem
                                        key={key}
                                        value={value.toString()}
                                    >
                                        {key}
                                    </MenuItem>
                                ),
                            )}
                        </Select>
                    </FormControl>
                    {store.form.get("contactDetailsEditable").toString() ===
                        "false" && (
                        <FormControl fullWidth>
                            <TextField
                                label={t`feature-configuration-detail-modal.other-section.edit-contact-details-label`}
                                value={store.form.get("editContactDetails")}
                                onChange={store.form.setter(
                                    "editContactDetails",
                                )}
                                variant="outlined"
                                multiline
                                minRows={3}
                            />
                        </FormControl>
                    )}
                </Stack>
            </FormFieldSet>
        </>
    )
})
