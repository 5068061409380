import { observer } from "mobx-react"

import { FormControlLabel, Stack, Switch } from "@mui/material"

import { FormPanel } from "src/components/FormPanel"

import { IPurchaseActiveFieldsProps } from "src/modals/embedded-integration-v2/types/purchaseActiveFields"

export const EmbeddedIntegrationActivePurchaseSection = observer(
    ({
        isRequired,
        headerLabel,
        handleSwitchChange,
        children,
    }: IPurchaseActiveFieldsProps) => (
        <FormPanel
            sections={[
                {
                    header: headerLabel,
                    content: (
                        <>
                            <FormControlLabel
                                data-testid="switch-button"
                                checked={isRequired}
                                onChange={(
                                    event: React.ChangeEvent<unknown>,
                                    value: boolean,
                                ) => handleSwitchChange(event, value)}
                                control={<Switch color="success" />}
                                label={""}
                                sx={{ ml: 1 }}
                            />
                            {isRequired ? (
                                <Stack spacing={2}>{children}</Stack>
                            ) : null}
                        </>
                    ),
                },
            ]}
        />
    ),
)
