import { t } from "@lingui/macro"
import { makeAutoObservable } from "mobx"
import React from "react"

import { CommunityAdminService } from "src/api"
import { CustomDataHelper } from "src/api/_custom/services/DataHelper"
import { community_BlockedAuthorWithDetails } from "src/api/models/community_BlockedAuthorWithDetails"
import { Channel } from "src/channel"
import { loads } from "src/channel/utils"
import { DEFAULT_ACCESS_GROUP } from "src/config"

import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"
import { reportSuccess, reportUnhandledApiError } from "src/lib/report"

export class CommunityBlockedTenantsStore {
    //#region initializations
    static Context = React.createContext<CommunityBlockedTenantsStore | null>(
        null,
    )
    static LoadingKeys = createLoadingKeys("init", "loading")
    _accessGroupId: number = DEFAULT_ACCESS_GROUP.id
    _segments: number[] = []
    private repositoryUpdatesListenerDisposer?: () => void

    constructor() {
        makeAutoObservable(this)
    }

    blockedTenants = new Pagination(
        async (params) => {
            const response =
                await CommunityAdminService.postV1AdminCommunityBlockedAuthors({
                    request: {
                        page_number: params.page,
                        page_size: params.pageSize,
                        ...CustomDataHelper.PrepareVisibilityData(this),
                    },
                })

            const items = (response.authors ?? []).map(
                (author: community_BlockedAuthorWithDetails) => ({
                    id: `${author.author_id}_${author.community_id}`,
                    author_name: author.author_name ?? "",
                    author_email: author.author_email ?? "",
                    author_phone: author.author_phone ?? "",
                    author_id: author.author_id ?? -1,
                    community_name: author.community_name ?? "",
                    community_id: author.community_id ?? -1,
                    blocked_at: author.blocked_at ?? "",
                }),
            )

            return {
                items,
                count: response.total_count ?? 0,
            }
        },
        {
            loadingKey: CommunityBlockedTenantsStore.LoadingKeys.loading,
        },
    )
    //#endregion

    //#region getters
    get accessGroupId() {
        return this._accessGroupId
    }
    //#endregion

    //#region setters
    private setAccessGroupId(accessGroupId: number) {
        this._accessGroupId = accessGroupId
    }
    //#endregion

    //#region store operations
    @loads(() => CommunityBlockedTenantsStore.LoadingKeys.init)
    async init(accessGroupId: number) {
        this.setAccessGroupId(accessGroupId)
        await this.blockedTenants.loadInitialPage()
        this.listenToRepositoryChanges()
    }

    @loads(() => CommunityBlockedTenantsStore.LoadingKeys.loading)
    async unblockTenant(communityId: number, tenantId: number) {
        try {
            await CommunityAdminService.postV1AdminCommunityUnblock({
                communityId: communityId,
                request: {
                    author_id: tenantId,
                },
            })

            reportSuccess(t`community-blocked-tenants-view-unblock-success`)
            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "community-blocked-tenants",
                    action: "update",
                },
            })
        } catch (error) {
            reportUnhandledApiError(error)
        }
    }

    dispose() {
        this.repositoryUpdatesListenerDisposer?.()
    }

    private listenToRepositoryChanges() {
        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "community-blocked-tenants"
                ) {
                    if (event.payload.action === "update") {
                        await this.blockedTenants.reload()
                    } else {
                        await this.blockedTenants.loadInitialPage()
                    }
                }
            },
        )
    }
    //#endregion
}
