/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { admin_CategoryResponse } from "../models/admin_CategoryResponse"
import type { admin_CreateCategoryRequest } from "../models/admin_CreateCategoryRequest"
import type { admin_GetCategoriesResponse } from "../models/admin_GetCategoriesResponse"
import type { admin_GetCategoryResponse } from "../models/admin_GetCategoryResponse"
import type { admin_GetOrderOutputListResponse } from "../models/admin_GetOrderOutputListResponse"
import type { admin_GetPricePlanListResponse } from "../models/admin_GetPricePlanListResponse"
import type { admin_GetProductTypeListResponse } from "../models/admin_GetProductTypeListResponse"
import type { admin_GetProviderListResponse } from "../models/admin_GetProviderListResponse"
import type { admin_ImprovementOrderListResponse } from "../models/admin_ImprovementOrderListResponse"
import type { admin_OrderListResponse } from "../models/admin_OrderListResponse"
import type { admin_OrderOutputResponse } from "../models/admin_OrderOutputResponse"
import type { admin_ProductListResponse } from "../models/admin_ProductListResponse"
import type { admin_ProductResponse } from "../models/admin_ProductResponse"
import type { admin_ProviderResponse } from "../models/admin_ProviderResponse"
import type { admin_PublishProductRequest } from "../models/admin_PublishProductRequest"
import type { admin_PutPublishProductResponse } from "../models/admin_PutPublishProductResponse"
import type { admin_UpdateCategoryRequest } from "../models/admin_UpdateCategoryRequest"
import type { cataloguedomain_CreateOrderOutput } from "../models/cataloguedomain_CreateOrderOutput"
import type { cataloguedomain_CreateProduct } from "../models/cataloguedomain_CreateProduct"
import type { cataloguedomain_CreateProvider } from "../models/cataloguedomain_CreateProvider"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class ProductCatalogueAdminService {
    /**
     * List categories
     * List available categories for property owner
     * @returns admin_GetCategoriesResponse OK
     * @throws ApiError
     */
    public static getV1AdminCatalogueCategory(): CancelablePromise<admin_GetCategoriesResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/catalogue/category",
        })
    }

    /**
     * Create category
     * Create new category
     * @returns admin_CategoryResponse OK
     * @throws ApiError
     */
    public static postV1AdminCatalogueCategory({
        request,
        lang,
    }: {
        /** Request body to create category **/
        request: admin_CreateCategoryRequest
        /** Language **/
        lang?: string
    }): CancelablePromise<admin_CategoryResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/catalogue/category",
            query: {
                lang: lang,
            },
            body: request,
        })
    }

    /**
     * Get category products
     * Get products published in category
     * @returns admin_GetCategoryResponse OK
     * @throws ApiError
     */
    public static getV1AdminCatalogueCategory1({
        categoryId,
    }: {
        /** ID of category to display **/
        categoryId: number
    }): CancelablePromise<admin_GetCategoryResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/catalogue/category/{category_id}",
            path: {
                category_id: categoryId,
            },
        })
    }

    /**
     * Update category
     * Update category by id
     * @returns admin_CategoryResponse OK
     * @throws ApiError
     */
    public static putV1AdminCatalogueCategory({
        categoryId,
        request,
        lang,
    }: {
        /** ID of category to update **/
        categoryId: number
        /** Request body to update category **/
        request: admin_UpdateCategoryRequest
        /** Language **/
        lang?: string
    }): CancelablePromise<admin_CategoryResponse> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/catalogue/category/{category_id}",
            path: {
                category_id: categoryId,
            },
            query: {
                lang: lang,
            },
            body: request,
        })
    }

    /**
     * Delete category
     * Delete category by id
     * @returns string OK
     * @throws ApiError
     */
    public static deleteV1AdminCatalogueCategory({
        categoryId,
    }: {
        /** ID of category to delete **/
        categoryId: number
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/catalogue/category/{category_id}",
            path: {
                category_id: categoryId,
            },
        })
    }

    /**
     * Get standard improvement order list for admin
     * Get standard improvement orders for admins legal entities
     * @returns admin_ImprovementOrderListResponse OK
     * @throws ApiError
     */
    public static getV1AdminCatalogueImprovementOrder({
        pageNumber = 1,
        pageSize = 10,
        accessGroupId,
    }: {
        /** Pagination - number of page to load **/
        pageNumber?: number
        /** Pagination - size of pages to load **/
        pageSize?: number
        /** Access group ID filter **/
        accessGroupId?: number
    }): CancelablePromise<admin_ImprovementOrderListResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/catalogue/improvement-order",
            query: {
                page_number: pageNumber,
                page_size: pageSize,
                access_group_id: accessGroupId,
            },
        })
    }

    /**
     * Get order export for admin
     * Get standard improvement orders export for admins access groups
     * @returns string OK
     * @throws ApiError
     */
    public static getV1AdminCatalogueImprovementOrderExport(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/catalogue/improvement-order/export",
        })
    }

    /**
     * Get order list for admin
     * Get orders for admins legal entities
     * @returns admin_OrderListResponse OK
     * @throws ApiError
     */
    public static getV1AdminCatalogueOrder({
        pageNumber = 1,
        pageSize = 10,
        accessGroupId,
    }: {
        /** Pagination - number of page to load **/
        pageNumber?: number
        /** Pagination - size of pages to load **/
        pageSize?: number
        /** Access group ID filter **/
        accessGroupId?: number
    }): CancelablePromise<admin_OrderListResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/catalogue/order",
            query: {
                page_number: pageNumber,
                page_size: pageSize,
                access_group_id: accessGroupId,
            },
        })
    }

    /**
     * Get order export for admin
     * Get orders export for admins access groups
     * @returns string OK
     * @throws ApiError
     */
    public static getV1AdminCatalogueOrderExport(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/catalogue/order/export",
        })
    }

    /**
     * @deprecated
     * List price plans
     * List available price plans
     * @returns admin_GetPricePlanListResponse OK
     * @throws ApiError
     */
    public static getV1AdminCataloguePricePlan(): CancelablePromise<admin_GetPricePlanListResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/catalogue/price-plan",
        })
    }

    /**
     * List price plans
     * List available price plans for product type
     * @returns admin_GetPricePlanListResponse OK
     * @throws ApiError
     */
    public static getV1AdminCataloguePricePlan1({
        productType,
    }: {
        /** Product type to fetch the available price plans **/
        productType: string
    }): CancelablePromise<admin_GetPricePlanListResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/catalogue/price-plan/{product_type}",
            path: {
                product_type: productType,
            },
        })
    }

    /**
     * Get product list for admin
     * Get products visible to currently logged in admin
     * @returns admin_ProductListResponse OK
     * @throws ApiError
     */
    public static getV1AdminCatalogueProduct({
        pageNumber = 1,
        pageSize = 10,
        accessGroupId,
    }: {
        /** Pagination - number of page to load **/
        pageNumber?: number
        /** Pagination - size of pages to load **/
        pageSize?: number
        /** Access group ID filter **/
        accessGroupId?: number
    }): CancelablePromise<admin_ProductListResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/catalogue/product",
            query: {
                page_number: pageNumber,
                page_size: pageSize,
                access_group_id: accessGroupId,
            },
        })
    }

    /**
     * Create product
     * Create new product
     * @returns admin_ProductResponse OK
     * @throws ApiError
     */
    public static postV1AdminCatalogueProduct({
        request,
    }: {
        /** Request body to create product **/
        request: cataloguedomain_CreateProduct
    }): CancelablePromise<admin_ProductResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/catalogue/product",
            body: request,
        })
    }

    /**
     * List product types
     * List available product order types
     * @returns admin_GetProductTypeListResponse OK
     * @throws ApiError
     */
    public static getV1AdminCatalogueProductType(): CancelablePromise<admin_GetProductTypeListResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/catalogue/product-type",
        })
    }

    /**
     * Get product
     * Get product by id
     * @returns admin_ProductResponse OK
     * @throws ApiError
     */
    public static getV1AdminCatalogueProduct1({
        productId,
    }: {
        /** ID of product to display **/
        productId: number
    }): CancelablePromise<admin_ProductResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/catalogue/product/{product_id}",
            path: {
                product_id: productId,
            },
        })
    }

    /**
     * Update product
     * Update product by id
     * @returns admin_ProductResponse OK
     * @throws ApiError
     */
    public static putV1AdminCatalogueProduct({
        productId,
        request,
    }: {
        /** ID of product to update **/
        productId: number
        /** Request body to update product **/
        request: cataloguedomain_CreateProduct
    }): CancelablePromise<admin_ProductResponse> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/catalogue/product/{product_id}",
            path: {
                product_id: productId,
            },
            body: request,
        })
    }

    /**
     * Delete product
     * Delete product by id
     * @returns string OK
     * @throws ApiError
     */
    public static deleteV1AdminCatalogueProduct({
        productId,
    }: {
        /** ID of product to delete **/
        productId: number
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/catalogue/product/{product_id}",
            path: {
                product_id: productId,
            },
        })
    }

    /**
     * Clone product
     * Clone product by id
     * @returns admin_ProductResponse OK
     * @throws ApiError
     */
    public static postV1AdminCatalogueProductClone({
        productId,
    }: {
        /** ID of product to clone **/
        productId: number
    }): CancelablePromise<admin_ProductResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/catalogue/product/{product_id}/clone",
            path: {
                product_id: productId,
            },
        })
    }

    /**
     * Publish product
     * Publish product by id
     * @returns admin_PutPublishProductResponse OK
     * @throws ApiError
     */
    public static putV1AdminCatalogueProductPublish({
        productId,
        request,
    }: {
        /** ID of product to update **/
        productId: number
        /** Request body to set where products are published **/
        request: admin_PublishProductRequest
    }): CancelablePromise<admin_PutPublishProductResponse> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/catalogue/product/{product_id}/publish",
            path: {
                product_id: productId,
            },
            body: request,
        })
    }

    /**
     * List providers
     * List available providers for property owner
     * @returns admin_GetProviderListResponse OK
     * @throws ApiError
     */
    public static getV1AdminCatalogueProvider(): CancelablePromise<admin_GetProviderListResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/catalogue/provider",
        })
    }

    /**
     * Create provider
     * Create new provider
     * @returns admin_ProviderResponse OK
     * @throws ApiError
     */
    public static postV1AdminCatalogueProvider({
        request,
    }: {
        /** Request body to create provider **/
        request: cataloguedomain_CreateProvider
    }): CancelablePromise<admin_ProviderResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/catalogue/provider",
            body: request,
        })
    }

    /**
     * Get provider
     * Get provider by id
     * @returns admin_ProviderResponse OK
     * @throws ApiError
     */
    public static getV1AdminCatalogueProvider1({
        providerId,
    }: {
        /** ID of provider to display **/
        providerId: number
    }): CancelablePromise<admin_ProviderResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/catalogue/provider/{provider_id}",
            path: {
                provider_id: providerId,
            },
        })
    }

    /**
     * Update provider
     * Update provider by id
     * @returns admin_ProviderResponse OK
     * @throws ApiError
     */
    public static putV1AdminCatalogueProvider({
        providerId,
        request,
    }: {
        /** ID of provider to update **/
        providerId: number
        /** Request body to update provider **/
        request: cataloguedomain_CreateProvider
    }): CancelablePromise<admin_ProviderResponse> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/catalogue/provider/{provider_id}",
            path: {
                provider_id: providerId,
            },
            body: request,
        })
    }

    /**
     * Delete provider
     * Delete provider by id
     * @returns string OK
     * @throws ApiError
     */
    public static deleteV1AdminCatalogueProvider({
        providerId,
    }: {
        /** ID of provider to delete **/
        providerId: number
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/catalogue/provider/{provider_id}",
            path: {
                provider_id: providerId,
            },
        })
    }

    /**
     * Get order outputs
     * Get order outputs by provider id
     * @returns admin_GetOrderOutputListResponse OK
     * @throws ApiError
     */
    public static getV1AdminCatalogueProviderOrderOutput({
        providerId,
    }: {
        /** ID of provider for order outputs **/
        providerId: number
    }): CancelablePromise<admin_GetOrderOutputListResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/catalogue/provider/{provider_id}/order-output",
            path: {
                provider_id: providerId,
            },
        })
    }

    /**
     * Create order output
     * Create new order output for provider
     * @returns admin_OrderOutputResponse OK
     * @throws ApiError
     */
    public static postV1AdminCatalogueProviderOrderOutput({
        providerId,
        request,
    }: {
        /** ID of parent provider **/
        providerId: number
        /** Request body to create order output **/
        request: cataloguedomain_CreateOrderOutput
    }): CancelablePromise<admin_OrderOutputResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/catalogue/provider/{provider_id}/order-output",
            path: {
                provider_id: providerId,
            },
            body: request,
        })
    }

    /**
     * Update order output
     * Update order output for provider
     * @returns admin_OrderOutputResponse OK
     * @throws ApiError
     */
    public static putV1AdminCatalogueProviderOrderOutput({
        providerId,
        orderOutputId,
        request,
    }: {
        /** ID of parent provider **/
        providerId: number
        /** ID of order output to update **/
        orderOutputId: number
        /** Request body to update order output **/
        request: cataloguedomain_CreateOrderOutput
    }): CancelablePromise<admin_OrderOutputResponse> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/catalogue/provider/{provider_id}/order-output/{order_output_id}",
            path: {
                provider_id: providerId,
                order_output_id: orderOutputId,
            },
            body: request,
        })
    }

    /**
     * Delete order output
     * Delete order output for provider
     * @returns string OK
     * @throws ApiError
     */
    public static deleteV1AdminCatalogueProviderOrderOutput({
        providerId,
        orderOutputId,
    }: {
        /** ID of parent provider **/
        providerId: number
        /** ID of order output to delete **/
        orderOutputId: number
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/catalogue/provider/{provider_id}/order-output/{order_output_id}",
            path: {
                provider_id: providerId,
                order_output_id: orderOutputId,
            },
        })
    }
}
