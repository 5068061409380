import { Button, Grid, styled, TextField } from "@mui/material"

import { SegmentPickerButton } from "src/components/SegmentPickerButton"

export const ActionButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== "loading",
})<{ loading?: boolean }>(({ theme, loading }) => ({
    background: theme.palette.common.white,
    position: "relative",
    marginTop: 0,
    minWidth: 160,
    "& .MuiCircularProgress-root": {
        display: loading === true ? "block" : "none",
        position: "absolute",
    },
    "& span:not(.MuiCircularProgress-root)": {
        visibility: loading === true ? "hidden" : "visible",
    },
}))

export const HeaderContainer = styled(Grid)(() => ({
    marginTop: "24px",
}))

export const ButtonsContainer = styled(Grid)(({ theme }) => ({
    display: "flex",
    alignItems: "stretch",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
        marginTop: "16px",
    },
    [theme.breakpoints.up("md")]: {
        marginTop: "8px",
    },
}))

export const ActionsContainer = styled(Grid)(() => ({
    display: "flex",
    alignItems: "stretch",
    justifyContent: "flex-end",
    marginTop: "8px",
    marginLeft: "16px",
}))

export const SearchField = styled(TextField)(({ theme }) => ({
    minWidth: "320px",
    background: theme.palette.common.white,
    marginTop: "8px",
    [theme.breakpoints.down("sm")]: {
        width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
        width: "550px",
    },
}))

export const SegmentPicker = styled(SegmentPickerButton)(() => ({
    marginTop: "8px",
    minWidth: 160,
}))

export const ButtonContainerLargeScreen = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: "10px",
}))

export const ActionsLargeScreenContainer = styled("div")(() => ({
    marginLeft: "16px",
}))
