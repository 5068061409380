import { t } from "@lingui/macro"
import {
    Button as MUIButton,
    styled,
    useMediaQuery,
    useTheme,
} from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import { FilterOutline24 } from "src/components/icons/FilterOutline24"
import { SegmentPickerModal } from "src/modals/segment-picker"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { SessionStore } from "src/store/session"

interface IProps {
    value: number[]
    onChange: (segmentIds: number[]) => void
    allowedSegmentTypes?: ISegmentForSearch["type"][]
}

const Button = styled(MUIButton)(({ theme }) => ({
    flexShrink: 0,
    background: theme.palette.common.white,
}))

export const SegmentPickerButton = observer((props: IProps) => {
    const gstore = useStore(GlobalStore)
    const theme = useTheme()
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))

    const handleClick = useCallback(() => {
        gstore.modals.open(
            () => (
                <SegmentPickerModal
                    initialSegmentIds={props.value}
                    accessGroupId={gstore.session.accessGroupId}
                    allowedSegmentTypes={props.allowedSegmentTypes}
                    onChange={(segmentIds) => {
                        props.onChange(segmentIds)
                        gstore.modals.pop()
                    }}
                    onClose={() => {
                        gstore.modals.pop()
                    }}
                />
            ),
            { variant: "slide-up-w995" },
        )
    }, [gstore.modals, gstore.session, props])

    return (
        <Button
            variant="outlined"
            color="info"
            endIcon={<FilterOutline24 height={20} width={20} />}
            onClick={handleClick}
            disabled={gstore.loading.is(
                SessionStore.LoadingKeys["reload-access-group-segments"],
            )}
            style={{ width: smallScreen ? "100%" : "auto" }}
        >
            {gstore.session.segments.length === props.value.length
                ? t`segment-picker-button.all-segments-selected`
                : props.value.length === 0
                ? t`segment-picker-button.filter-by-segment`
                : t({
                      id: "segment-picker-button.some-segments-selected",
                      values: { count: props.value.length },
                  })}
        </Button>
    )
})
