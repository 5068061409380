import { request as __request } from "src/api/core/request"

import { CancelablePromise } from "src/api/core/CancelablePromise"
import { OpenAPI } from "src/api/core/OpenAPI"
import { tenant_GetTenantsRequest } from "src/api/models/tenant_GetTenantsRequest"
import { leasecontract_GetLeaseContractsRequest } from "src/api/models/leasecontract_GetLeaseContractsRequest"

export class CustomTenantAdminService {
    /**
     * Get invoices
     * Get invoices filtered by some criteria. Segments being one of them
     * @returns domain_AdminGetInvoicesResponse OK
     * @throws ApiError
     */
    public static postV1AdminTenantsList({
        request,
    }: {
        /** The filter object needed for searching invoices, we can't blindly browse **/
        request: tenant_GetTenantsRequest
    }): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/tenants/list",
            body: request,
            headers: {
                Accept: "text/csv",
            },
        })
    }

    public static postV1AdminLeaseContractList({
        request,
    }: {
        /** The filter object needed for searching invoices, we can't blindly browse **/
        request: leasecontract_GetLeaseContractsRequest
    }): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/lease-contract/list",
            body: request,
            headers: {
                Accept: "text/csv",
            },
        })
    }
}
