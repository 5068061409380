import { t } from "@lingui/macro"
import { Button, styled } from "@mui/material"
import { observer } from "mobx-react"

import { FormDetail } from "./FormDetail"

import { ContactFormChatModal } from "./ContactFormChat"

import { ModalHeader } from "src/components/ModalHeader"

import {
    ContactFormDetailStore,
    ITabId,
} from "src/modals/contact-form-detail/store"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { Tabs } from "src/components/Tabs"
import { useUniqueId } from "src/lib/unique-id"

interface IProps {
    id: number
}

const Container = styled("div")(() => ({
    height: "100%",
    display: "flex",
    flexDirection: "column",
}))

const View = observer((props: IProps) => {
    const globalStore = useStore(GlobalStore)
    const store = useStore(ContactFormDetailStore)

    // Auto select initial tab

    const formId = useUniqueId()

    const isSaveButtonDisabled = globalStore.loading.is(
        ContactFormDetailStore.LoadingKeys.submit,
    )
    const tabsData = [
        {
            label: t`contact-form-detail-modal.detail-tab-label`,
            id: ITabId.DETAIL,
            view: <FormDetail formId={formId} id={props.id} />,
        },
        {
            label: t`contact-form-detail-modal.chat-tab-label`,
            id: ITabId.CHAT,
            view: <ContactFormChatModal id={props.id} />,
        },
    ]

    const handleTabChange = (_event: React.SyntheticEvent, id: string) => {
        store.setSelectedTabId(id)
    }
    return (
        <Container>
            <ModalHeader>
                {store.selectedTabId === tabsData[0].id && (
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={
                            isSaveButtonDisabled ||
                            store.form.getIsDirty() === false
                        }
                        form={formId}
                    >{t`contact-form-detail-modal.save-button`}</Button>
                )}
            </ModalHeader>
            {globalStore.features.enabled("ShowCasesDetailTabs") &&
            store.messagingEnabled ? (
                <Tabs
                    data={tabsData}
                    selectedTabId={store.selectedTabId}
                    handleTabChange={handleTabChange}
                    tabListStyles={{
                        padding: 0,
                    }}
                    tabContentStyles={{
                        flex: 1,
                    }}
                />
            ) : (
                <FormDetail formId={formId} id={props.id} />
            )}
        </Container>
    )
})

export const ContactFormDetailModalView = observer((props: IProps) => (
    <StoreProvider Store={ContactFormDetailStore}>
        <View {...props} />
    </StoreProvider>
))
