import {
    alpha,
    Paper,
    Stack,
    styled,
    TableRow as MUITableRow,
} from "@mui/material"

const NoWrap = styled("span")({
    whiteSpace: "nowrap",
})

const TableContainerPaper = styled(Paper)(({ theme }) => ({
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.grey[300],
    boxShadow: "none",
}))

const TableRow = styled(MUITableRow)(({ theme, onClick }) => ({
    height: "54px",
    ...(onClick != null
        ? {
              cursor: "pointer",
              "&:hover": {
                  background: alpha(theme.palette.utility.blueFaded.main, 0.5),
              },
          }
        : {}),
}))

const EmptyState = styled(Stack)(({ theme }) => ({
    display: "inline-flex",
    maxWidth: "320px",
    textAlign: "center",
    width: "100%",
    padding: "100px 0",
    color: theme.palette.grey[700],
}))

export { NoWrap, TableContainerPaper, TableRow, EmptyState }
