import { reject } from "lodash"

import { Repository } from "src/types/channel"
import {
    FilterModel,
    IAdvanceQueryModel,
    IAdvanceQueryModelApi,
    IFilterModel,
    ISortModel,
    SortModel,
    SortModelApi,
} from "src/types/data-grid-pro"

/**
 *
 * @param repository to get data for particular feature
 * @param model the complete sort model object for all features
 * @returns SortModel for a particular feature which complies with API requirements
 */
export const getSortModelForRepository = (
    repository: Repository,
    model?: ISortModel,
) => {
    return model !== undefined
        ? (model[repository as keyof ISortModel] as SortModel)
        : undefined
}

/**
 *
 * @param repository to get data for particular feature
 * @param model the complete filter model object for all features
 * @returns FilterModel for a particular feature which complies with API requirements
 */
export const getFilterModelForRepository = (
    repository: Repository,
    model?: IFilterModel,
) => {
    return model !== undefined
        ? (model[repository as keyof IFilterModel] as FilterModel)
        : undefined
}

const checkItemValueAsPerType = (
    value: string | number | string[] | number[],
) => (Array.isArray(value) ? value.length > 0 : value !== "")

const removeBlankFilters = (filter: FilterModel) => {
    const items = filter.items.filter(
        (item) =>
            item.value !== undefined && checkItemValueAsPerType(item.value),
    )

    return { ...filter, items }
}

/**
 *
 * @param query The advance query model
 * @returns api understandable query model
 */
export const convertAdvanceQueryToApiPayload = (
    query?: IAdvanceQueryModel,
): IAdvanceQueryModelApi | undefined => {
    if (query === undefined) {
        return
    }
    let filter = query.filter !== undefined ? query.filter : undefined
    // filter_SortItem (API Model) does not has null as sort direction, but data-grid-pro has it
    const notNullSortDirection = reject(query.sort, [
        "sort",
        null,
    ]) as SortModelApi
    const sort =
        query.sort !== undefined ? [...notNullSortDirection] : undefined
    filter = filter !== undefined ? removeBlankFilters(filter) : filter

    const payload: IAdvanceQueryModelApi = { ...filter, sort }
    return payload
}
