import React from "react"
import { t } from "@lingui/macro"
import { makeAutoObservable } from "mobx"

import { reportError } from "src/lib/report"
import { loads } from "src/channel/utils"
import { DashboardAdminService } from "src/api"
import { createLoadingKeys } from "src/lib/loading"
import { variables } from "src/config/variables"

export class TenantsInsightsStore {
    static Context = React.createContext<TenantsInsightsStore | null>(null)
    static LoadingKeys = createLoadingKeys("init")

    private _link?: string = undefined

    constructor() {
        makeAutoObservable(this)
    }

    //#region getters
    get link() {
        return this._link
    }
    //#endregion

    //#region setters
    setLink(link: string) {
        this._link = link
    }
    //#endregion

    //#region store operation
    @loads(() => TenantsInsightsStore.LoadingKeys.init)
    async init() {
        try {
            const response =
                await DashboardAdminService.getV1AdminDashboardLink1({
                    dashboardId: variables.TENANTS_INSIGHTS_DASHBOARD_ID,
                })
            // @ts-ignore the response type is set to string and doesn't contain the url key
            this.setLink(response.url)
        } catch (e) {
            reportError(t`tenants-insights-view.failed-to-load-dashboard`, e)
        }
    }
    //#endregion
}
