/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { avy_api_pkg_segment_SegmentForSearch } from "src/api/models/avy_api_pkg_segment_SegmentForSearch"

import { OpenAPI } from "src/api/core/OpenAPI"
import { request as __request } from "../core/request"
import {
    readSegments,
    writeSegments,
} from "src/api/_indexedDB/queries/Segments"
import { ISegmentsDB } from "src/api/_indexedDB/database/Segments"
import { CancelablePromise } from "src/api/core/CancelablePromise"
import { reportError } from "src/lib/report"

export class CustomSegmentsAdminService {
    /**
     * Segment picker
     * Segment picker
     * @returns avy_api_pkg_segment_SegmentForSearch OK
     * @throws ApiError
     */
    public static getV1AdminSegmentSearch({
        accessGroupId,
    }: {
        /** Access group ID (optional) **/
        accessGroupId?: string
    }): CancelablePromise<Array<avy_api_pkg_segment_SegmentForSearch>> {
        const defaultAccessGroupId = "-1"

        // ENDPOINT CALL
        const getSegmentsRemote = async () => {
            try {
                return (await __request(OpenAPI, {
                    method: "GET",
                    url: "/v1/admin/segment/search",
                    query: {
                        access_group_id: accessGroupId,
                    },
                })) as ISegmentForSearch[]
            } catch (error) {
                reportError("An error occurred. Please, try again.", error)
                return []
            }
        }

        // GET LOCAL SEGMENTS
        const getSegmentsLocalStorage = async () => {
            const id = accessGroupId || defaultAccessGroupId
            //get segment from local DB
            const stored = await readSegments(id)
            if (stored != null) {
                return (stored as ISegmentsDB).segments
            }
            return null
        }

        // SAVE SEGMENTS LOCALLY
        const refreshSegmentsLocalStorage = async () => {
            const segments = await getSegmentsRemote()
            // save response into local DB
            await writeSegments({
                accessGroupId: accessGroupId || defaultAccessGroupId,
                segments,
            })
            return segments
        }

        return new CancelablePromise(async (resolve, reject, onCancel) => {
            if (!onCancel.isCancelled) {
                const segmentsLocalStorage = await getSegmentsLocalStorage()

                if (segmentsLocalStorage !== null) {
                    refreshSegmentsLocalStorage()
                    resolve(segmentsLocalStorage)
                } else {
                    const refresh = await refreshSegmentsLocalStorage()
                    resolve(refresh)
                }
            }
        })
    }
}
