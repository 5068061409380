export enum Status {
    Created = "created",
    Sent = "sent",
    InProgress = "in_progress",
    Done = "done",
    SentFallback = "sent_fallback",
    // ContactForm.status is only typed as a string in the api which means that
    // we cannot typecheck the status during compile time. If we get an
    // unsupported status we set the it to Unknown.
    Unknown = "unknown",
}

export enum ReadUnreadStatus {
    UnRead = "false",
}

export const parseStatus = (status: string): Status => {
    switch (status) {
        case Status.Created:
        case Status.Sent:
        case Status.InProgress:
        case Status.Done:
        case Status.SentFallback:
            return status
        default:
            return Status.Unknown
    }
}
