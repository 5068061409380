import { observer } from "mobx-react"
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    FormHelperText,
} from "@mui/material"
import { useEffect } from "react"
import { t } from "@lingui/macro"

import { EditProductStore } from "src/views/product-catalogue/edit/store"
import { useStore } from "src/store/lib/useStore"

export const PricePlan = observer(() => {
    const store = useStore(EditProductStore)
    const initialPricePlan = store.product.pricePlan
    const pricePlanIsAnOption = store.pricePlans.includes(
        store.formFields.get("pricePlan") ?? "",
    )

    useEffect(() => {
        // Re-set initial value if selected value does not exist in options array but the initial value does.
        if (!pricePlanIsAnOption) {
            const initialPricePlanIsAnOption = store.pricePlans.includes(
                initialPricePlan ?? "",
            )

            if (initialPricePlanIsAnOption) {
                store.formFields.set("pricePlan", initialPricePlan)
            }
        }
    }, [
        store.formFields,
        store.pricePlans,
        initialPricePlan,
        pricePlanIsAnOption,
    ])

    return (
        <FormControl>
            <InputLabel id="price-plan-label">{t`edit-product-view.price-plan.label`}</InputLabel>
            <Select
                labelId="price-plan-label"
                id="price-plan"
                value={
                    pricePlanIsAnOption ? store.formFields.get("pricePlan") : ""
                }
                label={t`edit-product-view.price-plan.label`}
                onChange={(event) =>
                    store.formFields.set("pricePlan", event.target.value)
                }
                error={Boolean(store.formFields.error("pricePlan"))}
            >
                {store.pricePlans.map(
                    (pricePlan) =>
                        getMappedPricePlan(pricePlan) != null && (
                            <MenuItem value={pricePlan} key={pricePlan}>
                                {getMappedPricePlan(pricePlan)}
                            </MenuItem>
                        ),
                )}
            </Select>
            {Boolean(store.formFields.error("pricePlan")) && (
                <FormHelperText error={true}>
                    {store.formFields.error("pricePlan")}
                </FormHelperText>
            )}
        </FormControl>
    )
})

const getMappedPricePlan = (unit: string) => {
    const priceUnitMapping = {
        fixed: t`edit-product-view.price-plan.fixed-price`,
        usage_based: t`edit-product-view.price-plan.usage-based`,
        hourly: t`edit-product-view.price-plan.hourly`,
        rent: t`edit-product-view.price-plan.per-month`,
        custom: t`edit-product-view.price-plan.not-used`,
    }

    return priceUnitMapping[unit as keyof typeof priceUnitMapping]
}
