import { makeAutoObservable } from "mobx"
import React from "react"

import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"
import { loads } from "src/channel/utils"

import { ConnectorAdminService } from "src/api"
import { Channel } from "src/channel"

export class IntegrationConnectorStore {
    static Context = React.createContext<IntegrationConnectorStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "loading", "pageLoad")

    private repositoryUpdatesListenerDisposer?: () => void

    result = new Pagination(
        async () => {
            const response =
                await ConnectorAdminService.getV1AdminConnectorIntegration({
                    type: "",
                })
            const items =
                response.map((item) => ({
                    id: item.integration_id as number,
                    accessGroupId: item.access_group_id as number,
                    accessType: item.access_type as string,
                    createdAt: item.created_at as string,
                    deletedAt: item.deleted_at as string,
                    integrationId: item.integration_id as number,
                    integrationTypeId: item.integration_type_id as number,
                    metaData: item.metadata as Record<string, string>,
                    name: item.name as string,
                    type: item.type as string,
                    updatedAt: item.updated_at as string,
                })) ?? []

            return {
                items,
                rawItems: response ?? [],
                count: response.length ?? 0,
            }
        },
        { loadingKey: IntegrationConnectorStore.LoadingKeys.pageLoad },
    )

    constructor() {
        makeAutoObservable(this)
    }

    dispose() {
        this.repositoryUpdatesListenerDisposer?.()
    }
    private listenToIntegrationConnectorRepositoryUpdated() {
        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "integration-connector"
                ) {
                    await this.result.loadAllPage()
                }
            },
        )
    }
    @loads(() => IntegrationConnectorStore.LoadingKeys.init)
    async init() {
        this.listenToIntegrationConnectorRepositoryUpdated()
        await this.result.loadAllPage()
    }
}
