import { t } from "@lingui/macro"
import { makeAutoObservable } from "mobx"
import React from "react"

import { TagsAdminService } from "src/api"
import { Channel } from "src/channel"
import { loads } from "src/channel/utils"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"
import { reportError } from "src/lib/report"
import { IAdvanceQueryModel } from "src/types/data-grid-pro"

export class TagsStore {
    static Context = React.createContext<TagsStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "loading")

    _accessGroupId: number = DEFAULT_ACCESS_GROUP.id

    private repositoryUpdatesListenerDisposer?: () => void

    tags = new Pagination(
        async (params) => {
            const response = await TagsAdminService.postV1AdminTagList({
                request: {
                    grid_filter: params.advanceQuery,
                    page_size: params.pageSize,
                    page_number: params.page,
                },
            })

            const items = (response.tags ?? []).map((tag) => ({
                id: tag.tag_id ?? -1,
                name: tag.name,
                description: tag.description,
            }))

            return {
                items,
                count: response.total_count ?? 0,
            }
        },
        {
            loadingKey: TagsStore.LoadingKeys.loading,
        },
    )

    constructor() {
        makeAutoObservable(this)
    }

    //#region getters
    get accessGroupId() {
        return this._accessGroupId
    }
    //#endregion

    //#region setters
    private setAccessGroup(accessGroupId: number) {
        this._accessGroupId = accessGroupId
    }
    //#endregion

    //#region store operations
    @loads(() => TagsStore.LoadingKeys.init)
    async init(accessGroupId: number, advanceQuery?: IAdvanceQueryModel) {
        this.listenToMessageRepositoryUpdated()
        this.setAccessGroup(accessGroupId)
        await this.tags.loadInitialPage(advanceQuery)
    }

    dispose() {
        this.repositoryUpdatesListenerDisposer?.()
    }

    deleteItem = async (tagId: number) => {
        try {
            await TagsAdminService.deleteV1AdminTag({
                tagId: tagId.toString(),
            })
        } catch (error) {
            reportError(t`tags-view.delete-item-fail`, error)
        } finally {
            await this.tags.reload()
        }
    }

    async query(advanceQuery: IAdvanceQueryModel) {
        await this.tags.loadAdvanceQuery(advanceQuery)
    }

    // events fired from tags modal are listened here
    private listenToMessageRepositoryUpdated() {
        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "tags"
                ) {
                    await this.tags.reload()
                }
            },
        )
    }
    //#endregion
}
