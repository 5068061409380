import { t } from "@lingui/macro"
import { Stack, styled, useMediaQuery, useTheme } from "@mui/material"
import { observer } from "mobx-react"
import React, { useCallback, useRef } from "react"

import { FileInputButton } from "src/components/AttachmentsField/FileInputButton"
import { Icon } from "src/components/icons"

import { UploadCloud56 } from "src/components/icons/UploadCloud56"
import { Label } from "src/components/ImageUploadField/Label"
import { TooltipWithIcon } from "src/components/Tooltips/TooltipWithIcon"
import { useUniqueId } from "src/lib/unique-id"

const ImageInputButton = styled(FileInputButton)({
    aspectRatio: "16/9",
})

const ACCEPTED_FILE_TYPES = ["image/jpg", "image/jpeg", "image/png"].join(",")

interface IProps {
    onChange: (file: File) => void
    label?: string
    disabled?: boolean
    tooltip?: string
}

export const UploadImageField = observer((props: IProps) => {
    const inputRef = useRef<HTMLInputElement | null>(null)

    const theme = useTheme()
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))
    const labelId = useUniqueId()

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (
                "files" in event.target &&
                event.target.files != null &&
                event.target.files.length > 0
            ) {
                const file = event.target.files[0]
                props.onChange(file)
            }
            event.target.value = ""
        },
        [props],
    )

    return (
        <div>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                {Boolean(props.label) && (
                    <Label htmlFor={labelId}>{props.label}</Label>
                )}
                {Boolean(props.tooltip) && (
                    <TooltipWithIcon tooltipText={props.tooltip ?? ""} />
                )}
            </Stack>

            <ImageInputButton
                type="button"
                title={t`image-upload-field-component.image-upload-button`}
                onClick={() => inputRef.current?.click()}
                disabled={props.disabled}
            >
                <Icon icon={<UploadCloud56 />} size={smallScreen ? 32 : 32} />
            </ImageInputButton>
            <input
                id={labelId}
                data-testid="UploadImageField/Input"
                ref={inputRef}
                style={{ display: "none" }}
                type="file"
                onChange={handleChange}
                accept={ACCEPTED_FILE_TYPES}
            />
        </div>
    )
})
