import { observer } from "mobx-react"
import { Typography, Box } from "@mui/material"
import { t } from "@lingui/macro"

import { EditProductStore } from "src/views/product-catalogue/edit/store"

import { useStore } from "src/store/lib/useStore"
import { Provider } from "src/views/product-catalogue/edit/form-elements/Provider"
import { Category } from "src/views/product-catalogue/edit/form-elements/Category"
import { ProductType } from "src/views/product-catalogue/edit/form-elements/ProductType"
import { OrderOutput } from "src/views/product-catalogue/edit/form-elements/OrderOutput"
import { Price } from "src/views/product-catalogue/edit/form-elements/Price"
import { PriceText } from "src/views/product-catalogue/edit/form-elements/PriceText"
import { PricePlan } from "src/views/product-catalogue/edit/form-elements/PricePlan"
import { IncludeInOnboarding } from "src/views/product-catalogue/edit/form-elements/IncludeInOnboarding"
import { LinkToOffer } from "src/views/product-catalogue/edit/form-elements/LinkToOffer"

export const SettingsSection = observer(() => {
    const store = useStore(EditProductStore)
    const productType = store.formFields.get("productType")
    const providerId = store.formFields.get("providerId")
    const showFields = {
        category:
            providerId != null && productType !== "standard_improvement_single",
        linkToOffer: productType === "affiliate",
        orderOutput: providerId != null,
        priceCent:
            productType != null &&
            (productType === "standard_improvement_single" ||
                productType === "standard_improvement_order" ||
                productType === "purchase" ||
                productType === "standard_improvement_project"),
        pricePlan: productType != null,
        priceText:
            (productType != null && productType === "quote_request") ||
            productType === "affiliate",
    }

    return (
        <>
            <Typography
                component="h2"
                fontSize="20px"
                fontWeight="500"
                sx={{ mb: 2 }}
            >
                {t`edit-product-view.product-settings`}
            </Typography>
            <Box
                sx={{
                    display: "grid",
                    gap: "24px",
                    gridTemplateColumns: {
                        xs: "1fr",
                        sm: "1fr 1fr",
                        lg: "1fr 1fr 1fr",
                    },
                    alignItems: "start",
                }}
            >
                <Provider />
                <ProductType />
                {showFields.category && <Category />}
                {showFields.orderOutput && <OrderOutput />}
                <Box
                    sx={{
                        display: "grid",
                        gap: "24px",
                        gridTemplateColumns: "1fr 1fr",
                    }}
                >
                    {showFields.priceCent && <Price />}
                    {showFields.pricePlan && <PricePlan />}
                </Box>
                {showFields.priceText && <PriceText />}
                {showFields.linkToOffer && <LinkToOffer />}
                <IncludeInOnboarding />
            </Box>
        </>
    )
})
