/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { Dict } from "mixpanel-browser"

import { track } from "src/analytics/AnalyticsManager"
import { MixpanelProperties } from "src/analytics/constants/properties"
import { INavigationMenuData } from "src/analytics/types/navigationMenu"
import { environment } from "src/config"
import { removeHyphen } from "src/lib/strings"

import { ISelectedAccessGroup } from "src/types/local-storage"

let sessionTime = 0

const navigationDataSet = window.localStorage.getItem(
    "navigationDataForTracking",
)
const navigationMenuData: INavigationMenuData | null = navigationDataSet
    ? JSON.parse(navigationDataSet)
    : null

export const resetTimeout = () => {
    sessionTime = 0
}

export const trackUserSession = (adminId: number) => {
    if (sessionTime >= 60 && environment.MIXPANEL_TOKEN !== "") {
        track("Session Duration", {
            [MixpanelProperties.SessionDuration]: sessionTime,
            [MixpanelProperties.UserID]: adminId,
        })
        resetTimeout()
    } else {
        setTimeout(() => {
            sessionTime++
            trackUserSession(adminId)
        }, 60000)
    }
}

export const routeNameForMixPanel = (string: string) => {
    const values = string.split(",")
    return values.map((value) => removeHyphen(value)).join(" | ")
}

export const getScreenName = (name: string) => {
    let firstValue = ""
    let values = []
    let screen = "Unknown Screen"

    if (navigationMenuData === null) return screen

    if (name.includes(",")) {
        values = name.split(",")
        firstValue = values[0]
    } else {
        values = []
        firstValue = name
    }

    Object.keys(navigationMenuData).forEach((key) => {
        if (
            navigationMenuData[key as keyof INavigationMenuData].includes(
                firstValue,
            )
        ) {
            const formattedValue =
                values.length > 1
                    ? routeNameForMixPanel(name)
                    : removeHyphen(name)
            screen = `${key} | ${formattedValue}`
        }
    })

    return screen
}

/** This function is used to track View Page event for mixpanel
 @param eventName: Name of the event
 @param accessGroup: Current access group the user is in. Type: ISelectedAccessGroup
 @param adminId: ID of the admin user
**/
export const trackNavigationMenuEvents = (
    name: string[],
    accessGroup: ISelectedAccessGroup,
    adminId?: number,
) => {
    if (environment.MIXPANEL_TOKEN === "" || adminId === -1) return

    trackEvent("View Page", name, {
        [MixpanelProperties.AccessGroupName]: accessGroup.name,
        [MixpanelProperties.AccessGroupID]: accessGroup.id,
        [MixpanelProperties.UserID]: adminId,
    })
}

/** This function is used to track general(not specific to any module) user events that are sent to mixpanel
 @param eventName: Name of the event
 @param screen: View from where the event happened
 @param properties: A key value pair that would be sent to mixpanel as additional properties to get more insights
**/
export const trackEvent = (
    eventName: string,
    name: string[],
    properties: Dict,
) => {
    const screenName = getScreenName(name.toString())

    track(eventName, {
        [MixpanelProperties.PageName]: screenName,
        ...properties,
    })
}

/** This function is used to track module specific user events that are sent to mixpanel
 @param eventName: Name of the event that is supposed to have module name as well
 @param properties: A key value pair that would be sent to mixpanel as additional properties to get more insights
**/
export const trackModuleEvent = (eventName: string, properties?: Dict) => {
    track(
        eventName,
        properties && {
            ...properties,
        },
    )
}
