import { makeAutoObservable } from "mobx"
import { createContext } from "react"

import { InvoiceAdminService } from "src/api"
import { Channel } from "src/channel"
import { loads } from "src/channel/utils"
import { FormFields } from "src/lib/form-fields"
import { createLoadingKeys } from "src/lib/loading"
import { reportUnhandledApiError } from "src/lib/report"

interface IFormFields {
    id: number | null
    date: Date
}

export class MarkInvoiceAsPaidStore {
    static Context = createContext<MarkInvoiceAsPaidStore | null>(null)
    static LoadingKeys = createLoadingKeys("submit")

    form = new FormFields<IFormFields>({ id: null, date: new Date() })

    constructor() {
        makeAutoObservable(this)
    }

    @loads(() => MarkInvoiceAsPaidStore.LoadingKeys.submit)
    async submit() {
        const id = this.form.get("id")
        if (id == null) {
            throw new Error("id cannot be null")
        }

        try {
            await InvoiceAdminService.patchV1AdminInvoiceMarkAsPaid({
                invoiceId: id,
                request: {
                    paid_at: this.form.get("date").toISOString(),
                },
            })

            Channel.send({
                name: "repository/updated",
                payload: { repository: "invoices", action: "update" },
            })

            return true
        } catch (e) {
            reportUnhandledApiError(e)
            return false
        }
    }
}
