import {
    Stack,
    useTheme,
    Menu,
    IconButton,
    Typography,
    useMediaQuery,
} from "@mui/material"
import { observer } from "mobx-react"

import { useCallback, useState } from "react"

import { t } from "@lingui/macro"

import { UserInfo } from "./UsertInfo"

import { CustomMenuItem } from "./styled"

import { UserInfoIconWrapper, Flag } from "src/layouts/DefaultLayout/styled"

import { AccountCircleFilled24 } from "src/components/icons/AccountCircleFilled24"
import { Icon } from "src/components/icons"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { Logout24 } from "src/components/icons/Logout24"
import { environment } from "src/config"
import { Locales } from "src/locales/locale"
import { LanguageSelectorModal } from "src/modals/language-selector"

export const HeadUserInfo = observer(() => {
    const theme = useTheme()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const { session, modals } = useStore(GlobalStore)

    const open = Boolean(anchorEl)
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"))

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleLogoutClick = useCallback(() => {
        handleClose()
        session.destroy()
        window.localStorage.removeItem("SelectedTenantSegments")
        window.localStorage.removeItem("selectedPageSize")
        window.location.href = environment.BASE_URL
    }, [session])

    const handleLocaleClick = useCallback(() => {
        handleClose()
        modals.open(() => <LanguageSelectorModal />, {
            variant: "slide-up-w600",
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const renderUserDetails = ({ isMenuItem }: { isMenuItem: boolean }) => {
        const iconSize = isMenuItem ? 20 : 24
        return (
            <Stack direction="row" textAlign="left" spacing={2}>
                <UserInfoIconWrapper>
                    <Icon
                        icon={
                            <AccountCircleFilled24
                                width={iconSize}
                                height={iconSize}
                            />
                        }
                        color={theme.palette.grey[900]}
                    />
                </UserInfoIconWrapper>
                {isLargeScreen || isMenuItem ? <UserInfo /> : null}
            </Stack>
        )
    }

    const renderLogoutItem = () => (
        <Stack direction="row" textAlign="left" spacing={2}>
            <UserInfoIconWrapper>
                <Logout24 />
            </UserInfoIconWrapper>
            <Typography color={theme.palette.grey[900]}>
                {t`navigation.logout`}
            </Typography>
        </Stack>
    )

    const renderLocale = () => (
        <Stack direction="row" textAlign="left" spacing={2}>
            <UserInfoIconWrapper>
                <Flag src={Locales[session.language].icon} />
            </UserInfoIconWrapper>
            <Typography color={theme.palette.grey[900]}>
                {Locales[session.language].name}
            </Typography>
        </Stack>
    )

    const borderStyles = {
        height: 48,
        borderTop: 1,
        borderColor: theme.palette.grey[500],
    }

    return (
        <>
            <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                data-testid="user-profile-menu"
            >
                {renderUserDetails({ isMenuItem: false })}
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                    disablePadding: true,
                }}
            >
                <CustomMenuItem onClick={() => null} sx={{ height: 70 }}>
                    {renderUserDetails({ isMenuItem: true })}
                </CustomMenuItem>
                {!isLargeScreen ? (
                    <CustomMenuItem
                        onClick={handleLocaleClick}
                        sx={borderStyles}
                        data-testid="locale-profile-item"
                    >
                        {renderLocale()}
                    </CustomMenuItem>
                ) : null}

                <CustomMenuItem onClick={handleLogoutClick} sx={borderStyles}>
                    {renderLogoutItem()}
                </CustomMenuItem>
            </Menu>
        </>
    )
})
