import { t } from "@lingui/macro"
import { useTheme } from "@mui/material"
import { observer } from "mobx-react"

import { CheckCircle16 } from "src/components/icons/CheckCircle16"
import { User16 } from "src/components/icons/User16"
import { SegmentPickerChip } from "src/components/SegmentPicker/components/SegmentPickerChip"

export const SelectionStatusChip = observer(
    (props: {
        selected: boolean
        hasSelectedAncestors: boolean
        tenantCount: number
    }) => {
        const theme = useTheme()

        if (props.selected) {
            return (
                <SegmentPickerChip
                    variant="selected"
                    icon={<CheckCircle16 />}
                    textColor={theme.palette.common.white}
                    tenantCount={props.tenantCount}
                />
            )
        } else if (props.hasSelectedAncestors) {
            return (
                <SegmentPickerChip
                    variant="ancestor-selected"
                    icon={<User16 />}
                    textColor={theme.palette.common.white}
                    tenantCount={props.tenantCount}
                    tooltip={t`segment-picker.available.segment-parent-is-already-selected`}
                />
            )
        } else {
            return (
                <SegmentPickerChip
                    variant="regular"
                    icon={<User16 />}
                    textColor={theme.palette.grey[700]}
                    tenantCount={props.tenantCount}
                />
            )
        }
    },
)
