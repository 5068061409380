import { observer } from "mobx-react"
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    useTheme,
    Box,
    SelectChangeEvent,
    Typography,
    FormHelperText,
} from "@mui/material"
import { t } from "@lingui/macro"

import { EditProductStore } from "src/views/product-catalogue/edit/store"

import { useStore } from "src/store/lib/useStore"
import { Alert16 } from "src/components/icons/Alert16"
import { Icon } from "src/components/icons"
import { Tooltip } from "src/components/Tooltips/Tooltip"

export const OrderOutput = observer(() => {
    const store = useStore(EditProductStore)
    const theme = useTheme()

    // Set orderOutputId as value only if it exists as option
    const value =
        store.orderOutputs?.find(
            (orderOutput) =>
                orderOutput.orderOutputId ===
                store.formFields.get("orderOutputId"),
        )?.orderOutputId ?? ""

    const handleChange = (event: SelectChangeEvent<number | string>) => {
        store.formFields.set("orderOutputId", Number(event.target.value))
    }

    return (
        <Box
            sx={{
                display: "flex",
                gap: "8px",
            }}
        >
            <FormControl style={{ breakInside: "avoid" }}>
                <InputLabel id="order-output-label">{t`edit-product-view.order-output.label`}</InputLabel>
                <Select
                    labelId="order-output-label"
                    id="order-output"
                    value={value}
                    label={t`edit-product-view.order-output.label`}
                    disabled={store.orderOutputs?.length === 0}
                    onChange={handleChange}
                    error={Boolean(store.formFields.error("orderOutputId"))}
                >
                    {store.orderOutputs.map((orderOutput) => (
                        <MenuItem
                            value={orderOutput.orderOutputId}
                            key={orderOutput.orderOutputId}
                        >
                            {orderOutput.name}
                        </MenuItem>
                    ))}
                </Select>
                {Boolean(store.formFields.error("orderOutputId")) && (
                    <FormHelperText error={true}>
                        {store.formFields.error("orderOutputId")}
                    </FormHelperText>
                )}
            </FormControl>
            <Tooltip title={t`edit-product-view.order-output.tooltip`} arrow>
                <Typography sx={{ marginTop: "16px" }}>
                    <Icon
                        icon={<Alert16 width="20px" height="20px" />}
                        color={theme.palette.grey[700]}
                    />
                </Typography>
            </Tooltip>
        </Box>
    )
})
