import React from "react"
import { makeAutoObservable } from "mobx"
import { t } from "@lingui/macro"

import { Channel } from "src/channel"
import { ISessionPropertyOwner } from "src/store/session"
import { loads } from "src/channel/utils"
import { Pagination } from "src/lib/pagination"
import { AdminService } from "src/api"
import { createLoadingKeys } from "src/lib/loading"

interface IDropdownItem {
    name: string
    id: number
}

interface IAdmin {
    adminId: number
    email: string
    name: string
    propertyOwnerId: number
    id: number
}

export class ManageUsersStore {
    static Context = React.createContext<ManageUsersStore | null>(null)
    static LoadingKeys = createLoadingKeys(
        "pageLoad",
        "init",
        "delete",
        "duplicate",
    )

    private _admins: IAdmin[] = []
    setAdmins(admins: IAdmin[]) {
        this._admins = admins
    }

    private _propertyOwnerId: string | undefined = undefined
    public async setPropertyOwnerId(propertyOwnerId: string) {
        this._propertyOwnerId = propertyOwnerId
        this._legalEntityId = undefined

        const legalEntitiesResponse = await AdminService.getV1AdminLegalEntity({
            propertyOwnerId: Number(propertyOwnerId),
        })

        this.setLegalEntities(
            legalEntitiesResponse.map((legalEntity): IDropdownItem => {
                return {
                    name: legalEntity.legal_name ?? "",
                    id: legalEntity.legal_entity_id ?? 0,
                }
            }) ?? [],
        )
        await this.loadAdmins()
    }

    get propertyOwnerId() {
        return this._propertyOwnerId
    }

    private _legalEntityId: string | undefined = undefined
    public async setLegalEntityId(legalEntityId: string) {
        this._legalEntityId = legalEntityId
        await this.loadAdmins()
    }
    get legalEntityId() {
        return this._legalEntityId
    }

    private _propertyOwners: IDropdownItem[] = []
    get propertyOwners() {
        return this._propertyOwners
    }
    private setPropertyOwners(propertyOwners: IDropdownItem[]) {
        this._propertyOwners = propertyOwners
    }

    private _legalEntities: IDropdownItem[] = []
    get legalEntities() {
        return this._legalEntities
    }
    private setLegalEntities(legalEntities: IDropdownItem[]) {
        this._legalEntities = legalEntities
    }

    @loads(() => ManageUsersStore.LoadingKeys.init)
    async init(loggedInUsersPropertyOwners: ISessionPropertyOwner[]) {
        this.listenToManageUsersRepositoryUpdated()
        this.setPropertyOwners(
            loggedInUsersPropertyOwners.map(
                (propertyOwner): IDropdownItem => ({
                    name: propertyOwner.legalName ?? "",
                    id: propertyOwner.propertyOwnerId as number,
                }),
            ) ?? [],
        )
        if (loggedInUsersPropertyOwners.length === 1) {
            await this.setPropertyOwnerId(
                String(loggedInUsersPropertyOwners[0].propertyOwnerId),
            )
        } else {
            await this.loadAdmins()
        }
    }

    private repositoryUpdatesListenerDisposer?: () => void

    dispose() {
        this.repositoryUpdatesListenerDisposer?.()
    }

    private listenToManageUsersRepositoryUpdated() {
        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "manage-users"
                ) {
                    await this.loadAdmins()
                }
            },
        )
    }

    admins = new Pagination(
        (params) => {
            const pageStart = (params.page - 1) * params.pageSize
            const pageAdmins = this._admins.slice(
                pageStart,
                pageStart + params.pageSize,
            )
            return {
                items: pageAdmins,
                count: this._admins.length,
                sourceItems: this._admins,
            }
        },
        { static: true },
    )

    @loads(() => ManageUsersStore.LoadingKeys.delete)
    async deleteUser(id: number) {
        try {
            await AdminService.deleteV1Admin({
                adminId: id,
            })
        } catch {
            alert(t`manage-users-view.failed-to-delete-user`)
        } finally {
            await this.loadAdmins()
        }
    }

    private async loadAdmins() {
        try {
            const response = await AdminService.getV1Admin({
                propertyOwnerId:
                    this.legalEntityId != null
                        ? undefined
                        : this._propertyOwnerId,
                legalEntityId: this._legalEntityId,
            })
            this.setAdmins(
                response.admins?.map(
                    (admin): IAdmin => ({
                        adminId: admin.admin_id ?? 0,
                        email: admin.email ?? "",
                        name: admin.name ?? "",
                        propertyOwnerId: admin.property_owner_id ?? 0,
                        id: admin.admin_id ?? 0,
                    }),
                ) ?? [],
            )
        } catch {
            alert(t`manage-users-store.failed-to-load-admins`)
        } finally {
            await this.admins.loadInitialPage()
        }
    }

    constructor() {
        makeAutoObservable(this)
    }
}
