import { makeAutoObservable } from "mobx"
import React from "react"

import { AdminService, TenantAdminService } from "src/api"
import { MixpanelProperties } from "src/analytics/constants/properties"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"

import { CustomTenantAdminService } from "src/api/_custom/services/TenantAdminService"

import { loads } from "src/channel/utils"

import { DEFAULT_ACCESS_GROUP } from "src/config"
import { autoDownloadFile } from "src/lib/file"
import { createLoadingKeys } from "src/lib/loading"
import { Pagination } from "src/lib/pagination"

export class TenantsListStore {
    static Context = React.createContext<TenantsListStore | null>(null)
    static LoadingKeys = createLoadingKeys("pageLoad", "export")

    private _segments: number[] = []
    private _accessGroupId: number = DEFAULT_ACCESS_GROUP.id
    private _adminId: number = -1

    get segments() {
        return this._segments
    }

    constructor() {
        makeAutoObservable(this)
    }

    @loads(() => TenantsListStore.LoadingKeys.export)
    async export() {
        const meta = this.tenants.meta

        const blob = await CustomTenantAdminService.postV1AdminTenantsList({
            request: {
                search_term: meta.search ?? undefined,
                segment_ids: this.segments,
            },
        })

        const url = URL.createObjectURL(blob)

        autoDownloadFile(
            url,
            `AVY-TMPL-Tenants-${new Date().toDateString()}.csv`,
            "export-tenants-link",
        )

        // Keep the url alive for a minute just to be sure.
        setTimeout(() => {
            URL.revokeObjectURL(url)
        }, 60_000)
    }

    async exportContract() {
        const meta = this.tenants.meta

        const blob =
            await CustomTenantAdminService.postV1AdminLeaseContractList({
                request: {
                    search_term: meta.search ?? undefined,
                    segment_ids: this.segments,
                },
            })

        const url = URL.createObjectURL(blob)

        autoDownloadFile(
            url,
            `AVY-TMPL-Contract-${new Date().toDateString()}.csv`,
            "export-contract-link",
        )

        // Keep the url alive for a minute just to be sure.
        setTimeout(() => {
            URL.revokeObjectURL(url)
        }, 60_000)
    }

    async deleteTenantsWithExpiredContracts() {
        await AdminService.postV1AdminPropertyOwnerTriggerScheduledOffboarding()
    }

    tenants = new Pagination(
        async (query) => {
            const response = await TenantAdminService.postV1AdminTenantsList({
                request: {
                    page_number: query.page,
                    page_size: query.pageSize,
                    search_term: query.search ?? undefined,
                    segment_ids: this.segments,
                },
            })

            if (query.search !== null && query.search !== "") {
                this.trackSearch()
            }

            const items =
                response.tenants?.map((tenant) => ({
                    id: tenant.tenant_id as number,
                    name: tenant.name as string,
                    registration_date: tenant.registration_date as string,
                    email: tenant.email as string,
                    phone_no: tenant.phone_no as string,
                    is_registered: tenant.is_registered as boolean,
                })) ?? []

            return {
                items,
                rawItems: response.tenants ?? [],
                count: response.total_count ?? 0,
            }
        },
        { loadingKey: TenantsListStore.LoadingKeys.pageLoad },
    )

    async init(accessGroupId: number, segments: number[], adminId?: number) {
        this.setAccountGroupId(accessGroupId)
        this.setAdminId(adminId ?? this._adminId)
        await this.loadSegments(segments)
    }

    async loadSegments(segments: number[]) {
        window.localStorage.setItem(
            "SelectedTenantSegments",
            JSON.stringify(segments),
        )
        this.setSegments(segments)
        await this.tenants.loadInitialPage()
    }

    private getAccountGroupId() {
        return this._accessGroupId !== DEFAULT_ACCESS_GROUP.id
            ? this._accessGroupId
            : undefined
    }

    private setAccountGroupId(accessGroupId: number) {
        this._accessGroupId = accessGroupId
    }

    private setSegments(segments: number[]) {
        this._segments = segments
    }

    private setAdminId(adminId: number) {
        this._adminId = adminId
    }

    private trackSearch = () => {
        trackModuleEvent("Residents | Search tenant", {
            [MixpanelProperties.SegmentID]: this.segments,
            [MixpanelProperties.UserID]: this._adminId,
        })
    }
}
