import { t } from "@lingui/macro"
import { Photo, Send } from "@material-ui/icons"
import { Stack } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useEffect, useRef } from "react"

import {
    ChatInputControlsSquareButton,
    ChatInputControlsUploadButton,
} from "src/components/Chat/Input/Controls/styled"
import { AvyChatInputStore } from "src/components/Chat/Input/store"

import { useStore } from "src/store/lib/useStore"

export const ChatInputControls = observer(
    ({ isSubmitting }: { isSubmitting: boolean }) => {
        const store = useStore(AvyChatInputStore)
        const uploadInputRef = useRef<HTMLInputElement | null>(null)
        const previewImageUrls = useRef<string[]>([])

        useEffect(() => {
            const urls = previewImageUrls.current
            return () => {
                urls.forEach(URL.revokeObjectURL)
            }
        }, [])

        const handleFileChange = useCallback(
            (event: React.ChangeEvent<HTMLInputElement>) => {
                store.chatInputForm.clearErrors()
                const files = event.target.files
                if (files === null || files.length === 0) return

                const file = files[0]
                if (file.type.includes("video")) {
                    store.chatInputForm.setError("text", t`errors.video-upload`)
                    return
                }

                const url = URL.createObjectURL(file)
                store.appendImage({ file, url })

                previewImageUrls.current.push(url)
            },
            [store],
        )

        return (
            <Stack direction="row" spacing={1} justifyContent="space-between">
                <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
                    <ChatInputControlsUploadButton
                        title={t`chat-component.chat-input.attach-image-button`}
                        type="button"
                        color="inherit"
                        variant="outlined"
                        onClick={() => {
                            uploadInputRef.current?.click()
                        }}
                        disabled={isSubmitting || !store.canAppendImage()}
                        data-testid="attach-image-button"
                    >
                        <Photo />
                    </ChatInputControlsUploadButton>
                    <input
                        ref={uploadInputRef}
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                        accept="image/jpg,image/jpeg,image/png"
                        data-testid="attach-image-input"
                    />
                </Stack>
                <ChatInputControlsSquareButton
                    title={t`chat-component.chat-input.submit-message-button`}
                    type="submit"
                    color="info"
                    variant="contained"
                    disabled={isSubmitting || !store.canSubmitComment()}
                    data-testid="submit-button"
                >
                    <Send />
                </ChatInputControlsSquareButton>
            </Stack>
        )
    },
)
