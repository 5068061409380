import { CancelablePromise } from "src/api/core/CancelablePromise"
import { OpenAPI } from "src/api/core/OpenAPI"
import { request as __request } from "src/api/core/request"
import { featurev2_UpdateSegmentsRequest } from "src/api/models/featurev2_UpdateSegmentsRequest"

export class FeatureFlagAdminService {
    /**
     * Updates feature flag segments. This is re-implemented as the generated one
     * is non-functioning as it misses the path parameter.
     *
     * (original description)
     * Updates the flag count associated with particular feature.
     *
     * @throws ApiError
     */

    public static putV2AdminFeatureFlagSegments({
        request,
        flagId,
    }: {
        /** Update feature flag segments request **/
        request: featurev2_UpdateSegmentsRequest
        flagId?: number
    }): CancelablePromise<unknown> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v2/admin/feature/flag/{flag_id}/segments",
            body: request,
            path: {
                flag_id: flagId,
            },
        })
    }
}
