import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    styled,
    Button,
    Box,
    Paper,
    Checkbox,
    FormControlLabel,
    Autocomplete,
    TextField,
    Chip,
} from "@mui/material"
import { observer } from "mobx-react"
import { useCallback, useMemo } from "react"
import { t } from "@lingui/macro"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import CheckBoxIcon from "@mui/icons-material/CheckBox"

import { FeaturesAdminService } from "src/api/services/FeaturesAdminService"
import { ListPage } from "src/components/ListPage"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { FeatureTogglesStore } from "src/views/feature-toggles/store"
import { FeatureTogglesTableView } from "src/views/feature-toggles/feature-toggles-table"
import {
    reportError,
    reportSuccess,
    reportUnhandledApiError,
} from "src/lib/report"
import { GlobalStore } from "src/store"
import { ISessionLegalEntity } from "src/store/session"

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const View = observer(() => {
    const store = useStore(FeatureTogglesStore)
    const header = useMemo(
        () => ({
            header: t`feature-toggles-view.header`,
            breadcrumbs: [],
        }),
        [],
    )
    const globalStore = useStore(GlobalStore)

    const handlePropertyOwnerChange = useCallback(
        async (event: SelectChangeEvent) => {
            try {
                store.setIsLoading(true)
                const selectedPropertyOwnerId = Number(event.target.value)
                store.setSelectedPropertyOwnerId(
                    String(selectedPropertyOwnerId),
                )
                const legalEntities = globalStore.session.propertyOwners.filter(
                    (propertyOwner) =>
                        propertyOwner.propertyOwnerId ===
                        selectedPropertyOwnerId,
                )[0].legalEntities
                store.setLegalEntities(legalEntities ?? [])
                await store.populateTableData()
                if (store.legalEntities.length === 0) {
                    reportError(t`feature-toggles-view.no-legal-entities-found`)
                }
            } catch (e) {
                reportUnhandledApiError(e)
            } finally {
                store.setIsLoading(false)
            }
        },
        [store, globalStore.session.propertyOwners],
    )

    const handleSelectLegalEntities = useCallback(
        async (event: React.SyntheticEvent<Element, Event>, value) => {
            try {
                store.setIsLoading(true)
                store.setSelectedLegalEntities(value)
                await store.populateTableData()
            } catch (e) {
                reportUnhandledApiError(e)
            } finally {
                store.setIsLoading(false)
            }
        },
        [store],
    )

    const handleSelectAllLegalEntities = useCallback(async () => {
        try {
            store.setIsLoading(true)
            store.setSelectedLegalEntities(store.legalEntities)
            await store.populateTableData()
        } catch (e) {
            reportUnhandledApiError(e)
        } finally {
            store.setIsLoading(false)
        }
    }, [store])

    const handleSelectAllLegalEntitiyIdsWithoutFeatures =
        useCallback(async () => {
            try {
                store.setIsLoading(true)
                const filterOutLegalEntetiesId =
                    await FeaturesAdminService.getV1AdminFeaturesFlagHasFlags({
                        propertyOwnerId: Number(store.selectedPropertyOwnerId),
                    })
                store.setSelectedLegalEntities(
                    store.legalEntities?.filter(
                        (legalEntity) =>
                            !filterOutLegalEntetiesId.includes(
                                Number(legalEntity.legalEntityId),
                            ),
                    ),
                )
                await store.populateTableData()
            } catch (e) {
                reportUnhandledApiError(e)
            } finally {
                store.setIsLoading(false)
            }
        }, [store])

    const handleSelectNoLegalEntities = async () => {
        try {
            store.setIsLoading(true)
            store.setSelectedLegalEntities([])
            await store.populateTableData()
        } catch (e) {
            reportUnhandledApiError(e)
        } finally {
            store.setIsLoading(false)
        }
    }

    const handleSubmit = useCallback(async () => {
        try {
            store.setIsLoading(true)
            store.populateModifiedFeatureFlags()
            if (store.modifiedFeatureFlags.length > 0) {
                await FeaturesAdminService.putV1AdminFeaturesFlag({
                    request: {
                        feature_flags: store.modifiedFeatureFlags,
                    },
                })
                reportSuccess(t`global.saved-changes`)
                await store.populateTableData()
            }
        } catch {
            reportError(t`errors.request-failed-with-server-error-alert`)
            store.setTableData([])
        } finally {
            store.setIsLoading(false)
        }
    }, [store])

    const handleUndoChanges = useCallback(async () => {
        try {
            store.setIsLoading(true)
            await store.populateTableData()
        } catch (e) {
            reportUnhandledApiError(e)
        } finally {
            store.setIsLoading(false)
        }
    }, [store])

    return (
        <ListPage header={header}>
            {globalStore.session.propertyOwners.length > 0 && (
                <>
                    <StyledBox
                        sx={{
                            gridAutoColumns: {
                                md: "minmax(min-content, max-content)",
                            },
                            gridAutoFlow: {
                                xs: "row",
                                md: "column",
                            },
                        }}
                    >
                        <FormControl fullWidth>
                            <InputLabel id="property-owner-label">
                                {t`feature-toggles-view.property-owner`}
                            </InputLabel>
                            <Select
                                labelId="property-owner-label"
                                label={t`feature-toggles-view.property-owner`}
                                id="property-owner"
                                autoWidth
                                onChange={handlePropertyOwnerChange}
                                sx={{
                                    minWidth: 250,
                                }}
                                value={store.selectedPropertyOwnerId}
                            >
                                {globalStore.session.propertyOwners.map(
                                    (propertyOwner) =>
                                        propertyOwner.propertyOwnerId != null &&
                                        propertyOwner.legalName != null && (
                                            <MenuItem
                                                value={
                                                    propertyOwner.propertyOwnerId
                                                }
                                                key={
                                                    propertyOwner.propertyOwnerId
                                                }
                                            >
                                                {propertyOwner.legalName}
                                            </MenuItem>
                                        ),
                                )}
                            </Select>
                        </FormControl>
                    </StyledBox>

                    {store.legalEntities.length > 0 && (
                        <StyledPaper
                            sx={{
                                flexDirection: { xs: "column", sm: "row" },
                            }}
                        >
                            <Autocomplete
                                multiple
                                includeInputInList
                                limitTags={2}
                                id="legal-entities"
                                disableCloseOnSelect
                                options={store.legalEntities}
                                getOptionLabel={(legalEntity) =>
                                    legalEntity.legalName ?? ""
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t`feature-toggles-view.legal-entities`}
                                        placeholder="Search &amp; select"
                                    />
                                )}
                                renderOption={(
                                    props,
                                    legalEntity,
                                    { selected },
                                ) => (
                                    <li
                                        key={legalEntity.legalEntityId}
                                        {...props}
                                    >
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {legalEntity?.legalName}
                                    </li>
                                )}
                                onChange={handleSelectLegalEntities}
                                value={store.selectedLegalEntities}
                                sx={{
                                    width: "100%",
                                    flexGrow: 1,
                                }}
                                renderTags={(
                                    legalEntities: ISessionLegalEntity[],
                                    getTagProps,
                                ) => (
                                    <div
                                        key={legalEntities[0].legalEntityId}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Chip
                                            label={legalEntities[0].legalName}
                                            {...getTagProps({ index: 0 })}
                                        />
                                        {store.selectedLegalEntities.length >
                                            1 &&
                                            `+${
                                                store.selectedLegalEntities
                                                    .length - 1
                                            }`}
                                    </div>
                                )}
                            />

                            <FormControlLabel
                                htmlFor="select-all-legal-entities"
                                label={t`global.select-all`}
                                control={
                                    <Checkbox
                                        id="select-all-legal-entities"
                                        checked={
                                            store.selectedLegalEntities
                                                .length ===
                                            store.legalEntities.length
                                        }
                                        indeterminate={
                                            store.selectedLegalEntities.length >
                                                0 &&
                                            store.selectedLegalEntities
                                                .length !==
                                                store.legalEntities.length
                                        }
                                        onChange={(event) =>
                                            event.target.checked
                                                ? handleSelectAllLegalEntities()
                                                : handleSelectNoLegalEntities()
                                        }
                                    />
                                }
                                sx={{ flexShrink: 0 }}
                            />

                            <Button
                                size="small"
                                color="info"
                                variant="contained"
                                onClick={
                                    handleSelectAllLegalEntitiyIdsWithoutFeatures
                                }
                                type="button"
                                sx={{ flexShrink: 0 }}
                                data-testid="select-all-without-features-button"
                            >
                                {t`feature-toggles-view.select-all-without-features`}
                            </Button>
                        </StyledPaper>
                    )}
                </>
            )}

            {store.tableData.length > 1 && (
                <>
                    <FeatureTogglesTableView />
                    <StyledBox
                        sx={{
                            gridAutoColumns: {
                                md: "minmax(min-content, max-content)",
                            },
                            gridAutoFlow: {
                                xs: "row",
                                md: "column",
                            },
                        }}
                    >
                        <Button
                            color="info"
                            variant="contained"
                            type="button"
                            onClick={handleSubmit}
                            disabled={!store.dirtyFeatureFlags}
                            data-testid="save-button"
                        >
                            {t`global.save`}
                        </Button>
                        <Button
                            color="info"
                            variant="text"
                            onClick={handleUndoChanges}
                            disabled={!store.dirtyFeatureFlags}
                            type="button"
                            data-testid="undo-changes-button"
                        >
                            {t`global.undo-changes`}
                        </Button>
                    </StyledBox>
                </>
            )}
        </ListPage>
    )
})

export const FeatureTogglesView = () => (
    <StoreProvider Store={FeatureTogglesStore}>
        <View />
    </StoreProvider>
)

const StyledPaper = styled(Paper)(() => ({
    borderWidth: "0",
    padding: "20px",
    gap: "20px",
    alignItems: "center",
    display: "flex",
}))

const StyledBox = styled(Box)(() => ({
    display: "grid",
    gap: "20px",
    alignItems: "center",
}))
