import { styled, TablePagination, List as MUIList } from "@mui/material"

export const TablePaginationWithUncontrolledPageSize = styled(TablePagination)({
    display: "flex",
    justifyContent: "center",
    "& .MuiTablePagination-selectLabel": {
        display: "none",
    },
    // Hide page select
    "& .MuiInputBase-root": {
        display: "none",
    },
}) as typeof TablePagination // Hack to get `component` prop to work

export const List = styled(MUIList)({
    overflow: "auto",
    flex: 1,
})
