import { makeAutoObservable, runInAction } from "mobx"
import React from "react"

import { t } from "@lingui/macro"

import { OmniDomainAdminService } from "src/api"
import { FormFields } from "src/lib/form-fields"
import { reportError } from "src/lib/report"
import { IOmniDomainFormFields } from "src/modals/omni-domains/types/formFields"
import { avy_api_pkg_omnidomain_OmniDomain } from "src/api/models/avy_api_pkg_omnidomain_OmniDomain"
import { ISelectedPropertyOwner } from "src/types/local-storage"
import { Channel } from "src/channel"

export class OmniDomainStore {
    static Context = React.createContext<OmniDomainStore | null>(null)
    formFields = new FormFields<IOmniDomainFormFields>({})
    isLoading = false
    isDnsChecking = false
    dnsCheckError: string | null = null
    propertyOwners: ISelectedPropertyOwner[] = []
    fetchError: string | null = null

    constructor() {
        makeAutoObservable(this)
    }

    init = async (omniDomain?: avy_api_pkg_omnidomain_OmniDomain) => {
        if (omniDomain?.omni_domain_id !== undefined) {
            await this.fetchOmniDomain(omniDomain.omni_domain_id)
            void this.checkDNS(omniDomain.omni_domain_id)
        } else {
            this.formFields.init({
                name: "",
                certificate_dns_record: "",
                certificate_dns_record_value: "",
                certificate_status: "",
                property_owner_id: undefined,
                is_managed: false, // Initialize is_managed to false
            })
        }
    }

    fetchOmniDomain = async (omniDomainId: number) => {
        this.setIsLoading(true)
        this.fetchError = null
        try {
            const result = await OmniDomainAdminService.getV1AdminOmnidomain({
                omniDomainId,
            })
            runInAction(() => {
                this.formFields.init({
                    name: result.name ?? "",
                    certificate_dns_record: result.certificate_dns_record ?? "",
                    certificate_dns_record_value:
                        result.certificate_dns_record_value ?? "",
                    certificate_status: result.certificate_status ?? "",
                    property_owner_id: result.property_owner_id,
                    omni_domain_name: result.omni_domain_name ?? "",
                })
            })
        } catch (error) {
            runInAction(() => {
                this.fetchError = t`omni-domains-modal.fetch-item-fail`
            })
            reportError(t`omni-domains-modal.fetch-item-fail`, error)
        } finally {
            this.setIsLoading(false)
        }
    }

    setIsLoading = (isLoading: boolean) => {
        this.isLoading = isLoading
    }

    setPropertyOwners = (propertyOwners: ISelectedPropertyOwner[]) => {
        this.propertyOwners = propertyOwners
    }

    async createItem(): Promise<boolean> {
        try {
            this.setIsLoading(true)
            await OmniDomainAdminService.postV1AdminOmnidomain({
                request: {
                    name: this.formFields.get("name") as string,
                    property_owner_id: this.formFields.get(
                        "property_owner_id",
                    ) as number,
                    is_managed: this.formFields.get("is_managed") as boolean,
                },
            })

            // Send channel update
            Channel.send({
                name: "repository/updated",
                payload: {
                    repository: "omni-domains",
                    action: "create",
                },
            })

            return true // Return true to indicate success
        } catch (error) {
            reportError(t`omni-domains-modal.create-item-fail`, error)
            runInAction(() => {
                this.formFields.setError(
                    "generic",
                    t`omni-domains-modal.create-item-fail`,
                )
            })
            return false // Return false to indicate failure
        } finally {
            this.setIsLoading(false)
        }
    }

    checkDNS = async (omniDomainId: number) => {
        this.setIsDnsChecking(true)
        this.dnsCheckError = null
        try {
            const result =
                await OmniDomainAdminService.getV1AdminOmnidomainCheckDns({
                    omniDomainId,
                })
            runInAction(() => {
                this.formFields.set(
                    "certificate_dns_valid",
                    result.certificate_dns_valid,
                )
                this.formFields.set(
                    "domain_cname_valid",
                    result.domain_cname_valid,
                )
            })
        } catch (error) {
            runInAction(() => {
                this.dnsCheckError = t`omni-domains-modal.check-dns-fail`
            })
            reportError(t`omni-domains-modal.check-dns-fail`, error)
        } finally {
            this.setIsDnsChecking(false)
        }
    }

    setIsDnsChecking = (isDnsChecking: boolean) => {
        this.isDnsChecking = isDnsChecking
    }
}
