import { observer } from "mobx-react"

import { Stack } from "@mui/material"

import { t } from "@lingui/macro"

import { DynamicCardLayout } from "src/components/DynamicCardLayout"
import { ModalTextField } from "src/components/ModalTextField"
import { IDynamicFieldItemProps } from "src/modals/embedded-integration-v2/types/dynamicFieldItem"
import { IPurchaseTabDynamicSimpleLinkFieldsProps } from "src/modals/embedded-integration-v2/types/purchaseTabDynamicSimpleLinkFields"
import { useStore } from "src/store/lib/useStore"
import { ContentSectionStore } from "src/modals/embedded-integration-v2/sections/ContentSection/store"

export const EmbeddedIntegrationSimpleLinkCardSection = observer(
    ({
        index,
        item,
        purchaseType,
        onRemoveClick,
    }: IPurchaseTabDynamicSimpleLinkFieldsProps) => {
        const contentStore = useStore(ContentSectionStore)
        const purchaseSimpleLinkDynamicFieldsList =
            purchaseType.purchaseSimpleLinkDynamicFieldsList
        const salePurchaseSelectedType =
            contentStore.fields.data.salesPurchaseType
        const handleTextInputChange = (
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            key: string,
        ) => {
            const purchaseObject = {
                ...purchaseType,
            }
            const data = [...purchaseSimpleLinkDynamicFieldsList]
            const obj = JSON.parse(JSON.stringify(data[index]))
            obj[key].errorMessage = ""
            obj[key].value =
                event.target.value.trim() !== "" ? event.target.value : ""
            data[index] = obj
            purchaseObject.purchaseSimpleLinkDynamicFieldsList = data
            contentStore.fields.set(salePurchaseSelectedType, purchaseObject)
        }

        const renderTextInput = (
            label: string,
            item: IDynamicFieldItemProps,
            key: string,
            rowLength: number,
            maxLength?: number,
        ) => {
            return (
                <ModalTextField
                    variant="default"
                    label={label}
                    value={item?.value}
                    onChange={(event) => handleTextInputChange(event, key)}
                    helperText={item.errorMessage}
                    error={Boolean(item.errorMessage)}
                    maxLength={maxLength ?? 255}
                    rows={rowLength}
                />
            )
        }

        return (
            <DynamicCardLayout
                index={index}
                showDraggableIndicator={true}
                hideCopyContentIcon={true}
                hideBinIcon={false}
                onRemoveClick={onRemoveClick}
            >
                <Stack spacing={2}>
                    {renderTextInput(
                        t`embed-integrations-modal.content-section.purchase-tab.simple-link.title`,
                        item.linkTitle,
                        "linkTitle",
                        1,
                        255,
                    )}
                    {renderTextInput(
                        t`embed-integrations-modal.content-section.purchase-tab.simple-link.url`,
                        item.linkUrl,
                        "linkUrl",
                        1,
                        255,
                    )}
                </Stack>
            </DynamicCardLayout>
        )
    },
)
