import { observer } from "mobx-react"
import { Button, Stack } from "@mui/material"
import { t } from "@lingui/macro"
import React, { useCallback, useEffect } from "react"

import { CreateSection } from "./CreateSection"
import { CreatePropertyModalStore, IFormFields } from "./store"

import { ConfirmDeleteModal } from "src/modals/confirm-delete"

import { IPropertyOwner } from "src/views/client-management/store"
import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { Form } from "src/components/Form"
import { ModalHeader } from "src/components/ModalHeader"
import { FormPanel } from "src/components/FormPanel"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useCloseConfirmationForForm } from "src/store/modals/use-close-confirmation-for-form"
import { CenteredSpinner } from "src/components/CenteredSpinner"

interface IProps {
    propertyOwnersList: IPropertyOwner[]
    chosenPropertyOwnerId?: number
    chosenLegalEntityId?: number
    id?: number | undefined
}

const CreateNewProperty = observer(
    ({
        propertyOwnersList,
        chosenPropertyOwnerId,
        chosenLegalEntityId,
        id,
    }: IProps) => {
        const globalStore = useStore(GlobalStore)
        const store = useStore(CreatePropertyModalStore)

        useCloseConfirmationForForm(store.fields)

        useEffect(() => {
            ;(async () => {
                await store.init(chosenPropertyOwnerId, chosenLegalEntityId, id)
            })()
        }, [store, chosenPropertyOwnerId, chosenLegalEntityId, id])

        const isLoading =
            globalStore.loading.is(CreatePropertyModalStore.LoadingKeys.init) ||
            globalStore.loading.is(
                CreatePropertyModalStore.LoadingKeys.submit,
            ) ||
            globalStore.loading.is(CreatePropertyModalStore.LoadingKeys.delete)

        const validateRequiredFields = useCallback(
            (requiredFields: string[]) => {
                for (const field of requiredFields) {
                    const requiredField = field as keyof IFormFields
                    const formField = store.fields.get(requiredField)

                    if (formField == null || formField === "") {
                        store.fields.setError(requiredField, t`errors.required`)
                    } else {
                        store.fields.clearError(requiredField)
                    }
                }
            },
            [store.fields],
        )

        const handleSubmit = useCallback(async () => {
            let requiredFields = ["propertyOwnerId", "legalEntityId", "name"]

            validateRequiredFields(requiredFields)
            if (store.fields.hasErrors()) {
                return
            }
            await store.submit()

            if (!store.fields.hasErrors()) {
                globalStore.modals.pop()
            }
        }, [store, globalStore, validateRequiredFields])

        const deleteEntity = useCallback(
            async (id: number | string) => {
                await store.delete(id)
                globalStore.modals.pop()
                globalStore.modals.pop()
            },
            [store, globalStore],
        )

        const handleDeleteEntityCall = useCallback(async () => {
            if (store.fields.data.id !== undefined) {
                await globalStore.modals.open(
                    () => (
                        <ConfirmDeleteModal
                            variant="single"
                            deleteItem={deleteEntity}
                            itemId={store.fields.data.id ?? 0}
                            title={t`common.delete`}
                            content={t`create-new-property-modal.delete-warning`}
                        />
                    ),
                    { variant: "slide-up-w600" },
                )
            }
        }, [deleteEntity, globalStore.modals, store.fields.data.id])

        if (isLoading) {
            return <CenteredSpinner height="100vh" />
        }

        return (
            <>
                <Stack spacing={2}>
                    <Form onSubmit={handleSubmit}>
                        <ModalHeader>
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={store.isLoading}
                                data-testid="save-button"
                            >
                                {t`create-new-property-modal.save-button`}
                            </Button>
                        </ModalHeader>
                        <FormPanel
                            sections={[
                                {
                                    header: Boolean(store.fields.data.id)
                                        ? t`create-new-property-modal.section-header.edit-property`
                                        : t`create-new-property-modal.section-header.create-new-property`,
                                    content: (
                                        <CreateSection
                                            propertyOwnersList={
                                                propertyOwnersList
                                            }
                                        />
                                    ),
                                },
                            ]}
                        />
                    </Form>
                    {store.fields.data.id !== undefined && (
                        <FormPanel
                            sections={[
                                {
                                    header: "Delete",
                                    content: (
                                        <>
                                            <div>{t`create-new-property-modal.delete-warning`}</div>
                                            <Button
                                                type="submit"
                                                style={{ marginRight: "auto" }}
                                                variant="contained"
                                                data-testid={"delete-button"}
                                                onClick={handleDeleteEntityCall}
                                            >
                                                {t`common.delete`}
                                            </Button>
                                        </>
                                    ),
                                },
                            ]}
                        />
                    )}
                </Stack>
            </>
        )
    },
)

export const CreateNewPropertyModal = ({
    propertyOwnersList,
    chosenPropertyOwnerId,
    chosenLegalEntityId,
    id,
}: IProps) => (
    <StoreProvider Store={CreatePropertyModalStore}>
        <CreateNewProperty
            chosenPropertyOwnerId={chosenPropertyOwnerId}
            propertyOwnersList={propertyOwnersList}
            chosenLegalEntityId={chosenLegalEntityId}
            id={id}
        />
    </StoreProvider>
)
