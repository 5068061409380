import { styled, Typography } from "@mui/material"

export const EmptyMessageText = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[900],
    fontSize: "12px",
    lineHeight: 1.8,
    width: "220px",
    textAlign: "center",
    marginTop: "32px",
}))
