import { t } from "@lingui/macro"
import { Link } from "@mui/material"
import { observer } from "mobx-react"
import { useEffect } from "react"

import { Table } from "src/components/Table"
import { Pdf24 } from "src/components/icons/Pdf24"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { SelfRegistrationStore } from "src/views/self-registration/store"
import { SelfRegistrationLegalEntityStyledStack } from "src/views/self-registration/views/legalEntity/styled"

export const SelfRegistrationLegalEntityTab = observer(() => {
    const store = useStore(SelfRegistrationStore)
    const globalStore = useStore(GlobalStore)

    useEffect(() => {
        ;(async () => {
            if (store.legalEntities.length === 0) {
                store.setLegalEntities(
                    await store.fetchLegalEntities(
                        globalStore.session.propertyOwnerId as number,
                    ),
                )
            }

            if (store.legalEntitiesPagination.meta.initialized === false) {
                await store.legalEntitiesPagination.loadInitialPage()
            }
        })()
    }, [store, globalStore.session.propertyOwnerId])

    return (
        <Table
            paginator={store.legalEntitiesPagination}
            headers={[
                {
                    key: "legalEntityId",
                    name: t`self-registration-view.legal-entity-id`,
                },
                {
                    key: "legalName",
                    name: t`self-registration-view.legal-name`,
                },
                {
                    key: "orgId",
                    name: t`self-registration-view.org-id`,
                },
                {
                    key: "type",
                    name: t`self-registration-view.type`,
                },
                {
                    key: "selfOnboarding",
                    name: t`self-registration-view.title`,
                },
                {
                    key: "selfOnboardingCode",
                    name: t`self-registration-view.self-registration-code`,
                },
                {
                    key: "codeDistributionLeaflet",
                    name: t`self-registration-view.code-distribution-leaflet`,
                },
            ]}
            rowRenderer={(legalEntity) => ({
                legalEntityId: legalEntity.legalEntityId,
                legalName: legalEntity.legalName,
                orgId: legalEntity.orgId,
                type: legalEntity.type,
                selfOnboarding:
                    legalEntity.selfOnboardingEnabled === true
                        ? t`self-registration-view.yes`
                        : t`self-registration-view.no`,
                selfOnboardingCode: legalEntity.selfOnboardingCode,
                codeDistributionLeaflet: legalEntity.leafletUrl != null && (
                    <Link
                        href={legalEntity.leafletUrl}
                        target="_blank"
                        sx={{
                            textTransform: "uppercase",
                            lineHeight: 0,
                            display: "block",
                        }}
                    >
                        <SelfRegistrationLegalEntityStyledStack
                            direction="row"
                            alignItems="center"
                            gap={1}
                        >
                            <Pdf24 />
                            {t`self-registration-view.download`}
                        </SelfRegistrationLegalEntityStyledStack>
                    </Link>
                ),
            })}
        />
    )
})
