import { observer } from "mobx-react"
import {
    FormControlLabel,
    FormHelperText,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    TextField,
} from "@mui/material"

import { t } from "@lingui/macro"

import { CreateLegalEntityModalStore, TPropertyType } from "./store"

import { useStore } from "src/store/lib/useStore"
import { FormFieldSet } from "src/components/FormFieldSet"
import { IPropertyOwner } from "src/views/client-management/store"

interface IProps {
    propertyOwnersList: IPropertyOwner[]
}

interface IPropertyTypeObject {
    value: TPropertyType
    text: string
}

export const SettingsSection = observer(({ propertyOwnersList }: IProps) => {
    const store = useStore(CreateLegalEntityModalStore)

    const types: IPropertyTypeObject[] = [
        {
            value: "RENT",
            text: t`create-new-legal-entity-modal.types-array-rent`,
        },
        {
            value: "BRF",
            text: t`create-new-legal-entity-modal.types-array-brf`,
        },
        {
            value: "STUDENT_BRF",
            text: t`create-new-legal-entity-modal.types-array-student-brf`,
        },
        {
            value: "STUDENT_RENT",
            text: t`create-new-legal-entity-modal.types-array-student-rent`,
        },
        {
            value: "DEMO",
            text: t`create-new-legal-entity-modal.types-array-demo`,
        },
    ]

    const handleTypeDropdownChange = (event: SelectChangeEvent) => {
        // @ts-ignore -The Select-component returns a string and "type" needs to be of type TPropertyType-
        store.fields.set("type", event.target.value)
    }

    const onChangeIntegrationId = (event: { target: { value: string } }) => {
        store.fields.set(
            "integrationId",
            Number(event.target.value) > 0
                ? Number(event.target.value)
                : undefined,
        )
    }

    const onChangeActive = (event: { target: { value: string } }) => {
        store.fields.set("integrationActive", event.target.value === "true")
    }
    return (
        <>
            <FormFieldSet
                header={t`create-new-legal-entity-modal.property-owner.form-field-header`}
                tooltip={t`create-new-legal-entity-modal.property-owner.form-field-header`}
            >
                <Select
                    labelId="property-owner-label"
                    id="property-owner"
                    value={store.fields.get("propertyOwnerId") ?? ""}
                    onChange={(event) => {
                        store.fields.set(
                            "propertyOwnerId",
                            Number(event.target.value),
                        )
                    }}
                    error={Boolean(store.fields.error("propertyOwnerId"))}
                    sx={{ width: "100%" }}
                    data-testid="property-owner-dropdown"
                >
                    {propertyOwnersList.map((propertyOwner) => (
                        <MenuItem
                            value={propertyOwner.propertyOwnerId}
                            key={propertyOwner.propertyOwnerId}
                        >
                            {propertyOwner.legalName}
                        </MenuItem>
                    ))}
                </Select>
                {Boolean(store.fields.error("propertyOwnerId")) && (
                    <FormHelperText error={true}>
                        {store.fields.error("propertyOwnerId")}
                    </FormHelperText>
                )}
            </FormFieldSet>
            <FormFieldSet
                header={t`create-new-legal-entity-modal.legal-name`}
                tooltip={t`create-new-legal-entity-modal.legal-name`}
            >
                <TextField
                    label={t`create-new-legal-entity-modal.legal-name`}
                    placeholder={t`create-new-legal-entity-modal.legal-name`}
                    onChange={store.fields.setter("legalName")}
                    value={store.fields.get("legalName") ?? ""}
                    helperText={store.fields.error("legalName")}
                    error={Boolean(store.fields.error("legalName"))}
                />
            </FormFieldSet>
            <FormFieldSet
                header={t`create-new-legal-entity-modal.organisation-id`}
                tooltip={t`create-new-legal-entity-modal.organisation-id`}
            >
                <TextField
                    label={t`create-new-legal-entity-modal.organisation-id`}
                    placeholder={t`create-new-legal-entity-modal.organisation-id`}
                    onChange={store.fields.setter("organisationId")}
                    value={store.fields.get("organisationId") ?? ""}
                    helperText={store.fields.error("organisationId")}
                    error={Boolean(store.fields.error("organisationId"))}
                    disabled={Boolean(store.fields.get("id"))}
                />
            </FormFieldSet>
            <FormFieldSet
                header={t`create-new-legal-entity-modal.external-id`}
                tooltip={t`create-new-legal-entity-modal.external-id`}
            >
                <TextField
                    label={t`create-new-legal-entity-modal.external-id`}
                    placeholder={t`create-new-legal-entity-modal.external-id`}
                    onChange={store.fields.setter("externalId")}
                    value={store.fields.get("externalId") ?? ""}
                    helperText={store.fields.error("externalId")}
                    error={Boolean(store.fields.error("externalId"))}
                />
            </FormFieldSet>
            <FormFieldSet
                header={t`create-new-legal-entity-modal.integration-id`}
                tooltip={t`create-new-legal-entity-modal.integration-id`}
            >
                <TextField
                    label={t`create-new-legal-entity-modal.integration-id`}
                    placeholder={t`create-new-legal-entity-modal.integration-id`}
                    onChange={onChangeIntegrationId}
                    value={store.fields.get("integrationId") ?? ""}
                    helperText={store.fields.error("integrationId")}
                    error={Boolean(store.fields.error("integrationId"))}
                />
            </FormFieldSet>
            <FormFieldSet
                header={t`create-new-legal-entity-modal.type`}
                tooltip={t`create-new-legal-entity-modal.type`}
            >
                <Select
                    labelId="type-label"
                    id="type"
                    value={store.fields.get("type")}
                    onChange={handleTypeDropdownChange}
                    error={Boolean(store.fields.error("type"))}
                    disabled={Boolean(store.fields.get("id"))}
                    sx={{ width: "100%" }}
                    data-testid="type-dropdown"
                >
                    {types.map((type, index) => {
                        return (
                            <MenuItem value={type.value} key={index}>
                                {type.text}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormFieldSet>
            <FormFieldSet
                header={t`create-new-legal-entity-modal.self-onboarding`}
                tooltip={t`create-new-legal-entity-modal.self-onboarding`}
            >
                <RadioGroup
                    row
                    value={store.fields.get("selfOnboardingEnabled")}
                    onChange={(event) => {
                        store.fields.set(
                            "selfOnboardingEnabled",
                            event.target.value === "true",
                        )
                    }}
                >
                    <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label={t`create-new-legal-entity-modal.radio-button.yes`}
                        disabled={Boolean(store.fields.get("id"))}
                    />
                    <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label={t`create-new-legal-entity-modal.radio-button.no`}
                        disabled={Boolean(store.fields.get("id"))}
                    />
                </RadioGroup>
            </FormFieldSet>
            <FormFieldSet
                header={t`create-new-legal-entity-modal.integration-active`}
                tooltip={t`create-new-legal-entity-modal.integration-active`}
            >
                <RadioGroup
                    row
                    value={store.fields.get("integrationActive")}
                    // defaultChecked={true}
                    onChange={onChangeActive}
                >
                    <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label={t`create-new-legal-entity-modal.radio-button.yes`}
                    />
                    <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label={t`create-new-legal-entity-modal.radio-button.no`}
                    />
                </RadioGroup>
            </FormFieldSet>
        </>
    )
})
