import { t } from "@lingui/macro"
import { FormControlLabel, Radio, RadioGroup } from "@mui/material"
import { observer } from "mobx-react-lite"
import { useCallback } from "react"

import { FormFieldSet } from "src/components/FormFieldSet"
import { CommunitySelect } from "src/modals/community-post-detail/SettingsSection/CommunitySelect"
import { CommunityPostDetailStore } from "src/modals/community-post-detail/store"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"

export const SettingsSection = observer(() => {
    const gstore = useStore(GlobalStore)
    const store = useStore(CommunityPostDetailStore)

    const handlePinChange = useCallback(
        (_: unknown, value: string) => {
            store.form.set("pin", value === "true")
        },
        [store],
    )

    return (
        <>
            <FormFieldSet
                header={t`community-post-detail-modal.settings-section.community-header`}
            >
                <CommunitySelect />
            </FormFieldSet>
            {gstore.features.enabled("ShowCommunityPostPinnedCheckbox") && (
                <FormFieldSet
                    header={t`community-post-detail-modal.settings-section.pinned-header`}
                >
                    <RadioGroup
                        value={store.form.get("pin")}
                        onChange={handlePinChange}
                        row
                    >
                        <FormControlLabel
                            control={<Radio value={true} />}
                            label={t`community-post-detail-modal.settings-section.pinned-yes`}
                            disabled={store.form.get("accessType") === "READ"}
                        />
                        <FormControlLabel
                            control={<Radio value={false} />}
                            label={t`community-post-detail-modal.settings-section.pinned-no`}
                            disabled={store.form.get("accessType") === "READ"}
                        />
                    </RadioGroup>
                </FormFieldSet>
            )}
        </>
    )
})
