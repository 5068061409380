import { styled } from "@mui/material"

import { formatDateTime } from "src/lib/date"

const TimeNoWrap = styled("time")({ whiteSpace: "nowrap" })

interface IProps {
    date: Date | null
}

export const Time = (props: IProps) => {
    if (props.date == null) {
        return null
    }

    return <TimeNoWrap>{formatDateTime(props.date)}</TimeNoWrap>
}
