import { AlertColor } from "@mui/material"

import { makeAutoObservable } from "mobx"
import React from "react"

import { Channel } from "src/channel"
import { uniqueId } from "src/lib/unique-id"

interface INotification {
    id: number
    severity: AlertColor
    message: string
}

const NOTIFICATION_VISIBLE_FOR = 10_000

export class ToastFragmentStore implements IDisposable {
    static Context = React.createContext<ToastFragmentStore | null>(null)

    notifications: INotification[] = []

    private toastNotificationListenerDisposer?: () => void

    constructor() {
        makeAutoObservable(this)
    }

    init() {
        this.listenToToastNotifications()
    }

    dispose() {
        this.toastNotificationListenerDisposer?.()
    }

    removeNotification(id: number) {
        this.notifications = this.notifications.filter(
            (notification) => notification.id !== id,
        )
    }

    private listenToToastNotifications() {
        this.toastNotificationListenerDisposer = Channel.addListener(
            (event) => {
                if (event.name === "notification/toast") {
                    this.addNotification(
                        event.payload.severity,
                        event.payload.message,
                    )
                }
            },
        )
    }

    private addNotification(severity: AlertColor, message: string) {
        const notification = { id: uniqueId(), severity, message }
        this.notifications.push(notification)
        this.queueNotificationRemoval(notification.id)
    }

    private queueNotificationRemoval(id: number) {
        setTimeout(() => {
            this.removeNotification(id)
        }, NOTIFICATION_VISIBLE_FOR)
    }
}
