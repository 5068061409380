import { styled, Box, Dialog } from "@mui/material"

const ContentBox = styled(Box)(({ theme }) => ({
    display: "flex",
    backgroundColor: "rgba(250, 250, 250, 1)",
    borderRadius: theme.spacing(1),
    padding: "16px 16px 0px",
    flexDirection: "column",
    marginBottom: "16px",
}))

const StyledDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
        borderRadius: `0 0 ${theme.spacing(1)}px ${theme.spacing(1)}px`,
    },
}))

export { ContentBox, StyledDialog }
