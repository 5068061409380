/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { community_AdminCommentCreate } from "../models/community_AdminCommentCreate"
import type { community_AdminCommentUpdate } from "../models/community_AdminCommentUpdate"
import type { community_AdminPostCreate } from "../models/community_AdminPostCreate"
import type { community_AdminPostUpdate } from "../models/community_AdminPostUpdate"
import type { community_AuthorNameRequest } from "../models/community_AuthorNameRequest"
import type { community_BlockAuthorRequest } from "../models/community_BlockAuthorRequest"
import type { community_Category } from "../models/community_Category"
import type { community_CommunityCreateUpdate } from "../models/community_CommunityCreateUpdate"
import type { community_CommunityForAdmin } from "../models/community_CommunityForAdmin"
import type { community_GetBlockedAuthorsRequest } from "../models/community_GetBlockedAuthorsRequest"
import type { community_GetBlockedAuthorsResponse } from "../models/community_GetBlockedAuthorsResponse"
import type { community_GetCommentResponse } from "../models/community_GetCommentResponse"
import type { community_GetCommentsResponse } from "../models/community_GetCommentsResponse"
import type { community_GetPostsResponse } from "../models/community_GetPostsResponse"
import type { community_ListPostsRequest } from "../models/community_ListPostsRequest"
import type { community_ListPostsResponse } from "../models/community_ListPostsResponse"
import type { community_PostLike } from "../models/community_PostLike"
import type { community_PostWithAdminDetails } from "../models/community_PostWithAdminDetails"
import type { community_PostWithAdminDetailsV2 } from "../models/community_PostWithAdminDetailsV2"
import type { community_PublishCommunityRequest } from "../models/community_PublishCommunityRequest"
import type { community_ValidateSegmentRequest } from "../models/community_ValidateSegmentRequest"
import type { community_ValidateSegmentResponse } from "../models/community_ValidateSegmentResponse"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class CommunityAdminService {
    /**
     * Get communities
     * Returns all communities.
     * @returns community_CommunityForAdmin OK
     * @throws ApiError
     */
    public static getV1AdminCommunity(): CancelablePromise<
        Array<community_CommunityForAdmin>
    > {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/community",
        })
    }

    /**
     * Create community
     * Creates a community.
     * @returns community_CommunityForAdmin OK
     * @throws ApiError
     */
    public static postV1AdminCommunity({
        request,
    }: {
        /** Community data. **/
        request: community_CommunityCreateUpdate
    }): CancelablePromise<community_CommunityForAdmin> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/community",
            body: request,
        })
    }

    /**
     * Get blocked authors
     * Returns all blocked authors of communities that the admin has access to.
     * @returns community_GetBlockedAuthorsResponse OK
     * @throws ApiError
     */
    public static postV1AdminCommunityBlockedAuthors({
        request,
    }: {
        /** GetBlockedAuthorsRequest **/
        request: community_GetBlockedAuthorsRequest
    }): CancelablePromise<community_GetBlockedAuthorsResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/community/blocked-authors",
            body: request,
        })
    }

    /**
     * Get categories
     * Returns all categories.
     * @returns community_Category OK
     * @throws ApiError
     */
    public static getV1AdminCommunityCategory(): CancelablePromise<
        Array<community_Category>
    > {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/community/category",
        })
    }

    /**
     * Delete comment
     * Deletes a comment.
     * @returns any OK
     * @throws ApiError
     */
    public static deleteV1AdminCommunityComment({
        commentId,
    }: {
        /** Comment ID **/
        commentId?: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/community/comment/{comment_id}",
            path: {
                comment_id: commentId,
            },
        })
    }

    /**
     * Update comment
     * Updates a comment.
     * @returns any OK
     * @throws ApiError
     */
    public static patchV1AdminCommunityComment({
        commentId,
        request,
    }: {
        /** Comment ID **/
        commentId: number
        /** Comment update data **/
        request: community_AdminCommentUpdate
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PATCH",
            url: "/v1/admin/community/comment/{comment_id}",
            path: {
                comment_id: commentId,
            },
            body: request,
        })
    }

    /**
     * Handle comment
     * Mark comment as handled
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminCommunityCommentHandle({
        commentId,
    }: {
        /** Comment ID **/
        commentId?: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/community/comment/{comment_id}/handle",
            path: {
                comment_id: commentId,
            },
        })
    }

    /**
     * Admin like comment
     * Creates a like on comment.
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminCommunityCommentLike({
        request,
        commentId,
    }: {
        /** Author Name **/
        request: community_AuthorNameRequest
        /** Comment ID **/
        commentId?: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/community/comment/{comment_id}/like",
            path: {
                comment_id: commentId,
            },
            body: request,
        })
    }

    /**
     * Admin unlike comment
     * Unlike a comment.
     * @returns any OK
     * @throws ApiError
     */
    public static deleteV1AdminCommunityCommentLike({
        commentId,
    }: {
        /** Comment ID **/
        commentId?: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/community/comment/{comment_id}/like",
            path: {
                comment_id: commentId,
            },
        })
    }

    /**
     * Report comment
     * Report/flag comment which will mark it for future reference.
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminCommunityCommentReport({
        commentId,
    }: {
        /** Comment ID **/
        commentId?: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/community/comment/{comment_id}/report",
            path: {
                comment_id: commentId,
            },
        })
    }

    /**
     * Unhandle comment
     * Remove handled mark from comment
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminCommunityCommentUnhandle({
        commentId,
    }: {
        /** Comment ID **/
        commentId?: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/community/comment/{comment_id}/unhandle",
            path: {
                comment_id: commentId,
            },
        })
    }

    /**
     * Get community comments
     * Get a list of all comments
     * @returns community_GetCommentsResponse OK
     * @throws ApiError
     */
    public static getV1AdminCommunityComments({
        communityId,
        accessGroupId,
        pageNumber = 1,
        pageSize = 10,
        filter,
    }: {
        /** Community filter (optional, multiple IDs can be supplied comma separated) **/
        communityId?: Array<number>
        /** Access group filter (optional) **/
        accessGroupId?: number
        /** Pagination - number of page to load **/
        pageNumber?: number
        /** Pagination - size of pages to load **/
        pageSize?: number
        /** Advanced grid filter (JSON object) **/
        filter?: string
    }): CancelablePromise<community_GetCommentsResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/community/comments",
            query: {
                community_id: communityId,
                access_group_id: accessGroupId,
                page_number: pageNumber,
                page_size: pageSize,
                filter: filter,
            },
        })
    }

    /**
     * Get posts
     * Returns all posts.
     * @returns community_GetPostsResponse OK
     * @throws ApiError
     */
    public static getV1AdminCommunityPost({
        communityId,
        accessGroupId,
        pageNumber = 1,
        pageSize = 10,
        filter,
    }: {
        /** Community filter (optional, multiple IDs can be supplied comma separated) **/
        communityId?: Array<number>
        /** Access group filter (optional) **/
        accessGroupId?: number
        /** Pagination - number of page to load **/
        pageNumber?: number
        /** Pagination - size of pages to load **/
        pageSize?: number
        /** Advanced grid filter (JSON object) **/
        filter?: string
    }): CancelablePromise<community_GetPostsResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/community/post",
            query: {
                community_id: communityId,
                access_group_id: accessGroupId,
                page_number: pageNumber,
                page_size: pageSize,
                filter: filter,
            },
        })
    }

    /**
     * Create post
     * Creates a post.
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminCommunityPost({
        request,
    }: {
        /** Post data **/
        request: community_AdminPostCreate
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/community/post",
            body: request,
        })
    }

    /**
     * Get posts with comments
     * Returns all posts including the comments for each post.
     * @returns community_ListPostsResponse OK
     * @throws ApiError
     */
    public static postV1AdminCommunityPostList({
        request,
    }: {
        /** ListPostsRequest. **/
        request: community_ListPostsRequest
    }): CancelablePromise<community_ListPostsResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/community/post/list",
            body: request,
        })
    }

    /**
     * Get post
     * Get a single post.
     * @returns community_PostWithAdminDetails OK
     * @throws ApiError
     */
    public static getV1AdminCommunityPost1({
        postId,
    }: {
        /** Post ID **/
        postId: number
    }): CancelablePromise<community_PostWithAdminDetails> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/community/post/{post_id}",
            path: {
                post_id: postId,
            },
        })
    }

    /**
     * Delete post
     * Deletes a post.
     * @returns any OK
     * @throws ApiError
     */
    public static deleteV1AdminCommunityPost({
        postId,
    }: {
        /** Post ID **/
        postId?: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/community/post/{post_id}",
            path: {
                post_id: postId,
            },
        })
    }

    /**
     * Update post
     * Updates a post.
     * @returns any OK
     * @throws ApiError
     */
    public static patchV1AdminCommunityPost({
        postId,
        request,
    }: {
        /** Post ID **/
        postId: number
        /** Post update data **/
        request: community_AdminPostUpdate
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PATCH",
            url: "/v1/admin/community/post/{post_id}",
            path: {
                post_id: postId,
            },
            body: request,
        })
    }

    /**
     * Get comments
     * Returns all comments of a post.
     * @returns community_GetCommentsResponse OK
     * @throws ApiError
     */
    public static getV1AdminCommunityPostComment({
        postId,
    }: {
        /** Post ID **/
        postId: number
    }): CancelablePromise<community_GetCommentsResponse> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/community/post/{post_id}/comment",
            path: {
                post_id: postId,
            },
        })
    }

    /**
     * Create post comment
     * Creates a comment.
     * @returns community_GetCommentResponse OK
     * @throws ApiError
     */
    public static postV1AdminCommunityPostComment({
        postId,
        request,
    }: {
        /** Post ID **/
        postId: number
        /** Comment data. **/
        request: community_AdminCommentCreate
    }): CancelablePromise<community_GetCommentResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/community/post/{post_id}/comment",
            path: {
                post_id: postId,
            },
            body: request,
        })
    }

    /**
     * Handle post
     * Mark post as handled
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminCommunityPostHandle({
        postId,
    }: {
        /** Post ID **/
        postId?: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/community/post/{post_id}/handle",
            path: {
                post_id: postId,
            },
        })
    }

    /**
     * Like a post
     * Likes a post.
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminCommunityPostLike({
        postId,
        request,
    }: {
        /** Post ID **/
        postId: number
        /** Like data **/
        request: community_PostLike
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/community/post/{post_id}/like",
            path: {
                post_id: postId,
            },
            body: request,
        })
    }

    /**
     * Unlike a post
     * Unlikes a post.
     * @returns any OK
     * @throws ApiError
     */
    public static deleteV1AdminCommunityPostLike({
        postId,
    }: {
        /** Post ID **/
        postId?: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/community/post/{post_id}/like",
            path: {
                post_id: postId,
            },
        })
    }

    /**
     * Manage post
     * Mark post as managed.
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminCommunityPostManage({
        postId,
    }: {
        /** Post ID **/
        postId?: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/community/post/{post_id}/manage",
            path: {
                post_id: postId,
            },
        })
    }

    /**
     * Report post
     * Report/flag post which will mark it for future reference.
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminCommunityPostReport({
        postId,
    }: {
        /** Post ID **/
        postId?: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/community/post/{post_id}/report",
            path: {
                post_id: postId,
            },
        })
    }

    /**
     * Unhandle post
     * Remove handled mark from post
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminCommunityPostUnhandle({
        postId,
    }: {
        /** Post ID **/
        postId?: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/community/post/{post_id}/unhandle",
            path: {
                post_id: postId,
            },
        })
    }

    /**
     * Validate segment
     * Checks if the given segment, its parent or children already have access to a community.
     * @returns community_ValidateSegmentResponse OK
     * @throws ApiError
     */
    public static postV1AdminCommunityValidateSegment({
        request,
    }: {
        /** Segment IDs **/
        request: community_ValidateSegmentRequest
    }): CancelablePromise<community_ValidateSegmentResponse> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/community/validate-segment",
            body: request,
        })
    }

    /**
     * Get community
     * Returns a community by ID.
     * @returns community_CommunityForAdmin OK
     * @throws ApiError
     */
    public static getV1AdminCommunity1({
        communityId,
    }: {
        /** Community ID **/
        communityId: number
    }): CancelablePromise<community_CommunityForAdmin> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v1/admin/community/{community_id}",
            path: {
                community_id: communityId,
            },
        })
    }

    /**
     * Delete community
     * Deletes a community by ID.
     * @returns any OK
     * @throws ApiError
     */
    public static deleteV1AdminCommunity({
        communityId,
    }: {
        /** Community ID **/
        communityId: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "DELETE",
            url: "/v1/admin/community/{community_id}",
            path: {
                community_id: communityId,
            },
        })
    }

    /**
     * Update community
     * Updates a community.
     * @returns any OK
     * @throws ApiError
     */
    public static patchV1AdminCommunity({
        request,
        communityId,
    }: {
        /** Community data. **/
        request: community_CommunityCreateUpdate
        /** Community ID **/
        communityId: number
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PATCH",
            url: "/v1/admin/community/{community_id}",
            path: {
                community_id: communityId,
            },
            body: request,
        })
    }

    /**
     * Block an author from a community
     * Blocks an author from a community.
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminCommunityBlock({
        communityId,
        request,
    }: {
        /** Community ID **/
        communityId: number
        /** Author ID **/
        request: community_BlockAuthorRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/community/{community_id}/block",
            path: {
                community_id: communityId,
            },
            body: request,
        })
    }

    /**
     * Publish community
     * Publish community to segments
     * @returns any OK
     * @throws ApiError
     */
    public static putV1AdminCommunityPublish({
        communityId,
        request,
    }: {
        /** Community ID **/
        communityId: number
        /** Segment IDs **/
        request: community_PublishCommunityRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "PUT",
            url: "/v1/admin/community/{community_id}/publish",
            path: {
                community_id: communityId,
            },
            body: request,
        })
    }

    /**
     * Unblock an author from a community
     * Unblocks an author from a community.
     * @returns any OK
     * @throws ApiError
     */
    public static postV1AdminCommunityUnblock({
        communityId,
        request,
    }: {
        /** Community ID **/
        communityId: number
        /** Author ID **/
        request: community_BlockAuthorRequest
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/v1/admin/community/{community_id}/unblock",
            path: {
                community_id: communityId,
            },
            body: request,
        })
    }

    /**
     * Get post
     * Get a single post.
     * @returns community_PostWithAdminDetailsV2 OK
     * @throws ApiError
     */
    public static getV2AdminCommunityPost({
        postId,
    }: {
        /** Post ID **/
        postId: number
    }): CancelablePromise<community_PostWithAdminDetailsV2> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/v2/admin/community/post/{post_id}",
            path: {
                post_id: postId,
            },
        })
    }
}
