import { observer } from "mobx-react"
import { TextField, Box, useTheme, Typography } from "@mui/material"
import { useEffect } from "react"
import { t } from "@lingui/macro"

import { EditProductStore } from "src/views/product-catalogue/edit/store"
import { useStore } from "src/store/lib/useStore"
import { Alert16 } from "src/components/icons/Alert16"
import { Icon } from "src/components/icons"
import { Tooltip } from "src/components/Tooltips/Tooltip"

export const PriceText = observer(() => {
    const store = useStore(EditProductStore)
    const theme = useTheme()
    const productType = store.formFields.get("productType")

    useEffect(() => {
        return () => {
            // Reset to initial value if component unmounts
            store.formFields.set("priceText", store.product.priceText)
        }
    }, [store])

    return (
        <Box
            sx={{
                display: "flex",
                gap: "8px",
            }}
        >
            <TextField
                label={
                    productType === "affiliate"
                        ? t`edit-product-view.price-text.label-optional`
                        : t`edit-product-view.price-text.label`
                }
                value={store.formFields.get("priceText") ?? ""}
                onChange={store.formFields.setter("priceText")}
                error={Boolean(store.formFields.error("priceText"))}
                helperText={store.formFields.error("priceText")}
            />
            <Tooltip title={t`edit-product-view.price-text.tooltip`} arrow>
                <Typography sx={{ marginTop: "16px" }}>
                    <Icon
                        icon={<Alert16 width="20px" height="20px" />}
                        color={theme.palette.grey[700]}
                    />
                </Typography>
            </Tooltip>
        </Box>
    )
})
